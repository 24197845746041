import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Props } from "./ModalTurn.type";
import { Formik } from 'formik';
import { useState } from "react";
import { Divider, Grid, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import { Loading } from "@/components/common/Loading";
import { TurnService } from "@/service/services/Turn.service";
import ___PHRASE from "@/service/translate/translateService";
import '../../../assets/styles/modals.scss';
export const ModalTurns: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, turn, onListTurns, clearState } = props;

    const [loading, setLoading] = useState<boolean>(false);

    const [data, setData] = useState({
        turn: turn?.turn || ""
    });

    const validateForm = (values) => {
        let errors: any = {};
        if(!values.turn) errors.turn = ___PHRASE("esteCampoEsRequerido");
        if(values.turn.length > 100) errors.turn = ___PHRASE("noPuedeExcederLos100Caracteres");
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        const formData = {
            turn: values.turn,
        }
        setLoading(true);
        if(turn && actionSelected=='edit'){
            const respEdit = await TurnService.editTurn(turn.id,formData)
            onListTurns && onListTurns();
            closeModal && closeModal();
        }else{
            //llamar al crear
            const respCreated = await TurnService.createTurn(formData)
            onListTurns && onListTurns();
            closeModal && closeModal();
        }
        setLoading(false);
    }

    return(
        <>
        {loading && <Loading/>}

        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={((turn && actionSelected=='edit') ? ___PHRASE("editar") : ___PHRASE("agregar"))+' '+___PHRASE("girosDeLaEmpresa")}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("descripcion")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="turn"
                                            variant="standard"
                                            type="text" 
                                            name="turn"
                                            fullWidth
                                            value={values.turn}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("nombre")}
                                            error={errors.turn && touched.turn ? true : false}
                                            helperText={errors ? errors.turn : ''}
                                        />
                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(turn && actionSelected=='edit') ? ___PHRASE("actualizar") : ___PHRASE("registrar")}
                                    cancelText={___PHRASE("cancelar")}
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}