import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Container } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { Loading } from '@/components/common/Loading';
import { Props } from './TypeNotifications.type';
import { notificationsTypeService } from '@/service/services/NotificationsType.service';
import { ModalNotificationsType } from '@/components/Modal/ModalTypeNotifications';
import { ModalNotificationsTypeDelete } from '@/components/Modal/ModalTypeNotificationsDelete/ModalTypeNotificationsDelete';
import ___PHRASE from '@/service/translate/translateService';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_TRANSLATE, KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { apiResponseTranslate } from '@/toolbox/helpers/responseApi.helper';

export const NotificationsTypeView: React.FC<Props> = (props:any): JSX.Element => {

   const [loading, setLoading] = useState<boolean>(false);
   const [notificationsTypes, setNotificationsTypes] = useState([]);
   const [showNotificationsTypeModal, setShowNotificationsTypeModal] = useState<boolean>(false);
   const [showNotificationsTypeDeleteModal, setShowNotificationsTypeDeleteModal] = useState<boolean>(false);
   const [actionSelected, setActionSelected] = useState<String>('');
   const [notificationsTypeSelected, setNotificationsTypeSelected] = useState<String>('');
   const language = readLocalStorage(KEY_TRANSLATE);
   const dataUser = readLocalStorage(KEY_USER_DATA);
   const typeSuperadmin = dataUser?.user?.type_superadmin;

   useEffect(() => {
    getNotificationsTypes()
   },[]);

   const getNotificationsTypes = async () => {
      setLoading(true);
      const resp = await notificationsTypeService.getNotificationsTypes();
      const newData = [];
      resp.data && (resp.data).length > 0 && (resp.data).map((item)=>{
         if(language && language == 'en'){
            newData.push({...item, name: apiResponseTranslate(item.name)})
         }else{
            newData.push(item)
         }
      })

      setNotificationsTypes(newData || []);
      setLoading(false);
   }

   function RecuperarData(value){
      console.log(value);
      setNotificationsTypeSelected(value);
   }

  return (
      <Protected>
         {loading && <Loading/>}
         <Container>
            <TableDataV2
               title={___PHRASE("tiposdenotificaciones")}
               data={notificationsTypes}
               header={[
                  { name: 'name', label: ___PHRASE("nombre"), filter: false, Chip: true },
                  { name: 'url', label: 'URL', filter: false, Chip: true },
               ]}
               actionSelect={setActionSelected}
               setModalSave={setShowNotificationsTypeModal}
               setModalDelete={setShowNotificationsTypeDeleteModal}
               status_action={typeSuperadmin == '1' ? true : false}
               btn_disabled={typeSuperadmin == '1' ? false : true}
               // checkbox
               RecuperarData={RecuperarData}
            />
         </Container>

         { 
            showNotificationsTypeModal && (
               <ModalNotificationsType
                  open={showNotificationsTypeModal}
                  closeModal={()=>{setShowNotificationsTypeModal(false)}}
                  actionSelected={actionSelected}
                  onListNotificationsType={getNotificationsTypes}
                  notificationsType={notificationsTypeSelected}
                  clearState={()=>{setNotificationsTypeSelected('')}}
               />
            )
         }
         {
            actionSelected === 'delete' && (
               <ModalNotificationsTypeDelete
                  open={showNotificationsTypeDeleteModal}
                  closeModal={()=>{setShowNotificationsTypeDeleteModal(false)}}
                  actionSelected={actionSelected}
                  onListNotificationsType={getNotificationsTypes}
                  notificationsType={notificationsTypeSelected}
                  clearState={()=>{setNotificationsTypeSelected('')}}
               />
            )
         }
      
      </Protected>
   )
};