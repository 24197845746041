import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

const dataUser = readLocalStorage(KEY_USER_DATA);
const roleUser = dataUser?.user?.role;

export const WorkerValidationRepository = {

    getWorkerValidations: async(): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/identityValidation/myList`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    createWorkerValidation: async (dataValidation) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/identityValidation/create`, dataValidation);
        const {data, error, message} = res;
        return { data, error, message };
    },

    editWorkerValidation: async (idValidation, dataValidation) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/identityValidation/myEdit/${idValidation}`, dataValidation);
        const {data, error, message} = res;
        return { data, error, message };
    },

    deleteWorkerValidation: async(idValidation:number): Promise<any> => {
        const res= await http.delete<any>(`${API_URL_BASE}/v1/identityValidation/myEdit/${idValidation}`)
        return res;
    },

    listValidationIdentityAdmin: async(idemployee): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/identityValidation/listValidationIdentityAdmin?idemployee=${idemployee}&role=${roleUser}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    confirmValidationIdentityAdmin: async (dataValidation) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/identityValidation/confirmValidationIdentityAdmin`, dataValidation);
        const {data, error, message} = res;
        return { data, error, message };
    },
}   