import { AdvanceRepository } from "../repositories/Advance.repository";
import { IntegrationdiviceRepository } from "../repositories/IntegrationDivice.repository";

export const IntegrationDiviceService = {
    getDivicesByCompany,
    getEmployeeRemyZkteco,
    getAssitenceRemyZktecoByEmployee,
    createDivice,
    editDivice,
    deleteDivice,
    syncEmployee,
    createAssists,
    createAssistMasive,
    getReportAbsenceDelay,
}

async function getDivicesByCompany(idCompany: any){
    const res = await IntegrationdiviceRepository.getDivicesByCompany(idCompany);
    return res;
}

async function getEmployeeRemyZkteco(){
    const res = await IntegrationdiviceRepository.getEmployeeRemyZkteco();
    return res;
}

async function getAssitenceRemyZktecoByEmployee(idemployee, start_date, end_date){
    const res = await IntegrationdiviceRepository.getAssitenceRemyZktecoByEmployee(idemployee, start_date, end_date);
    return res;
}

async function createDivice(data){
    const res = await IntegrationdiviceRepository.createDivice(data);
    return res;
}
async function syncEmployee(){
    const res = await IntegrationdiviceRepository.syncEmployee();
    return res;
}

async function editDivice(data, idDivice: number){
    const res = await IntegrationdiviceRepository.editDivice(data, idDivice);
    return res;
}

async function deleteDivice(idDivice:number){
    const res = await IntegrationdiviceRepository.deleteDivice(idDivice);
    return res;
}

async function createAssists(data){
    const res = await IntegrationdiviceRepository.createAssists(data);
    return res;
}

async function createAssistMasive(data){
    const res = await IntegrationdiviceRepository.createAssistMasive(data);
    return res;
}

async function getReportAbsenceDelay(start_date:any, end_date:any, idEmployee:number){
    const res = await IntegrationdiviceRepository.getReportAbsenceDelay(start_date, end_date, idEmployee);
    return res;
}
