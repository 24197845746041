
import { Formik } from 'formik';
import { useState } from "react";
import { Button, Card, Grid, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { Loading } from "@/components/common/Loading";
import { Props } from './CompensationBox.type';
import { companiesService } from '@/service/services/Companies.service';
import ___PHRASE from '@/service/translate/translateService';

export const CompensationBox: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, dataCompany, onListDataCompany, compensationBox, clearState } = props;

    const [loading, setLoading] = useState<boolean>(false);

    const [data, setData] = useState({
        id: dataCompany ? dataCompany.idcompany : '',
        idcompensation_box: dataCompany ? dataCompany.idcompensation_box : '',
    });

    const validateForm = (values) => {
        let errors: any = {};
        if(!values.idcompensation_box) errors.idcompensation_box = ___PHRASE("compensacion") +' '+___PHRASE("requerido");
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        const formData = {
            idcompensation_box : values.idcompensation_box,
        }
        setLoading(true);
        await companiesService.updateCompensationBox(formData).then((data) => {
            onListDataCompany && onListDataCompany();
            closeModal && closeModal();
        })
        setLoading(false);
    }
    return(
        <>
        {loading && <Loading/>}


            <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                    return(
                        <form onSubmit={handleSubmit}>
                            <Card sx={{ width: "100%", marginTop: '20px' }}>
                                <TableContainer>
                                    <Table>
                                        <TableHead sx={{ bgcolor: '#EDEFF1' }}>
                                            <TableRow >
                                                <TableCell >
                                                    <Typography>{___PHRASE("cajaDeCompensacion")}</Typography>
                                                </TableCell>
                                                <TableCell >
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            <TableRow >
                                                <TableCell size={'small'} sx={{ bgcolor: '#F4F6F8', width: '30%' }}>
                                                {___PHRASE("cajaDeCompensacion")}
                                                </TableCell>
                                                <TableCell >
                                                    <Select
                                                        id="idcompensation_box"
                                                        name="idcompensation_box"
                                                        variant="standard"
                                                        value={values.idcompensation_box}
                                                        onChange={(event) => {
                                                            setFieldValue("idcompensation_box", event.target.value);
                                                        }}
                                                        sx={{ width: '400px' }}
                                                        error={errors.idcompensation_box && touched.idcompensation_box ? true : false}
                                                    >
                                                        {compensationBox.map((item, i) => {
                                                            return <MenuItem key={i} value={item.id}>{item.name}</MenuItem>;
                                                        })}
                                                    </Select>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Grid sx={{ textAlign: 'center', margin: '10px' }}>
                                    <Button color="primary" variant="contained" onClick={() => {handleSubmit()}}>
                                        {___PHRASE("actualizar")}
                                    </Button>
                                </Grid>
                            </Card>
                        </form>
                    )
                }}
            </Formik>
        </>
    );
}