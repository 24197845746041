import { useEffect, useState } from "react"
import { TableDataV2 } from "@/components/common/Tablev2"
import { Protected } from "@/components/layout/Protected"
import { Container } from "@mui/material"
import { ApplicantsService } from "@/service/services/Applicants.service"
import { Loading } from "@/components/common/Loading"
import { ModalApplicants } from "@/components/Modal/ModalApplicants"
import { ModalApplicantDelete } from "@/components/Modal/ModalApplicantDelete"
import { Props } from "./Applicants.type"
import ___PHRASE from "@/service/translate/translateService"


export const ApplicantsView: React.FC<Props> = (props:any): JSX.Element => {
    const [loading, setLoading] = useState<boolean>(false);
    const [apllicants, setApplicants] = useState([]);
    const [showApplicantModal, setShowApplicantModal] = useState<boolean>(false);
    const [showApplicantDeleteModal, setShowApplicantDeleteModal] = useState<boolean>(false);
    const [actionSelected, setActionSelected] = useState<String>('');
    const [applicantSelected, setApplicantSelected] = useState<String>('');

    useEffect(() => {
        getApplicants();
    }, []);

    const getApplicants = async() => {
        setLoading(true);
        await ApplicantsService.getApplicants().then((data) => {
            const oldData = data.data || [];
            let newData = [];
            oldData.length > 0 && oldData.map((item) => {
                data = {
                    id: item.id,
                    adresss: item.adresss,
                    birth_date: item.birth_date,
                    cv: item.cv,
                    email: item.email,
                    name: item.name,
                    first_surname: item.first_surname,
                    second_surname: item.second_surname,
                    surnames: `${item.first_surname} ${item.second_surname}`,
                    password: item.password,
                    pensioner: item.pensioner,
                    phone: item.phone,
                    photo1_validate: item.photo1_validate,
                    photo2_validate: item.photo2_validate,
                    rut: item.rut,
                    sex: item.sex,
                    status: item.status,
                    validate_identity: item.validate_identity,
                    id_afp: item.id_afp,
                    id_civil_status: item.id_civil_status,
                    id_district: item.id_district,
                    id_education_level: item.id_education_level,
                    id_employee: item.id_employee,
                    id_military_situacion: item.id_military_situacion,
                    id_nationality: item.id_nationality,
                    id_profession: item.id_profession,
                    id_salud: item.id_salud,
                }
                newData.push(data);
            });
            setApplicants(newData || []);
            setLoading(false);
        })
    }

    function RecuperarData(value){
        setApplicantSelected(value)
        console.log(value)
    }

    return (
        <>
        <Protected>
            {loading && <Loading/>}
            <Container>
                <TableDataV2
                    title={___PHRASE("postulantes")}
                    data={apllicants || []}
                    header={[
                        { name: 'name', label: ___PHRASE("nombres"), filter: false, Chip: false },
                        { name: 'surnames', label: ___PHRASE("apellidos"), filter: false, Chip: true },
                        // { name: 'rut', label: 'RUT', filter: false, Chip: false },
                        { name: 'adresss', label: ___PHRASE("direccion"), filter: false, Chip: false },
                        { name: 'phone', label: ___PHRASE("telefono"), filter: false, Chip: false },
                        // { name: 'email', label: 'Correo', filter: false, Chip: false },
                        { name: 'birth_date', label: ___PHRASE("fechaDeNacimiento"), filter: false, Chip: false },
                        // { name: 'sex', label: 'Género', filter: false, Chip: false },       
                        // { name: 'cv', label: 'Archivo', filter: false, Chip: false },                
                    ]}
                    actionSelect={setActionSelected}
                    setModalSave={setShowApplicantModal}
                    setModalDelete={setShowApplicantDeleteModal}
                    status_action
                    // checkbox
                    RecuperarData={RecuperarData}
                />
            </Container>
            { 
                showApplicantModal && (
                    <ModalApplicants
                        open={showApplicantModal}
                        closeModal={()=>{setShowApplicantModal(false)}}
                        actionSelected={actionSelected}
                        onListApplicants={getApplicants}
                        applicant={applicantSelected}
                        clearState={()=>{setApplicantSelected('')}}
                    />
                )
            }
            {
                actionSelected === 'delete' && (
                    <ModalApplicantDelete
                        open={showApplicantDeleteModal}
                        closeModal={()=>{setShowApplicantDeleteModal(false)}}
                        actionSelected={actionSelected}
                        onListApplicants={getApplicants}
                        applicant={applicantSelected}
                        clearState={()=>{setApplicantSelected('')}}
                    />
                )
            }
        </Protected>
        </>
    )
}