export const lang = {
  bienvenido: {
    es: "Bienvenido",
    en: "Welcome"
  },
  hola: {
    es: "Hola",
    en: "Hello"
  },
  rol: {
    es: "Rol",
    en: "Role"
  },
  cambiarRol: {
    es: "Cambiar Rol",
    en: "Change Role"
  },
  perfil: {
    es: "Perfil",
    en: "Profile"
  },
  tipoSuperadmin: {
    es: "Tipo Superadmin",
    en: "Superadmin type"
  },
  empresas: {
    es: "Empresas",
    en: "Companies"
  },
  configuracionempresa: {
    es: "Configuración Empresa",
    en: "Company Configuration"
  },
  inicio: {
    es: "Inicio",
    en: "Home"
  },
  actividades: {
    es: "Actividades",
    en: "Activities"
  },
  cliente: {
    es: "Cliente",
    en: "Client"
  },
  negocios: {
    es: "Negocios",
    en: "Business"
  },
  miCuenta: {
    es: "Mi Cuenta",
    en: "My Account"
  },
  salir: {
    es: "Salir",
    en: "Exit"
  },
  notificaciones: {
    es: "Notificaciones",
    en: "Notifications"
  },
  marcarTodasComoLeidas: {
    es: "Marcar Todas Como Leídas",
    en: "Mark All as Read"
  },
  verTodas: {
    es: "Ver Todas",
    en: "See All"
  },
  abrirEnUnaPestanaNueva: {
    es: "Abrir en una pestaña nueva",
    en: "Open in a new tab"
  },
  marcarComoLeido: {
    es: "Marcar como leído",
    en: "Mark as read"
  },
  quitar: {
    es: "Quitar",
    en: "Remove"
  },
  recordatorios: {
    es: "Recordatorios",
    en: "Reminders"
  },
  miEmpresa: {
    es: "Mi Empresa",
    en: "My Company"
  },
  buscarEmpresa: {
    es: "Buscar Empresa",
    en: "Search Company"
  },
  buscarProyectos: {
    es: "Buscar Proyectos",
    en: "Search Projects"
  },
  numeroDeRegistro: {
    es: "Número de RUT",
    en: "Registry number"
  },
  numeroDeDocumento: {
    es: "Número de Documento",
    en: "Document number"
  },
  numeroDeCargas: {
    es: "Número de Cargas",
    en: "Number of Charges"
  },
  nombre: {
    es: "Nombre",
    en: "Name"
  },
  nombres: {
    es: "Nombres",
    en: "Names"
  },
  telefono: {
    es: "Teléfono",
    en: "Phone"
  },
  telefonoFijo: {
    es: "Teléfono Fijo",
    en: "Landline Phone"
  },
  celular: {
    es: "Celular",
    en: "Mobile Phone"
  },
  celularDeEmergencia: {
    es: "Celular de Emergencia",
    en: "Emergency Mobile Phone"
  },
  correo: {
    es: "Correo",
    en: "Email"
  },
  maestros: {
    es: "Maestros",
    en: "Manteiners"
  },
  editar: {
    es: "Editar",
    en: "Edit"
  },
  acciones: {
    es: "Acciones",
    en: "Actions"
  },
  empresa: {
    es: "Empresa",
    en: "Company"
  },
  agregar: {
    es: "Agregar",
    en: "Add"
  },
  agregado: {
    es: "Agregado",
    en: "aggregated"
  },
  numeroDeRut: {
    es: "Número de RUT",
    en: "RUT number"
  },
  nombreDeNegocio: {
    es: "Nombre de Negocio",
    en: "Business Name"
  },
  nombreDeContacto: {
    es: "Nombre de Representante",
    en: "Contact manager"
  },
  rutDeContacto: {
    es: "RUT de Representante",
    en: "RUT of manager"
  },
  nombreDeUsuario: {
    es: "Nombre de Usuario",
    en: "User name"
  },
  correoDeUsuario: {
    es: "Correo de Usuario",
    en: "User email"
  },
  correoDeContacto: {
    es: "Correo de Representante",
    en: "Email manager"
  },
  telefonoDeContacto: {
    es: "Teléfono de Representate",
    en: "Telephone manager"
  },
  sitioWeb: {
    es: "Sitio Web",
    en: "Website"
  },
  pais: {
    es: "País",
    en: "Country"
  },
  departamento: {
    es: "Departamento",
    en: "Department"
  },
  escribeParaBuscar: {
    es: "Escriba para Buscar",
    en: "Write to Search"
  },
  provincia: {
    es: "Provincia",
    en: "Province"
  },
  distrito: {
    es: "Distrito",
    en: "District"
  },
  direccionPrincipal: {
    es: "Dirección Principal",
    en: "Main Address"
  },
  direccion: {
    es: "Dirección",
    en: "Address"
  },
  tipoDeEmpresa: {
    es: "Tipo de Empresa",
    en: "Type of Company"
  },
  cajaDeCompensacion: {
    es: "Caja de Compensación",
    en: "Compensation Box"
  },
  logo: {
    es: 'Logo',
    en:'Logo'
  },
  cargarLogo: {
    es: "Cargar un Logo",
    en: "Upload a logo"
  },
  verLogoActual: {
    es: 'Ver logo actual',
    en: 'See current logo'
  },
  actualizar: {
    es: "Actualizar",
    en: "Update"
  },
  registrar: {
    es: "Registrar",
    en: "Register"
  },
  cancelar: {
    es: "Cancelar",
    en: "Cancel"
  },
  requerido: {
    es: "Requerido",
    en: "Required"
  },
  noRequerido: {
    es: "No Requerido",
    en: "Not Required"
  },
  compensacion: {
    es: "Compensación",
    en: "Compensation"
  },
  debeSeleccionarUnDistrito: {
    es: "Debe seleccionar un Distrito",
    en: "You must select a District"
  },
  debeSeleccionarUnEmpleado: {
    es: "Debe seleccionar un empleado",
    en: "You must select a employee"
  },
  debeSeleccionarMesyAno: {
    es: "Debe seleccionar Mes y Año",
    en: "You must select Month and Year"
  },
  eliminar: {
    es: "Eliminar",
    en: "Delete"
  },
  estaSeguroDeEliminarEsteUsuario: {
    es: "¿Está seguro de eliminar este usuario?",
    en: "Are you sure to remove this user?"
  },
  estaSeguroDeEliminarEstaVariableGlobal: {
    es: "¿Está seguro de eliminar esta variable global?",
    en: "Are you sure to remove this global variable?"
  },
  estaSeguroDeEliminarEsteTipoDeSucursal: {
    es: "¿Está seguro de eliminar este tipo de Sucursal?",
    en: "Are you sure to delete this type of Branch?"
  },
  estaSeguroDeEliminarEstaZonaHoraria: {
    es: "¿Está seguro de eliminar esta Zona Horaria?",
    en: "Are you sure to delete this Time Zone?"
  },
  estaSeguroDeEliminarEsteTipoDeNotificacion: {
    es: "¿Está seguro de eliminar este tipo de notificación?",
    en: "Are you sure to remove this type of notification?"
  },
  estaSeguroDeEliminarEstaComuna: {
    es: "¿Está seguro de eliminar esta Comuna?",
    en: "Are you sure to delete this Commune?"
  },
  estaSeguroDeEliminarEstaProvincia: {
    es: "¿Está seguro de eliminar esta Provincia?",
    en: "Are you sure to delete this Province?"
  },
  estaSeguroDeEliminarEstaRegion: {
    es: "¿Está seguro de eliminar esta Región?",
    en: "Are you sure to delete this Region?"
  },
  estaSeguroDeEliminarEstePais: {
    es: "¿Está seguro de eliminar este País?",
    en: "Are you sure to delete this Country?"
  },
  estaSeguroDeEliminarElGasto: {
    es: "¿Está seguro de eliminar el gasto?",
    en: "Are you sure to eliminate the expense?"
  },
  estaSeguroDeEliminarEsteTipoDeGasto: {
    es: "¿Está seguro de eliminar este tipo de gasto?",
    en: "Are you sure to eliminate this type of expense?"
  },
  estaSeguroDeEliminarEstePostulante: {
    es: "¿Está seguro de eliminar a este postulante?",
    en: "Are you sure to delete this applicant?"
  },
  estaSeguroDeEliminarEstaFase: {
    es: "¿Está seguro de eliminar esta Fase?",
    en: "Are you sure to delete this Phase?"
  },
  estaSeguroDeEliminarEsteEquipoDeReclutamiento: {
    es: "¿Está seguro de eliminar este equipo de reclutamiento?",
    en: "Are you sure to delete this recruiting team?"
  },
  estaSeguroDeEliminarEstaEmpresa: {
    es: "¿Está seguro de eliminar esta Empresa?",
    en: "Are you sure to delete this Company?"
  },
  estaSeguroDeEliminarEsteProyecto: {
    es: "¿Está seguro de eliminar este proyecto?",
    en: "Are you sure to delete this project?"
  },
  estaSeguroDeEliminarEsteCentroDeCosto: {
    es: "¿Está seguro de eliminar este centro de costo?",
    en: "Are you sure to eliminate this cost center?"
  },
  estaSeguroDeEliminarEsteDepartamento: {
    es: "¿Está seguro de eliminar este Departamento?",
    en: "Are you sure to delete this Department?"
  },
  estaSeguroDeEliminarEstaResponsabilidadDelDepartamento: {
    es: "¿Está seguro de eliminar esta responsabilidad del Departamento?",
    en: "Are you sure to remove this responsibility from the Department?"
  },
  estaSeguroDeEliminarEsteCargo: {
    es: "¿Está seguro de eliminar este cargo?",
    en: "Are you sure to remove this charge?"
  },
  estaSeguroDeEliminarEsteGiro: {
    es: "¿Está seguro de eliminar este GIRO?",
    en: "Are you sure to delete this TURN?"
  },
  estaSeguroDeEliminarEstePerfil: {
    es: "¿Está seguro de eliminar este perfil?",
    en: "Are you sure to delete this Profile?"
  },
  estaSeguroDeEliminarLaAsistencia: {
    es: "¿Está seguro de eliminar la asistencia?",
    en: "Are you sure to delete the assistance?"
  },
  debeLlenarTodosLosCamposParaLaIntegracionDelControlAsistencia: {
    es: "Debe llenar todos los campos para la integración del control asistencia",
    en: "You must fill in all the fields for the integration of attendance control"
  },
  debeLlenarTodosLosCampos: {
    es: "Debe llenar todos los campos.",
    en: "You must fill in all the fields."
  },
  configuracionDeEmpresa: {
    es: "Configuración de Empresa",
    en: "Company Configuration"
  },
  estadoDeLasIntegraciones: {
    es: "ESTADO DE LAS INTEGRACIONES",
    en: "STATUS OF THE INTEGRATIONS"
  },
  activo: {
    es: "Activo",
    en: "Active"
  },
  inactivo: {
    es: "Inactivo",
    en: "Inactive"
  },
  desactivado: {
    es: "Desactivado",
    en: "Disabled"
  },
  verDispositivos: {
    es: "Ver Dispositivos",
    en: "View Devices"
  },
  agregarDispositivo: {
    es: "Agregar Dispositivo",
    en: "Add Devices"
  },
  sucursal: {
    es: "Sucursal",
    en: "Branch"
  },
  sucursalOficina: {
    es: "Sucursal Oficina",
    en: "branch office"
  },
  editarDispositivo: {
    es: "Editar Dispositivo",
    en: "Edit Devices"
  },
  areaDelDispositivo: {
    es: "Area del Dispositivo",
    en: "Device Area"
  },
  usuarioDeBaseDeDatos: {
    es: "Usuario de Base de Datos",
    en: "Database User"
  },
  contrasena: {
    es: "Contraseña",
    en: "Password"
  },
  nombreDeLaBaseDeDatos: {
    es: "Nombre de la Base de Datos",
    en: "Database Name"
  },
  serieDelDispositivo: {
    es: "Serie del Dispositivo",
    en: "Device Series"
  },
  modeloDelDispositivo: {
    es: "Modelo del Dispositivo",
    en: "Device Model"
  },
  serie: {
    es: "Serie",
    en: "Series"
  },
  modelo: {
    es: "Modelo",
    en: "Model"
  },
  usuario: {
    es: "Usuario",
    en: "User"
  },
  noEncontrado: {
    es: "No Encontrado",
    en: "Not Found"
  },
  buscarTrabajador: {
    es: "Buscar Trabajador",
    en: "Search Worker"
  },
  empleado: {
    es: "Empleado",
    en: "Employee"
  },
  sincronizarEmpleados: {
    es: "Sincronizar Trabajadores",
    en: "Synchronize Workers"
  },
  habilitarPrestamo: {
    es: "Habilitar Préstamo",
    en: "Enable Borrow"
  },
  solicitarPrestamo: {
    es: "Solicitar Prestamo",
    en: "Request Loan"
  },
  ocurrioAlgunErrorIntentaloMasTarde: {
    es: "Ocurrió algun error, intentelo mas tarde.",
    en: "An error occurred, please try again later."
  },
  registroExitoso: {
    es: "Registro Exitoso",
    en: "Successful registration"
  },
  cargando: {
    es: "Cargando...",
    en: "Loading..."
  },
  grupoDeCompania: {
    es: "Grupo de Compañias",
    en: "Group of Companies"
  },
  compania: {
    es: "Compañia",
    en: "Company"
  },
  estado: {
    es: "Estado",
    en: "State"
  },
  deseaAceptarORechazarLaSolicitud: {
    es: "¿Desea Aceptar o Recharzar la Solicitud?",
    en: "Do you want to Accept or Reject the Request?"
  },
  aceptar: {
    es: "Aceptar",
    en: "Accept"
  },
  solicitar: {
    es: "Solicitar",
    en: "Request"
  },
  solicitudAceptada: {
    es: "Solicitud Aceptada",
    en: "Request accepted"
  },
  valorRequerido: {
    es: "valor requerido",
    en: "required value"
  },
  anticipoeditadosatisfactoriamente: {
    es: "Anticipo editado satisfactoriamente.",
    en: "Advance edited satisfactorily."
  },
  rechazar: {
    es: "Rechazar",
    en: "Decline"
  },
  eliminarEmpresa: {
    es: "Eliminar Empresa",
    en: "Delete Company"
  },
  estaSeguroDeEliminarEstaCompañia: {
    es: "¿Está seguro de eliminar esta compañia?",
    en: "Are you sure to delete this company?"
  },
  estaSeguroDeEliminarEsteContacto: {
    es: "¿Está seguro de eliminar este contacto?",
    en: "Are you sure to delete this contact?"
  },
  estaSeguroDeEliminarEstaSucursal: {
    es: "¿Está seguro de eliminar esta sucursal?",
    en: "Are you sure to delete this branch?"
  },
  estaSeguroDeEliminarAlEmpleado2: {
    es: "¿Está seguro de eliminar al empleado?",
    en: "Are you sure to delete the employee?"
  },
  alEliminarUnEmpleadoSeEliminaraElUsuarioyContratoAlCualEsteAfiliadoDentroDeLaEmpresa:{
    es: "Al eliminar un empleado, se eliminará el usuario y contrato, al cual este afiliado dentro de la empresa.",
    en: "When deleting an employee, the user and contract will be deleted, to which he is affiliated within the company."
  },
  estaSeguroDeEliminarAlEmpleado: {
    es: "¿Está seguro de eliminar al empleado con sus funcion(es) y su participación dentro del proyecto?",
    en: "Are you sure to delete the employee with his function(s) and his participation within the project?"
  },
  estaSeguroDeEliminarHistoriaLaboral: {
    es: "¿Está seguro de eliminar el historial laboral?",
    en: "Are you sure to delete the employment history?"
  },
  usuarios: {
    es: "Usuarios",
    en: "Users"
  },
  documentN: {
    es: "Documento N°",
    en: "Document No."
  },
  nDocumento: {
    es: "N° Documento",
    en: "Document No."
  },
  buscarUsuarios: {
    es: "Buscar Usuarios",
    en: "Seach Users"
  },
  cargasFamiliares: {
    es: "Cargas Familiares",
    en: "Family Responsibilities"
  },
  historialLaboral: {
    es: "Historial Laboral",
    en: "Employment History"
  },
  verContrato: {
    es: "Ver Contrato",
    en: "See Contract"
  },
  reprocesarLiquidacion: {
    es: "Reprocesar Liquidación",
    en: "Reprocess Settlement"
  },
  estasSeguroReprocesarLaLiquidacion:{
    es: "¿Está seguro de reprocesar la liquidación? Este proceso eliminará la liquidación del periodo y mes seleccionado, generando una nueva previsualización con los datos actualizados.",
    en: "Are you sure to reprocess the settlement? This process will eliminate the settlement of the selected period and month, generating a new preview with the updated data."
  },
  redirigir: {
    es: "Redirigir",
    en: "Redirect"
  },
  verDocumento: {
    es: "Ver Documento",
    en: "See Document"
  },
  empresaALaQueElUsuarioPertenece: {
    es: "Empresas a la que el Usuario Pertenece",
    en: "Companies to which the User belongs"
  },
  restriccionPorIP: {
    es: "Restricción por IP",
    en: "IP restriction"
  },
  conRestriccion: {
    es: "Con Restricción",
    en: "With Restriction"
  },
  sinRestriccion: {
    es: "Sin Restricción",
    en: "Without Restrictions"
  },
  apellido: {
    es: "Apellido",
    en: "Last name"
  },
  segundoApellido: {
    es: "Segundo Apellido",
    en: "Second surname"
  },
  apellidoPaterno: {
    es: "Apellido Paterno",
    en: "Paternal Surname"
  },
  apellidoMaterno: {
    es: "Apellido Materno",
    en: "Maternal Surname"
  },
  certificadoDeAntiguedad: {
    es: "Certificado de Antigüedad",
    en: "Certificate of Antiquity"
  },
  mantenedorDeClientesTrabajadores: {
    es: "Mantenedor de Clientes Trabajadores",
    en: "Maintainer of Employee Clients"
  },
  incorporeSusTrabajadoresEnElSiguienteFormulario: {
    es: "Incorpore sus Trabajadores en el siguiente Formulario.",
    en: "Incorporate your Workers in the following Form."
  },
  listadoDeCargasFamiliares: {
    es: "Listado de Cargas Familiares",
    en: "List of Family Burdens"
  },
  listadoDeTrabajadoresIngresados: {
    es: "Listado de Trabajadores Ingresados",
    en: "List of Entered Workers"
  },
  mantenedorDeCargasFamiliaresDelTrabajador: {
    es: "Mantenedor de Cargas Familiares del Trabajador",
    en: "Maintainer of Worker's Family Burdens"
  },
  mantenedorDeHistorialLaboral: {
    es: "Mantenedor de Historial Laboral",
    en: "Employment Record Keeper"
  },
  formadorDeEmpleado: {
    es: "Formulario de Empleado",
    en: "Employee Form"
  },
  masculino: {
    es: "Masculino",
    en: "Male"
  },
  femenino: {
    es: "Femenino",
    en: "Female"
  },
  campoObligatorio: {
    es: "Campo Obligatorio",
    en: "Obligatory field"
  },
  obligatorio: {
    es: "Obligatorio",
    en: "Obligatory"
  },
  estadoCivil: {
    es: "Estado Civil",
    en: "Civil status"
  },
  estadoContrato: {
    es: "Estado de Contrato",
    en: "Contract Status"
  },
  tipoDeTrabajador: {
    es: "Tipo de Trabajador",
    en: "Employee Type"
  },
  sexo: {
    es: "Sexo",
    en: "Sex"
  },
  estadoDelTrabajador: {
    es: "Estado del Trabajador",
    en: "Worker Status"
  },
  ultimoNivelDeEstudios: {
    es: "Último Nivel de Estudios",
    en: "Last Level of Studies"
  },
  comuna: {
    es: "Comuna",
    en: "commune"
  },
  siguiente: {
    es: "Siguiente",
    en: "Next"
  },
  errorDeFormatoPDF: {
    es: `El formato de imagen no es correcta, o esta intentando subir un archivo en formato distinto a ".pdf"`,
    en: `The image format is not correct, or you are trying to upload a file in a format other than ".pdf"`
  },
  elArchivoEsDemasiadoGrandePorFavorSeleccioneUnArchivoMasPequeño:{
    es: "El archivo es demasiado grande. Por favor, seleccione un archivo más pequeño (máx. 1MB).",
    en: "The file is too large. Please select a smaller file (max. 1MB)."
  },
  imagen: {
    es: "Imagen",
    en: "Image"
  },
  debeSubirDocumentosDeIdentidadDelEmpleado: {
    es: "Debe subir documentos de identidad del empleado.",
    en: "You must upload identity documents of the employee."
  },
  asegureseDeLlenarTodosLosCamposYSubirLosArchivosCorrespondientes: {
    es: "Asegurese de llenar todos los campos y subir los archivos correspondientes.",
    en: "Be sure to fill in all the fields and upload the corresponding files."
  },
  cantidadDeDocumentos: {
    es: "Cantidad de documentos",
    en: "Number of documents"
  },
  nombreDelDocumento: {
    es: "Nombre del documento",
    en: "Document Name"
  },
  tipoDeArchivo: {
    es: "Tipo de Archivo",
    en: "Type of file"
  },
  atras: {
    es: "Atrás",
    en: "Back"
  },
  volver: {
    es: "Volver",
    en: "Return"
  },
  noSeEncontraronDocumento: {
    es: "No se Encontraron Documentos",
    en: "Not Documents Found"
  },
  subirDocumentosDeIdentidadDelEmpleado: {
    es: "Subir Documentos de Identidad del Empleado",
    en: "Upload Employee Identity Documents"
  },
  losDocumentosYaHanSidoValidadosPorElEmpleado: {
    es: "Los documentos ya han sido validados por el empleado.",
    en: "The documents have already been validated by the employee."
  },
  losDocumentosAunNoHanSidoValidadosPorElEmpleado: {
    es: "Los documentos aún no han sido validados por el empleado.",
    en: "The documents have not yet been validated by the employee."
  },
  datosPersonales: {
    es: "Datos Personales",
    en: "Personal information"
  },
  documentosDeIdentidad: {
    es: "Documentos de Identidad",
    en: "Identity Documents"
  },
  datosLaborales: {
    es: "Datos Laborales",
    en: "Labor data"
  },
  informacionPrevisional: {
    es: "Información Previsional",
    en: "Social Security Information"
  },
  informacionAdicional: {
    es: "Información Adicional",
    en: "Additional information"
  },
  informacionBancaria: {
    es: "Información Bancaria",
    en: "Bank information" 
  },
  siTieneSubsidio: {
    es: "Si Tiene Subsidio",
    en: "if you have a subsidy"
  },
  noTieneSubsidio: {
    es: "No Tiene Subsidio",
    en: "Does not have subsidy"
  },
  si: {
    es: "Si",
    en: "Yes"
  },
  no: {
    es: "No",
    en: "Not"
  },
  cargo: {
    es: "Cargo",
    en: "Charge"
  },
  modoDeTrabajo: {
    es: "Modo de Trabajo",
    en: "Working mode"
  },
  tiempoDeTrabajo: {
    es: "Tiempo de Trabajo",
    en: "Working time"
  },
  seguroDeCesantia: {
    es: "Seguro de Cesantía",
    en: "Unemployment insurance"
  },
  duracionDeContrato: {
    es: "Duración de Contrato",
    en: "Contract duration"
  },
  tipoDeContrato: {
    es: "Tipo de Contrato",
    en: "Type of contract"
  },
  tipoDocumentoContrato: {
    es: "Tipo Documento Contrato",
    en: "Contract Document Type"
  },
  subsidioTrabajadorJoven: {
    es: "Subsidio Trabajador Joven",
    en: "Young Worker Subsidy"
  },
  comunaDePrestacionDeServicios: {
    es: "Comuna de prestación de servicios",
    en: "Commune of provision of services"
  },
  sueldoPactado: {
    es: "Sueldo Pactado",
    en: "Agreed salary"
  },
  tipoDeGratificacion: {
    es: "Tipo Gratificación",
    en: "Gratification Type"
  },
  Gratificacion: {
    es: "Gratificación",
    en: "Gratification"
  },
  centroCosto: {
    es: "Centro Costo",
    en: "Cost Center"
  },
  anosOtrosControladores: {
    es: "Años otros Empleadores",
    en: "Years Other Employers"
  },
  afp: {
    es: "A.F.P.",
    en: "A.F.P."
  },
  variablesAFP: {
    es: "Variables de AFP",
    en: "AFP Variables"
  },
  variablesCM: {
    es: "Variables de CM",
    en: "CM Variables"
  },
  salud: {
    es: "Salud",
    en: "Health"
  },
  institucionAPV: {
    es: "Institución APV",
    en: "APV Institution"
  },
  libroRemElect: {
    es: "Libro Rem. Elect.",
    en: "Elect. Pay. Book"
  },
  nContrato: {
    es: "N° Contrato",
    en: "Contract No."
  },
  tipoCargaFamiliar: {
    es: "Tipo de Carga Familiar",
    en: "Type of Family Load"
  },
  tipoContratoSalud: {
    es: "Tipo Contrato Salud",
    en: "Health Contract Type"
  },
  pactadoCotSalud: {
    es: "Pactado % Cot. Salud",
    en: "Agreed % Cot. Health"
  },
  pactadoCotSalud2: {
    es: "Pactado $ Cot. Salud",
    en: "Agreed $ Cot. Health"
  },
  pactadoCotSalud3: {
    es: "Pactado Uf Cot. Salud",
    en: "Agreed Uf Cot. Health"
  },
  tramoAsigFamiliar: {
    es: "Tramo Asig Familiar",
    en: "Tranche Asig Family"
  },
  banco: {
    es: "Banco",
    en: "Bank"
  },
  formaDePago: {
    es: "Forma de Pago",
    en: "Method of Payment"
  },
  tipoDeCuenta: {
    es: "Tipo de Cuenta",
    en: "Account type"
  },
  numero: {
    es: "Número",
    en: "Number"
  },
  guardar: {
    es: "Guardar",
    en: "Save"
  },
  fechaDeNacimiento: {
    es: "Fecha de Nacimiento",
    en: "Birthdate"
  },
  desde: {
    es: "Desde",
    en: "From"
  },
  hasta: {
    es: "Hasta",
    en: "Until"
  },
  vigenciaDesde: {
    es: "Vigencia desde",
    en: "Valid since"
  },
  vigenciaInicio: {
    es: "Vigencia Inicio",
    en: "Validity Start"
  },
  vigenciaTermino: {
    es: "Vigencia Término",
    en: "Validity Term"
  },
  tipo: {
    es: "Tipo",
    en: "Type"
  },
  parentesco: {
    es: "Parentesco",
    en: "Relationship"
  },
  visualizar: {
    es: "Visualizar",
    en: "Visualize"
  },
  enlazarProspecto: {
    es: "Enlazar Prospecto",
    en: "Link Lead"
  },
  verDetalles: {
    es: "Ver Detalles",
    en: "See details"
  },
  mover: {
    es: "Mover",
    en: "Move"
  },
  nota: {
    es: "Nota",
    en: "Note"
  },
  cotizacion: {
    es: "Cotización",
    en: "Price"
  },
  hiloDeCorreo: {
    es: "Hilo de Correo",
    en: "Mail Thread"
  },
  enviarLandingPorEmail: {
    es: "Enviar Landing por correo",
    en: "Send landing by email"
  },
  enviarAccesoPorEmail: {
    es: "Enviar Acceso por correo",
    en: "Send access by email"
  },
  envio: {
    es: "Envio",
    en: "Mailing"
  },
  correosEnviados: {
    es: "Correos Enviados",
    en: "Emails Sent"
  },
  fechaDeInicio: {
    es: "Fecha de Inicio",
    en: "Start Date"
  },
  fechaDeTermino: {
    es: "Fecha de Termino",
    en: "End Date"
  },
  negociacionesProhibidasPorEscrito: {
    es: "Negociaciones prohibidas por escrito",
    en: "Negotiations prohibited in writing"
  },
  noConcurrenciaALasLabores: {
    es: "No concurrencia a las labores",
    en: "No attendance at work"
  },
  abandonoDelTrabajo: {
    es: "Abandono del trabajo",
    en: "abandonment of work"
  },
  IncorporeHistorialLaboralDelTrabajador: {
    es: "Incorpore Historial Laboral del trabajador",
    en: "Incorporate the Work History of the worker"
  },
  IncorporeHistorialLaboral: {
    es: "Incorpore Historial Laboral",
    en: "Incorporate the Work History"
  },
  EditarHistorialLaboral: {
    es: "Editar Historial Laboral",
    en: "Edit the Work History"
  },
  EditarHistorialLaboralDelTrabajador: {
    es: "Editar Historial Laboral del trabajador",
    en: "Edit the Work History of the worker"
  },
  crearFiniquito: {
    es: "Crear finiquito",
    en: "Create settlement"
  },
  causalUltimoFiniquito: {
    es: "Causal Último Finiquito",
    en: "Cause Last Settlement"
  },
  listadoDeHistorialLaboral: {
    es: "Listado de Historial Laboral",
    en: "Employment History List"
  },
  micontrato: {
    es: "Mi Contrato",
    en: "My Contract"
  },
  firmar: {
    es: "Firmar",
    en: "Sign"
  },
  verFirma: {
    es: "Ver Firma",
    en: "See Signature"
  },
  suEmpresaCuentaConLaIntegracionDeFirmaDigital: {
    es: "Su empresa cuenta con la Integración de Firma Digital",
    en: "Your company has Digital Signature Integration"
  },
  atrasos: {
    es: "Atrasos",
    en: "Delays"
  },
  atraso: {
    es: "Atraso",
    en: "Delay"
  },
  atrasosPorEmpleado: {
    es: "Atrasos por Empleado",
    en: "Employee Delays"
  },
  atrasosDe: {
    es: "Atrasos de ",
    en: "Delays by"
  },
  anexosDe: {
    es: "Anexos de ",
    en: "Annexes by "
  },
  editarAtraso: {
    es: "Editar Atraso",
    en: "Edit Delay"
  },
  agregarAtraso: {
    es: "Agregar Atraso",
    en: "Add Delay"
  },
  rutTrabajador: {
    es: "RUT Trabajador",
    en: "RUT Worker"
  },
  fecha: {
    es: "Fecha",
    en: "Date"
  },
  totalDeHoras: {
    es: "Total de Horas",
    en: "Total hours"
  },
  totalDeHorasEsRequerido: {
    es: 'El total de horas es requerido.',
    en: 'Total hours are required.'
  },
  observacion: {
    es: "Observación",
    en: "Observation"
  },
  listaDeEmpleados: {
    es: "Lista de Empleados",
    en: "List of employees"
  },
  verAtrasos: {
    es: "Ver Atrasos",
    en: "See Delays"
  },
  nHorasDeAtrasos: {
    es: "N° horas de atrasos",
    en: "Hours of delays No."
  },
  totalAtrasos: {
    es: "Total de Atrasos",
    en: "Total delay"
  },
  estaSeguroDeEliminarEsteAtraso: {
    es: "¿Está seguro de eliminar este Atraso?",
    en: "Are you sure to remove this Delay?"
  },
  eliminadoCorrectamente: {
    es: "Eliminado Correctamente",
    en: "Deleted Successfully"
  },
  editadoCorrectamente: {
    es: "Editado Correctamente",
    en: "Edit Successfully"
  },
  guardadoCorrectamente: {
    es: "Guardado Correctamente",
    en: "Save Successfully"
  },
  campo: {
    es: "Campo",
    en: "Field"
  },
  antes: {
    es: "Antes",
    en: "Before"
  },
  despues: {
    es: "Despues",
    en: "After"
  },
  noExistenAnexosEnElContrato: {
    es: "No existen anexos en el contrato",
    en: "There are no annexes in the contract"
  },
  anexos: {
    es: "Anexos",
    en: "Annexes"
  },
  anexosPorEmpleado: {
    es: "Anexos por Empleado",
    en: "Annexes by Employee"
  },
  verAnexos: {
    es: "Ver Anexos",
    en: "See Annexes"
  },
  administrador: {
    es: "Administrador",
    en: "Administrator"
  },
  grupodeempresas: {
    es: "Grupo de Empresas",
    en: "Business group"
  },
  trabajadores: {
    es: "Trabajadores",
    en: "Workers"
  },
  trabajador: {
    es: "Trabajador",
    en: "Worker"
  },
  contactosdeltrabajdor: {
    es: "Contactos del Trabajador",
    en: "Worker Contacts"
  },
  contactosde: {
    es: "Contactos de ",
    en: "'s Contacts"
  },
  sucursales: {
    es: "Sucursales",
    en: "Branch offices"
  },
  proyectos: {
    es: "Proyectos",
    en: "Projects"
  },
  proyecto: {
    es: "Proyecto",
    en: "Project"
  },
  proyectoEliminado: {
    es: "Proyecto Eliminado",
    en: "Deleted Project"
  },
  centrosdecostos: {
    es: "Centro de Costos",
    en: "Cost center"
  },
  areasdetrabajo: {
    es: "Areas de Trabajo",
    en: "Work areas"
  },
  cargos: {
    es: "Cargos",
    en: "Charges"
  },
  tiposdesolicitudes: {
    es: "Tipo de solicitudes",
    en: "Type of requests"
  },
  girosdelaempresa: {
    es: "Giros de la Empresa",
    en: "Turns of the Company"
  },
  productos: {
    es: "Productos",
    en: "Products"
  },
  mispoliticasyprivacidad: {
    es: "Mis Politicas y Privacidad",
    en: "My Policies and Privacy"
  },
  documentocontrato: {
    es: "Documento Contrato",
    en: "Contract Document"
  },
  horarios: {
    es: "Horarios",
    en: "Schedules"
  },
  controldevistas: {
    es: "Control de Vistas",
    en: "View Control"
  },
  creardocumentocontrato: {
    es: "Crear Documento Contrato",
    en: "Create Contract Document"
  },
  personalizarcontrato: {
    es: "Personalizar Contrato",
    en: "Customize Contract"
  },
  perfilesdeusuario: {
    es: "Perfiles de Usuario",
    en: "User Profiles"
  },
  configuraciónempresa: {
    es: "Configuración Empresa",
    en: "Company Configuration"
  },
  contratoempleado: {
    es: "Contrato Empleado",
    en: "Employee Contract"
  },
  informacionDeContacto: {
    es: "Informacion de Contacto",
    en: "Contact information"
  },
  telefonoDeEmergencia: {
    es: "Teléfono de Emergencia",
    en: "Telephone emergency "
  },
  telefonoPersonal: {
    es: "Teléfono Personal",
    en: "Telephone personal "
  },
  tardanzasPorEmpleado: {
    es: "Tardanzas por empleado",
    en: "Tardies per employee"
  },
  verInformacionDeContacto: {
    es: "Ver información de contacto",
    en: "See contact information"
  },
  contacto: {
    es: "Contacto",
    en: "Contact"
  },
  codigo: {
    es: "Código",
    en: "Code"
  },
  buscarSucursal: {
    es: "Buscar Sucursal",
    en: "Search Branch"
  },
  codigoDeSucursal: {
    es: "Código de Sucursal",
    en: "Branch Code"
  },
  tipoDeSucursal: {
    es: "Tipo de Sucursal",
    en: "Branch Type"
  },
  sucursalEliminada: {
    es: "Sucursal Eliminada",
    en: "Deleted branch"
  },
  descripcion: {
    es: "Descripción",
    en: "Description"
  },
  fechaInicio: {
    es: "Fecha Inicio",
    en: "Start date"
  },
  fechaFin: {
    es: "Fecha Fin",
    en: "End date"
  },
  apellidos: {
    es: "Apellidos",
    en: "Surnames"
  },
  funcion: {
    es: "Función",
    en: "Function"
  },
  participacion: {
    es: "Participación",
    en: "participation"
  },
  listaDeProyectos: {
    es: "Lista de Proyectos",
    en: "Project List"
  },
  empleadosPorProyecto: {
    es: "Empleados por Proyectos",
    en: "Employees by Projects"
  },
  detalleDeParticipación: {
    es: "Detalle de Participación",
    en: "Participation Detail"
  },
  deIngenieria: {
    es: "De Ingeniería",
    en: "Engineering"
  },
  economicos: {
    es: "Económicos",
    en: "Economic"
  },
  fiscales: {
    es: "Fiscales",
    en: "Prosecutors"
  },
  legales: {
    es: "Legales",
    en: "Legal"
  },
  artisticos: {
    es: "Artísticos",
    en: "Artistic"
  },
  tecnologicos: {
    es: "Tecnológicos",
    en: "Technological"
  },
  productivos: {
    es: "Productivos",
    en: "productive"
  },
  otros: {
    es: "Otros",
    en: "Others"
  },
  inicioDelProyecto: {
    es: "Inicio del proyecto",
    en: "Project start"
  },
  finDelProyecto: {
    es: "Fin del proyecto",
    en: "Project end"
  },
  totalProyectado: {
    es: "Total Proyectado",
    en: "Total projected"
  },
  tipoDeProyecto: {
    es: "Tipo de Proyecto",
    en: "Project Type"
  },
  eliminarProyecto: {
    es: "Eliminar Proyecto",
    en: "Delete Project"
  },
  empleadoEliminadoConExito: {
    es: "Empleado eliminado con éxito",
    en: "Employee successfully removed"
  },
  ElPeriodoSeleccionadoNoSeEncuentraEnElRangoDeInicioyFinDElPoryecto: {
    es: "El periodo seleccionado no se encuentra en el rango de Inicio y Fin del Proyecto.",
    en: "The selected period is not in the Project Start and End range."
  },
  agregarParticipacion: {
    es: "Agregar Participación",
    en: "Add Participation"
  },
  adicional: {
    es: "adicional",
    en: "additional"
  },
  totalHorasSemanales: {
    es: "Total de Horas Semanales",
    en: "Total Weekly Hours"
  },
  esPorDefectoElNumeroDeHorasSemanales: {
    es: "45 es por defecto el numero de horas semanales. El cambio de las horas alterará bajo su responsabilidad la generación de liquidaciones.",
    en: "45 is the default number of weekly hours. Changing the hours will alter the generation of settlements under your responsibility."
  },
  periodo: {
    es: "Periodo",
    en: "Period"
  },
  mes: {
    es: "Mes",
    en: "Month"
  },
  enero: {
    es: "Enero",
    en: "January"
  },
  febrero: {
    es: "Febrero",
    en: "February"
  },
  marzo: {
    es: "Marzo",
    en: "March"
  },
  abril: {
    es: "Abril",
    en: "April"
  },
  mayo: {
    es: "Mayo",
    en: "May"
  },
  junio: {
    es: "Junio",
    en: "June"
  },
  julio: {
    es: "Julio",
    en: "July"
  },
  agosto: {
    es: "Agosto",
    en: "August"
  },
  septiembre: {
    es: "Septiembre",
    en: "September"
  },
  octubre: {
    es: "Octubre",
    en: "October"
  },
  noviembre: {
    es: "Noviembre",
    en: "November"
  },
  diciembre: {
    es: "Diciembre",
    en: "December"
  },
  elegirLaParticipacionDelEmpleado: {
    es: "Elegir la participación del empleado",
    en: "Choosing Employee Participation"
  },
  porcentajeParticipacion: {
    es: "Porcentaje Participacion",
    en: "Percentage Participation"
  },
  totalParticipacion: {
    es: "Total Participacion",
    en: "Total Participation"
  },
  verPorcentaje: {
    es: "Ver Porcentaje",
    en: "See Percentage"
  },
  verTotal: {
    es: "Ver Total",
    en: "See Total"
  },
  buscarCentroDeCostos: {
    es: "Buscar Centro de Costos",
    en: "Search Cost Center"
  },
  centroDeCosto: {
    es: "Centro de Costo",
    en: "Cost center"
  },
  centroDeCostoEliminado: {
    es: "Centro de Costo Eliminado",
    en: "Eliminated Cost Center"
  },
  cantidadDeTrabajadores: {
    es: "Cantidad de Trabajadores",
    en: "Number of Workers"
  },
  responsabilidadesDelDepartamento: {
    es: "Responsabilidades del departamento",
    en: "Department responsibilities"
  },
  listaDeDepartamentos: {
    es: "Lista de departamentos",
    en: "department list"
  },
  departamentos: {
    es: "Departamentos",
    en: "Departments"
  },
  buscarDepartamentos: {
    es: "Buscar Departamentos",
    en: "Search Departments"
  },
  debeSeleccionarUnaSucursal: {
    es: "Debe seleccionar una Sucursal",
    en: "You must select a Branch"
  },
  responsabilidad: {
    es: "Responsabilidad",
    en: "Responsibility"
  },
  verHorasExtras: {
    es: "Ver horas extras",
    en: "See overtime"
  },
  crearLiquidacion: {
    es: "Crear liquidación",
    en: "Create settlement"
  },
  verInasistencia: {
    es: "Ver inasistencias",
    en: "See absences"
  },
  verAnticipos: {
    es: "Ver anticipos",
    en: "See advances"
  },
  verEstructuras: {
    es: "Ver estructuras",
    en: "See structures"
  },
  verDetalle: {
    es: "Ver detalle",
    en: "See detail"
  },
  verMovimientos: {
    es: "Ver movimientos",
    en: "See movements"
  },
  verDetalleDelReporte: {
    es: "Ver detalle del reporte",
    en: "See report detail"
  },
  verEmpresasALaQuePertenece: {
    es: "Ver empresas a la que pertenece",
    en: "See companies to which it belongs"
  },
  detalles: {
    es: "Detalles",
    en: "Details"
  },
  detalle: {
    es: "Detalle",
    en: "Detail"
  },
  verResponsabilidades: {
    es: "Ver responsabilidades",
    en: "See responsibilities"
  },
  verFunciones: {
    es: "Ver funciones",
    en: "See functions"
  },
  ver: {
    es: "Ver",
    en: "See"
  },
  verEspecificaciones: {
    es: "Ver especificaciones",
    en: "See specifications"
  },
  listaDeCargos: {
    es: "Lista de cargos",
    en: "Charges list"
  },
  funciones: {
    es: "Funciones",
    en: "Functions"
  },
  buscarCargos: {
    es: "Buscar cargos",
    en: "Search charges"
  },
  funcionesDelCargo: {
    es: "Funciones del cargo",
    en: "functions of the charge"
  },
  cargoEliminado: {
    es: "Cargo eliminado",
    en: "Deleted charge"
  },
  girosDeLaEmpresa: {
    es: "Giros de la Empresa",
    en: "Turns of the Company"
  },
  politicasyPrivacidad: {
    es: "Politicas y Privacidad",
    en: "Policies and Privacy"
  },
  politicasyPrivacidadDelSoftware: {
    es: "Politicas y Privacidad del Software",
    en: "Software Policies and Privacy"
  },
  politicasyPrivacidadDeLaEmpresa: {
    es: "Politicas y Privacidad de la Empresa",
    en: "Company Policies and Privacy"
  },
  documento: {
    es: "Documento",
    en: "Document"
  },
  tipoDeDocumento: {
    es: "Tipo de Documento",
    en: "Document type"
  },
  cargar: {
    es: "Cargar",
    en: "Load"
  },
  subirArchivo: {
    es: "Subir archivo",
    en: "Upload file"
  },
  archivoSubido: {
    es: "Archivo subido",
    en: "Uploaded file"
  },
  documentocontratos: {
    es: "Documento Contratos",
    en: "Contracts document"
  },
  distritoDeLaEmpresa: {
    es: "Distrito de la Empresa",
    en: "Company District"
  },
  datoFirmaContrato: {
    es: "Dato firma contrato",
    en: "Contract signature data"
  },
  nombreDeLaEmpresa: {
    es: "Nombre de la empresa",
    en: "Company name"
  },
  rutDeLaEmpresa: {
    es: "RUT de la empresa",
    en: "RUT Company"
  },
  administradorDeLaEmpresa: {
    es: "Administrador de la Empresa",
    en: "Company manager"
  },
  rutAdministradorDeLaEmpresa: {
    es: "Rut Administrador de la Empresa",
    en: "Rut Company Administrator"
  },
  direccionAdministradorDeLaEmpresa: {
    es: "Dirección Administrador de la Empresa",
    en: "Company Administrator Address"
  },
  distritoAdministradorDeLaEmpresa: {
    es: "Distrito de Administrador de la Empresa",
    en: "Company Administrator District"
  },
  nombreDelEmpleado: {
    es: "Nombre del Empleado",
    en: "Name of the employee"
  },
  rutDelEmpleado: {
    es: "RUT del Empleado",
    en: "Employee's RUT"
  },
  nacionalidadDelEmpleado: {
    es: "Nacionalidad del Empleado",
    en: "Employee Nationality"
  },
  fechaNacimientoDelEmpleado: {
    es: "Fecha Nacimiento del Empleado",
    en: "Employee's Date of Birth"
  },
  direccionDelEmpleado: {
    es: "Dirección del Empleado",
    en: "Employee Address"
  },
  distritoDelEmpleado: {
    es: "Distrito del Empleado",
    en: "Employee District"
  },
  cargoDelEmpleado: {
    es: "Cargo del Empleado",
    en: "Employee Title"
  },
  direccionDeLaSucursal: {
    es: "Dirección de la Sucursal",
    en: "Branch Address"
  },
  distritoDeLaSucursal: {
    es: "Distrito de la Sucursal",
    en: "Branch District"
  },
  horarioSemanalDelEmpleado: {
    es: "Horario Semanal del Empleado",
    en: "Employee Weekly Schedule"
  },
  horarioInicioDelEmpleado: {
    es: "Horario Inicio del Empleado",
    en: "Employee Start Hours"
  },
  horarioFinDelEmpleado: {
    es: "Horario Fin del Empleado",
    en: "Employee End Hours"
  },
  colaciónHoraDelEmpleado: {
    es: "Colación Hora del Empleado",
    en: "Employee Hour Snack"
  },
  salarioBaseDelEmpleado: {
    es: "Salario base del Empleado",
    en: "Employee base salary"
  },
  cartasSalarialesDelEmpleado: {
    es: "Cartas Salariales del Empleado",
    en: "Employee Pay Letters"
  },
  errorAlCrearElDocumentoContrato: {
    es: "Error al crear el Documento Contrato",
    en: "Error when creating the Contract Document"
  },
  errorAlEditarElDocumentoContrato: {
    es: "Error al editar el Documento Contrato",
    en: "Error when editing the Contract Document"
  },
  errorAlCrearClausulas: {
    es: "Error al crear Clausulas",
    en: "Error creating Clauses"
  },
  nombreDeLaClausula: {
    es: "Nombre de la Cláusula",
    en: "Clause Name"
  },
  nuevaClausula: {
    es: "Nueva Cláusula",
    en: "New Clause"
  },
  crearDocumentoContrato: {
    es: "Crear Documento Contrato",
    en: "Create Contract Document"
  },
  crearContrato: {
    es: "Crear Contrato",
    en: "Create Contract"
  },
  regresar: {
    es: "Regresar",
    en: "Return"
  },
  nombreDelContrato: {
    es: "Nombre del contrato",
    en: "Contract name"
  },
  descripcionDelContrato: {
    es: "Descripción del contrato",
    en: "Contract description"
  },
  clausulaEditadaConExito: {
    es: "Cláusula editada con éxito",
    en: "Clause Edited Successfully"
  },
  clausulaEliminadaConExito: {
    es: "Cláusula eliminada con éxito",
    en: "Clause removed successfully"
  },
  guardarCambios: {
    es: "Guardar",
    en: "Save changes"
  },
  editarDocumentoDeContrato: {
    es: "Editar Documento de Contrato",
    en: "Edit Contract Document"
  },
  indicacionesContrato: {
    es: '(*) Para poder utilizar las variables dentro de las cláusulas, simplemente escribe un "@" dentro de los inputs y aparecerá un cuadro (modal) con las opciones disponibles para seleccionar.',
    en: '(*) To use variables within clauses, simply type an "@" inside the inputs, and a modal will appear with the available options to choose from.'
  },
  horarioDeEntrada: {
    es: "Horario de entrada",
    en: "Check in times"
  },
  horarioDeSalida: {
    es: "Horario de salida",
    en: "Closing hour"
  },
  toleranciaMin: {
    es: "Tolerancia (min)",
    en: "Tolerance (min)"
  },
  horarioEditadoSatisfactoriamente: {
    es: "Horario editado satisfactoriamente.",
    en: "Schedule edited satisfactorily."
  },
  horarioCreadoSatisfactoriamente: {
    es: "Horario creado satisfactoriamente.",
    en: "Schedule created satisfactorily."
  },
  horario: {
    es: "Horario",
    en: "Schedule"
  },
  minutosDeTolerancia: {
    es: "Minutos de Tolerancia",
    en: "Minutes of Tolerance"
  },
  modulo: {
    es: "Módulo",
    en: "Module"
  },
  ruta: {
    es: "Ruta",
    en: "Route"
  },
  debeSeleccionarUnUsuario: {
    es: "Debe seleccionar un usuario",
    en: "You must select a user"
  },
  escribaParaBuscarUnUsuario: {
    es: "Escriba para buscar un usuario",
    en: "Type to search for a user"
  },
  buscar: {
    es: "Buscar",
    en: "Search"
  },
  verVistas: {
    es: "Ver vistas",
    en: "See views"
  },
  vistasPorPerfil: {
    es: "Vistas por Perfil",
    en: "Views by Profile"
  },
  vistasAdicionales: {
    es: "Vistas Adicionales",
    en: "Additional Views"
  },
  agregarVistaAdicional: {
    es: "Agregar Vista Adicional",
    en: "Add Additional View"
  },
  cargandoDatosEspereUnMomento: {
    es: "CARGANDO DATOS, ESPERE UN MOMENTO",
    en: "LOADING DATA, WAIT A MOMENT"
  },
  seleccioneUnUsuarioParaVerSusVistasDisponibles: {
    es: "SELECCIONE UN USUARIO PARA VER SUS VISTAS DISPONIBLES",
    en: "SELECT A USER TO SEE HIS AVAILABLE VIEWS"
  },
  agregarNuevasVistas: {
    es: "Agregar Nuevas Vistas",
    en: "Add New Views"
  },
  filtrarPorRoles: {
    es: "Filtrar por Roles",
    en: "Filter by Roles"
  },
  seleccionarPorModulos: {
    es: "Seleccionar por Modulos",
    en: "Select by Modules"
  },
  seleccioneAlMenosUnRol: {
    es: "SELECCIONE ALMENOS UN ROL",
    en: "SELECT AT LEAST ONE ROLE"
  },
  tieneAccesoaTodasLasVistasDelRolSeleccionado: {
    es: "TIENE ACCESO A TODAS LAS VISTAS DEL ROL SELECCIONADO",
    en: "HAS ACCESS TO ALL VIEWS OF THE SELECTED ROLE"
  },
  anadir: {
    es: "Añadir",
    en: "Add"
  },
  perfiles: {
    es: "Perfiles",
    en: "Profiles"
  },
  salarioMinimo: {
    es: "Salario Mínimo",
    en: "Minimun Salary"
  },
  salarioMaximo: {
    es: "Salario Maximo",
    en: "Maximun Salary"
  },
  debeInsertarElMontoDeLaEstructura: {
    es: "Debe insertar el monto de la estructura.",
    en: "You must insert the amount of the structure."
  },
  yaSeEncuentraSeleccionado: {
    es: "ya se encuentra seleccionado.",
    en: "is already selected."
  },
  noAgregadoNada: {
    es: "No ha agregado nada",
    en: "You have not added anything"
  },
  nombreDelPerfil: {
    es: "Nombre del perfil",
    en: "Profile name"
  },
  agregarEstructuras: {
    es: "Agregar Estructuras",
    en: "Add Structures"
  },
  opcional: {
    es: "Opcional",
    en: "Optional"
  },
  monto: {
    es: "Monto",
    en: "Amount"
  },
  montoDeEstructura: {
    es: "Monto de estructura",
    en: "Structure amount"
  },
  agregarEspecificacion: {
    es: "Agregar especificación",
    en: "Add specification"
  },
  listaDePerfiles: {
    es: "Lista de Perfiles",
    en: "Profile List"
  },
  detalleDelPerfil: {
    es: "Detalle por Perfil",
    en: "Profile Detail"
  },
  vistasDelPerfil: {
    es: "VISTAS DEL PERFIL",
    en: "PROFILE VIEWS"
  },
  listaDeUsuariosAsignadosAlPerfil: {
    es: "LISTA DE USUARIOS ASIGNADOS AL PERFIL",
    en: "LIST OF USERS ASSIGNED TO THE PROFILE"
  },
  asignarUsuariosAlPerfil: {
    es: "Asignar Usuarios al Perfil",
    en: "Assign Users to Profile"
  },
  configuracionDeLaEmpresa: {
    es: "Configuración de la Empresa",
    en: "Company Configuration"
  },
  configuracionDeAccesoPorIP: {
    es: "Configuración de Acceso por IP",
    en: "IP Access Configuration"
  },
  inicioDeAcceso: {
    es: "Inicio de Acceso",
    en: "Start of Access"
  },
  finDeAcceso: {
    es: "Fin de Acceso",
    en: "End of Access"
  },
  noTieneRestriccionPorHorario: {
    es: "No tiene restricción por horario",
    en: "It has no time restriction"
  },
  tieneRestriccionPorIpPeroNoPorHorario: {
    es: "Tiene restricción por ip, pero no por horario",
    en: "Has restriction by IP, but not by time"
  },
  tieneRestriccionPorIpYPorHorarioDeAcuerdoALaEmpresa: {
    es: "Tiene restricción por ip y  por horario, de acuerdo a la empresa",
    en: "It has a restriction by IP and by time, according to the company"
  },
  tieneRestriccionPorIpyPorHorarioDeAcuerdoAlTrabajador: {
    es: "Tiene restricción por ip y por horario, de acuerdo al trabajador",
    en: "It has a restriction by IP and by schedule, according to the worker"
  },
  tasaMutua: {
    es: "Tasa Mutua",
    en: "Mutual Rate"
  },
  mutuaYTasaMutua: {
    es: "Mutual y Tasa Mutual",
    en: "Mutual y Mutual Rate"
  },
  mutua: {
    es: "Mutua",
    en: "Mutual"
  },
  valorDeHorasExtras: {
    es: "Valor de Horas Extras",
    en: "Overtime Value"
  },
  horasExtras: {
    es: "Horas Extras",
    en: "Extra hours"
  },
  diaDeCierre: {
    es: "Día de Cierre",
    en: "Closing day"
  },
  deCadaMes: {
    es: "de cada mes",
    en: "of each month"
  },
  novedadesdelmes: {
    es: "Novedades del mes",
    en: "News of the month"
  },
  liquidaciones: {
    es: "Liquidaciones",
    en: "Closeouts"
  },
  reclutamiento: {
    es: "Reclutamiento",
    en: "Recruitment"
  },
  reportes: {
    es: "Reportes",
    en: "Reports"
  },
  politicasyprivacidad: {
    es: "Politicas y Privacidad",
    en: "Policies and Privacy"
  },
  asistencias: {
    es: "Asistencias",
    en: "Assists"
  },
  asistencia: {
    es: "Asistencia",
    en: "Assist"
  },
  inasistencias: {
    es: "Inasistencias",
    en: "Absences"
  },
  inasistencia: {
    es: "Inasistencia",
    en: "Absence"
  },
  anticipos: {
    es: "Anticipos",
    en: "Advances"
  },
  anticipo: {
    es: "Anticipo",
    en: "Advance"
  },
  solicitudes: {
    es: "Solicitudes",
    en: "Requests"
  },
  movimiento: {
    es: "Movimiento",
    en: "Movement"
  },
  movimientos: {
    es: "Movimientos",
    en: "Movements"
  },
  validacionesdeidentidad: {
    es: "Validaciones de identidad",
    en: "Identity validations"
  },
  inventariosdetrabajadores: {
    es: "Inventarios de Trabajadores",
    en: "Worker Inventories"
  },
  solicitudesgastos: {
    es: "Solicitudes Gastos",
    en: "Requests Expenses"
  },
  ipsaceptadas: {
    es: "IP's Aceptadas",
    en: "Accepted IP's"
  },
  ipsdenegadas: {
    es: "IP's Denegadas",
    en: "Denied IP's"
  },
  estructuralre: {
    es: "Estructura LRE",
    en: "LRE structure"
  },
  liquidacionactivatrabajadores: {
    es: "Liquidación activa - Trabajadores",
    en: "Active liquidation - Workers"
  },
  liquidaciondesueldo: {
    es: "Liquidación de sueldo",
    en: "Salary settlement"
  },
  liquidacionmasiva: {
    es: "Liquidación masiva",
    en: "Mass liquidation"
  },
  planillaremuneracionesmensuales: {
    es: "Planilla Remuneraciones Mensuales",
    en: "Monthly Remuneration Sheet"
  },
  liquidacionporproyectos: {
    es: "Liquidación por proyectos",
    en: "Settlement by projects"
  },
  generarliquidacionmasiva: {
    es: "Generar liquidación masiva",
    en: "Generate mass liquidation"
  },
  equipodereclutamiento: {
    es: "Equipo de reclutamiento",
    en: "Recruiting team"
  },
  fases: {
    es: "Fases",
    en: "Phases"
  },
  fase: {
    es: "Fase",
    en: "Phase"
  },
  convocatorias: {
    es: "Convocatorias",
    en: "Calls"
  },
  postulantes: {
    es: "Postulantes",
    en: "Applicants"
  },
  postulante: {
    es: "Postulante",
    en: "Applicant"
  },
  politicasyprivacidaddelsoftware: {
    es: "Políticas y Privacidad del Software",
    en: "Software Policies and Privacy"
  },
  reporteempleados: {
    es: "Reporte Empleados",
    en: "Employees Report"
  },
  reportegrupodeempresas: {
    es: "Reporte Grupo de Empresas",
    en: "Group of Companies Report"
  },
  reporteliquidaciones: {
    es: "Reporte Liquidaciones",
    en: "Settlements Report"
  },
  reporteliquidacionporproyectos: {
    es: "Reporte Liquidación por proyectos",
    en: "Settlement report by projects"
  },
  reportesolicitudes: {
    es: "Reporte solicitudes",
    en: "Report requests"
  },
  reportefaltastardanzasyhorasext: {
    es: "Reporte de Faltas, Tardanzas y Horas Ext.",
    en: "Absences, Tardies and Overtime Report"
  },
  reportegastos: {
    es: "Reporte Gastos",
    en: "Report Expenses"
  },
  reporteproductos: {
    es: "Reporte Productos",
    en: "Report Products"
  },
  tipoDeInasistencia: {
    es: "Tipo de inasistencia",
    en: "Type of absence"
  },
  tipoDeDescuento: {
    es: "Tipo de descuento",
    en: "Discount rate"
  },
  totalInasistencia: {
    es: "Total Inasistencia",
    en: "Total absence"
  },
  inasistenciasPorEmpleado: {
    es: "Inasistencias por empleado",
    en: "Absences per employee"
  },
  inasistenciaDe: {
    es: "Inasistencia de",
    en: "Absence of"
  },
  asegurateDeAgregarLaInasistenciaDeAcuerdoALaSolicitudDe: {
    es: "Asegurese de agregar la(s) inasistencia(s) de acuerdo a la solicitud de",
    en: "Be sure to add the absence(s) according to the request for"
  },
  laCualCorrespondeDeLaFecha: {
    es: "; la cual corresponde de la fecha",
    en: "; which corresponds to the date"
  },
  asegureseQueLaFechaDeInicioyFinSeaMenorQueLaFechaActual: {
    es: "Asegurese que la fecha de inicio y fin, sean menor que la fecha actual.",
    en: "Make sure that the start and end dates are less than the current date."
  },
  asegureseQueLaFechaDeFinSeaMenorAlUltimoDiaDelMesActual: {
    es: "Asegurese que la fecha de fin sea menor al ultimo dia del mes actual",
    en: "Make sure the end date is less than the last day of the current month"
  },
  elMesDeLasFechasSeleccionadasDebeSerIgualAlDelMesActual: {
    es: "El mes de las fechas seleccionadas debe ser igual al del mes actual",
    en: "El mes de las fechas seleccionadas debe ser igual al del mes actual"
  },
  asistenciasPorEmpleado: {
    es: "Asistencias por empleado",
    en: "Attendances per employee"
  },
  verAsistencias: {
    es: "Ver asistencias",
    en: "See assists"
  },
  asistenciaDe: {
    es: "Asistencias de",
    en: "Assists of"
  },
  filtrar: {
    es: "Filtrar",
    en: "Filter"
  },
  horaIngreso: {
    es: "Hora ingreso",
    en: "Entry time"
  },
  inicioRefrigerio: {
    es: "Inicio refrigerio",
    en: "Home snack"
  },
  finRefrigerio: {
    es: "Fin refrigerio",
    en: "End of snack"
  },
  horaSalida: {
    es: "Hora Salida",
    en: "Departure Time"
  },
  horasExtrasMin: {
    es: "Horas Extras (min)",
    en: "Overtime (min)"
  },
  tardanzasMin: {
    es: "Tardanzas (min)",
    en: "Tardies (min)"
  },
  estadoDeSincronizacion: {
    es: "Estado de Sincronización",
    en: "Synchronization Status"
  },
  asegureseDeQueLasHorasSeEncuentrenDeManeraAscendente: {
    es: "Asegurese de que las horas se encuentren de manera ascendente.",
    en: "Make sure the hours are in ascending order."
  },
  asegureseQueLaFechaInicialSeaMenorQueLaFinal: {
    es: "Asegúrese que la fecha inicial sea menor que la final.",
    en: "Make sure the start date is less than the end date."
  },
  seleccioneAlMenosUnEmpleado: {
    es: "Seleccione al menos un empleado.",
    en: "Select at least one employee."
  },
  elMesDeLaFechaSeleccionadaDebeSerIgualAlDelMesActual: {
    es: "El mes de la fecha seleccionada debe ser igual al del mes actual",
    en: "The month of the selected date must be the same as the current month"
  },
  asegureseQueLaFechaSeleccionadaSeaMenorQueLaFechaActual: {
    es: "Asegúrese que la fecha seleccionada sea menor que la fecha actual.",
    en: "Make sure the selected date is less than the current date"
  },
  empleados: {
    es: "Empleados",
    en: "Employees"
  },
  noPuedeEliminarAsistenciasQueNoPertenecenAlMesActual: {
    es: "No puede eliminar asistencias que no pertenecen al mes actual",
    en: "You cannot delete attendances that do not belong to the current month"
  },
  estaSeguroDeEliminarEstaInasistencia: {
    es: "¿Está seguro de eliminar esta Inasistencia?",
    en: "Are you sure to delete this Absence?"
  },
  asistenciaEliminada: {
    es: "Asistencia eliminada.",
    en: "Assistance removed."
  },
  licencia: {
    es: "Licencia",
    en: "License"
  },
  anticiposDe: {
    es: "Anticipos de",
    en: "Advances of"
  },
  misanticipos: {
    es: "Mis Anticipos",
    en: "My advances"
  },
  valor: {
    es: "Valor",
    en: "Value"
  },
  noPuedeSolicitarAnticiposComuniqueseConSuAdministrador: {
    es: "No puede solicitar anticipos, comuníquese con su Administrador",
    en: "You cannot request advances, contact your Administrator"
  },
  asegureseQueTengaAsignadoDescuentoPorAnticipos: {
    es: "Asegurese que tenga asignado descuento por anticipos",
    en: "Make sure you have an advance discount assigned"
  },
  elEmpleadoNoTienePermisoParaSolicitarAnticipos: {
    es: "El empleado no tiene permiso para solicitar anticipos",
    en: "The employee does not have permission to request advances"
  },
  valorDeAnticipo: {
    es: "Valor de anticipo",
    en: "Advance value"
  },
  estadoDePeticion: {
    es: "Estado de petición",
    en: "Request status"
  },
  peticion: {
    es: "Petición",
    en: "Request"
  },
  aceptarPeticion: {
    es: "Aceptar Petición",
    en: "Accept Request"
  },
  rechazarPeticion: {
    es: "Rechazar Petición",
    en: "Reject Request"
  },
  laSolicitudYaHaSidoRespondida: {
    es: "La solicitud ya ha sido respondida",
    en: "The request has already been answered"
  },
  solicitudesDeEmpleados: {
    es: "Solicitudes de empleados",
    en: "Employee requests"
  },
  missolicitudes: {
    es: "Mis solicitudes",
    en: "My requests"
  },
  tipoDeSolicitud: {
    es: "Tipo de Solicitud",
    en: "Type of request"
  },
  fechaDeSolicitud: {
    es: "Fecha de Solicitud",
    en: "Application date"
  },
  motivo: {
    es: "Motivo",
    en: "Reason"
  },
  observacionDelAdmin: {
    es: "Observación del Admin",
    en: "Admin Remark"
  },
  noSeEncontraronSolicitudes: {
    es: "No se encontraron solicitudes.",
    en: "No requests found."
  },
  verSolicitudes: {
    es: "Ver Solicitudes",
    en: "See Requests"
  },
  verMisSolicitudes: {
    es: "Ver mis Solicitudes",
    en: "See my Requests"
  },
  movimientosPorEmpleado: {
    es: "Movimientos por empleado",
    en: "Movements per employee"
  },
  fechaDeRegistro: {
    es: "Fecha de Registro",
    en: "Registration date"
  },
  tipoMovimiento: {
    es: "Tipo movimiento",
    en: "Movement type"
  },
  movimientosDe: {
    es: "Movimientos de",
    en: "Movement of"
  },
  validacionesDeIdentidad: {
    es: "Validaciones de Identidad",
    en: "Identity Validations"
  },
  verValidaciones: {
    es: "Ver Validaciones",
    en: "See Validations"
  },
  noHayDatosDisponibles: {
    es: "No hay datos disponibles",
    en: "No data available"
  },
  loSientoNoHayDatosParaMostrar: {
    es: "Lo siento, no hay datos para mostrar.",
    en: "Sorry, there is no data to display."
  },
  cerrar: {
    es: "Cerrar",
    en: "Close"
  },
  solicitudesDeGastos: {
    es: "Solicitudes de Gastos",
    en: "Expense Requests"
  },
  conceptoDeDetalle: {
    es: "Concepto de Detalle",
    en: "Concept of Detail"
  },
  verDetalleDelGasto: {
    es: "Ver detalle del gasto",
    en: "See expense detail"
  },
  detalleDelGasto: {
    es: "Detalle del gasto",
    en: "Expense detail"
  },
  ipsAceptadas: {
    es: "IP's Aceptadas",
    en: "Accepted IP's"
  },
  laEmpresaNoCuentaConRestriccionPorIp: {
    es: "LA EMPRESA NO CUENTA CON RESTRICCIÓN POR IP",
    en: "THE COMPANY DOES NOT HAVE RESTRICTION BY IP"
  },
  ipsDenegadas: {
    es: "IP's Denegadas",
    en: "Denied IP's"
  },
  horariosPorEmpleado: {
    es: "Horario por empleados",
    en: "Hours by employees"
  },
  horariosDeLaEmpresa: {
    es: "Horarios de la Empresa",
    en: "Company Hours"
  },
  verHorario: {
    es: "Ver horario",
    en: "See Hour"
  },
  detalleDeHorario: {
    es: "Detalle de Horario",
    en: "Detail Schedules"
  },
  horarioDe: {
    es: "Horario de",
    en: "Hour of"
  },
  detalleDeHorariosDe: {
    es: "Detalle de Horarios de",
    en: "Detail of Schedules"
  },
  diasLaborales: {
    es: "Dias laborales",
    en: "Business days"
  },
  inicioDeColacion: {
    es: "Inicio de Colación",
    en: "Start of Snack"
  },
  finDeColacion: {
    es: "Fin de Colación",
    en: "End of Snack"
  },
  horasALaSemana: {
    es: "Horas a la Semana",
    en: "Hours per week"
  },
  horasSemanales: {
    es: "Horas Semanales",
    en: "Hours weeks"
  },
  estructuraDeLiquidacion: {
    es: "Estructura de Liquidación",
    en: "Settlement Structure"
  },
  buscarEstructura: {
    es: "Buscar Estructura",
    en: "Search Structure"
  },
  agregarEstructuraOpcional: {
    es: "Agregar Estructura Opcional",
    en: "Add Optional Structure"
  },
  conceptoDT: {
    es: "Concepto DT",
    en: "TD concept"
  },
  otraDescripcion: {
    es: "Otra descripción",
    en: "Other description"
  },
  vigenciaIndefinida: {
    es: "Vigencia indefinida",
    en: "Indefinite Validity"
  },
  debeSeleccionarUnaEstructura: {
    es: "Debe seleccionar una estructura",
    en: "You must select a structure"
  },
  estructuras: {
    es: "Estructuras",
    en: "Structures"
  },
  alias: {
    es: "Alias",
    en: "Alias"
  },
  aliasDeLaEstructua: {
    es: "Alias de la estructura",
    en: "Structure aliases"
  },
  validez: {
    es: "Validez",
    en: "Validity"
  },
  indefinido: {
    es: "Indefinido",
    en: "Undefined"
  },
  noIndefinido: {
    es: "No Indefinido",
    en: "Not indefinite"
  },
  estructurasOpcionales: {
    es: "Estructuras Opcionales",
    en: "Optional Structures"
  },
  estructurasObligatorias: {
    es: "Estructuras Obligatorias",
    en: "Mandatory Structures"
  },
  fechaVigencia: {
    es: "Fecha Vigencia",
    en: "Effective date"
  },
  fechaRegistro: {
    es: "Fecha Registro",
    en: "Registration date"
  },
  liquidacionDeSueldoPorTrabajadores: {
    es: "Liquidacion de sueldos por trabajadores",
    en: "Settlement of wages for workers"
  },
  subirPlantilla: {
    es: "Subir Plantilla",
    en: "Upload Template"
  },
  descargarPlantilla: {
    es: "Descargar Plantilla",
    en: "Download Template"
  },
  liquidacionPara: {
    es: "Liquidación para",
    en: "Clearance for"
  },
  generar: {
    es: "Generar",
    en: "Generate"
  },
  reprocesar: {
    es: "Reprocesar",
    en: "Reprocess"
  },
  verLiquidación: {
    es: "Ver liquidación",
    en: "See settlement"
  },
  crearNueva: {
    es: "Crear nueva",
    en: "Create new"
  },
  vuelvaaGenerarLaLiquidacion: {
    es: "Vuelva a generar la liquidación.",
    en: "Regenerate the settlement."
  },
  elPeriodoActual: {
    es: "El Periodo Actual",
    en: "The Current Period"
  },
  noSeEncuentraDisponible: {
    es: "No se Encuentra Disponible",
    en: "Not Available"
  },
  elPeriodoActualNoSeEncuentraDisponible: {
    es: "El periodo actual no se encuentra disponible",
    en: "The current period is not available"
  },
  liquidacionesMasivas: {
    es: "Liquidaciones Masivas",
    en: "Mass Liquidations"
  },
  seleccionePeriodoyMesParaGenerarRemuneracionMasiva: {
    es: "Seleccione periodo y mes para generar remuneración masiva",
    en: "Select period and month to generate massive remuneration."
  },
  noHayRemuneracionGeneradas: {
    es: "No hay remuneraciones generadas",
    en: "There are no remunerations generated"
  },
  planillasDeRemuneraciones: {
    es: "Planillas de Remuneraciones",
    en: "Salary Sheets"
  },
  previredMesyPeriodo: {
    es: "Previred - Mes y Periodo",
    en: "Previred - Month and Period"
  },
  ir: {
    es: "Ir",
    en: "Go"
  },
  libroElectronicoDeRemuneraciones: {
    es: "Libro Electrónico de Remuneraciones",
    en: "Electronic Remuneration Book"
  },
  certificadoDelTrabajador: {
    es: "Certificado del Trabajador",
    en: "Worker's Certificate"
  },
  seleccioneUnTrabajador: {
    es: "Seleccione un trabajador",
    en: "Select a worker"
  },
  generaDj: {
    es: "Genera DJ",
    en: "Generate DJ"
  },
  subtotalMutual: {
    es: "Subtotal Mutual",
    en: "Mutual Subtotal"
  },
  institucion: {
    es: "Institución",
    en: "Institution"
  },
  totalaPagar: {
    es: "Total a Pagar",
    en: "Total to pay"
  },
  mutual: {
    es: "Mutual",
    en: "Mutual"
  },
  subtotalIsapre: {
    es: "Subtotal ISAPRE",
    en: "ISAPRE subtotal"
  },
  subTotalAFP: {
    es: "Subtotal AFP",
    en: "AFP subtotal"
  },
  descargar: {
    es: "Descargar",
    en: "Download"
  },
  verMontosPrevired: {
    es: "Ver montos Previred",
    en: "See Previred amounts"
  },
  previredMontosGenerales: {
    es: "Previred montos generales",
    en: "General amounts anticipated"
  },
  previredFormatoCsv: {
    es: "Previred formato CSV",
    en: "Expected CSV format"
  },
  previredFormatoExcel: {
    es: "Previred formato Excel",
    en: "Expected Excel format"
  },
  centroDeCostosSucursalAgenciaObraRegion: {
    es: "Centro de Costos, Sucursal, Agencia,Obra, Región",
    en: "Cost Center, Branch, Agency, Project, Region"
  },
  dvPagadoraSubsidio: {
    es: "DV Pagadora Subsidio",
    en: "DV Subsidy Payer"
  },
  rutPagadoraSubsidio: {
    es: "Rut Pagadora Subsidio",
    en: "Rut Subsidy Payer"
  },
  aporteEmpleadorSeguroCesantia: {
    es: "Aporte Empleador Seguro Cesantía",
    en: "Employer Contribution Unemployment Insurance"
  },
  aporteTrabajadorSeguroCesantia: {
    es: "Aporte Trabajador Seguro Cesantía",
    en: "Worker Contribution Unemployment Insurance"
  },
  rentaImponibleSeguroCesantia: {
    es: "Renta Imponible Seguro Cesantía (Informar Renta Total Imponible)",
    en: "Taxable Income Unemployment Insurance (Inform Total Taxable Income)"
  },
  cotizacionAccidenteDelTrabajo: {
    es: "Cotización Accidente del Trabajo (MUTUAL)",
    en: "Work Accident Quote (MUTUAL)"
  },
  sucursalParaPagoMutual: {
    es: "Sucursal para pago Mutual",
    en: "Branch for Mutual payment"
  },
  rentaImponibleMutual: {
    es: "Renta Imponible Mutual",
    en: "Mutual Taxable Income"
  },
  codigoMutualidad: {
    es: "Código Mutualidad",
    en: "Mutual Code"
  },
  codigoDeSucursalUsoFuturo: {
    es: "Código de Sucursal (Uso Futuro)",
    en: "Branch Code (Future Use)"
  },
  libroDtFormatoCsv: {
    es: "Libro DT formato csv",
    en: "DT book csv format"
  },
  libroDtFormatoExcelSinCabecera: {
    es: "Libro DT formato excel sin cabecera",
    en: "Excel format DT book without header"
  },
  libroDtFormatoExcelConCabecera: {
    es: "Libro DT formato excel con cabecera",
    en: "Excel format DT book with header"
  },
  libroRemuneracionesElectronico: {
    es: "Libro Remuneraciones Electronico",
    en: "Electronic Remuneration Book"
  },
  liquidacionesPorProyecto: {
    es: "Liquidaciones por Proyecto",
    en: "Settlements by Project"
  },
  equipoDeReclutamiento: {
    es: "Equipo de reclutamiento",
    en: "Recruiting team"
  },
  especificacionDeOfertasLaborales: {
    es: "Especificación de ofertas laborales"
  },
  ofertasLaborales: {
    es: "Ofertas laborales",
    en: "Job offers"
  },
  modo: {
    es: "Modo",
    en: "Mode"
  },
  tipoDeDia: {
    es: "Tipo de día",
    en: "Type of day"
  },
  remoto: {
    es: "Remoto",
    en: "Remote"
  },
  presencial: {
    es: "Presencial",
    en: "Face to face"
  },
  hibrido: {
    es: "Híbrido",
    en: "Hybrid"
  },
  noHaAgregadoNada: {
    es: "No ha agregado nada",
    en: "You have not added anything"
  },
  ofertaLaboral: {
    es: "Oferta laboral",
    en: "Job offer"
  },
  inicioDePostulacion: {
    es: "Inicio de postulación",
    en: "Start of application"
  },
  finDePostulacion: {
    es: "Fin de postulación",
    en: "End of application"
  },
  vacantesDisponibles: {
    es: "Vacantes disponibles",
    en: "Vacancies available"
  },
  agregarEspecificaciones: {
    es: "Agregar Especificaciones",
    en: "Add Specifications"
  },
  nombreDeLaEspecificacion: {
    es: "Nombre de la especificación",
    en: "Specification Name"
  },
  certificado: {
    es: "Certificado",
    en: "Certificate"
  },
  constancia: {
    es: "Constancia",
    en: "Constancy"
  },
  especificaciones: {
    es: "Especificaciones",
    en: "Specifications"
  },
  usuarioAsignado: {
    es: "Usuario Asignado",
    en: "Assigned User"
  },
  filtrarPorPerfil: {
    es: "Filtrar por perfil",
    en: "Filter by profile"
  },
  fasesDelReclutamiento: {
    es: "Fases del reclutamiento",
    en: "Recruitment phases"
  },
  seleccioneUnArchivo: {
    es: "Seleccione un Archivo",
    en: "Select a File"
  },
  tipoDeAfp: {
    es: "Tipo de AFP",
    en: "Type of AFP"
  },
  codigoSalud: {
    es: "Código Salud",
    en: "Health Code"
  },
  nivelDeEducacion: {
    es: "Nivel de Educación",
    en: "Education Level"
  },
  situacionMilitar: {
    es: "Situación Militar",
    en: "Military situation"
  },
  profesion: {
    es: "Profesión",
    en: "Profession"
  },
  nacionalidad: {
    es: "Nacionalidad",
    en: "Nationality"
  },
  pensioner: {
    es: "Pensioner",
    en: "Pensioner"
  },
  curriculum: {
    es: "Curriculum",
    en: "Resume"
  },
  reporteDeEmpleados: {
    es: "Reporte de Empleados",
    en: "Employee Report"
  },
  seEncontro: {
    es: "Se encontró",
    en: "It was found"
  },
  empleadosConLiquidacionGeneradaEnElPeriodoyMesSeleccionado: {
    es: "Empleado(s) con liquidación generada en el periodo y mes seleccionado.",
    en: "Employee(s) with settlement generated in the selected period and month."
  },
  totalEmpleados: {
    es: "Total empleados",
    en: "Total employees"
  },
  totalEmpleadosConContrato: {
    es: "Total empleados con contrato",
    en: "Total employees with contract"
  },
  descargarExcel: {
    es: "Descargar Excel",
    en: "Download excel"
  },
  tiposDeContrato: {
    es: "Tipos de contrato",
    en: "Contract types"
  },
  cantidadEmpleados: {
    es: "Cantidad Empleados",
    en: "Number of employees"
  },
  totalPago: {
    es: "Total Pago",
    en: "Total Payment"
  },
  noSeEncontraronEmpleadosConLiquidacion: {
    es: "No se encontraron empleados con liquidación",
    en: "No severance employees found"
  },
  tiposDeGratificaciones: {
    es: "Tipo de gratificaciones",
    en: "Type of gratuities"
  },
  tramosFamiliares: {
    es: "Tramos Familiares",
    en: "Family Tranches"
  },
  reporteDeGrupoDeEmpresas: {
    es: "Reporte de Grupo de Empresa",
    en: "Company Group Report"
  },
  empleadosPorLiquidacion: {
    es: "Empleados con Liquidación",
    en: "Employees with Liquidation"
  },
  pagoAfp: {
    es: "Pago AFP",
    en: "AFP payment"
  },
  pagoSalud: {
    es: "Pago Salud",
    en: "Health Payment"
  },
  pagototal: {
    es: "Pago Total",
    en: "Full payment"
  },
  noSeEncontraronDatos: {
    es: "No se encontraron datos",
    en: "No data found"
  },
  reportePorLiquidaciones: {
    es: "Reporte por Liquidaciones",
    en: "Report for Settlements"
  },
  tipoDeCargos: {
    es: "Tipos de cargos",
    en: "Types of charges"
  },
  reporteDeLiquidacionesPorProyecto: {
    es: "Reporte de liquidaciones por proyecto",
    en: "Settlement report by project"
  },
  proyectosEnElPeriodoyMesSeleccionado: {
    es: "Proyecto(s) en el periodo y mes seleccionado.",
    en: "Project(s) in the selected period and month."
  },
  totalProyectos: {
    es: "Total Proyectos",
    en: "Total Projects"
  },
  descripcionDelProyecto: {
    es: "Descripción del Proyecto",
    en: "Project description"
  },
  costoTotalDelProyecto: {
    es: "Costo total del proyecto",
    en: "Total project cost"
  },
  detalleDeParticipacionEnElPeriodoSeleccionado: {
    es: "Detalle de participación en el periodo seleccionado",
    en: "Detail of participation in the selected period"
  },
  porcentajeDeParticipacion: {
    es: "Porcentaje de participación",
    en: "Participation Percentage"
  },
  noSeEncontroParticipacionParaElPeriodoyMesSeleccionado: {
    es: "No se encontró participación para el periodo y mes seleccionado.",
    en: "No participation was found for the selected period and month."
  },
  reporteDeSolicitud: {
    es: "Reporte de solicitud",
    en: "Application report"
  },
  seEncontraron: {
    es: "Se encontraron",
    en: "Were found"
  },
  empleadosRegistrados: {
    es: "Empleados registrados",
    en: "Registered Employees"
  },
  nombresEmpleados: {
    es: "Nombres Empleados",
    en: "Employee Names"
  },
  estados: {
    es: "Estados",
    en: "States"
  },
  solicitudesEnElPeriodoyMesSeleccionado: {
    es: "Solicitud(es) en el periodo y mes seleccionado.",
    en: "Request(s) in the period and month selected."
  },
  estadoDeSolicitud: {
    es: "Estado de solicitud",
    en: "application status"
  },
  pendientes: {
    es: "Pendientes",
    en: "Earrings"
  },
  aceptados: {
    es: "Aceptados",
    en: "Accepted"
  },
  rechazados: {
    es: "Rechazados",
    en: "Rejected"
  },
  debeCompletarTodosLosCamposParaRealizarElFiltrado: {
    es: "Debe completar todos los campos para realizar el filtrado",
    en: "You must complete all the fields to filter"
  },
  reporteDeTardanzasInasistenciasyHorasExtras: {
    es: "Reporte de tardanzas, inasistencias y horas extras",
    en: "Report of tardies, absences and extra hours"
  },
  extrasMin: {
    es: "Extras (min)",
    en: "Extras (min)"
  },
  fechasInasistencias: {
    es: "Fechas Inasistencias",
    en: "Dates Absences"
  },
  crearInasistencias: {
    es: "Crear Inasistencias",
    en: "Create Absences"
  },
  solicitudesPendientes: {
    es: "Solicitudes Pendientes",
    en: "Awaiting requests"
  },
  solicitudesAprobadas: {
    es: "Solicitudes Aprobadas",
    en: "Approved Requests"
  },
  solicitudesRechazadas: {
    es: "Solicitudes Rechazadas",
    en: "Rejected Applications"
  },
  tipoDeGasto: {
    es: "Tipo de gasto",
    en: "Expense type"
  },
  gastoDelEmpleado: {
    es: "Gasto del Empleado",
    en: "Employee Expense"
  },
  gastoDeLaEmpresa: {
    es: "Gasto de la Empresa",
    en: "Company Expense"
  },
  gastoTotal: {
    es: "Gasto total",
    en: "Total cost"
  },
  rendicionDeGastos: {
    es: "Rendición de Gastos",
    en: "Expense report"
  },
  detalleDeLaSolicitud: {
    es: "Detalle de la solicitud",
    en: "Request detail"
  },
  noHaySolicitudesPendientes: {
    es: "No hay solicitudes pendientes",
    en: "There are no pending requests"
  },
  noHaySolicitudesAprobadas: {
    es: "No hay solicitudes aprobadas",
    en: "No approved requests"
  },
  noHaySolicitudesRechazadas: {
    es: "No hay solicitudes rechazadas",
    en: "No rejected applications"
  },
  tiposdegasto: {
    es: "Tipos de Gastos",
    en: "Types of Expenses"
  },
  errorAlEditarElTipoDeGasto: {
    es: "Error al editar el Tipo de Gasto",
    en: "Error when editing the Type of Expense"
  },
  errorAlCrearElTipoDeGasto: {
    es: "Error al crear el Tipo de Gasto",
    en: "Error when creating the Type of Expense"
  },
  errorAlEliminarTipoDeGasto: {
    es: "Error al eliminar el Tipo de Gasto",
    en: "Error when deleting the Type of Expense"
  },
  nombreDelTipoDeGasto: {
    es: "Nombre del tipo de gasto",
    en: "Expense type name"
  },
  eliminarTipoDeGasto: {
    es: "Eliminar tipo de gasto",
    en: "Delete expense type"
  },
  tipoDeMovimiento: {
    es: "Tipo  de Movimiento",
    en: "Movement type"
  },
  fechaDeRetornoAcordado: {
    es: "Fecha de Retorno Acordado",
    en: "Agreed Return Date"
  },
  fechaDeCompra: {
    es: "Fecha de Compra",
    en: "Date of purchase"
  },
  netCost: {
    es: "Costo",
    en: "Cost"
  },
  estadoInicial: {
    es: "Estado Inicial",
    en: "Initial state"
  },
  duracionEstimada: {
    es: "Duración Estimada",
    en: "Estimated duration"
  },
  listaDeMateriales: {
    es: "Lista de Materiales",
    en: "Material's list"
  },
  comprasPorMaterial: {
    es: "Compras por Material",
    en: "Purchases by Material"
  },
  movimientosDeLaCompra: {
    es: "Movimientos de la Compra",
    en: "Purchase Movements"
  },
  materiales: {
    es: "Materiales",
    en: "Materials"
  },
  buscarMaterial: {
    es: "Buscar material",
    en: "search material"
  },
  compras: {
    es: "Compras",
    en: "Shopping"
  },
  material: {
    es: "Material",
    en: "Material"
  },
  datosDelMaterial: {
    es: "Datos del material",
    en: "Material data"
  },
  marca: {
    es: "Marca",
    en: "Brand"
  },
  darDeBajaAlMaterial: {
    es: "Dar de baja al material",
    en: "Dismiss the material"
  },
  darDeAltaAlMaterial: {
    es: "Dar de alta el material",
    en: "Discharge the material"
  },
  datosDeLaCompra: {
    es: "Datos de la Compra",
    en: "Purchase information"
  },
  precioDeCompra: {
    es: "Precio de Compra",
    en: "Purchase price"
  },
  datosDelMovimiento: {
    es: "Datos del Movimiento",
    en: "Movement Data"
  },
  fechaDeRegresoAcordado: {
    es: "Fecha de Regreso Acordado",
    en: "Agreed Return Date"
  },
  queTipoDeMovimientoRealizaraHoy: {
    es: "¿Qué tipo de movimiento realizarás hoy?",
    en: "What kind of movement will you make today?"
  },
  cambiarSucursal: {
    es: "Cambiar Sucursal",
    en: "Change Branch"
  },
  beneficio: {
    es: "Beneficio",
    en: "Benefit"
  },
  reembolso: {
    es: "Reembolso",
    en: "Refund"
  },
  prestamosPorEmpleado: {
    es: "Préstamos por Empleado",
    en: "Employee Loans"
  },
  prestamos: {
    es: "Préstamos",
    en: "Loans"
  },
  prestamosDe: {
    es: "Préstamos de",
    en: "Loans from"
  },
  tieneUnLimiteDePrestamoDe: {
    es: "Tiene un límite de prestamo de",
    en: "It has a loan limit of"
  },
  montoMaximoaAprobar: {
    es: "Monto Máximo a Aprobar",
    en: "Maximum Amount to Approve"
  },
  debeSeleccionarUnPerido: {
    es: "Debe seleccionar un Periodo",
    en: "You must select a Period"
  },
  debeSeleccionarUnMes: {
    es: "Debe seleccionar un Mes",
    en: "You must select a month"
  },
  debeSeleccionarUnAnoyMesMayoresoIgualesAlActual: {
    es: "Debe seleccionar un año y mes mayores o iguales al actual",
    en: "You must select a year and month greater than or equal to the current one"
  },
  revisaTusCuotasHasSuperadoElMontoMaximoPorLey: {
    es: "Revisa tus cuotas, has superado el monto máximo por ley",
    en: "Check your quotas, you have exceeded the maximum amount by law"
  },
  revisaTusCuotasHayMontosIgualaCero: {
    es: "Revisa tus cuotas, hay montos igual a 0",
    en: "Check your quotas, there are amounts equal to 0"
  },
  yaSeDisponibilizoLasPosiblesCuotas: {
    es: "Ya se disponibilizo las posibles cuotas",
    en: "The possible quotas are already available"
  },
  porCuotaSegunLeyDeProteccionAlTrabajador: {
    es: "por cuota segun Ley de Protección al Trabajador",
    en: "by quota according to the Worker Protection Law"
  },
  disponibilizarPosiblesFormasDePago: {
    es: "Disponibilizar Posibles Formas de Pago",
    en: "Make Available Possible Forms of Payment"
  },
  periodoDeInicioDePago: {
    es: "Periodo de Inicio de Pago",
    en: "Payment Start Period"
  },
  mesDeInicioDePago: {
    es: "Mes de inicio de pago",
    en: "Payment start month"
  },
  agregarFila: {
    es: "Agregar Fila",
    en: "Add Row"
  },
  nCuotas: {
    es: "N° Cuotas",
    en: "No. Quotas"
  },
  cuotas: {
    es: "Cuotas",
    en: "Quotas"
  },
  debesDeSeleccionarUnaCuota: {
    es: "Debes de seleccionar una cuota",
    en: "You must select a quota"
  },
  aunNoSeDisponibilizaLasCuotas: {
    es: "Aún no se disponibiliza las cuotas",
    en: "Quotas not yet available"
  },
  eligeLaCuotaDePago: {
    es: "Elige la cuota de pago",
    en: "Choose the payment rate"
  },
  detallesDeLaCuotas: {
    es: "Detalle de la cuotas",
    en: "Details of the quotas"
  },
  seleccionado: {
    es: "Seleccionado",
    en: "Selected"
  },
  seleccionar: {
    es: "Seleccionar",
    en: "Select"
  },
  misNotificaciones: {
    es: "Mis notificaciones",
    en: "My notifications"
  },
  haceUnosSegundos: {
    es: "Hace unos Segundos",
    en: "A few seconds ago"
  },
  misprestamos: {
    es: "Mis Préstamos",
    en: "My Loans"
  },
  puedesSolicitarComoMaximoUnPrestamoDe: {
    es: "Puedes solicitar como máximo un prestamo de",
    en: "You can request a maximum loan of"
  },
  noTienesDisponibleSolicitarPrestamo: {
    es: "No tienes disponible solicitar préstamo",
    en: "You are not available to apply for a loan"
  },
  tienesDisponibilidadDeSolicitarHasta: {
    es: "Tienes disponibilidad de solicitar hasta",
    en: "You are available to request up to"
  },
  montoaSolicitar: {
    es: "Monto a Solicitar",
    en: "Amount to Request"
  },
  misgastos: {
    es: "Mis Gastos",
    en: "My expenses"
  },
  concepto: {
    es: "Concepto",
    en: "Concept"
  },
  tipoDeReporte: {
    es: "Tipo de Reporte",
    en: "Report Type"
  },
  gastoEmpresa: {
    es: "Gasto Empresa",
    en: "Company Expense"
  },
  estadoDeRevision: {
    es: "Estado de Revisión",
    en: "Review Status"
  },
  debeSeleccionarElAnoyMesActual: {
    es: "Debe seleccionar el año y mes actual",
    en: "You must select the current year and month"
  },
  gastoCreadoSatisfactoriamente: {
    es: "Gasto creado satisfactoriamente.",
    en: "Expense created successfully."
  },
  gasto: {
    es: "Gasto",
    en: "Spent"
  },
  nDeReportes: {
    es: "N° de reportes",
    en: "No. of reports"
  },
  nDetalle: {
    es: "N° Detalle",
    en: "No. Detail"
  },
  debeAgregarUnDocumento: {
    es: "Debe agregar un documento",
    en: "You must add a document"
  },
  solicitudDeGastoEliminado: {
    es: "Solicitud de gasto eliminado.",
    en: "Expense request removed."
  },
  eliminarGasto: {
    es: "Eliminar Gasto",
    en: "Delete Expense"
  },
  noSePuedeTomarAccionesSolicitudNoRealizada: {
    es: "¡No se puede tomar acciones!. La solicitud no fue realizada en el periodo actual.",
    en: "Actions cannot be taken! The request was not made in the current period."
  },
  laSolicitudHaSidoAceptada: {
    es: "La solicitud ha sido aceptada. El monto del gasto se sumo a la estructura opcional del trabajador.",
    en: "The request has been accepted. The amount of the expense was added to the optional structure of the worker."
  },
  noSePuedeRealizarAccionesRechazada: {
    es: "No puede realizar acciones. La solicitud ha sido rechazada.",
    en: "You cannot perform actions. The request has been rejected."
  },
  detalleEditadoSatisfactoriamente: {
    es: "Detalle editado satisfactoriamente.",
    en: "Detail edited successfully."
  },
  aceptarRechazarSolicitud: {
    es: "Aceptar o Rechazar Solicitud",
    en: "Accept or Reject Request"
  },
  editarDetalle: {
    es: "Editar Detalle",
    en: "Edit Detail"
  },
  agregarDetalle: {
    es: "Agregar Detalle",
    en: "Add Detail"
  },
  coberturaEmpresa: {
    es: "Cobertura Empresa",
    en: "Company Coverage"
  },
  estadoDelDetalle: {
    es: "Estado del Detalle",
    en: "Detail Status"
  },
  habilitado: {
    es: "Habilitado",
    en: "Enabled"
  },
  deshabilitado: {
    es: "Deshabilitado",
    en: "Disabled"
  },
  actualDocumento: {
    es: "Actual Documento",
    en: "Current Document"
  },
  nuevoDocumento: {
    es: "Nuevo Documento",
    en: "New Document"
  },
  aceptarSolicitud: {
    es: "Aceptar Solicitud",
    en: "Accept request"
  },
  rechazarSolicitud: {
    es: "Rechazar Solicitud",
    en: "Reject Request"
  },
  seleccioneUnaEstructura: {
    es: "Seleccione una Estructura",
    en: "Select a Structure"
  },
  laOperacionSeRealizoConExito: {
    es: "La operación se realizó con éxito.",
    en: "The operation was carried out successfully."
  },
  agregarMontoaEstructura: {
    es: "Agregar Monto a Estructura",
    en: "Add Amount to Structure"
  },
  nombreEmpleado: {
    es: "Nombre Empleado",
    en: "Employee Name"
  },
  estructura: {
    es: "Estructura",
    en: "Structure"
  },
  noSeEncontroLaEstructura: {
    es: "No se encontro la estructura",
    en: "Structure not found"
  },
  confirmar: {
    es: "Confirmar",
    en: "Confirm"
  },
  elEmpleadoNoTieneAsignadoLaEstructuraOpcionalaLaCualPerteneceElTipoDeGasto: {
    es: "El empleado no tiene asignado la estructura opcional a la cual pertenece el tipo de gasto",
    en: "The employee has not been assigned the optional structure to which the type of expense belongs"
  },
  detalleEliminado: {
    es: "Detalle Eliminado",
    en: "Detail Removed"
  },
  eliminarDetalleDeGasto: {
    es: "Eliminar Detalle de Gasto",
    en: "Delete Expense Detail"
  },
  noEsPosibleEliminarElDetalle: {
    es: "No es posible eliminar el detalle. La solicitud ya ha sido respondida",
    en: "It is not possible to remove the detail. The request has already been answered"
  },
  estaSeguroDeEliminarEsteMovimiento: {
    es: "¿Está seguro de eliminar este Movimiento?",
    en: "Are you sure to delete this Movement?"
  },
  estaSeguroDeEliminarEstaEstructura: {
    es: "¿Está seguro de eliminar esta Estructura?",
    en: "Are you sure to delete this Structure?"
  },
  estaSeguroDeEliminarEsteTipoDeSolicitud: {
    es: "¿Está seguro de eliminar este tipo de solicitud?",
    en: "Are you sure to delete this request type?"
  },
  estaSeguroDeEliminarElDetalle: {
    es: "¿Está seguro de eliminar el detalle?",
    en: "Are you sure to delete the detail?"
  },
  misliquidaciones: {
    es: "Mis Liquidaciones",
    en: "My Settlements"
  },
  liquidacionConfirmada: {
    es: "Liquidación Confirmada",
    en: "Confirmed Settlement"
  },
  verLiquidacion: {
    es: "Ver Liquidación",
    en: "See Liquidation"
  },
  aunNoSeHaGeneradoSuLiquidacion: {
    es: "Aún no se ha generado su Liquidación",
    en: "Your Settlement has not yet been generated"
  },
  solicitarRevision: {
    es: "Solicitar Revisión",
    en: "Request Review"
  },
  confirmando: {
    es: "Confirmando...",
    en: "Confirming..."
  },
  laFechaInicialTieneQueEncontrarseDentroDelMesDeLaLiquidación: {
    es: "La fecha inicial tiene que encontrarse dentro del mes de la liquidación",
    en: "The initial date must be within the month of the settlement"
  },
  laFechaFinalTieneQueEncontrarseDentroDelMesDeLaLiquidación: {
    es: "La fecha final tiene que encontrarse dentro del mes de la liquidación",
    en: "The final date must be within the month of the settlement"
  },
  solicitudGeneradaConExito: {
    es: "Solicitud generada con éxito.",
    en: "Request generated successfully."
  },
  generarSolicitudDeRevision: {
    es: "Generar Solicitud de Revisión",
    en: "Generate Review Request"
  },
  especifiqueDetallamenteElIncovenienteConSuLiquidacion: {
    es: "Especifique detallamente el incoveniente con su liquidación",
    en: "Specify in detail the inconvenience with your settlement"
  },
  yaHaRealizadoUnaSolicitudDeRevisionParaLaLiquidacionActual: {
    es: "Ya ha realizado una solicitud de revisión para la liquidación actual.",
    en: "You have already made a review request for the current settlement."
  },
  validaciondeidentidad: {
    es: "Validación de Identidad",
    en: "Identity Validation"
  },
  misvalidaciones: {
    es: "Mis Validaciones",
    en: "My Validations"
  },
  contactosDelEmpleado: {
    es: "Contactos del Empleado",
    en: "Employee Contacts"
  },
  documentos: {
    es: "Documentos",
    en: "Documents"
  },
  contrato: {
    es: "Contrato",
    en: "Contract"
  },
  estadoDelContrato: {
    es: "Estado del Contrato",
    en: "Contract Status"
  },
  registrarElDocumentoIntegroColoresNitidoLegible: {
    es: "Registrar el documento íntegro, a colores, nítido y legible. - Formato jpg, jpeg ó png.",
    en: "Register the entire document, in color, clear and legible. - Format jpg, jpeg or png."
  },
  faltaSubirDatosDeSuFirma: {
    es: "Falta subir datos de su firma",
    en: "You need to upload your signature data"
  },
  faltaFirmaDigital: {
    es: "Falta firma Digital",
    en: "Digital signature missing"
  },
  elFormatoDeImagenNoEsCorrecta: {
    es: "El formato de imagen no es correcta",
    en: "The image format is not correct"
  },
  debeSubirLaImagenCorrespondiente: {
    es: "Debe subir la imagen correspondiente",
    en: "You must upload the corresponding image"
  },
  elijaUnTipoDeFirma: {
    es: "Elija un tipo de Firma",
    en: "Choose a type of Signature"
  },
  firmaEnviadaConExitoPendienteDeValidacion: {
    es: "Firma enviada con éxito. Pendiente de validación.",
    en: "Signature sent successfully. Validation pending."
  },
  noSePudoRealizarLaOperacion: {
    es: "No se pudo realizar la operación",
    en: "The operation could not be performed"
  },
  enviarFirma: {
    es: "Enviar Firma",
    en: "Send signature"
  },
  tipoDeFirma: {
    es: "Tipo de Firma",
    en: "Signature Type"
  },
  enviar: {
    es: "Enviar",
    en: "Send"
  },
  suFirmaDigitalSeRealizoConExito: {
    es: "Su firma digital se realizó con exito!.",
    en: "Your digital signature was successful!"
  },
  elUsuarioEstaPendienteDeEnviarSuFirma: {
    es: "El usuario esta pendiente de enviar su firma",
    en: "The user is waiting to send his signature"
  },
  elEstadoDelContratoFueActualizadoSatisfactoriamente: {
    es: "El estado del contrato fue actualizado satisfactoriamente.",
    en: "The contract status was successfully updated."
  },
  validarFirma: {
    es: "Validar Firma",
    en: "Validate Signature"
  },
  verImagen: {
    es: "Ver Imagen",
    en: "See image"
  },
  elEmpleadoAunNoHaEnviadoSuFirma: {
    es: "El empleado aún no ha enviado su firma.",
    en: "The employee has not yet submitted their signature."
  },
  laVerificacionDeLaFirmaEstaEnProceso: {
    es: "La verificación de la firma esta en proceso",
    en: "Signature verification is in process"
  },
  firmaVerificada: {
    es: "Firma verificada",
    en: "Verified signature"
  },
  solicitudDeVerificacionEliminada: {
    es: "Solicitud de verificación eliminada",
    en: "Removed verification request"
  },
  verificacionRechazada: {
    es: "Verificación rechazada",
    en: "Rejected verification"
  },
  controlDeAsistencias: {
    es: "CONTROL DE ASISTENCIAS",
    en: "ASSISTANCE CONTROL"
  },
  registrarAsistencia: {
    es: "Registrar asistencia",
    en: "Record attendance"
  },
  controlasistencia: {
    es: "Control Asistencia",
    en: "Control assistance"
  },
  misasistencias: {
    es: "Mis Asistencias",
    en: "My Assists"
  },
  suEmpresaCuentaConLaIntegracionDeAsistencia: {
    es: "Su empresa cuenta con la integración de asistencia",
    en: "Your company has support integration"
  },
  horas: {
    es: "Horas",
    en: "Hours"
  },
  eliminarHoraExtra: {
    es: "ELIMINAR HORA EXTRA",
    en: "DELETE EXTRA HOUR"
  },
  estaSeguroDeEliminarEstaHoraExtra: {
    es: "¿Está seguro de eliminar esta Hora extra?",
    en: "Are you sure to delete this Hora extra?"
  },
  haMarcadoSalidaaLas: {
    es: "Ha marcado Salida a las",
    en: "You have marked Departure at"
  },
  haMarcadoFinDeRefrigerioaLas: {
    es: "Ha marcado Fin de refrigerio a las",
    en: "You have marked End of snack at"
  },
  haMarcadoInicioDeRefrigerioaLas: {
    es: "Ha marcado Inicio de refrigerio a las",
    en: "You have marked Snack Start at"
  },
  haMarcadoAsistenciaaLas: {
    es: "Ha marcado asistencia a las",
    en: "Has marked attendance at"
  },
  horasDel: {
    es: "horas del",
    en: "hours of"
  },
  registrarInicioDeRefrigerio: {
    es: "Registrar Inicio de Refrigerio",
    en: "Register Snack Start"
  },
  registrarFinDeRefrigerio: {
    es: "Registrar Fin de Refrigerio",
    en: "Register End of Refreshment"
  },
  registrarSalida: {
    es: "Registrar Salida",
    en: "Check out"
  },
  verCuotas: {
    es: "Ver Cuotas",
    en: "See Quotas"
  },
  observaciones: {
    es: "Observaciones",
    en: "observations"
  },
  imagenRostro: {
    es: "Imagen Rostro",
    en: "Image Face"
  },
  imagenDocumento: {
    es: "Imagen Documento",
    en: "Image Document"
  },
  coincidenciasDeRostro: {
    es: "Coincidencias de rostros",
    en: "Face Matches"
  },
  contrasenaRequerida: {
    es: "Contraseña requerida",
    en: "Password required"
  },
  lasContraseñasNoCoinciden: {
    es: "Las contraseñas no coinciden",
    en: "Passwords do not match"
  },
  cambiarContrasena: {
    es: "Cambiar Contraseña",
    en: "Change Password"
  },
  ingreseActualContrasena: {
    es: "Ingrese actual contraseña",
    en: "Enter current password"
  },
  ingreseNuevaContrasena: {
    es: "Ingrese nueva contraseña",
    en: "Enter new password"
  },
  vuelvaaIngresarNuevaContrasena: {
    es: "Vuelva a ingresar nueva contraseña",
    en: "Re-enter new password"
  },
  cambiar: {
    es: "Cambiar",
    en: "Change"
  },
  elFormatoImagenNoEsCorrecto: {
    es: "El formato de imagen no es correcta",
    en: "The image format is not correct"
  },
  documentosConfirmados: {
    es: "Documentos Confirmados.",
    en: "Confirmed Documents."
  },
  confirmarDocumentosDeValidacionRegistradosPorSuAdministrador: {
    es: "Confirmar documentos de validación registrados por su administrador",
    en: "Confirm validation documents registered by your administrator"
  },
  fotosoImagenes: {
    es: "Fotos o Imágenes",
    en: "Photos or Images"
  },
  noSeRegistraronImagenes: {
    es: "No se registraron imagenes",
    en: "No images registered"
  },
  documentosPDF: {
    es: "Documentos PDF",
    en: "PDF documents"
  },
  noSeRegistraronDocumentos: {
    es: "No se registraron documentos",
    en: "No documents registered"
  },
  noSeRegistraronDocumentosDeIdentidad: {
    es: "No se registraron documentos de identidad",
    en: "No identity documents were registered"
  },
  registroActualizadoConExito: {
    es: "Registro actualizado con éxito",
    en: "Record updated successfully"
  },
  validacion: {
    es: "Validación",
    en: "Validation"
  },
  imagenActualDelRostro: {
    es: "Imagen Actual del Rostro",
    en: "Current Face Image"
  },
  imagenDelRostro: {
    es: "Imagen del Rostro (.jpg, .jpeg)",
    en: "Face Image (.jpg, .jpeg)"
  },
  imagenActualDelDocumentoDeIdentidad: {
    es: "Imagen Actual del Documento de Identidad",
    en: "Current Image of the Identity Document"
  },
  imagenDeDocumentoDeIdentidad: {
    es: "Imagen de Documento de Identidad (.jpg, .jpeg)",
    en: "Identity Document Image (.jpg, .jpeg)"
  },
  listaDeUsuariosAdmin: {
    es: "Lista de usuarios Admin",
    en: "Admin User List"
  },
  usuarioAdmin: {
    es: "Usuario Administrador",
    en: "User Administator"
  },
  usuariosAdmin: {
    es: "Usuarios Administradores",
    en: "Users Administator"
  },
  gruposDeEmpresa: {
    es: "Grupos de Empresa",
    en: "Company Groups"
  },
  variablesglobales: {
    es: "Variables Globales",
    en: "Global Variables"
  },
  reportemateriales:{
    es: "Reporte Materiales",
    en: "Materials Report"
  },
  sinInformacion:{
    es: "Sin información",
    en: "No information"
  },
  estadoDelProducto: {
    es: "Estado del producto",
    en: "Product status"
  },
  movimientosDelMaterial: {
    es: "Movimientos del material",
    en: "Material movements"
  },
  fechaAcordadaDeRetorno: {
    es: "Fecha Acordada de Retorno",
    en: "Agreed Return Date"
  },
  noSeEncontraronMovimientos: {
    es: "No se encontraron movimientos",
    en: "No movements found"
  },
  ubigeo: {
    es: "Ubigeo",
    en: "Ubigeo"
  },
  paises: {
    es: "Países",
    en: "Countries"
  },
  abreviacion: {
    es: "Abreviación",
    en: "Abbreviation"
  },
  lenguaje: {
    es: "Lenguaje",
    en: "Language"
  },
  moneda: {
    es: "Moneda",
    en: "Currency"
  },
  idioma: {
    es: "Idioma",
    en: "Idiom"
  },
  codigoDeMoneda:{
    es: "Código de Moneda",
    en: "Currency Code"
  },
  zonaHoraria: {
    es: "Zona Horaria",
    en: "Time zone"
  },
  filtrosDeBusqueda:{
    es: "Filtros de búsqueda",
    en: "Search filters"
  },
  filtrarPorPais: {
    es: "Filtrar por país",
    en: "Filter by country"
  },
  filtrarPorDepartamento: {
    es: "Filtra por Departamento",
    en: "Filter by Department"
  },
  filtrarPorProvincia: {
    es: "Filtra por Provincia",
    en: "Filter by Province"
  },
  buscarRegion: {
    es: "Buscar región",
    en: "Search region"
  },
  region:{
    es: "Región",
    en: "Region"
  },
  regiones: {
    es: "Regiones",
    en: "Regions"
  },
  provincias: {
    es: "Provincias",
    en: "Provinces"
  },
  debeSeleccionarUnDepartamento: {
    es: "Debe seleccionar un departamento",
    en: "You must select a department"
  },
  comunas: {
    es: "Comunas",
    en: "Communes"
  },
  debeSeleccionarUnaProvincia: {
    es: "Debe seleccionar una provincia",
    en: "You must select a province"
  },
  tiposdenotificaciones: {
    es: "Tipos de Notificaciones",
    en: "Types of Notifications"
  },
  debeSeleccionarUnaURL: {
    es: "Debe seleccionar una URL",
    en: "You must select a URL"
  },
  creadoConExito: {
    es: "Creado con Éxito",
    en: "Successfully Created"
  },
  tipoDeNotificacion: {
    es: "Tipo de Notificación",
    en: "Notification Type"
  },
  zonashorarias: {
    es: "Zonas Horarias",
    en: "Time Zones"
  },
  tiposdesucursales: {
    es: "Tipos de sucursales",
    en: "Types of branches"
  },
  politicasdelsoftware: {
    es: "Políticas del Software",
    en: "Software Policies"
  },
  topeCotizacion: {
    es: "Tope Cotización",
    en: "Quotation ceiling"
  },
  topeCesantia: {
    es: "Tope Cesantía",
    en: "Unemployment ceiling"
  },
  remuneracionMensualMinima: {
    es: "Rem. Mensual Mínima",
    en: "Minimum Monthly Rem."
  },
  tramoFamiliar: {
    es: "Tramo Familiar",
    en: "Family Tranche"
  },
  variableGlobal:{
    es: "Variable Global",
    en: "Global Variable"
  },
  firmaDigital: {
    es: "Firma Digital",
    en: "Digital signature"
  },
  firmadigital: {
    es: "Firma Digital",
    en: "Digital signature"
  },
  controlasistenciahardware: {
    es: "Control Asistencia (Hardware)",
    en: "Assistance Control (Hardware)"
  },
  suFirmaDigitalEstaSiendoProcesada: {
    es: "Su firma digital está siendo procesada...",
    en: "Your digital signature is being processed..."
  },
  firmaManual: {
    es: "Firma Manual",
    en: "Manual Signature"
  },
  estructurasDeLiquidacion: {
    es: "Estructuras de Liquidación",
    en: "Settlement Structures"
  },
  tipoDeEstructura: {
    es: "Tipo de Estructura",
    en: "Structure Type"
  },
  asegureseDeSeleccionarUnaEmpresa: {
    es: "Asegurese de seleccionar una empresa",
    en: "Be sure to select a company"
  },
  asegureseDeSeleccionarAlMenosUnaEmpresa: {
    es: "Asegurese de seleccionar al menos una empresa",
    en: "Be sure to select at least one company"
  },
  debeDeSeleccionarUnPerfil: {
    es: "Debe de Seleccionar un Perfil",
    en: "You must select a profile"
  },
  editarDatosDelFamiliar: {
    es: "Editar datos del familiar",
    en: "Edit relative's data"
  },
  datosActualizadosSatisfactoriamente: {
    es: "Datos actualizados satisfactoriamente",
    en: "Data updated successfully"
  },
  eliminarFamiliar: {
    es: "Eliminar Familiar",
    en: "Delete Family"
  },
  estaSeguroDeEliminarAlFamiliar: {
    es: "¿Está seguro de eliminar al familiar?",
    en: "Are you sure to delete the familiar?"
  },

  crearRemuneracion: {
    es: "crear remuneracion",
    en: "create remuneration"
  },
  crearRemuneracionMasiva: {
    es: "crear remuneracion masiva",
    en: "create massive remuneration"
  },

  clase: {
    es: "Clase",
    en: "Class"
  },

  rangoInicio: {
    es: "Rango de Inicio",
    en: "Start Range"
  },

  rangoFinal: {
    es: "Rango Final",
    en: "Start Range"
  },

  rangoDirecciones: {
    es: "Rango de direcciones",
    en: "Address range"
  },

  escribirIP: {
    es: "escribir IP",
    en: "write IP"
  },

  agregarRangoIP: {
    es: "Agregar Rango de IP",
    en: "Add IP Range"
  },

  editarRangoIP: {
    es: "Editar Rango de IP",
    en: "Edit IP Range"
  },

  eliminarIP: {
    es: "ELIMINAR RANGO DE IP",
    en: "DELETE IP RANGE"
  },

  estaSeguroDeEliminarElRangoDeIP: {
    es: "¿Está seguro de eliminar el rango de IP?",
    en: "Are you sure to remove the IP range?"
  },

  estaSeguroDeEliminarLaIP: {
    es: "¿Está seguro de eliminar la IP?",
    en: "Are you sure to remove the IP?"
  },

  agregarIP: {
    es: "Agregar IP",
    en: "Add IP"
  },

  editarIP: {
    es: "Editar IP",
    en: "Edit IP"
  },
  eliminarAnticipo:{
    es: "ELIMINAR ANTICIPO",
    en : "DELETE ADVANCE" 
  },
  noesposibleeliminarelanticipolasolicitudyahasidorespondida:{
    es: "No es posible eliminar el anticipo. La solicitud ya ha sido respondida",
    en : "It is not possible to delete the advance. The request has already been answered" 
  },
  estasegurodeeliminaresteanticipo:{
    es: "¿Está seguro de eliminar este Anticipo?",
    en : "Are you sure to delete this Advance?" 
  },
  tiposDeRecordatorios:{
    es: "Tipos de recordatorios",
    en: "Types of reminders"
  },
  fechainicioesrequerido: {
    es: "Fecha inicio es requerido",
    en: "Start date is required"
  },
  fechafinesrequerido: {
    es: "Fecha fin es requerido",
    en: "End date is required"
  },
  seleccionauntipodecontrato: {
    es: "Selecciona un tipo de contrato",
    en: "Select a type of contract"
  },
  seleccionaunestadodecontrato: {
    es: "Selecciona un estado de contrato",
    en: "Select a contract status"
  },
  seleccionaunacausaldelalista: {
    es: "Selecciona una causal de la lista",
    en: "Select a cause from the list"
  },
  horasextrasporempleado: {
    es: "Horas extras por empleado",
    en: "Extra hours for employee"
  },
  horasextrasde: {
    es: "Horas extras de",
    en: "Extra hours of"
  },
  horasextras: {
    es: "Horas extras",
    en: "Extra hours"
  },
  activarestructuradehorasextras: {
    es: "Activar estructura de horas extras",
    en: "Activate extra hours structure"
  },
  editarhorasextras: {
    es: "Editar horas extras",
    en: "Edit extra hours"
  },
  agregarhorasextras: {
    es: "Agregar horas extras",
    en: "Add extra hours"
  },
  cantidadhora: {
    es: "Cantidad hora",
    en: "Quantity hour"
  },
  iprequerido: {
    es: "ip requerido",
    en: "required ip"
  },
  ipinvalido: {
    es: "ip inválido",
    en: "invalid ip"
  },
  rutincorrecto: {
    es: "Rut incorrecto",
    en: "incorrect rut"
  },

   aseguresedeagregarlasasistenciasdeacuerdoalasolicitudde: {
    es: "Asegurese de agregar la(s) asistencia(s) de acuerdo a la solicitud de",
    en: "Be sure to add the assistance(s) according to the request"
  },
  lacualcorrespondedelafecha: {
    es: "la cual corresponde de la fecha",
    en: "which corresponds to the date"
  },
  aseguresedeagregarlastardanzasdeacuerdoalasolicitudde: {
    es: "Asegurese de agregar la(s) tardanza(s) de acuerdo a la solicitud de",
    en: "Be sure to add the delay(s) according to the request"
  },
  previsualizacionDeLiquidaciones: {
    es: "previsualización de liquidaciones",
    en: "Liquidation preview"
  },
  descargarprevisualizacionpdf: {
    es: "descargar previsualizacion pdf",
    en: "download pdf preview"
  },
  LIQUIDACIONDESUELDOMASIVO: {
    es: "LIQUIDACIóN DE SUELDO MASIVO",
    en: "MASSIVE SALARY SETTLEMENT"
  },
  verpdf: {
    es: "Ver PDF",
    en: "View PDF"
  },
  datosdelempleado: {
    es: "Datos del empleado",
    en: "Employee data"
  },
  haberes: {
    es: "Haberes",
    en: "taxables"
  },
  descuentos: {
    es: "Descuentos",
    en: "discounts"
  },
  nombrecompleto: {
    es: "Nombre completo",
    en: "Full name"
  },
  fondodepensiones: {
    es: "fondo de pensiones",
    en: "pension fund"
  },
  sistemadesalud: {
    es: "Sistema de salud",
    en: "Healthcare system"
  },
  diastrabajados: {
    es: "días trabajados",
    en: "worked days"
  },
  basetributable: {
    es: "Base tributable",
    en: "Tax base"
  },
  sueldobase: {
    es: "Sueldo base",
    en: "base salary"
  },
  totalsalud: {
    es: "Total salud",
    en: "Total health"
  },
  tope: {
    es: "Tope",
    en: "Limit"
  },
  dias: {
    es: "días",
    en: "days"
  },
  TOTALHABERES: {
    es: "TOTALHABERES",
    en: "TOTAL ASSETS"
  },
  gratificacionLegalTope: {
    es: "gratificación legal tope",
    en: "Legal gratification Limit"
  },
  HABERESIMPONIBLESYNOTRIBUTABLES: {
    es: "HABERES IMPONIBLES Y NO TRIBUTABLES",
    en: "TAXABLE AND NOTRIBUTABLE ASSETS"
  },
  totalHaberesImpNoTrib: {
    es: "Total haberes Imp No Trib",
    en: "Total assets Imp No Trib"
  },
  HABERESNOIMPONIBLESYTRIBUTABLES: {
    es: "HABERES NO IMPONIBLES Y TRIBUTABLES",
    en: "NON-TAXABLE AND TAXABLE ASSETS"
  },
  totalHaberesNoImpytrib: {
    es: "total Haberes No Imp y trib",
    en: "total assets No Imp and trib"
  },
  HABERESIMPONIBLESYTRIBUTABLES: {
    es: "HABERES IMPONIBLES Y TRIBUTABLES",
    en: "TAXABLE AND TAXABLE ASSETS"
  },
  totalHaberesImpTrib: {
    es: "total Haberes Imp Trib",
    en: "total assets Imp Trib"
  },
  HABERESNOIMPONIBLESYNOTRIBUTABLES: {
    es: "HABERES NO IMPONIBLES Y NO TRIBUTABLES",
    en: "NON-TAXABLE AND NON-TAXABLE ASSETS"
  },
  totalHaberesNoImpyNotrib: {
    es: "total Haberes No Imp y No trib",
    en: "total assets No Imp and No trib"
  },
  DESCUENTOSPREVISIONALES: {
    es: "DESCUENTOS PREVISIONALES",
    en: "PROVISIONAL DISCOUNTS"
  },
  cotizObligatAFP: {
    es: "Cotización obligatoria AFP",
    en: "Mandatory AFP contribution"
  },
  seguroCesantia: {
    es: "Seguro cesantia",
    en: "Unemployment insurance"
  },
  cotizObligatSalud: {
    es: "Cotización obligatoria salud",
    en: "Mandatory health quote"
  },
  totalDescPrevisionales: {
    es: "total Desc previsionales",
    en: "total disc provisional "
  },
  DESCUENTOSLEGALES: {
    es: "DESCUENTOS LEGALES",
    en: "LEGAL DISCOUNTS"
  },
  impuestoUnico: {
    es: "impuesto único",
    en: "Single tax"
  },
  totalDescLegales: {
    es: "total desc legales",
    en: "total disc legal"
  },
  TOTALDESCUENTOSGENERALES: {
    es: "TOTAL DESCUENTOS GENERALES",
    en: "TOTAL GENERAL DISCOUNTS"
  },
  detalledelempleado: {
    es: "Detalle del empleado",
    en: "Employee Detail"
  },
  // dias: {
  //   es: "días",
  //   en: "days"
  // },
  // dias: {
  //   es: "días",
  //   en: "days"
  // },


 /* ---------------------------- RESPONSE API ----------------------------  */
//advanceService
yaexisteunasolicitudpendienteenelmesactual:{
  es: "Ya existe una solicitud pendiente en el mes actual",
  en : "There is already a pending request in the current month" 
},
anticipocreadosatisfactoriamente:{
  es: "Anticipo creado satisfactoriamente",
  en : "Advance created successfully" 
},
adelantoeliminado:{
  es: "Adelanto eliminado",
  en : "advance removed" 
},
accionrealizada:{
  es: "Acción realizada",
  en : "Action performed" 
},

//AnnexedService
elcontratonoexisteonocuentaconanexos:{
  es: "El contrato no existe o no cuenta con anexos",
  en : "The contract does not exist or does not have annexes" 
},
nohhaycambiosregistrados:{
  es: "No hay cambios registrados",
  en : "No changes recorded" 
},

//AssignmentMovStaffService
debeDeSeleccionarUnTipoDeMovimiento:{
  es: "Debe seleccionar un tipo de movimiento",
  en : "You must select a type of movement" 
},
elempleadonoperteneceaestaempresa:{
  es: "El empleado no pertenece a esta empresa",
  en : "The employee does not belong to this company" 
},
movimientocreadoconexito:{
  es: "Movimiento creado con éxito",
  en : "movement created successfully" 
},
movimientoeditadoconexito:{
  es: "Movimiento editado con éxito",
  en : "Movement edited successfully" 
},
elmovimientonopertneceaestaempresa:{
  es: "El movimiento no pertenece a esta empresa",
  en : "The movement does not belong to this company" 
},
asignamientoeliminado:{
  es: "Asignamiento eliminado",
  en : "Assignment removed" 
},
movimientoeliminado:{
  es: "movimiento eliminado",
  en : "move removed" 
},

//AssistanceService
ocurrioalgunerroraseguresequeelempleadocuenteconunhorarioasignado: {
  es: "Ocurrió algún error, asegurese que el empleado cuente con un horario asignado.",
  en : "An error occurred, make sure that the employee has an assigned schedule."
},
inasistenciacreadasatisfactoriamente: {
  es: "Inasistencia creada satisfactoriamente",
  en : "Absence Created Successfully"
},
noseencontrolainasistencia: {
  es: "No se encontro la inasistencia",
  en : "No absence found"
},
inasistenciaeditadasatisfactoriamente: {
  es: "Inasistencia editada satisfactoriamente",
  en : "Absence Edited Successfully"
},
horaextraecreadoconexito:{
  es: "Hora extra creado con éxito",
  en : "Overtime created successfully" 
},
horaextraeditadaconexito:{
  es: "Hora extra editada con éxito",
  en : "Overtime successfully edited" 
},
horaextraeliminada:{
  es: "Hora extra eliminada",
  en : "Overtime removed" 
},
eliminarinasistencia:{
  es: "ELIMINAR INASISTENCIA",
  en : "ELIMINATE ABSENCE" 
},
inasistenciaeliminada:{
  es: "Inasistencia eliminada.",
  en : "Absence eliminated." 
},

//BranchOfficeService
ladireccióndelasucursalyaseencuentraregistrado: {
  es: "La dirección de la sucursal ya se encuentra registrado",
  en : "The address of the branch is already registered"
},
sucursalcreadasatisfactoriamente: {
  es: "Sucursal creada satisfactoriamente",
  en : "Branch created successfully"
},
sucursalactualizadasatisfactoriamente: {
  es: "Sucursal actualizada satisfactoriamente",
  en : "Branch updated successfully"
},
sucursaleliminadasatisfactoriamente: {
  es: "Sucursal eliminada satisfactoriamente",
  en : "Branch deleted successfully"
},
elTipodesucursalyaseencuentraregistrado: {
  es: "El Tipo de Sucursal ya se encuentra registrado",
  en : "The Branch Type is already registered"
},
tipodesucursalcreadosatisfactoriamente: {
  es: "Tipo de sucursal creado satisfactoriamente",
  en : "Branch type created successfully"
},
noseencontroeltipodesucursal: {
  es: "No se encontro el tipo de sucursal",
  en : "Branch type not found"
},
tipodesucursalactualizadosatisfactoriamente: {
  es: "Tipo de sucursal actualizado satisfactoriamente",
  en : "Branch type updated successfully"
},
tipodesucursaleliminadosatisfactoriamente: {
  es: "Tipo de sucursal eliminado satisfactoriamente",
  en : "Branch type deleted successfully"
},

//BusinessAreaService
areadenegociocreadoconexito:{
  es: "Area de negocio creado con éxito",
  en : "Successfully created business area" 
},
areadenegocioeditadoconexito:{
  es: "Area de negocio editado con éxito",
  en : "Successfully edited business area" 
},
areadenegocioeliminadosatisfactoriamente: {
  es: "Area de negocio eliminado satisfactoriamente",
  en : "Business area successfully removed"
},
creadoconexito:{
  es: "Creado con éxito",
  en : "Successfully created" 
},
areadenegociovalida:{
  es: "Área de negocio válida",
  en : "Valid business area" 
},
areadenegocioinvalida:{
  es: "Área de negocio inválida",
  en : "Invalid business area" 
},
funciondelareadenegocioeditadoconexito:{
  es: "Funcion del área de negocio editado con éxito",
  en : "Function of the business area edited successfully" 
},
funcioneliminada:{
  es: "Función eliminada",
  en : "Feature removed" 
},
sintrabajadores:{
  es: "Sin trabajadores",
  en : "No workers" 
},

//CertificateRemunerationService
elcertificadodeltrabajadorsecreoconexito:{
  es: "El certificado del trabajador se creo con éxito",
  en : "The worker's certificate was created successfully" 
},
elcertificadodeltrabajadoryaexisteparaesteperiodoano:{
  es: "El certificado del trabajador ya existe para este periodo-año",
  en : "The worker's certificate already exists for this period-year" 
},
loscertificadosdelostrabajadoressecrearonconexito:{
  es: "Los certificados de los trabajadores se crearon con éxito",
  en : "Worker certificates were created successfully" 
},

//ChargeService
elcargoyaseencuentraregistrado: {
  es: "El cargo ya se encuentra registrado",
  en : "The charge is already registered"
},
cargocreadosatisfactoriamente: {
  es: "Cargo creado satisfactoriamente",
  en : "Charge created successfully"
},
elnombredelcargoyaseencuentraregistrado: {
  es: "El nombre del cargo ya se encuentra registrado",
  en : "The name of the position is already registered"
},
cargoeditadosatisfactoriamente: {
  es: "Cargo editado satisfactoriamente",
  en : "Charge edited successfully"
},
cargoeliminadosatisfactoriamente: {
  es: "Cargo eliminado satisfactoriamente",
  en : "Charge deleted successfully"
},
funciondelcargoeditadoconexito:{
  es: "Funcion del cargo editado con éxito",
  en : "Position function edited successfully" 
},
funciondelcargoeliminadoconexito:{
  es: "Funcion del cargo eliminado con éxito",
  en : "Function of the position successfully deleted" 
},

//CMService
nocuentaconlospermisossuficientes:{
  es: "No cuenta con los permisos suficientes",
  en : "You do not have sufficient permissions" 
},
yahayregistroparaesteaño:{
  es: "Ya hay registro para este año",
  en : "There is already registration for this year" 
},
noexisteesteregistro:{
  es: "No existe este registro",
  en : "This record does not exist" 
},
eliminadoconexito:{
  es: "Eliminado con éxito",
  en : "Successfully removed" 
},

//CompanyDataService
horasextrasdefinidas:{
  es: "Horas extras definidas",
  en : "Defined overtime" 
},
fechadecierredeliquidacionestablecida:{
  es: "Fecha de cierre de liquidacion establecida",
  en : "Established settlement closing date" 
},
cantidaddehorassemanalesactualizada:{
  es: "Cantidad de Horas semanales actualizada",
  en : "Updated weekly number of hours" 
},
fechadeejecuciondelapreliquidacionactualizada:{
  es: "Fecha de ejecución de la pre liquidacion actualizada",
  en : "Execution date of the updated pre-settlement" 
},
mutualactualizado:{
  es: "Mutual actualizado",
  en : "Mutual updated" 
},

//CompanyGroupService
notienepermisosparacrearungrupodecompanias: {
  es: "No tiene permisos para crear un grupo de compañias",
  en : "You do not have permission to create a group of companies"
},
yaseencuentradentrodelgrupodecompanias: {
  es: "Ya se encuentra dentro del grupo de Compañias",
  en : "You are already in the group of Companies"
},
grupodecompaniaregistradosatisfactoriamente: {
  es: "Grupo de Compañia registrado satisfactoriamente",
  en : "Company Group successfully registered"
},
grupodecompaniaactualizadosatisfactoriamente: {
  es: "Grupo de Compañia actualizado satisfactoriamente",
  en : "Company Group updated successfully"
},
grupodecompaniaeliminadosatisfactoriamente: {
  es: "Grupo de compañia eliminado satisfactoriamente",
  en : "Company group deleted successfully"
},

//CompanyService
elrutyaseencuentraregistrado: {
  es: "El rut ya se encuentra registrado",
  en : "The rut is already registered"
},

  empresacreadasatisfactoriamente: {
    es: "Empresa creada satisfactoriamente",
    en : "Company successfully created"
  },
  empresaactualizadasatisfactoriamente: {
    es: "Empresa actualizada satisfactoriamente",
    en : "Company updated successfully"
  },
  empresaeliminadasatisfactoriamente: {
    es: "Empresa eliminada satisfactoriamente",
    en : "Company deleted successfully"
  },
  documentoeliminadosatisfactoriamente:{
    es: "Documento eliminado satisfactoriamente",
    en : "Document successfully deleted" 
  },
  accesorestringido: {
    es: "Acceso Restringido",
    en: "Access Denied"
  },
  eltipodedocumentoesrequerido: {
    es: "El tipo de documento es requerido",
    en : "The type of document is required"
  },
  seleccioneunarchivodetipoPDF: {
    es: "Seleccione un archivo de tipo PDF",
    en : "Select a file of type PDF"
  },
  documentoregistradosatisfactoriamente: {
    es: "documento registrado satisfactoriamente",
    en : "document successfully registered"
  },
  documentoactualizadosatisfactoriamente:{
    es: "documento actualizado satisfactoriamente",
    en : "document successfully updated" 
  },
  nosepudorealizarlaasignacion: {
    es: "No se pudo realizar la asignación",
    en : "Assignment failed"
  },
  laasignacionserealizosatisfactoriamente: {
    es: "La asignación se realizó satisfactoriamente",
    en : "The assignment was successful"
  },

  //ConfigIPService
  yaexisteunrangoconlasmismasdireccionesparaelusuario:{
    es: "Ya existe un rango con las mismas direcciones para el usuario",
    en: "A range with the same addresses already exists for the user"
  },
  rangosdeipcreadossatisfactoriamente:{
    es: "Rangos de ip creados satisfactoriamente",
    en: "IP ranges created successfully"
  },
  editadoconexito:{
    es: "Editado con éxito",
    en : "Edited successfully" 
  },
  yaexisteunaipdenegadaconlamismadireccionparaelusuario:{
    es: "Ya existe una ip denegada con la misma dirección para el usuario",
    en: "There is already an ip denied with the same address for the user"
  },
  sedenegoelaccesoaesteusuario:{
    es: "Se denego el acceso a este usuario",
    en : "This user was denied access" 
  },
  acceptIP:{
    es: "Solo se aceptan direcciones IP's pertenecientes a la v4. Tienen el formato x.x.x.x, donde a la x se le denomina un octeto y debe ser un valor decimal entre 0 y 255. Los octetos se encuentran separados por puntos.",
    en: "Only IP addresses belonging to v4 are accepted. They have the format x.x.x.x, where x is called an octet and must be a decimal value between 0 and 255. The octets are separated by periods."
  },
  rangoeditadoip:{
    es: "Rangos de ip editados satisfactoriamente",
    en: "Successfully edited ip ranges"
  },
  ipcreadosatisfactoriamente:{
    es: "IP creado satisfactoriamente",
    en: "IP created successfully"
  },
  ipeditadosatisfactoriamente:{
    es: "IP editado satisfactoriamente",
    en: "IP edited successfully"
  },
  rangoipeliminado:{
    es: "Rango de IP eliminado",
    en: "IP range removed"
  },

  //CostCenterService
  cuentaconcentrodecosto:{
    es: "Cuenta con centro de costo",
    en : "Has a cost center" 
  },
  nocuentaconcentrodecosto:{
    es: "No cuenta con centro de costo",
    en : "Does not have a cost center" 
  },
  elnombredelcentrodecostoyaseencuentraregistrado: {
    es: "El nombre del centro de costo ya se encuentra registrado",
    en : "The name of the cost center is already registered"
  },
  centrodecostoeliminadosatisfactoriamente: {
    es: "Centro de Costo eliminado satisfactoriamente",
    en : "Cost Center deleted successfully"
  },

  //CountryService
  elpaisyaseencuentraregistrado:{
    es: "El País ya se encuentra registrado",
    en : "The Country is already registered" 
  },
  eldepartamentoyaseencuentraregistrado:{
    es: "El Departamento ya se encuentra registrado",
    en : "The Department is already registered" 
  },
  laprovinciayaseencuentraregistrada:{
    es: "La Provincia ya se encuentra registrada",
    en : "The Province is already registered" 
  },
  eldistritoyaseencuentraregistrado:{
    es: "El Distrito ya se encuentra registrado",
    en : "The District is already registered" 
  },

  //DashboardService
  noseencontraronproyectos:{
    es: "No se encontraron proyectos",
    en : "No projects found"
  },
  noseencontraronproyectosiniciadosenelano:{
    es: "No se encontraron proyectos iniciados en el año.",
    en: "No projects started in the year were found"
  },
  periodoinvalido:{
    es: "Periodo inválido",
    en : "Invalid period" 
  },

  //DetailAFPService
  yaexisteundetallparaestafechayafp:{
    es: "Ya existe un detalle para esta fecha y AFP",
    en : "There is already a detail for this date and AFP" 
  },
  detalledeafpcreadoconexito:{
    es: "Detalle de AFP creado con éxito",
    en : "AFP detail successfully created" 
  },
  detalledeafpeditadoconexito:{
    es: "Detalle de AFP editado con éxito",
    en : "AFP detail successfully edited" 
  },
  detalledeafpeliminadoconexito:{
    es: "Detalle de AFP eliminado con éxito",
    en : "AFP Detail Successfully Removed" 
  },
  estaSeguroQueDeseaEliminarEstosValoresDeAFP: {
    es: "¿Esta seguro que desea eliminar estos valores de AFP?",
    en: "Are you sure you want to delete these AFP values?"
  },


   //DetailCompanyTurnService
   giroeliminado:{
    es: "Giro eliminado",
    en : "Twist removed" 
  },
   //DetailEmployeeCodhealthService
   suempleadoseencuentradeshabilitado:{
    es: "Su empleado se encuentra deshabilitado",
    en : "Your employee is disabled" 
  },
  suempleadoyacuentaconundetallelerecomendamoseditarlo:{
    es: "Su empleado ya cuenta con un detalle, le recomendamos editarlo",
    en : "Your employee already has a detail, we recommend editing it" 
  },
  suempleadoseencuentraeliminado:{
    es: "Su empleado se encuentra eliminado",
    en : "Your employee is eliminated" 
  },
  detallenoencontrado:{
    es: "Detalle no encontrado",
    en : "Detail not found" 
  },
  detalleeliminado:{
    es: "Detalle eliminado",
    en : "Detail removed" 
  },
 //DetailEmployeeStructureOptionalService
 nosecuentaconopcionalesactivos:{
  es: "No se cuenta con opcionales activos",
  en : "There are no active options" 
  },
  realizadoconexito:{
    es: "Realizado con éxito",
    en : "Successfully done" 
  },
  cambiosefectuadosconexito:{
    es: "Cambios efectuados con éxito",
    en : "Changes made successfully" 
  },
 
  //DetailSpecificationJobOfferService

  //DetailUserCompanyService
  lainserccionserealizoconexito:{
    es: "La insercción se realizó con éxito",
    en : "The insertion was successful" 
  },
  elusuarioyoempleadoqueintentarelacionarnopertenecenalamismaempresa:{
    es: "El usuario y/o empleado que intenta relacionar no pertenecen a la misma empresa",
    en : "The user and/or employee you are trying to relate do not belong to the same company" 
  },
  
  //DetailWorkerSchedulesService
  solodebeingresardiasounrangodefechas:{
    es: "Solo debe ingresar días o un rango de fechas",
    en : "You only need to enter days or a date range" 
  },
  lacantidaddediasespecificosdebeserigualquesushorarioselegidos:{
    es: "La cantidad de días específicos debe ser igual que sus horarios elegidos",
    en : "The number of specific days must be the same as your chosen schedules" 
  },
  horarioscreadosconexito:{
    es: "Horarios creados con éxito",
    en : "Successfully created schedules" 
  },
  verifiquequeelhorarioelegidoseadesuempleado:{
    es: "Verifique que el horario elegido sea de su empleado",
    en : "Verify that the chosen schedule belongs to your employee" 
  },
  detalledehorarioeditadosatisfactoriamente: {
    es: "Detalle de Horario editado satisfactoriamente",
    en : "Schedule detail edited successfully"
  },
  detalledeasistenciaeliminadoconexito:{
    es: "Detalle de asistencia eliminado con éxito",
    en : "Attendance detail successfully removed" 
  },
  
  //DJService

  //DocumentContractService
  documentocontratocreadoconexito:{
    es: "Documento Contrato creado con éxito",
    en : "Contract Document created successfully" 
  },
  documentodecontratoeditadosatisfactoriamente: {
    es: "Documento de Contrato editado satisfactoriamente",
    en : "Contract document edited successfully"
  },
  //user service
  documentonoencontrado:{
    es: "Documento no Encontrado.",
    en : "Document not found." 
  },
  noseencontraronclausulasparaelcontrato:{
    es: "No se encontraron clausulas para el contrato.",
    en : "No clauses were found for the contract." 
  },
  clausulascreadasconexito:{
    es: "Clausulas creadas con éxito",
    en : "Clauses created successfully" 
  },
  clausulaeliminadasatisfactoriamente: {
    es: "Clausula eliminada satisfactoriamente",
    en : "Clause deleted successfully"
  },
  lasclausulaspredeterminadasnosepuedeneditar: {
    es: "Las clausulas predeterminadas no se pueden editar",
    en : "Default clauses cannot be edited"
  },
  clausulaseditadassatisfactoriamente: {
    es: "Clausulas editadas satisfactoriamente",
    en : "clauses updated successfully"
  },
  estaclausulaestasiendousadaenundocumentocontrato:{
    es: "Esta clausula esta siendo usada en un Documento contrato",
    en : "This clause is being used in a Contract Document" 
  },
  clausulaseliminadassatisfactoriamente:{
    es: "Clausulas eliminadas satisfactoriamente",
    en : "Clauses successfully removed" 
  },
  noestaregistradoesteempleado:{
    es: "No esta registrado este empleado",
    en : "This employee is not registered" 
  },
  
  //ElectronicBookSalariesService
  sindataderemuneraciones:{
    es: "Sin data de remuneraciones",
    en : "No salary data"
  },
  sindatapormostrar:{
    es: "Sin data por mostrar",
    en : "No data to show"
  },
  aseguresequeelempleadotengaliquidaciones:{
    es: "Asegurese que el empleado tenga liquidaciones y que la tabla 'cm' contenga data, para el año seleccionado",
    en : "Make sure the employee has payoffs and that the 'cm' table contains data, for the selected year"
  },
  nocuentasconliquidacionesparaelañoseleccionado:{
    es: "No cuentas con liquidaciones para el año seleccionado. Comuníquese con su administrador.",
    en : "You do not have settlements for the selected year. Contact your administrator."
  },
  //EmployeeCcafService
  elempleadonopertenecealaempresa:{
    es: "El empleado no pertenece a la empresa",
    en : "The employee does not belong to the company" 
  },
  elempleadocuentaconunarelacionvigente:{
    es: "El empleado cuenta con una relación vigente",
    en : "The employee has a current relationship" 
  },
  relacioneliminadosatisfactoriamente:{
    es: "Relación eliminado satisfactoriamente",
    en : "Relationship successfully removed" 
  },
  estadodelarelacioncambiada:{
    es: "Estado de la relación cambiada",
    en : "Relationship status changed" 
  },
   //EmployeeContractService
   estecontratoserausadocomoreferencia:{
    es: "Este contrato será usado como referencia",
    en : "This contract will be used as a reference" 
  },
  validacioncreadasatisfactoriamente:{
    es: "Validación creada satisfactoriamente",
    en : "Validation created successfully" 
  },
  sinregistrosporactualizar:{
    es: "Sin registros por actualizar",
    en : "No records to update" 
  },
  actualizacionconexito:{
    es: "Actualización con éxito",
    en : "Successful update" 
  },
  esteempleadonotienecontrato:{
    es: "Este empleado no tiene contrato",
    en : "This employee does not have a contract" 
  },
  contratoinvalido:{
    es: "Contrato inválido",
    en : "Invalid contract" 
  },
  contratoactivado:{
    es: "Contrato activado",
    en : "Contract activated" 
  },
  contratoeliminadoodesactivado:{
    es: "Contrato eliminado o desactivado",
    en : "Contract deleted or deactivated" 
  },
  parametroinvalido:{
    es: "Parámetro inválido",
    en : "Invalid parameter" 
  },
  //EmployeeService
  elempleadocuentaconuncontratoporfavorediteelcontratodirectamente: {
    es: "El empleado cuenta con un contrato, por favor edite el contrato directamente",
    en : "The employee has a contract, please edit the contract directly"
  },
  noseencontroalempleado: {
    es: "No se encontro al empleado",
    en : "Employee not found"
  },
  elempleadonoperteneceaningunacompania: {
    es: "El empleado no pertenece a ninguna compañia",
    en : "The user does not belong to any company"
  },
  empleadoeliminadosatisfactoriamente: {
    es: "Empleado eliminado satisfactoriamente",
    en : "User deleted successfully"
  },
  elempleadocuentaconuncontratoporfavorelimineodesactiveelcontratoantesdedesactivaralempleado: {
    es: "El empleado cuenta con un contrato, por favor elimine o desactive el contrato antes de desactivar al empleado",
    en : "The employee has a contract, please delete or deactivate the contract before deactivating the employee"
  },
  empleadodesactivadosatisfactoriamente: {
    es: "Empleado desactivado satisfactoriamente",
    en : "Employee disabled successfully"
  },
  contactocreadosatisfactoriamente: {
    es: "Contacto creado satisfactoriamente",
    en : "Contact created successfully"
  },
  elcorreoesincorrecto: {
    es: "El correo es incorrecto",
    en : "the email is wrong"
  },
  contactoactualizadosatisfactoriamente: {
    es: "Contacto actualizado satisfactoriamente",
    en : "Contact updated successfully"
  },
  contactoeliminadosatisfactoriamente: {
    es: "Contacto eliminado satisfactoriamente",
    en : "Contact deleted successfully"
  },
 
  
  //EmploymentHistoryService
  elempleadonoestacontratado: {
    es: "El empleado no está contratado",
    en : "The employee is not hired"
  },
  historiallaboralcreadosatisfactoriamente: {
    es: "Historial Laboral creado satisfactoriamente",
    en : "Employment History created successfully"
  },
  historiallaboralactualizadosatisfactoriamente: {
    es: "Historial Laboral actualizado satisfactoriamente",
    en : "Employment History updated successfully"
  },
  historiallaboralelimiandosatisfactoriamente: {
    es: "Historial Laboral elimiando satisfactoriamente",
    en : "Employment History deleted successfully"
  },
   //ExpenseReportEmployeeService
   nocuentaconprivilegiossuficientes:{
    es: "No cuenta con privilegios suficientes",
    en : "You do not have sufficient privileges" 
  },
  sucuentanoestarelacionadaconningúnempleado:{
    es: "Su cuenta no está relacionada con ningún empleado.",
    en : "Your account is not related to any employee."
  },
  yacuentaconunreportegeneradoparaesteperiodo:{
    es: "Ya cuenta con un reporte generado para este periodo.",
    en : "You already have a report generated for this period." 
  },
  reportedegastoscreadosconexito:{
    es: "Reporte de gastos creados con éxito",
    en : "Expense report created successfully" 
  },
  estereportenopertenecealempleadologueado:{
    es: "Este reporte no pertenece al empleado logueado.",
    en : "This report does not belong to the logged in employee." 
  },
  yacuentaconundetalleparaeltipodereporteseleccionado:{
    es: "Ya cuenta con un detalle para el tipo de reporte seleccionado.",
    en : "You already have a detail for the type of report selected." 
  },
  detalledegastoscreadosatisfactoriamente:{
    es: "Detalle de gastos creado satisfactoriamente.",
    en : "Expense detail created successfully." 
  },
  existeunreportependientearevision:{
    es: "Existe un reporte pendiente a revisión",
    en : "There is a report pending review" 
  },
  puedecrearunnuevoreporte:{
    es: "Puede crear un nuevo reporte",
    en : "You can create a new report" 
  },
  elgastonoseencuentradisponible:{
    es: "El gasto no se encuentra disponible.",
    en : "The expense is not available." 
  },
  nopuedeeliminarelgastoalmenosundetalleyahasidorespondido:{
    es: "No puede eliminar el gasto. Al menos un detalle ya ha sido respondido.",
    en : "You cannot eliminate the expense. At least one detail has already been answered." 
  },
  registroeliminadoconexito:{
    es: "Registro eliminado con éxito",
    en : "Record deleted successfully" 
  },
  eldetalledegastonoseencuentradisponible:{
    es: "El detalle de gasto no se encuentra disponible.",
    en : "The expense detail is not available." 
  },
  noesposibleeliminareldetallelasolicitudyahasidorespondida:{
    es: "No es posible eliminar el detalle. La solicitud ya ha sido respondida.",
    en : "It is not possible to delete the detail. The request has already been answered." 
  },

  //ExportDataEmployeeERPService
  esrequeridoeljsondeCompañia:{
    es: "Es requerido el json de Compañia",
    en : "The Company json is required" 
  },
  esrequeridoeljsondelre:{
    es: "Es requerido el json de LRE",
    en : "The LRE json is required" 
  },
  esrequeridoeljsondelredeempleados:{
    es: "Es requerido el json de LRE de empleados",
    en : "The employee LRE json is required" 
  },
  esrequeridoeljsondeempleados:{
    es: "Es requerido el json de Empleados",
    en : "The Employees json is required" 
  },
  esrequeridoeljsondehistorialdeempleados:{
    es: "Es requerido el json de Historial de Empleados",
    en : "The Employee History json is required" 
  },
  esrequeridoeljsondemutualidaddeempleados:{
    es: "Es requerido el json de mutualidad de Empleados",
    en : "The Employee mutuality json is required" 
  },

  //FirmaDigitalService
  elpropietariodelsitiopuedehaberestablecidorestriccionesqueleimpidenaccederaestasolicitud:{
    es: "El propietario del sitio puede haber establecido restricciones que le impiden acceder a está solicitud.",
    en : "The site owner may have set restrictions that prevent you from accessing this request." 
  },
  codigoclienterepetido:{
    es: "Código cliente repetido",
    en : "Repeated Customer Code" 
  },

  //IdentityValidationService
  nonecesitavalidacion:{
    es: "No necesita validación",
    en : "Does not need validation" 
  },
  usuariovalidado:{
    es: "Usuario validado",
    en : "validated user" 
  },
  usuariosinprocesodevalidacionporfavoriniciaruno:{
    es: "Usuario sin proceso de validación, por favor iniciar uno",
    en : "User without validation process, please start one" 
  },
  suvalidacionestasiendoprocesada:{
    es: "Su validación esta siendo procesada",
    en : "Your validation is being processed" 
  },
  suvalidacionfueeliminadarealiceunanuevaporfavor:{
    es: "Su validación fue eliminada, realice una nueva por favor",
    en : "Your validation was deleted, please make a new one" 
  },
  suvalidacionfuerechazadarealiceunanuevaporfavor:{
    es: "Su validación fue rechazada, realice una nueva por favor",
    en : "Your validation was rejected, please make a new one" 
  },
  yacuentaconunapeticionpendienteconsidereeditarla:{
    es: "Ya cuenta con una petición pendiente, considere editarla",
    en : "You already have a pending request, consider editing it" 
  },
  lavalidacionnopuedesereditadayafueevaluadaporsuadministrador:{
    es: "La validación no puede ser editada; ya fue evaluada por su administrador.",
    en : "The validation cannot be edited; It has already been evaluated by its administrator." 
  },
  lavalidacionnopuedesereliminada:{
    es: "La validación no puede ser eliminada",
    en : "Validation cannot be removed" 
  },
  documentosconfirmados:{
    es: "Documentos confirmados",
    en : "Confirmed documents" 
  },

  //IncidentService
  esteempleadonoestaregistrado:{
    es: "Este empleado no esta registrado",
    en : "This employee is not registered" 
  },
  incidentecreadoconexito:{
    es: "Incidente creado con éxito",
    en : "Incident created successfully" 
  },
  incidenteeditadoconexito:{
    es: "Incidente editado con éxito",
    en : "Successfully edited incident" 
  },
  incidenteeliminadoconexito:{
    es: "Incidente eliminado con éxito",
    en : "Incident successfully removed" 
  },

  //IntegrationDiviceService
  noseencontrarondispositivos: {
    es: "No se encontraron dispositivos",
    en : "No devices found"
  },
  eldispositivoyaseencuentravinculado: {
    es: "El dispositivo ya se encuentra vinculado",
    en : "The device is already paired"
  },
  basededatosenuso: {
    es: "Base de datos en uso",
    en : "Database in use"
  },
  credencialesdebasededatosincorrectas: {
    es: "Credenciales de Base de Datos Incorrectas",
    en : "Incorrect Database Credentials"
  },
  dispositivoregistradosatisfactoriamente: {
    es: "Dispositivo registrado satisfactoriamente",
    en : "Device successfully registered"
  },
  dispositivonodisponible: {
    es: "Dispositivo no disponible",
    en : "device not available"
  },
  nosepuedeincluirdosdispositivosenunamismaareadeunasucursal: {
    es: "No se puede incluir dos dispositivos en una misma area de una sucursal",
    en : "Two devices cannot be included in the same area of a branch"
  },
  dispositivoactualizadosatisfactoriamente: {
    es: "Dispositivo actualizado satisfactoriamente",
    en : "Device updated successfully"
  },
  dispositivoeliminadosatisfactoriamente: {
    es: "Dispositivo eliminado satisfactoriamente",
    en : "Device deleted successfully"
  },
  lafechafinnopuedesermenoralafechadeinicio: {
    es: "La fecha fin no puede ser menor a la fecha de inicio",
    en : "The end date cannot be less than the start date"
  },
  laasistenciayahasidoregistradaporeldispositivo: {
    es: "La asistencia ya ha sido registrada por el dispositivo",
    en : "The attendance has already been registered by the device"
  },
  asistenciacreadasatisfactoriamente: {
    es: "Asistencia creada satisfactoriamente",
    en : "Attendance created successfully"
  },
  asistenciamasivacreadasatisfactoriamente: {
    es: "Asistencia masiva creada satisfactoriamente",
    en : "mass attendance created successfully"
  },
  suasistenciaparahoyyahasidoregistrada: {
    es: "Su Asistencia para hoy, ya ha sido registrada.",
    en : "Your Attendance for today has already been recorded."
  },
  laasistenciaparalafechaseleccionadayahasidoregistrada: {
    es: "La asistencia para la fecha seleccionada ya ha sido registrada.",
    en : "Attendance for the selected date has already been recorded."
  },

  //JobInterviewService

  //JobOfferService
  ingresesuscredencialescorrectas:{
    es: "Ingrese sus credenciales correctas",
    en : "Enter your correct credentials" 
  },

  //LoanService
  ellimitemaximoestimadoes:{
    es: "El límite máximo estimado es",
    en : "The estimated maximum limit is" 
  },
  notienesdisponiblesolicitarunprestamo:{
    es: "No tienes disponible solicitar un prestamo",
    en : "You are not available to apply for a loan" 
  },
  noexisteprestamopendiente:{
    es: "No existe prestamo pendiente",
    en : "There is no outstanding loan" 
  },
  pendientedeaprobacion:{
    es: "Pendiente de Aprobación",
    en : "Pending approval" 
  },
  preaprobado:{
    es: "Pre Aprobado",
    en : "Pre Approved" 
  },
  pagado:{
    es: "Pagado",
    en : "Paid" 
  },

  //MarkedAssistsService
  laasistenciaparalafechaseleccionadayaseencuentraregistrada:{
    es: "La asistencia para la fecha seleccionada ya se encuentra registrada.",
    en : "The attendance for the selected date is already registered." 
  },
  asistenciaeditadasatisfactoriamente:{
    es: "Asistencia editada satisfactoriamente",
    en : "Attendance edited successfully" 
  },
  asistenciaeliminadasatisfactoriamente:{
    es: "Asistencia eliminada satisfactoriamente",
    en : "Attendance successfully removed" 
  },

  //ModuleService
  noseencontraronvistas:{
    es: "No se encontraron vistas.",
    en : "No views found." 
  },
  noseencontraronmodulos:{
    es: "No se encontraron módulos.",
    en : "No modules found." 
  },
  elmoduloyaseencuentraregistrado:{
    es: "El módulo ya se encuentra registrado",
    en : "The module is already registered" 
  },
  moduloeliminadosatisfactoriamente:{
    es: "Módulo eliminado satisfactoriamente",
    en : "Module successfully removed" 
  },

  //NotificationsReminderService
  notificacionprogramadacreadaconexito:{
    es: "Notificación programada creada con éxito",
    en : "Scheduled notification created successfully" 
  },
  ustednoeselemisorolanotificaciondejodeestarpendiente:{
    es: "Usted no es el emisor o la notificación dejó de estar pendiente",
    en : "You are not the issuer or the notification is no longer pending" 
  },
  notificacionprogramadaeliminada:{
    es: "Notificación programada eliminada",
    en : "Scheduled notification removed" 
  },

  //NotificationsService
  ustednoeselreceptorolanotificaciondejodeestarpendiente:{
    es: "Usted no es el receptor o la notificación dejó de estar pendiente",
    en : "You are not the recipient or the notification is no longer pending" 
  },
  registromodificadoavisto:{
    es: "Registro modificado a visto",
    en : "Record modified to seen" 
  },
  notienenotificacionessinver:{
    es: "No tiene notificaciones sin ver",
    en : "You have no unseen notifications" 
  },
  registrosmodificadosavisto:{
    es: "Registros modificados a visto",
    en : "Records modified to viewed" 
  },
  notificacioneliminadasatisfactoriamente:{
    es: "Notificación eliminada satisfactoriamente",
    en : "Notification successfully removed" 
  },
  noexisteeltipodenotificacion:{
    es: "No existe el tipo de notificación",
    en : "Notification type does not exist" 
  },

  //OnBoardingService
  noseencontrolaestructura:{
    es: "No se encontró la estructura.",
    en : "The structure was not found." 
  },
  companianoencontrada:{
    es: "Compañia no encontrada",
    en : "Company not found" 
  },

  //PeriodService
  periodocreadoconexito:{
    es: "Periodo creado con éxito",
    en : "Successfully created period" 
  },

  //PermissionsProfileService
  elperfilnoseencuentradisponible:{
    es: "El perfil no se encuentra disponible.",
    en : "The profile is not available." 
  },
  laasignaciondepermisosserealizosatisfactoriamente:{
    es: "La asignación de permisos se realizó satisfactoriamente",
    en : "Permission assignment was successful" 
  },
  laasignaciondepermisosseeditosatisfactoriamente:{
    es: "La asignación de permisos se editó satisfactoriamente",
    en : "Permissions assignment edited successfully" 
  },
  permisoeliminadosatisfactoriamente:{
    es: "Permiso eliminado satisfactoriamente",
    en : "Successfully removed permission" 
  },

  //PermissionsService
  elusuarionoseencuentradisponible:{
    es: "El usuario no se encuentra disponible.",
    en : "The user is not available." 
  },
  permisosagregadossatisfactoriamente:{
    es: "Permisos agregados satisfactoriamente",
    en : "Permissions successfully added" 
  },
  vistanodisponible:{
    es: "Vista no disponible.",
    en : "View not available." 
  },

  //PhaseService

  //PostulantService
  idemployeeesinvalido:{
    es: "id_employee es inválido",
    en : "employee_id is invalid" 
  },
  contrasenaactualizadasatisfactoriamente: {
    es: "Contraseña actualizada satisfactoriamente",
    en : "Password updated successfully"
  },
  lacontrasenaanteriorylanuevacontrasenasoniguales: {
    es: "La contraseña anterior y la nueva contraseña, son iguales",
    en : "The old password and the new password are the same"
  },
  lacontrasenanoescorrecta: {
    es: "La contraseña no es correcta",
    en : "The password is not correct"
  },

  //PreviredService
  elperiodonoseencuentradisponible:{
    es: "El periodo no se encuentra disponible",
    en : "The period isn't available"
  },
  noseencuentraregistrosdelvalvenesteperiodo:{
    es: "No se encuentra registros del valV en este periodo",
    en : "No Vval records found in this period"
  },
  elmesoanonoseencuentrandisponiblesdelafponotieneregistrodeeste:{
    es: "El Mes o Año no se encuentran disponibles del AFP o no tiene registro de este",
    en : "The Month or Year are not available from the AFP or there is no record of this"
  },
  sinmutualempresaentregaaporteaccidentesdeltrabajoalisl:{
    es: "Sin Mutual - Empresa entrega aporte Accidentes del Trabajo al ISL",
    en : "Without Mutual - Company delivers Work Accident contribution to the ISL" 
  },

  //ProductService
  dadodebaja: {
    es: "Dado de baja",
    en: "Dismissed"
  },
  ingreso: {
    es: "Ingreso",
    en: "Entry"
  },
  devolucion: {
    es: "Devolucion",
    en: "Return"
  },
  cambiosucursal: {
    es: "Cambiar Sucursal",
    en: "Change branch"
  },
  prestacion: {
    es: "Beneficio",
    en: "Benefit"
  },
  //ProfileService

  //ProjectService
  proyectocreadoconexito:{
    es: "Proyecto creado con éxito",
    en : "Project created successfully" 
  },
  proyectoactualizadosatisfactoriamente:{
    es: "Proyecto actualizado satisfactoriamente",
    en : "Project successfully updated" 
  },
  proyectoeliminadosatisfactoriamente: {
    es: "Proyecto eliminado satisfactoriamente",
    en : "Project deleted successfully"
  },
  empleadoagregadoalproyecto:{
    es: "Empleado agregado al proyecto",
    en : "Employee added to project" 
  },
  noseencontroeldetalle:{
    es: "No se encontró el detalle.",
    en : "The detail was not found." 
  },
  nosehageneradoremuneracionparaelempleadoenelperiodoseleccionado: {
    es: "No se ha generado remuneración para el empleado en el periodo seleccionado",
    en : "No remuneration has been generated for the employee in the selected period"
  },
  participaciondelempleadocreadosatisfactoriamente: {
    es: "Participación del empleado creado satisfactoriamente",
    en : "Employee participation created successfully"
  },  
  lasumatoriadeporcentajesuperaellimite:{
    es: "La sumatoria de porcentajes supera el 100%",
    en : "The percentage sum exceeds the limit" 
  },
  lasumatoriasicumple: {
    es: "La sumatoria si cumple",
    en : "The sum if it meets"
  },
  lasumatoriadeparticipacionessuperaelsueldoliquidodelmes: {
    es: "La sumatoria de participaciones supera el sueldo liquido del mes",
    en : "The sum of participations exceeds the net salary of the month"
  },

  //RecruitingTeamService

  //RemunerationService
  yasegenerosusremuneracionesacadatrabajador:{
    es: "Ya se genero sus remuneraciones a cada trabajador",
    en : "Their remunerations have already been generated for each worker"
  },
  remuneracionescreadasconexito:{
    es: "Remuneraciones creadas con éxito",
    en: "Successfully created rewards",
  },
  nosepudocrearlasremuneraciones:{
    es: "No se pudo crear las remuneraciones",
    en : "Unable to create remunerations"
  },
  remuneracioncreadaconexito:{
    es: "Remuneracion creada con éxito",
    en : "Remuneration successfully created" 
  },
  existeremuneracionparaesteperiododeestetrabajador:{
    es: "Existe Remuneracion para este periodo de este trabajador",
    en : "There is Remuneration for this period of this worker"
  },
  noexisteremuneracionparaesteperiododeestetrabajador:{
    es: "No existe Remuneracion para este periodo de este trabajador",
    en : "There is no remuneration for this period of this worker"
  },
  noseharegistradoeliniciodesuslaboresalempleadoseleccionado:{
    es: "No se ha registrado el inicio de sus labores al empleado seleccionado",
    en: "The start of work for the selected employee has not been recorded",
  },
  elsueldobasenopuedeserceroomenor:{
    es: "El sueldo base no puede ser cero o menor",
    en : "Base salary cannot be zero or less"
  },
  esteempleadonotieneuntipodegratificacion:{
    es: "Este empleado no tiene un tipo de Gratificación",
    en : "This employee doesn't have a type of Gratuity"
  },
  nosepuedegenerarlaremuneracionsieltotalapagarsaleceroomenor:{
    es: "No se puede generar la remuneración si el total a pagar sale cero o menor",
    en : "Remuneration cannot be generated if the total to be paid is zero or less"
  },
  nohayremuneraciongeneradaparaestetrabajador:{
    es: "No hay remuneracion generada para este trabajador",
    en : "There is no remuneration generated for this worker" 
  },
  nohayremuneraciongeneradas:{
    es: "No hay remuneracion generadas",
    en : "There is no remuneration generated" 
  },
  remuneracionesgeneradasconexito:{
    es: "Remuneraciones generadas con éxito",
    en : "Successfully generated remunerations" 
  },
  noexisteunaliquidacionprevia:{
    es: "No existe una liquidación previa",
    en : "There is no prior settlement" 
  },
  buenosdias:{
    es: "Buenos días",
    en : "Good morning" 
  },
  buenastardes:{
    es: "Buenas tardes",
    en : "Good afternoon" 
  },
  buenasnoches:{
    es: "Buenas noches",
    en : "Good night" 
  },
  mensajeenviado:{
    es: "mensaje enviado",
    en : "Message sent" 
  },
  erroralenviarcorreo:{
    es: "Error al enviar correo",
    en : "Error sending email" 
  },
  mensajenoenviado:{
    es: "Mensaje no enviado",
    en : "Message not sent" 
  },
  mensajesenviados:{
    es: "Mensajes enviados",
    en : "Sent messages" 
  },
  mensajesnoenviados:{
    es: "Mensajes no enviados",
    en : "Unsent messages" 
  },
  noexisteestaliquidacion:{
    es: "No existe esta liquidación",
    en : "This settlement does not exist" 
  },
  lasolicitudseencuentraenestadodesolicitudpendienteespereunarespuestaparaconfirmar:{
    es: "La solicitud se encuentra en estado de solicitud pendiente, espere una respuesta para confirmar",
    en : "The request is in pending request status, please wait for a response to confirm" 
  },
  laliquidacionyafueconfirmada:{
    es: "La liquidación ya fue confirmada",
    en : "The settlement has already been confirmed" 
  },
  liquidacionconfirmada:{
    es: "Liquidación confirmada",
    en : "Confirmed settlement" 
  },
  elempleadonocuentaconunaliquidaciongeneradaenelperiodoactual:{
    es: "El empleado no cuenta con una liquidación generada en el periodo actual",
    en : "The employee does not have a settlement generated in the current period" 
  },
  laliquidacionhasidosuspendidavuelvaagenerarlaliquidacionparareprocesarla:{
    es: "La liquidación ha sido suspendida, vuelva a generar la liquidación para reprocesarla",
    en : "The settlement has been suspended, please regenerate the settlement to reprocess it" 
  },
  aseguresedequetodoslosempleadostenganregistradoeliniciodesuslabores:{
    es: "Asegurese de que todos los empleados tengan registrado el inicio de sus labores",
    en : "Make sure all employees have a record of when they start work" 
  },

  //RemunerationServiceBackup

  //RequestManagementService
  sucuentanoestarelacionadaconningunempleado:{
    es: "Su cuenta no esta relacionada con ningún empleado.",
    en : "Your account is not related to any employee." 
  },
  elusuarionoestarelacionadoconunempleado:{
    es: "El usuario no esta relacionado con un empleado",
    en : "The user is not related to an employee" 
  },
  yaexisteunregistrocreadoconelmismotipodesolicitudparaelmesactual:{
    es: "Ya existe un registro creado con el mismo tipo de solicitud para el mes actual.",
    en : "There is already a record created with the same request type for the current month." 
  },
  ingreseelidremuneration:{
    es: "Ingrese el idremuneration",
    en : "Enter the idremuneration" 
  },
  remuneracioninvalida:{
    es: "Remuneración inválida",
    en : "Invalid remuneration" 
  },
  solicitudcreadasatisfactoriamente:{
    es: "Solicitud creada satisfactoriamente.",
    en : "Request created successfully." 
  },
  solicitudeliminadaconexito:{
    es: "Solicitud eliminada con éxito",
    en : "Request deleted successfully" 
  },
  yaexisteunasolicitudparalaliquidacionseleccionada:{
    es: "Ya existe una solicitud para la liquidación seleccionada.",
    en : "A request already exists for the selected settlement." 
  },
  puedegenerarunasolicitudderevision:{
    es: "Puede generar una solicitud de revisión.",
    en : "You can generate a review request." 
  },

  //ResponsabilityFamilyService
  responsabilidadfamiliarcreadasatisfactoriamente: {
    es: "Responsabilidad Familiar creada satisfactoriamente",
    en : "Family Responsibility created successfully"
  },
  responsabilidadfamiliaractualizadasatisfactoriamente: {
    es: "Responsabilidad Familiar actualizada satisfactoriamente",
    en : "Family Responsibility updates successfully"
  },
  responsabilidadfamiliareliminadasatisfactoriamente: {
    es: "Responsabilidad Familiar eliminada satisfactoriamente",
    en : "Family Responsibility deleted successfully"
  },

  //RLEStructureOptionalService
  elopcionalyaseencuentrapresenteensuempresaporfavormodifiquesuestado:{
    es: "El opcional ya se encuentra presente en su empresa, por favor modifique su estado",
    en : "The optional is already present in your company, please modify its status" 
  },
  estructuraopcionalescreadoconexito:{
    es: "Estructura opcionales creado con éxito",
    en : "Optional structure created successfully"
  },
  estructurasopcionalescreadosconexito:{
    es: "Estructuras opcionales creados con éxito",
    en : "Optional structures created successfully"
  },
  elopcionalnoperteneceasuempresa:{
    es: "El opcional no pertenece a su empresa",
    en : "The optional doesn't belong to your company"
  },

  //RLEStructureService
  estructurayaexistente:{
    es: "Estructura ya existente",
    en : "Already existing structure" 
  },
  estructuracreadasatisfactoriamente: {
    es: "Estructura creada satisfactoriamente",
    en : "Structure created successfully"
  },
  laestructuranoexiste:{
    es: "La estructura no existe",
    en : "Structure does not exist" 
  },
  estructuraeliminadaconexito:{
    es: "Estructura eliminada con éxito",
    en : "Structure successfully removed" 
  },

  //SessionProfileService
  noseencontraronperfiles:{
    es: "No se encontraron perfiles.",
    en : "No profiles found." 
  },
  elperfilyaseencuentraregistrado:{
    es: "El perfil ya se encuentra registrado",
    en : "The profile is already registered" 
  },
  elperfilysuasignaciondevistasfueroncreadassatisfactoriamente:{
    es: "El perfil y su asignación de vistas fueron creadas satisfactoriamente.",
    en : "The profile and its view assignment were created successfully." 
  },
  elperfildesessionnoseencuentradisponible:{
    es: "El perfil de sessión no se encuentra disponible",
    en : "Session profile is not available" 
  },
  vistasdelperfileditadassatidfactoriamente:{
    es: "Vistas del perfil editadas satidfactoriamente",
    en : "Satisfactorily edited profile views" 
  },
  perfileliminadosatisfactoriamente:{
    es: "Perfil eliminado satisfactoriamente",
    en : "Profile Deleted Successfully" 
  },

  //SessionProfileViewService
  noseencontroelperfil:{
    es: "No se encontró el perfil",
    en : "Profile not found" 
  },
  vistasasiganadassatisfactoriamente:{
    es: "Vistas asiganadas satisfactoriamente",
    en : "Views successfully assigned" 
  },
  eliminadosatisfactoriamente:{
    es: "Eliminado satisfactoriamente",
    en : "Successfully removed" 
  },

  //TimeZoneService
  yaseencuentraregistradoestazonahoraria:{
    es: "Ya se encuentra registrado esta zona horaria",
    en : "This time zone is already registered" 
  },
  zonahorariacreadaconexito:{
    es: "Zona horaria creada con éxito",
    en : "Time zone created successfully" 
  },
  lazonahorarianoexiste:{
    es: "La Zona horaria no existe",
    en : "Time zone does not exist" 
  },
  zonahorariaeditadaconexito:{
    es: "Zona horaria editada con éxito",
    en : "Successfully edited time zone" 
  },
  lazonahorariaestasiendousada:{
    es: "La Zona horaria esta siendo usada",
    en : "Time zone is being used" 
  },
  zonahorariaeliminada:{
    es: "Zona horaria eliminada",
    en : "Removed time zone" 
  },

 //TypeRequestService
  tipodesolicitudeliminado:{
    es: "Tipo de solicitud eliminado",
    en : "Request type removed" 
  },
  //TypeExpenseService
  eltipodegastoyaseencuentraregistrado: {
    es: "El tipo de gasto ya se encuentra registrado",
    en : "The type of expense is already registered"
  },
  tipodegastocreadosatisfactoriamente: {
    es: "Tipo de gasto creado satisfactoriamente",
    en : "Type of expense created successfullyy"
  },
  tipodegastoactualizadosatisfactoriamente: {
    es: "Tipo de gasto actualizado satisfactoriamente",
    en : "Type of expense updated successfully"
  },
  tipodegastoeliminadosatisfactoriamente: {
    es: "Tipo de gasto eliminado satisfactoriamente",
    en : "Type of expense deleted successfully"
  },

   //UserService
   sesioncerrada: {
    es: "Sesión cerrada",
    en : "closed session"
  },
  usuarionoencontrado:{
    es: "Usuario no encontrado",
    en : "User not found" 
  },
  empresanoencontrada:{
    es: "Empresa no encontrada",
    en : "Company not found" 
  },

  elnombredeusuarioyaseencuentraregistrado: {
    es: "El nombre de usuario ya se encuentra registrado",
    en : "Username is already registered"
  },
  elempleadonoseencuentraregistrado: {
    es: "El empleado no se encuentra registrado",
    en : "The employee is not registered"
  },
  elusuarionoexiste: {
    es: "El usuario no existe",
    en : "Username does not exist"
  },
  usuarioactualizadosatisfactoriamente: {
    es: "Usuario actualizado satisfactoriamente",
    en : "User updated successfully"
  },
  eliddeusuarioesrequerido: {
    es: "El id de usuario es requerido",
    en : "The user id is required"
  },
  ocurrioalgunerrorintentelomastarde: {
    es: "Ocurrió algún error, intentelo mas tarde",
    en : "An error occurred, please try again later."
  },
  noseencontroalusuario: {
    es: "No se encontro al usuario",
    en : "User not found"
  },
  elusuarionoperteneceaningunacompania: {
    es: "El usuario no pertenece a ninguna compañia",
    en : "The user does not belong to any company"
  },
  usuarioeliminadosatisfactoriamente: {
    es: "Usuario eliminado satisfactoriamente",
    en : "User deleted successfully"
  },
  correoelectronicoenviadosatisfactoriamente: {
    es: "Correo electrónico enviado satisfactoriamente",
    en : "Email sent successfully"
  },
  erroralcrearusuario: {
    es: "Error al crear Usuario",
    en : "Error creating User"
  },
  elcorreoyaseencuentraregistrado: {
    es: "El correo ya se encuentra registrado",
    en : "The email is already registered"
  },
  
  //ValidationSignatureService
  nonecesitavalidaciondefirma:{
    es: "No necesita validación de firma",
    en : "No signature validation required"
  },
  faltafirmadigital:{
    es: "Falta firma Digital",
    en : "Digital signature missing" 
  },
  sucontratofuefirmadomediantefirmadigital:{
    es: "Su contrato fue firmado mediante Firma Digital.",
    en : "Your contract was signed using a Digital Signature." 
  },
  faltasubirdatosdesufirma:{
    es: "Falta subir datos de su firma",
    en : "You need to upload your signature data" 
  },
  laverificaciondelafirmaestaenproceso:{
    es: "La verificación de la firma esta en proceso",
    en : "Signature verification is in process" 
  },

  firmaverificada: {
    es: "Firma verificada",
    en: "Verified signature"
  },
  solicituddeverificacioneliminada: {
    es: "Solicitud de verificación eliminada",
    en: "Removed verification request"
  },
  verificacionrechazada: {
    es: "Verificación rechazada",
    en: "Rejected verification"
  },
  nodata:{
    es: "No data",
    en : "Does not date" 
  },

  nocumpleconlosrequisitosparapodervalidarsufirma:{
    es: "No cumple con los requisitos para poder validar su firma",
    en : "You do not meet the requirements to validate your signature" 
  },
  lavalidacionnopuedesereditada:{
    es: "La validación no puede ser editada",
    en : "Validation cannot be edited" 
  },
  //VariableGlobalService
  yaexistevariablesglobalesparaesafechaeditalas:{
    es: "Ya existe Variables globales para esa fecha, editalas",
    en : "Global Variables already exist for that date, edit them" 
  },

  //TimeZoneService
  variablesglobalescreadasconexito:{
    es: "Variables globales creadas con éxito",
    en : "Global variables created successfully"
  },
  variablesglobaleseditadaconexito:{
    es: "Variables globales editada con éxito",
    en : "Global variables edited successfully" 
  },
  variablesglobaleseliminadapermanentemente:{
    es: "Variables globales eliminada permanentemente",
    en : "Global variables permanently removed"
  },
  //ViewService
  noseencontraronvistasdisponibles:{
    es: "No se encontraron vistas disponibles.",
    en : "No available views found." 
  },
  elusuarionocuentaconunperfilasignado:{
    es: "El usuario no cuenta con un perfil asignado",
    en : "The user does not have a profile assigned" 
  },
  vistaeliminadasatisfactoriamente:{
    es: "Vista eliminada satisfactoriamente",
    en : "View Successfully Deleted" 
  },
  
  //WorkerSchedulesService
  horariocreadosatisfactoriamente: {
    es: "Horario creado satisfactoriamente",
    en : "Schedule created successfully"
  },
  horarioeditadosatisfactoriamente: {
    es: "Horario editado satisfactoriamente",
    en : "Schedule updated successfully"
  },
  horarioeliminadosatisfactoriamente: {
    es: "Horario eliminado satisfactoriamente",
    en : "Schedule deleted successfully"
  },
  eliniciodecolacionnopuedesermayoralfinaldecolacion: {
    es: "El inicio de colación no puede ser mayor al final de colación",
    en : "The start of the collation cannot be greater than the end of the collation"
  },
  aseguresedeseleccionarlosdiaslaborales: {
    es: "Asegurese de seleccionar los dias laborales",
    en : "Be sure to select business days"
  },
  aseguresedeelegirunhorario: {
    es: "Asegurese de elegir un horario",
    en : "Be sure to pick a time"
  },

   /* ---------------------------- ---- ----------------------------  */

   //asistencia
  selecioneunafechadeinicioyfinparabuscarlasasistencias:{
    es: "Seleccione una fecha de incio y fin para buscar las asistencias.",
    en : "Select a start and end date to search for attendance." 
  },
  aseguresequelafechainicialseamenorquelafinal:{
    es: "Asegurese que la fecha inicial sea menor que la final.",
    en : "Make sure the start date is less than the end date." 
  },
  lafechafinnopuedesermayoralafechactual:{
    es: "La fecha fin no puede ser mayor a la fecha actual.",
    en : "The end date cannot be greater than the current date." 
  },

  //REQUEST
  lasolicitudaunnohasidorespondida:{
    es: "La solicitud aún no ha sido respondida.",
    en : "The request has not yet been answered." 
  },
  lasolicitudhasidorechazada:{
    es: "La solicitud ha sido rechazada.",
    en : "The request has been rejected." 
  },
  estadodesolicitudactualizadoconexito:{
    es: "Estado de solicitud actualizado con éxito.",
    en : "Application status updated successfully." 
  },
  nocuentasconunaliquidaciongeneradaenelmesactual:{
    es: "No cuentas con una liquidación generada en el mes actual.",
    en : "You do not have a settlement generated in the current month." 
  },
  lafechainicialtienequeencontrarsedentrodelmesdelaliquidacion:{
    es: "La fecha inicial tiene que encontrarse dentro del mes de la liquidación",
    en : "The initial date must be within the month of the settlement" 
  },
  deseaaceptarorechazarlasolicitud:{
    es: "¿Desea Aceptar o Rechazar la Solicitud?",
    en : "Do you want to Accept or Reject the Request?" 
  },
  agregarSolicitud:{
    es: "Agregar Solicitud",
    en : "Add Request" 
  },
  motivoesuncamporequerido:{
    es: "Motivo es un campo requerido",
    en : "Reason is a required field" 
  },
  fechaInicial:{
    es: "Fecha Inicial",
    en : "Initial date" 
  },
  fechaInicialEsUnCampoRequerido:{
    es: "Fecha Inicial es un campo requerido",
    en : "Start Date is a required field" 
  },
  fechaFinal:{
    es: "Fecha Final",
    en : "Final date" 
  },
  fechaFinalEsUncampoRequerido:{
    es: "Fecha Final es un campo requerido",
    en : "End Date is a required field" 
  },
  observacionEsUncampoRequerido:{
    es: "Observación es un campo requerido",
    en : "Observation is a required field" 
  },
  
  //validations
  aprobado:{
    es: "Aprobado",
    en : "Approved" 
  },
  rechazado:{
    es: "Rechazado",
    en : "Refused" 
  },
  rechazada:{
    es: "Rechazada",
    en : "Refused" 
  },
  pendiente:{
    es: "Pendiente",
    en : "Pending" 
  },
  aceptada:{
    es: "aceptada",
    en : "accepted" 
  },
  revisado:{
    es: "Revisado",
    en : "Reviewed" 
  },
  norevisado:{
    es: "No Revisado",
    en : "Not reviewed" 
  },
  actualizarvalidaciondeidentidad:{
    es: "Actualizar Validación de Identidad",
    en : "Update Identity Validation" 
  },
  enviarvalidacion:{
    es: "Enviar Validación",
    en : "Send Validation" 
  },
  laverificaciondeidentidadestaenproceso:{
    es: "La verificación de identidad está en proceso",
    en : "Identity verification is in process" 
  },
  identidadverificada:{
    es: "Identidad verificada",
    en : "verified identity" 
  },
  verificaciondeidentidadeliminada:{
    es: "Verificación de identidad eliminada",
    en : "Identity verification removed" 
  },
  verificaciondeidentidadrechazada:{
    es: "Verificación de identidad rechazada",
    en : "Identity verification rejected" 
  },

  //solicitud de gastos
  editarestadodereporte:{
    es: "Editar estado de reporte",
    en : "Edit report status" 
  },
  agregarreporte:{
    es: "Agregar reporte",
    en : "Add report" 
  },
  estadoreporte:{
    es: "Estado reporte",
    en : "Report status" 
  },

  //resource
   descuentoLiquidacion:{
    es: 'Descuento Liquidación',
    en: 'Remuneration Discount'
  },
  descuentoVacaciones:{
    es: 'Descuento Vacaciones',
    en: 'Holiday Discount'
  },
  cantidad: {
    es: 'cantidad',
    en: 'amount'
  },
  cantidadd: {
    es:'Cantidad',
    en:'Amount'
  },
  porcentaje: {
    es:'porcentaje',
    en: 'percentage'
  },
  sinSucursal: {
    es: 'Sin sucursal',
    en: 'No branch'
  },
  sinNombre: {
    es: 'Sin nombre',
    en: 'Nameless'
  },
  eliminado: {
    es: 'Eliminado',
    en: 'Removed'
  },
  activado: {
    es: 'ACTIVADO',
    en: 'ACTIVATED'
  },
  Desactivado: {
    es: 'DESACTIVADO',
    en:'DISABLED'
  },
  sinDocumento: {
    es: 'Sin documento',
    en: 'without document'
  },
  aceptado: {
    es: 'Aceptado',
    en: 'Accepted'
  },

  //VALIDACIONES FORMS
  nombreRequerido: {
    es: "nombre requerido",
    en: "name is required"
  },
  fecharequerido: {
    es: "fecha requerido",
    en: "date is required"
  },
  tipoinasistenciarequerido: {
    es: "tipo inasistencia requerido",
    en: "type of absence is required"
  },
  observacionrequerido: {
    es: "observación requerido",
    en: "observation is required"
  },
  apellpaternorequerido: {
    es: "apell. paterno requerido",
    en: "paternal surname is required"
  },
  passwordRequerido: {
    es: "password requerido",
    en: "password is required"
  },
  apellmaternoRequerido: {
    es: "apell. materno requerido",
    en: "mother's last name is required"
  },
  rutRequerido: {
    es: "rut requerido",
    en: "rut is required"
  },
  direccionRequerido: {
    es: "dirección requerido",
    en: "address is required"
  },
  telefonoRequerido: {
    es: "telefono requerido",
    en: "phone is required"
  },
  emailRequerido: {
    es: "email requerido",
    en: "email is required"
  },
  fechaNacimientoRequerido: {
    es: "fecha nacimiento requerido",
    en: "date of birth is required"
  },
  generoRequerido: {
    es: "género requerido",
    en: "gender is required"
  },
  codigoRequerido: {
    es: "codigo requerido",
    en: "code is required"
  },
  telefonoNoValido: {
    es: "teléfono no válido",
    en: "invalid phone"
  },
  maximo50Caracteres: {
    es: "máximo 50 caracteres",
    en: "maximum 50 characters"
  },
  descripcionRequerido: {
    es: "descripción requerido",
    en: "description is required"
  },
  negocioRequerido: {
    es: "negocio requerido",
    en: "business is required"
  },
  añoNoValido: {
    es: "año no válido",
    en: "invalid year"
  },
  noValido: {
    es: "no válido",
    en: "invalid"
  },
  ingreseAño: {
    es: "Ingrese año",
    en: "Enter year"
  },
  ingrese: {
    es: "Ingrese",
    en: "Enter"
  },
  ingreseAdicional: {
    es: "Ingrese Adicional",
    en: "Enter Additional"
  },
  estaSeguroQueDeseaEliminarEstasVariablesCM: {
    es: "¿Esta seguro que desea eliminar estas variables CM?",
    en: "Are you sure you want to delete these CM variables?"
  },
  totalTardanzasRequerido: {
    es: "total tardanzas requerido",
    en: "total delay is required"
  },
  cantidadDeHorasExcedidas: {
    es: "cantidad de horas excedidas",
    en: "number of hours exceeded"
  },
  noPuedeExcederLos150Caracteres: {
    es: "No puede exceder los 150 caracteres",
    en: "Cannot exceed 150 characters"
  },
  numeroDeReporteRequerido: {
    es: "numero de reporte requerido",
    en: "report number required"
  },
  tipoDeReporteRequerido: {
    es: "tipo de reporte requerido",
    en: "type of report required"
  },
  detalleRequerido: {
    es: "detalle requerido",
    en: "detail is required"
  },
  montoRequerido: {
    es: "monto requerido",
    en: "amount is required"
  },
  documentoRequerido: {
    es: "documento requerido",
    en: "amount is required"
  },
  estadoRequerido: {
    es: "estado requerido",
    en: "state is required"
  },
  porcentajeRequerido: {
    es: "porcentaje requerido",
    en: "percentage is required"
  },
  estructuraRequerido: {
    es: "estructura requerido",
    en: "structure is required"
  },
  horaRequerido: {
    es: "hora requerido",
    en: "time is required"
  },
  funcionRequerido: {
    es: "función requerido",
    en: "function is required"
  },
  rolRequerido: {
    es: "rol requerido",
    en: "role is required"
  },
  laFechaDebeCorresponderAlMesYAñoActual: {
    es: "La fecha debe corresponder al mes y año actual",
    en: "The date must correspond to the current month and year"
  },
  laCantidadDeHorasNoPuedeSerSuperiorA9: {
    es: "La cantidad de horas no puede ser superior a 9",
    en: "The number of hours cannot be more than 9"
  },
  nombreDelFamiliar: {
    es: "nombre del familiar",
    en: "family name"
  },
  estaSeguroQueDeseaEliminarEstaPolitica: {
    es: "¿Esta seguro que desea eliminar esta Politica?",
    en: "Are you sure you want to delete this Policy?"
  },
  noPuedesSolicitarMasDelLimite: {
    es: "No puedes solicitar mas del límite",
    en: "You cannot request more than the limit"
  },
  estaSeguroDeEliminarEsteHorario: {
    es: "¿Está seguro de eliminar este horario?",
    en: "Are you sure to delete this schedule?"
  },
  lunes: {
    es: "Lunes",
    en: "Monday"
  },
  martes: {
    es: "Martes",
    en: "Tuesday"
  },
  miercoles: {
    es: "Miercoles",
    en: "Wednesday"
  },
  jueves: {
    es: "Jueves",
    en: "Thursday"
  },
  viernes: {
    es: "Viernes",
    en: "Friday"
  },
  sabado: {
    es: "Sabado",
    en: "Saturday"
  },
  domingo: {
    es: "Domingo",
    en: "Sunday"
  },
  esteCampoEsRequerido: {
    es: "Este campo es requerido",
    en: "This field is required"
  },
  noPuedeExcederLos100Caracteres: {
    es: "No puede exceder los 100 caracteres",
    en: "Cannot exceed 100 characters"
  },
  tipoDeRecordatorio: {
    es: "Tipo de Recordatorio",
    en: "Reminder Type"
  },
  estaSeguroDeEliminarEsteTipoDeRecordatorio: {
    es: "¿Está seguro de eliminar este tipo de recordatorio?",
    en: "Are you sure to delete this type of reminder?"
  },
  nombreDeUsuarioRequerido: {
    es: "nombre de usuario requerido",
    en: "username is required"
  },
  nombreDeUsuarioDebeTenerComoMaximo20Caracteres: {
    es: "nombre de usuario debe tener como máximo 20 caracteres",
    en: "username must be a maximum of 20 characters"
  },
  soloDebeContenerLetrasMinúsculas: 
  {
    es: "solo debe contener letras minúsculas",
    en: "must only contain lowercase letters"
  },
  numeroDeDocumentoRequerido: {
    es: "numero de documento requerido",
    en: "required document number"
  },
  numeroDeDocumentoNoValido: {
    es: "numero de documento no válido",
    en: "invalid document number"
  },
  perfilRequerido: {
    es: "perfil requerido",
    en: "profile is required"
  },
  topeDeCotizacion: {
    es: "Tope de Cotizacion ",
    en: "Quote Cap"
  },
  topeDeCesantia: {
    es: "Tope de Cesantia",
    en: "Cesantia Stop"
  },
  remuneracionMinima: {
    es: "Remuneracion Mínima",
    en: "Minimum Remuneration"
  },
  tramoFamilia: {
    es: "Tramo Familia",
    en: "Family Section"
  },
  rutaRequerido: {
    es: "Ruta es requerido",
    en: "Path is required"
  },
  iconoRequerido: {
    es: "Icono es requerido",
    en: "Icon is required"
  },
  moduloRequerido: {
    es: "Modulo es requerido",
    en: "Module is required"
  },
  editarVista: {
    es: "Editar Vista",
    en: "Edit View"
  },
  agregarVista: {
    es: "Agregar Vista",
    en: "Add View"
  },
  icono: {
    es: "Icono",
    en: "Icon"
  },
  estaSeguroDeEliminarEstaVista: {
    es: "¿Está seguro de eliminar esta vista?",
    en: "Are you sure to delete this view?"
  },
  telefonoFijoRequerido: {
    es: "telefono fijo requerido",
    en: "landline required"
  },
  pension: {
    es: "Pensión",
    en: "Pension"
  },
  sinInstitucionprevisional: {
    es: "Sin institución previsional",
    en: "Without pension institution"
  },
  contenidoNoAutorizado: {
    es: "Contenido no autorizado",
    en: "Unauthorized content"
  },
  soloSeAceptanNumerosDel0Al1: {
    es: "solo se aceptan números del 0 al 1",
    en: "Only numbers from 0 to 1 are accepted"
  },
  accesoRequerido: {
    es: "acceso requerido",
    en: "access required"
  },
  soloSeAceptanNumerosEnteros: {
    es: "solo se aceptan numeros enteros",
    en: "Only integers are accepted"
  },
  encontramosUnRegistroAprobadoExistenteNoEsNecesarioVolverAEnviarTuValidacionDeIdentidad: {
    es: "Encontramos un registro aprobado existente, no es necesario volver a enviar tu validación de identidad",
    en: "We found an existing approved record, there is no need to resubmit your identity validation"
  },
  yaCuentaConUnValidacionEnEstadoPendienteConsidereEditarla: {
    es: "Ya cuenta con un validación en estado pendiente, considere editarla",
    en: "You already have a validation in a pending state, consider editing it"
  },
  nombreCompleto: {
    es: "Nombre Completo",
    en: "Full name"
  },
  fondoDePensiones: {
    es: "Fondo de pensiones",
    en: "Pension fund"
  },
  sistemaDeSalud: {
    es: "Sistema de salud",
    en: "Healthcare system"
  },
  baseTributable: {
    es: "Base tributable",
    en: "Tax base"
  },
  sueldoBase: {
    es: "Sueldo base",
    en: "Base salary"
  },
  totalSalud: {
    es: "Total salud",
    en: "Total health"
  },
  datosDelEmpleado: {
    es: "Datos del empleado",
    en: "Employee data"
  },
  VERPDF: {
    es: "VER PDF", 
    en: "SEE PDF"
  },
  valorDeHoraExtrasPorEmpleado: {
    es: "Valor de hora extras por empleado",
    en: "Value of overtime per employee"
  },
  liquidacionesActivaDeTrabajadores: {
    es: "Liquidaciones activa de Trabajadores",
    en: "Active settlements of Workers"
  },


  //previred

  rutTrabajadorCod1101: {
    es: "Rut trabajador (cód 1101)",
    en: "Rut worker (code 1101)"
  },
  fechaInicioContratoCod1102: {
    es: "Fecha inicio contrato (cód 1102)",
    en: "Contract start date (code 1102)"
  },
  fechaDeTerminoDeContratoCod1103: {
    es: "Fecha de término de contrato (cód 1103)",
    en: "Contract end date (code 1103)"
  },
  causalDeTerminoDelContratoCod1104: {
    es: "Causal de término del contrato (cód 1104)",
    en: "Cause for termination of the contract (code 1104)"
  },
  regionDePrestacionDeLosServiciosCod1105: {
    es: "Región de prestación de los servicios (cód 1105)",
    en: "Region of provision of services (code 1105)"
  },
  comunaDePrestacionDeLosServiciosCod1106: {
    es: "Comuna de prestación de los servicios (cód 1106)",
    en: "Commune of provision of services (code 1106)"
  },
  tipoDeImpuestoALaRentaCod1170: {
    es: "Tipo de impuesto a la renta (cód 1170)",
    en: "Type of income tax (code 1170)"
  },
  tecnicoExtranjeroExencionDeCotizacionesPrevisionalesLey18156Cod1146: {
    es: "Técnico extranjero exención de cotizaciones previsionales (Ley 18.156) (cód 1146)",
    en: "Foreign technician exemption from pension contributions (Law 18,156) (code 1146)"
  },
  codigoTipoDeJornadaCod1107: {
    es: "Código tipo de jornada (cód 1107)",
    en: "Day type code (code 1107)"
  },
  personaConDiscapacidadPensionadoPorInvalidezCod1108: {
    es: "Persona con discapacidad/pensionado por invalidez (cód 1108)",
    en: "Person with a disability/disability pensioner (code 1108)"
  },
  pensionadoPorVejezCod1109: {
    es: "Pensionado por vejez (cód 1109)",
    en: "Pensioner due to old age (code 1109)"
  },
  afpCod1141: {
    es: "AFP (cód 1141)",
    en: "AFP (cód 1141)"
  },
  orgAdministradorLey16744Cod1152: {
    es: "Org. administrador ley 16.744 (cód 1152)",
    en: "Org. administrator law 16,744 (code 1152)"
  },
  numeroCargasFamiliaresLegalesAutorizadasCod1111: {
    es: "Número cargas familiares legales autorizadas (cód 1111)",
    en: "Number of authorized legal family members (code 1111)"
  },
  numeroDeCargasFamiliaresMaternalesCod1112: {
    es: "Número de cargas familiares maternales (cód 1112)",
    en: "Number of maternal family responsibilities (code 1112)"
  },
  numeroDeCargasFamiliaresInvalidezCod1113: {
    es: "Número de cargas familiares invalidez (cód 1113)",
    en: "Number of disability family dependents (code 1113)"
  },
  tramoAsignacionFamiliarCod1114: {
    es: "Tramo asignación familiar (cód 1114)",
    en: "Family allowance section (code 1114)"
  },
  rutOrgSindical1Cod1171: {
    es: "Rut org sindical 1 (cód 1171)",
    en: "Rut union org 1 (code 1171)"
  },
  rutOrgSindical2Cod1172: {
    es: "Rut org sindical 2 (cód 1172)",
    en: "Rut union org 2 (code 1172)"
  },
  rutOrgSindical3Cod1173: {
    es: "Rut org sindical 3 (cód 1173)",
    en: "Rut union org 3 (code 1173)"
  },
  rutOrgSindical4Cod1174: {
    es: "Rut org sindical 4 (cód 1174)",
    en: "Rut union org 4 (code 1174)"
  },
  rutOrgSindical5Cod1175: {
    es: "Rut org sindical 5 (cód 1175)",
    en: "Rut union org 5 (code 1175)"
  },
  rutOrgSindical6Cod1176: {
    es: "Rut org sindical 6 (cód 1176)",
    en: "Rut union org 6 (code 1176)"
  }, 
  rutOrgSindical7Cod1177: {
    es: "Rut org sindical 7 (cód 1177)",
    en: "Rut union org 7 (code 1177)"
  },
  /*------------------ COMBOX LLENADOS POR SEEDER ------------------------*/

  //types of request
  liquidacion: {
    es: "Liquidación",
    en: "Settlement"
  },
  horasdecompensancion: {
    es: "Horas de compensanción",
    en: "Compensation hours"
  },
  vacaciones: {
    es: "Vacaciones",
    en: "Vacation"
  },
  justificaciondeinasistencias: {
    es: "Justificación de inasistencias",
    en: "Justification of absences"
  },

  //type of company
  privada: {
    es: "Privada",
    en: "Private"
  },

  //type of document
  definiciondepoliticasprivacidadyseguridad: {
    es: "Definición de políticas, privacidad y seguridad.",
    en: "Definition of policies, privacy and security"
  },
  reglamentointerno: {
    es: "Reglamento interno.",
    en: "Rules of Procedure."
  },
  almacenarydisponerlaspoliticasprivacidadydocumentosrelacionados: {
    es: "Almacenar y disponer las políticas, privacidad y documentos relacionados.",
    en: "Store and dispose of policies, privacy and related documents."
  },

  //civil status
  solteroa: {
    es: "Soltero(a)",
    en: "single - single woman"
  },
  casadoa: {
    es: "Casado(a)",
    en: "married"
  },
  separadoa: {
    es: "Separado(a)",
    en: "separated"
  },
  viudoa: {
    es: "Viudo(a)",
    en: "Widower - Widow"
  },
  divorciadoa: {
    es: "Divorciado(a)",
    en: "divorced"
  },
  //eductation level
  certificadosprofesionales : {
    es: "Certificados profesionales",
    en: "Professional certificates"
  },
  postgrado: {
    es: "Post grado",
    en: "Postgraduate"
  },
  universitaria: {
    es: "Universitaria",
    en: "University"
  },
  tecnica: {
    es: "Tecnica",
    en: "Technique"
  },
  Media: {
    es: "Media",
    en: "Half"
  },
  Basica: {
    es: "Basica",
    en: "Basic"
  },

  //Military Situacions
  efectuado: {
    es: "Efectuado",
    en: "Done"
  },
  eximido: {
    es: "Eximido",
    en: "Exempted"
  },
  postergado: {
    es: "Postergado",
    en: "Postponed"
  },
  inscritodisponible: {
    es: "Inscrito disponible",
    en: "Registered available"
  },
  sinserviciomilitar: {
    es: "Sin servicio militar",
    en: "No military service"
  },

  //Proffesion
  ingenieriadesistemas: {
    es: "Ingeniería de Sistemas",
    en: "Systems engineer"
  },
  medicinahumana: {
    es: "Medicina Humana",
    en: "Human medicine"
  },
  ingenieriametalurgica: {
    es: "Inegniería metalúrgica",
    en: "Metallurgical engineering"
  },

  //Nationality
  chileno : {
    es: "Chileno",
    en: "Chilean"
  },
  extranjero: {
    es: "Extranjero",
    en: "Foreign"
  },
  peruano: {
    es: "Peruano",
    en: "Peruvian"
  },

  //Type branch
  urbanas : {
    es: "Urbanas",
    en: "Urban"
  },
  rurales: {
    es: "Rurales",
    en: "Rural"
  },
  industriales: {
    es: "Industriales",
    en: "Industrial"
  },
  principal: {
    es: "Principal",
    en: "main"
  },
  teletrabajo: {
    es: "Teletrabajo",
    en: "Telecommuting"
  },

  //type discount
  descuentoliquidacion:{
    es: 'Descuento Liquidación',
    en: 'Remuneration Discount'
  },
  descuentovacaciones:{
    es: 'Descuento Vacaciones',
    en: 'Holiday Discount'
  },

  //type report
  otrogasto:{
    es: 'Otro gasto',
    en: 'Other expense'
  },
  movilidad:{
    es: 'Movilidad',
    en: 'Mobility'
  },
  colacion:{
    es: 'Colación',
    en: 'Collation'
  },
  alquiler:{
    es: 'Alquiler',
    en: 'Rent'
  },

  //type employee
  activonopensionado:{
    es: 'Activo (No pensionado)',
    en: 'Active (Not pensioned)'
  },
  pensionadoycotiza:{
    es: 'Pensionado y cotiza',
    en: 'Retired and contributions'
  },
  pensionadoynocotiza:{
    es: 'Pensionado y no cotiza',
    en: 'Retired and does not contribute'
  },
  activo65anosnuncapensionado:{
    es: 'Activo > 65 años (Nunca pensionado)',
    en: 'Active > 65 years old (Never retired)'
  },

  //estate worker
  desactivo: {
    es: "Desactivado",
    en: "Disabled"
  },

  //type duration contract
  anual: {
    es: "Anual",
    en: "Annual"
  },
  mensual: {
    es: "Mensual",
    en: "Monthly"
  },

  //type of contract
  plazofijo: {
    es: "Plazo fijo",
    en: "Fixed term"
  },

  //type account
  sintipodecuenta: {
    es: "Sin tipo de cuenta",
    en: "No account type"
  },
  cuentadeahorro: {
    es: "Cuenta de ahorro",
    en: "Savings account"
  },
  cuentacorriente: {
    es: "Cuenta Corriente",
    en: "Current account"
  },
  cuentavista: {
    es: "Cuenta Vista",
    en: "Vista Account"
  },
  lineadecredito: {
    es: "Linea de Credito",
    en: "Credit line"
  },
  tarjetadecredito: {
    es: "Tarjeta de Credito",
    en: "Credit card"
  },

  //way pay
  efectivo: {
    es: "Efectivo",
    en: "CashCredit"
  },
  cheque: {
    es: "Cheque",
    en: "Check"
  },
  valevista: {
    es: "Vale vista",
    en: "Ok view"
  },
  deposito: {
    es: "Depósito",
    en: "Deposit"
  },
  transferencia: {
    es: "Transferencia",
    en: "Transfer"
  },

  //family strech
  primertramo: {
    es: "Primer Tramo",
    en: "First stretch"
  },
  segundotramo: {
    es: "Segundo Tramo",
    en: "Second stretch"
  },
  tercertramo: {
    es: "Tercer Tramo",
    en: "Third stretch"
  },
  sinderecho: {
    es: "Sin Derecho",
    en: "No right"
  },

  //type cto salud
  porcentajefijo: {
    es: "P orcentaje Fijo",
    en: "Fixed Percentage"
  },
  pesosfijos: {
    es: "Pesos Fijos",
    en: "Fixed Weights"
  },
  ufvariable: {
    es: "U.F. Variable",
    en: "U.F. Variable"
  },

  //cost center
  operaciones: {
    es: "Operaciones",
    en: "Operations"
  },
  ventas: {
    es: "Ventas",
    en: "Sales"
  },
  desarrollo: {
    es: "Desarrollo",
    en: "Development"
  },
  insumos: {
    es: "Insumos",
    en: "Supplies"
  },
  prorrateodecentrodecosto: {
    es: "Prorrateo de centro de costo",
    en: "Cost center proration"
  },
  //charge
  gerentefactoring: {
    es: "Gerente factoring",
    en: "Factoring manager"
  },
  programador: {
    es: "Programador",
    en: "Developer"
  },
  gerentenuevosnegocios: {
    es: "Gerente nuevos negocios",
    en: "New business manager"
  },
  encargadagestiondepersonas: {
    es: "Encargada gestión de personas",
    en: "People management manager"
  },
  gerentegeneral: {
    es: "Gerente General",
    en: "General manager"
  },
  gerentegeneralareafactoring: {
    es: "Gerente general area factoring",
    en: "General manager factoring area"
  },
  gerencianuevosnegocios: {
    es: "Gerencia nuevos negocios",
    en: "New business management"
  },

  //bussisnes area
  nuevosnegocios: {
    es: "Nuevos negocios",
    en: "New business"
  },
  administracion: {
    es: "Administracion",
    en: "Administration"
  },
  produccion: {
    es: "Producción",
    en: "Production"
  },
  contabilidad: {
    es: "Contabilidad",
    en: "Accounting"
  },
  factoring: {
    es: "FACTORING",
    en: "FACTORING"
  },

  //input type file
  porfavorseleccionaunarchivodeimagenvalidojpegpngogif: {
    es: "Por favor, selecciona un archivo de imagen válido (JPEG, PNG o GIF).",
    en: "Please select a valid image file (JPEG, PNG or GIF)."
  },

  //profile session
  administradorgeneral: {
    es: "Administrador General",
    en: "General administrator"
  },
  trabajadorestandar: {
    es: "Trabajador Estandar",
    en: "Standard Worker"
  },
  superadmingeneral: {
    es: "Super Admin General",
    en: "Super Admin General"
  },
  vendedor: {
    es: "Vendedor",
    en: "seller"
  },
  //structure type
  haberesimponiblesytributables: {
    es: "Haberes imponibles y tributables",
    en: "Taxable and taxable assets"
  },
  haberesimponiblesynotributables: {
    es: "Haberes imponibles y no tributables",
    en: "Taxable and non-taxable assets"
  },
  haberesnoimponiblesynotributables: {
    es: "Haberes no imponibles y no tributables",
    en: "Non-taxable and non-taxable assets"
  },
  haberesnoimponiblesytributables: {
    es: "Haberes no imponibles y tributables",
    en: "Non-taxable and taxable assets"
  },
  totaleshaberes: {
    es: "Totales haberes",
    en: "Total assets"
  },
  descuentosprevisionales: {
    es: "Descuentos Previsionales",
    en: "Forecast Discounts"
  },
  descuentoslegales: {
    es: "Descuentos Legales",
    en: "Legal Discounts"
  },
  descuentosvoluntarios: {
    es: "Descuentos Voluntarios",
    en: "Voluntary Discounts"
  },
  totalesdescuentos: {
    es: "Totales descuentos",
    en: "Total discounts"
  },
  aporteempleador: {
    es: "Aporte empleador",
    en: "Employer contribution"
  },
  totalaporteempleados: {
    es: "Total aporte empleados",
    en: "Total employee contribution"
  },
  descuentosporimpuestosalasremuneraciones: {
    es: "Descuentos por Impuestos a las Remuneraciones",
    en: "Remuneration Tax Discounts"
  },
  descuentosporimpuestosalasindenmizaciones: {
    es: "Descuentos por Impuestos a las Indenmizaciones",
    en: "Tax Discounts on Compensation"
  },
  totalesgenerales: {
    es: "Totales generales",
    en: "Grand Totals"
  },
   //relation ship
   esposa: {
    es: "Esposa",
    en: "Wife"
  },
  esposo: {
    es: "Esposo",
    en: "Husband"
  },
  hija: {
    es: "Hija",
    en: "Daughter"
  },
  hijo: {
    es: "Hijo",
    en: "Son"
  },
  madre: {
    es: "Madre",
    en: "Mother"
  },
  padre: {
    es: "Padre",
    en: "Father"
  },
  otro: {
    es: "Otro",
    en: "Other"
  },

  //Type Responsability
  simple: {
    es: "simple",
    en: "simple"
  },
  duplo: {
    es: "duplo",
    en: "double"
  },
  maternal: {
    es: "maternal",
    en: "maternal"
  },


  /* ----------------- RLEStructureSeeder -------------------- */
  sueldo: {
    es: "Sueldo",
    en: "Salary"
  },
  gratificacionlegal: {
    es: "Gratificación Legal",
    en: "Legal gratification"
  },
  horasextraordinarias1: {
    es: "Horas Extraordinarias 1",
    en: "Extraordinary hours 1"
  },
  comision: {
    es: "Comisión",
    en: "Commission"
  },
  bonoproduccion: {
    es: "Bono Producción",
    en: "Production Bonus"
  },
  bonoresponsabilidad: {
    es: "Bono Responsabilidad",
    en: "Responsibility Bonus"
  },
  bonospormetas: {
    es: "Bonos por Metas",
    en: "Goal Bonuses"
  },
  premios: {
    es: "Premios",
    en: "Awards"
  },
  semanacorrida: {
    es: "Semana Corrida",
    en: "Running Week"
  },
  recargo30diadomingo: {
    es: "Recargo 30% día domingo",
    en: "Surcharge 30% on Sunday"
  },
  bonosuotrasremuneracionesfijasmensuales: {
    es: "Bonos u otras remuneraciones fijas mensuales",
    en: "Bonuses or other fixed monthly remunerations"
  },
  pagoporhorasdetrabajosindical: {
    es: "Pago por horas de trabajo sindical",
    en: "Payment for hours of union work"
  },
  anticipodegratificacion: {
    es: "Anticipo de Gratificación",
    en: "Advance Payment"
  },
  asignaciondeantigüedad: {
    es: "Asignación de Antigüedad",
    en: "Seniority Assignment"
  },
  asignaciondeasistencia: {
    es: "Asignación de asistencia",
    en: "Attendance Assignment"
  },
  asignacionderesponsabilidaddecargo: {
    es: "Asignación de responsabilidad de cargo",
    en: "Assignment of position responsibility"
  },
  asignaciondecasa: {
    es: "Asignación de casa",
    en: "House assignment"
  },
  asignaciondeescolaridad: {
    es: "Asignación de Escolaridad",
    en: "School Assignment"
  },
  asignaciondematrimonio: {
    es: "Asignación de Matrimonio",
    en: "Marriage Assignment"
  },
  asignaciondenatalidad: {
    es: "Asignacion de Natalidad",
    en: "Birth Allowance"
  },
  asignacionderepresentacion: {
    es: "Asignación de representación",
    en: "Representation assignment"
  },
  asignaciondetituloprofesional: {
    es: "Asignación de título profesional",
    en: "Professional title assignment"
  },
  asignaciondevacaciones: {
    es: "Asignación de vacaciones",
    en: "Vacation allowance"
  },
  asignaciondezona: {
    es: "Asignación de zona",
    en: "Zone assignment"
  },
  regalias: {
    es: "Regalias",
    en: "Royalties"
  },
  bonoanualdeproductividad: {
    es: "Bono Anual de Productividad",
    en: "Annual Productivity Bonus"
  },
  reembolsosgastosmedicosydentales: {
    es: "Reembolsos Gastos medicos y Dentales",
    en: "Reimbursement of medical and dental expenses"
  },
  segurodevidacargoempresa: {
    es: "Seguro de vida cargo empresa",
    en: "Life insurance company charge"
  },
  remuneracionvariablepagadaenclausura: {
    es: "Remuneración variable pagada en clausura",
    en: "Variable remuneration paid at closing"
  },
  remuneracionvariablepagadaenvacaciones: {
    es: "Remuneración variable pagada en vacaciones",
    en: "Variable remuneration paid on vacations"
  },
  usotelefonocelularfinesparticulares: {
    es: "Uso Teléfono celular fines particulares",
    en: "Use of cell phone for private purposes"
  },
  arrendamientodecasa: {
    es: "Arrendamiento de casa",
    en: "House rental"
  },
  becasdeestudio: {
    es: "Becas de estudio",
    en: "Scholarships"
  },
  gastosderepresentacionsinrendiciondecuentas: {
    es: "Gastos de representación sin rendición de cuentas",
    en: "Representation expenses without accountability"
  },
  usodecasa: {
    es: "Uso de casa",
    en: "Use of house"
  },
  bonopordiferenciasdesubsidios: {
    es: "Bono por Diferencias de Subsidios",
    en: "Subsidy difference bonus"
  },
  subsidioporincapacidadlaboralporlicenciamedi: {
    es: "Subsidio por incapacidad laboral por licencia médica",
    en: "Work disability allowance for medical leave"
  },
  bonificacionporatencionmedica: {
    es: "Bonificación por atencion médica",
    en: "Bonus for medical care"
  },
  asignaciondelicenciamedica: {
    es: "Asignación de licencia médica",
    en: "Medical leave assignment"
  },
  usovehiculofinesparticularesajenosgiroempresa: {
    es: "Uso vehiculo fines particulares ajenos giro empresa",
    en: "Vehicle use for private purposes other than business"
  },
  beneficiospagadosatravesdecajadecompensacion: {
    es: "Beneficios pagados a traves de caja de compensacion",
    en: "Benefits paid through compensation fund"
  },
  beneficiospagadosatravesdeserviciosdebienestar: {
    es: "Beneficios pagados a traves de servicios de bienestar",
    en: "Benefits paid through welfare services"
  },
  viaticos: {
    es: "Viaticos",
    en: "Travel expenses"
  },
  gastosdetraslacionasalacuna: {
    es: "Gastos de Traslacion a sala cuna",
    en: "Transfer expenses to nursery"
  },
  prestamosalpersonal: {
    es: "Prestamos al personal",
    en: "Personal loans"
  },
  reembolsodegastos: {
    es: "Reembolso de Gastos",
    en: "Reimbursement"
  },
  celularfinesdeltrabajo: {
    es: "Celular fines del trabajo",
    en: "Cell phone for work purposes"
  },
  usovehiculoparagiroempresa: {
    es: "Uso vehiculo para giro empresa",
    en: "Use vehicle for business"
  },
  gastosderepresentacionconrendiciondecuentas: {
    es: "Gastos de representación con rendición de cuentas",
    en: "Representation expenses with accountability"
  },
  asignaciondecolacion: {
    es: "Asignación de colación",
    en: "Collation assignment"
  },
  asignaciondedesgastedeherramientas: {
    es: "Asignacion de desgaste de herramientas",
    en: "Tool wear assignment"
  },
  asignaciondemovilizacion: {
    es: "Asignacion de Movilización",
    en: "Mobilization Assignment"
  },
  asignaciondeperdidadecompra: {
    es: "Asignación de perdida de compra",
    en: "Purchase loss allocation"
  },
  asignaciondeperdidadecaja: {
    es: "Asignación de perdida de caja",
    en: "Cash loss allocation"
  },
  asignaciondetraslacion: {
    es: "Asignacion de traslacion",
    en: "Translation assignment"
  },
  asignacionfamiliarlegal: {
    es: "Asignación familiar legal",
    en: "Legal family allowance"
  },
  depositoconvenidohastauf900: {
    es: "Depósito convenido hasta UF 900",
    en: "Agreed deposit up to UF 900"
  },
  salacuna: {
    es: "Sala cuna",
    en: "Crib room"
  },
  gastosporcambioderesidencia: {
    es: "Gastos por cambio de residencia",
    en: "Expenses for change of residence"
  },
  asignaciontrabajoadistanciaoteletrabajo: {
    es: "Asignación trabajo a distancia o teletrabajo",
    en: "Remote work or teleworking assignment"
  },
  alojamientoporrazonesdetrabajo: {
    es: "Alojamiento por razones de trabajo",
    en: "Accommodation for work reasons"
  },
  incentivoproductividad: {
    es: "Incentivo productividad",
    en: "Productivity Incentive"
  },
  trato: {
    es: "Trato",
    en: "Deal"
  },
  bonoadicional1: {
    es: "Bono adicional 1",
    en: "Additional bonus 1"
  },
  bonoadicional2: {
    es: "Bono adicional 2",
    en: "Additional bonus 2"
  },
  horasextraordinarias2: {
    es: "Horas extraordinarias 2",
    en: "Extraordinary hours 2"
  },
  horasextraordinarias3: {
    es: "Horas extraordinarias 3",
    en: "Extraordinary hours 3"
  },
  excesodegratificacion: {
    es: "Exceso de gratificación",
    en: "Excess gratification"
  },
  bonomovilizacionobrafaena: {
    es: "Bono movilización - Obra Faena",
    en: "Mobilization bonus - work taks"
  },
  movilizacionobrasfaenas: {
    es: "Movilización obras-faenas",
    en: "Works-tasks mobilization"
  },
  colacionobrasfaenas: {
    es: "Colación obras-faenas",
    en: "Works-tasks collation"
  },
  totalhaberesimponiblesytributables5210: {
    es: "Total haberes imponibles y tributables(5210)",
    en: "Total taxable and taxable assets (5210)"
  },
  totalhaberesimponiblesnotributables5220: {
    es: "Total haberes imponibles no tributables(5220)",
    en: "Total non-taxable taxable assets (5220)"
  },
  totalhaberesnoimponiblesynotributables5230: {
    es: "Total haberes no imponibles y no tributables(5230)",
    en: "Total non-taxable and non-taxable assets (5230)"
  },
  totalhaberesnoimponiblesytributables5240: {
    es: "Total haberes no imponibles y tributables(5240)",
    en: "Total non-taxable and taxable assets (5240)"
  },
  totalhaberes5201: {
    es: "Total haberes(5201)",
    en: "Total assets (5201)"
  },
  cotizacionobligatoriaprevisionalafpoips: {
    es: "Cotización obligatoria previsional (AFP o IPS)",
    en: "Mandatory pension contribution (AFP or IPS)"
  },
  cotizacionobligatoriasalud7: {
    es: "Cotización obligatoria salud 7%",
    en: "Mandatory health contribution 7%"
  },
  cotizacionvoluntariaparasalud: {
    es: "Cotización voluntaria para salud",
    en: "Voluntary health contribution"
  },
  cotizacionafctrabajador: {
    es: "Cotización AFC -Trabajador",
    en: "AFC Quote -Worker"
  },
  cotizacionestecnicoextranjeroparaseguridadsocialfueradechile: {
    es: "Cotizaciones técnico extranjero para seguridad social fuera de Chile",
    en: "Foreign technical quotes for social security outside Chile"
  },
  descuentodepositoconvenidohastauf900anual: {
    es: "Descuento depósito convenido hasta UF 900 anual",
    en: "Agreed deposit discount up to UF 900 annually"
  },
  cotizacionahorroprevisionalvoluntarioindividualmodalidada: {
    es: "Cotización ahorro previsional voluntario individual modalidad A",
    en: "Individual voluntary pension savings contribution modality A"
  },
  cotizacionahorroprevisionalvoluntarioindividualmodalidadbhastauf50: {
    es: "Cotización ahorro previsional voluntario individual modalidad B hasta UF 50",
    en: "Individual voluntary pension savings contribution, modality B up to UF 50"
  },
  cotizacionahorroprevisionalvoluntariocolectivomodalidada: {
    es: "Cotización ahorro previsional voluntario colectivo modalidad A",
    en: "Collective voluntary pension savings contribution modality A"
  },
  cotizacionahorroprevisionalvoluntariocolectivomodalidadbhastauf50: {
    es: "Cotización ahorro previsional voluntario colectivo modalidad B hasta UF 50",
    en: "Collective voluntary pension savings contribution, modality B up to UF 50"
  },
  impuestoretenidoporremuneraciones: {
    es: "Impuesto retenido por remuneraciones",
    en: "Tax withheld for salaries"
  },
  impuestoretenidoporindemnizacionesleyporexcesoenelmontodeindenmizaciones: {
    es: "Impuesto retenido por indemnizaciones (LEY POR EXCESO EN EL MONTO DE INDENMIZACIONES)",
    en: "Tax withheld for compensation (LAW FOR EXCESS IN THE AMOUNT OF COMPENSATION)"
  },
  mayorretenciondeimpuestosolicitadaporeltrabajadorart88lir: {
    es: "Mayor retención de impuesto solicitada por el trabajador (Art. 88 LIR)",
    en: "Greater tax withholding requested by the worker (Art. 88 LIR)"
  },
  impuestoretenidoporreliquidacionderemuneracionesdevengadasenotrosperiodosmensuales: {
    es: "Impuesto retenido por reliquidación de remuneraciones devengadas en otros períodos mensuales",
    en: "Tax withheld for re-assessment of remunerations accrued in other monthly periods"
  },
  diferenciadeimpuestoporreliquidacionderemuneracionesdevengadasenesteperíiodo: {
    es: "Diferencia de impuesto por reliquidación de remuneraciones devengadas en este período",
    en: "Tax difference due to re-assessment of remuneration accrued in this period"
  },
  cuotasindical1: {
    es: "Cuota sindical 1",
    en: "union dues 1"
  },
  cuotasindical2: {
    es: "Cuota sindical ",
    en: "union dues "
  },
  cuotasindical3: {
    es: "Cuota sindical 3",
    en: "union dues 3"
  },
  cuotasindical4: {
    es: "Cuota sindical 4",
    en: "union dues 4"
  },
  cuotasindical5: {
    es: "Cuota sindical 5",
    en: "union dues 5"
  },
  creditosocialccaf: {
    es: "Crédito social CCAF",
    en: "CCAF social credit"
  },
  cuotaviviendaoeducacion: {
    es: "Cuota vivienda o educación",
    en: "Housing or education fee"
  },
  creditocooperativasdeahorro: {
    es: "Crédito cooperativas de ahorro",
    en: "Credit savings cooperatives"
  },
  otrosdescuentosautorizadosysolicitadosporeltrabajador: {
    es: "Otros descuentos autorizados y solicitados por el trabajador",
    en: "Other discounts authorized and requested by the worker"
  },
  cotizacionadicionaltrabajopesadotrabajador: {
    es: "Cotización adicional trabajo pesado - trabajador",
    en: "Additional quote heavy work - worker"
  },
  donacionesculturalesydereconstruccion: {
    es: "Donaciones culturales y de reconstrucción",
    en: "Cultural and reconstruction donations"
  },
  otrosdescuentoslegales: {
    es: "Otros Descuentos Legales",
    en: "Other Legal Discounts"
  },
  pensionesdealimentos: {
    es: "Pensiones de alimentos",
    en: "Food pensions"
  },
  descuentomujercasada: {
    es: "Descuento mujer casada",
    en: "Married woman discount"
  },
  descuentoporanticipos: {
    es: "Descuento por anticipos",
    en: "Advance payment discount"
  },
  descuentoporprestamos: {
    es: "Descuento por préstamos",
    en: "Loan discount"
  },
  totaldescuentosprevisionales: {
    es: "Total descuentos previsionales",
    en: "Total pension discounts"
  },
  totaldescuentoslegales: {
    es: "Total descuentos legales",
    en: "Total legal discounts"
  },
  totaldescuentosvoluntarios: {
    es: "Total descuentos voluntarios",
    en: "Total voluntary discounts"
  },
  aporteafcempleador: {
    es: "Aporte AFC - empleador",
    en: "AFC - employer contribution"
  },
  aporteempleadorseguroaccidentesdeltrabajoyleysanna: {
    es: "Aporte empleador seguro accidentes del trabajo y Ley SANNA",
    en: "Employer contribution insurance for work accidents and SANNA Law"
  },
  aporteempleadorindemnizaciónatodoevento: {
    es: "Aporte empleador indemnización a todo evento",
    en: "Employer compensation contribution to all events"
  },
  aporteadicionaltrabajopesadoempleador: {
    es: "Aporte adicional trabajo pesado - empleador",
    en: "Additional contribution heavy work - employer"
  },
  aporteempleadorseguroinvalidezysobrevivencia: {
    es: "Aporte empleador seguro invalidez y sobrevivencia",
    en: "Employer contribution for disability and survival insurance"
  },
  aporteempleadorahorroprevisionalvoluntariocolectivoart164ct: {
    es: "Aporte empleador ahorro previsional voluntario colectivo (Art. 164 CT)",
    en: "Employer contribution to collective voluntary pension savings (Art. 164 CT)"
  },
  totaldescuentos: {
    es: "Total Descuentos",
    en: "Total discounts"
  },
  totalaporteempleador: {
    es: "Total aporte empleador",
    en: "Total employer contribution"
  },
  totalimpuestosporremuneraciones: {
    es: "Total impuestos por remuneraciones",
    en: "Total compensation taxes"
  },
  alcanceliquido: {
    es: "Alcance Líquido",
    en: "Liquid Scope"
  },
  totalapagar: {
    es: "Total a Pagar",
    en: "Total to pay"
  },
  retencionesjudiciales: {
    es: "Retenciones Judiciales",
    en: "Judicial Withholdings"
  },
  retencionprestamoclasemedia2020ley212523166: {
    es: "Retención préstamo clase media 2020 (Ley 21.252) (3166)",
    en: "Middle class loan retention 2020 (Law 21,252) (3166)"
  },
  otrosimponibles: {
    es: "Otros imponibles",
    en: "Other taxes"
  },
  otrosbonosimponibles: {
    es: "Otros bonos imponibles",
    en: "Other taxable bonds"
  },
  aguinaldosnoimponibles: {
    es: "Aguinaldos no imponibles",
    en: "Non-taxable bonuses"
  },
  cotizacionapvimoda3155: {
    es: "Cotización APVi Mod A(3155)",
    en: "APVi Mod A Quote(3155)"
  },
  haberhni11: {
    es: "HABER HNI11",
    en: "asset HNI11"
  },
  retirosvtsservinternos: {
    es: "Retiros/Vts./Serv.Internos",
    en: "Withdrawals/Vts./Internal Serv."
  },
  otrosdescuentos: {
    es: "Otros descuentos",
    en: "Other discounts"
  },
  tiempodeespera: {
    es: "Tiempo de espera",
    en: "Wait time"
  },
  bonodecumplimiento: {
    es: "Bono de cumplimiento",
    en: "Performance bonus"
  },
  bonobimensual: {
    es: "Bono Bimensual",
    en: "Bimonthly Bonus"
  },
  bonomultas: {
    es: "Bono Multas",
    en: "Bonus Fines"
  },
  bonoanual: {
    es: "Bono anual",
    en: "Annual bonus"
  },

  
  /* ------------- DetailCompanyTurn ------------ */
  asesoresyconsultoreseninformaticasoftware: {
    es: "ASESORES Y CONSULTORES EN INFORMATICA (SOFTWARE)",
    en: "COMPUTER ADVISORS AND CONSULTANTS (SOFTWARE)"
  },
  empresasdeserviciosintegralesdeinformaticabbb: {
    es: "726000 - EMPRESAS DE SERVICIOS INTEGRALES DE INFORMATICA BBB",
    en: "726000 - BBB COMPREHENSIVE COMPUTER SERVICES COMPANIES"
  },

  /* ------------- TypeNotificationsSeed ------------ */
  planillamensualesremuneraciones: {
    es: "Planilla mensuales remuneraciones",
    en: "Monthly remuneration spreadsheet"
  },
  liquidaciondesueldosempleado: {
    es: "Liquidación de sueldos Empleado",
    en: "Employee salary settlement"
  },
  gestiondesolicitudes: {
    es: "Gestión de solicitudes",
    en: "Request management"
  },
  solicituddeadelanto: {
    es: "Solicitud de adelanto",
    en: "Advance request"
  },
  rendiciondegastosadmin: {
    es: "Rendición de gastos Admin",
    en: "Admin Expense Report"
  },
  liquidaciondesueldosadmin: {
    es: "Liquidación de sueldos Admin",
    en: "Admin salary settlement"
  },
  rendiciondegastosempleado: {
    es: "Rendición de gastos Empleado",
    en: "Employee expense report"
  },
  detalledeafp: {
    es: "Detalle de AFP",
    en: "AFP Detail"
  },

  /* ------------- MovStaffSeed ------------ */
  sinmovimientoenelmes: {
    es: "Sin Movimiento en el Mes",
    en: "No Movement in the Month"
  },
  contratacionaplazoindefinido: {
    es: "Contratación a plazo indefinido",
    en: "Indefinite term contract"
  },
  retiro: {
    es: "Retiro",
    en: "Withdrawal"
  },
  subsidios: {
    es: "Subsidios",
    en: "Subsidies"
  },
  permisosingocedesueldos: {
    es: "Permiso Sin Goce de Sueldos",
    en: "Leave without Pay"
  },
  incorporacionenellugardetrabajo: {
    es: "Incorporación en el Lugar de Trabajo",
    en: "Incorporation in the Workplace"
  },
  accidentesdeltrabajo: {
    es: "Accidentes del Trabajo",
    en: "Work Accidents"
  },
  contratacionaplazofijo: {
    es: "Contratación a plazo fijo",
    en: "Fixed-term contract"
  },
  cambiocontratoplazofijoaplazoindefinido: {
    es: "Cambio Contrato plazo fijo a plazo indefinido",
    en: "Fixed-term contract change to indefinite term"
  },
  otrosmovimientosausentismos: {
    es: "Otros Movimientos (Ausentismos)",
    en: "Other Movements (Absenteeisms)"
  },
  reliquidacionpremioobonoposterioralfiniquito: {
    es: "Reliquidación, Premio o bono posterior al finiquito",
    en: "Resettlement, Award or bonus after settlement"
  },
  suspensioncontratoactodeautoridadleynº21227: {
    es: "Suspensión Contrato acto de autoridad (Ley Nº21.227)",
    en: "Contract suspension act of authority (Law Nº 21.227)"
  },
  suspensioncontratoporpactoleynº21227: {
    es: "Suspensión Contrato por pacto (Ley Nº21.227)",
    en: "Suspension of Contract by agreement (Law Nº 21.227)"
  },
  reducciondejornadaleynº21227: {
    es: "Reducción de Jornada (Ley Nº21.227)",
    en: "Reduction of Working Hours (Law Nº 21,227)"
  },


   /* ----------------- FINIQUITOS -------------------- */
   art160n2negociacionesprohibidasporescrito: {
    es: "ART. 160 N 2: Negociaciones prohibidas por escrito",
    en: "ART. 160 N 2: Negotiations prohibited in writing"
  },
  art160n3noconcurrenciaalaslabores: {
    es: "ART. 160 N 3: No concurrencia a las labores",
    en: "ART. 160 N 3: Non-attendance at work"
  },
  art160n4negociacionesprohibidasporescrito: {
    es: "ART. 160 N 4: Negociaciones prohibidas por escrito",
    en: "ART. 160 N 4: Negotiations prohibited in writing"
  },
  art160n5abandonodeltrabajo: {
    es: "ART. 160 N 5: Abandono del trabajo",
    en: "ART. 160 N 5: Abandonment of work"
  },
  art160n6negociacionesprohibidasporescrito: {
    es: "Horas",
    en: "ART. 160 N 6: Negotiations prohibited in writing"
  },

  /* ----------------- VIEWS -------------------- */

  pdfdeliquidaciondesueldos: {
    es: "PDF de Liquidación de Sueldos",
    en: "Salary Settlement PDF"
  },
  pdfdemontosgenerales: {
    es: "PDF de Montos Generales",
    en: "PDF of General Amounts"
  },
  pdfpersonalizado: {
    es: "PDF Personalizado",
    en: "Custom PDF"
  },
  pdfdecertificaciondeempleado: {
    es: "PDF de certificación de empleado",
    en: "Employee Certification PDF"
  },

   /* ----------------- PAGE NOT FOUND -------------------- */
   paginanoencontrada: {
    es: "PÁGINA NO ENCONTRADA",
    en: "PAGE NOT FOUND"
  },
  losentimosnopudimosencontrarlapaginaqueestasbuscando: {
    es: "Lo sentimos, no pudimos encontrar la página que estas buscando.",
    en: "Sorry, we couldn't find the page you're looking for."
  },
  iralapaginadeinicio: {
    es: "IR A LA PÁGINA DE INICIO",
    en: "GO TO HOMEPAGE"
  },
  paginanoautorizada: {
    es: "PÁGINA NO AUTORIZADA",
    en: "UNAUTHORIZED PAGE"
  },
  losentimosnoestasautorizadoparaverestapagina: {
    es: "Lo sentimos, no estas autorizado para ver esta página.",
    en: "Sorry, you are not authorized to view this page."
  },
  
  /* FRONT PREVIEW EBOOK TABLE */
  numerodiastrabajadosenelmescod1115: {
    es: "Número días trabajados en el mes (cód 1115)",
    en: "Number of days worked in the month (code 1115)"
  },
  numerodiasdelicenciamedicaenelmescod1116: {
    es: "Número días de licencia médica en el mes (cód 1116)",
    en: "Number of days of medical leave in the month (code 1116)"
  },
  numerodiasdevacacionesenelmescod1117: {
    es: "Número días de vacaciones en el mes (cód 1117)",
    en: "Number of vacation days in the month (code 1117)"
  },
  subsidiotrabajadorjovencod1118: {
    es: "Subsidio trabajador joven (cód 1118)",
    en: "Young worker subsidy (code 1118)"
  },
  puestotrabajopesadocod1154: {
    es: "Puesto Trabajo Pesado (cód 1154)",
    en: "Heavy Duty Position (code 1154)"
  },
  ahorroprevisionalvoluntarioindividualcod1155: {
    es: "Ahorro previsional voluntario individual (cód 1155)",
    en: "Individual voluntary pension savings (code 1155)"
  },
  ahorroprevisionalvoluntariocolectivocod1157: {
    es: "Ahorro previsional voluntario colectivo (cód 1157)",
    en: "Collective voluntary pension savings (code 1157)"
  },
  indemnizacionatodoeventoart164cod1131: {
    es: "Indemnización a todo evento (art. 164) (cód 1131)",
    en: "Compensation for all events (art. 164) (code 1131)"
  },
  tasaindemnizacionatodoeventoart164cod1132: {
    es: "Tasa indemnización a todo evento (Art 164) (cód 1132)",
    en: "Compensation rate for all events (Art 164) (code 1132)"
  },
  sueldocod2101: {
    es: "Sueldo (cód 2101)",
    en: "Salary (code 2101)"
  },
  sobresueldocod2102: {
    es: "Sobresueldo (cód 2102)",
    en: "Bonus (code 2102)"
  },
  comisionesmensualcod2103: {
    es: "Comisiones (mensual) (cód 2103)",
    en: "Commissions (monthly) (code 2103)"
  },
  semanacorridamensualart45cod2104: {
    es: "Semana corrida mensual (Art 45) (cód 2104)",
    en: "Monthly running week (Art 45) (code 2104)"
  },
  participacionmensualcod2105: {
    es: "Participación (mensual) (cód 2105)",
    en: "Participation (monthly) (code 2105)"
  },
  gratificacionmensualcod2106: {
    es: "Gratificación (mensual) (cód 2106)",
    en: "Bonus (monthly) (code 2106)"
  },
  recargo30diadomingoart38cod2107: {
    es: "Recargo 30% día domingo (Art. 38) (cód 2107)",
    en: "Surcharge 30% on Sunday (Art. 38) (code 2107)"
  },
  remuneracionvariablepagadaenvacacionesart71cod2108: {
    es: "Remuneración variable pagada en vacaciones (Art 71) (cód 2108)",
    en: "Variable remuneration paid on vacations (Art 71) (code 2108)"
  },
  remuneracionvariablepagadaenclausuraart38dfl2cod2109: {
    es: "Remuneración variable pagada en clausura (Art. 38 DFL 2) (cód 2109)",
    en: "Variable remuneration paid at closing (Art. 38 DFL 2) (code 2109)"
  },
  aguinaldocod2110: {
    es: "Aguinaldo (cód 2110)",
    en: "Bonus (code 2110)"
  },
  bonosuotrasremuneracionesfijasmensualescod2111: {
    es: "Bonos u otras remuneraciones fijas mensuales (cód 2111)",
    en: "Bonus or other fixed monthly remuneration (code 2111)"
  },
  tratosmensualcod2112: {
    es: "Tratos (mensual) (cód 2112)",
    en: "Deals (monthly) (code 2112)"
  },
  bonosuotrasremuneracionesvariablesmensualesosuperioresaunmescod2113: {
    es: "Bonos u otras remuneraciones variables mensuales o superiores a un mes (cód 2113)",
    en: "Bonuses or other variable remunerations monthly or longer than one month (code 2113)"
  },
  ejercicioopcionnopactadaencontratoart17n8lircod2114: {
    es: "Ejercicio opción no pactada en contrato (Art. 17 N°8 LIR) (cód 2114)",
    en: "Exercise option not agreed in contract (Art. 17 No. 8 LIR) (code 2114)"
  },
  beneficiosenespecieconstitutivosderemuneracioncod2115: {
    es: "Beneficios en especie constitutivos de remuneración (cód 2115)",
    en: "Benefits in kind constituting remuneration (code 2115)"
  },
  remuneracionesbimestralesdevengoendosmesescod2116: {
    es: "Remuneraciones bimestrales (devengo en dos meses) (cód 2116)",
    en: "Bimonthly salaries (accrued in two months) (code 2116)"
  },
  remuneracionestrimestralesdevengoentresmesescod2117: {
    es: "Remuneraciones trimestrales (devengo en tres meses) (cód 2117)",
    en: "Quarterly salaries (accrued in three months) (code 2117)"
  },
  remuneracionescuatrimestralesdevengoencuatromesescod2118: {
    es: "Remuneraciones cuatrimestrales (devengo en cuatro meses) (cód 2118)",
    en: "Quarterly salaries (accrued in four months) (code 2118)"
  },
  remuneracionessemestralesdevengoensesismesescod2119: {
    es: "Remuneraciones semestrales (devengo en sesis meses) (cód 2119)",
    en: "Semi-annual remunerations (accrued in six months) (code 2119)"
  },
  remuneracionesanualesdevengoendocemesescod2120: {
    es: "Remuneraciones anuales (devengo en doce meses) (cód 2120)",
    en: "Annual remuneration (accrued in twelve months) (code 2120)"
  },
  participacionanualdevengoendocemesescod2121: {
    es: "Participación anual (devengo en doce meses (cód 2121)",
    en: "Annual participation (accrual in twelve months (code 2121)"
  },
  gratificacionanualdevengoendocemesescod2122: {
    es: "Gratificación anual (devengo en doce meses) (cód 2122)",
    en: "Annual bonus (accrued in twelve months) (code 2122)"
  },
  otrasremuneracionessuperioresaunmescod2123: {
    es: "Otras remuneraciones superiores a un mes (cód 2123)",
    en: "Other remunerations greater than one month (code 2123)"
  },
  pagoporhorasdetrabajosindicalcod2124: {
    es: "Pago por horas de trabajo sindical (cód 2124)",
    en: "Payment for hours of union work (code 2124)"
  },
  sueldoempresarialcod2161: {
    es: "Sueldo empresarial (cód 2161)",
    en: "Business salary (code 2161)"
  },
  subsidioporincapacidadlaboralporlicenciamedicatotalmensualcod2201: {
    es: "Subsidio por incapacidad laboral por licencia médica - total mensual (cód 2201)",
    en: "Work disability allowance for medical leave - monthly total (code 2201)"
  },
  becadeestudioart17n18lircod2202: {
    es: "Beca de estudio (Art. 17 N°18 LIR) (cód 2202)",
    en: "Study scholarship (Art. 17 N°18 LIR) (code 2202)"
  },
  gratificacionesdezonaart17n27cod2203: {
    es: "Gratificaciones de zona (Art.17 N°27) (cód 2203)",
    en: "Zone bonuses (Art.17 N°27) (code 2203)"
  },
  otrosingresosnoconstitutivosderentaart17n29lircod2204: {
    es: "Otros ingresos no constitutivos de renta (Art 17 N°29 LIR) (cód 2204)",
    en: "Other income not constituting income (Art 17 N°29 LIR) (code 2204)"
  },
  colaciontotalmensualart41cod2301: {
    es: "Colación total mensual (Art 41) (cód 2301)",
    en: "Total monthly snack (Art 41) (code 2301)"
  },
  movilizaciontotalmensualart41cod2302: {
    es: "Movilización total mensual (Art 41) (cód 2302)",
    en: "Total monthly mobilization (Art 41) (code 2302)"
  },
  viaticostotalmensualart41cod2303: {
    es: "Viáticos total mensual (Art 41) (cód 2303)",
    en: "Total monthly travel expenses (Art 41) (code 2303)"
  },
  asignaciondeperdidadecajatotalmensualart41cod2304: {
    es: "Asignación de pérdida de caja total mensual (Art 41) (cód 2304)",
    en: "Allocation of total monthly cash loss (Art 41) (code 2304)"
  },
  asignaciondedesgasteherramientastotalmensualart41cod2305: {
    es: "Asignación de desgaste herramientas total mensual (Art 41) (cód 2305)",
    en: "Total monthly tool wear allowance (Art 41) (code 2305)"
  },
  asignacionfamiliarlegaltotalmensualart41cod2311: {
    es: "Asignación familiar legal total mensual (Art 41) (cód 2311)",
    en: "Total monthly legal family allowance (Art 41) (code 2311)"
  },
  gastosporcausadeltrabajoart41cdtygastosderepresentacionart42n1lircod2306: {
    es: "Gastos por causa del trabajo (Art 41 CdT) y gastos de representación (Art. 42 Nº1 LIR) (cód 2306)",
    en: "Expenses due to work (Art. 41 CdT) and representation expenses (Art. 42 Nº1 LIR) (code 2306)"
  },
  gastosporcambioderesidenciaart53cod2307: {
    es: "Gastos por cambio de residencia (Art 53) (cód 2307)",
    en: "Expenses for change of residence (Art 53) (code 2307)"
  },
  salacunaart203cod2308: {
    es: "Sala cuna (Art 203) (cód 2308)",
    en: "Crib room (Art 203) (code 2308)"
  },
  asignaciontrabajoadistanciaoteletrabajocod2309: {
    es: "Asignación trabajo a distancia o teletrabajo (cód 2309)",
    en: "Remote work or teleworking assignment (code 2309)"
  },
  depositoconvenidohastauf900cod2347: {
    es: "Depósito convenido hasta UF 900 (cód 2347)",
    en: "Agreed deposit up to UF 900 (code 2347)"
  },
  alojamientoporrazonesdetrabajoart17n14lircod2310: {
    es: "Alojamiento por razones de trabajo (Art 17 N°14 LIR) (cód 2310)",
    en: "Accommodation for work reasons (Art 17 N°14 LIR) (code 2310)"
  },
  asignaciondetraslacionart17n15lircod2312: {
    es: "Asignación de traslación (Art. 17 N°15 LIR) (cód 2312)",
    en: "Transfer assignment (Art. 17 No. 15 LIR) (code 2312)"
  },
  indemnizacionporferiadolegalcod2313: {
    es: "Indemnización por feriado legal (cód 2313)",
    en: "Compensation for legal holidays (code 2313)"
  },
  indemnizacionañosdeserviciocod2314: {
    es: "Indemnización años de servicio (cód 2314)",
    en: "Compensation for years of service (code 2314)"
  },
  indemnizacionsustitutivadelavisopreviocod2315: {
    es: "Indemnización sustitutiva del aviso previo (cód 2315)",
    en: "Compensation in lieu of prior notice (code 2315)"
  },
  indemnizacionfueromaternalart163biscod2316: {
    es: "Indemnización fuero maternal (Art 163 bis) (cód 2316)",
    en: "Maternal jurisdiction compensation (Art 163 bis) (code 2316)"
  },
  indemnizacionatodoeventoart164cod2331: {
    es: "Indemnización a todo evento (Art.164) (cód 2331)",
    en: "Compensation for all events (Art.164) (code 2331)"
  },
  indemnizacionesvoluntariastributablescod2417: {
    es: "Indemnizaciones voluntarias tributables (cód 2417)",
    en: "Taxable voluntary compensation (code 2417)"
  },
  indemnizacionescontractualestributablescod2418: {
    es: "Indemnizaciones contractuales tributables (cód 2418)",
    en: "Taxable contractual compensation (code 2418)"
  },
  cotizacionobligatoriaprevisionalafpoipscod3141: {
    es: "Cotización obligatoria previsional (AFP o IPS) (cód 3141)",
    en: "Mandatory pension contribution (AFP or IPS) (code 3141)"
  },
  cotizacionobligatoriasalud7cod3143: {
    es: "Cotización obligatoria salud 7% (cód 3143)",
    en: "Mandatory health contribution 7% (code 3143)"
  },
  cotizacionvoluntariaparasaludcod3144: {
    es: "Cotización voluntaria para salud (cód 3144)",
    en: "Voluntary contribution for health (code 3144)"
  },
  cotizacionafctrabajadorcod3151: {
    es: "Cotización AFC -Trabajador (cód 3151)",
    en: "AFC Quote - Worker (code 3151)"
  },
  cotizacionestcnicoextranjeroparaseguridadsocialfueradechilecod3146: {
    es: "Cotizaciones técnico extranjero para seguridad social fuera de Chile (cód 3146)",
    en: "Foreign technical contributions for social security outside Chile (code 3146)"
  },
  descuentodepositoconvenidohastauf900anualcod3147: {
    es: "Descuento depósito convenido hasta UF 900 anual (cód 3147)",
    en: "Agreed deposit discount up to UF 900 per year (code 3147)"
  },
  cotizacionahorroprevisionalvoluntarioindividualmodalidadacod3155: {
    es: "Cotización ahorro previsional voluntario individual modalidad A (cód 3155)",
    en: "Individual voluntary pension savings contribution modality A (code 3155)"
  },
  cotizacionahorroprevisionalvoluntarioindividualmodalidadbhastauf50cod3156: {
    es: "Cotización ahorro previsional voluntario individual modalidad B hasta UF 50 (cód 3156)",
    en: "Individual voluntary pension savings contribution, modality B up to UF 50 (code 3156)"
  },
  cotizacionahorroprevisionalvoluntariocolectivomodalidadaacod3157: {
    es: "Cotización ahorro previsional voluntario colectivo modalidad A (cód 3157)",
    en: "Collective voluntary pension savings contribution modality A (code 3157)"
  },
  cotizacionahorroprevisionalvoluntariocolectivomodalidadbhastauf50cod3158: {
    es: "Cotización ahorro previsional voluntario colectivo modalidad B hasta UF 50 (cód 3158)",
    en: "Collective voluntary pension savings contribution, modality B up to UF 50 (code 3158)"
  },
  impuestoretenidoporremuneracionescod3161: {
    es: "Impuesto retenido por remuneraciones (cód 3161)",
    en: "Tax withheld for remuneration (code 3161)"
  },
  impuestoretenidoporindemnizacionescod3162: {
    es: "Impuesto retenido por indemnizaciones (cód 3162)",
    en: "Tax withheld for compensation (code 3162)"
  },
  mayorretenciondeimpuestosolicitadaporeltrabajadorcod3163: {
    es: "Mayor retención de impuesto solicitada por el trabajador (cód 3163)",
    en: "Greater tax withholding requested by the worker (code 3163)"
  },
  impuestoretenidoporreliquidacionderemuneracionesdevengadasenotrosperiodosmensualescod3164: {
    es: "Impuesto retenido por reliquidación de remuneraciones devengadas en otros períodos mensuales (cód 3164)",
    en: "Tax withheld for re-assessment of remunerations accrued in other monthly periods (code 3164)"
  },
  diferenciadeimpuestoporreliquidacionderemuneracionesdevengadasenesteperiodocod3165: {
    es: "Diferencia de impuesto por reliquidación de remuneraciones devengadas en este período (cód 3165)",
    en: "Tax difference for re-assessment of remunerations accrued in this period (code 3165)"
  },
  retencionprestamoclasemedia2020ley21252cod3166: {
    es: "Retención préstamo clase media 2020 (Ley 21.252) (cód 3166)",
    en: "Middle class loan retention 2020 (Law 21,252) (code 3166)"
  },
  rutorigsindical8cod1178: {
    es: "Rut org sindical 8 (cód 1178)",
    en: "Trade Union ID 8 (code 1178)"
  },
  rutorigsindical9cod1179: {
    es: "Rut org sindical 9 (cód 1179)",
    en: "Trade Union ID 9 (code 1179)"
  },
  rutorigsindical10cod1180: {
    es: "Rut org sindical 10 (cód 1180)",
    en: "Trade Union ID 10 (code 1180)"
  },
  rebajazonaextremadl889cod3167: {
    es: "Rebaja zona extrema DL 889 (cód 3167)",
    en: "Extreme Zone Reduction DL 889 (code 3167)"
  },
  cuotasindical1cod3171: {
    es: "Cuota sindical 1 (cód 3171)",
    en: "Union Dues 1 (code 3171)"
  },
  cuotasindical2cod3172: {
    es: "Cuota sindical 2 (cód 3172)",
    en: "Union Dues 2 (code 3172)"
  },
  cuotasindical3cod3173: {
    es: "Cuota sindical 3 (cód 3173)",
    en: "Union Dues 3 (code 3173)"
  },
  cuotasindical4cod3174: {
    es: "Cuota sindical 4 (cód 3174)",
    en: "Union Dues 4 (code 3174)"
  },
  cuotasindical5cod3175: {
    es: "Cuota sindical 5 (cód 3175)",
    en: "Union Dues 5 (code 3175)"
  },
  cuotasindical6cod3176: {
    es: "Cuota sindical 6 (cód 3176)",
    en: "Union Dues 6 (code 3176)"
  },
  cuotasindical7cod3177: {
    es: "Cuota sindical 7 (cód 3177)",
    en: "Union Dues 7 (code 3177)"
  },
  cuotasindical8cod3178: {
    es: "Cuota sindical 8 (cód 3178)",
    en: "Union Dues 8 (code 3178)"
  },
  cuotasindical9cod3179: {
    es: "Cuota sindical 9 (cód 3179)",
    en: "Union Dues 9 (code 3179)"
  },
  cuotasindical10cod3180: {
    es: "Cuota sindical 10 (cód 3180)",
    en: "Union Dues 10 (code 3180)"
  },
  creditosocialccafcod3110: {
    es: "Crédito social CCAF (cód 3110)",
    en: "Social Credit CCAF (code 3110)"
  },
  cuotaviviendaoeducacionart58cod3181: {
    es: "Cuota vivienda o educación Art. 58 (cód 3181)",
    en: "Housing or Education Quota Art. 58 (code 3181)"
  },
  creditocooperativasdeahorroart54leycoopcod3182: {
    es: "Crédito cooperativas de ahorro (Art 54 Ley Coop.) (cód 3182)",
    en: "Savings Cooperatives Credit (Art 54 Coop. Law) (code 3182)"
  },
  otrosdescuentosautorizadosysolicitadosporeltrabajadorcod3183: {
    es: "Otros descuentos autorizados y solicitados por el trabajador (cód 3183)",
    en: "Other Discounts Authorized and Requested by the Worker (code 3183)"
  },
  cotizacionadicionaltrabajopesadotrabajadorcod3154: {
    es: "Cotización adicional trabajo pesado- trabajador (cód 3154)",
    en: "Additional Heavy Work Contribution - Worker (code 3154)"
  },
  donacionesculturalesydereconstruccioncod3184: {
    es: "Donaciones culturales y de reconstrucción (cód 3184)",
    en: "Cultural and Reconstruction Donations (code 3184)"
  },
  otrosdescuentosart58cod3185: {
    es: "Otros descuentos (Art 58) (cód 3185)",
    en: "Other Discounts (Art 58) (code 3185)"
  },
  pensionesdealimentoscod3186: {
    es: "Pensiones de alimentos (cód 3186)",
    en: "Alimony Payments (code 3186)"
  },
  descuentomujercasadaart59cod3187: {
    es: "Descuento mujer casada (Art 59) (cód 3187)",
    en: "Married Woman Discount (Art 59) (code 3187)"
  },
  descuentoporanticiposoprestamoscod3188: {
    es: "Descuento por anticipos o préstamos (cód 3188)",
    en: "Advance or Loan Discount (code 3188)"
  },
  aporteafcempleadorcod4151: {
    es: "Aporte AFC -empleador (cód 4151)",
    en: "AFC Contribution - Employer (code 4151)"
  },
  aporteempleadorseguroaccidentestrabajosannaley16744cod4152: {
    es: "Aporte empleador seguro accidentes del trabajo y Ley SANNA (Ley 16.744) (cód 4152)",
    en: "Employer Contribution Accident Insurance and SANNA Law (Law 16,744) (code 4152)"
  },
  aporteempleadorindemnizacionatodoeventoart164cod4131: {
    es: "Aporte empleador indemnización a todo evento (Art 164) (cód 4131)",
    en: "Employer Contribution Compensation for Any Event (Art 164) (code 4131)"
  },
  aporteadicionaltrabajopesadoempleadorcod4154: {
    es: "Aporte adicional trabajo pesado- empleador (cód 4154)",
    en: "Additional Contribution Heavy Work - Employer (code 4154)"
  },
  aporteempleadorseguroinvalidezsobrevivenciacod4155: {
    es: "Aporte empleador seguro invalidez y sobrevivencia (cód 4155)",
    en: "Employer Contribution Disability and Survivor Insurance (code 4155)"
  },
  aporteempleadorahorroprevisionalvoluntariocolectivocod4157: {
    es: "Aporte empleador ahorro previsional voluntario colectivo (cód 4157)",
    en: "Employer Contribution Voluntary Collective Pension Savings (code 4157)"
  },
  totalhaberescod5201: {
    es: "Total haberes (cód 5201)",
    en: "Total Income (code 5201)"
  },
  totalhaberesimponiblestributablescod5210: {
    es: "Total haberes imponibles y tributables (cód 5210)",
    en: "Total Taxable Income (code 5210)"
  },
  totalhaberesimponiblestributablesnotributablescod5220: {
    es: "Total haberes imponibles y no tributables (cód 5220)",
    en: "Total Taxable and Non-Taxable Income (code 5220)"
  },
  totalhaberesnoimponiblestributablesnotributablescod5230: {
    es: "Total haberes no imponibles y no tributables (cód 5230)",
    en: "Total Non-Taxable and Non-Taxable Income (code 5230)"
  },
  totalhaberesnoimponiblestributablescod5240: {
    es: "Total haberes no imponibles y tributables (cód 5240)",
    en: "Total Non-Taxable and Taxable Income (code 5240)"
  },
  totaldescuentoscod5301: {
    es: "Total descuentos (cód 5301)",
    en: "Total Deductions (code 5301)"
  },
  totaldescuentosimpuestosalasremuneracionescod5361: {
    es: "Total descuentos impuestos a las remuneraciones (cód 5361)",
    en: "Total Deductions Taxes on Salaries (code 5361)"
  },
  totaldescuentosimpuestosporeindemnizacionescod5362: {
    es: "Total descuentos impuestos por indemnizaciones (cód 5362)",
    en: "Total Deductions Taxes on Indemnities (code 5362)"
  },
  totaldescuentosporcotizacionesdeltrabajadorcod5341: {
    es: "Total descuentos por cotizaciones del trabajador (cód 5341)",
    en: "Total Deductions for Worker's Contributions (code 5341)"
  },
  totalotrosdescuentoscod5302: {
    es: "Total otros descuentos (cód 5302)",
    en: "Total Other Deductions (code 5302)"
  },
  totalaportesempleadorcod5410: {
    es: "Total aportes empleador (cód 5410)",
    en: "Total Employer Contributions (code 5410)"
  },
  totalliquidocod5501: {
    es: "Total líquido (cód 5501)",
    en: "Net Total (code 5501)"
  },
  totalindemnizacionescod5502: {
    es: "Total indemnizaciones (cód 5502)",
    en: "Total Indemnities (code 5502)"
  },
  totalindemnizacionestributablescod5564: {
    es: "Total indemnizaciones tributables (cód 5564)",
    en: "Total Taxable Indemnities (code 5564)"
  },
  totalindemnizacionesnotributablescod5565: {
    es: "Total indemnizaciones no tributables (cód 5565)",
    en: "Total Non-Taxable Indemnities (code 5565)"
  }







}