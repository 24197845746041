import React, { useEffect, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Loading } from "@components/common/Loading";
import { Container } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { branchOfficeRepository } from '@/service/repositories/BranchOffice.repository';
import { Props } from './BranchOfficeTypes.type';
import { ModalBranchOfficeType } from '@/components/Modal/ModalBranchOfficeType/ModalBranchOfficeType';
import { ModalBranchOfficeTypeDelete } from '@/components/Modal/ModalBranchOfficeTypeDelete/ModalBranchOfficeTypeDelete';
import ___PHRASE from '@/service/translate/translateService';
import { apiResponseTranslate } from '@/toolbox/helpers/responseApi.helper';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_TRANSLATE, KEY_USER_DATA } from '@/toolbox/constants/local-storage';

export const BranchOfficeTypesView: React.FC<Props> = (props:any): JSX.Element => {

    const [loading, setLoading] = useState<boolean>(false);
    const [branchOfficeTypes, setBranchOfficeTypes] = useState<any>([]);
    const [showBranchOfficeTypeModal, setShowBranchOfficeTypeModal] = useState<boolean>(false);
    const [showBranchTypeDeleteModal, setShowBranchTypeDeleteModal] = useState<boolean>(false);
    const [actionSelected, setActionSelected] = useState<any>('');
    const [branchOfficeTypeSelected, setBranchOfficeTypeSelected] = useState<any>('');

    const language = readLocalStorage(KEY_TRANSLATE);
    const dataUser = readLocalStorage(KEY_USER_DATA);
    const typeSuperadmin = dataUser?.user?.type_superadmin;

    useEffect(() => {
        getBranchOfficeTypes()
    },[]);

    const getBranchOfficeTypes = async () => {
        setLoading(true);
        const response = await branchOfficeRepository.getBranchTypes();
        const newData = [];
        response.data && (response.data).length > 0 && (response.data).map((item)=>{
            if(language && language == 'en'){
                newData.push({...item, name: apiResponseTranslate(item.name)})
            }else{
                newData.push(item)
            }
        })

        setBranchOfficeTypes(newData || [])
        setLoading(false);
    }

    function RecuperarData(value){
        console.log(value);
        setBranchOfficeTypeSelected(value);
    }

    return (
        <Protected>
            {loading && <Loading/>}

            <Container>
                <TableDataV2
                title={___PHRASE("tiposdesucursales")}
                data={branchOfficeTypes}
                header={[
                    { name: 'name', label: ___PHRASE("nombre"), filter: false, Chip: true },
                    { name: 'description', label: ___PHRASE("descripcion"), filter: false, Chip: true },

                ]}
                actionSelect={setActionSelected}
                setModalSave={setShowBranchOfficeTypeModal}
                setModalDelete={setShowBranchTypeDeleteModal}
                status_action={typeSuperadmin == '1' ? true : false}
                btn_disabled={typeSuperadmin == '1' ? false : true}
                RecuperarData={RecuperarData}
                />
            </Container>

            { 
                showBranchOfficeTypeModal && (
                <ModalBranchOfficeType
                    open={showBranchOfficeTypeModal}
                    closeModal={()=>{setShowBranchOfficeTypeModal(false)}}
                    actionSelected={actionSelected}
                    onListTypes={getBranchOfficeTypes}
                    branchOfficeType={branchOfficeTypeSelected}
                    clearState={()=>{setBranchOfficeTypeSelected('')}}
                />
                )
            }
            {
                actionSelected === 'delete' && (
                <ModalBranchOfficeTypeDelete
                    open={showBranchTypeDeleteModal}
                    closeModal={()=>{setShowBranchTypeDeleteModal(false)}}
                    actionSelected={actionSelected}
                    onListTypes={getBranchOfficeTypes}
                    branchOfficeType={branchOfficeTypeSelected}
                    clearState={()=>{setBranchOfficeTypeSelected('')}}
                />
                )
            }
        
        </Protected>
    )
};