import { politicsRepository } from "../repositories/Politic.repository";

export const PoliticService = {
    getTypeDocuments,
    uploadDocuments,
    getDocuments,
    editDocuments,
    deleteDocument
}

async function getTypeDocuments(){
    const res = await politicsRepository.getTypeDocuments();
    return res;
}
async function uploadDocuments(data){
    const res = await politicsRepository.uploadDocuments(data);
    return res;
}
async function getDocuments(type){
    const res = await politicsRepository.getDocuments(type);
    return res;
}
async function editDocuments(id, data){
    const res = await politicsRepository.editDocuments(id,data);
    return res;
}
async function deleteDocument(id){
    const res = await politicsRepository.deleteDocuments(id);
    return res;
}


