import { AfpService } from '@/service/services/Afp.service';
import { Alert, Snackbar, Typography } from "@mui/material";
import { useState } from "react";
import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalFooter } from "../ModalFooter";
import { ModalHeader } from "../ModalHeader";
import { Props } from "./ModalAFPDelete.type";
import ___PHRASE from "@/service/translate/translateService";
import { Loading } from "@/components/common/Loading";
import { apiResponseTranslate } from '@/toolbox/helpers/responseApi.helper';

export const ModalAFPDelete: React.FC<Props> = (props:any): JSX.Element => {
    const { open, closeModal, afp, onListAFP, clearState } = props;
    const [loading, setLoading] = useState<boolean>(false);

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
  });

    const onDeleted= async()=>{
        setLoading(true);
        await AfpService.deleteDetailAfp(afp.id).then((data) => {
          let apimessagge = apiResponseTranslate(data?.data?.message);
          if (data.data.status) {
            setSnackBarConfig({ ...snackBarConfig, open: true, severity: 'success', message: apimessagge })
            onListAFP && onListAFP()
            closeModal && closeModal();
          }
        })
        setLoading(false);
    }

  return (
    <>
     {loading && <Loading/>}
     <Snackbar
        open={snackBarConfig.open} 
        autoHideDuration={snackBarConfig.autoHideDuration} 
        onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
        <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
          {snackBarConfig.message || ""}
        </Alert>
      </Snackbar>
      <Modal
        open={open}
        handleClose={closeModal}
        size="xs"
      >
       <ModalHeader
          text={___PHRASE("eliminar")+' valor AFP'}
          className='positionElements'
          onCancel={closeModal}
          clearState={clearState}
        >
        </ModalHeader>

      <ModalBody>
        <Typography variant="subtitle1" className="textColor">
        {___PHRASE("estaSeguroQueDeseaEliminarEstosValoresDeAFP")}
        </Typography>
      </ModalBody>

      <ModalFooter
        confirmText={___PHRASE("eliminar")}
        cancelText={___PHRASE("cancelar")}
        onCancel={closeModal}
        className="footerRight"
        onConfirm={onDeleted}
        clearState={clearState}
      />
    </Modal>
    </>
  )
}