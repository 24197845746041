import { Loading } from "@/components/common/Loading";
import { Protected } from "@/components/layout/Protected"
import { ContractService } from "@/service/services/Contract.service";
import { KEY_INTEGRATIONS_COMPANY, KEY_USER_DATA } from "@/toolbox/constants/local-storage";
import { ROLE_ADMIN, ROLE_TRABAJADOR } from "@/toolbox/defaults/static-roles";
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { Alert, Button, Grid, Snackbar, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { ROUTE_WORKER } from "@/toolbox/constants/route-map";
import { ModalContractValidation } from "@/components/Modal/ModalContractValidation";
import { ModalSignatureDetail } from "@/components/Modal/ModalSignatureDetail/ModalSIgnatureDetail";
import ___PHRASE from "@/service/translate/translateService";

export const ContractEmployee: React.FC<any> = (props: any) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [dataPDF, setDataPDF] = useState<any>(null)
    const [messageStatus, setMessageStatus] = useState<any>('');
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [openModalDetail, setOpenModalDetail] = useState<boolean>(false);

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });
    const userData = readLocalStorage(KEY_USER_DATA);
    const idEmployee = props?.location?.state?.idEmployee || userData?.user.idemployee;
    const role = userData?.user.role
    const nameEmployee = props?.location?.state?.name

    const integrationsCompany = readLocalStorage(KEY_INTEGRATIONS_COMPANY);
    const digitalSignature = integrationsCompany.find(signature => signature.name == "Firma Digital");

    const history = useHistory();

    useEffect(() => {
        getContract();
        getContractStatus();
    }, []);

    const getContract = async () => {
        setLoading(true);
        const RespPDF = await ContractService.getDocumentContract(idEmployee)
        if (RespPDF) {
            const blob = new Blob([RespPDF], { type: 'application/pdf' });
            const blobURL = URL.createObjectURL(blob);
            setDataPDF(blobURL)
        }
        setLoading(false);
    }

    const getContractStatus = async() =>{
        const respStatus = await ContractService.getValidationSignature();
        if(respStatus?.data){
            setMessageStatus(respStatus?.data?.message)
        }
    }

    const createSignature = async(data) => {
        const respStatus = await ContractService.createSignature(data);
        getContract();
        getContractStatus();
        return respStatus
    }

    const changeMessage = (message) => {
        switch(message){
            case 'Falta subir datos de su firma':
             return ___PHRASE("faltaSubirDatosDeSuFirma");
            case 'Falta firma Digital':
             return ___PHRASE("faltaFirmaDigital");
            case 'Verificación rechazada': 
             return ___PHRASE("verificacionRechazada");
        }
    }

    return (
        <Protected>
            {loading && <Loading />}
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
                <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                    {snackBarConfig.message || ""}
                </Alert>
            </Snackbar>
            <Container>

                {role == ROLE_TRABAJADOR &&<> <Typography variant='h6' component='h1' gutterBottom className="tittle-level2"
                    sx={{ m: 1, fontWeight: 600 }}
                >
                    {___PHRASE("micontrato")}
                </Typography>
                <Grid my={2}>
                <span>{___PHRASE("estadoDelContrato")+`: ${changeMessage(messageStatus)}`}</span>
                </Grid>
                {
                    messageStatus == 'Verificación rechazada' && (
                        <Grid item xs={12} container alignItems="center">
                        <Typography variant="subtitle2" className="custom-input" sx={{
                           border: `1px #e37474 solid`, 
                           borderRadius:'8px', 
                           padding: '5px', 
                           color: '#df5959'}}
                        >
                           {___PHRASE("registrarElDocumentoIntegroColoresNitidoLegible")}
                        </Typography>
                     </Grid>
                    )
                }
                </>
                }
               
              
                {role == ROLE_ADMIN &&
                    <>
                        <Link to={ROUTE_WORKER} className='link__css'>
                            <Typography variant='h6' gutterBottom className='link__css-back'>
                                <ArrowBackIosIcon className='icon_back' /> {___PHRASE("volver")}
                            </Typography>
                        </Link>
                        <Typography variant='h6' component='h1' gutterBottom className="tittle-level2"
                            sx={{ m: 1, fontWeight: 600 }}
                        >
                            {nameEmployee}
                        </Typography>
                    </>
                }
                {
                    dataPDF && role == ROLE_TRABAJADOR && (
                        messageStatus == 'Falta subir datos de su firma' || messageStatus == 'Verificación rechazada' || 
                        messageStatus == 'Falta firma Digital'
                    ) && <Button sx={{ my: 2 }} onClick={() => { setOpenModal(true) }}>{___PHRASE("firmar")}</Button>
                }
                { dataPDF && role == ROLE_ADMIN && (
                        <Grid sx={{justifyContent: 'space'}}>
                            {
                                digitalSignature ? (
                                    <Typography style={{marginBottom: '15px', marginLeft: '5px'}} variant="body1">{___PHRASE("suEmpresaCuentaConLaIntegracionDeFirmaDigital")}</Typography>
                                ) : (
                                    <Button sx={{ my: 2 }} onClick={() => {setOpenModalDetail(true) }}>{___PHRASE("verFirma")}</Button>
                                )
                            }
                        </Grid>
                    )
                }

                <iframe src={dataPDF} id="pdfVer" height="1000px" width="100%"></iframe>
            </Container>

            {
                openModal && <ModalContractValidation
                open={openModal}
                setOpen={setOpenModal}
                createSignature={createSignature}
                setDataPDF={setDataPDF}
                getContract={getContract}
                getContractStatus={getContractStatus}
                idEmployee={idEmployee}
                />
            }
            {
                openModalDetail && <ModalSignatureDetail
                open={openModalDetail}
                setOpen={setOpenModalDetail}
                idEmployee={idEmployee}
                getContractStatus={getContractStatus}
                getContract={getContract}
                />
            }

        </Protected>
    )
}