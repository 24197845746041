import React, { useEffect, useRef, useState } from "react";
import { Protected } from "@components/layout/Protected";
import { Loading } from "@components/common/Loading";
import { Alert, Breadcrumbs, Container, Grid, Snackbar } from "@mui/material";
import { TableDataV2 } from "@/components/common/Tablev2";
import { WorkerService } from "@/service/services/Worker.service";
import { emphasize, styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import GroupsIcon from "@mui/icons-material/Groups";
import { Props } from "./Movements.type";
import { MovementsService } from "@/service/services/Movements.service";
import { ModalMovements } from "@/components/Modal/ModalMovements";
import { ModalMovementDelete } from "@/components/Modal/ModalMovementDelete";
import moment from "moment";
import ___PHRASE from "@/service/translate/translateService";
import { apiResponseTranslate } from "@/toolbox/helpers/responseApi.helper";
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { KEY_TRANSLATE } from "@/toolbox/constants/local-storage";

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
   const backgroundColor =
      theme.palette.mode === "light"
         ? theme.palette.grey[100]
         : theme.palette.grey[800];
   return {
      backgroundColor,
      height: theme.spacing(3),
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightRegular,
      "&:hover, &:focus": {
         backgroundColor: emphasize(backgroundColor, 0.06),
      },
      "&:active": {
         boxShadow: theme.shadows[1],
         backgroundColor: emphasize(backgroundColor, 0.12),
      },
   };
});

export const MovementsView: React.FC<Props> = (props: any): JSX.Element => {

   const language = readLocalStorage(KEY_TRANSLATE);
   const [loading, setLoading] = useState<boolean>(false);
   const [workers, setWorkers] = useState<any>([]);
   const [workerSelected, setWorkerSelected] = useState<any>("");
   const [movements, setMovements] = useState<any>([]);
   const [showMovementsModal, setShowMovementsModal] = useState<boolean>(false);
   const [showMovementsDeleteModal, setShowMovementsDeleteModal] =
      useState<boolean>(false);
   const [movemntSelected, setMovemntSelected] = useState<any>("");
   const [actionSelected, setActionSelected] = useState<any>("");
   const [view, setView] = useState<String>("workers");
   const [showMovemnts, setShowMovements] = useState<boolean>(false);
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
   })

   const cabecera = [
      { name: "n_document", label: ___PHRASE("rutTrabajador") },
      { name: "surname", label: ___PHRASE("apellidoPaterno") },
      { name: "second_surname", label: ___PHRASE("apellidoMaterno") },
      { name: "name", label: ___PHRASE("nombres") },
   ];

   const cabeceraMovements = [
      { name: "movstaffdetail", label: ___PHRASE("tipoMovimiento") },
      { name: "date_from", label: ___PHRASE("fechaInicio") },
      { name: "date_until", label: ___PHRASE("fechaFin") },
      { name: "registration_date", label: ___PHRASE("fechaDeRegistro") },
      { name: "observation", label: ___PHRASE("observacion") },
   ];

   useEffect(() => {
      getWorkers();
   }, []);

   useEffect(() => {
      if (workerSelected) {
         getMovementsByEmployee(workerSelected.id);
      }
   }, [workerSelected]);

   const getWorkers = async () => {
      setLoading(true);
      const resp: any = await WorkerService.getWorkers();
      setWorkers(resp.data || []);
      setLoading(false);
   };

   const getMovementsByEmployee = async (idEmployee) => {
      setLoading(true);
      await MovementsService.getMovementsByEmployee(idEmployee).then((data) => {
         const newData = [];
         data.data && (data.data).length > 0 && (data.data).map((item)=>{
            if(language && language == 'en'){
               newData.push({...item, movstaffdetail: apiResponseTranslate(item.movstaffdetail)})
            }else{
               newData.push(item)
            }
         })
         setMovements(newData || []);
         setLoading(false);
      });
   };

   function RecuperarData(value) {
      console.log(value);
      !workerSelected && setWorkerSelected(value);
      workerSelected && setMovemntSelected(value);
      setShowMovements(true);
      setView("movements");
      // setOpenModal(true)
   }

   const searchData = async (value) => {
      const resp: any = await WorkerService.getWorkers(value);
      setWorkers(resp.data || []);
   };
   const downloadExcelMovements = async () => {
      setLoading(true);
      const respExcel: any = await MovementsService.generateExcelMovement();

      const btn_excel = document.createElement("a");
      btn_excel.href = `${respExcel.data.link}`;
      btn_excel.download = "RLE_Opcionales.xlsx";

      document.body.appendChild(btn_excel);
      btn_excel.click();
      document.body.removeChild(btn_excel);
      setLoading(false);
   };

    const statusDisabled = (movement) => {
        const start = moment(movement.date_from, 'YYYY-MM-DD');
      //   if (moment().month() !== start.month() || moment().year() !== start.year()){
      //       return true
      //   }
        return false
    }

    const getSnackBar = (severity, message) => {
       setSnackBarConfig(prev => ({
          ...prev, open: true,
          severity: severity,
          message: message
          }
       ));
    }

   return (
      <Protected>
         {loading && <Loading />}
        <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>
         <Container>
            <Breadcrumbs aria-label="breadcrumb" sx={{my:3, ml:2}}>
               <StyledBreadcrumb
                  label={___PHRASE("listaDeEmpleados")}
                  onClick={() => {
                     setView("workers");
                     setWorkerSelected("");
                     setShowMovements(false);
                  }}
                  icon={<GroupsIcon fontSize="small" />}
               />
               {showMovemnts && (
                  <StyledBreadcrumb
                     label={___PHRASE("movimientos")}
                     onClick={() => {
                        setView("movements");
                     }}
                     icon={<GroupsIcon fontSize="small" />}
                  />
               )}
            </Breadcrumbs>
            {view == "workers" && (
               <>
                  <Grid container>
                     {/* <Grid item xs={6}>
                        <Button
                           onClick={() => {
                              downloadExcelMovements();
                           }}
                        >
                           Descargar Plantilla
                        </Button>
                     </Grid>
                     <Grid>
                        <TextField type={"file"} />
                        <Button>Subir Plantilla</Button>
                     </Grid> */}
                     <TableDataV2
                        title={___PHRASE("movimientosPorEmpleado")}
                        data={workers}
                        header={cabecera}
                        actionSelect={setActionSelected}
                        status_action
                        disabled_title={false}
                        btn_disabled
                        btn_edit_disable={true}
                        btn_delete_disable={true}
                        btn_helper
                        currentView={view}
                        textSearch={___PHRASE("buscarTrabajador")}
                        searchData={searchData}
                        RecuperarData={RecuperarData}
                     />
                  </Grid>
               </>
            )}
            {view == "movements" && (
               <TableDataV2
                  title={___PHRASE("movimientosDe")+` "${workerSelected?.name}"`}
                  data={movements}
                  header={cabeceraMovements}
                  actionSelect={setActionSelected}
                  setModalSave={setShowMovementsModal}
                  setModalDelete={setShowMovementsDeleteModal}
                  status_action
                  disabled_title={false}
                  btn_disabled={false}
                  status_disabled={statusDisabled}
                  btn_delete_disable={false}
                  // btn_detail
                  currentView={view}
                  RecuperarData={RecuperarData}
               />
            )}
         </Container>

         {showMovementsModal && (
            <ModalMovements
               open={showMovementsModal}
               closeModal={() => {
                  setShowMovementsModal(false);
               }}
               actionSelected={actionSelected}
               onListMovements={getMovementsByEmployee}
               movement={movemntSelected}
               workerSelected={workerSelected}
               getSnackBar={getSnackBar}
               clearState={() => {
                  setMovemntSelected("");
               }}
            />
         )}
         {actionSelected === "delete" && (
            <ModalMovementDelete
               open={showMovementsDeleteModal}
               closeModal={() => {
                  setShowMovementsDeleteModal(false);
               }}
               actionSelected={actionSelected}
               onListMovements={getMovementsByEmployee}
               movement={movemntSelected}
               workerSelected={workerSelected}
               clearState={() => {
                  setMovemntSelected("");
               }}
            />
         )}
      </Protected>
   );
};
