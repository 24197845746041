import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Props } from "./ModalApplicants.type";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useState } from "react";
import { Autocomplete, Button, Divider, Grid, MenuItem, Select, TextField, Typography, Snackbar, Alert } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { ApplicantsService } from "@/service/services/Applicants.service";
import { Loading } from "@/components/common/Loading";
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { KEY_TRANSLATE, KEY_USER_DATA } from "@/toolbox/constants/local-storage";
import { AfpService } from "@/service/services/Afp.service";
import { HealthService } from "@/service/services/Health.service";
import { EducationLevelService } from "@/service/services/EducationLevel.service";
import { MilitarySituacionService } from "@/service/services/MilitarySituacion.service";
import { RemNationalityService } from "@/service/services/RemNationality.service";
import { ProfessionService } from "@/service/services/Profession.service";
import { CountryService } from "@/service/services/Country.service";
import { CivilStatusService } from "@/service/services/CivilStatus.service";
import ___PHRASE from "@/service/translate/translateService";
import { apiResponseTranslate } from "@/toolbox/helpers/responseApi.helper";

export const ModalApplicants: React.FC<Props> = (props: any): JSX.Element => {

    const { open, closeModal, actionSelected, applicant, onListApplicants, clearState } = props;

    const language = readLocalStorage(KEY_TRANSLATE);
    const dataUser = readLocalStorage(KEY_USER_DATA);
    const idcompany = dataUser?.user?.id_company;

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [currentCv, setCurrentCv] = useState<any>(null); //state to store curriculum
    //afps
    const [afpTypes, SetAfpTypes] = useState<any>([]);
    const [afpSelected, SetAfpSelected] = useState<any>({});
    //health
    const [codeHealth, SetCodeHealth] = useState<any>([]);
    const [healthSelected, SetHealthSelected] = useState<any>({});
    //eductacionLevel
    const [EducationLevels, SetEducationLevels] = useState<any>([]);
    //militarySituacions
    const [militarySituacions, SetMilitarySituacions] = useState<any>([]);
    //proffesions
    const [professions, SetProfessions] = useState<any>([]);
    const [professionSelected, SetProfessionSelected] = useState<any>({});
    //nationality
    const [nationalities, SetNationalities] = useState<any>([]);
    
    //for get idDistrict
    const [countries, setCountries] = useState<any>([]);
    const [countrySelected, setCountrySelected] = useState<any>('');
    const [departaments, setDepartaments] = useState<any>([]);
    const [departamentSelected, setDepartamentSelected] = useState<any>('');
    const [provinces, setProvinces] = useState<any>([]);
    const [provinceSelected, setProvinceSelected] = useState<any>('');
    const [districts, setDistricts] = useState<any>([]);
    const [districtSelected, setDistrictSelected] = useState<any>('');

    //civilstatus
    const [civilStatus, setcivilStatus] = useState<any>([]);

    const [data, setData] = useState({
        id: applicant ? applicant.id : '',
        id_job_offer: 11,
        pensioner: applicant ? applicant.pensioner : '',
        password: applicant ? applicant.password : '',
        name: applicant ? applicant.name : '',
        first_surname: applicant ? applicant.first_surname : '',
        second_surname: applicant ? applicant.second_surname : '',
        rut: applicant ? applicant.rut : '',
        adresss: applicant ? applicant.adresss : '',
        phone: applicant ? applicant.phone : '',
        email: applicant ? applicant.email : '',
        birth_date: applicant ? applicant.birth_date : '',
        sex: applicant ? applicant.sex : '',
        cv: applicant ? applicant.cv : '',
        id_afp: applicant ? applicant.id_afp : '',
        id_salud: applicant ? applicant.id_salud : '',
        id_education_level: applicant ? applicant.id_education_level : '',
        id_military_situacion: applicant ? applicant.id_military_situacion : '',
        id_profession: applicant ? applicant.id_profession : '',
        id_nationality: applicant ? applicant.id_nationality : '',
        id_district: applicant ? applicant.id_district : '',
        id_civil_status: applicant ? applicant.id_civil_status : '',
        id_employe: applicant ? applicant.id_employe : '',
    });

    useEffect(() => {
        getAfpTypes();
        getCodeHealth();
        getEducationLevels();
        getMilitarySituacions();
        getProfessions();
        getNationalities();
        getCountries();
        getCivilStatus();
    }, []);

    useEffect(() => {
        if(applicant && afpTypes){
            setCustomAfp(applicant.id_afp, afpTypes);
        }

    },[applicant, afpTypes]);

    useEffect(() => {
        if(applicant && professions){
            setCustomProfession(applicant.id_profession, professions);
        }

    },[applicant, professions]);

    useEffect(() => {
        if(applicant && districts){
            setCustomDistrict(applicant.id_district, districts);
        }

    },[applicant, districts]);

    const getAfpTypes = async () => {
        setLoading(true);
        await AfpService.getAfpType().then((data) => {
            SetAfpTypes(data.data || [])
           setLoading(false);
        });
    }

    const getCodeHealth = async () => {
        setLoading(true);
        await HealthService.getCodeHealth().then((data) => {
            SetCodeHealth(data.data || [])
           setLoading(false);
        });
    }

    const getEducationLevels = async () => {
        setLoading(true);
        await EducationLevelService.getEducationLevels().then((data) => {
            const newData = [];
            data.data && (data.data).length > 0 && (data.data).map((item)=>{
                if(language && language == 'en'){
                    newData.push({...item, name: apiResponseTranslate(item.name)})
                }else{
                    newData.push(item)
                }
            })

            SetEducationLevels(newData || [])
            // SetEducationLevels(data.data || [])
           setLoading(false);
        });
    }

    const getMilitarySituacions = async () => {
        setLoading(true);
        await MilitarySituacionService.getMilitarySituacions().then((data) => {
           
            const newData = [];
            data.data && (data.data).length > 0 && (data.data).map((item)=>{
                if(language && language == 'en'){
                    newData.push({...item, name: apiResponseTranslate(item.name)})
                }else{
                    newData.push(item)
                }
            })

            SetMilitarySituacions(newData || [])
            // SetMilitarySituacions(data.data || [])
           setLoading(false);
        });
    }

    const getProfessions = async () => {
        setLoading(true);
        await ProfessionService.getProfessions().then((data) => {
            const newData = [];
            data.data && (data.data).length > 0 && (data.data).map((item)=>{
                if(language && language == 'en'){
                    newData.push({...item, name: apiResponseTranslate(item.name)})
                }else{
                    newData.push(item)
                }
            })

            SetProfessions(newData || [])
            // SetProfessions(data.data || [])
           setLoading(false);
        });
    }

    const getNationalities = async () => {
        setLoading(true);
        await RemNationalityService.getNationalities().then((data) => {
           
            const newData = [];
            data.data && (data.data).length > 0 && (data.data).map((item)=>{
                if(language && language == 'en'){
                    newData.push({...item, type: apiResponseTranslate(item.type)})
                }else{
                    newData.push(item)
                }
            })
            SetNationalities(newData || [])
           setLoading(false);
        });
    }

    const getCountries = async () => {
        setLoading(true);
        await CountryService.getCountries().then((data) => {
            const newData = [];
            data.data && (data.data).length > 0 && (data.data).map((item)=>{
                if(language && language == 'en'){
                    newData.push({...item, name: apiResponseTranslate(item.name)})
                }else{
                    newData.push(item)
                }
            })
            setCountries(newData || [])
            setLoading(false);
        });
    }

    const getDepartments = async (idCountry) => {
        setLoading(true);
        await CountryService.getDepartments(idCountry).then((data) => {
            setDepartaments(data.data || [])
           setLoading(false);
        });
    }

    const getProvinces = async (idDepartament) => {
        setLoading(true);
        await CountryService.getProvinces(idDepartament).then((data) => {
            setProvinces(data.data || [])
           setLoading(false);
        });
    }

    const getDistricts = async (idProvince) => {
        setLoading(true);
        await CountryService.getDistricts(idProvince).then((data) => {
            setDistricts(data.data || [])
           setLoading(false);
        });
    }

    const getCivilStatus = async () => {
        setLoading(true);
        await CivilStatusService.getCivilStatus().then((data) => {
            const newData = [];

            data.data && (data.data).length > 0 && (data.data).map((item)=>{
                if(language && language == 'en'){
                    newData.push({...item, name: apiResponseTranslate(item.name)})
                }else{
                    newData.push(item)
                }
            })
            setcivilStatus(newData || [])

           setLoading(false);
        });
    }

    const validateForm = (values) => {
        let errors: any = {};
        if (!values.name) errors.name = ___PHRASE("nombreRequerido");
        if (!values.first_surname) errors.first_surname = ___PHRASE("apellpaternorequerido");
        if (!values.password) errors.password = ___PHRASE("passwordRequerido");
        if (!values.second_surname) errors.second_surname = ___PHRASE("apellmaternoRequerido");
        if (!values.rut) errors.rut = ___PHRASE("rutRequerido");
        if (!values.adresss) errors.adresss = ___PHRASE("direccionRequerido");
        if (!values.phone) errors.phone = ___PHRASE("telefonoRequerido");
        if (!values.email) errors.email = ___PHRASE("emailRequerido");
        if (!values.birth_date) errors.birth_date = ___PHRASE("fechaNacimientoRequerido");
        if (!values.sex) errors.sex = ___PHRASE("generoRequerido");
        return errors;
    }

    const setNameFile = (event) => {
        const files = event.target && event.target.files || [];
        const currentCv = files[0];
        
        if(currentCv){
            
            setCurrentCv(currentCv);

            const nameAsArray = currentCv.name.split(".") || [];
            const nameWithoutExtension = nameAsArray.filter((word, i) => i != nameAsArray.length-1);
            const nameFile = nameWithoutExtension.join(".") || "";
            return nameFile;
        }
        return "";
    };

    const onSubmit = async (values, { resetForm }) => {

        if(!currentCv && actionSelected != 'edit'){
            return setSnackBarConfig(prev => ({ ...prev, open: true, 
                severity:'error', 
                message: `${___PHRASE("seleccioneUnArchivo")} ` }
            ))
            // return alert(___PHRASE("seleccioneUnArchivo"));
        }

        const formData = new FormData();
        formData.append("id", values.id);
        formData.append("name", values.name);
        formData.append("first_surname", values.first_surname);
        formData.append("second_surname", values.second_surname);
        formData.append("rut", values.rut);
        formData.append("birth_date", values.birth_date);
        formData.append("sex", values.sex);
        formData.append("adresss", values.adresss);
        formData.append("phone", values.phone);
        formData.append("email", values.email);
        formData.append("id_job_offer", values.id_job_offer);
        formData.append("idcompany", values.idcompany);
        formData.append("pensioner", values.pensioner);
        formData.append("password", values.password);
        formData.append("cv", currentCv);
        formData.append("idcompany", idcompany);
        formData.append("id_afp", afpSelected.id);
        formData.append("id_salud", healthSelected.id);
        formData.append("id_education_level", values.id_education_level);
        formData.append("id_military_situacion", values.id_military_situacion);
        formData.append("id_profession", professionSelected.id);
        formData.append("id_nationality", values.id_nationality);
        formData.append("id_district", districtSelected.id);
        formData.append("id_civil_status", values.id_civil_status);

        setLoading(true);
        if (applicant && actionSelected == 'edit') {
            await ApplicantsService.editApplicant(formData).then((data) => {
                onListApplicants && onListApplicants();
                closeModal && closeModal();
            })
        } else {
            await ApplicantsService.createApplicant(formData).then((data) => {
                onListApplicants && onListApplicants();
                closeModal && closeModal();
            })
        }
        setLoading(false);
    }

    function setCustomAfp(idAfp, afpTypes): any {
        const data = afpTypes && afpTypes.find(afp => afp.id === idAfp);
        const newData = {
            id: data?.id,
            name: data?.name
        }
        return SetAfpSelected(newData);
    }

    // function setCustomCodeHealth(idSalud, codeHealth): any {
    //     const data = codeHealth && codeHealth.find(salud => salud.id === idSalud);
    //     const newData = {
    //         id: data?.id,
    //         name: data?.name
    //     }
    //     return SetAfpSelected(newData);
    // }

    function setCustomProfession(idProfession, professions): any {
        const data = professions && professions.find(profession => profession.id === idProfession);
        const newData = {
            id: data?.id,
            name: data?.name
        }
        return SetProfessionSelected(newData);
    }

    function setCustomDistrict(idDistrict, districts): any {
        const data = districts && districts.find(district => district.id === idDistrict);
        const newData = {
            id: data?.id,
            name: data?.name
        }
        return setDistrictSelected(newData);
    }

    return (
        <>
            {loading && <Loading />}

            <Snackbar
                open={snackBarConfig.open} 
                autoHideDuration={snackBarConfig.autoHideDuration} 
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
                <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                    {snackBarConfig.message || ""}
                </Alert>
            </Snackbar>
            <Modal open={open} handleClose={closeModal} handle size="sm">
                <ModalHeader
                    text={((applicant && actionSelected=='edit') ? ___PHRASE("editar") : ___PHRASE("agregar"))+' '+___PHRASE("postulante")}
                    className='positionElements'
                    onCancel={closeModal}
                    clearState={clearState}
                >
                </ModalHeader>
                <ModalBody>
                    <Formik initialValues={data} enableReinitialize validate={(values) => validateForm(values)} onSubmit={onSubmit}>
                        {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
                            return (
                                <form onSubmit={handleSubmit}>
                                    <Grid container >
                                        <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                            <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("nombre")}:</b></Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    id="name"
                                                    variant="standard"
                                                    type="text"
                                                    name="name"
                                                    fullWidth
                                                    value={values.name}
                                                    onChange={handleChange}
                                                    placeholder={___PHRASE("nombre")}
                                                    error={errors.name && touched.name ? true : false}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                            <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("apellidoPaterno")}:</b></Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    id="first_surname"
                                                    variant="standard"
                                                    type="text"
                                                    name="first_surname"
                                                    multiline
                                                    maxRows={4}
                                                    fullWidth
                                                    value={values.first_surname}
                                                    onChange={handleChange}
                                                    placeholder={___PHRASE("apellidoPaterno")}
                                                    error={errors.first_surname && touched.first_surname ? true : false}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                            <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("apellidoMaterno")}:</b></Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    id="second_surname"
                                                    variant="standard"
                                                    type="text"
                                                    name="second_surname"
                                                    multiline
                                                    maxRows={4}
                                                    fullWidth
                                                    value={values.second_surname}
                                                    onChange={handleChange}
                                                    placeholder={___PHRASE("apellidoMaterno")}
                                                    error={errors.second_surname && touched.second_surname ? true : false}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                            <Grid item xs={4}>
                                                <Typography variant="subtitle1"><b>{___PHRASE("sexo")}:</b> </Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Select
                                                    id="sex"
                                                    variant="standard"
                                                    value={values.sex}
                                                    onChange={(event)=>{
                                                    setFieldValue("sex", event.target.value)
                                                    }}
                                                    fullWidth
                                                >
                                                    <MenuItem value="M">{___PHRASE("masculino")}</MenuItem>
                                                    <MenuItem value="F">{___PHRASE("femenino")}</MenuItem>
                                                    <MenuItem value="Otros">{___PHRASE("otros")}</MenuItem>
                                                </Select>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                            <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>RUT:</b></Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    id="rut"
                                                    variant="standard"
                                                    type="text"
                                                    name="rut"
                                                    multiline
                                                    maxRows={4}
                                                    fullWidth
                                                    value={values.rut}
                                                    onChange={handleChange}
                                                    placeholder="RUT"
                                                    error={errors.rut && touched.rut ? true : false}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                            <Grid item xs={4}>
                                            <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("fechaDeNacimiento")}:</b></Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    id="birth_date"
                                                    variant="standard"
                                                    fullWidth
                                                    name="birth_date"
                                                    type="date"
                                                    onChange={handleChange}
                                                    value={values.birth_date}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    error={errors.birth_date && touched.birth_date ? true : false}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                            <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("direccion")}:</b></Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    id="adresss"
                                                    variant="standard"
                                                    type="text"
                                                    name="adresss"
                                                    multiline
                                                    maxRows={4}
                                                    fullWidth
                                                    value={values.adresss}
                                                    onChange={handleChange}
                                                    placeholder={___PHRASE("direccion")}
                                                    error={errors.adresss && touched.adresss ? true : false}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                            <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("telefono")}:</b></Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    id="phone"
                                                    variant="standard"
                                                    type="text"
                                                    name="phone"
                                                    multiline
                                                    maxRows={4}
                                                    fullWidth
                                                    value={values.phone}
                                                    onChange={handleChange}
                                                    placeholder={___PHRASE("telefono")}
                                                    error={errors.phone && touched.phone ? true : false}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                            <Grid item xs={4}>
                                                <Typography variant="body1"><b>{___PHRASE("tipoDeAfp")}:</b> </Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Autocomplete
                                                    id="disable-portal"
                                                    disablePortal
                                                    value={afpSelected}
                                                    options={afpTypes || []}
                                                    getOptionLabel={(option) => option.name || ""}
                                                    fullWidth
                                                    renderInput={(params) => <TextField {...params} variant="standard" label={___PHRASE("escribeParaBuscar")+'*'} />}
                                                    onChange={(e, newValue) => { SetAfpSelected(newValue) }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                            <Grid item xs={4}>
                                                <Typography variant="body1"><b>{___PHRASE("codigoSalud")}:</b> </Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Autocomplete
                                                    id="disable-portal"
                                                    disablePortal
                                                    value={healthSelected}
                                                    options={codeHealth || []}
                                                    getOptionLabel={(option) => option.detail || ""}
                                                    fullWidth
                                                    renderInput={(params) => <TextField {...params} variant="standard" label={___PHRASE("escribeParaBuscar")} />}
                                                    onChange={(e, newValue) => { SetHealthSelected(newValue) }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                            <Grid item xs={4}>
                                                <Typography variant="body1"><b>{___PHRASE("nivelDeEducacion")}:</b> </Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Select
                                                    id="id_education_level"
                                                    variant="standard"
                                                    value={values.id_education_level}
                                                    onChange={(event) => { 
                                                    setFieldValue("id_education_level", event.target.value);
                                                    }}
                                                    error={ errors.id_education_level && touched.id_education_level ? true : false }
                                                    fullWidth
                                                >
                                                    {
                                                    EducationLevels.map((education, i) => {
                                                        // var formatRole = {...role, name: getCustomRoleName(role.name)};
                                                        return <MenuItem key={i} value={education.id}>{education.name}</MenuItem>;
                                                    })
                                                    }
                                                </Select>
                                            </Grid>
                                        </Grid>

                                        <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                            <Grid item xs={4}>
                                                <Typography variant="body1"><b>{___PHRASE("situacionMilitar")}:</b> </Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                            <Select
                                                id="id_military_situacion"
                                                variant="standard"
                                                value={values.id_military_situacion}
                                                onChange={(event) => { 
                                                setFieldValue("id_military_situacion", event.target.value);
                                                }}
                                                error={ errors.id_military_situacion && touched.id_military_situacion ? true : false }
                                                fullWidth
                                            >
                                                {
                                                militarySituacions.map((situacion, i) => {
                                                    // var formatRole = {...role, name: getCustomRoleName(role.name)};
                                                    return <MenuItem key={i} value={situacion.id}>{situacion.name}</MenuItem>;
                                                })
                                                }
                                            </Select>
                                            </Grid>
                                        </Grid>

                                        <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                            <Grid item xs={4}>
                                                <Typography variant="body1"><b>{___PHRASE("profesion")}:</b> </Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Autocomplete
                                                    id="disable-portal"
                                                    disablePortal
                                                    value={professionSelected}
                                                    options={professions || []}
                                                    getOptionLabel={(option) => option.name || ""}
                                                    fullWidth
                                                    renderInput={(params) => <TextField {...params} variant="standard" label={___PHRASE("escribeParaBuscar")} />}
                                                    onChange={(e, newValue) => { SetProfessionSelected(newValue) }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                            <Grid item xs={4}>
                                                <Typography variant="body1"><b>{___PHRASE("nacionalidad")}:</b> </Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Select
                                                    id="id_nationality"
                                                    variant="standard"
                                                    value={values.id_nationality}
                                                    onChange={(event) => { 
                                                    setFieldValue("id_nationality", event.target.value);
                                                    }}
                                                    error={ errors.id_nationality && touched.id_nationality ? true : false }
                                                    fullWidth
                                                >
                                                    {
                                                    nationalities.map((nationality, i) => {
                                                        // var formatRole = {...role, name: getCustomRoleName(role.name)};
                                                        return <MenuItem key={i} value={nationality.id}>{nationality.type}</MenuItem>;
                                                    })
                                                    }
                                                </Select>
                                            </Grid>
                                        </Grid>

                                        <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                            <Grid item xs={4}>
                                                <Typography variant="body1"><b>{___PHRASE("pais")}:</b> </Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Select
                                                    id="id_nationality"
                                                    variant="standard"
                                                    value={countrySelected}
                                                    onChange={(event) => { 
                                                        setCountrySelected(event.target.value);
                                                    }}
                                                    error={ errors.id_nationality && touched.id_nationality ? true : false }
                                                    fullWidth
                                                >
                                                    {
                                                    countries.map((country, i) => {
                                                        // var formatRole = {...role, name: getCustomRoleName(role.name)};
                                                        return <MenuItem key={i} value={country.id} onClick={ () => {getDepartments(country.id)}}>{country.name}</MenuItem>;
                                                    })
                                                    }
                                                </Select>
                                            </Grid>
                                        </Grid>

                                        <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                            <Grid item xs={4}>
                                                <Typography variant="body1"><b>{___PHRASE("departamento")}:</b> </Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Autocomplete
                                                    id="disable-portal"
                                                    disablePortal
                                                    value={departamentSelected}
                                                    options={departaments || []}
                                                    getOptionLabel={(option) => option.name || ""}
                                                    fullWidth
                                                    renderInput={(params) => <TextField {...params} variant="standard" label={___PHRASE("escribeParaBuscar")} />}
                                                    onChange={(e, newValue) => { 
                                                        setDepartamentSelected(newValue); 
                                                        newValue && getProvinces(newValue?.id);
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                            <Grid item xs={4}>
                                                <Typography variant="body1"><b>{___PHRASE("provincia")}:</b> </Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Autocomplete
                                                    id="disable-portal"
                                                    disablePortal
                                                    value={provinceSelected}
                                                    options={provinces || []}
                                                    getOptionLabel={(option) => option.name || ""}
                                                    fullWidth
                                                    renderInput={(params) => <TextField {...params} variant="standard" label={___PHRASE("escribeParaBuscar")} />}
                                                    onChange={(e, newValue) => { 
                                                        setProvinceSelected(newValue);
                                                        newValue && getDistricts(newValue?.id); 
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                            <Grid item xs={4}>
                                                <Typography variant="body1"><b>{___PHRASE("distrito")}:</b> </Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Autocomplete
                                                    id="disable-portal"
                                                    disablePortal
                                                    value={districtSelected}
                                                    options={districts || []}
                                                    getOptionLabel={(option) => option.name || ""}
                                                    fullWidth
                                                    renderInput={(params) => <TextField {...params} variant="standard" label={___PHRASE("escribeParaBuscar")} />}
                                                    onChange={(e, newValue) => { setDistrictSelected(newValue) }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                            <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("pensioner")}:</b></Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    id="pensioner"
                                                    variant="standard"
                                                    type="text"
                                                    name="pensioner"
                                                    multiline
                                                    maxRows={4}
                                                    fullWidth
                                                    value={values.pensioner}
                                                    onChange={handleChange}
                                                    placeholder={___PHRASE("pensioner")}
                                                    error={errors.pensioner && touched.pensioner ? true : false}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                            <Grid item xs={4}>
                                                <Typography variant="body1"><b>{___PHRASE("estadoCivil")}:</b> </Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Select
                                                    id="id_civil_status"
                                                    variant="standard"
                                                    value={values.id_civil_status}
                                                    onChange={(event) => { 
                                                    setFieldValue("id_civil_status", event.target.value);
                                                    }}
                                                    error={ errors.id_civil_status && touched.id_civil_status ? true : false }
                                                    fullWidth
                                                >
                                                    {
                                                    civilStatus.map((state, i) => {
                                                        // var formatRole = {...role, name: getCustomRoleName(role.name)};
                                                        return <MenuItem key={i} value={state.id}>{state.name}</MenuItem>;
                                                    })
                                                    }
                                                </Select>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                            <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("correo")}:</b></Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TextField
                                                    id="email"
                                                    variant="standard"
                                                    type="text"
                                                    name="email"
                                                    multiline
                                                    maxRows={4}
                                                    fullWidth
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    placeholder={___PHRASE("correo")}
                                                    error={errors.email && touched.email ? true : false}
                                                />
                                            </Grid>
                                        </Grid>
                                        {
                                            !applicant && (
                                                <>
                                                <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                    <Grid item xs={4}>
                                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("contrasena")}:</b></Typography>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <TextField
                                                            id="password"
                                                            variant="standard"
                                                            type="text"
                                                            name="password"
                                                            fullWidth
                                                            value={values.password}
                                                            onChange={handleChange}
                                                            placeholder={___PHRASE("contrasena")}
                                                            error={errors.password && touched.password ? true : false}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                                    <Grid item xs={4}>
                                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("curriculum")}:</b></Typography>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Button variant="contained" component="label" fullWidth>
                                                            {/* <GetAppIcon style={{ marginRight: "12px" }} /> */}
                                                            <input
                                                                id="cv"
                                                                type="file"
                                                                name="cv"
                                                                onChange={(event) => {
                                                                    setFieldValue("cv", setNameFile(event));
                                                                }}
                                                            />
                                                        </Button>
                                                    </Grid>
                                                </Grid> 
                                                </>
                                            )
                                        }

                                    </Grid>
                                    <Divider />
                                    <ModalFooter
                                        buttonType="submit"
                                        confirmText={(applicant && actionSelected == 'edit') ? ___PHRASE("actualizar") : ___PHRASE("registrar")}
                                        cancelText={___PHRASE("cancelar")}
                                        onCancel={closeModal}
                                        onConfirm={handleSubmit}
                                        clearState={clearState}
                                    />
                                </form>
                            )
                        }}
                    </Formik>
                </ModalBody>
            </Modal>
        </>
    );
}