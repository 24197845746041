import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';


export const typesExpenseRepository = {

    getTypesExpense: async(search = ''): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/typeExpense?search=${search}`);
        const {data, error, message} = res;
        return {
            data: (data||[]).map((raw) => ({
               id: raw?.id,
               name: raw?.name,
               idrlestructureoptional: raw?.idrlestructureoptional,
               idcompany: raw?.idcompany,
               status: raw?.status,
               rlestructureoptional: raw?.alias_name,
               code: raw?.code
            })),
            error,
            message
         }
    },

    createTypeExpense: async (dataTypeExpense) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/typeExpense`, dataTypeExpense);
        const {data, error, message} = res;
        return { data, error, message };
    },

    editTypeExpense: async (idTypeExpense, dataTypeExpense) : Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/typeExpense/${idTypeExpense}`, dataTypeExpense);
        const {data, error, message} = res;
        return { data, error, message };
    },
    deleteTypeExpense: async(idTypeExpense:number): Promise<any> => {
        const res= await http.delete<any>(`${API_URL_BASE}/v1/typeExpense/${idTypeExpense}`)
        return res;
    },
}


