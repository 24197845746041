import { useEffect, useState } from "react"
import { TableDataV2 } from "@/components/common/Tablev2"
import { Protected } from "@/components/layout/Protected"
import { Container } from "@mui/material"
import { Props } from "./Phases.type"
import { PhasesService } from "@/service/services/Phases.service"
import { Loading } from "@/components/common/Loading"
import { ModalPhases } from "@/components/Modal/ModalPhases"
import { ModalPhaseDelete } from "@/components/Modal/ModalPhaseDelete"
import ___PHRASE from "@/service/translate/translateService"

export const PhasesView: React.FC<Props> = (props:any): JSX.Element => {

    const [loading, setLoading] = useState<boolean>(false);
    const [phases, setPhases] = useState([]);
    const [showPhaseModal, setShowPhaseModal] = useState<boolean>(false);
    const [showPhaseDeleteModal, setShowPhaseDeleteModal] = useState<boolean>(false);
    const [actionSelected, setActionSelected] = useState<any>('');
    const [phaseSelected, setPhaseSelected] = useState<any>('');

    useEffect(() => {
        getPhases();
    }, []);

    const getPhases = async() => {
        setLoading(true);
        await PhasesService.getPhases().then((data) => {
            setPhases(data.data || []);
            setLoading(false);
        })
    }

    function RecuperarData(value){
        setPhaseSelected(value)
        console.log(value)
    }

    return (
        <Protected>
            {loading && <Loading/>}
            <Container>
                <TableDataV2
                    title={___PHRASE("fasesDelReclutamiento")}
                    data={phases || []}
                    header={[
                        { name: 'name', label: ___PHRASE("nombre"), filter: false, Chip: false },
                        { name: 'description', label: ___PHRASE("descripcion"), filter: false, Chip: true },
                        // { name: 'id_company', label: 'Empresa', filter: false, Chip: false },
                    ]}
                    actionSelect={setActionSelected}
                    setModalSave={setShowPhaseModal}
                    setModalDelete={setShowPhaseDeleteModal}
                    status_action
                    // checkbox
                    RecuperarData={RecuperarData}
                />
            </Container>

            { 
                showPhaseModal && (
                    <ModalPhases
                        open={showPhaseModal}
                        closeModal={()=>{setShowPhaseModal(false)}}
                        actionSelected={actionSelected}
                        onListPhases={getPhases}
                        phase={phaseSelected}
                        clearState={()=>{setPhaseSelected('')}}
                    />
                )
            }
            {
                actionSelected === 'delete' && (
                    <ModalPhaseDelete
                        open={showPhaseDeleteModal}
                        closeModal={()=>{setShowPhaseDeleteModal(false)}}
                        actionSelected={actionSelected}
                        onListPhases={getPhases}
                        phase={phaseSelected}
                        clearState={()=>{setPhaseSelected('')}}
                    />
                )
            }
        </Protected>
    )
}