import React, { useEffect, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Container } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { Loading } from '@/components/common/Loading';
import { Props } from './ScheduleCompany.type';
import { ScheduleService } from '@/service/services/Schedule.service';
import { ModalScheduleCompanyDelete } from '@/components/Modal/ModalScheduleCompanyDelete';
import { ModalScheduleCompany } from '@/components/Modal/ModalScheduleCompany';
import ___PHRASE from '@/service/translate/translateService';

export const ScheduleView: React.FC<Props> = (props:any): JSX.Element => {

   const [loading, setLoading] = useState<boolean>(false);
   const [schedule, setSchedule] = useState([]);
   const [showModal, setShowModal] = useState<boolean>(false);
   const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
   const [actionSelected, setActionSelected] = useState<String>('');
   const [scheduleSelected, setScheduleSelected] = useState<String>('');

   useEffect(() => {
    getScheduleCompany()
   },[]);

   const getScheduleCompany = async () => {
      setLoading(true);
      const response = await ScheduleService.getScheduleCompany();
      if (response.data) {
        setSchedule(response.data || [])
      } else {
         console.log('error')
      }
      setLoading(false);
   }

   function RecuperarData(value){
      console.log(value);
      setScheduleSelected(value);
   }

  return (
      <Protected>
         {loading && <Loading/>}
         <Container>
            <TableDataV2
               title={___PHRASE("horarios")}
               data={schedule}
               header={[
                  { name: 'check_in_times', label: ___PHRASE("horarioDeEntrada"), filter: false, Chip: true },
                  { name: 'closing_hour', label: ___PHRASE("horarioDeSalida"), filter: false, Chip: true },
                  { name: 'tolerance_minutes', label: ___PHRASE("toleranciaMin"), filter: false, Chip: true },
                   { name: 'idworkingtime_name', label: ___PHRASE("tipo"), filter: false, Chip: true },
               ]}
               actionSelect={setActionSelected}
               setModalSave={setShowModal}
               setModalDelete={setShowDeleteModal}
               status_action
               // checkbox
             btn_delete_disable={true}
               RecuperarData={RecuperarData}
            />
         </Container>

         { 
            showModal && (
               <ModalScheduleCompany
                  open={showModal}
                  closeModal={()=>{setShowModal(false)}}
                  actionSelected={actionSelected}
                  onList={getScheduleCompany}
                  schedule={scheduleSelected}
                  clearState={()=>{setScheduleSelected('')}}
               />
            )
         }
         {
            actionSelected === 'delete' && (
               <ModalScheduleCompanyDelete
                  open={showDeleteModal}
                  closeModal={()=>{setShowDeleteModal(false)}}
                  actionSelected={actionSelected}
                  onList={getScheduleCompany}
                  schedule={scheduleSelected}
                  clearState={()=>{setScheduleSelected('')}}
               />
            )
         }
      
      </Protected>
   )
};