import { TableDataV2 } from "@/components/common/Tablev2";
import { OnBoardingService } from "@/service/services/OnBoarding.service";
import { PeriodService } from "@/service/services/Period.service";
import ___PHRASE from "@/service/translate/translateService";
import { Box, Button, Container, Grid, IconButton, MenuItem, Select, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DownloadIcon from '@mui/icons-material/Download';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { ModalExcel } from "./ModalExcel";

export const LiquidationWorkersOnBoarding: React.FC<any> = (props:any) : JSX.Element => {

    const { setLoading, idCompany, setView} = props;

    const [structures, setStructures] = useState<any>([]);

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [typeModal, setTypeModal] = useState<string>('')

    useEffect(()=> {
        getStructureOnboarding();
    },[])

    const getStructureOnboarding = async() => {
      setLoading(true)
      const resp:any = await OnBoardingService.getStructureEmployeeOnboarding(idCompany);
      if(resp.data){
         setStructures(resp.data)
      }
      setLoading(false)

    }

    const RecuperarData = (item) => {
        console.log(item)
        setLoading(true)
        let link = item.excel
        if(!!process.env.REACT_APP_HOST){
           link = `${process.env.REACT_APP_HOST}/api/${link}`
        }else{
           link = `http://localhost:8000/${link}`
        }
        console.log(link);
        const btn_excel = document.createElement("a")
        btn_excel.href = `${link}`
        btn_excel.download = 'ESTRUCTURA_EMPLEADOS'+'_'+item.year+'_'+item.month+'.xlsx'
  
        document.body.appendChild(btn_excel);
        btn_excel.click();
        document.body.removeChild(btn_excel)
        setLoading(false)
    }
     
    return (
       <Container>
          <Grid container spacing={3}>
                <Grid item container xs={12} >
                    <IconButton >
                        <ArrowBackIcon sx={{ color: '#FF9700' }} onClick={() => { setView('settlement') }} />
                    </IconButton>
                    <Typography variant="subtitle1" sx={{ pt: 1 }}>
                        {___PHRASE("regresar")}
                    </Typography>
                </Grid>
               <Grid item xs={12} md={12} display='flex' justifyContent='space-between'>
                  <Box>
                     <Typography variant='h6' component='h1' gutterBottom className="tittle">
                        {___PHRASE("estructuraDeLiquidacion")+' - '+___PHRASE("trabajadores")}
                     </Typography>
                  </Box>
               </Grid>
            </Grid>
          <Grid item xs={12} md={12} display='flex' justifyContent='space-between' container marginY={2}>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<DownloadIcon />}
                        onClick={() => {setTypeModal('download');setOpenModal(true)}}>{___PHRASE("descargarPlantilla")}</Button>
                    <Button sx={{ ml: 2 }}
                        variant="contained"
                        color="primary"
                        startIcon={<FileUploadIcon />}
                        onClick={() => {setTypeModal('upload');setOpenModal(true)}}>{___PHRASE("subirPlantilla")}</Button>
                </Grid>
          </Grid>
          <Grid item>
            <TableDataV2 
             data={structures}
             header={[
               {name: 'year', label:___PHRASE("periodo")},
               {name: 'month', label:___PHRASE("mes")},
             ]}
             disabled_title
             btn_disabled
             btn_download
             btn_edit_disable
             btn_delete_disable
             status_action
             RecuperarData={RecuperarData}
            />
          </Grid>
          {
            openModal && 
            <ModalExcel
               closeModal={() => setOpenModal(false)}
               getStructureOnboarding={getStructureOnboarding}
               idCompany={idCompany}
               typeModal={typeModal}
            />
          }
       </Container>
    )
}