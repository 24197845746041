import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Props } from './Deparment.type'
import { Alert, Backdrop, Breadcrumbs, CircularProgress, Container, Divider, Grid, IconButton, Menu, MenuItem, Snackbar } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { deparmentRepository } from '@/service/repositories/Deparment.repository';
import { Loading } from '@/components/common/Loading';
import { ModalDepartment } from '@/components/Modal/ModalDepartment';
import { ModalDepartmentDelete } from '@/components/Modal/ModalDepartmentDelete';
import { emphasize, styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import GroupsIcon from '@mui/icons-material/Groups';
import { ModalDepartamentResponsability } from '@/components/Modal/ModalDepartamentResponsability/ModalDepartamentResponsability';
import { ModalDepartamentResponsabilityDelete } from '@/components/Modal/ModalDepartamentResponsabilityDelete/ModalDepartamentResponsabilityDelete';
import ___PHRASE from '@/service/translate/translateService';
import { apiResponseTranslate } from '@/toolbox/helpers/responseApi.helper';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_TRANSLATE } from '@/toolbox/constants/local-storage';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
   const backgroundColor =
       theme.palette.mode === 'light'
           ? theme.palette.grey[100]
           : theme.palette.grey[800];
   return {
       backgroundColor,
       height: theme.spacing(3),
       color: theme.palette.text.primary,
       fontWeight: theme.typography.fontWeightRegular,
       '&:hover, &:focus': {
           backgroundColor: emphasize(backgroundColor, 0.06),
       },
       '&:active': {
           boxShadow: theme.shadows[1],
           backgroundColor: emphasize(backgroundColor, 0.12),
       },
   };
});


export const DeparmentView: React.FC<Props> = (props:any): JSX.Element => {

   const [loading, setLoading] = useState<boolean>(false);
   const [deparments, setDeparments] = useState([]);
   const [showDepartmentModal, setShowDepartmentModal] = useState<boolean>(false);
   const [showDepartmentDeleteModal, setShowDepartmentDeleteModal] = useState<boolean>(false);
   const [departmentSelected, setDepartmentSelected] = useState<any>('');
   
   const [responsabilities, setResponsabilities] = useState<any>([]);
   const [showResponsabilityModal, setShowResponsabilityModal] = useState<boolean>(false);
   const [showResponsabilityDeleteModal, setShowResponsabilityDeleteModal] = useState<boolean>(false);
   const [responsabilitySelected, setResponsabilitySelected] = useState<any>('');
   const [showResponsabilities, setShowResponsabilities] = useState<boolean>(false);
   
   const [actionSelected, setActionSelected] = useState<any>('');
   const [view, setView] = useState<String>('Areas');

   const cabecera = [
      { name: 'name', label: ___PHRASE("nombre"), filter: false, Chip: true },
      { name: 'branchOffice', label: ___PHRASE("sucursal"), filter: false, Chip: false },
      { name: 'count_workers', label: ___PHRASE("cantidadDeTrabajadores"), filter: false, Chip: true },
   ];

   const cabeceraResponsability = [
      { name: 'description', label: ___PHRASE("descripcion") },
   ];

   const language = readLocalStorage(KEY_TRANSLATE);

   useEffect(() => {
      getDeparments()
   },[]);

   useEffect(()=> {
      if(departmentSelected && actionSelected=='helper' && view=='responsabilities'){
         getResponsibilities(departmentSelected.id);
      }
   },[departmentSelected]);

   const getDeparments = async () => {
      setLoading(true);
      const resp = await deparmentRepository.getDeparments();

      const newData = [];
      resp.data && (resp.data).length > 0 && (resp.data).map((item)=>{
         if(language && language == 'en'){
            newData.push({
               ...item, 
               name: apiResponseTranslate(item.name),
               count_workers: item.count_workers == 'Sin trabajadores' ? apiResponseTranslate(item.count_workers) : (item.count_workers),
            })
         }else{
            newData.push(item)
         }
      })

      setDeparments(newData || []);
      setLoading(false);
   }

   const getResponsibilities = async (idArea) => {
      setLoading(true);
      const response = await deparmentRepository.getResponsibilities(idArea);
      if (response.data) {
         setResponsabilities(response.data || [])
      } else {
         console.log('error')
      }
      setLoading(false);
   }

   function RecuperarData(value){
      console.log(value);

      !departmentSelected && setDepartmentSelected(value);
      departmentSelected && setResponsabilitySelected(value);

      if(value.action == "helper" && view=='Areas'){
         setShowResponsabilities(true);
         setView('responsabilities');
      }
   }

   const searchData = async (value) => {
      const resp = await deparmentRepository.getDeparments(value);
      const newData = [];
      resp.data && (resp.data).length > 0 && (resp.data).map((item)=>{
         if(language && language == 'en'){
            newData.push({
               ...item, 
               name: apiResponseTranslate(item.name),
               count_workers: item.count_workers == 'Sin trabajadores' ? apiResponseTranslate(item.count_workers) : (item.count_workers),
            })
         }else{
            newData.push(item)
         }
      })

      setDeparments(newData || []);
   }

  return (
      <Protected>
         {loading && <Loading/>}
         <Container>

         <Breadcrumbs aria-label="breadcrumb" sx={{mt:3, ml:2}}>
               <StyledBreadcrumb
                  label={___PHRASE("listaDeDepartamentos")}
                  onClick={() => {
                     setView('Areas');
                     setDepartmentSelected('');
                     setShowResponsabilities(false);
                  }}
                  icon={<GroupsIcon fontSize="small" />}
               />
               {
                  showResponsabilities && (
                     <StyledBreadcrumb
                           label={___PHRASE("responsabilidadesDelDepartamento")}
                           onClick={() => {
                              setView('responsabilities');
                              setResponsabilitySelected('');
                           }}
                           icon={<GroupsIcon fontSize="small" />}
                     />
                  )
               }
            </Breadcrumbs>


            {
               view == 'Areas' && (
                  <TableDataV2
                     title={___PHRASE("departamentos")}
                     data={deparments}
                     header={cabecera}
                     actionSelect={setActionSelected}
                     setModalSave={setShowDepartmentModal}
                     setModalDelete={setShowDepartmentDeleteModal}
                     status_action
                     btn_helper
                     textSearch={___PHRASE("buscarDepartamentos")}
                     searchData={searchData}
                     RecuperarData={RecuperarData}
                  />
               )
            }

            {
               view == 'responsabilities' && (
                  <TableDataV2
                     data={responsabilities}
                     header={cabeceraResponsability}
                     actionSelect={setActionSelected}
                     setModalSave={setShowResponsabilityModal}
                     setModalDelete={setShowResponsabilityDeleteModal}
                     status_action
                     disabled_title={false}
                     currentView={view}
                     RecuperarData={RecuperarData}
                     />
               )
            }

         </Container>

         { 
            showDepartmentModal && (
               <ModalDepartment
                  open={showDepartmentModal}
                  closeModal={()=>{setShowDepartmentModal(false)}}
                  actionSelected={actionSelected}
                  onListDepartments={getDeparments}
                  department={departmentSelected}
                  clearState={()=>{setDepartmentSelected('')}}
               />
            )
         }
         {
            actionSelected === 'delete' && (
               <ModalDepartmentDelete
                  open={showDepartmentDeleteModal}
                  closeModal={()=>{setShowDepartmentDeleteModal(false)}}
                  actionSelected={actionSelected}
                  onListDepartments={getDeparments}
                  department={departmentSelected}
                  clearState={()=>{setDepartmentSelected('')}}
               />
            )
         }

         {
            (showResponsabilityModal && view == 'responsabilities') && (
               <ModalDepartamentResponsability
                  open={showResponsabilityModal}
                  closeModal={()=>{setShowResponsabilityModal(false)}}
                  actionSelected={actionSelected}
                  onListResponsability={getResponsibilities}
                  responsability={responsabilitySelected}
                  areaSelected={departmentSelected}
                  clearState={()=>{setResponsabilitySelected('')}}
               />
            )
         }

         {
            (showResponsabilityDeleteModal && view == 'responsabilities') && (
               <ModalDepartamentResponsabilityDelete
                  open={showResponsabilityDeleteModal}
                  closeModal={()=>{setShowResponsabilityDeleteModal(false)}}
                  actionSelected={actionSelected}
                  onListResponsability={getResponsibilities}
                  responsability={responsabilitySelected}
                  areaSelected={departmentSelected}
                  clearState={()=>{setResponsabilitySelected('')}}
               />
            )
         }
      
      </Protected>
   )
};