import { AfpRepository } from "../repositories/Afp.repository";

export const AfpService = {
    getAfpType,
    getDetailAfp,
    createDetailAfp,
    editDetailAfp,
    deleteDetailAfp,
    getYearDetailAFP,
}

async function getAfpType(){
    const res = await AfpRepository.getAfpType();
    return res;
}

async function getDetailAfp(){
    const res = await AfpRepository.getDetailAfp();
    return res;
}

async function getYearDetailAFP(){
    const res = await AfpRepository.getYearDetailAFP();
    return res;
}

async function createDetailAfp(dataCM: any){
    const res = await AfpRepository.createDetailAfp(dataCM);
    return res;
}

async function editDetailAfp(idCM: number, dataCM: any){
    const res = await AfpRepository.editDetailAfp(idCM, dataCM);
    return res;
}

async function deleteDetailAfp(idCM: number){
    const res = await AfpRepository.deleteDetailAfp(idCM);
    return res;
}