import React from 'react'
import { Dialog } from '@mui/material';
import '../../assets/styles/modals.scss';

export const Modal = (props) => {

    const { open, handleClose, size="", children, className, disableBackdropClick, disableEscapeKeyDown} = props;

    const close = (event, reason) => {
        if (disableBackdropClick && reason === "backdropClick") {
            return false;
        }
    
        if (disableEscapeKeyDown && reason === "escapeKeyDown") {
        return false;
        }

        if(typeof(handleClose) === "function"){
            handleClose();
        }

    }

    return (
        <Dialog 
            onClose={close} 
            fullWidth maxWidth={size}
            color="primary" 
            open={open}
            keepMounted
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            PaperProps={{
                style: { borderRadius: 20 }
              }}
            className={className || ''}
       >
            {children}
        </Dialog>
    )
}