import { useEffect, useState } from "react"
import { Protected } from "@/components/layout/Protected"
import { Alert, Box, Button, Container, Grid, IconButton, Snackbar, Typography } from "@mui/material"
import { Loading } from "@/components/common/Loading"
import { Props } from "./AssistanceWorker.type"
import moment from 'moment-timezone';
import 'moment/locale/es';
moment.locale('es');
import { AssistsWorkerService } from "@/service/services/AssistanceWorker.service"
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper"
import { KEY_INTEGRATIONS_COMPANY, KEY_USER_DATA } from "@/toolbox/constants/local-storage"
import Clock from 'react-clock'
import 'react-clock/dist/Clock.css';
import { CloseCircleIcon } from "@/toolbox/constants/icons"
import { companiesService } from "@/service/services/Companies.service"
import ___PHRASE from "@/service/translate/translateService"
import { apiResponseTranslate } from "@/toolbox/helpers/responseApi.helper"

export const AssistanceWorkerView: React.FC<Props> = (props:any): JSX.Element => {

    const [loading, setLoading] = useState<boolean>(false);
    const [mecompany, setMeCompany] = useState<any>([]);
    const [todayAssistance, setTodayAssistance] = useState<any>('');
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const timeZone = mecompany?.time_zone ? mecompany.time_zone : 'America/Santiago';

    const dataUser = readLocalStorage(KEY_USER_DATA);
    const idEmployee = dataUser?.user?.idemployee;

    const previousDate = moment().subtract(7, 'days').format("YYYY-MM-DD");
    const nexDate = moment().add(1, 'days').format("YYYY-MM-DD");

    const integrationsCompany = readLocalStorage(KEY_INTEGRATIONS_COMPANY);
    const AssistanceControl = integrationsCompany.find(assistance => assistance.name == "Control Asistencia (Hardware)");

    useEffect(() => {
        getAssitsCalendar();
    }, [idEmployee, previousDate, nexDate]);

    useEffect(() => {
        getDataCompany();
    }, []);

    const getDataCompany = async () => {
        const resp = await companiesService.getDataCompany();
        setMeCompany(resp.data)
    }

    const getAssitsCalendar = async() => {
        setLoading(true);

        if(AssistanceControl){
            await AssistsWorkerService.getAssistanceWithIntegration(idEmployee).then((data) => {
                if(data?.data) setTodayAssistance(data?.data[0] || '');
                setLoading(false);
            })
        }else{
            await AssistsWorkerService.getAssitsCalendar(idEmployee, previousDate, nexDate).then((data) => {
                if(data?.data) setTodayAssistance(data?.data[0] || '');
                setLoading(false);
            })
        }
        setLoading(false);
    }


    const [currentDate, setCurrentDate] = useState(moment().tz(timeZone).format());

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentDate(moment().tz(timeZone).format());
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const createAssists = async() => {
        setLoading(true);

        const currentHour = moment().tz(timeZone).format("HH:mm");
        const currentDate = moment().tz(timeZone).format("YYYY-MM-DD");

        const dataAssists = {
            idemployee: idEmployee,
            date: currentDate,
            start_time: currentHour,
        }

        await AssistsWorkerService.createAssists(dataAssists).then((data) => {
            if(data.data.status == true){
                getAssitsCalendar();
                setLoading(false);
                return (
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'success', 
                        message: `${apiResponseTranslate(data?.data?.message || '')}` }
                    ))
                );
            }else{
                setLoading(false);
                return (
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'error', 
                        message: `${apiResponseTranslate(data?.data?.message) || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}` }
                    ))
                );
            }
        })
    }

    const updateAssists = async(item) => {

        setLoading(true);
        
        const currentHour = moment().tz(timeZone).format("HH:mm");
        const currentDate = moment().tz(timeZone).format("YYYY-MM-DD");

        const dataAssists = {
            idemployee: idEmployee,
            date: currentDate,
            start_refreshment: ((item.start_time != null ) && 
                                (item.start_refreshment == null || item.start_refreshment == "")) ? currentHour : item.start_refreshment,

            end_refreshment: ((item.start_refreshment != null) && 
                                (item.end_refreshment == null)) ? currentHour : item.end_refreshment,

            end_time: ((item.end_refreshment != null) && 
                        (item.end_time == null)) ? currentHour : item.end_time,
        }

        await AssistsWorkerService.updateAssists(item.id, dataAssists).then((data) => {
            if(data.data.status == true){
                getAssitsCalendar();
                setLoading(false);
                return (
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'success', 
                        message: `${data.data.message}` }
                    ))
                );
            }else{
                setLoading(false);
                return (
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'error', 
                        message: `${apiResponseTranslate(data?.data?.message) || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}` }
                    ))
                );
            }
            
        })
    }

    return (
        <Protected>
            {loading && <Loading/>}
            <Snackbar
                open={snackBarConfig.open} 
                autoHideDuration={snackBarConfig.autoHideDuration} 
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
                <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                    {snackBarConfig.message || ""}
                </Alert>
            </Snackbar>
            <Container>
            <Box
               sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                  m: -1,
                  py: 3
               }}
            >
                <Typography variant="h3" align="center" gutterBottom style={{ color: '#0084c8', fontWeight: 'bold', marginLeft:20}}
               >
                  {___PHRASE("controlDeAsistencias")}
               </Typography>
                    <Grid container sx={{ padding: 7 }} alignItems="center">
                        <Grid item xs={6}>
                            <Clock size={300}
                                value={moment().tz(timeZone).format("HH:mm:ss")}
                                renderMinuteMarks={true}
                                renderSecondHand={true}
                                hourHandWidth={6}
                                hourHandLength={50}
                                minuteHandWidth={4}
                                minuteHandLength={70}
                                secondHandWidth={2}
                                secondHandLength={90}
                                secondHandOppositeLength={20}
                                // secondHandOppositeColor={'#000000'}
                                hourMarksWidth={6}
                                hourMarksLength={10}
                                minuteMarksWidth={3}
                                minuteMarksLength={5}
                                renderNumbers={true}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Container style={{ background: '#f5f5f5', padding: 20, margin: 10 }}>
                                <Typography variant="h4" gutterBottom>
                                    {moment(currentDate).tz(timeZone).format("HH:mm:ss")}
                                </Typography>
                                <Typography variant="subtitle1" gutterBottom>
                                    {moment(currentDate).tz(timeZone).format("LL")}
                                </Typography>
                            </Container>
                            <Container style={{ padding: 20, margin: 10 }}>
                                {
                                    (!AssistanceControl && todayAssistance && (moment(currentDate).format("YYYY-MM-DD") != todayAssistance?.date) ) ||
                                    (todayAssistance?.length == 0 && !AssistanceControl) ? (

                                        <Button color="secondary" variant="contained" onClick={createAssists}
                                            style={{
                                                backgroundColor: '#0084c8',
                                                color: '#fff',
                                                fontWeight: 'bold',
                                                padding: '10px 15px',
                                                fontSize: '20px'
                                            }}
                                        >
                                            {___PHRASE("registrarAsistencia")}
                                        </Button>
                                    ) : (
                                        <Grid style={{border: '1px solid #d1cccc', paddingTop: '10px', paddingBottom: '5px', borderRadius: '8px'}}>
                                            <Typography variant="subtitle1" align="center" gutterBottom style={{color: '#787676'}}>
                                                {___PHRASE("suEmpresaCuentaConLaIntegracionDeAsistencia")}
                                            </Typography>
                                        </Grid>
                                    )
                                }
                                
                                {
                                    (   !AssistanceControl && 
                                        todayAssistance && 
                                        (todayAssistance?.end_time == null || todayAssistance?.end_time == '') && 
                                        (todayAssistance?.start_time != null || todayAssistance?.start_time != '')) && (

                                        <Button color="secondary" variant="contained" onClick={()=>{updateAssists(todayAssistance)}}
                                            style={{
                                                backgroundColor: '#0084c8',
                                                color: '#fff',
                                                fontWeight: 'bold',
                                                padding: '10px 15px',
                                                fontSize: '20px'
                                            }}
                                        >
                                            {
                                                (todayAssistance?.start_refreshment == null || todayAssistance?.start_refreshment == "") ? ___PHRASE("registrarInicioDeRefrigerio") : 
                                                ((todayAssistance?.start_refreshment != null) && (todayAssistance?.end_refreshment == null)) ?
                                                ___PHRASE("registrarFinDeRefrigerio") : 
                                                ((todayAssistance?.end_refreshment != null) && (todayAssistance?.end_time == null)) ? 
                                                ___PHRASE("registrarSalida") : ''
                                            }
                                        </Button>
                                    ) 
                                }
                            </Container>
                            {
                                (currentDate && todayAssistance) && 
                                (moment(currentDate).format("YYYY-MM-DD") == todayAssistance?.date) &&
                                todayAssistance && todayAssistance?.start_time && (
                                    <Container style={{ padding: 5, margin: 10 }}>
                                        <Box bgcolor="#4caf50" color="white" p={2} display="flex" alignItems="center">
                                            <Typography variant="subtitle2" align="center" gutterBottom> {___PHRASE("haMarcadoAsistenciaaLas")+' '+(todayAssistance?.start_time)+' '+___PHRASE("horasDel")} {moment(todayAssistance?.date).format("LL")}
                                            </Typography>
                                            <IconButton size="small" onClick={()=>{}}>
                                                <CloseCircleIcon fontSize="small" />
                                            </IconButton>
                                        </Box>
                                    </Container>
                                )
                            }

                        </Grid>
                        {
                            (currentDate && todayAssistance && 
                            (moment(currentDate).format("YYYY-MM-DD") == todayAssistance?.date) ) && (

                                <Box sx={{ width:'100%', display: 'flex', marginTop: '50px', alignItems:'center', justifyContent: 'center'}}>
                                    {
                                        todayAssistance && todayAssistance?.start_refreshment && (
                                            <Box bgcolor="#567fe5" color="white" p={2} display="flex" alignItems="center" marginRight={'13px'}>
                                                <Typography variant="subtitle2" align="center" gutterBottom>{___PHRASE("haMarcadoInicioDeRefrigerioaLas")+' '+(todayAssistance?.start_refreshment)+' '+___PHRASE("horas")} 
                                                </Typography>
                                            </Box>
                                        )
                                    }
                                    {
                                        todayAssistance && todayAssistance?.end_refreshment && (
                                            <Box bgcolor="#567fe5" color="white" p={2} display="flex" alignItems="center" marginRight={'13px'}>
                                                <Typography variant="subtitle2" align="center" gutterBottom>{___PHRASE("haMarcadoFinDeRefrigerioaLas")+' '+(todayAssistance?.end_refreshment)+' '+___PHRASE("horas")}
                                                </Typography>
                                            </Box>
                                        )
                                    }
                                    {
                                        todayAssistance && todayAssistance?.end_time && (
                                            <Box bgcolor="#567fe5" color="white" p={2} display="flex" alignItems="center">
                                                <Typography variant="subtitle2" align="center" gutterBottom> {___PHRASE("haMarcadoSalidaaLas")+' '+(todayAssistance?.end_time)+' '+___PHRASE("horas")}
                                                </Typography>
                                            </Box>
                                        )
                                    }
                                </Box>
                            )
                        }
                        
                    </Grid>
            </Box>
            </Container>
        </Protected>
    )
}