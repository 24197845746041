import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';

export const ProfileSessionRepository = {

    getProfiles: async(): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/sessionProfile`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    getProfileByUser: async(idUser): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/sessionProfile/${idUser}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    getProfileByRole: async(idrole): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/sessionProfile/getProfileByRole/${idrole}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    createProfiles: async (dataProfile) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/sessionProfile`, {
            name: dataProfile.name,
            idviews: dataProfile.idviews //array
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    editProfile: async (id, dataProfile) : Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/sessionProfile/${id}`, {
            name: dataProfile.name,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    editViews: async (id, dataProfile) : Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/sessionProfile/updateViews/${id}`, {
            idviews: dataProfile.idviews,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    deleteProfiles: async(id): Promise<any> => {
        const res = await http.delete<any>(`${API_URL_BASE}/v1/sessionProfile/${id}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    getRoles: async(): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/profile`);
        const {data, error, message} = res;
        return { data, error, message };
    }

}   