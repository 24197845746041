import { ProductsRepository } from "../repositories/Products.reposiotry";

export const ProductService = {
    getProducts,
    createProducts,
    editProducts,
    inventaryGeneralProduct,
    inventaryGeneralProductExcel,
    purchasesByProdyct,
    movementsByPurchase,
    changeBranchOfficeMovement,
    benefitMovemnt,
    refundMovement,
}

async function getProducts(search = ''){
    const res = await ProductsRepository.getProducts(search);
    return res;
}

async function createProducts(data:{}){
    const res = await ProductsRepository.createProducts(data);
    return res;
}

async function editProducts(data:{}, idProduct: number){
    const res = await ProductsRepository.editProducts(data, idProduct);
    return res;
}

async function inventaryGeneralProduct(){
    const res = await ProductsRepository.inventaryGeneralProduct();
    return res;
}

async function inventaryGeneralProductExcel(){
    const res = await ProductsRepository.inventaryGeneralProductExcel();
    return res;
}


async function purchasesByProdyct(idProduct: number){
    const res = await ProductsRepository.purchasesByProdyct(idProduct);
    return res;
}

async function movementsByPurchase(idPurchase: number){
    const res = await ProductsRepository.movementsByPurchase(idPurchase);
    return res;
}

async function changeBranchOfficeMovement(data:{}){
    const res = await ProductsRepository.changeBranchOfficeMovement(data);
    return res;
}

async function benefitMovemnt(data:{}){
    const res = await ProductsRepository.benefitMovemnt(data);
    return res;
}

async function refundMovement(data:{}){
    const res = await ProductsRepository.refundMovement(data);
    return res;
}

