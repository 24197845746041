import React, { useState, useMemo, useRef, useEffect } from 'react';
import { Grid, Container, Button, Autocomplete, CircularProgress, Modal, Box, FormControl, MenuItem, FormHelperText, Select, InputLabel, Typography, TextField, Divider, InputAdornment, FormControlLabel, Checkbox, } from '@mui/material';
import { ArrowBackIcon, ArrowDownIcon, ArrowRightIcon, MoreIcon } from "@toolbox/constants/icons";
import { Props } from './FormLaborData.type'
import { VALIDATORS } from '@toolbox/helpers/validation-rules';
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import Entity from "@assets/img/entity.png";
import './style.sass'
import "./FormLaborData_style.css";
import BadgeIcon from '@mui/icons-material/Badge';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ___PHRASE from '@/service/translate/translateService';

export const FormLaborData: React.FC<Props> = (
   props: Props
): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [openModal, setOpenModal] = useState(false)
   const [isDisabled, setIsDisabled] = useState<boolean>(false);
   const [error, setError] = useState<any>('')
   const [value, setValue] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
   );
   const [estado, setEstado] = useState<any>({ rut: false, nombre_rz: false })

   const [valueConsiderable, setValueConsiderable] = React.useState('Si');
   const [valueTipoAdquisicion, setValueTipoAvalueTipoAdquisicion] = React.useState('Activo Propio');

   const [valueDatosLaborales,setvalueDatosLaborales ] = useState<any>(props.valueDatosLaborales)

   const handleChangeConsiderable = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValueConsiderable(event.target.value);
   };

   const handleTipoAvalueTipoAdquisicion = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValueTipoAvalueTipoAdquisicion(event.target.value);
   };

   const [errors, setErrors] = useState({
      idcharge: '',
      idprofession: '',
      idbusinessarea: '',
      idtype_contract: '',
      idbranch_office:'',
      // status:'', 
      workersubsidy: '',
      idworkmode:'',
      idcommuneprovisionservices: '',
      iddistrictbranchoffice: '',
      money: '',
      idgratificationtype: '',
      idcostcenter: '',
      private_role: '',
      n_control_card: '',
      years_other_employers: '',
      apv_libro_rem: 0,
      unemployment_insurance: '',
      idtype_duration_contract: '',
      workerstate:'',
      iddisableperson: '',
      time_control: '',
      idworkingtime:'',
      iddocumentcontract: ''
   })

   const cargoValue = [
      { id:'1', label: 'Cargo  1' },
      { id:'2', label: 'Cargo  2' },
      { id:'3', label: 'Cargo  3' },
      { id:'4', label: 'Cargo  4' },
      { id:'5', label: 'Cargo  5' },
   ]

   const departamentoValue = [
      { id:1, label: 'FINANZAS' },
      { id:2, label: 'RECURSOS HUMANOS' },
      { id:3, label: 'CONTABILIDAD' }
   ]
   const tipoContrato = [
      { id:1, label: 'Indefinido' },
      { id:2, label: 'Plazo Fijo' }
   ]
   const estadoValue = [
      { id:'1', label: 'Finiquitado/Cesado' },
      { id:'2', label: 'VIgente/Activo' }
   ]
   const subsidioEstado = [
      { id:'1', label: ___PHRASE("siTieneSubsidio") },
      { id:'0', label: ___PHRASE("noTieneSubsidio") }
   ]
   const comunaPrestacion = [
      { id:1, label: 'Algarrobo' },
      { id:2, label: 'Alburre' },
      { id:3, label: 'Angol' },
      { id:4, label: 'otro' }
   ]
   const tipoGratificacion = [
      { id:'1', label: '25% MENSUAL CON TOPE' },
      { id:'2', label: '25% MENSUAL SIN TOPE' },
      { id:'3', label: 'ANUAL' },
      { id:'4', label: 'SIN(NO APLICA)' }
   ]
   const tipoCesantia = [
      { id:1, label: 'NO' },
      { id:2, label: 'SI' },
   ]
   const tipoTrabajador = [
      { id:1, label: 'Activo' },
      { id:2, label: 'Pensionado y Cotiza' },
      { id:3, label: 'Pensionado y No Cotiza' },
   ]
   const tipoDiscapacidad = [
      { id:1,  label: 'NO' },
      { id:2, label: 'Discapacidad Certificada por la Compin' },
      { id:3, label: 'Asignatorio Pension por Invalidez Total' },
   ]
   const centroCosto = [
      { id:1, label: 'Operaciones' },
      { id:2, label: 'Ventas' },
      { id:3, label: 'Desarrollo' },
   ]
   const rotPrivado = [
      { id:'1', label: 'SI' },
      { id:'2', label: 'NO' },
   ]
   const certificadoRenta = [
      { id:1, label: 'Honorario' },
      { id:2, label: 'Participación' },
      { id:3, label: 'Sueldo' },
   ]

   const seguroCesantia = [
      { id:"0", label: ___PHRASE("no") },
      { id:"1", label: ___PHRASE("si") },
   ]


   const rules = useMemo(() => ({
      unidadMedida: [
         VALIDATORS.REQUIRED,
      ],
      stockMinimo: [
         VALIDATORS.REQUIRED,
         VALIDATORS.CODE.ONLY_NUMBER
      ],
      stockMaximo: [
         VALIDATORS.REQUIRED,
         VALIDATORS.CODE.ONLY_NUMBER
      ],
      ubicacionFisica: [
         VALIDATORS.REQUIRED,
      ],

   }), []);


   const handleInput = (event) => {
      setError('')
      const name = event.target.name;
      const value = event.target.value;

      
      setErrors((prevErrors) => ({
         ...prevErrors,
         [name]: '',
       }));

       setvalueDatosLaborales((prevData) => ({
         ...prevData,
         [name]: value,
       }));
      // switch (name) {

      //    case 'money':
      //       props.setError('');
      //       setvalueDatosLaborales(prev => ({ ...prev, money: value }));
      //       break;
      //    // case 'private_role':
      //    //    props.setError('');
      //    //    setvalueDatosLaborales(prev => ({ ...prev, private_role: value }));
      //    //    break;
      //    // case 'n_control_card':
      //    // props.setError('');   
      //    // setvalueDatosLaborales(prev => ({ ...prev, n_control_card: value }));
      //    //    break;
      //    case 'years_other_employers':
      //       props.setError('');
      //       setvalueDatosLaborales(prev => ({ ...prev, years_other_employers: value }));
      //       break;
      //    default:
      //       break;
      // }
   };
   const handleInputBlur = (event: any) => {
      const newVal = (event.target.value).trim();
      const name = event.target.name;
      valueDatosLaborales(prev => ({ ...prev, [name]: newVal }));
   }

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();

      const newErrors:any = {};

      if (!valueDatosLaborales.idcharge) { newErrors.idcharge = 'idcharge';}
      if (!valueDatosLaborales.idbusinessarea) {  newErrors.idbusinessarea = 'idbusinessarea'; }
      if (!valueDatosLaborales.idbranch_office) {  newErrors.idbranch_office = 'idbranch_office';}
      if (!valueDatosLaborales.idworkmode || valueDatosLaborales.idworkmode == "") {  newErrors.idworkmode = 'idworkmode';}
      if (!valueDatosLaborales.idworkingtime || valueDatosLaborales.idworkingtime == "") {  newErrors.idworkingtime = 'idworkingtime';}
      if (!valueDatosLaborales.unemployment_insurance) {  newErrors.unemployment_insurance = 'unemployment_insurance';}
      if (!valueDatosLaborales.idtype_duration_contract || valueDatosLaborales.idtype_duration_contract == "") {  newErrors.idtype_duration_contract = 'idtype_duration_contract'; }
      if (!valueDatosLaborales.idtype_contract) {  newErrors.idtype_contract = 'idtype_contract'; }
      if (!valueDatosLaborales.iddocumentcontract || valueDatosLaborales.iddocumentcontract == "") { newErrors.iddocumentcontract = 'iddocumentcontract';}
      if(!valueDatosLaborales.workerstate) { newErrors.workerstate = 'workerstate';}
      if (!valueDatosLaborales.workersubsidy) {  newErrors.workersubsidy = 'workersubsidy'; }
      if(!valueDatosLaborales.idcommuneprovisionservices){ newErrors.idcommuneprovisionservices = 'idcommuneprovisionservices';}
      if (!valueDatosLaborales.money) {  newErrors.money = 'money'; }
      if (!valueDatosLaborales.idgratificationtype) {  newErrors.idgratificationtype = 'idgratificationtype'; }
      if (!valueDatosLaborales.idcostcenter) {  newErrors.idcostcenter = 'idcostcenter'; }
      if (!valueDatosLaborales.years_other_employers) {  newErrors.years_other_employers = 'years_other_employers'; }

      setErrors(newErrors);

      if (Object.keys(newErrors).length === 0) {
         props.setvalueDatosLaborales(valueDatosLaborales); props.handleNext()
       }
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }
   return (
      <Container maxWidth="xl">
         <form >
            <Grid container spacing={3} className={`e-mb-lg`}>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     // disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={props.dataInitial.Charge}
                     onChange={(e, value:any)=>{ setvalueDatosLaborales(prev => ({ ...prev, idcharge: value?value.id:null }));  setErrors((prevErrors) => ({
                        ...prevErrors,
                        idcharge: '',
                      }));
                     }}
                     getOptionLabel={(option: any) => option.name ? option.name : ''}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     value={
                        // props.valueDatosLaborales?.idcharge?(
                        //    props.dataInitial.Charge.filter((value)=>{return value.id == props.valueDatosLaborales?.idcharge})[0]
                        // ):(
                           valueDatosLaborales?.idcharge?(
                              props.dataInitial.Charge.filter((value)=>{return value.id == valueDatosLaborales?.idcharge})[0]
                           ):(
                              null
                           )
                        // )
                        }
                     renderInput={(params) => <TextField {...params} label={___PHRASE("cargo")} 
                     error={!!errors.idcharge}
                     helperText={errors.idcharge && ___PHRASE("campoObligatorio")}/>}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     // disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={props.dataInitial.BusinessArea}
                     onChange={(e,value:any)=> { setvalueDatosLaborales(prev => ({ ...prev, idbusinessarea: value?value.id:null })); 
                     setErrors((prevErrors) => ({
                        ...prevErrors,
                        idbusinessarea: '',
                      }));
                  }}
                     getOptionLabel={(option: any) => option.name ? option.name : ''}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     value={
                        // props.valueDatosLaborales?.idbusinessarea?(
                        //    props.dataInitial.BusinessArea.filter((value)=>{return value.id == props.valueDatosLaborales?.idbusinessarea})[0]
                        // ):(
                           valueDatosLaborales?.idbusinessarea?(
                              props.dataInitial.BusinessArea.filter((value)=>{return value.id == valueDatosLaborales?.idbusinessarea})[0]
                           ):(
                              null
                           )
                        // )
                        }
                     renderInput={(params) => <TextField {...params} label={___PHRASE("departamento")} 
                     error={!!errors.idbusinessarea}
                     helperText={errors.idbusinessarea && ___PHRASE("campoObligatorio")}/>}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     // disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={props.dataInitial.BranchOffice}
                     onChange={(e,value:any)=> { setvalueDatosLaborales(prev => ({ ...prev, idbranch_office: value?value.id:null })); 
                     setErrors((prevErrors) => ({
                        ...prevErrors,
                        idbranch_office: '',
                      }));
                  }}
                     getOptionLabel={(option: any) => option.address ? option.address : ''}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     value={
                        // props.valueDatosLaborales?.idbranch_office?(
                        //    props.dataInitial.BranchOffice.filter((value)=>{return value.id == props.valueDatosLaborales?.idbranch_office})[0]
                        // ):(
                           valueDatosLaborales?.idbranch_office?(
                              props.dataInitial.BranchOffice.filter((value)=>{return value.id == valueDatosLaborales?.idbranch_office})[0]
                           ):(
                              null
                           )
                        // )
                        }
                     renderInput={(params) => <TextField {...params} label={___PHRASE("sucursalOficina")} 
                     error={!!errors.idbranch_office}
                     helperText={errors.idbranch_office && ___PHRASE("campoObligatorio")}/>}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     // disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={props.dataInitial.WorkMode }
                     onChange={(e,value:any)=> { setvalueDatosLaborales(prev => ({ ...prev, idworkmode: value?value.id:null }));
                     setErrors((prevErrors) => ({
                        ...prevErrors,
                        idworkmode: '',
                      }));
                  }}
                     getOptionLabel={(option: any) => option.mode ? option.mode : ''}
                     value={
                           valueDatosLaborales?.idworkmode?(
                              props.dataInitial.WorkMode.filter((value)=>{return value.id == valueDatosLaborales?.idworkmode})[0]
                           ):(
                              null
                           )
                        // )
                        }
                     renderInput={(params) => <TextField {...params} label={___PHRASE("modoDeTrabajo")} 
                     error={!!errors.idworkmode}
                     helperText={errors.idworkmode && ___PHRASE("campoObligatorio")}/>}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     // disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={props.dataInitial.WorkingTime }
                     onChange={(e,value:any)=> { setvalueDatosLaborales(prev => ({ ...prev, idworkingtime: value?value.id:null })); 
                     setErrors((prevErrors) => ({
                        ...prevErrors,
                        idworkingtime: '',
                      }));
                  }}
                     getOptionLabel={(option: any) => option.name ? option.name : ''}
                     value={
                           valueDatosLaborales?.idworkingtime?(
                              props.dataInitial.WorkingTime.filter((value)=>{ return value.id == valueDatosLaborales?.idworkingtime})[0]
                           ):(
                              null
                           )
                        // )
                        }
                     renderInput={(params) => <TextField {...params} label={___PHRASE("tiempoDeTrabajo")} 
                     error={!!errors.idworkingtime}
                     helperText={errors.idworkingtime && ___PHRASE("campoObligatorio")}/>}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     // disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={seguroCesantia }
                     onChange={(e,value:any)=> { setvalueDatosLaborales(prev => ({ ...prev, unemployment_insurance: value?value.id:null })); 
                     setErrors((prevErrors) => ({
                        ...prevErrors,
                        unemployment_insurance: '',
                      }));
                  }}
                     getOptionLabel={(option: any) => option.label ? option.label : ''}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     value={
                        // props.valueDatosLaborales?.idworkmode?(
                        //    props.dataInitial.WorkMode.filter((value)=>{return value.id == props.valueDatosLaborales?.idworkmode})[0]
                        // ):(
                           valueDatosLaborales?.idworkmode?(
                              seguroCesantia.filter((value)=>{return value.id == valueDatosLaborales?.unemployment_insurance})[0]
                           ):(
                              null
                           )
                        // )
                        }
                     renderInput={(params) => <TextField {...params} label={___PHRASE("seguroDeCesantia")} 
                     error={!!errors.unemployment_insurance}
                     helperText={errors.unemployment_insurance && ___PHRASE("campoObligatorio")}/>}
                  />
               </Grid>
               
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     // disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={props.dataInitial.TypeDurationContract }
                     onChange={(e,value:any)=> { setvalueDatosLaborales(prev => ({ ...prev, idtype_duration_contract: value.id })); 
                     setErrors((prevErrors) => ({
                        ...prevErrors,
                        idtype_duration_contract: '',
                      }));
                  }}
                     getOptionLabel={(option: any) => option.name ? option.name : ''}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     value={
                        // props.valueDatosLaborales?.idtype_duration_contract?(
                        //    props.dataInitial.TypeDurationContract.filter((value)=>{return value.id == props.valueDatosLaborales?.idtype_duration_contract})[0]
                        // ):(
                           valueDatosLaborales?.idtype_duration_contract?(
                              props.dataInitial.TypeDurationContract.filter((value)=>{return value.id == valueDatosLaborales?.idtype_duration_contract})[0]
                           ):(
                              null
                           )
                        // )
                        }
                     renderInput={(params) => <TextField {...params} label={___PHRASE("duracionDeContrato")} 
                     error={!!errors.idtype_duration_contract}
                     helperText={errors.idtype_duration_contract && ___PHRASE("campoObligatorio")}/>}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     // disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={props.dataInitial.ContractType}
                     onChange={(e, value:any)=> {setvalueDatosLaborales(prev => ({ ...prev, idtype_contract: value?value.id:null })); 
                     setErrors((prevErrors) => ({
                        ...prevErrors,
                        idtype_contract: '',
                      }));
                  }}
                     getOptionLabel={(option: any) => option.name ? option.name : ''}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     value={
                        // props.valueDatosLaborales?.idtype_contract?(
                        //    props.dataInitial.ContractType.filter((value)=>{return value.id == props.valueDatosLaborales?.idtype_contract})[0]
                        // ):(
                           valueDatosLaborales?.idtype_contract?(
                              props.dataInitial.ContractType.filter((value)=>{return value.id == valueDatosLaborales?.idtype_contract})[0]
                           ):(
                              null
                           )
                        // )
                        }
                     renderInput={(params) => <TextField {...params} label={___PHRASE("tipoDeContrato")}
                     error={!!errors.idtype_contract} 
                     helperText={errors.idtype_contract && ___PHRASE("campoObligatorio")}/>}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     // disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={props.dataInitial.DocumentContract }
                     onChange={(e,value:any)=> { setvalueDatosLaborales(prev => ({ ...prev, iddocumentcontract: value?value.id:null })); 
                     setErrors((prevErrors) => ({
                        ...prevErrors,
                        iddocumentcontract: '',
                      }));
                  }}
                     getOptionLabel={(option: any) => option.name ? option.name : ''}
                     value={
                           valueDatosLaborales?.iddocumentcontract?(
                              props.dataInitial.DocumentContract.filter((value)=>{ return value.id == valueDatosLaborales?.iddocumentcontract})[0]
                           ):(
                              null
                           )
                        // )
                        }
                     renderInput={(params) => <TextField {...params} label={___PHRASE("tipoDocumentoContrato")} 
                     error={!!errors.iddocumentcontract}
                     helperText={errors.iddocumentcontract && ___PHRASE("campoObligatorio")}/>}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     // disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={props.dataInitial.State}
                     onChange={(e, value:any)=> {setvalueDatosLaborales(prev => ({ ...prev, workerstate: value?value.valueStatus:null }));
                     setErrors((prevErrors) => ({
                        ...prevErrors,
                        workerstate: '',
                      }));
                  }}
                     getOptionLabel={(option: any) => option.status ? option.status : ''}
                     value={
                        // props.valueDatosLaborales?.workerstate?(
                        //    props.dataInitial.State.filter((value)=>{return value.valueStatus == props.valueDatosLaborales?.workerstate})[0]
                        // ):(
                           valueDatosLaborales?.workerstate?(
                              props.dataInitial.State.filter((value)=>{return value.valueStatus == valueDatosLaborales?.workerstate})[0]
                           ):(
                              null
                           )
                        // )
                        }
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label={___PHRASE("estado")}
                     error={!!errors.workerstate}
                     helperText={errors.workerstate && ___PHRASE("campoObligatorio")}/>}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     // disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={subsidioEstado}
                     onChange={(e, value:any)=>{setvalueDatosLaborales(prev => ({ ...prev, workersubsidy: value?value.id:null }));
                     setErrors((prevErrors) => ({
                        ...prevErrors,
                        workersubsidy: '',
                      }));
                  }}
                     value={
                        // props.valueDatosLaborales?.workersubsidy?(
                        //    subsidioEstado.filter((value)=>{return value.id == props.valueDatosLaborales?.workersubsidy})[0]
                        // ):(
                           valueDatosLaborales?.workersubsidy?(
                              subsidioEstado.filter((value)=>{return value.id == valueDatosLaborales?.workersubsidy})[0]
                           ):(
                              null
                           )
                        // )
                        }
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label={___PHRASE("subsidioTrabajadorJoven")}
                     error={!!errors.workersubsidy} 
                     helperText={errors.workersubsidy && ___PHRASE("campoObligatorio")}/>}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     // disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={props.dataInitial.District}
                     onChange={(e, value:any)=>{setvalueDatosLaborales(prev => ({ ...prev, idcommuneprovisionservices: value?value.id:null }));
                     setErrors((prevErrors) => ({
                        ...prevErrors,
                        idcommuneprovisionservices: '',
                      }));
                  }}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     getOptionLabel={(option: any) => option.name ? option.name : ''}
                     value={
                        // props.valueDatosLaborales?.idcommuneprovisionservices?(
                        //    props.dataInitial.District.filter((value)=>{return value.id == props.valueDatosLaborales?.idcommuneprovisionservices})[0]
                        // ):(
                           valueDatosLaborales?.idcommuneprovisionservices?(
                              props.dataInitial.District.filter((value)=>{return value.id == valueDatosLaborales?.idcommuneprovisionservices})[0]
                           ):(
                              null
                           )
                        // )
                        }
                     renderInput={(params) => <TextField {...params} label={___PHRASE("comunaDePrestacionDeServicios")}
                     error={!!errors.idcommuneprovisionservices}
                     helperText={errors.idcommuneprovisionservices && ___PHRASE("campoObligatorio")}/>}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Input
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <BadgeIcon />
                        </InputAdornment>
                     }
                     ref={ref => inputRefs.current[0] = ref}
                     name="money"
                     type='number'
                     placeholder={___PHRASE("sueldoPactado")}
                     value={valueDatosLaborales.money}
                     onChange={handleInput}
                     error={!!errors.money}
                     errorMessages={errors.money && ___PHRASE("campoObligatorio")}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                     height='38px'
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     // disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={props.dataInitial.GratificationType}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     onChange={(e,value:any)=> { setvalueDatosLaborales(prev => ({ ...prev, idgratificationtype: value?value.id:null }));
                     setErrors((prevErrors) => ({
                        ...prevErrors,
                        idgratificationtype: '',
                      }));
                  }}
                     getOptionLabel={(option: any) => option.name ? option.name : ''}
                     value={
                        // props.valueDatosLaborales?.idgratificationtype?(
                        //    props.dataInitial.GratificationType.filter((value)=>{return value.id == props.valueDatosLaborales?.idgratificationtype})[0]
                        // ):(
                           valueDatosLaborales?.idgratificationtype?(
                              props.dataInitial.GratificationType.filter((value)=>{return value.id == valueDatosLaborales?.idgratificationtype})[0]
                           ):(
                              null
                           )
                        // )
                        }
                     renderInput={(params) => <TextField {...params} label={___PHRASE("tipoDeGratificacion")}
                     error={!!errors.idgratificationtype}
                     helperText={errors.idgratificationtype && ___PHRASE("campoObligatorio")} />}
                  />
               </Grid>
               {/* <Grid item xs={12} sm={3}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={tipoCesantia}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Tipo Cesantía"
                     />}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={tipoDiscapacidad}
                     // onChange={(e,value:any)=>{}}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Persona con discapacidad/pensionado por invalidez" />}
                  />
               </Grid> */}
               {/* <Grid item container mb={3} xs={12} >
                  <Typography variant="subtitle1" >
                     Bonos
                  </Typography>
               </Grid> */}
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     // disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={props.dataInitial.CostCenter}
                     onChange={(e,value:any)=>{setvalueDatosLaborales(prev => ({ ...prev, idcostcenter: value?value.id:null }));
                     setErrors((prevErrors) => ({
                        ...prevErrors,
                        idcostcenter: '',
                      }));
                     }}
                     value={
                        // props.valueDatosLaborales?.idcostcenter?(
                        //    props.dataInitial.CostCenter.filter((value)=>{return value.id == props.valueDatosLaborales?.idcostcenter})[0]
                        // ):
                        // (
                           valueDatosLaborales?.idcostcenter?(
                              props.dataInitial.CostCenter.filter((value)=>{return value.id == valueDatosLaborales?.idcostcenter})[0]
                           ):(
                              null
                           )
                        // )
                        }
                     // sx={{ bgcolor: '#F3F3F3'}}
                     getOptionLabel={(option: any) => option.name ? option.name : ''}
                     renderInput={(params) => <TextField {...params} label={___PHRASE("centroCosto")}
                     error={!!errors.idcostcenter}
                     helperText={errors.idcostcenter && ___PHRASE("campoObligatorio")} />}
                  />
               </Grid>
               {/* <Grid item container mb={3} xs={12} >
                  <Typography variant="subtitle1" >
                     Perfil del Empleado
                  </Typography>
               </Grid> */}
               {/* <Grid item xs={12} sm={3}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={rotPrivado}
                     onChange={(e, value:any)=>{ setvalueDatosLaborales(prev => ({ ...prev, private_role: value.id })); props.setError('');}}
                     value={
                        props.valueDatosLaborales?.private_role?(
                           rotPrivado.filter((value)=>{return value.id == props.valueDatosLaborales?.private_role})[0]
                        ):(
                           valueDatosLaborales?.private_role?(
                              rotPrivado.filter((value)=>{return value.id == valueDatosLaborales?.private_role})[0]
                           ):(
                              null
                           )
                        )
                        }
                     getOptionLabel={(option: any) => option.label ? option.label : ''}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Rol Privado" 
                     error={!!props.error=='private_role'}
                     helperText={props.error=='private_role' && 'Campo obligatorio'}/>}
                  />
               </Grid> */}
               {/* <Grid item xs={12} sm={3}>
                  <Input
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <BadgeIcon />
                        </InputAdornment>
                     }
                     ref={ref => inputRefs.current[0] = ref}
                     name="n_control_card"
                     type='text'
                     placeholder="N° tarjeta control horario"
                     value={valueDatosLaborales.n_control_card}
                     onChange={handleInput}
                     error={!!props.error=='n_control_card'}
                     errorMessages={props.error=='n_control_card' && 'Campo obligatorio'}
                     // backgroundColor="#F3F3F3"
                     
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid> */}
               {/* <Grid item xs={12} sm={3}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={rotPrivado}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Sujeto a control horario" />}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={certificadoRenta}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label="Certificado Renta" />}
                  />
               </Grid> */}
               <Grid item xs={12} sm={3}>
                  <Input
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <BadgeIcon />
                        </InputAdornment>
                     }
                     ref={ref => inputRefs.current[0] = ref}
                     name="years_other_employers"
                     type='text'
                     placeholder={___PHRASE("anosOtrosControladores")}
                     value={valueDatosLaborales.years_other_employers}
                     error={!!errors.years_other_employers}
                     errorMessages={errors.years_other_employers && ___PHRASE("campoObligatorio")}
                     onChange={handleInput}
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                     height='38px'
                  />
               </Grid>
               
               <Grid item container
                  justifyContent='space-between'
               >
                   <Grid item xs={6}>
                     <Button
                        variant='contained'
                        onClick={() => { props.setvalueDatosLaborales(valueDatosLaborales); props.handleBack(); }}
                        disabled={loading}
                        disableElevation
                        className='btn_back'
                        startIcon={<ArrowBackIosIcon />}
                     >
                        {
                           loading ?
                              <CircularProgress className="CircularProgress" size={24} /> :
                              <span>{___PHRASE("atras")}</span>
                        }
                     </Button>
                  </Grid>
                  <Grid item xs={6} container
                     justifyContent='right'>
                     {/* <Button
                        type='submit'
                        variant='contained'
                        onClick={handleSubmit}
                        disabled={loading}
                        disableElevation
                     >
                        {
                           loading ?
                              <CircularProgress className="CircularProgress" size={24} /> :
                              <span>Guardar</span>
                        }
                     </Button> */}
                     <Button
                        type='submit'
                        variant='contained'
                        // onClick={()=>{props.setvalueDatosLaborales(valueDatosLaborales); props.handleNext()}}
                        onClick={(e)=>handleSubmit(e)}
                        disabled={loading}
                        disableElevation
                        className='btn_save'
                        sx={{ml:2}}
                        endIcon={<ArrowRightIcon />}
                     >
                        {
                           loading ?
                              <CircularProgress className="CircularProgress" size={24} /> :
                              <span>{___PHRASE("siguiente")}</span>
                        }
                     </Button>

                  </Grid>
               </Grid>
            </Grid>
         </form>
      </Container>
   );
}
