import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik } from 'formik';
import { useEffect, useState } from "react";
import { Alert, Autocomplete, Divider, Grid, MenuItem, Select, Snackbar, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { Loading } from "@/components/common/Loading";
import { Props } from "./ModalStructureLiquidation.type";
import { RleStructureService } from "@/service/services/RleStructure.service";
import ___PHRASE from "@/service/translate/translateService";
import { apiResponseTranslate } from "@/toolbox/helpers/responseApi.helper";

export const ModalStructureLiquidation: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, onListStructures, setValueTab } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [generalStructures, SetGeneralStructures] = useState<any>([]);
    const [generalStructureSelected, SetGeneralStructureSelected] = useState<any>('');
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const [data, setData] = useState({
        id: '',
        idtblrlestructure: '',
        alias_name: '',
        indefinite_validity: '0',
    });

    useEffect(() => {
        getRLEStructure();
    }, []);

    const getRLEStructure = async() => {
        const requireStructure = 0; //values: 1 - 0
        setLoading(true);
        await RleStructureService.getRLEStructureFilter(requireStructure).then((data) => {
            SetGeneralStructures(data.data || []);
            setLoading(false);
        })
    }

    const validateForm = (values) => {
        let errors: any = {};
        if(!values.alias_name) errors.alias_name = ___PHRASE("nombreRequerido");
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {
        if(!generalStructureSelected){
            return (
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                    severity:'warning', 
                    message: ___PHRASE("debeSeleccionarUnaEstructura") }
                ))
            );
        }
        const formData = {
            id: values.id,
            idtblrlestructure: generalStructureSelected.id,
            alias_name : values.alias_name,
            indefinite_validity : values.indefinite_validity,
        }
        setLoading(true);
        await RleStructureService.createStructureOptional(formData).then((data) => {
            let apimessage = apiResponseTranslate(data.data.message)
            if(data.data.status == false) {
                return (
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'error',
                        message: `${apimessage || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}` }
                    ))
                )
            } else {
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                    severity:'success',
                    message: `${apimessage || ___PHRASE("creadoConExito")}` }
                ))
            }
            setValueTab('2');
            onListStructures && onListStructures();
            closeModal && closeModal();
        })
        setLoading(false);
    }

    return(
        <>
        {loading && <Loading/>}
        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>

        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={___PHRASE("agregarEstructuraOpcional")}
                className='positionElements'
                onCancel={closeModal}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>{___PHRASE("estructuras")}</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Autocomplete
                                                id="disable-portal"
                                                disablePortal
                                                value={generalStructureSelected}
                                                options={generalStructures || []}
                                                getOptionLabel={(option) => option.name || ""}
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} variant="standard" label={___PHRASE("escribeParaBuscar")} />}
                                                onChange={(e, newValue) => { SetGeneralStructureSelected(newValue) }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("alias")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="alias_name"
                                            variant="standard"
                                            type="text" 
                                            name="alias_name"
                                            fullWidth
                                            value={values.alias_name}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("aliasDeLaEstructua")}
                                            error={errors.alias_name && touched.alias_name ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>{___PHRASE("validez")}:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Select
                                                id="indefinite_validity"
                                                variant="standard"
                                                value={values.indefinite_validity}
                                                onChange={(event) => { 
                                                setFieldValue("indefinite_validity", event.target.value);
                                                }}
                                                error={ errors.indefinite_validity && touched.indefinite_validity ? true : false }
                                                fullWidth
                                            >
                                                <MenuItem value={'1'}>{___PHRASE("indefinido")}</MenuItem>;
                                                <MenuItem value={'0'}>{___PHRASE("noIndefinido")}</MenuItem>;
                                            </Select>
                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={___PHRASE("registrar")}
                                    cancelText={___PHRASE("cancelar")} 
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}