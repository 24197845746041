import { http } from '../http/http';
import { UserDetailsDTO,UserDTO,UserPost,UserRequestPost, UsersDTO} from '../http/dto/UserDTO';
import { UserDetails, Users } from '../models/User';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

const dataUser = readLocalStorage(KEY_USER_DATA);
const rut_empresa = dataUser?.user?.rut_empresa;
const idcompany = dataUser?.user?.id_company;

export const ApplicantsRepository = {

    getApplicants: async(): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/postulant`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    // createApplicants: async (dataPhase) : Promise<any> => {
    //     const res = await http.post<any>(`${API_URL_BASE}/v1/postulant/create`, {
    //         id_job_offer: dataPhase.id_job_offer,
    //         idcompany: idcompany,
    //         pensioner: dataPhase.pensioner,
    //         password: dataPhase.password,
    //         name: dataPhase.name,
    //         first_surname: dataPhase.first_surname,
    //         second_surname: dataPhase.second_surname,
    //         rut: dataPhase.rut,
    //         adresss: dataPhase.adresss,
    //         phone: dataPhase.phone,
    //         email: dataPhase.email,
    //         birth_date: dataPhase.birth_date,
    //         sex: dataPhase.sex,
    //         cv: dataPhase.cv
    //     });
    //     const {data, error, message} = res;
    //     return { data, error, message };
    // },

    createApplicants: async (dataPhase) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/postulant/create`, dataPhase);
        const {data, error, message} = res;
        return { data, error, message };
    },

    editApplicants: async (dataPhase) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/postulant/update`, dataPhase);
        const {data, error, message} = res;
        return { data, error, message };
    },
}   