import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { useEffect, useState } from "react";
import { Alert, Autocomplete, Button, Divider, Grid, Snackbar, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { Loading } from "@/components/common/Loading";
import { Props } from "./ModalContractValidation.type";
import { ContractService } from "@/service/services/Contract.service";
import { KEY_INTEGRATIONS_COMPANY } from "@/toolbox/constants/local-storage";
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { DigitalSignatureService } from "@/service/services/DigitalSignature.service";
import ___PHRASE from "@/service/translate/translateService";

export const ModalContractValidation: React.FC<Props> = (props: Props): JSX.Element => {

    const { open, setOpen, createSignature, idEmployee, setDataPDF, getContract, getContractStatus } = props;

    const integrationsCompany = readLocalStorage(KEY_INTEGRATIONS_COMPANY);
    const digitalSignature = integrationsCompany.find(signature => signature.name == "Firma Digital");

    const [loading, setLoading] = useState<boolean>(false);
    const [imageDoc, setImageDoc] = useState<any>("" || undefined);

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const [data, setData] = useState({
        idtypesignature: null,
        img: ''
    });

    const [typeSignature, setTypeSignature] = useState<any>([]);
    const [typeSignatureSelected, setTypeSignatureSelected] = useState<any>('');
    const [stateSignature, setStateSignature] = useState<boolean>(false);
    const [messageSignature, setMessageSignature] = useState<any>(___PHRASE("suFirmaDigitalEstaSiendoProcesada"));

    useEffect(()=>{
        getTypeSignature();
    },[])

    useEffect(()=>{
        createDigitalSignature();
    },[])

    const getTypeSignature = async() => {
        const res = await ContractService.getTypeSignature();
        const typesSignature = res?.data || [];
        setTypeSignature(typesSignature);

        if(typesSignature.length > 0){
            if(digitalSignature){
                const signature = typesSignature.find(signature => signature.name == "Firma Digital");
                if(signature) { setTypeSignatureSelected(signature); setData({...data, idtypesignature: signature.id}) };
            }else{
                const signature = typesSignature.find(signature => signature.name == 'Firma Manual');
                if(signature) { setTypeSignatureSelected(signature); setData({...data, idtypesignature: signature.id})};
            }
        }
        setLoading(false);
    }

    const setNameFile = (event) => {
        const files = event.target && event.target.files || [];
        const fileSignature = files[0];

        if (fileSignature) {

            const nameAsArray = fileSignature.name.split(".") || [];
            const extension = nameAsArray.slice(-1)
            const nameWithoutExtension = nameAsArray.filter((word, i) => i != nameAsArray.length - 1);

            if (extension == 'jpg' || extension == 'JPG' || extension == 'jpeg' ||
                extension == 'JPEG' || extension == 'png' || extension == 'PNG') {
                    const MAX_FILE_SIZE = 1024 * 1024;
                    if (fileSignature.size <= MAX_FILE_SIZE) {
                        setImageDoc(fileSignature) 
                        return fileSignature
                    } else {
                        return (
                            setSnackBarConfig(prev => ({ ...prev, open: true, 
                                severity:'warning', 
                                message: ___PHRASE("elArchivoEsDemasiadoGrandePorFavorSeleccioneUnArchivoMasPequeño") }
                            ))
                        );
                    }

            } else {
                return (
                    setSnackBarConfig(prev => ({
                        ...prev, open: true,
                        severity: 'warning',
                        message: ___PHRASE("elFormatoDeImagenNoEsCorrecta")
                    }
                    ))
                );
            }
        }
        return "";
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (!imageDoc) {
            setLoading(false)
            return (
                setSnackBarConfig(prev => ({
                    ...prev, open: true,
                    severity: 'warning',
                    message: ___PHRASE("debeSubirLaImagenCorrespondiente")
                }
                ))
            );
        }
        if(!data.idtypesignature){
            setLoading(false)
            return (
                setSnackBarConfig(prev => ({
                    ...prev, open: true,
                    severity: 'warning',
                    message: ___PHRASE("elijaUnTipoDeFirma")
                }
                ))
            );
        }

        const formData = new FormData();
        formData.append("idtypesignature", data.idtypesignature);
        formData.append("img", imageDoc);

        const respSig  = await createSignature(formData);
        if(respSig?.data){
            setSnackBarConfig(prev => ({
                ...prev, open: true,
                severity: 'success',
                message: ___PHRASE("firmaEnviadaConExitoPendienteDeValidacion")
            }
            ))
        }else{
            setSnackBarConfig(prev => ({
                ...prev, open: true,
                severity: 'warning',
                message: ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")
            }
            ))
        }
        setLoading(false);
        setOpen(false)
    }

    const createDigitalSignature = async() => {
        setLoading(true);

        const FormData = {
            idemployee: idEmployee
        }

        if(digitalSignature){
            const res = await DigitalSignatureService.create(FormData);

            if(res?.status == false){
                setLoading(false);
                setSnackBarConfig(prev => ({ ...prev, 
                    open: true, 
                    severity:'warning', 
                    message: `${res?.detail || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}`
                }))
                setMessageSignature(___PHRASE("noSePudoRealizarLaOperacion")+`: ${res?.detail || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}`)
            }else{
                if (res) {
                    idEmployee && getContract && getContract(idEmployee);
                    getContractStatus && getContractStatus();
                }
                setStateSignature(true);
                setLoading(false);
            }
            setLoading(false);
        }
    }


    return (
        <>
            {loading && <Loading />}
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
                <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                    {snackBarConfig.message || ""}
                </Alert>
            </Snackbar>

            <Modal open={open} handleClose={() => setOpen(false)} handle size="sm">
                <ModalHeader
                    text={digitalSignature ? ___PHRASE("suEmpresaCuentaConLaIntegracionDeFirmaDigital") : ___PHRASE("enviarFirma")}
                    className='positionElements'
                    onCancel={() => setOpen(false)}
                >
                </ModalHeader>
                <ModalBody>

                    {
                        !digitalSignature && (
                            <form onSubmit={handleSubmit}>
                                <Grid container >
                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>{___PHRASE("tipoDeFirma")}:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Autocomplete
                                                id="idtypesignature"
                                                value={typeSignatureSelected}
                                                options={typeSignature || []}
                                                getOptionLabel={(option:any) => option.name || ""}
                                                readOnly
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} variant="standard" />}
                                                onChange={(e:any, newValue) => {setData({...data, idtypesignature: newValue.id}) }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("imagen")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Button variant="contained" component="label" fullWidth>
                                                <input
                                                    id="imgDoc"
                                                    type="file"
                                                    name="imgDoc"
                                                    onChange={(event) => {
                                                        setNameFile(event);
                                                    }}
                                                />
                                            </Button>
                                        </Grid>
                                    </Grid>

                                    {
                                        typeSignatureSelected && (
                                            <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Typography variant="subtitle2" className="custom-input" sx={{
                                                    border: `1px #999595 solid`, 
                                                    borderRadius:'8px', 
                                                    padding: '5px', 
                                                    color:'#605f5f', textAlign: 'center',
                                                    width: '100%'}}
                                                >
                                                    {typeSignatureSelected.definition}
                                                </Typography>
                                            </Grid>
                                        )
                                    }

                                </Grid>
                                <Divider />
                                <ModalFooter
                                    buttonType="submit"
                                    confirmText={___PHRASE("enviar")}
                                    onConfirm={handleSubmit}
                                />

                            </form>
                        )   
                    }
                    {
                        digitalSignature && (
                            <Grid container >
                                {
                                    !stateSignature ? (
                                        <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                            <Typography variant="body1"> {messageSignature} </Typography>
                                        </Grid>
                                    ) : (
                                        <Grid container item xs={12} alignItems="start" justifyContent="start" marginBottom='25px'>
                                            <Typography variant="body1">{___PHRASE("suFirmaDigitalSeRealizoConExito")}</Typography>
                                            <div style={{marginTop: '20px'}}>{typeSignatureSelected?.definition || ''}</div>
                                        </Grid>
                                    )
                                }
                            </Grid>
                        )
                    }


                </ModalBody>
            </Modal>
        </>
    );
}