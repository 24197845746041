import React from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { IconButton, MenuList, Popover, MenuItem } from '@mui/material'
import { MouseEvent, ReactNode, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ___PHRASE from '@/service/translate/translateService'

type OptionPopoverProps = {
    notification: any,
    isRead?: boolean,
    changeToSeenStatus?: (id) => void,
    removeReceivedNotification?: (id) => void
  }

export const OptionPopover = (props: OptionPopoverProps) => {
    const { notification, isRead, changeToSeenStatus, removeReceivedNotification } = props

    const history = useHistory();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleOpenOption = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseOption = () => {
    setAnchorEl(null)
  }

  const redirect = (notificacion) => {
    console.log(notificacion)
    changeToSeenStatus(notificacion.id)
    history.push(notificacion.url, notificacion.variables)
  }

  const optionOpened = Boolean(anchorEl)
  const idPopoverOption = optionOpened ? `simple-popover-${notification.id}` : undefined

  return (
    <>
      <IconButton onClick={handleOpenOption}>
        <MoreVertIcon style={{ cursor: 'pointer' }} />
      </IconButton>
      <Popover
        id={idPopoverOption}
        open={optionOpened}
        anchorEl={anchorEl}
        onClose={handleCloseOption}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuList>
        <MenuItem onClick={() => redirect(notification)}>{___PHRASE('abrir')}</MenuItem>
          <MenuItem disabled={isRead} onClick={() => changeToSeenStatus(notification.id)}>{___PHRASE('marcarcomoleido')}</MenuItem>
          <MenuItem onClick={() => removeReceivedNotification(notification.id)}>{___PHRASE('eliminar')}</MenuItem>

        </MenuList>
      </Popover>
    </>
  )
}