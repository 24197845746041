import { Loading } from "@/components/common/Loading";
import { MovementsService } from "@/service/services/Movements.service";
import { Alert, Snackbar, Typography } from "@mui/material";
import { useState } from "react";
import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalFooter } from "../ModalFooter";
import { ModalHeader } from "../ModalHeader";
import { Props } from "./ModalMovementDelete.type";
import ___PHRASE from "@/service/translate/translateService";

export const ModalMovementDelete: React.FC<Props> = (props:any): JSX.Element => {
    const { open, closeModal, movement, onListMovements, workerSelected, clearState } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
    });

    const onDeleted= async()=>{
        setLoading(true);
        await MovementsService.deleteMovement(movement.id).then((data) => {
            setSnackBarConfig(prev => ({ ...prev, open: true, 
              severity:'success', 
              message: `${___PHRASE("movimientoeliminado")}` }
            ));
            closeModal && closeModal();
            onListMovements && onListMovements(workerSelected.id);
        })
        setLoading(false);
    }
  
  return (
    <>
    {loading && <Loading/>}
    <Snackbar
        open={snackBarConfig.open} 
        autoHideDuration={snackBarConfig.autoHideDuration} 
        onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
        <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
            {snackBarConfig.message || ""}
        </Alert>
    </Snackbar>

    <Modal
      open={open}
      handleClose={closeModal}
      size="xs"
    >
      <ModalHeader
        text={___PHRASE("eliminar")+' '+___PHRASE("movimiento")}
        className='positionElements'
        onCancel={closeModal}
        clearState={clearState}
      >
      </ModalHeader>

      <ModalBody>
        <Typography variant="subtitle1" className="textColor"> 
          {___PHRASE("estaSeguroDeEliminarEsteMovimiento")}
        </Typography>
      </ModalBody>

      <ModalFooter 
        confirmText={___PHRASE("eliminar")}
        cancelText={___PHRASE("cancelar")}
        onCancel={closeModal}
        className="footerRight"
        onConfirm={onDeleted}
        clearState={clearState}
      />
    </Modal>
    </>
  )
}