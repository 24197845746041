export const KEY_LOCAL_STORAGE  = process.env.REACT_APP_KEY_COOKIE || '_';
export const KEY_USER_DATA      = 'userData';
export const KEY_INTEGRATIONS_COMPANY = 'integrationCompany';
export const KEY_USER_DATA_OPTIONS      = 'userDataOptions';
export const KEY_USER_PERMISSION = 'userPermission';
export const KEY_ARRAY_ROLES = 'arrayRoles';
export const KEY_ARRAY_MY_MENU  = 'arrayMyMenu';
export const KEY_OBJECT_MY_MENU = 'objectMyMenu';
export const KEY_EMPRESA        = 'userEmpresa';
export const KEY_USER_SOFTNET     = 'userDataSoftnet';
export const KEY_TOKEN_SOFTNET  =  'token';
export const KEY_TOOGLE_MENU    = 'userToogleMenu';
export const KEY_SOFTNET        = 'tokenSoftnet';
export const KEY_BYPASS         = 'tokenBypass'
export const KEY_NAVEGACION_SUB_MODULO   = 'userSubNavigators'
export const KEY_TOKEN_KYTE = 'tokenkyte';
export const DATA_CAMPAING_LEADS = 'dataCampaingLeads';
export const KEY_DOCUMENTS = 'dataDocuments';
export const KEY_RUTA = 'keyRuta';
export const KEY_SUB_RUTA = 'keySubRuta';
export const KEY_TRANSLATE = 'translate';
export const KEY_UPDATE_TRANSLATE = 'updateTranslate';