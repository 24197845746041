import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Props } from "./ModalViews.type";
import { Formik } from 'formik';
import { useEffect, useState } from "react";
import { Autocomplete, Divider, Grid, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { Loading } from "@/components/common/Loading";
import { ViewsService } from "@/service/services/Views.service";
import { userService } from "@/service/services/User.service";
import { ModuleService } from "@/service/services/Module.service";
import ___PHRASE from "@/service/translate/translateService";

export const ModalViews: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, view, onListViews, clearState } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [modules, setModules] = useState<any>([])
    const [moduleSelected, setModuleSelected] = useState<any>(null)
    const [roles, setRoles]     = useState<any>([])
    const [roleSelected, setRoleSelected] = useState<any>(null)
    const [data, setData] = useState({
        code: view ? view.code : '',
        name: view ? view.name : '',
        route: view ? view.route : '',
        icon: view ? view.icon : '',
    });


    const validateForm = (values) => {
        let errors: any = {};
        if(!values.code) errors.code = ___PHRASE("codigoRequerido");
        if(!values.name) errors.name = ___PHRASE("nombreRequerido");
        if(!values.route) errors.route = ___PHRASE("rutaRequerido");
        if(!values.icon) errors.icon = ___PHRASE("iconoRequerido");
        if(!roleSelected) errors.roleSelected = ___PHRASE("rolRequerido");
        if(!moduleSelected) errors.moduleSelected = ___PHRASE("moduloRequerido");
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        const formData = {
            code: values.code,
            name: values.name,
            route: values.route,
            idrole: roleSelected.id,
            idmodule: moduleSelected.id,
            required: 1,
            icon: values.icon
        }
        setLoading(true);
        if(view && actionSelected=='edit'){
            await ViewsService.editView(view.id,formData).then((data) => {
                onListViews && onListViews();
                closeModal && closeModal();
            })
        }else{
            await ViewsService.createView(formData).then((data) => {
                onListViews && onListViews();
                closeModal && closeModal();
            })
        }
        setLoading(false);
    }

    const getDataInitial = async() => {
        console.log(view)
        const respRoles =  await userService.getRoles();
        setRoles(respRoles.data || [])

        const respModules = await ModuleService.getAllModules();
        setModules(respModules.data || [])

        if(actionSelected == 'edit'){
            setRoleSelected(respRoles.data.find((role) => role.id == view.idrole)) 
            setModuleSelected(respModules.data.find((module) => module.id == view.idmodule))
        }

    } 

    useEffect(()=>{
        getDataInitial();
    },[])

    return(
        <>
        {loading && <Loading/>}

        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={(view && actionSelected=='edit') ? ___PHRASE("editarVista") : ___PHRASE("agregarVista")}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={5}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("codigo")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                        <TextField
                                            id="code"
                                            variant="standard"
                                            type="text" 
                                            name="code"
                                            fullWidth
                                            value={values.code}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("codigo")}
                                            error={errors.code && touched.code ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={5}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("nombre")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                        <TextField
                                            id="name"
                                            variant="standard"
                                            type="text" 
                                            name="name"
                                            fullWidth
                                            value={values.name}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("nombre")}
                                            error={errors.name && touched.name ? true : false}
                                        />
                                        </Grid>
                                    </Grid>
                                     <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={5}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("rol")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                        <Autocomplete
                                                id="disable-portal"
                                                disablePortal
                                                value={roleSelected}
                                                options={roles || []}
                                                getOptionLabel={(option) => option.name || ""}
                                                fullWidth
                                                autoHighlight
                                                renderInput={(params) => <TextField {...params} variant="standard" label={___PHRASE("escribeParaBuscar")} />}
                                                onChange={(e, newValue) => { 
                                                    setRoleSelected(newValue); 
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={5}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("modulo")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                        <Autocomplete
                                                id="disable-portal"
                                                disablePortal
                                                value={moduleSelected}
                                                options={modules || []}
                                                getOptionLabel={(option) => option.name || ""}
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} variant="standard" label={___PHRASE("escribeParaBuscar")} />}
                                                onChange={(e, newValue) => { 
                                                    setModuleSelected(newValue); 
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={5}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("ruta")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                        <TextField
                                            id="route"
                                            variant="standard"
                                            type="text" 
                                            name="route"
                                            fullWidth
                                            value={values.route}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("ruta")}
                                            error={errors.route && touched.route ? true : false}
                                        />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={5}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("icono")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                        <TextField
                                            id="icon"
                                            variant="standard"
                                            type="text" 
                                            name="icon"
                                            fullWidth
                                            value={values.icon}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("icono")}
                                            error={errors.icon && touched.icon ? true : false}
                                        />
                                        </Grid>
                                    </Grid>
                                   
                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(view && actionSelected=='edit') ? ___PHRASE("actualizar") : ___PHRASE("registrar")}
                                    cancelText={___PHRASE("cancelar")} 
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}