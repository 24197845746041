import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik } from 'formik';
import { useEffect, useState } from "react";
import { Alert, Divider, Grid, Snackbar, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import { Loading } from "@/components/common/Loading";
import { CountryService } from "@/service/services/Country.service";
import { Props } from "./ModalCountryDistrict.type";
import ___PHRASE from "@/service/translate/translateService";
import { apiResponseTranslate } from "@/toolbox/helpers/responseApi.helper";
import '../../../assets/styles/modals.scss';

export const ModalCountryDistrict: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, district, onListDistrict, clearState, provinceSelected } = props;

    const [loading, setLoading] = useState<boolean>(false);

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const [data, setData] = useState({
        id: district ? district.id : '',
        code: district ? district.code : '',
        name: district ? district.name : '',
        idprovince: district ? district.idprovince : '',
    });

    useEffect(() => {
        if(!provinceSelected){
            setTimeout(() => {
                closeModal && closeModal();
            }, 1000)
            return (
                setSnackBarConfig(prev => ({
                    ...prev, open: true,
                    severity: 'warning',
                    message: ___PHRASE("debeSeleccionarUnaProvincia")
                }))
            )
        }
    }, []);

    const validateForm = (values) => {
        let errors: any = {};
        if(!values.code) errors.code = ___PHRASE("codigoRequerido");
        if(!values.name) errors.name = ___PHRASE("nombreRequerido");
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        const formData = {
            id: values.id,
            code: values.code,
            name: values.name,
            idprovince : provinceSelected.id,
        }
        setLoading(true);
        if(district && actionSelected=='edit'){
            await CountryService.editDistrict(formData, district.id).then((data) => {
                onListDistrict && onListDistrict(provinceSelected.id);
                closeModal && closeModal();
            })
        }else{
            await CountryService.createDistrict(formData).then((data) => {
                let apimessagge = apiResponseTranslate(data?.data?.message || '');
                if(data.data.status == false){
                    setLoading(false);
                    return (
                        setSnackBarConfig(prev => ({
                            ...prev, open: true,
                            severity: 'warning',
                            message: `${apimessagge || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}`
                        }))
                    );
                }
                onListDistrict && onListDistrict(provinceSelected.id);
                closeModal && closeModal();
            })
        }
        setLoading(false);
    }

    return(
        <>
        {loading && <Loading/>}

        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>

        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={((district && actionSelected=='edit') ? ___PHRASE("editar") : ___PHRASE("agregar"))+' '+___PHRASE("comuna")}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("codigo")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="code"
                                            variant="standard"
                                            type="text" 
                                            name="code"
                                            fullWidth
                                            value={values.code}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("codigo")}
                                            error={errors.code && touched.code ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("nombre")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="name"
                                            variant="standard"
                                            type="text" 
                                            name="name"
                                            fullWidth
                                            value={values.name}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("nombre")}
                                            error={errors.name && touched.name ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(district && actionSelected=='edit') ? ___PHRASE("actualizar") : ___PHRASE("registrar")}
                                    cancelText={___PHRASE("cancelar")} 
                                    onCancel={closeModal}
                                    clearState={clearState}
                                    onConfirm={handleSubmit}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}