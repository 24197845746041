import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Props } from './Views.type'
import { Loading } from '@/components/common/Loading';
import { Alert, Backdrop, CircularProgress, Container, Divider, Grid, IconButton, Menu, MenuItem, Snackbar } from "@mui/material";
import { SpinnerGrow } from '@/components/common/Spinner';
import { TableDataV2 } from '@/components/common/Tablev2';
import { ViewsService } from '@/service/services/Views.service';
import { ModalViewsDelete } from '@/components/Modal/ModalViewsDelete';
import { ModalViews } from '@/components/Modal/ModalViews';
import ___PHRASE from '@/service/translate/translateService';
import { apiResponseTranslate } from '@/toolbox/helpers/responseApi.helper';

export const ViewsView: React.FC<Props> = (props: any): JSX.Element => {
   
   const [openView, setOpenView] = useState(false);
   const [Views, setViews] = useState([])
   const [showViewsModal, setShowViewsModal] = useState<boolean>(false);
   const [showViewsDeleteModal, setShowViewsDeleteModal] = useState<boolean>(false);
   const [actionSelected, setActionSelected] = useState<String>('');
   const [viewsSelected, setViewsSelected] = useState<String>('');
   const [loading, setLoading] = useState<boolean>(false);
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
   })
   const getViews = async () => {
      setLoading(true);
      const response = await ViewsService.getAllViews();
      let apimessagge = apiResponseTranslate(response?.data || '');
      console.log(response)
      if (response.data) {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: apimessagge,
         }));
         setViews(response.data)
      } else {
         console.log('error')
      }
      setLoading(false);
   }

   function RecuperarData(value) {
      setViewsSelected(value)
   }

   useEffect(() => {
      getViews()
   }, [])

   return (
      <Protected>
         {loading && <Loading/>}
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openView}
         >
            <Grid container
               direction="row"
               justifyContent="center"
               alignItems="center">
               <SpinnerGrow />
               <h1 style={{ marginLeft: '10px' }}><strong>{___PHRASE("cargando")}</strong></h1>
            </Grid>
         </Backdrop>
         <Container>
            <TableDataV2
               data={Views}
               header={[
                  { name: 'code', label: ___PHRASE("codigo"), filter: false, Chip: true },
                  { name: 'name', label: ___PHRASE("nombre"), filter: false, Chip: false },
                  { name: 'route', label: 'Route', filter: false, Chip: true },
                  { name: 'module', label: 'Módulo', filter: false, Chip: false },
                  { name: 'icon', label: 'Icono', filter: false, Chip: true },
                  { name: 'role', label: 'Rol', filter: false, Chip: false },
               ]}
               status_action
               title={'Vistas del Software'}
               RecuperarData={RecuperarData}
               setModalSave={setShowViewsModal}
               setModalDelete={setShowViewsDeleteModal}
               actionSelect={setActionSelected}
            />
         </Container>
         {
            showViewsModal && (
               <ModalViews
                  open={showViewsModal}
                  closeModal={() => { setShowViewsModal(false) }}
                  actionSelected={actionSelected}
                  onListViews={getViews}
                  view={viewsSelected}
                  clearState={() => { setViewsSelected('') }}
               />
            )
         }
         {
            actionSelected === 'delete' && (
               <ModalViewsDelete
                  open={showViewsDeleteModal}
                  closeModal={()=>{setShowViewsDeleteModal(false)}}
                  actionSelected={actionSelected}
                  onListViews={getViews}
                  view={viewsSelected}
                  clearState={()=>{setViewsSelected('')}}
               />
            )
         }
      </Protected>
   )
};