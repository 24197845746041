import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

const dataUser = readLocalStorage(KEY_USER_DATA);
const rut_empresa = dataUser?.user?.rut_empresa;

export const AdvanceRepository = {

    getAdvancesByEmployee: async(idEmployee: number): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/advance/${idEmployee}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    createAdvance: async (dataAdvance, idEmployee: number) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/advance/${idEmployee}`, {
            idperiod: dataAdvance.idperiod,
            value: dataAdvance.value,
            language: dataAdvance.language,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    editAdvance: async (dataAdvance, idAdvance: number) : Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/advance/${idAdvance}`, {
            idperiod: dataAdvance.idperiod,
            value: dataAdvance.value,
            language: dataAdvance.language,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    acceptAdvance: async (dataAdvance, idAdvance: number) : Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/advance/acceptAdvance/${idAdvance}`, {
            request_status: dataAdvance.request_status,
            validity: dataAdvance.validity,
            total_value: dataAdvance.total_value,
            idemployee: dataAdvance.idemployee,
            language: dataAdvance.language,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    deleteAdvance: async(idAdvance:number): Promise<any> => {
        const res= await http.delete<any>(`${API_URL_BASE}/v1/advance/${idAdvance}`)
        return res;
    },
}   