import { notifyRepository } from "../repositories/Notify.repository";

export const notificationService = {
    getNotify,
    getNotifyNotView,
    getNotifyView,
    editVisto,
    getNotifySent,
    editVistoMassive
}

async function getNotify(perPage: number, page: number){
    const res = await notifyRepository.getNotify(perPage, page);
    return res;
}

async function getNotifyNotView(perPage: number, page: number){
    const res = await notifyRepository.getNotifyNotView(perPage,page);
    return res;
}

async function getNotifyView(perPage: number, page: number){
    const res = await notifyRepository.getNotifyView(perPage,page);
    return res;
}

async function getNotifySent(perPage: number, page: number){
    const res = await notifyRepository.getNotifySent(perPage,page);
    return res;
}

async function editVisto(id: number){
    const res = await notifyRepository.editVisto(id);
    return res;
}

async function editVistoMassive(){
    const res = await notifyRepository.editVistoMassive();
    return res;
}

// async function deleteNotificationsType(idRequestType:number){
//     const res = await notifyRepository.deleteNotificationsType(idRequestType);
//     return res;
//  }