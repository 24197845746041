import React, { useState, useEffect } from 'react';
import { Protected } from "@/components/layout/Protected";
import { Container, Breadcrumbs, Grid, Typography, Button } from '@mui/material'
import { Props } from "./SettlementActive.type"
import './SettlementActive_styles.css'
import { TableDataV2 } from '@components/common/Tablev2';
import { WorkerService } from '@/service/services/Worker.service';
import { Loading } from '@/components/common/Loading';
import { emphasize, styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import GroupsIcon from '@mui/icons-material/Groups';
import { LiquidationWorkers } from './LiquidationWorkers/LiquidationWorkers';
import { RleStructureService } from '@/service/services/RleStructure.service';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { useLocation } from 'react-router-dom';
import ___PHRASE from '@/service/translate/translateService';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { LiquidationWorkersOnBoarding } from './LiquidationWorkersOnBoarding';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
   const backgroundColor =
      theme.palette.mode === 'light'
         ? theme.palette.grey[100]
         : theme.palette.grey[800];
   return {
      backgroundColor,
      height: theme.spacing(3),
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightRegular,
      '&:hover, &:focus': {
         backgroundColor: emphasize(backgroundColor, 0.06),
      },
      '&:active': {
         boxShadow: theme.shadows[1],
         backgroundColor: emphasize(backgroundColor, 0.12),
      },
   };
});
   
interface Data {
   codigo: string;
   nombreDefault: string;
   nombre: string;
}

function createDataCharges(
   codigo: string,
   nombreDefault: string,
   nombre: string,
): Data {
   return { codigo, nombreDefault, nombre };
}

const rowsDataSettkement = [
   createDataCharges('H|1', 'Sueldo Mes', 'Sueldo Base'),
   createDataCharges('H|2', 'Sobresueldo1 (Hrs. Extras)', 'Sobresueldo1 (Hrs. Extras)'),
   createDataCharges('H|3', 'Sobresueldo2 (Hrs. Extras)', 'Sobresueldo2 (Hrs. Extras)'),
   createDataCharges('H|4', 'Sobresueldo3 (Hrs. Extras)', 'Sobresueldo3 (Hrs. Extras)'),
   createDataCharges('H|5', 'Comision', 'Comision'),
   createDataCharges('H|6', 'Bono por Meta', 'Bono por Meta'),
]

const labels = [{ label: ___PHRASE("estructurasObligatorias"), id: '1' }, { label: ___PHRASE("estructurasOpcionales"), id: '2' }]


export const SettlementActive: React.FC<Props> = (props: Props): JSX.Element => {

   let location = useLocation();
   const dataprops: any = location?.state;
   const employee: any = dataprops?.employee;
   const [loading, setLoading] = useState(false);
   const [workers, setWorkers] = useState<any>([])
   const [openModal, setOpenModal] = useState<boolean>(false);
   const [workerSelect, setWorkerSelect] = useState<any>({});
   const [actionSelected, setActionSelected] = useState<any>('');
   const [view, setView] = useState<String>('workers');
   const [showLiquidation, setShowLiquidation] = useState<boolean>(false);
   const [typeView, setTypeView] = useState<string>('settlement');

  const dataUser = readLocalStorage(KEY_USER_DATA);
  const onBoarding = dataUser?.user?.on_boarding;
   const idCompany = dataUser?.user?.id_company;
  
  const cabecera = [
   { name: 'n_document', label: ___PHRASE("rutTrabajador") },
   { name: 'surname', label: ___PHRASE("apellidoPaterno") },
   { name: 'second_surname', label: ___PHRASE("apellidoMaterno") },
   { name: 'name', label: ___PHRASE("nombres") }
  ];

   useEffect(() => {
      getWorkers()
   }, [])

   useEffect(()=> {
      if(dataprops){
         setWorkerSelect(employee);
         setShowLiquidation(true);
         setView('liquidation');
      }
   },[dataprops]);

   const getWorkers = async () => {
      setLoading(true);
      const resp:any = await WorkerService.getWorkers();
      setWorkers(resp.data);
      setLoading(false);
   }

   const recuperarData = (data) => {
      // setOpenModal(true);
      setWorkerSelect(data);
      setShowLiquidation(true);
      setView('liquidation');
   }

   const searchData = async (value) => {
      // setLoading(true);
      const resp:any = await WorkerService.getWorkers(value);
      setWorkers(resp.data);
      // setLoading(false);
   }


   return (
      <Protected>
         {loading && <Loading />}
         { typeView == 'settlement' && <Container>

            {
               onBoarding == "1" &&
               <Grid item xs={12} md={12} display={'flex'} sx={{ justifyContent: 'center', alignItems: 'center', bgcolor: '#87CEFA', borderRadius: 5, padding: 2 }}>
                  <Typography sx={{ color: '#fff', fontSize: 18 }}>La empresa se encuentra habilitada para 'Onboarding'</Typography>
                  <Button sx={{ color: '#fff', ml: 2 }} color="primary" variant="contained" onClick={() => setTypeView('onBoarding')}>Ver</Button>
               </Grid>
            }

            <Breadcrumbs aria-label="breadcrumb" sx={{my:3, ml:2}}>
               <StyledBreadcrumb
                  label={___PHRASE("listaDeEmpleados")}
                  onClick={() => {
                     setView('workers');
                     setWorkerSelect('');
                     setShowLiquidation(false);
                  }}
                  icon={<GroupsIcon fontSize="small" />}
               />
               {
                  showLiquidation && (
                     <StyledBreadcrumb
                        label={___PHRASE("liquidaciones")}
                        onClick={() => { setView('liquidation') }}
                        icon={<GroupsIcon fontSize="small" />}
                     />
                  )
               }
            </Breadcrumbs>

            {
               view == 'workers' && (
                  <>
                 
                  <TableDataV2
                     data={workers}
                     header={cabecera}
                     actionSelect={setActionSelected}
                     status_action
                     btn_disabled
                     btn_helper
                     iconBtnHelper={<ListAltIcon fontSize="small" ></ListAltIcon>}
                     toolTipBtnHelper={___PHRASE("verEstructuras")}
                     btn_edit_disable={true}
                     btn_delete_disable={true}
                     RecuperarData={recuperarData}
                     textSearch={___PHRASE("buscarTrabajador")}
                     searchData={searchData}
                  />
                  </>
               )
            }

            {
               view == 'liquidation' && (
                     <LiquidationWorkers
                        open={openModal}
                        setOpen={setOpenModal}
                        data={workerSelect}
                        setData={setWorkerSelect}
                     />
               )
            }

         </Container>
         }

         {
            typeView == 'onBoarding' && 
            <LiquidationWorkersOnBoarding
            setView={setTypeView}
            setLoading={setLoading}
            idCompany={idCompany}
            />
         }
      </Protected>
   );
};

