import { Props } from './Assistances.type';
import React, { useEffect, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Loading } from "@components/common/Loading";
import { Alert, Breadcrumbs, Button, Container, Grid, Snackbar, TextField, Typography } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { WorkerService } from '@/service/services/Worker.service';
import { emphasize, styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import GroupsIcon from '@mui/icons-material/Groups';
import moment from 'moment';
import { AssistsWorkerService } from '@/service/services/AssistanceWorker.service';
import { ModalAssistance } from '@/components/Modal/ModalAssistance';
import { IntegrationDiviceService } from '@/service/services/IntegrationDiviceService';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { ModalAssistanceDelete } from '@/components/Modal/ModalAssistanceDelete/ModalAssistanceDelete';
import { useLocation } from 'react-router-dom';
import ___PHRASE from '@/service/translate/translateService';
import { apiResponseTranslate } from '@/toolbox/helpers/responseApi.helper';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

export const AssistancesView: React.FC<Props> = (props:any): JSX.Element => {

    const [loading, setLoading] = useState<boolean>(false);

    const [workers, setWorkers] = useState<any>([]);
    const [workerSelected, setWorkerSelected] = useState<any>('');
    const [assistances, setAssistances] = useState([]);
    const [showAssistanceModal, setShowAssistanceModal] = useState<boolean>(false);
    const [showAssistanceDeleteModal, setShowAssistanceDeleteModal] = useState<boolean>(false);
    const [assistanceSelected, setAssistanceSelected] = useState<any>('');
    const [actionSelected, setActionSelected] = useState<any>('');

    const [view, setView] = useState<String>('workers');
    const [showAbsences, setShowAbsences] = useState<boolean>(false);

    const [startDateSelected, setStartDateSelected] = useState<any>('')
    const [endDateSelected, setEndDateSelected] = useState<any>('')
    const [isIntegration, setIsIntegration] = useState<boolean>(false)

    const cabecera = [
        { name: 'n_document', label: ___PHRASE("rutTrabajador") },
        { name: 'surname', label: ___PHRASE("apellidoPaterno") },
        { name: 'second_surname', label: ___PHRASE("apellidoMaterno") },
        { name: 'name', label: ___PHRASE("nombres") }
    ];

    const cabeceraAsistence = [
        { name: 'date', label: ___PHRASE("fecha") },
        { name: 'start_time', label: ___PHRASE("horaIngreso") },
        { name: 'start_refreshment', label: ___PHRASE("inicioRefrigerio") },
        { name: 'end_refreshment', label: ___PHRASE("finRefrigerio") },
        { name: 'end_time', label: ___PHRASE("horaSalida") },
        { name: 'extra_hours', label: ___PHRASE("horasExtrasMin") },
        { name: 'delay', label: ___PHRASE("tardanzasMin") },
        { name: 'observations', label: ___PHRASE("observacion") },
    ];

    const cabeceraEmployeeZkteco  = [
        { name: 'n_document', label: ___PHRASE("rutTrabajador") },
        { name: 'surname', label: ___PHRASE("apellidoPaterno") },
        { name: 'second_surname', label: ___PHRASE("apellidoMaterno") },
        { name: 'name', label: ___PHRASE("nombres") },
        {name: 'status_sync', label: ___PHRASE("estadoDeSincronizacion")}
    ];
    const user = readLocalStorage(KEY_USER_DATA);
    const idCompany = user.user.id_company

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    //Redireccion: props enviados de la vista solicitud (novedades del mes)
    let location: any = useLocation();
    let dataprops: any = location?.state;

    useEffect(()=> {
        if(dataprops){
            setWorkerSelected(dataprops);
            setShowAbsences(true);
            setView('assistance');
        }
    },[dataprops]);

    useEffect(()=> {
        getDivicesByCompany();
    },[]);

    useEffect(()=> {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonthJS = currentDate.getMonth() + 1; //getMonth() su indice empieza desde 0
        const currentMonth = currentMonthJS <= 9 ? `0${currentMonthJS}` : `${currentMonthJS}`;

        const previousDate = currentYear+'-'+currentMonth+'-01';
        const nexDate = moment().format("YYYY-MM-DD");

        setStartDateSelected(previousDate)
        setEndDateSelected(nexDate)

        if(workerSelected ){
            getAssitsCalendar(workerSelected.id, previousDate, nexDate);
        }
    },[workerSelected]);

    const getDivicesByCompany = async() => {
        setLoading(true);
        const divices = await IntegrationDiviceService.getDivicesByCompany(idCompany);
    
        if(divices?.data && (divices?.data).length > 0) { 
            getEmployeeRemyZkteco();
            setIsIntegration(true);
            return true;
        }
        
        getWorkers();
        return false;
    }

    const getWorkers = async() => {
        setLoading(true);
        const resp:any = await WorkerService.getWorkers();

        const oldData = resp.data || [];
        let newData = [];
        oldData.length > 0 && oldData.map((item) => {
            let obj = {
                ...item,
                name_complete: item.name+' '+item.surname+' '+item.second_surname,
            }
            newData.push(obj);
        });
        setWorkers(newData || []);
        setLoading(false);
    }

    const getEmployeeRemyZkteco = async() => {
        setLoading(true);
        const resp = await IntegrationDiviceService.getEmployeeRemyZkteco();
        const oldData = resp.data || [];
        let newData = [];
        oldData.length > 0 && oldData.map((item) => {
            let obj = {
                ...item,
                name_complete: item.name+' '+item.surname+' '+item.second_surname,
            }
            newData.push(obj);
        });
        setWorkers(newData || []);
        setLoading(false);
    }

    const getAssitsCalendar = async(idEmployee, start_date, end_date) => {

        let currentDate = moment().format("YYYY/MM/DD");
        if(start_date) start_date = moment(start_date).format("YYYY/MM/DD");
        if(end_date) end_date = moment(end_date).format("YYYY/MM/DD");

        if(!start_date || !end_date) return (
            setSnackBarConfig(prev => ({ ...prev, open: true, 
                severity:'warning', 
                message: `${___PHRASE("selecioneunafechadeinicioyfinparabuscarlasasistencias")}` }
            ))
        );

        if(start_date && end_date && start_date > end_date) return (
            setSnackBarConfig(prev => ({ ...prev, open: true, 
                severity:'warning', 
                message: `${___PHRASE("aseguresequelafechainicialseamenorquelafinal")}` }
            ))
        );

        if(end_date && end_date > currentDate) return (
            setSnackBarConfig(prev => ({ ...prev, open: true, 
                severity:'warning', 
                message: `${___PHRASE("lafechafinnopuedesermayoralafechactual")}` }
            ))
        );

        if(isIntegration){
            setLoading(true);
            await IntegrationDiviceService.getAssitenceRemyZktecoByEmployee(idEmployee, start_date, end_date).then((data) => {
                setAssistances(data.data || []);
                setLoading(false);
            })

        }else{
            setLoading(true);
            await AssistsWorkerService.getAssitsCalendar(idEmployee, start_date, end_date).then((data) => {
                setAssistances(data.data || []);
                setLoading(false);
            })
        } 
    }

    function RecuperarData(value){
        !workerSelected && setWorkerSelected(value);
        workerSelected && setAssistanceSelected(value);
        setShowAbsences(true);
        setView('assistance');
    }

    const SyncEmployee = async() => {
        setLoading(true);
        await IntegrationDiviceService.syncEmployee().then((data) => {
            setLoading(false);
        })
    }

    const searchData = async (value) => {
        const resp:any = await WorkerService.getWorkers(value);

        const oldData = resp.data || [];
        let newData = [];
        oldData.length > 0 && oldData.map((item) => {
            let obj = {
                ...item,
                name_complete: item.name+' '+item.surname+' '+item.second_surname,
            }
            newData.push(obj);
        });
        setWorkers(newData || []);
    }

    return (
        <Protected>
            {loading && <Loading/>}
            <Snackbar
                open={snackBarConfig.open} 
                autoHideDuration={snackBarConfig.autoHideDuration} 
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
                <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                    {snackBarConfig.message || ""}
                </Alert>
            </Snackbar>
            <Container>
            <Breadcrumbs aria-label="breadcrumb" sx={{mt:3, ml:2}}>
                <StyledBreadcrumb
                    label={___PHRASE("listaDeEmpleados")}
                    onClick={() => {
                        setView('workers');
                        setWorkerSelected('');
                        setShowAbsences(false);
                    }}
                    icon={<GroupsIcon fontSize="small" />}
                    disabled={dataprops ? true : false}
                />
                {
                    showAbsences && (
                        <StyledBreadcrumb
                            label={___PHRASE("asistencias")}
                            onClick={() => {setView('assistance')}}
                            icon={<GroupsIcon fontSize="small" />}
                        />
                    )
                }
            </Breadcrumbs>
            {
                view == 'workers' && (
                    <>
                   {/* { isIntegration && <Button onClick={()=>{SyncEmployee()}}>Sincronizar con Dispositivo</Button>} */}
                    <TableDataV2
                        title={___PHRASE("asistenciasPorEmpleado")}
                        data={workers}
                        header={isIntegration ? cabeceraEmployeeZkteco  :cabecera}
                        actionSelect={setActionSelected}
                        setModalSave={setShowAssistanceModal}
                        status_action
                        disabled_title={false}
                        btn_edit_disable={true}
                        btn_delete_disable={true}
                        btn_helper
                        iconBtnHelper={<EventAvailableIcon fontSize="small" ></EventAvailableIcon>}
                        toolTipBtnHelper={___PHRASE("verAsistencias")}
                        currentView={view}
                        RecuperarData={RecuperarData}
                        btn_isIntegrationZkteco={isIntegration}
                        SyncEmployee={SyncEmployee}
                        textSearch={___PHRASE("buscarTrabajador")}
                        searchData={searchData}
                        />
                    </>   
                )
            }
            {
                view == 'assistance' && (

                    <>
                    <Typography variant='h6' component='h1' gutterBottom className="tittle" sx={{fontWeight:600, marginLeft:'20px', marginTop:'25px'}}>
                        {(___PHRASE("asistenciaDe")+` ${workerSelected?.name_complete ? workerSelected?.name_complete : workerSelected?.name}`).toUpperCase()}
                    </Typography>

                    <Grid container spacing={3} sx={{marginTop: '10px', marginBottom: '20px', alignItems: 'center', marginLeft: '5px'}}>
                    
                        <Grid item spacing={3} xs={12} md={12} display='flex' sx={{alignItems: 'center'}}>
                            <TextField
                                id="start_date"
                                variant="standard"
                                name="start_date"
                                sx={{ width: '200px', marginRight: '30px'}}
                                label={___PHRASE("desde")}
                                type="date"
                                value={startDateSelected}
                                onChange={(e)=>{setStartDateSelected(e.target.value)}}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <TextField
                                id="end_date"
                                variant="standard"
                                sx={{ width: '200px', marginRight: '30px'}}
                                name="end_date"
                                label={___PHRASE("hasta")}
                                type="date"
                                value={endDateSelected}
                                onChange={(e)=>{setEndDateSelected(e.target.value)}}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />

                            <Button
                                sx={{marginTop:'20px'}}
                                onClick={()=>{getAssitsCalendar(workerSelected.id, startDateSelected, endDateSelected)}}
                            >
                                {___PHRASE("filtrar")}
                            </Button>
                        </Grid>
                        
                    </Grid>
                     <TableDataV2
                        title={(`Asistencias de ${workerSelected?.name_complete ? workerSelected?.name_complete : workerSelected?.name}`).toUpperCase()}
                        disabled_title
                        data={assistances}
                        header={cabeceraAsistence}
                        actionSelect={setActionSelected}
                        setModalSave={setShowAssistanceModal}
                        setModalDelete={setShowAssistanceDeleteModal}
                        status_action
                        btn_disabled={false}
                        btn_edit_disable={false}
                        btn_delete_disable={false}
                        currentView={view}
                        RecuperarData={RecuperarData}
                        note_severity={dataprops && 'warning'}
                        note={dataprops && 
                            `${apiResponseTranslate('Asegurese de agregar la(s) asistencia(s) de acuerdo a la solicitud de')} 
                            "${dataprops.requestTypeName}"; ${apiResponseTranslate('la cual corresponde de la fecha')} ${dataprops.from_date_request} 
                            ${apiResponseTranslate('hasta')} ${dataprops.to_date_request}.`}
                    />
                    </>
                   
                )
            }
            </Container>

            { 
                view =='workers' && showAssistanceModal && (
                    <ModalAssistance
                        open={showAssistanceModal}
                        closeModal={()=>{setShowAssistanceModal(false)}}
                        actionSelected={actionSelected}
                        onListAssistances={getAssitsCalendar}
                        assistance={assistanceSelected}
                        workerSelected={workerSelected}
                        clearState={()=>{setAssistanceSelected('')}}
                        workers={workers}
                        isIntegration={isIntegration}
                        massive
                    />
                )
            }
            { 
                view =='assistance' && showAssistanceModal && (
                    <ModalAssistance
                        open={showAssistanceModal}
                        closeModal={()=>{setShowAssistanceModal(false)}}
                        actionSelected={actionSelected}
                        onListAssistances={getAssitsCalendar}
                        assistance={assistanceSelected}
                        workerSelected={workerSelected}
                        clearState={()=>{setAssistanceSelected('')}}
                        isIntegration={isIntegration}
                        startDateSelected={startDateSelected}
                        endDateSelected={endDateSelected}
                    />
                )
            }
            {
                view =='assistance' && showAssistanceDeleteModal && (
                    <ModalAssistanceDelete
                        open={showAssistanceDeleteModal}
                        closeModal={()=>{setShowAssistanceDeleteModal(false)}}
                        actionSelected={actionSelected}
                        onListAssistances={getAssitsCalendar}
                        assistance={assistanceSelected}
                        workerSelected={workerSelected}
                        clearState={()=>{setAssistanceSelected('')}}
                        startDateSelected={startDateSelected}
                        endDateSelected={endDateSelected}
                    />
                )
            }
        
        </Protected>
    )
};