import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik } from 'formik';
import { useState } from "react";
import { Alert, Divider, Grid, MenuItem, Select, Snackbar, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { Loading } from "@/components/common/Loading";
import { Props } from "./ModalAFP.type";
import { AfpService } from '@/service/services/Afp.service';
import ___PHRASE from "@/service/translate/translateService";
import { apiResponseTranslate } from "@/toolbox/helpers/responseApi.helper";

export const ModalAFP: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, afp, onListAFP, afpType, clearState } = props;

    const [loading, setLoading] = useState<boolean>(false);

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const [data, setData] = useState({
        id: afp ? afp?.id : '',
        idafp: afp ? afp.idafp : '',
        year: afp ? afp.year : '',
        month: afp ? afp.month : '',
        cot: afp ? afp.cot : 0,
        sis: afp ? afp.sis : 0
    });

    const year = [
        {year: 2022},
        {year: 2023},
        {year: 2024},
        {year: 2025},
        {year: 2026},
        {year: 2027},
    ]

    const month = [
        {id: 1, month: ___PHRASE("enero")},
        {id: 2, month: ___PHRASE("febrero")},
        {id: 3, month: ___PHRASE("marzo")},
        {id: 4, month: ___PHRASE("abril")},
        {id: 5, month: ___PHRASE("mayo")},
        {id: 6, month: ___PHRASE("junio")},
        {id: 7, month: ___PHRASE("julio")},
        {id: 8, month: ___PHRASE("agosto")},
        {id: 9, month: ___PHRASE("septiembre")},
        {id: 10, month: ___PHRASE("octubre")},
        {id: 11, month: ___PHRASE("noviembre")},
        {id: 12, month: ___PHRASE("diciembre")},
    ]

    const validateForm = (values) => {
        let errors: any = {};
        var regYear = /^\d{4}$/;

        if(!values.idafp) errors.idafp = "AFP requerido";
        if(!values.year) errors.year = "año requerido";
        if(!(regYear.test(values.year))) errors.year = ___PHRASE("añoNoValido");
        if(!values.month) errors.month = "Mes requerido";
        if(!values.idafp) errors.idafp = "afp requerido";
        if(!values.cot) errors.cot = "cot requerido";
        // if(!values.sis) errors.sis = "sis requerido";
        return errors;
    }

    const onSubmit = async(values, {})  => {
        const formData = {
            year: values.year.toString(),
            month: values.month.toString(),
            idafp: values.idafp,
            cot: values.cot,
            sis: values.sis
        }

        setLoading(true);
        if(actionSelected=='edit'){
            await AfpService.editDetailAfp(afp.id, formData).then((data) => {
                let apimessagge = apiResponseTranslate(data?.data?.message);

                if(data.data.status){
                    setSnackBarConfig({...snackBarConfig, open:true, severity:'success', message: apimessagge})
                    onListAFP && onListAFP()
                    closeModal && closeModal();
                } else {
                    setSnackBarConfig({...snackBarConfig, open:true, severity:'error', message: apimessagge})
                }
            })
            setLoading(false);
        }else{
            await AfpService.createDetailAfp(formData).then((data) => {
                let apimessagge = apiResponseTranslate(data?.data?.message);

                if(data.data.status){
                    setSnackBarConfig({...snackBarConfig, open:true, severity:'success', message: apimessagge})
                    onListAFP && onListAFP()
                    closeModal && closeModal();
                } else {
                    setSnackBarConfig({...snackBarConfig, open:true, severity:'error', message: apimessagge})
                }
            })
            setLoading(false);
        }
    }

    const handleAFPChange = (event) => {
        const selectedAFP = event.target.value
        setData({ ...data, idafp: selectedAFP })
    };

    const handleYearChange = (event) => {
        const selectedYear = event.target.value
        setData({ ...data, year: selectedYear })
    };

    const handleMonthChange = (event) => {
        const selectedMonth = event.target.value
        setData({ ...data, month: selectedMonth })
    };


    return(
        <>
        {loading && <Loading/>}
        <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>

        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick handle size="sm">
            <ModalHeader
                text={(actionSelected=='edit') ? ___PHRASE("editar") + ' variables de AFP' : ___PHRASE("agregar") + ' variables de AFP'}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{overflowY:'scroll', maxHeight:'400px', paddingX:4}}>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={3}>
                                        <Typography variant="subtitle1" className="custom-input"><b>AFP:</b></Typography>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Select
                                                id="afp_status"
                                                variant="standard"
                                                value={values.idafp}
                                                onChange={handleAFPChange}
                                                error={ errors.idafp && touched.idafp ? true : false }
                                                // helperText={errors.afp && touched.afp ? errors.afp : ''}
                                                fullWidth
                                            >
                                                {
                                                afpType.map((item, i) => {
                                                    return <MenuItem key={i} value={item.id}>{item.codigo + " - " + item.name}</MenuItem>;
                                                })
                                                }
                                            </Select>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={3}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Año:</b></Typography>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Select
                                                id="year_status"
                                                variant="standard"
                                                value={values.year}
                                                onChange={handleYearChange}
                                                error={ errors.year && touched.year ? true : false }
                                                disabled={actionSelected=='edit'}
                                                // helperText={errors.year && touched.year ? errors.year : ''}
                                                fullWidth
                                            >
                                                {
                                                year.map((item, i) => {
                                                    return <MenuItem key={i} value={item.year}>{item.year}</MenuItem>;
                                                })
                                                }
                                            </Select>
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={3}>
                                            <Typography variant="body1"><b>Mes:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Select
                                                id="month_status"
                                                variant="standard"
                                                value={values.month}
                                                onChange={handleMonthChange}
                                                disabled={actionSelected=='edit'}
                                                error={ errors.month && touched.month ? true : false }
                                                // helperText={errors.month && touched.month ? errors.month : ''}
                                                fullWidth
                                            >
                                                {
                                                month.map((item, i) => {
                                                    return <MenuItem key={i} value={item.id}>{item.month}</MenuItem>;
                                                })
                                                }
                                            </Select>
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={3}>
                                            <Typography variant="body1"><b>Cot:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={9}>
                                        <TextField
                                            id="cot"
                                            variant="standard"
                                            type="number"
                                            name="cot"
                                            fullWidth
                                            value={values.cot}
                                            onChange={handleChange}
                                            placeholder="Ingrese Cot"
                                            error={errors.cot && touched.cot ? true : false}
                                            helperText={errors.cot && touched.cot ? errors.cot : ''}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={3}>
                                            <Typography variant="body1"><b>SIS:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={9}>
                                        <TextField
                                            id="sis"
                                            variant="standard"
                                            type="number"
                                            name="sis"
                                            fullWidth
                                            value={values.sis}
                                            onChange={handleChange}
                                            placeholder="Ingrese SIS"
                                            error={errors.sis && touched.sis ? true : false}
                                            helperText={errors.sis && touched.sis ? errors.sis : ''}
                                        />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(actionSelected=='edit') ? ___PHRASE("actualizar") : ___PHRASE("registrar")}
                                    cancelText={___PHRASE("cancelar")}
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}