import { typesExpenseRepository } from "../repositories/TypesExpense.repository";

export const typesExpenseService = {
    getTypesExpense,
    createTypeExpense,
    editTypeExpense,
    deleteTypeExpense,
}

async function getTypesExpense(search = ''){
    const res = await typesExpenseRepository.getTypesExpense(search);
    return res;
}

async function createTypeExpense(dataTypeExpense){
    const res = await typesExpenseRepository.createTypeExpense(dataTypeExpense);
    return res;
}

async function editTypeExpense(id, dataTypeExpense){
    const res = await typesExpenseRepository.editTypeExpense(id, dataTypeExpense);
    return res;
}

async function deleteTypeExpense(id:number){
    const res = await typesExpenseRepository.deleteTypeExpense(id);
    return res;
}