import React from 'react'
import { Button, DialogTitle, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import '../../assets/styles/modals.scss';

export const ModalHeader = (props) => {
  const { icon, text, className="", onCancel, clearState, disabledButton=false } = props;

  return (
    <DialogTitle className="modal-header">
      <div className={className}>
        <div className={className}>
          {icon && icon}<Typography className="ml2" sx={{textTransform:'uppercase', fontSize:14, fontWeight:600}}>{text || ""}</Typography>
        </div>
        {
          !disabledButton && (
            <Button onClick={() => {onCancel && onCancel(); clearState && clearState()}}>
                <CloseIcon />
            </Button> 
          )
        }
              
      </div>
    </DialogTitle>
  )
}