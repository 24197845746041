import React, { useEffect, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Loading } from "@components/common/Loading";
import { Alert, Breadcrumbs, Container, Snackbar } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { WorkerService } from '@/service/services/Worker.service';
import { Props } from './ExtraHours.type';
import { emphasize, styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import GroupsIcon from '@mui/icons-material/Groups';
import { AssistanceService } from '@/service/services/Assistance.service';
import { ModalExtraHour } from '@/components/Modal/ModalExtraHour';
import { ModalExtraHourDelete } from '@/components/Modal/ModalExtraHourDelete';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import ___PHRASE from '@/service/translate/translateService';
import { apiResponseTranslate } from '@/toolbox/helpers/responseApi.helper';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

export const ExtraHoursView: React.FC<Props> = (props:any): JSX.Element => {

    const [loading, setLoading] = useState<boolean>(false);
    const [workers, setWorkers] = useState<any>([]);
    const [workerSelected, setWorkerSelected] = useState<any>('');
    const [ExtraHours, setExtraHours] = useState<any>([]);
    const [showExtraHourModal, setShowExtraHourModal] = useState<boolean>(false);
    const [showExtraHourDeleteModal, setShowExtraHourDeleteModal] = useState<boolean>(false);
    const [extraHourSelected, setExtraHourSelected] = useState<any>('');
    // const [openModal, setOpenModal] = useState<Boolean>(false)
    const [actionSelected, setActionSelected] = useState<any>('');
    const [view, setView] = useState<String>('workers');
    const [showExtraHours, setShowExtraHours] = useState<boolean>(false);
    const [validateExtraHour, setValidateExtraHour] = useState<any>([]);
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const cabecera = [
        { name: 'n_document', label: ___PHRASE("rutTrabajador") },
        { name: 'surname', label: ___PHRASE("apellidoPaterno") },
        { name: 'second_surname', label: ___PHRASE("apellidoMaterno") },
        { name: 'name', label: ___PHRASE("nombres") }];

    const cabeceraExtraHours = [
    { name: 'code', label: ___PHRASE("codigo") },
    // { name: 'iddetstructureemployeeopt', label: 'ESTRUCTURA' },
    { name: 'date', label: ___PHRASE("fecha") },
    { name: 'hour', label: ___PHRASE('horas') }];

    useEffect(()=> {
        getWorkers()
    },[]);

    useEffect(()=> {
        if(workerSelected){
            getExtraHoursByEmployee(workerSelected.id);
        }
    },[workerSelected]);

    const getWorkers = async() => {
        setLoading(true);
        const resp:any = await WorkerService.getWorkers();
        setWorkers(resp.data || []);
        setLoading(false);
    }

    const getExtraHoursByEmployee = async(idEmployee) => {
        setLoading(true);
        await AssistanceService.getExtraHoursByEmployee(idEmployee).then(async(data) => {
            setExtraHours(data.data || []);
            await AssistanceService.getDetailEmployeeOPT(idEmployee).then((opt) => {
                setValidateExtraHour(opt.data)
                console.log(opt)
            })
            setLoading(false);
        })
    }

    function RecuperarData(value){
        console.log(value);
        !workerSelected && setWorkerSelected(value);
        workerSelected && setExtraHourSelected(value);
        setShowExtraHours(true);
        setView('extraHours');
        // setOpenModal(true)
    }

    const searchData = async (value) => {
        const resp:any = await WorkerService.getWorkers(value);
        setWorkers(resp.data || []);
    }

    const getSnackBar = (severity, message) => {
       setSnackBarConfig(prev => ({
          ...prev, open: true,
          severity: severity,
          message: message
          }
       ));
    }

    return (
        <Protected>
            {loading && <Loading/>}
            <Snackbar
                open={snackBarConfig.open} 
                autoHideDuration={snackBarConfig.autoHideDuration} 
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
                <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                    {snackBarConfig.message || ""}
                </Alert>
            </Snackbar>
            <Container>
            <Breadcrumbs aria-label="breadcrumb" sx={{my:3, ml:2}}>
                <StyledBreadcrumb
                    label={___PHRASE("listaDeEmpleados")}
                    onClick={() => {
                        setView('workers');
                        setWorkerSelected('');
                        setShowExtraHours(false);
                    }}
                    icon={<GroupsIcon fontSize="small" />}
                />
                {
                    showExtraHours && (
                        <StyledBreadcrumb
                            label={apiResponseTranslate(`Horas extras`)}
                            onClick={() => {setView('extraHours')}}
                            icon={<GroupsIcon fontSize="small" />}
                        />
                    )
                }
            </Breadcrumbs>
            {
                view == 'workers' && (
                    <TableDataV2
                        title={apiResponseTranslate('Horas Extras por empleado')}
                        data={workers}
                        header={cabecera}
                        actionSelect={setActionSelected}
                        status_action
                        disabled_title={false}
                        btn_disabled
                        btn_edit_disable={true}
                        btn_delete_disable={true}
                        btn_helper
                        iconBtnHelper={<AccessTimeFilledIcon fontSize='small'></AccessTimeFilledIcon>}
                        currentView={view}
                        textSearch={___PHRASE("buscarTrabajador")}
                        searchData={searchData}
                        RecuperarData={RecuperarData}
                        />
                )
            }
            {
                view == 'extraHours' && (
                    <TableDataV2
                        title={`${apiResponseTranslate('Horas extras de')} " ${workerSelected?.name}"`}
                        data={ExtraHours}
                        header={cabeceraExtraHours}
                        actionSelect={setActionSelected}
                        setModalSave={setShowExtraHourModal}
                        setModalDelete={setShowExtraHourDeleteModal}
                        status_action
                        disabled_title={false}
                        btn_disabled={false}
                        btn_edit_disable={false}
                        btn_delete_disable={false}
                        currentView={view}
                        RecuperarData={RecuperarData}
                    />
                )
            }
            </Container>

            { 
                showExtraHourModal && (
                    <ModalExtraHour
                        open={showExtraHourModal}
                        closeModal={()=>{setShowExtraHourModal(false)}}
                        actionSelected={actionSelected}
                        onListExtraHours={getExtraHoursByEmployee}
                        extraHour={extraHourSelected}
                        workerSelected={workerSelected}
                        validateOPTHour={validateExtraHour}
                        getSnackBar={getSnackBar}
                        clearState={()=>{setExtraHourSelected('')}}
                    />
                )
            }
            {
                actionSelected === 'delete' && (
                    <ModalExtraHourDelete
                        open={showExtraHourDeleteModal}
                        closeModal={()=>{setShowExtraHourDeleteModal(false)}}
                        actionSelected={actionSelected}
                        onListExtraHours={getExtraHoursByEmployee}
                        extraHour={extraHourSelected}
                        workerSelected={workerSelected}
                        getSnackBar={getSnackBar}
                        clearState={()=>{setExtraHourSelected('')}}
                    />
                )
            }
        </Protected>
    )
};