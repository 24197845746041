import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik } from 'formik';
import { useEffect, useState } from "react";
import { Alert, Autocomplete, Checkbox, Divider, FormControlLabel, Grid, MenuItem, Select, Snackbar, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { Loading } from "@/components/common/Loading";
import { Props } from "./ModalProduct.type";
import { branchOfficeService } from "@/service/services/BranchOffice.service";
import { WorkerService } from "@/service/services/Worker.service";
import { ProductService } from "@/service/services/Products.service";
import moment from "moment";
import ___PHRASE from "@/service/translate/translateService";

export const ModalProduct: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, product, onListProduct, clearState } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [branchOffices, setBranchOffices] = useState<any>([]);
    const [employees, setEmployees] = useState<any>([]);
    const [employeeSelected, setEmployeeSelected] = useState<any>('');
    const [lowProduct, setLowProduct] = useState<boolean>(false);
    const [highProduct, setHighProduct] = useState<boolean>(false);

    const todayDate = moment().format('MMMM Do YYYY, h:mm:ss a');

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    })

    const [data, setData] = useState<any>({
        id: product ? product.id : '',
        name: product ? product.name : '',
        description: product ? product.description : '',
        brand: product ? product.brand : '',
        status: product ? product.status : '',

        observations: product ? product.observations : '',
        status_initial: product ? product.status_initial : 10,
        model: product ? product.model : '',
        num_doc: product ? product.num_doc : '',
        net_cost: product ? product.net_cost : '',
        date_purchase: product ? product.date_purchase : '',
        estimated_duration: product ? product.estimated_duration : '',

        id_employee: product ? product.id_employee : '',
        id_branch_office: product ? product.id_branch_office : '',
        date: product ? product.date : '',
        agreed_return_date: product ? product.agreed_return_date : '',
    });

    useEffect(() => {
        getBranchOffices();
        getWorkers();
    }, []);

    const getBranchOffices = async () => {
        setLoading(true);
        const response = await branchOfficeService.getBranchOffices();
        setBranchOffices(response.data || []);
        setLoading(false);
    }

    const getWorkers = async() => {
        setLoading(true);
        const resp:any = await WorkerService.getWorkers();
        
        const oldData = resp.data || [];
        let newData = [];
        oldData.length > 0 && oldData.map((item) => {
            let obj = {
                id: item.id,
                name: item.name+' '+item.surname+' '+item.second_surname,
            }
            newData.push(obj);
        });
        setEmployees(newData || []);
        setLoading(false);
    }

    const validateForm = (values) => {
        let errors: any = {};
        if(!values.name) errors.name = ___PHRASE("nombreRequerido");
        if(!values.description) errors.description = ___PHRASE("descripcionRequerido");
        if(!values.brand) errors.brand = "marca requerido";

        if(!values.num_doc && !product) errors.num_doc = "num documento requerido";
        if(!values.model && !product) errors.model = "modelo requerido";
        if(!values.net_cost && !product) errors.net_cost = "net_cost requerido";
        if(!values.date_purchase && !product) errors.date_purchase = "fecha de compra requerido";
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        const formData = {
            id: values.id,
            name: values.name,
            description : values.description,
            brand : values.brand,
            status : lowProduct ? '3' : (highProduct ? '1' :'1'),

            observations : values.observations,
            status_initial : values.status_initial,
            num_doc : values.num_doc,
            model : values.model,
            net_cost : values.net_cost,
            date_purchase : values.date_purchase,
            estimated_duration : values.estimated_duration,
            
            id_employee : employeeSelected && employeeSelected.id,
            id_branch_office : values.id_branch_office,
            date : values.date,
            agreed_return_date : values.agreed_return_date,
        }

        setLoading(true);
        if(product && actionSelected=='edit'){
            await ProductService.editProducts(formData, product.id).then((data) => {
                onListProduct && onListProduct();
                closeModal && closeModal();
                clearState && clearState();
            })
        }else{
            await ProductService.createProducts(formData).then((data) => {
                onListProduct && onListProduct();
                closeModal && closeModal();
                clearState && clearState();
            })
        }
        setLoading(false);
    }

    return(
        <>
        {loading && <Loading/>}

        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>

        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={((product && actionSelected=='edit') ? ___PHRASE("editar") : ___PHRASE("agregar"))+' '+___PHRASE("material")}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >

                                <Typography variant="subtitle1" className="custom-input" marginBottom='25px'><b>{___PHRASE("datosDelMaterial")}</b></Typography>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("nombre")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="name"
                                            variant="standard"
                                            type="text" 
                                            name="name"
                                            fullWidth
                                            value={values.name}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("nombre")}
                                            error={errors.name && touched.name ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("descripcion")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="description"
                                            variant="standard"
                                            type="text" 
                                            name="description"
                                            multiline
                                            maxRows={4}
                                            fullWidth
                                            value={values.description}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("descripcion")}
                                            error={errors.description && touched.description ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("marca")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="brand"
                                            variant="standard"
                                            type="text" 
                                            name="brand"
                                            fullWidth
                                            value={values.brand}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("marca")}
                                            error={errors.brand && touched.brand ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid sx={{ display: 'flex' }}>

                                            {
                                                product?.status != '3' && (
                                                    <FormControlLabel control={
                                                        <Checkbox 
                                                            checked={lowProduct} 
                                                            onChange={()=>{lowProduct ? setLowProduct(false) : setLowProduct(true)}} 
                                                        />} 
                                                        label={___PHRASE("darDeBajaAlMaterial")} />
                                                )
                                            }
                                            {
                                                product?.status == '3' && (
                                                    <FormControlLabel control={
                                                        <Checkbox 
                                                            checked={highProduct} 
                                                            onChange={()=>{highProduct ? setHighProduct(false) : setHighProduct(true)}} 
                                                        />} 
                                                        label={___PHRASE("darDeAltaAlMaterial")} />
                                                )
                                            }
                                            {
                                                (lowProduct || highProduct) && (
                                                    <TextField
                                                        type="text"
                                                        value={todayDate}
                                                        sx={{width: '300px'}}
                                                    />
                                                )
                                            }
                                        </Grid>
                                    </Grid>

                                    {
                                        !product && (
                                            <>
                                            <Typography variant="subtitle1" className="custom-input" marginBottom='25px'  marginTop='15px'><b>{___PHRASE("datosDeLaCompra")}</b></Typography>

                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                                <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("observacion")}:</b></Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                <TextField
                                                    id="observations"
                                                    variant="standard"
                                                    type="text" 
                                                    name="observations"
                                                    multiline
                                                    maxRows={4}
                                                    fullWidth
                                                    value={values.observations}
                                                    onChange={handleChange}
                                                    placeholder={___PHRASE("observacion")}
                                                    error={errors.observations && touched.observations ? true : false}
                                                />
                                                </Grid>
                                            </Grid>

                                            <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={4}>
                                                    <Typography variant="body1"><b>{___PHRASE("estadoInicial")}:</b> </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Select
                                                        id="status_initial"
                                                        name='status_initial'
                                                        variant="standard"
                                                        value={values.status_initial}
                                                        onChange={(event) => { 
                                                        setFieldValue("status_initial", event.target.value);
                                                        }}
                                                        fullWidth
                                                    >
                                                    <MenuItem key={0} value={1}>1</MenuItem>;
                                                    <MenuItem key={1} value={2}>2</MenuItem>;
                                                    <MenuItem key={2} value={3}>3</MenuItem>;
                                                    <MenuItem key={3} value={4}>4</MenuItem>;
                                                    <MenuItem key={4} value={5}>5</MenuItem>;
                                                    <MenuItem key={5} value={6}>6</MenuItem>;
                                                    <MenuItem key={6} value={7}>7</MenuItem>;
                                                    <MenuItem key={7} value={8}>8</MenuItem>;
                                                    <MenuItem key={8} value={9}>9</MenuItem>;
                                                    <MenuItem key={9} value={10}>10</MenuItem>;
                                                        
                                                    </Select>
                                                </Grid>
                                            </Grid> 

                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("nDocumento")}:</b></Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                <TextField
                                                    id="num_doc"
                                                    variant="standard"
                                                    type="text" 
                                                    name="num_doc"
                                                    fullWidth
                                                    value={values.num_doc}
                                                    onChange={handleChange}
                                                    placeholder={___PHRASE("numeroDeDocumento")}
                                                    error={errors.num_doc && touched.num_doc ? true : false}
                                                />
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("modelo")}:</b></Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                <TextField
                                                    id="model"
                                                    variant="standard"
                                                    type="text" 
                                                    name="model"
                                                    fullWidth
                                                    value={values.model}
                                                    onChange={handleChange}
                                                    placeholder={___PHRASE("modelo")}
                                                    error={errors.model && touched.model ? true : false}
                                                />
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("precioDeCompra")}:</b></Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                <TextField
                                                    id="net_cost"
                                                    variant="standard"
                                                    type="number" 
                                                    name="net_cost"
                                                    fullWidth
                                                    value={values.net_cost}
                                                    onChange={handleChange}
                                                    placeholder={___PHRASE("precioDeCompra")}
                                                    error={errors.net_cost && touched.net_cost ? true : false}
                                                />
                                                </Grid>
                                            </Grid> 

                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("fechaDeCompra")}:</b></Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <TextField
                                                        id="date_purchase"
                                                        variant="standard"
                                                        fullWidth
                                                        name="date_purchase"
                                                        type="date"
                                                        onChange={handleChange}
                                                        value={values.date_purchase}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        error={errors.date_purchase && touched.date_purchase ? true : false}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("duracionEstimada")}:</b></Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                <TextField
                                                    id="estimated_duration"
                                                    variant="standard"
                                                    type="date" 
                                                    name="estimated_duration"
                                                    fullWidth
                                                    value={values.estimated_duration}
                                                    onChange={handleChange}
                                                    placeholder={___PHRASE("duracionEstimada")}
                                                    error={errors.estimated_duration && touched.estimated_duration ? true : false}
                                                />
                                                </Grid>
                                            </Grid> 

                                            <Typography variant="subtitle1" className="custom-input" marginBottom='25px' marginTop='15px'><b>{___PHRASE("datosDelMovimiento")}</b></Typography>

                                            <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={4}>
                                                    <Typography variant="body1"><b>{___PHRASE("empleado")}:</b> </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Autocomplete
                                                        id="disable-portal"
                                                        disablePortal
                                                        value={employeeSelected}
                                                        options={employees || []}
                                                        getOptionLabel={(option) => option.name || ""}
                                                        fullWidth
                                                        renderInput={(params) => <TextField {...params} variant="standard" label={___PHRASE("escribeParaBuscar")} />}
                                                        onChange={(e, newValue) => { setEmployeeSelected(newValue) }}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={4}>
                                                    <Typography variant="body1"><b>{___PHRASE("sucursal")}:</b> </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Select
                                                        id="id_branch_office"
                                                        variant="standard"
                                                        value={values.id_branch_office}
                                                        onChange={(event) => { 
                                                        setFieldValue("id_branch_office", event.target.value);
                                                        }}
                                                        error={ errors.id_branch_office && touched.id_branch_office ? true : false }
                                                        fullWidth
                                                    >
                                                        {
                                                        branchOffices.map((branch, i) => {
                                                            return <MenuItem key={i} value={branch.id}>{branch.address}</MenuItem>;
                                                        })
                                                        }
                                                    </Select>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("fecha")}:</b></Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <TextField
                                                        id="date"
                                                        variant="standard"
                                                        fullWidth
                                                        name="date"
                                                        type="date"
                                                        onChange={handleChange}
                                                        value={values.date}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("fechaDeRegresoAcordado")}:</b></Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <TextField
                                                        id="agreed_return_date"
                                                        variant="standard"
                                                        fullWidth
                                                        name="agreed_return_date"
                                                        type="date"
                                                        onChange={handleChange}
                                                        value={values.agreed_return_date}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            </>
                                        )
                                    }

                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(product && actionSelected=='edit') ? ___PHRASE("actualizar") : ___PHRASE("registrar")}
                                    cancelText={___PHRASE("cancelar")} 
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}