import React, { useEffect, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Container } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { Loading } from '@/components/common/Loading';
import { Props } from './GroupCompanies.type';
import { companiesService } from '@/service/services/Companies.service';
import { ModalGroupCompany } from '@/components/Modal/ModalGroupCompany';
import { ModalGroupCompanyDelete } from '@/components/Modal/ModalGroupCompanyDelete';
import ___PHRASE from '@/service/translate/translateService';

export const GroupCompanyView: React.FC<Props> = (props:any): JSX.Element => {

   const [loading, setLoading] = useState<boolean>(false);
   const [companiesGroup, setCompaniesGroup] = useState([]);
   const [showModal, setShowModal] = useState<boolean>(false); // create and edit
   const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false); // delete
   const [actionSelected, setActionSelected] = useState<String>('');
   const [companyGroupSelected, setCompanyGroupSelected] = useState<String>('');

   useEffect(() => {
    getGroupCompanies()
   },[]);

   const getGroupCompanies = async () => {
      setLoading(true);
      const response = await companiesService.getGroupCompanies();
      if (response.data) {
        setCompaniesGroup(response.data || [])
      } else {
         console.log('error')
      }
      setLoading(false);
   }

   function RecuperarData(value){
      console.log(value);
      setCompanyGroupSelected(value);
   }

  return (
      <Protected>
         {loading && <Loading/>}
         <Container>
            <TableDataV2
               title={___PHRASE("grupoDeCompania")}
               data={companiesGroup}
               header={[
                  { name: 'business_name', label: ___PHRASE("compania"), filter: false, Chip: true },
                  { name: 'registry_number', label: 'RUT', filter: false, Chip: true },
                  { name: 'status_name', label: ___PHRASE("estado"), filter: false, Chip: true },
               ]}
               actionSelect={setActionSelected}
               setModalSave={setShowModal}
               setModalDelete={setShowDeleteModal}
               status_action
               btn_edit_disable
               // checkbox
               RecuperarData={RecuperarData}
            />
         </Container>

         { 
            showModal && (
               <ModalGroupCompany
                  open={showModal}
                  closeModal={()=>{setShowModal(false)}}
                  actionSelected={actionSelected}
                  onList={getGroupCompanies}
               />
            )
         }
         {
            actionSelected === 'delete' && (
               <ModalGroupCompanyDelete
                  open={showDeleteModal}
                  closeModal={()=>{setShowDeleteModal(false)}}
                  actionSelected={actionSelected}
                  onList={getGroupCompanies}
                  groupCompany={companyGroupSelected}
                  clearState={()=>{setCompanyGroupSelected('')}}
               />
            )
         }
      
      </Protected>
   )
};