import { apiResponseTranslate } from '@/toolbox/helpers/responseApi.helper';
import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_TRANSLATE } from '@/toolbox/constants/local-storage';

export const LoanRepository = {

    getLoan: async(status, idEmployee): Promise<any> => {
        const language = readLocalStorage(KEY_TRANSLATE);

        const res = await http.get<any>(`${API_URL_BASE}/v1/loan?status=${status}&idEmployee=${idEmployee}`);
        const {data, error, message} = res;

        
        const newData = [];

        (data || []).map((raw) => {
            if(language && language == 'en'){
                newData.push({
                    ...raw,
                    status_name: raw.status_loan == "0" ? apiResponseTranslate("Pendiente de Aprobación") :  
                            raw.status_loan == "3" ? apiResponseTranslate("Pre Aprobado") :  
                            raw.status_loan == "4" ? apiResponseTranslate("Pagado") : apiResponseTranslate("Activo"),
                })
            }else{
                newData.push({
                    ...raw,
                    status_name: raw.status_loan == "0" ? ("Pendiente de Aprobación") :  
                            raw.status_loan == "3" ? ("Pre Aprobado") :  
                            raw.status_loan == "4" ? ("Pagado") : ("Activo"),
                })
            }
        })

        return {
            data: newData,
            error,
            message
         };
    },

    getOptionalFeeLoan: async(idLoan): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/loan/getOptionalFeeLoan/${idLoan}`);
        const {data, error, message} = res;
        return { data: (data||[]).map((raw) => ({
            ...raw,
           selected: false,
        })), error, message };
    },

    getDetailFeeLoan: async(idLoan): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/loan/getDetailFeeLoan/${idLoan}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    limitForLey: async(idLoan): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/loan/limitForLey/${idLoan}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    createMaxEmployeeLoan:  async(dataLoan): Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/loan/createMaxEmployeeLoan`,{
            idEmployee: dataLoan.idEmployee,
            limit_max: dataLoan.limit_max
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    validatorMaxLoan: async(idEmployee): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/loan/validatorMaxLoan/${idEmployee}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    requestLoan:  async(dataLoan): Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/loan/requestLoan`,{
            idEmployee: dataLoan.idEmployee,
            // year_initial: dataLoan.year_initial,
            // month_initial: dataLoan.month_initial,
            idLimit: dataLoan.idLimit,
            loan: dataLoan.loan,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    createLoanOptional:  async(dataLoan): Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/loan/createLoanOptional`,{dataLoan});
        const {data, error, message} = res;
        return { data, error, message };
    },

    accept:  async(dataLoan): Promise<any> => {
        console.log(dataLoan)
        const res = await http.post<any>(`${API_URL_BASE}/v1/loan/accept`,{
            idLoan: dataLoan.idLoan,
            idOptional: dataLoan.idOptional,
            idEmployee: dataLoan.idEmployee
        });
        const {data, error, message} = res;
        return { data, error, message };
    },
}