import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

const dataUser = readLocalStorage(KEY_USER_DATA);
const rut_empresa = dataUser?.user?.rut_empresa;

export const AdminValidationRepository = {

    getAdminValidations: async(status:any): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/identityValidation/adminList?status=${status}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    editAdminValidation: async (idValidation, dataValidation) : Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/identityValidation/adminEdit/${idValidation}`, dataValidation);
        const {data, error, message} = res;
        return { data, error, message };
    },

    getValidationbyEmployee: async(idemployee: number): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/identityValidation/byEmployee?idemployee=${idemployee}`);
        const {data, error, message} = res;
        return { data, error, message };
    },
}