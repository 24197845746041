import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

const dataUser = readLocalStorage(KEY_USER_DATA);
const rut_empresa = dataUser?.user?.rut_empresa;

export const MovementsRepository = {

    getMovementsTypes: async(): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/assignmentMovStaff/mov/list`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    getMovementsByEmployee: async(idEmployee: number): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/assignmentMovStaff/${idEmployee}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    createMovement: async (dataMovement) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/assignmentMovStaff`, {
            idemployee: dataMovement.idemployee,
            idmovstaff: dataMovement.idmovstaff,
            date_from: dataMovement.date_from,
            date_until: dataMovement.date_until,
            observation: dataMovement.observation,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    generateExcelMovement: async () : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/assignmentMovStaff/generateExcel`, {});
        const {data, error, message} = res;
        return { data, error, message };
    },

    deleteMovement: async(idMovement:number): Promise<any> => {
        const res= await http.delete<any>(`${API_URL_BASE}/v1/assignmentMovStaff/${idMovement}`)
        return res;
    },

    editMovement: async (id, dataMovement) : Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/assignmentMovStaff/${id}`, dataMovement);
        const {data, error, message} = res;
        return { data, error, message };
    },
}