import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';


export const cmRepository = {

    getCM: async(year = ''): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/cm?year=${year}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    createCM: async (dataCM: any) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/cm`, dataCM);
        const {data, error, message} = res;
        return { data, error, message };
    },

    editCM: async (idCM: number, dataCM: any) : Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/cm/${idCM}`, dataCM);
        const {data, error, message} = res;
        return { data, error, message };
    },

    deleteCM: async(idCM: number): Promise<any> => {
        const res= await http.delete<any>(`${API_URL_BASE}/v1/cm/${idCM}`)
        return res;
    },
}


