import { Loading } from "@/components/common/Loading";
import { RemunerationService } from "@/service/services/Remuneration.service";
import ___PHRASE from "@/service/translate/translateService";
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { apiResponseTranslate } from "@/toolbox/helpers/responseApi.helper";
import { Alert, Button, Typography, Grid, Snackbar } from "@mui/material";
import { useState } from "react";
import { Props } from "./PDF.type"
import { KEY_TRANSLATE } from "@/toolbox/constants/local-storage";

export const PDFView: React.FC<Props> = (props:any): JSX.Element => {
    const [loading, setLoading] = useState<boolean>(false);
    const [btnSave, setBtnSave] = useState<boolean>(true);
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const param = urlParams.get('type');

    const language = readLocalStorage(KEY_TRANSLATE);
    const data = readLocalStorage('dataRemu');
    const dataIdEmployee = data?.idemployee;
    const dataPDF = data?.pdf;
    const dataRequest = data?.dataRequest ? data.dataRequest : '';
    const dataRemu = {
        idperiod: data?.idperiod,
        month: data?.month,
        year: data?.year
    }

    const onSubmit = async() => {
        setLoading(true);
        const resp:any = await RemunerationService.createRemuneration({...dataRemu, language:language }, dataIdEmployee);
        let apimessage = apiResponseTranslate(resp?.data?.message || '')
        if(resp.data.status == true){
            setBtnSave(false);
            setLoading(false);
            return (
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                severity:'success',
                message: `${apimessage || ___PHRASE("creadoConExito")}` }
                ))
            );
        } else {
            setLoading(false);
            return (
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                severity:'error',
                message: `${apimessage || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}` }
                ))
            );
        }
    }

    const dataMassive = readLocalStorage('dataRemuMassive');
    const dataPDFMassive = dataMassive?.pdf;

    const createLiquidationMasive = async () => {
        setLoading(true);
        const dataLiquidationMasive = {
           idperiod: dataMassive?.idperiod,
           month: dataMassive?.month,
           year: dataMassive?.year,
           idproject: dataMassive?.idproject,
           idbusinessarea: dataMassive?.idbusinessarea,
           idcharge: dataMassive?.idcharge,
           idcostcenter: dataMassive?.idcostcenter
        }
  
        const response = await RemunerationService.createRemunerationMassive(dataLiquidationMasive);
        let apimessage = apiResponseTranslate(response.data.message)
        if (response.data.status == true) {
           setSnackBarConfig(prev => ({
              ...prev, open: true,
              severity: 'success',
              message: `${apimessage}`
              }
           ));
        } else {
           setSnackBarConfig(prev => ({
              ...prev, open: true,
              severity: 'warning',
              message: `${apimessage || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}` }
           ));
        }
        setLoading(false);
     }

    return (
        <>
        {loading && <Loading/>}
        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>

        <Grid container style={{ overflow: 'hidden !important', height:'100%', alignItems: 'center', textAlign: 'center'}}>
            {
                dataRequest &&  (
                    <Grid container >
                        <Typography  variant='h6' component='h1' gutterBottom className="tittle">
                            Solicitud de tipo {dataRequest.requestTypeName}: {dataRequest.detail}
                        </Typography>
                    </Grid>
                )
            }
            {
                !param && btnSave && (
                    <Grid container display='flex' justifyContent='center' style={{margin: '10px', overflow: 'hidden'}} >
                        <Button onClick={onSubmit} size="small" color={"primary"} variant="contained" >
                        {___PHRASE('crearRemuneracion')}
                        </Button>
                    </Grid>
                )
            }
            {
                param && param == 'massive' && (
                    <Grid container display='flex' justifyContent='center' style={{margin: '10px', overflow: 'hidden'}} >
                        <Button onClick={createLiquidationMasive} size="small" color={"primary"} variant="contained" >
                        {___PHRASE('crearRemuneracionMasiva')}
                        </Button>
                    </Grid>
                )
            }
            <iframe src={param && param == 'massive' ? dataPDFMassive : dataPDF} id="pdfVer" height="1000px" width="100%"></iframe>
        </Grid>
        </>
    )
}