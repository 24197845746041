import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { Profile } from '../models/Profile';


export const profileRepository = {

   getProfiles: async () : Promise<Profile>=> {
      const users = await http.get<Profile>(`${API_URL_BASE}/v1/profile/`);
      const { data, error, message } = users;
      return {
         data: (data||[]).map((raw) => ({
            id: raw?.id,
            name: raw?.name,
            minimum_salary: raw?.minimum_salary,
            maximum_salary: raw?.maximum_salary,
            status: raw?.status
         })),
         error,
         message
      }
   },

   getRoles: async(idRoles:any = ''): Promise<any> => {
      const res = await http.get<any>(`${API_URL_BASE}/v1/profile/getRoles?idRoles=${idRoles}`);
      const {data, error, message} = res;
      return { data, error, message };
  },

   createProfile: async (dataProfile) : Promise<any> => {
      const res = await http.post<any>(`${API_URL_BASE}/v1/profile/create`, {
          name: dataProfile.name,
          minimum_salary: dataProfile.minimum_salary,
          maximum_salary: dataProfile.maximum_salary,
          detail_structure: dataProfile.detail_structure,
      });
      const {data, error, message} = res;
      return { data, error, message };
  },

  updateProfile: async (idProfile: number, dataProfile) : Promise<any> => {
      const res = await http.patch<any>(`${API_URL_BASE}/v1/profile/update/${idProfile}`, {
         name: dataProfile.name,
         minimum_salary: dataProfile.minimum_salary,
         maximum_salary: dataProfile.maximum_salary,
      });
      const {data, error, message} = res;
      return { data, error, message };
   },

   deleteProfile: async(idProfile:number): Promise<any> => {
      const res= await http.delete<any>(`${API_URL_BASE}/v1/profile/delete/${idProfile}`)
      return res;
   },
}


