import React, { useState, useEffect } from 'react';
import { Box, Grid, Container, Typography, Button,  Tab, TextField } from '@mui/material'
import { Props } from "./LiquidationWorkers.type";
import { RleStructureService } from '@/service/services/RleStructure.service';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import { ModalStructureLiquidation } from '@/components/Modal/ModalStructureLiquidation';
import { Loading } from '../../../../components/common/Loading';
import ___PHRASE from '@/service/translate/translateService';
import { apiResponseTranslate } from '@/toolbox/helpers/responseApi.helper';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_TRANSLATE } from '@/toolbox/constants/local-storage';

export const LiquidationWorkers: React.FC<Props> = ({
    open,
    setOpen,
    data,
    setData
}): JSX.Element => {

   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(5);
   const [value, setValue] = useState('1');
   const [dataRLEStructure, setDataRLEStructure] = useState<any>([]);
   const [dataRLEOptional, setDataRLEOptional] = useState<any>([]);
   const [editDataRLEOptional, setEditDataRLEOptional] = useState<any>([]);
   const [showStructureOptionalModal, setShowStructureOptionalModal] = useState<boolean>(false);

  const columnsRLEOptional= [
   { id: 'code', label: ___PHRASE("codigo"), minWidth: 100 },
   { id: 'name', label: ___PHRASE("descripcion"), minWidth: 100 },
   { id: 'creation_date', label: ___PHRASE("fechaRegistro"), minWidth: 100 },
   { id: 'validity', label: ___PHRASE("fechaVigencia"), minWidth: 100 },
   { id: 'value', label: ___PHRASE("monto"), minWidth: 100 },
   { id: 'status', label: ___PHRASE("estado"), minWidth: 100 },
];

  const columnsRLEStructure = [
   { id: 'concept_DT', label: ___PHRASE("conceptoDT"), minWidth: 100 },
   { id: 'code', label: ___PHRASE("codigo"), minWidth: 100 },
   { id: 'name', label: ___PHRASE("descripcion"), minWidth: 100 },
]


  const labels = [
   { label: ___PHRASE("estructurasOpcionales"), id: '1' }, 
   { label: ___PHRASE("estructurasObligatorias"), id: '2' }
]

   const [loading, setLoading] = useState<boolean>(false);

   const language = readLocalStorage(KEY_TRANSLATE);

   useEffect(() => {
      getDetailEmployeeSO(data.id);
      EstructuraObligatorios();
   },[])

   const EstructuraObligatorios = async() => {
      const requireStructure = 1; //values: 1 - 0
      setLoading(true);
      const resp:any = await RleStructureService.getRLEStructureFilter(requireStructure);
       const newData = [];
      resp.data && (resp.data).length > 0 && (resp.data).map((item)=>{
         if(language && language == 'en'){
            newData.push({...item, name: apiResponseTranslate(item.name)})
         }else{
            newData.push(item)
         }
      })
      setDataRLEStructure(newData || [])
      setLoading(false);
   }

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
   };

   const handleChange = (event: React.SyntheticEvent, newValue: string) => {
      setValue(newValue);
   };

   const getDetailEmployeeSO = async (id_employee) => {
      setLoading(true);
      const resp:any = await RleStructureService.getDetailEmployeeSO(id_employee);
      const newData = [];
      resp.data && (resp.data).length > 0 && (resp.data).map((item)=>{
         if(language && language == 'en'){
            newData.push({...item, alias_name: apiResponseTranslate(item.alias_name)})
         }else{
            newData.push(item)
         }
      })
      setDataRLEOptional(newData || [])
      setLoading(false);
   }

   const handleChangeValidate = (e, row, index) =>{
   
      const dataOptional = {
         ...row,
         [e.target.name]: e.target.value
      }
      
      const arrayOptional = dataRLEOptional.map((value)=>
         value.id == row.id? dataOptional : value
      );
      if(editDataRLEOptional.length > 0){
         const index = editDataRLEOptional.findIndex(element => {
            if (element.id === row.id) {
               return true;
            }
            return false;
         });
         index == -1 && editDataRLEOptional.push(dataOptional);
         const arrayOptionalEdit = editDataRLEOptional.map((value)=>
         value.id == row.id? dataOptional : value
         );
         setEditDataRLEOptional(arrayOptionalEdit);
      }else{
         editDataRLEOptional.push(dataOptional);
      }
      setDataRLEOptional(arrayOptional);
   }

   const changeSelected = (e, value) => {
      const nuevaData = {
         ...value,
         status: e.target.checked ? '1' : '0'
      }
      const changeStatus = dataRLEOptional.map((row) =>
         row.id === value.id ? nuevaData : row
      );

      if(editDataRLEOptional.length > 0){
         const index = editDataRLEOptional.findIndex(element => {
            if (element.id === value.id) {
               return true;
            }
            return false;
         });
         index == -1 && editDataRLEOptional.push(nuevaData);
         const arrayOptionalEdit = editDataRLEOptional.map((dt)=>
            value.id == dt.id? nuevaData : dt
         );
         setEditDataRLEOptional(arrayOptionalEdit);
      }else{
         editDataRLEOptional.push(nuevaData);
      }
      setDataRLEOptional(changeStatus);
   }

   const handleSubmit = async(e) => {
      e.preventDefault();
      setLoading(true);

      let changeValue;
      if(editDataRLEOptional.length == 1){
         editDataRLEOptional.map((element)=>{
            changeValue = {
               id: element.id,
               value: element.value,
               validity: element.validity,
               status: element.status
            }
         });
         const resp = await RleStructureService.editDetailEmployeeSO(changeValue.id, changeValue);
         setEditDataRLEOptional([]);
      }else{
         const resp = await RleStructureService.editDetailEmployeeSOMultiple(editDataRLEOptional);
         setEditDataRLEOptional([]);
      }
      setLoading(false);
   }

   return (
      <Box>
         {loading && <Loading/>}
         <Container maxWidth='xl'>
            <Grid container spacing={3}>
               <Grid container direction='row' justifyContent={'space-between'} item xs={12} md={12} style={{marginTop:'15px'}} >
                  <Typography variant='h6' component='h1' gutterBottom className="tittle">
                     {data.n_document+' - '+data.name+' '+data.surname+' '+data.second_surname}
                  </Typography>
               </Grid>
            </Grid>
            <Box sx={{ width: '100%', typography: 'body1' }}>
               <TabContext
                  value={value}
               >
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                     <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example">
                        {labels.map((value, i) => {
                           return (
                              <Tab key={i} label={value.label} value={value.id} />
                           )
                        })}
                     </TabList>
                  </Box>
                  <TabPanel value="1">
                     <Grid item xs={12} md={3} marginBottom='15px'>
                        <Button
                           type='submit'
                           variant='contained'
                            onClick={handleSubmit}
                           disableElevation
                           className='btn_next'
                           style={{ backgroundColor: '#ff9700'}}
                        >
                           <span>{___PHRASE("guardarCambios")}</span>
                        </Button>
                     </Grid>
                     <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer sx={{ maxHeight: 440 }}>
                           <Table stickyHeader aria-label="sticky table">
                              <TableHead>
                                 <TableRow>
                                    {columnsRLEOptional.map((column) => (
                                       <TableCell
                                          key={column.id}
                                          style={{ minWidth: column.minWidth }}
                                       >
                                          {column.label}
                                       </TableCell>
                                    ))}
                                 </TableRow>
                              </TableHead>
                              <TableBody>
                                 {dataRLEOptional.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                    return (
                                       <TableRow hover role="checkbox" tabIndex={-1} key={row.codigo}>
                                          <TableCell>
                                             {row.code}
                                          </TableCell>
                                          <TableCell>
                                             {row.alias_name}
                                          </TableCell>
                                          <TableCell>
                                             {row.creation_date}
                                          </TableCell>
                                          <TableCell>
                                             <TextField
                                                disabled={row.status == '0' ? true : false}
                                                name='validity'
                                                type='month'
                                                value={row.validity}
                                                onChange={(e)=>{handleChangeValidate(e,row, index)}}
                                             />
                                          </TableCell>
                                          <TableCell>
                                             {
                                                row.code == 'HIT2' && (
                                                   <Typography style={{textAlign: 'center'}}>
                                                      ---
                                                   </Typography>
                                                ) || (
                                                   <TextField
                                                      disabled={row.status == '0' ? true : false}
                                                      sx={{width:'100px'}}
                                                      type='number'
                                                      name='value'
                                                      value={row.value}
                                                      onChange={(e) => { handleChangeValidate(e, row, index) }}
                                                   />
                                                )
                                             }
                                             
                                          </TableCell>
                                          <TableCell>
                                             <Checkbox 
                                             checked={row.status == '1' ? true : false} 
                                             onChange={(e) => { 
                                                changeSelected(e, row)
                                             }}
                                             />
                                          </TableCell>
                                       </TableRow>
                                    );
                                 })}

                              </TableBody>
                           </Table>
                        </TableContainer>
                        <TablePagination
                           rowsPerPageOptions={[10, 25, 100]}
                           component="div"
                           count={dataRLEOptional.length}
                           rowsPerPage={rowsPerPage}
                           page={page}
                           onPageChange={handleChangePage}
                           onRowsPerPageChange={handleChangeRowsPerPage}
                           labelRowsPerPage={language && language == 'es' ? 'Filas por página' : 'Rows per page'}
                        />
                     </Paper>
                  </TabPanel>
                  <TabPanel value="2">
                     <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer sx={{ maxHeight: 440 }}>
                           <Table stickyHeader aria-label="sticky table">
                              <TableHead>
                                 <TableRow>
                                    {columnsRLEStructure.map((column) => (
                                       <TableCell
                                          key={column.id}
                                          style={{ minWidth: column.minWidth }}
                                       >
                                          {column.label}
                                       </TableCell>
                                    ))}
                                 </TableRow>
                              </TableHead>
                              <TableBody>
                                 {dataRLEStructure.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                    return (
                                       <TableRow hover role="checkbox" tabIndex={-1} key={row.codigo}>
                                            <TableCell>
                                             {row.concept_DT}
                                          </TableCell>
                                          <TableCell>
                                             {row.code}
                                          </TableCell>
                                          <TableCell>
                                             {row.name}
                                          </TableCell>
                                       </TableRow>
                                    );
                                 })}

                              </TableBody>
                           </Table>
                        </TableContainer>
                        <TablePagination
                           rowsPerPageOptions={[10, 25, 100]}
                           component="div"
                           count={dataRLEStructure.length}
                           rowsPerPage={rowsPerPage}
                           page={page}
                           onPageChange={handleChangePage}
                           onRowsPerPageChange={handleChangeRowsPerPage}
                           labelRowsPerPage={language && language == 'es' ? 'Filas por página' : 'Rows per page'}
                        />
                     </Paper>
                  </TabPanel>
               </TabContext>
            </Box>
         </Container>
         { 
            showStructureOptionalModal && (
               <ModalStructureLiquidation
                  open={showStructureOptionalModal}
                  closeModal={()=>{setShowStructureOptionalModal(false)}}
                  onListStructures={getDetailEmployeeSO}
                  setValueTab={setValue}
                  employe={data}
               />
            )
         }
        </Box>
    )
}
