import { useEffect, useState } from "react"
import { TableDataV2 } from "@/components/common/Tablev2"
import { Protected } from "@/components/layout/Protected"
import { Autocomplete, Container, Grid, TextField, Typography } from "@mui/material"
import { Props } from "./RecluitingTeam.type"
import { RecluitingTeamService } from "@/service/services/RecluitingTeam.service"
import { Loading } from "@/components/common/Loading"
import { ModalRecluitingTeam } from "@/components/Modal/ModalRecluitingTeam"
import { ModalRecluitingTeamDelete } from "@/components/Modal/ModalRecluitingTeamDelete"
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper"
import { JobOffertService } from "@/service/services/JobOffert.service"
import ___PHRASE from "@/service/translate/translateService"

export const RecluitingTeamView: React.FC<Props> = (props:any): JSX.Element => {

    const [loading, setLoading] = useState<boolean>(false);
    const [recluitingTeam, setRecluitingTeam] = useState([]);
    const [showRecluitingModal, setShowRecluitingModal] = useState<boolean>(false);
    const [showRecluitingModalDelete, setShowRecluitingModalDelete] = useState<boolean>(false);
    const [actionSelected, setActionSelected] = useState<String>('');
    const [recluitingTeamSelected, setRecluitingTeamSelected] = useState<String>('');
    const [jobOfferts, setJobOfferts] = useState([]);
    const [jobOffertSelected, setJobOffertSelected] = useState<any>({});

    const pathname = window.location.pathname;
    const currentJobOffert = readLocalStorage('newJobOffert');
    const idJobOffert = currentJobOffert ? currentJobOffert.id : jobOffertSelected?.id

    useEffect(() => {
        getJobOfferts();
    }, []);

    useEffect(() => {
        if(idJobOffert) getRecluitingTeam(idJobOffert);
    }, [idJobOffert]);

    const getRecluitingTeam = async(idJobOffert) => {
        setLoading(true);
        await RecluitingTeamService.getRecluitingTeam(idJobOffert).then((data) => {
            setRecluitingTeam(data.data || []);
            setLoading(false);
        })
    }

    const getJobOfferts = async() => {
        setLoading(true);
        await JobOffertService.getJobOffert().then((data) => {
            const oldData = data.data || [];
            let newData = [];
            oldData.length > 0 && oldData.map((item) => {
                data = {
                    id: item.id,
                    id_company: item.id_company,
                    start_postulation: item.start_postulation,
                    end_postulation: item.end_postulation,
                    n_available_positions: item.n_available_positions,
                    type_of_day: item.type_of_day,
                    minimum_salary: item.profile.minimum_salary,
                    maximum_salary: item.profile.maximum_salary,
                    mode: item.mode,
                    id_profile: item.profile.id,
                    profile: item.profile.name,
                    charge: item.charge.name,
                    id_charge: item.charge.id,
                    specification: item.specification
                }
                newData.push(data);
            });
            setJobOfferts(newData || []);
            if(newData.length > 0) setJobOffertSelected(newData[0]);
            setLoading(false);
        })
    }

    function RecuperarData(value){
        console.log(value);
        setRecluitingTeamSelected(value);
    }

    const mainView = () => {
        return (
            <Protected>
                <Container>
                    <Grid container item xs={3}>
                        <Autocomplete
                            id="disable-portal"
                            disablePortal
                            value={jobOffertSelected}
                            options={jobOfferts || []}
                            getOptionLabel={(option) => option.profile || ""}
                            fullWidth
                            renderInput={(params) => <TextField {...params} variant="standard" label={___PHRASE("filtrarPorPerfil")} />}
                            onChange={(e, newValue) => {  setJobOffertSelected(newValue);  getRecluitingTeam(newValue?.id);}}
                        />                                                                           
                    </Grid>
                    <TableDataV2
                        title={___PHRASE("equipoDeReclutamiento")}
                        data={recluitingTeam || []}
                        header={[
                            // { name: 'id', label: 'Id', filter: false, Chip: false },
                            // { name: 'id_job_offer', label: 'Oferta de trabajo', filter: false, Chip: true },
                            { name: 'role', label: ___PHRASE("rol"), filter: false, Chip: false },
                            { name: 'id_user', label: ___PHRASE("usuarioAsignado"), filter: false, Chip: true },
                        ]}
                        actionSelect={setActionSelected}
                        setModalSave={setShowRecluitingModal}
                        setModalDelete={setShowRecluitingModalDelete}
                        status_action
                        // checkbox
                        RecuperarData={RecuperarData}
                    />
                </Container>
            </Protected>
        )
    };

    const SubView = () => {
        return (
            <>
                <TableDataV2
                    title={___PHRASE("equipoDeReclutamiento")}
                    data={recluitingTeam || []}
                    header={[
                        // { name: 'id', label: 'Id', filter: false, Chip: false },
                        // { name: 'id_job_offer', label: 'Oferta de trabajo', filter: false, Chip: true },
                        { name: 'role', label: ___PHRASE("rol"), filter: false, Chip: false },
                        { name: 'id_user', label: ___PHRASE("usuarioAsignado"), filter: false, Chip: true },
                    ]}
                    actionSelect={setActionSelected}
                    setModalSave={setShowRecluitingModal}
                    setModalDelete={setShowRecluitingModalDelete}
                    status_action
                    // checkbox
                    RecuperarData={RecuperarData}
                />
            </>
        )
    };

    return (
        <>
            {loading && <Loading/>}
            {
                pathname == '/convocatorias' && SubView()
            }
            {
                pathname == '/equipo-reclutamiento' && mainView()
            }
            { 
                showRecluitingModal && (
                    <ModalRecluitingTeam
                        open={showRecluitingModal}
                        closeModal={()=>{setShowRecluitingModal(false)}}
                        actionSelected={actionSelected}
                        onListRecluitingTeam={getRecluitingTeam}
                        recluitingTeam={recluitingTeamSelected}
                        currentJobOffert={currentJobOffert}
                        jobOffertSelected={jobOffertSelected}
                        clearState={()=>{setRecluitingTeamSelected('')}}
                    />
                )
            }
            {
                actionSelected === 'delete' && (
                    <ModalRecluitingTeamDelete
                        open={showRecluitingModalDelete}
                        closeModal={()=>{setShowRecluitingModalDelete(false)}}
                        actionSelected={actionSelected}
                        onListRecluitingTeam={getRecluitingTeam}
                        recluitingTeam={recluitingTeamSelected}
                        clearState={()=>{setRecluitingTeamSelected('')}}
                    />
                )
            }
        </>
    )
}