import { Loading } from "@/components/common/Loading";
// import { Props } from "./PDF.type"
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { Alert, Grid, Snackbar } from "@mui/material";
import { useState } from "react";


export const CertificateEmployeeView: React.FC<any> = (props:any): JSX.Element => {
    // const { pdf, dataRemu, idEmploye } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });
    const data = readLocalStorage('CertificateEmployee');

    const [dataPDF, setDataPDF] = useState<any>(data?.pdf)
    return (
        <>
        {loading && <Loading/>}
        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>
        <Grid container style={{ overflow: 'hidden !important', height:'100%', alignItems: 'center', textAlign: 'center'}}>
             <iframe src={dataPDF} id="pdfVer" height="1000px" width="100%"></iframe>
            
        </Grid>
        </>
    )
}