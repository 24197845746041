import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

const dataUser = readLocalStorage(KEY_USER_DATA);
const rut_empresa = dataUser?.user?.rut_empresa;

export const OnBoardingRepository = {

    getPlantillaStructureExcel: async(): Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/onboarding/generateExcel/plantillaStructure`);
        const {data, error, message} = res;
        return { data, error, message };
    },
    
    uploadPlantillaStructureExcel: async(dataParams): Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/onboarding/uploadExcel/plantillaStructure`, dataParams);
        const {data, error, message} = res;
        return { data, error, message };
    },

    getStructureOnboarding: async(idcompany): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/onboarding/structure?idcompany=${idcompany}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    getPlantillaEmployeeStructureExcel: async(params): Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/onboarding/generateExcel/plantillaEmployeeStructure?year=${params.year}&month=${params.month}&idCompany=${params.idCompany}&idperiod=${params.idperiod}`);
        const {data, error, message} = res;
        return { data, error, message };
    },
    
    uploadPlantillaEmployeeStructureExcel: async(dataParams): Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/onboarding/uploadExcel/plantillaEmployeeStructure`, dataParams);
        const {data, error, message} = res;
        return { data, error, message };
    },

    getStructureEmployeeOnboarding: async(idcompany): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/onboarding/structureEmployee?idcompany=${idcompany}`);
        const {data, error, message} = res;
        return { data, error, message };
    },
   
}   