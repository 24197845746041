import { Formik } from 'formik';
import { useState } from "react";
import { Button, Card, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { Loading } from "@/components/common/Loading";
import { companiesService } from '@/service/services/Companies.service';
import { Props } from './Jornaly.type';
import ___PHRASE from '@/service/translate/translateService';

export const Jornaly: React.FC<Props> = (props:any): JSX.Element => {
    const { dataCompany, onListDataCompany } = props;
   
    const [loading, setLoading] = useState<boolean>(false);

    const [data, setData] = useState({
        id: dataCompany ? dataCompany.idcompany : '',
        hours_jornaly: dataCompany ? dataCompany.hours_jornaly : 45,
    });

    const validateForm = (values) => {
        let errors: any = {};
        let regNum = /^[0-9]+$/;
        if(values.hours_jornaly && !(regNum.test(values.hours_jornaly))) errors.hours_jornaly = ___PHRASE("soloSeAceptanNumerosEnteros");
        if(!values.hours_jornaly) errors.hours_jornaly = ___PHRASE("totalDeHoras") + " " + ___PHRASE("requerido");

        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        const formData = {
            hours_jornaly : values.hours_jornaly,
            idCompany: values.id
        }
        setLoading(true);
        await companiesService.updateHoursJornaly(formData).then((data) => {
            onListDataCompany && onListDataCompany();
        })
        setLoading(false);
    }
    return(
        <>
        {loading && <Loading/>}

        
            <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                    return(
                        <form onSubmit={handleSubmit}>
                            <Card sx={{ width: "100%", marginTop: '20px' }}>
                                <TableContainer>
                                    <Table>
                                        <TableHead sx={{ bgcolor: '#EDEFF1' }}>
                                            <TableRow >
                                                <TableCell >
                                                    <Typography>{___PHRASE("totalHorasSemanales")}</Typography>
                                                </TableCell>
                                                <TableCell >
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>

                                            <TableRow >
                                                <TableCell size={'small'} sx={{ bgcolor: '#F4F6F8', width: '30%' }}>
                                                {___PHRASE("totalHorasSemanales")}
                                                </TableCell>
                                                <TableCell >
                                                    <TextField
                                                        id="hours_jornaly"
                                                        variant="standard"
                                                        type="number" 
                                                        name="hours_jornaly"
                                                        multiline
                                                        maxRows={4}
                                                        fullWidth
                                                        value={values.hours_jornaly}
                                                        onChange={handleChange}
                                                        placeholder={"horas semanales"}
                                                        error={errors.hours_jornaly && touched.hours_jornaly ? true : false}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Grid sx={{ textAlign: 'center', margin: '10px' }}>
                                    <Typography variant="subtitle2" sx={{marginTop:'10px', marginBottom:'10px', color:'#e37474'}}> {___PHRASE("esPorDefectoElNumeroDeHorasSemanales")}</Typography>
                                    <Button color="primary" variant="contained" onClick={() => {handleSubmit()}}>
                                        {___PHRASE("actualizar")}
                                    </Button>
                                </Grid>
                            </Card>
                        </form>
                    )
                }}
            </Formik>
        </>
    );
}