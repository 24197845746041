import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

const dataUser = readLocalStorage(KEY_USER_DATA);
const rut_empresa = dataUser?.user?.rut_empresa;

export const CountryRepository = {

    getCountries: async(): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/country`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    getDepartments: async(idCountry: number, search = ''): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/country/department/${idCountry}?search=${search}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    getProvinces: async(idDepartment: number, search = ''): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/country/province/${idDepartment}?search=${search}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    getDistricts: async(idProvince: number): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/country/district/${idProvince}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    findCountry: async(id: number): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/country/findCountry/${id}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    findDepartment: async(id: number): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/country/findDepartment/${id}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    findProvince: async(id: number): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/country/findProvince/${id}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    findDistrict: async(id: number): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/country/findDistrict/${id}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    getTimeZone: async(): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/country/time-zone`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    createCountry: async(dataCountry): Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/country/create-country`, {
            code : dataCountry.code,
            abbreviation : dataCountry.abbreviation,
            name : dataCountry.name,
            nationality : dataCountry.nationality,
            language : dataCountry.language,
            coin : dataCountry.coin,
            coin_code : dataCountry.coin_code,
            idtimezone : dataCountry.idtimezone,
            state : dataCountry.state,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    createDepartament: async(dataCountry): Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/country/create-departament`, {
            code : dataCountry.code,
            name : dataCountry.name,
            idcountry : dataCountry.idcountry,
            idtimezone : dataCountry.idtimezone,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    createProvince: async(dataCountry): Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/country/create-province`, {
            code : dataCountry.code,
            name : dataCountry.name,
            iddepartment : dataCountry.iddepartment,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    createDistrict: async(dataCountry): Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/country/create-district`, {
            code : dataCountry.code,
            name : dataCountry.name,
            idprovince : dataCountry.idprovince,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    editCountry: async(dataCountry, id: number): Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/country/update-country/${id}`, {
            code : dataCountry.code,
            abbreviation : dataCountry.abbreviation,
            name : dataCountry.name,
            nationality : dataCountry.nationality,
            language : dataCountry.language,
            coin : dataCountry.coin,
            coin_code : dataCountry.coin_code,
            idtimezone : dataCountry.idtimezone,
            state : dataCountry.state,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    editDepartament: async(dataCountry, id: number): Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/country/update-departament/${id}`, {
            code : dataCountry.code,
            name : dataCountry.name,
            idcountry : dataCountry.idcountry,
            idtimezone : dataCountry.idtimezone,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    editProvince: async(dataCountry, id: number): Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/country/update-province/${id}`, {
            code : dataCountry.code,
            name : dataCountry.name,
            iddepartment : dataCountry.iddepartment,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    editDistrict: async(dataCountry, id: number): Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/country/update-district/${id}`, {
            code : dataCountry.code,
            name : dataCountry.name,
            idprovince : dataCountry.idprovince,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    deleteCountry: async(id:number): Promise<any> => {
        const res= await http.delete<any>(`${API_URL_BASE}/v1/country/delete-country/${id}`)
        return res;
    },

    deleteDepartament: async(id:number): Promise<any> => {
        const res= await http.delete<any>(`${API_URL_BASE}/v1/country/delete-departament/${id}`)
        return res;
    },

    deleteProvince: async(id:number): Promise<any> => {
        const res= await http.delete<any>(`${API_URL_BASE}/v1/country/delete-province/${id}`)
        return res;
    },

    deleteDistrict: async(id:number): Promise<any> => {
        const res= await http.delete<any>(`${API_URL_BASE}/v1/country/delete-district/${id}`)
        return res;
    },

}  