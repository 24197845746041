import React, { useEffect, useState } from 'react';
import { TableBody, TableCell, TableContainer, Typography, TablePagination, TableRow, Grid, Table, Button, Container, Card, Checkbox, Avatar, IconButton, Popover, MenuItem, ButtonGroup, Tooltip, TextField, InputAdornment, styled } from '@mui/material';
import { useHistory } from 'react-router-dom'
import ListHeader from './ListHeader';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { Box } from "@mui/material";
import { readLocalStorage, removeLocalStorage, saveLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import FeedIcon from '@mui/icons-material/Feed';
import { ROUTE_ANNOUNCEMENT, ROUTE_LIQUIDATION_SALARY, ROUTE_EXTRA_HOURS, ROUTE_ASSISTANCES, ROUTE_ARREARS, ROUTE_ADVANCES, ROUTE_ACTIVE_LIQUIDATION_WORKERS, ROUTE_LIQUIDATION_MASIVE_PREVIEW, ROUTE_MOVEMENTS, ROUTE_REPORT_EXPENS, ROUTE_EXPENSE_REQUESTS, ROUTE_EMPLOYEE_EXPENSE, ROUTE_USER, ROUTE_PROJECT, ROUTE_DEPARMENT, ROUTE_CHARGES } from '@/toolbox/constants/route-map';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import WorkIcon from '@mui/icons-material/Work';
import VisibilityIcon from '@mui/icons-material/Visibility';
import WifiProtectedSetupIcon from '@mui/icons-material/WifiProtectedSetup';
import MovingIcon from '@mui/icons-material/Moving';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SettingsIcon from '@mui/icons-material/Settings';
import SearchIcon from '@mui/icons-material/Search'
import ___PHRASE from '@/service/translate/translateService';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { KEY_TRANSLATE } from '@/toolbox/constants/local-storage';

type TableProps = {
   header?: Array<any>,
   data?: Array<any>,
   setData?: any,
   action?: Array<any>,
   RecuperarData?: (data) => void,
   Recuperarid?: (data) => void,
   perPage?: (perPage) => void,
   page?: (page) => void,
   actionSelect?: any,
   total?: any,
   setAddOpen?: any,
   colorHeader?: any,
   status_action?: boolean,
   title?: any,
   text_button_add?:any,
   text_eliminar?:any,
   checkbox?: boolean,
   setModalSave?:any,
   setModalDelete?:any,
   setModalHelper?:any,
   setShowSpecification?: any,
   setShowTeam?: any,
   setView?: any,
   currentView?: any,
   disabled_title?: any,
   btn_disabled?:any,
   btn_edit_disable?:any,
   btn_delete_disable?:any,
   btn_detail?:any,
   btn_helper?:any,
   iconBtnHelper?:any,
   toolTipBtnHelper?:any,
   btn_foolder?:any,
   btn_add_person?:any,
   btn_reprocess?:any,
   btn_redirect?:any,
   btn_contract?:any,
   btn_download?:any,
   btn_detail_view?:any,
   btn_view_document?:any,
   title_btn_custom?:any,
   btn_settings?:any,
   setModalSettings?:any,
   btn_isIntegrationZkteco?:any,
   SyncEmployee?:any,
   textSearch?:any,
   searchData?: (data) => void,
   setModalMaxLoan?: any,
   btn_validateMaxLoan?: any,
   btn_validateRequestLoan?: any,
   setModalRequestLoan?:any,
   btn_feeLoan?:any,
   setModalFeeLoan?:any,
   setModalFeeLoanDetail?:any,
   btn_feeLoanDetail?:any,
   note?:any,
   status_disabled?:any,
   note_severity?:any,
   bg_header?: any,
   color_text_header?:any,
   rows_per_page?:any,
   sticky_column?:any,
   setPageInitial?:any,
   pageInitial?:any
}

const Div = styled('div')(({ theme }) => ({
   ...theme.typography.button,
   padding: theme.spacing(1),
   fontWeight: 'bold',
   fontSize: '20px',
   color: 'rgba(102, 102, 102, 0.4)',
}));

export const TableDataV2: React.FC<TableProps> = (props: TableProps): JSX.Element => {

   const history = useHistory();
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(props.rows_per_page ? props.rows_per_page : 5);
   const [order, setOrder] = useState('asc');
   const [selected, setSelected] = useState<any>([]);
   const [orderBy, setOrderBy] = useState('');
   const [stateRender, setStateRender] = useState<boolean>(false);
   const [search, setSearch] = useState('');
   const [loading, setLoading] = useState<boolean>(false);
   const language = readLocalStorage(KEY_TRANSLATE);

   const pathname = window.location.pathname;

   useEffect(() => {
      if(pathname != `${ROUTE_ANNOUNCEMENT}`) removeLocalStorage('newJobOffert');
   },[])

   const nextPage = (toroute, row) => {
      history.push(toroute, row);
   };

   const handleChangePage = (event, newPage) => {
      setPage(newPage);
      props.setPageInitial && props.setPageInitial(false)
      if (props.page) {
         props.page(newPage);
      }

   };
   //console.log(props.pageInitial);
   useEffect(()=>{
      //console.log(props.pageInitial);
      if(props.pageInitial){
         setPage(0);
      }
   },[props.pageInitial])

   const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      if (props.perPage) {
         props.perPage(+event.target.value)
      }

      setPage(0);
   };

   const getTitleTooltip = () => {
      const pathname = window.location.pathname;
      switch (pathname) {
         case ROUTE_EXTRA_HOURS:
            return ___PHRASE("verHorasExtras");
         case ROUTE_LIQUIDATION_SALARY:
            return ___PHRASE("crearLiquidacion");
         case ROUTE_ASSISTANCES:
            return ___PHRASE("verInasistencia");
         case ROUTE_ARREARS:
            return ___PHRASE("verAtrasos");
         case ROUTE_ADVANCES:
            return ___PHRASE("verAnticipos");
         case ROUTE_ACTIVE_LIQUIDATION_WORKERS:
            return ___PHRASE("verEstructuras");
         case ROUTE_LIQUIDATION_MASIVE_PREVIEW:
            return ___PHRASE("verDetalle");
         case ROUTE_MOVEMENTS:
            return ___PHRASE("verMovimientos");
         case ROUTE_EXPENSE_REQUESTS || ROUTE_EMPLOYEE_EXPENSE:
         case ROUTE_EMPLOYEE_EXPENSE:
            return ___PHRASE("verDetalleDelReporte");
         case ROUTE_USER:
            return ___PHRASE("verEmpresasALaQuePertenece");
         case ROUTE_PROJECT:
            return ___PHRASE("detalles");
         case ROUTE_DEPARMENT:
            return ___PHRASE("verResponsabilidades");
         case ROUTE_CHARGES:
            return ___PHRASE("verFunciones");
         default:
            return ___PHRASE("verDetalles")
      }
   }

   const changeLanguageText = (value) => {
      switch(value){
         case 'Inasistencia':
            return ___PHRASE("inasistencia")
         case 'Licencia':
            return ___PHRASE("licencia")
         case 'Descuento Liquidación':
            return ___PHRASE("descuentoLiquidacion")
         case 'Descuento Vacaciones':
            return ___PHRASE("descuentoVacaciones")
         case 'cantidad':
            return ___PHRASE("cantidad")
         case 'porcentaje':
            return ___PHRASE("porcentaje")
         case 'Sin sucursal':
            return ___PHRASE("sinSucursal")
         case 'Sin nombre':
            return ___PHRASE("sinNombre")
         case 'Sí':
            return ___PHRASE("si")
         case 'Si':
            return ___PHRASE("si")
         case 'No':
            return ___PHRASE("no")
         case 'Pendiente':
            return ___PHRASE("pendiente")
         case 'Eliminado':
            return ___PHRASE("eliminado")
         case 'Rechazado':
            return ___PHRASE("rechazado")
         case 'ACTIVADO':
            return ___PHRASE("activado")
         case 'DESACTIVADO':
            return ___PHRASE("eliminado")
         case 'Sin documento':
            return ___PHRASE("sinDocumento")
         case 'Aceptado':
            return ___PHRASE("aceptado")
         default:
            return value
      }
   }

   const handleCheck = ( id ) => {
      const updateRows = props.data.map((row) => {
         if(row.id == id){
            row.active = !row.active
         }
         return row
      })
      props.setData(updateRows)
      setSelected(updateRows.filter(item=>(item.active==true)))
   }

   const handleSelectAllClick = (event) => {

      let value =  true;

     const dataFilter =  (props.data).filter(item => (item.active == true));
     const lengthData = (props.data).length;
      if(dataFilter.length == lengthData){
         value = false;
      }

      const updateRows = props.data.map((row) => {
         row.active = value;
         return row;
      });

      const filter = updateRows.filter(item => (item.active == true))

      setSelected(filter)
      props.setData(updateRows)
      setStateRender(!stateRender)
   };

   // const handleClick = (event, name) => {
   //    const selectedIndex = selected.indexOf(name);
   //    let newSelected = [];
   //    if (selectedIndex === -1) {
   //       newSelected = newSelected.concat(selected, name);
   //    } else if (selectedIndex === 0) {
   //       newSelected = newSelected.concat(selected.slice(1));
   //    } else if (selectedIndex === selected.length - 1) {
   //       newSelected = newSelected.concat(selected.slice(0, -1));
   //    } else if (selectedIndex > 0) {
   //       newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
   //    }
   //    setSelected(newSelected);
   // };

   // ----------------------------------------------------------------------

   function descendingComparator(a, b, orderBy) {
      if (b[orderBy] < a[orderBy]) {
         return -1;
      }
      if (b[orderBy] > a[orderBy]) {
         return 1;
      }
      return 0;
   }

   function getComparator(order, orderBy) {
      return order === 'desc'
         ? (a, b) => descendingComparator(a, b, orderBy)
         : (a, b) => -descendingComparator(a, b, orderBy);
   }

   function applySortFilter(array, comparator) {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
         const order = comparator(a[0], b[0]);
         if (order !== 0) return order;
         return a[1] - b[1];
      });
      return stabilizedThis.map((el) => el[0]);
   }
   
   const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
   };   

   const filteredData = applySortFilter(props.data, getComparator(order, orderBy));
   let recorrido = [];
   if (props.perPage || props.page) {
      recorrido = filteredData;
   } else {
      recorrido = filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
   }

   const tablecontainer = <Card sx={{width:"100%"}}>
      <TableContainer>
         <Table>
            <ListHeader
               order={order}
               orderBy={orderBy}
               headLabel={props.header}
               rowCount={props.data.length}
               numSelected={selected.length}
               onRequestSort={handleRequestSort}
               onSelectAllClick={handleSelectAllClick}
               status_action={props.status_action}
               checkbox={props.checkbox}
               bg_header={props.bg_header}
               color_text_header={props.color_text_header}
               sticky_column={props.sticky_column}
            />
            <TableBody sx={{position:'relative'}}>
               {
                  recorrido.map((data, id_data) => {
                     const { id, name } = data;
                     const selectedItem = selected.indexOf(data) !== -1;
                     return (
                        <TableRow hover key={id} tabIndex={-1} role="checkbox">
                           { props.status_action && (
                              <TableCell align="center" sx={{position:'sticky', left:0, background:'white', zIndex:10}} >
                                 <ButtonGroup variant="contained" color="inherit" aria-label="button-group-custom-table" style={{padding: "0px 5px"}}>
                                    { 
                                       ! props?.btn_edit_disable && (
                                          <Tooltip title={___PHRASE("editar")}>
                                             <IconButton size="small" color="success" aria-label="view" 
                                                onClick={() => {
                                                   props.setModalSave && props.setModalSave(true);
                                                   props.RecuperarData && props.RecuperarData({...data, action:'edit'}); 
                                                   props.actionSelect && props.actionSelect('edit');
                                                }}
                                                disabled={!props?.status_disabled ? false : props?.status_disabled(data)}
                                             >
                                                <EditIcon fontSize='small' />
                                             </IconButton>
                                          </Tooltip>
                                       )
                                    }
                                    { 
                                       props?.btn_detail && (
                                          <Tooltip title={___PHRASE("ver")}>
                                             <IconButton size="small" color="primary" aria-label="view" 
                                                onClick={() => {
                                                   props.setModalSave && props.setModalSave(true);
                                                   props.RecuperarData && props.RecuperarData({...data, action:'detail'}); 
                                                   props.actionSelect && props.actionSelect('detail');
                                                }}
                                             >
                                                <VisibilityIcon fontSize='small' />
                                             </IconButton>
                                          </Tooltip>
                                       )
                                    }

                                    {
                                       (pathname == `${ROUTE_ANNOUNCEMENT}` && props.currentView == 'jobOfert') && (
                                          <Tooltip title={___PHRASE("verEspecificaciones")}>
                                             <IconButton size="small" color="primary" aria-label="view"
                                                onClick={() => {
                                                   saveLocalStorage('newJobOffert', data);
                                                   props.actionSelect && props.actionSelect('especification')
                                                   props.setView && props.setView('specificOfert')
                                                   props.setShowSpecification && props.setShowSpecification(true);
                                                   props.setShowTeam && props.setShowTeam(true);
                                                }}>
                                                   <FeedIcon fontSize="small" />
                                                </IconButton>
                                          </Tooltip>
                                       )
                                    }

                                    {
                                       (props?.btn_helper) && (
                                          <Tooltip title={props?.toolTipBtnHelper ? props?.toolTipBtnHelper : getTitleTooltip()}
                                          >
                                             <IconButton size="small" color="primary" aria-label="view"
                                                onClick={() => {
                                                   props.RecuperarData({...data, action:'helper'}); 
                                                   props.actionSelect && props.actionSelect('helper');
                                                   props.setModalHelper && props.setModalHelper(true); 
                                                }}>
                                                   {props?.iconBtnHelper ? props?.iconBtnHelper : <FeedIcon fontSize="small" />}
                                                </IconButton>
                                          </Tooltip>
                                       )
                                    }

                                    {
                                       (props?.btn_detail_view) && (
                                          <Tooltip title={getTitleTooltip()}
                                          >
                                             <IconButton size="small" color="primary" aria-label="view"
                                                onClick={() => {
                                                   props.RecuperarData({...data, action:'view-detail'}); 
                                                }}>
                                                   <VisibilityIcon fontSize="small" />
                                                </IconButton>
                                          </Tooltip>
                                       )
                                    }

                                 {
                                       !props?.btn_delete_disable && (
                                          <Tooltip title={___PHRASE("eliminar")}>
                                             <IconButton size="small" color="error" aria-label="view" 
                                                onClick={() => { 
                                                   props.RecuperarData && props.RecuperarData({...data,action:'delete'});
                                                   props.actionSelect && props.actionSelect('delete');
                                                   props.setModalDelete && props.setModalDelete(true); 
                                                }}
                                             >
                                                <DeleteOutlineIcon fontSize='small' />
                                             </IconButton>
                                          </Tooltip>
                                       )
                                    }

                                    {
                                       props?.btn_add_person &&  (
                                          <Tooltip title={___PHRASE("cargasFamiliares")}>
                                             <IconButton size="small" color="secondary" aria-label="view" 
                                                onClick={() => { 
                                                   props.RecuperarData && props.RecuperarData({...data,action:'add_person'});
                                                   props.actionSelect && props.actionSelect('add_person');
                                                   props.setModalDelete && props.setModalDelete(true); 
                                                }}
                                             >
                                                <GroupAddIcon fontSize='small'/>
                                             </IconButton>
                                          </Tooltip>
                                       )
                                    }

                                    {
                                       props?.btn_foolder &&  (
                                          <Tooltip title={___PHRASE("historialLaboral")}>
                                             <IconButton size="small" color="primary" aria-label="view" 
                                                onClick={() => { 
                                                   props.RecuperarData && props.RecuperarData({...data,action:'folder'});
                                                   props.actionSelect && props.actionSelect('folder');
                                                   props.setModalDelete && props.setModalDelete(true); 
                                                }}
                                             >
                                                <WorkIcon fontSize='small' />
                                             </IconButton>
                                          </Tooltip>
                                       )
                                    }

                                    {
                                       props?.btn_contract &&  (
                                          <Tooltip title={___PHRASE("verContrato")}>
                                             <IconButton size="small" color="primary" aria-label="view" 
                                                onClick={() => { 
                                                   props.RecuperarData && props.RecuperarData({...data,action:'contract'});
                                                   props.actionSelect && props.actionSelect('contract');
                                                }}
                                             >
                                                <FilePresentIcon fontSize='small' />
                                             </IconButton>
                                          </Tooltip>
                                       )
                                    }

                                    {
                                       props?.btn_download &&  (
                                          <Tooltip title={___PHRASE("descargar")}>
                                             <IconButton size="small" color="primary" aria-label="view" 
                                                onClick={() => { 
                                                   props.RecuperarData && props.RecuperarData({...data,action:'download'});
                                                   props.actionSelect && props.actionSelect('download');
                                                }}
                                             >
                                                <FileDownloadIcon fontSize='small' />
                                             </IconButton>
                                          </Tooltip>
                                       )
                                    }

                                    {
                                       props?.btn_reprocess &&  (
                                          <Tooltip title={___PHRASE("reprocesarLiquidacion")}>
                                             <IconButton size="small" color="success" aria-label="view" 
                                                onClick={() => { 
                                                   props.RecuperarData && props.RecuperarData({...data,action:'reprocess'});
                                                   props.actionSelect && props.actionSelect('reprocess');
                                                   props.setModalDelete && props.setModalDelete(true); 
                                                }}
                                             >
                                                <WifiProtectedSetupIcon fontSize='small' />
                                             </IconButton>
                                          </Tooltip>
                                       )
                                    }

                                    {
                                       props?.btn_redirect &&  (
                                          <Tooltip title={___PHRASE("redirigir")}>
                                             <IconButton size="small" color="success" aria-label="view" 
                                                onClick={() => { 
                                                   props.RecuperarData && props.RecuperarData({...data,action:'redirect'});
                                                   props.actionSelect && props.actionSelect('redirect');
                                                   props.setModalDelete && props.setModalDelete(true); 
                                                }}
                                             >
                                                <MovingIcon fontSize='small' />
                                             </IconButton>
                                          </Tooltip>
                                       )
                                    }
                                    {
                                       props?.btn_view_document &&  (
                                          <Tooltip title={___PHRASE("verDocumento")}>
                                             <IconButton size="small" color="success" aria-label="view" 
                                                onClick={() => { 
                                                   props.RecuperarData && props.RecuperarData({...data,action:'view_document'});
                                                }}
                                             >
                                                <PictureAsPdfIcon fontSize='small' />
                                             </IconButton>
                                          </Tooltip>
                                       )
                                    }

                                    { 
                                       props?.btn_settings && (
                                          <IconButton size="small" color="inherit" aria-label="view" 
                                             onClick={() => {
                                                props.setModalSettings && props.setModalSettings(true);
                                                props.RecuperarData && props.RecuperarData({...data, action:'settings'}); 
                                                props.actionSelect && props.actionSelect('settings');
                                             }}
                                          >
                                             <SettingsIcon fontSize='small' />
                                          </IconButton>
                                       )
                                    }
                                    { 
                                       props?.btn_feeLoan && (
                                          <Tooltip title='Mantenedor de Cuotas'>
                                             <IconButton size="small" color="primary" aria-label="view"
                                                onClick={() => {
                                                   props.setModalFeeLoan && props.setModalFeeLoan(true);
                                                   props.RecuperarData && props.RecuperarData({ ...data, action: 'feeLoan' });
                                                   props.actionSelect && props.actionSelect('feeLoan');
                                                }}
                                             >
                                                <SettingsIcon fontSize='small' />
                                             </IconButton>
                                          </Tooltip>
                                       )
                                    }
                                    { 
                                       props?.btn_feeLoanDetail && (
                                          <Tooltip title={___PHRASE("verCuotas")}>
                                             <IconButton size="small" color="primary" aria-label="view"
                                                onClick={() => {
                                                   props.setModalFeeLoanDetail && props.setModalFeeLoanDetail(true);
                                                   props.RecuperarData && props.RecuperarData({ ...data, action: 'feeLoanDetail' });
                                                   props.actionSelect && props.actionSelect('feeLoanDetail');
                                                }}
                                             >
                                                <VisibilityIcon fontSize='small' />
                                             </IconButton>
                                          </Tooltip>
                                       )
                                    }
                                 </ButtonGroup>
                              </TableCell>
                           )}

                           {props.header.map((cabecera, id) => {
                              const value = data[cabecera.name];
                              const sticky_column = props?.sticky_column && props?.sticky_column == cabecera.name;
                              return (
                                 <TableCell key={id} align="center" 
                                    sx={{position: `${sticky_column ? 'sticky' : ''}` , left:`${sticky_column ? 0 : ''}`, 
                                       background:`${sticky_column ? 'white' : ''}`, zIndex:`${sticky_column ? 10 : ''}`}}>
                                    <Grid container direction="row"
                                       justifyContent="center"
                                       alignItems="center">
                                       <Grid sx={{ pr: 1 }}>
                                          {cabecera.avatar &&
                                             <Avatar
                                                alt="Remy Sharp"
                                                src="http://view.k-nela.cl/assets/images/avatars/avatar_8.jpg"
                                                sx={{ width: 30, height: 30 }}
                                             />
                                          }
                                       </Grid>
                                       <Grid sx={{ pl: 1 }}>
                                          <Typography sx={{ fontSize:12 }} variant='inherit' fontWeight={500}>
                                             {changeLanguageText(value) || '---' }
                                          </Typography>
                                       </Grid>
                                    </Grid>
                                 </TableCell>
                              )
                           })}

                           {/* {
                              props.checkbox && (
                                 <TableCell padding="checkbox">
                                    <Checkbox
                                    color="primary"
                                    checked={selectedItem}
                                    // inputProps={{
                                    //    'aria-labelledby': {name},
                                    // }}
                                    />
                                 </TableCell>
                              )
                           } */}
                           {
                              props.checkbox && (
                                 <TableCell padding="checkbox">
                                 <Checkbox
                                 color="primary"
                                 checked={data.active}
                                 onChange={()=> handleCheck(data.id)}
                                 // inputProps={{
                                 //    'aria-labelledby': {name},
                                 // }}
                                 />
                              </TableCell>
                              )
                           }
                        </TableRow>
                     );
                  })}
            </TableBody>
         </Table>
      </TableContainer>
      <TablePagination
         component="div"
         count={props.total ? props.total : props.data.length}
         page={page}
         rowsPerPage={rowsPerPage}
         rowsPerPageOptions={ [2, 5, 10, 15] }
         onPageChange={handleChangePage}
         onRowsPerPageChange={handleChangeRowsPerPage}
         labelRowsPerPage={language && language == 'es' ? 'Filas por página' : 'Rows per page'}
      />
</Card>

   const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value
      if (value.length > 3)
      {
        setPage(0);
        props?.searchData && props.searchData(value)
      }
      if (value.length==0)
      {
         setPage(0);
         props?.searchData && props.searchData(value)
      }
      setSearch(value)
   }

   return (
      <>
         <Container>
            <Box
               sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', m: -1, py: !props?.btn_disabled? 3:0 }}
            >
               {
                  !props?.disabled_title && (
                     <Typography variant='h6' component='h1' gutterBottom className="tittle" sx={{ mt: 1, ml:1 , fontWeight:600}}>
                        {props.title && (props.title).toUpperCase()}
                     </Typography>
                  )
               }
               {
                  props?.note && (
                     <Grid item xs={12} container alignItems="center" marginBottom='20px'>
                        <Typography variant="subtitle2" className="custom-input" sx={{
                           border: `1px ${( props?.note_severity == 'warning' ? '#e37474' : (props?.note_severity == 'info' ? '#81a4cb' : '#999595'))} solid`, 
                           borderRadius:'8px', 
                           padding: '5px', 
                           color:( props?.note_severity == 'warning' ? '#df5959' : (props?.note_severity == 'info' ? '#707ac3' : '#605f5f')) }}
                        >
                           {props?.note}
                        </Typography>
                     </Grid>
                  )
               }
               <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                  {
                  props.searchData ?
                  <Grid item xs={6} sm={6} sx={{ m: 1, p: 1 }}>
                     <TextField
                        InputProps={{
                        startAdornment: (
                           <InputAdornment position="start">
                              <SearchIcon fontSize="small" />
                           </InputAdornment>
                        )
                        }}
                        sx={{width:350}}
                        onChange={handleSearch}
                        placeholder={props?.textSearch ? props.textSearch : ___PHRASE("buscarTrabajador") }
                        value={search}
                        size="small"
                     />
                  </Grid>
                  : <Grid item xs={6} sm={6} sx={{ m: 1, p: 1 }}>
                     </Grid>
                     }
                  <Box sx={{ m: 1, p: 1 }}>

                     { props.btn_isIntegrationZkteco && <Button
                        color="secondary"
                        variant="contained"
                        sx={{ mr: 3, width: '150px' }}
                        onClick={()=>{props.SyncEmployee()}}
                     > {___PHRASE("sincronizarEmpleados")}</Button>}

                     {!props?.btn_disabled &&<Button
                        // color="primary"
                        variant="contained"
                        onClick={()=>{
                              props.setModalSave && props.setModalSave(true); 
                              props.actionSelect && props.actionSelect('save'); 
                        }}
                        sx={{background:'#516E80', mt: "10px",color:'#fff', '&:hover':{
                           bgcolor:'#516E80'
                          }}}
                     >
                     {props?.title_btn_custom ? props.title_btn_custom  : ___PHRASE("agregar")}
                     </Button>}

                     { props.btn_validateMaxLoan && <Button
                        color="secondary"
                        variant="contained"
                        sx={{ mr: 3, width: '150px' }}
                        onClick={()=>{props.setModalMaxLoan(true)}}
                     > {___PHRASE("habilitarPrestamo")}</Button>}

                  { props.btn_validateRequestLoan && <Button
                        variant="contained"
                        sx={{background:'#007ea7', mt: "10px",color:'#fff', '&:hover':{
                           bgcolor:'#007ea7'
                          }}}
                        onClick={()=>{props.setModalRequestLoan(true)}}
                     > {___PHRASE("solicitarPrestamo")}</Button>}
                  </Box>
               </Grid>
            </Box>
            {
               stateRender ? tablecontainer : tablecontainer
            }

         </Container>
      </>
   );
}
