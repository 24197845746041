import React, { useEffect, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Container, Snackbar, Alert } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { Loading } from '@/components/common/Loading';
import { Props } from './Politics.type';
import { PoliticService } from '@/service/services/Politic.service';
import { ModalPolitics } from '@/components/Modal/ModalPolitics/ModalPolitics';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_RUTA, KEY_SUB_RUTA, KEY_TRANSLATE, KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { ROLE_ADMIN, ROLE_SUPER_ADMIN, ROLE_TRABAJADOR } from '@/toolbox/defaults/static-roles';
import { API_URL_BASE } from '@/toolbox/defaults/app';
import ___PHRASE from '@/service/translate/translateService';
import { ModalPoliticsDelete } from '@/components/Modal/ModalPoliticsDelete';
import { apiResponseTranslate } from '@/toolbox/helpers/responseApi.helper';

export const PoliticsView: React.FC<Props> = (props:any): JSX.Element => {

   const [loading, setLoading] = useState<boolean>(false);
   const [politics, setPolitics] = useState([]);
   const [showPoliticsModal, setShowPoliticsModal] = useState<boolean>(false);
   const [showPoliticsDeleteModal, setShowPoliticsDeleteModal] = useState<boolean>(false);
   const [actionSelected, setActionSelected] = useState<String>('');
   const [politicsSelected, setPoliticsSelected] = useState<String>('');
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
   })

   const language = readLocalStorage(KEY_TRANSLATE);
   const route = readLocalStorage(KEY_RUTA)
   const subRuta = readLocalStorage(KEY_SUB_RUTA);
   console.log(subRuta)
   const userData = readLocalStorage(KEY_USER_DATA)
   const role = userData.user.role
   const typeSuperadmin = userData?.user?.type_superadmin;

   useEffect(() => {
    getPoliticsDocuments()
   },[route]);

   const getPoliticsDocuments = async () => {
      setLoading(true);
      let type;
      if(role == ROLE_SUPER_ADMIN){type = '1'}
      if(role == ROLE_ADMIN){
         if( subRuta == '/politicas-privacidad'){type = '1'}else{type = '0'}
      }
      if(role == ROLE_TRABAJADOR){type = '0'}
     
      const response = await PoliticService.getDocuments(type);
      const newData = [];

      response.data && (response.data).length > 0 && (response.data).map((item)=>{
         if(language && language == 'en'){
            newData.push({...item, name: apiResponseTranslate(item.name)})
         }else{
            newData.push(item)
         }
      })

      setPolitics(newData);

      setLoading(false);
   }

   function RecuperarData(value){
      
      setPoliticsSelected(value);
      if(value.action == 'view_document'){
         const btn_ahref = document.createElement("a")
         btn_ahref.href = `${API_URL_BASE}/${value.route}`
         btn_ahref.target = '_blank'
         document.body.appendChild(btn_ahref);
         btn_ahref.click();
         document.body.removeChild(btn_ahref)
      }

      if(value.action == 'delete'){

      }
   }

   const getSnackBar = (severity, message) => {
      setSnackBarConfig(prev => ({
         ...prev, open: true,
         severity: severity,
         message: message
         }
      ));
   }

  return (
      <>
      <Snackbar
         open={snackBarConfig.open}
         autoHideDuration={snackBarConfig.autoHideDuration}
         onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
         anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
         <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
            {snackBarConfig.message || ""}
         </Alert>
      </Snackbar>
      <Protected>
         {loading && <Loading/>}
         <Container>
           { role == ROLE_TRABAJADOR &&
            <TableDataV2
               title={___PHRASE("politicasyPrivacidad")}
               data={politics}
               header={[
                  { name: 'name', label: ___PHRASE("nombre"), filter: false, Chip: true },
               ]}
               actionSelect={setActionSelected}
               setModalSave={setShowPoliticsModal}
               setModalDelete={setShowPoliticsDeleteModal}
               btn_delete_disable={true}
               btn_edit_disable
               btn_disabled
               btn_view_document
               status_action
               // checkbox
               RecuperarData={RecuperarData}
            />
         }
         {
            role == ROLE_SUPER_ADMIN && 
            <TableDataV2
            title={___PHRASE("politicasyPrivacidad")}
            data={politics}
            header={[
               { name: 'name', label: ___PHRASE("nombre"), filter: false, Chip: true },
            ]}
            actionSelect={setActionSelected}
            setModalSave={setShowPoliticsModal}
            setModalDelete={setShowPoliticsDeleteModal}
            status_action={typeSuperadmin == '1' ? true : false}
            btn_disabled={typeSuperadmin == '1' ? false : true}
            // checkbox
            RecuperarData={RecuperarData}
         />
         }

         {
            (role == ROLE_ADMIN && subRuta == '/politicas-privacidad' ) &&
            <TableDataV2
            title={___PHRASE("politicasyPrivacidadDelSoftware")}
            data={politics}
            header={[
               { name: 'name', label: ___PHRASE("nombre"), filter: false, Chip: true },
            ]}
            actionSelect={setActionSelected}
            setModalSave={setShowPoliticsModal}
            setModalDelete={setShowPoliticsDeleteModal}
            btn_delete_disable={true}
            btn_edit_disable
            btn_disabled
            btn_view_document
            status_action
            // checkbox
            RecuperarData={RecuperarData}
         />
         }

{
            (role == ROLE_ADMIN && subRuta != '/politicas-privacidad' ) &&
            <TableDataV2
            title={___PHRASE("politicasyPrivacidadDeLaEmpresa")}
            data={politics}
            header={[
               { name: 'name', label: ___PHRASE("nombre"), filter: false, Chip: true },
            ]}
            actionSelect={setActionSelected}
            setModalSave={setShowPoliticsModal}
            setModalDelete={setShowPoliticsDeleteModal}
            btn_delete_disable={true}
            status_action
            // checkbox
            RecuperarData={RecuperarData}
         />
         }
         
         </Container>

         { 
            showPoliticsModal && (
               <ModalPolitics
                  open={showPoliticsModal}
                  closeModal={()=>{setShowPoliticsModal(false)}}
                  actionSelected={actionSelected}
                  onListPolitics={getPoliticsDocuments}
                  politic={politicsSelected}
                  clearState={()=>{setPoliticsSelected('')}}
                  getSnackBar={getSnackBar}
               />
            )
         }

         {
            setShowPoliticsDeleteModal && (
              <ModalPoliticsDelete 
                  open={showPoliticsDeleteModal}
                  closeModal={()=>setShowPoliticsDeleteModal(false)}
                  onList={getPoliticsDocuments}
                  politicSelected={politicsSelected}
                  clearState={()=>setPoliticsSelected('')}
              /> 
            )
         }
      
      </Protected>
      </>
   )
};