import { Divider, Grid, Pagination, Typography, Container, Snackbar ,Alert } from '@mui/material'
import { useEffect, useState } from 'react'
import { notificationService } from "@/service/services/Notifications.service";
import { Protected } from '@components/layout/Protected';
import { Loading } from "@components/common/Loading";
import { createTheme, ThemeProvider } from '@mui/material/styles'
import moment from 'moment'
import 'moment/locale/es'
import { OptionPopover } from './components/OptionPopover';
import ___PHRASE from '@/service/translate/translateService';
import { apiResponseTranslate } from '@/toolbox/helpers/responseApi.helper';
moment.locale('es')

export const NotificationView: React.FC = (props: any): JSX.Element => {
  // PAGINATION
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [notifications, setNotifications] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    };

    useEffect(() => {
      console.log(props.$store.NotificationReducer.notifications)
      getNotifyView()
    }, [props.$store.NotificationReducer.notifications])

    useEffect(() => {
        getNotifyView()
    }, [])

    const getNotifyView = async () => {
        const resp: any = await notificationService.getNotify(rowsPerPage, page);
        console.log(resp)
        setNotifications(resp.data.data)
        props.$action.actionSetListNotification(resp.data.countNotView)
    }

    const changeToSeenStatusMassive = async () => {
      const response: any = await notificationService.editVistoMassive();
      let apimessagge = apiResponseTranslate(response?.data?.message || '');
      if (response.data.status == true) {
        setSnackBarConfig(prev => ({
          ...prev, open: true,
          severity: 'success',
          message: `${apimessagge}`
          }
        ));
        getNotifyView()
      } else {
        setSnackBarConfig(prev => ({
          ...prev, open: true,
          severity: 'warning',
          message: `${apimessagge}`
          }
        ));
      }
    }

    const changeToSeenStatus = async (id) => {
      const response: any = await notificationService.editVisto(id);
      let apimessagge = apiResponseTranslate(response?.data?.message || '');
      if (response.data.status == true) {
        setSnackBarConfig(prev => ({
          ...prev, open: true,
          severity: 'success',
          message: `${apimessagge}`
          }
        ));
        getNotifyView()
      } else {
        setSnackBarConfig(prev => ({
          ...prev, open: true,
          severity: 'warning',
          message: `${apimessagge}`
          }
        ));
      }
    }

    const removeReceivedNotification = async (id) => {
      console.log('eliminado')
      alert(___PHRASE("eliminadoCorrectamente"))
    }

  const formatDate = (date) => {
    const moment = require('moment');
    require('moment-timezone');

    // Establecer la zona horaria a Chile
    moment.tz.setDefault('America/Santiago');

    // Obtener la fecha actual en el horario de Chile
    const fechaActual = moment().tz('America/Santiago');

    // Obtener la fecha enviada en el horario de Chile
    const fechaEnviada = moment(date).tz('America/Santiago');

    // Verificar si la fecha enviada es mayor a la fecha actual
    if (fechaEnviada.isAfter(fechaActual)) {
      return (___PHRASE("haceUnosSegundos"));
    } else {
      // Calcular la diferencia relativa entre las fechas
      const diferenciaRelativa = fechaEnviada.from(fechaActual);
      return diferenciaRelativa
    }
  }


  return (
    <>
    <Snackbar
        open={snackBarConfig.open}
        autoHideDuration={snackBarConfig.autoHideDuration}
        onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
        <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
            {snackBarConfig.message || ""}
        </Alert>
    </Snackbar>
    <Protected>
       
      {loading && <Loading/>}
      <Container>
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 15px' }}>
          <Typography variant='h6' sx={{ color: '#121c4c', m: 1, fontWeight: 600 }}>{ ___PHRASE("misNotificaciones")}</Typography>
          <Grid onClick={() => changeToSeenStatusMassive()} item display={'flex'} sx={{ cursor: 'pointer'}} alignItems='center'>
            <Typography variant='subtitle1' sx={{ marginRight: '3px', color: '#121c4c' }}>{___PHRASE("marcarTodasComoLeidas")}</Typography>
          </Grid>
        </div>

        <Divider />
          <div>
          {notifications.length > 0 && notifications.map((notification) => {
            const isRead = notification?.date_seen !== null ? true : false
              return (
                <div key={notification.id} style={{ background: `${(notification?.date_seen == null) ? '#efefef' : '#fff'}` }}>
                  <div style={{ wordWrap: 'break-word', justifyContent: 'space-between', display: 'flex', padding: '10px 10px 0px 10px', marginBottom: '0px' }}>
                      <Typography variant='body2' sx={{ fontWeight: isRead ? 500 : 700 }}>{notification.title}</Typography>
                      <Typography variant='caption'>{formatDate(notification.date_sent)}</Typography>
                  </div>

                  <div style={{ wordWrap: 'break-word', paddingInline: ' 10px', justifyContent: 'space-between', display: 'flex' }}>
                    <Typography variant='caption' sx={{ fontWeight: isRead ? 500 : 700 }} >{notification.details}</Typography>
                    <OptionPopover
                      notification={notification}
                      isRead={isRead}
                      changeToSeenStatus={changeToSeenStatus}
                      removeReceivedNotification={removeReceivedNotification}
                    />
                  </div>
                  <Divider />
                </div>
              )
            })}
          </div>

            <Grid item container justifyContent={'center'}>
            <Pagination count={1} page={page} onChange={handleChange} color="primary"  size="large"/>
            </Grid>
            {/* <TablePagination
            component="div"
            count={totalNotification}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 20, 50]}
            labelRowsPerPage={language && language == 'es' ? 'Filas por página' : 'Rows per page'}
            /> */}
        </Container>
    </Protected>
    </>
  )
}
