import React from 'react'
import { DialogActions,  Button} from '@mui/material';
import '../../assets/styles/modals.scss';

export const ModalFooter = (props) => {

  const { 
    confirmText, onConfirm, cancelText, onCancel, cancelColor, confirmColor, className, backgroundColor, buttonType,
    secondaryText, onSecondaryConfirm, clearState
  } = props;

    return (
      <DialogActions className={className || "modal-footer"} style={{position: 'sticky'}}>
        {
          cancelText && (
            <Button onClick={()=>{onCancel && onCancel(); clearState && clearState()}} size="small" color={cancelColor || "secondary"} variant="contained" className={backgroundColor || ''}
            sx={{background:'#E8954F',color:'#fff', '&:hover':{
              bgcolor:'#E8954F'
             }}}>
              {cancelText}
            </Button>
          )
        }
        {
          confirmText && (
            <Button onClick={onConfirm} type={buttonType} size="small" color={confirmColor || "primary"} autoFocus variant="contained"
            sx={{background:'#1386B2',color:'#fff', '&:hover':{
              bgcolor:'#1386B2'
             }}}>
              {confirmText}
            </Button>
          )
        }
        {
          secondaryText && (
            <Button onClick={onSecondaryConfirm} type={buttonType} size="small" color={"error" || confirmColor} autoFocus variant="contained">
              {secondaryText}
            </Button>
          )
        }
      </DialogActions>
    )
}