import React, { useState, useMemo, useRef, useEffect  } from 'react';
import { Grid, Container, Button, Autocomplete, CircularProgress, FormControl, TextField, InputAdornment, FormControlLabel, Checkbox, } from '@mui/material';
import { ArrowRightIcon } from "@toolbox/constants/icons";
import { Props } from './FormInformation.type'
import { VALIDATORS } from '@toolbox/helpers/validation-rules';
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import './style.sass'
import "./FormInformation_style.css";
import BadgeIcon from '@mui/icons-material/Badge';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ___PHRASE from '@/service/translate/translateService';

export const FormInformation: React.FC<Props> = (
   props: Props
): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [openModal, setOpenModal] = useState(false)
   const [isDisabled, setIsDisabled] = useState<boolean>(false);
   const [value, setValue] = React.useState<Date | null>(
      new Date('2018-01-01T00:00:00.000Z'),
    );
   const [error, setError] = useState<any>('')
   const [estado, setEstado] = useState<any>({ rut: false, nombre_rz: false })

   const [valueInformation, setvalueInformation] = useState(props.valueInformation)
   const [valueInitialSituation, setValueInitialSituation] = React.useState('Informacion de Compra');

    const handleInitialSituation = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValueInitialSituation(event.target.value);
    };

    const [errors, setErrors] = useState({
      idAFP: '',
      idsalud: '',
      idAPV: '',
      reduced_tax: '',
      apv_afp: '',
      book_rem_electr: '',
      n_contrato: '',
      idctosalud: '',
      cot_salud_por: '',
      cot_salud_$: '',
      cot_salud_uf: '',
      payment_method: ''
    })
    
   const afpValue = [
      { id:1, label: 'Capital'},
      { id:2, label: 'Cuprum'},
      { id:3, label: 'Empart'},
      { id:4, label: 'Modelo'},
      { id:5, label: 'Habitat'},
   ]
   const saludValue = [
      { id:1, label: 'Alemana Salud S.A.'},
      { id:2, label: 'Banmedica S.A.'},
      { id:3, label: 'Chuchikamata S.A.'},
      { id:4, label: 'Colmena S.A.'},
   ]
   const institucionApv = [
      { id:'1', label: 'ABN AMRO (CHILE) SEGUROS DE VIDA S.A.'},
      { id:'2', label: 'ACE SEGUROS S.A.'},
      { id:'3', label: 'ADMINISTRADORA GENERAL DE FONDOS SECURITY S.A.'},
      { id:'4', label: 'AGENCIA DE VALORES SURA S.A.'}
   ]
   const rebajaImpuesto = [
      { id:1, label: 'NO' },
      { id:2, label: 'SI' },
   ]
   const tipoValue = [
      { id:1, label: 'Pesos' },
      { id:2, label: 'Porcentaje' },
      { id:3, label: 'UF' },
   ]
   const tipoContratoSalud = [
      { id:1, label: 'Pesos Fijo' },
      { id:2, label: 'Porcentaje FIjo' },
      { id:3, label: 'U.F. Variable' },
   ]
   const pagoApvi = [
      { id:1, label: 'Directa' },
      { id:2, label: 'Indirecta' }
   ]   
   const rules = useMemo(() => ({
      unidadMedida: [
         VALIDATORS.REQUIRED,
      ],
      stockMinimo: [
         VALIDATORS.REQUIRED,
         VALIDATORS.CODE.ONLY_NUMBER
      ],
      stockMaximo: [
         VALIDATORS.REQUIRED,
         VALIDATORS.CODE.ONLY_NUMBER
      ],
      ubicacionFisica: [
         VALIDATORS.REQUIRED,
      ],

   }), []);

   useEffect(()=>{
      if(!!!props.isEdit && props.prevStep == 2){
        console.log(props.dataInitial.Salud)
        const saludFonasa = props.dataInitial.Salud.find( item => item.name == "Fonasa");
        console.log('saludFonasa', saludFonasa)
        if(!!saludFonasa){
         const validctosalud = saludFonasa.id == 1 ? 1 : "";
         setvalueInformation({...props.valueInformation, idsalud: saludFonasa.id,  idctosalud: validctosalud})
        }
      }
   },[props])


   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      
      setErrors((prevErrors) => ({
         ...prevErrors,
         [name]: '',
       }));

       setvalueInformation((prevData) => ({
         ...prevData,
         [name]: value,
       }));

      // switch (name) {
      //    case 'reduced_tax':
      //    props.setError(''); 
      //    setvalueInformation(prev => ({ ...prev, reduced_tax: value }));
      //       break;
      //    // case 'apv_afp':
      //    // props.setError(''); 
      //    // setvalueInformation(prev => ({ ...prev, apv_afp: value }))
      //    //    break;
      //    case 'n_contrato':
      //    props.setError(''); 
      //    setvalueInformation(prev => ({ ...prev, n_contrato: value }))
      //       break;
      //    case 'cot_salud_por':
      //    props.setError(''); 
      //    setvalueInformation(prev => ({ ...prev, cot_salud_por: value }));
      //       break;
      //    case 'cot_salud_$':
      //    props.setError(''); 
      //    setvalueInformation(prev => ({ ...prev, cot_salud_$: value }));
      //       break;
      //    case 'cot_salud_uf':
      //    props.setError(''); 
      //    setvalueInformation(prev => ({ ...prev, cot_salud_uf: value }));
      //       break;
      //    default:
      //       break;
      // }
   };

   const handleInputBlur = (event: any) => {
      const newVal = (event.target.value).trim();
      const name = event.target.name;
      props.setvalueInformation(prev => ({ ...prev, [name]: newVal }));
   }

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();

      const newErrors:any = {};

      if (!valueInformation.idAFP) {
         newErrors.idAFP = 'idAFP' 
      }
      // if (!valueInformation.idsalud) {
      //    newErrors.idsalud = 'idsalud'
      // }
      // if (!valueInformation.idAPV) {
      //    newErrors.idAPV = 'idAPV'
      // }
      // if (!valueInformation.reduced_tax) { ¡¡¡¡
      //    setActiveStep(2);
      //    newErrors.reduced_tax = 'reduced_tax'')
      // } 
      // if (!valueInformation.apv_afp) {
      //    setActiveStep(2);
      //    console.log('aqui')
      //    newErrors.apv_afp = 'apv_afp'')
      // }
      if (!valueInformation.n_contrato) { 
         newErrors.n_contrato = 'n_contrato'
      }
      if (!!valueInformation.idsalud && !valueInformation.idctosalud) {
         newErrors.idctosalud = 'idctosalud'
      }

      if (valueInformation.idctosalud == 1 && !valueInformation.cot_salud_por) {
         newErrors.cot_salud_por = 'cot_salud_por'
      }
      if (valueInformation.idctosalud == 2 && !valueInformation.cot_salud_$) {
         newErrors.cot_salud_$ = 'cot_salud_$'
      }
      if (valueInformation.idctosalud == 3 && !valueInformation.cot_salud_uf) {
         newErrors.cot_salud_uf = 'cot_salud_uf'
      }

      setErrors(newErrors);

      if (Object.keys(newErrors).length === 0) {
         props.setvalueInformation(valueInformation); props.handleNext();   
      }
      
   }
   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);
      return valid;
   }

   return (
      <Container maxWidth="xl">
         <form >
            <Grid container spacing={3} className={`e-mb-lg`} mt={2}>
                  
               <Grid item xs={12} sm={3} >
                  <Autocomplete
                        // disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={props.dataInitial.AFP}
                        // sx={{ bgcolor: '#F3F3F3'}}
                        onChange={(e,value:any)=>{setvalueInformation({...valueInformation, idAFP:value?value.id:null});
                        setErrors((prevErrors) => ({
                           ...prevErrors,
                           idAFP: '',
                         }))}}
                        value={
                           // props.valueInformation?.idAFP?(
                           //    props.dataInitial.AFP.filter((value)=>{return value.id == props.valueInformation?.idAFP})[0]
                           // ):(
                              valueInformation?.idAFP?(
                                 props.dataInitial.AFP.filter((value)=>{return value.id == valueInformation?.idAFP})[0]
                              ):(
                                 null
                              )
                           // )
                           }
                        getOptionLabel={(option: any) => option.name ? option.name : ''}
                        renderInput={(params) => <TextField {...params} label={___PHRASE("afp")} 
                        error={!!errors.idAFP}
                        helperText={errors.idAFP && ___PHRASE("campoObligatorio")}/>}
                     />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                        // disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={props.dataInitial.Salud}
                        onChange={(e, value:any)=>{
                           const validctosalud = value && value.id == 1 ? 1 : "";
                           setvalueInformation({...valueInformation, idsalud: value?value.id:null,  idctosalud: validctosalud});
                           setErrors((prevErrors) => ({
                              ...prevErrors,
                              idsalud: '',
                              idctosalud: ''
                            }));
                        }}
                        getOptionLabel={(option: any) => option.name ? option.name : ''}
                        value={
                           // props.valueInformation?.idsalud?(
                           //    props.dataInitial.Salud.filter((value)=>{return value.id == props.valueInformation?.idsalud})[0]
                           // ):(
                              valueInformation?.idsalud?(
                                 props.dataInitial.Salud.filter((value)=>{return value.id == valueInformation?.idsalud})[0]
                              ):(
                                 null
                              )
                           // )
                           }
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label={___PHRASE("salud")}/>}
                     />
               </Grid>
               <Grid item xs={12} sm={6}>
                  <Autocomplete
                        // disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={props.dataInitial.APV}
                        onChange={(e, value:any)=>{setvalueInformation({...valueInformation, idAPV: value?value.id:null});
                        setErrors((prevErrors) => ({
                           ...prevErrors,
                           idAPV: '',
                         }))
                     }}
                        getOptionLabel={(option: any) => option.name ? option.name : ''}
                        value={
                           // props.valueInformation?.idAPV?(
                           //    props.dataInitial.APV.filter((value)=>{return value.id == props.valueInformation?.idAPV})[0]
                           // ):(
                              valueInformation?.idAPV?(
                                 props.dataInitial.APV.filter((value)=>{return value.id == valueInformation?.idAPV})[0]
                              ):(
                                 null
                              )
                           // )
                           }
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label={___PHRASE("institucionAPV")}
                        error={!!errors.idAPV}
                        helperText={errors.idAPV && ___PHRASE("campoObligatorio")} />}
                     />
               </Grid>
               {/* <Grid item xs={12} sm={3}>
                  <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={rebajaImpuesto}
                        onChange={(e, value:any)=>{setvalueInformation({...valueInformation, reduced_tax: value.id});props.setError('')}}
                        getOptionLabel={(option: any) => option.label ? option.label : ''}
                        value={
                           props.valueInformation?.reduced_tax?(
                             rebajaImpuesto.filter((value)=>{return value.id == props.valueInformation?.reduced_tax})[0]
                           ):(
                              valueInformation?.reduced_tax?(
                                rebajaImpuesto.filter((value)=>{return value.id == valueInformation?.reduced_tax})[0]
                              ):(
                                 null
                              )
                           )
                           }
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label="Rebaja Impuesto" 
                        error={errors.reduced_tax}
                        helperText={errors.reduced_tax && 'Campo obligatorio'}/>}
                     />
               </Grid> */}
               {/* <Grid item xs={12} sm={3}>
                  <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={tipoValue}
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label="Tipo" />}
                     />
               </Grid> */}
               {/* <Grid item xs={12} sm={3}>
                  <Input
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <BadgeIcon />
                        </InputAdornment>
                     }
                     ref={ref => inputRefs.current[0] = ref}
                     name="apv_afp"
                     type='number'
                     placeholder="A.P.V. A.F.P."
                     value={valueInformation.apv_afp}
                     onChange={handleInput}
                     error={errors.apv_afp}
                     errorMessages={errors.apv_afp && 'Campo obligatorio'}
                     // backgroundColor="#F3F3F3"
                     disableElevation
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                  />
               </Grid> */}
               <Grid item xs={12} sm={3}>
                  <FormControl >
                     <FormControlLabel control={
                        <Checkbox onChange={(e)=>{setvalueInformation({...valueInformation, book_rem_electr: e.target.checked ? 1 : 0});
                        setErrors((prevErrors) => ({
                           ...prevErrors,
                           book_rem_electr: '',
                         }))
                     }}/>} 
                     label={___PHRASE("libroRemElect")} />                     
                  </FormControl>
               </Grid> 
               <Grid item xs={12} sm={3}>
                  <Input
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <BadgeIcon />
                        </InputAdornment>
                     }
                     ref={ref => inputRefs.current[0] = ref}
                     name="n_contrato"
                     type='text'
                     placeholder={___PHRASE("nContrato")}
                     value={valueInformation.n_contrato}
                     onChange={handleInput}
                     error={!!errors.n_contrato}
                     errorMessages={errors.n_contrato && ___PHRASE("campoObligatorio")}
                     // backgroundColor="#F3F3F3"
                     disableElevation
                     validateOnBlur
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                     height='38px'
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                        // disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={props.dataInitial.TypeCtoSalud}
                        onChange={(e,value:any)=>{setvalueInformation({...valueInformation, idctosalud:value?value.id:null});
                        setErrors((prevErrors) => ({
                           ...prevErrors,
                           idctosalud: '',
                         }))
                     }}
                        value={
                           // props.valueInformation?.idctosalud?(
                           //    props.dataInitial.TypeCtoSalud.filter((value)=>{return value.id == props.valueInformation?.idctosalud})[0]
                           // ):(
                              valueInformation?.idctosalud?(
                                 props.dataInitial.TypeCtoSalud.filter((value)=>{return value.id == valueInformation?.idctosalud})[0]
                              ):(
                                 null
                              )
                           // )
                           }
                        getOptionLabel={(option: any) => option.name ? option.name : ''}
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label={___PHRASE("tipoContratoSalud")} 
                        error={!!errors.idctosalud}
                        helperText={errors.idctosalud && ___PHRASE("campoObligatorio")} />}
                     />
               </Grid>
               {
                  valueInformation?.idctosalud == 1 && (
                     <Grid item xs={12} sm={3}>
                        <Input
                           prependInnerAdornment={
                              <InputAdornment position="end">
                                 <BadgeIcon />
                              </InputAdornment>
                           }
                           ref={ref => inputRefs.current[0] = ref}
                           name="cot_salud_por"
                           type='text'
                           placeholder={___PHRASE("pactadoCotSalud")}
                           value={valueInformation.cot_salud_por}
                           onChange={handleInput}
                           error={!!errors.cot_salud_por}
                           errorMessages={errors.cot_salud_por && ___PHRASE("campoObligatorio")}
                           // backgroundColor="#F3F3F3"
                           disableElevation
                           dense
                           disabled={isDisabled}
                           className='borderInput'
                           height='38px'
                        />
                     </Grid>
                  )
               }
               {
                  valueInformation?.idctosalud == 2 && (
                     <Grid item xs={12} sm={3}>
                        <Input
                           prependInnerAdornment={
                              <InputAdornment position="end">
                                 <BadgeIcon />
                              </InputAdornment>
                           }
                           ref={ref => inputRefs.current[0] = ref}
                           name="cot_salud_$"
                           type='text'
                           placeholder={___PHRASE("pactadoCotSalud2")}
                           value={valueInformation.cot_salud_$}
                           onChange={handleInput}
                           error={!!errors.cot_salud_$}
                           errorMessages={errors.cot_salud_$ && ___PHRASE("campoObligatorio")}
                           // backgroundColor="#F3F3F3"
                           disableElevation
                           dense
                           disabled={isDisabled}
                           className='borderInput'
                           height='38px'
                        />
                     </Grid>
                  )
               }
               {
                  valueInformation?.idctosalud == 3 && (
                     <Grid item xs={12} sm={3}>
                        <Input
                           prependInnerAdornment={
                              <InputAdornment position="end">
                                 <BadgeIcon />
                              </InputAdornment>
                           }
                           ref={ref => inputRefs.current[0] = ref}
                           name="cot_salud_uf"
                           type='number'
                           placeholder={___PHRASE("pactadoCotSalud3")}
                           value={valueInformation.cot_salud_uf}
                           onChange={handleInput}
                           error={!!errors.cot_salud_uf}
                           errorMessages={errors.cot_salud_uf && ___PHRASE("campoObligatorio")}
                           // backgroundColor="#F3F3F3"
                           disableElevation
                           validateOnBlur
                           dense
                           disabled={isDisabled}
                           className='borderInput'
                           height='38px'
                        />
                     </Grid>
                  )
               }
               {/* <Grid item xs={12} sm={3}>
                  <Autocomplete
                        disablePortal
                        size='small'
                        id="combo-box-demo"
                        options={pagoApvi}
                        onChange={(e,value:any)=>{setvalueInformation({...valueInformation, payment_method: value.id});props.setError('')}}
                        value={
                           props.valueInformation?.payment_method?(
                              pagoApvi.filter((value)=>{return value.id == props.valueInformation?.payment_method})[0]
                           ):(
                              valueInformation?.payment_method?(
                                 pagoApvi.filter((value)=>{return value.id == valueInformation?.payment_method})[0]
                              ):(
                                 null
                              )
                           )
                           }
                        getOptionLabel={(option: any) => option.label ? option.label : ''}
                        // sx={{ bgcolor: '#F3F3F3'}}
                        renderInput={(params) => <TextField {...params} label="Forma de Pago APVI"
                        error={errors.payment_method}
                        helperText={errors.payment_method && 'Campo obligatorio'} />}
                     />
               </Grid> */}

             

               <Grid item container
                  xs={12}
                  justifyContent='space-between'
               >
                  <Grid item xs={6}>
                     <Button
                        variant='contained'
                        onClick={()=>{  props.setvalueInformation(valueInformation); props.handleBack();}}
                        disabled={loading}
                        disableElevation
                        className='btn_back'
                        startIcon={<ArrowBackIosIcon />}
                     >
                        {
                           loading ?
                              <CircularProgress className="CircularProgress" size={24} /> :
                              <span>{___PHRASE("atras")}</span>
                        }
                     </Button>
                  </Grid>
                  <Grid item xs={6} container
                     justifyContent='right' >
                     {/* <Button
                        type='submit'
                        variant='contained'
                        onClick={handleSubmit}
                        disabled={loading}
                        disableElevation
                     >
                        {
                           loading ?
                              <CircularProgress className="CircularProgress" size={24} /> :
                              <span>Guardar</span>
                        }
                     </Button> */}
                     <Button
                        type='submit'
                        variant='contained'
                        // onClick={()=>{  props.setvalueInformation(valueInformation); props.handleNext();}}
                        onClick={(e)=>handleSubmit(e)}
                        disabled={loading}
                        disableElevation
                        className='btn_save'
                        sx={{ml:2}}
                        endIcon={<ArrowRightIcon />}
                     >
                        {
                           loading ?
                              <CircularProgress className="CircularProgress" size={24} /> :
                              <span>{___PHRASE("siguiente")}</span>
                        }
                     </Button>
                     
                  </Grid>
               </Grid>
            </Grid>
         </form>
      </Container>
   );
}
