import { CountryRepository } from "../repositories/Country.repository";

export const CountryService = {
    getCountries,
    getDepartments,
    getProvinces,
    getDistricts,
    findCountry,
    findDepartment,
    findProvince,
    findDistrict,
    getTimeZone,
    createCountry,
    createDepartament,
    createProvince,
    createDistrict,
    editCountry,
    editDepartament,
    editProvince,
    editDistrict,
    deleteCountry,
    deleteDepartament,
    deleteProvince,
    deleteDistrict,
}

async function getCountries(){
    const res = await CountryRepository.getCountries();
    return res;
}

async function getDepartments(idCountry: number, search = ''){
    const res = await CountryRepository.getDepartments(idCountry, search);
    return res;
}

async function getProvinces(idDepartment: number, search = ''){
    const res = await CountryRepository.getProvinces(idDepartment, search);
    return res;
}

async function getDistricts(idProvince: number){
    const res = await CountryRepository.getDistricts(idProvince);
    return res;
}

async function findCountry(id: number){
    const res = await CountryRepository.findCountry(id);
    return res;
}

async function findDepartment(id: number){
    const res = await CountryRepository.findDepartment(id);
    return res;
}

async function findProvince(id: number){
    const res = await CountryRepository.findProvince(id);
    return res;
}

async function findDistrict(id: number){
    const res = await CountryRepository.findDistrict(id);
    return res;
}

async function getTimeZone(){
    const res = await CountryRepository.getTimeZone();
    return res;
}

async function createCountry(data){
    const res = await CountryRepository.createCountry(data);
    return res;
}

async function createDepartament(data){
    const res = await CountryRepository.createDepartament(data);
    return res;
}

async function createProvince(data){
    const res = await CountryRepository.createProvince(data);
    return res;
}

async function createDistrict(data){
    const res = await CountryRepository.createDistrict(data);
    return res;
}

async function editCountry(data, id){
    const res = await CountryRepository.editCountry(data, id);
    return res;
}

async function editDepartament(data, id){
    const res = await CountryRepository.editDepartament(data, id);
    return res;
}

async function editProvince(data, id){
    const res = await CountryRepository.editProvince(data, id);
    return res;
}

async function editDistrict(data, id){
    const res = await CountryRepository.editDistrict(data, id);
    return res;
}

async function deleteCountry(data){
    const res = await CountryRepository.deleteCountry(data);
    return res;
}

async function deleteDepartament(data){
    const res = await CountryRepository.deleteDepartament(data);
    return res;
}

async function deleteProvince(data){
    const res = await CountryRepository.deleteProvince(data);
    return res;
}

async function deleteDistrict(data){
    const res = await CountryRepository.deleteDistrict(data);
    return res;
}