import React, { useEffect, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Box, Container, Typography, styled } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { Loading } from '@/components/common/Loading';
import { ConfigurationIPService } from '@/service/services/ConfigurationIP.service';
import { ModalIpDelete } from '@/components/Modal/ModalIpDelete/ModalIpDelete';
import { Props } from './IpsDenied.type';
import { ModalIpDenied } from '@/components/Modal/ModalIpDenied/ModalIpDenied';
import { companiesService } from '@/service/services/Companies.service';
import ___PHRASE from '@/service/translate/translateService';

const Div = styled('div')(({ theme }) => ({
    ...theme.typography.button,
    padding: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: '20px',
    color: 'rgba(102, 102, 102, 0.4)',
}));

export const IpsDeniedView: React.FC<Props> = (props:any): JSX.Element => {

    const [loading, setLoading] = useState<boolean>(false);
    const [IpsDenied, setIpsDenied] = useState([]);
    const [showIpDeniedModal, setShowIpDeniedModal] = useState<boolean>(false);
    const [showIpDeniedDeleteModal, setShowIpDeniedDeleteModal] = useState<boolean>(false);
    const [actionSelected, setActionSelected] = useState<String>('');
    const [ipDeniedSelected, setIpDeniedSelected] = useState<String>('');

    const [ipAcessRequest, setIpAcessRequest] = useState<String>('');

    useEffect(() => {
        getIPDeniedByCompany()
    },[]);

    const getIPDeniedByCompany = async() => {
        setLoading(true);
        let ipAccess = '';

        await companiesService.getDataCompany().then((data) => {
            if(data?.data && data?.data?.ip_access_request) ipAccess = data.data.ip_access_request;
            setIpAcessRequest(data.data.ip_access_request);
        })

        if(ipAccess != '0'){
            await ConfigurationIPService.getIPDeniedByCompany().then((data) => {
                setIpsDenied(data.data || []);
                setLoading(false);
            })
        }
        setLoading(false);
    }

    function RecuperarData(value){
        setIpDeniedSelected(value)
    }

    return (
        <Protected>
            {loading && <Loading/>}
            <Container>
                {
                    ipAcessRequest && ipAcessRequest != '0' && !loading && (
                        <TableDataV2
                            title={___PHRASE("ipsDenegadas")}
                            data={IpsDenied}
                            header={[
                                { name: 'ipDenied', label: 'IP', filter: false, Chip: false },
                                { name: 'user_name', label: ___PHRASE('nombreDeUsuario'), filter: false, Chip: true },
                                { name: 'n_document', label: ___PHRASE('nDocumento'), filter: false, Chip: true },
                                { name: 'email', label: ___PHRASE('correoDeUsuario'), filter: false, Chip: true },
                            ]}
                            actionSelect={setActionSelected}
                            setModalSave={setShowIpDeniedModal}
                            setModalDelete={setShowIpDeniedDeleteModal}
                            status_action
                            RecuperarData={RecuperarData}
                            note_severity={'warning'}
                            note={___PHRASE('acceptIP')}
                        />
                    )
                }
                {
                    ipAcessRequest && ipAcessRequest == '0' && !loading && (
                        <>
                        <Typography variant='h6' component='h1' gutterBottom className="tittle" sx={{ fontWeight:600, paddingTop:'23px'}}>
                            {___PHRASE("ipsDenegadas")}
                        </Typography>
                        <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'150px', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '45px' }}>
                            <Div>{___PHRASE("laEmpresaNoCuentaConRestriccionPorIp")}</Div>
                        </Box>
                        </>
                    )
                }
                {
                    loading && (
                        <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'150px', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '45px' }}>
                            <Div>{___PHRASE("cargandoDatosEspereUnMomento")}</Div>
                        </Box>
                    )
                }
                

            </Container>

            { 
                showIpDeniedModal && (
                <ModalIpDenied
                    open={showIpDeniedModal}
                    closeModal={()=>{setShowIpDeniedModal(false)}}
                    actionSelected={actionSelected}
                    onListIps={getIPDeniedByCompany}
                    userIp={ipDeniedSelected}
                    clearState={()=>{setIpDeniedSelected('')}}
                />
                )
            }
            {
                actionSelected === 'delete' && (
                <ModalIpDelete
                    open={showIpDeniedDeleteModal}
                    closeModal={()=>{setShowIpDeniedDeleteModal(false)}}
                    actionSelected={actionSelected}
                    onListIps={getIPDeniedByCompany}
                    userIp={ipDeniedSelected}
                    clearState={()=>{setIpDeniedSelected('')}}
                    type='denied'
                />
                )
            }
        
        </Protected>
    )
};