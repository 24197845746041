import React, { useState, useRef, useEffect } from 'react';
import { Grid, Container, Button, Autocomplete, CircularProgress, TextField, InputAdornment, Snackbar, Alert, } from '@mui/material';
import { ArrowRightIcon } from "@toolbox/constants/icons";
import { Props } from './FormPersonalData.type'
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import BadgeIcon from '@mui/icons-material/Badge';
import HeightIcon from '@mui/icons-material/Height';
import PhoneIcon from '@mui/icons-material/Phone';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import ___PHRASE from '@/service/translate/translateService';
import { apiResponseTranslate } from '@/toolbox/helpers/responseApi.helper';
import './style.sass'
import "./FormPersonalData_style.css";

const nationalityOptions = [
   { id: 1 , name: apiResponseTranslate("chileno")},
   { id:2 , name: apiResponseTranslate("extranjero")}
]


export const FormPersonalData: React.FC<Props> = (
   props: Props
): JSX.Element => {
   
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [isDisabled, setIsDisabled] = useState<boolean>(false);

   const [errors, setErrors] = useState({
      n_document: '',
      name: '',
      surname: '',
      second_surname: '',
      idcivilstatus: '',
      idnationality: '',
      idemployeetype: '',
      sexo: '',
      birth_date: '',
      pensioner: 1,
      idposition: 1,
      state: '',
      idmilitary: '',
      ideducation: '',
      direccion: '',
      iddistrict: '',
      ciudad:'',
      telefono: '',
      celular: '',
      emergency_phone: '',
      email: '',
      idcategoryemployee: ''
   })
   const [valuePersonalData, setvaluePersonalData] = useState<any>(props?.valuePersonalData);

   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
  });

   useEffect(() => {
      if(props?.valuePersonalData){
         setvaluePersonalData({...props.valuePersonalData, idnationality: props.valuePersonalData?.nationality})
      }
   }, [props?.valuePersonalData])

   const sexoValue = [
      { id:'1', label: ___PHRASE("masculino") },
      { id:'2', label: ___PHRASE("femenino") },
   ]
   
   useEffect(() => {
      if(props?.valuePersonalData){
         setvaluePersonalData({...props.valuePersonalData, idnationality: props.valuePersonalData?.nationality})
      }
   }, [props?.valuePersonalData])
   
   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;

      setErrors((prevErrors) => ({
         ...prevErrors,
         [name]: '',
       }));

       setvaluePersonalData((prevData) => ({
         ...prevData,
         [name]: value,
       }));

      // switch (name) {
      //    case 'n_document':
      //       setErrors((prevErrors) => ({
      //          ...prevErrors,
      //          [name]: '',
      //       }));
      //       setvaluePersonalData(prev => ({ ...prev, n_document: value }));
      //       break;
      //    case 'name':
      //       setErrors((prevErrors) => ({
      //          ...prevErrors,
      //          [name]: '',
      //       }));  
      //       setvaluePersonalData(prev => ({ ...prev, name: value }))
      //       break;
      //    case 'surname':
      //       setErrors((prevErrors) => ({
      //          ...prevErrors,
      //          [name]: '',
      //       }));  
      //       setvaluePersonalData(prev => ({ ...prev, surname: value }))
      //       break;
      //    case 'second_surname':
      //       setErrors((prevErrors) => ({
      //          ...prevErrors,
      //          [name]: '',
      //       }));  
      //       setvaluePersonalData(prev => ({ ...prev, second_surname: value }));
      //       break;
      //    case 'direccion':
      //       setErrors((prevErrors) => ({
      //          ...prevErrors,
      //          [name]: '',
      //       }));  
      //       setvaluePersonalData(prev => ({ ...prev, direccion: value }));
      //       break;
      //    case 'telefono':
      //       setErrors((prevErrors) => ({
      //          ...prevErrors,
      //          [name]: '',
      //       }));  
      //       setvaluePersonalData(prev => ({ ...prev, telefono: value }));
      //       break;
      //    // case 'ciudad':
      //    //    props.setError('')  
      //    //    setvaluePersonalData(prev => ({ ...prev, ciudad: value }));
      //    //    break;
      //    case 'celular':
      //       props.setError('')  
      //       setvaluePersonalData(prev => ({ ...prev, celular: value }));
      //       break;
      //    case 'emergency_phone':
      //    props.setError('')  
      //    setvaluePersonalData(prev => ({ ...prev, emergency_phone: value }));
      //    break;
      //    case 'email':
      //       props.setError('')  
      //       setvaluePersonalData(prev => ({ ...prev, email: value }));
      //       break;
      //    default:
      //       break;
      // }  
   };

   const handleChangeBirthDate = (newValue) => {
      setErrors((prevErrors) => ({
         ...prevErrors,
         birth_date: '',
       }));
      setvaluePersonalData({...valuePersonalData, birth_date: newValue.target.value})
   };

   const handleSubmit = async (e) => {
      e.preventDefault();
      let regRut = /^[0-9]{8}[-|‐]{1}[0-9kK]{1}$/;

      const newErrors:any = {};

      if(valuePersonalData.n_document && !(regRut.test(valuePersonalData.n_document))){
         return (
            setSnackBarConfig(prev => ({ ...prev, open: true, 
               severity:'warning', 
               message: ___PHRASE('rutincorrecto' || '') 
               // message: ___PHRASE("debeSubirDocumentosDeIdentidadDelEmpleado") 
            }))
         );
      }

      if (!valuePersonalData.n_document) {  newErrors.n_document = 'n_document'}
      if (!valuePersonalData.name) {   newErrors.name = 'name'}
      if (!valuePersonalData.surname) { newErrors.surname = 'surname'}
      if (!valuePersonalData.second_surname) { newErrors.second_surname = 'second_surname'}
      if (!valuePersonalData.idcivilstatus) { newErrors.idcivilstatus = 'idcivilstatus'}
      if (!valuePersonalData.idnationality) { newErrors.idnationality = 'idnationality'}
      if (!valuePersonalData.idemployeetype) {  newErrors.idemployeetype = 'idemployeetype'}
      if (!valuePersonalData.sexo) { newErrors.sexo = 'sexo'}  
      if (!!!valuePersonalData.birth_date) {newErrors.birth_date = 'birth_date'}
      if (!valuePersonalData.state) { newErrors.state = 'state'}
      if (!valuePersonalData.ideducation) {  newErrors.ideducation = 'ideducation'}
      if (!valuePersonalData.direccion) {
         newErrors.direccion = 'direccion'
      }
      if (!valuePersonalData.iddistrict) {
         newErrors.iddistrict = 'iddistrict'
      }
      if (!props.isEdit && !valuePersonalData.telefono) {
         newErrors.telefono = 'telefono'
      }
      // if (!valuePersonalData.ciudad) {
      //    console.log('aqui'); 
      //    newErrors.ciudad = 'ciudad'
      // }
      if (!props.isEdit && !valuePersonalData.celular) {
         newErrors.celular = 'celular'
      }
      if (!props.isEdit && !valuePersonalData.emergency_phone) {
         newErrors.emergency_phone = 'emergency_phone'
      }
      if (!props.isEdit && !valuePersonalData.email) {
         newErrors.email = 'email'
      }

      console.log(newErrors)
      setErrors(newErrors);

      if (Object.keys(newErrors).length === 0) {
         props.setvaluePersonalData && props.setvaluePersonalData(valuePersonalData); 
         props.handleNext && props.handleNext();
       }
     
   }

   return (
      <>
      <Snackbar
         open={snackBarConfig.open} 
         autoHideDuration={snackBarConfig.autoHideDuration} 
         onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
         anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
         <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
               {snackBarConfig.message || ""}
         </Alert>
      </Snackbar>
      
      <Container maxWidth="xl">
         <form >
            <Grid container spacing={3}>

               <Grid item xs={12} sm={3}>
                  <Input
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <BadgeIcon />
                        </InputAdornment>
                     }
                     ref={ref => inputRefs.current[0] = ref}
                     name="n_document"
                     type='text'
                     placeholder="Rut"
                     value={valuePersonalData.n_document}
                     onChange={handleInput}
                     error={!!errors.n_document}
                     errorMessages={errors.n_document && ___PHRASE("campoObligatorio")}
                     disableElevation
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                     height='38px'
                  />

               </Grid>

               <Grid item xs={12} sm={3}>
                  <Input
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <BadgeIcon />
                        </InputAdornment>
                     }
                     ref={ref => inputRefs.current[0] = ref}
                     name="name"
                     type='text'
                     placeholder={___PHRASE("nombre")}
                     value={valuePersonalData.name}
                     onChange={handleInput}
                     error={!!!!errors.name}
                     errorMessages={errors.name && ___PHRASE("campoObligatorio")}
                     disableElevation
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                     height='38px'
                  />

               </Grid>
               <Grid item xs={12} sm={3}>
                  <Input
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <BadgeIcon />
                        </InputAdornment>
                     }
                     ref={ref => inputRefs.current[0] = ref}
                     name="surname"
                     type='text'
                     placeholder={___PHRASE("apellidoPaterno")}
                     value={valuePersonalData.surname}
                     onChange={handleInput}
                     error={!!errors.surname}
                     errorMessages={errors.surname && ___PHRASE("campoObligatorio")}
                     disableElevation
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                     height='38px'
                  />

               </Grid>
               <Grid item xs={12} sm={3}>
                  <Input
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <BadgeIcon />
                        </InputAdornment>
                     }
                     ref={ref => inputRefs.current[0] = ref}
                     name="second_surname"
                     type='text'
                     placeholder={___PHRASE("apellidoMaterno")}
                     value={valuePersonalData.second_surname}
                     onChange={handleInput}
                     error={!!errors.second_surname}
                     errorMessages={errors.second_surname && ___PHRASE("campoObligatorio")}
                     disableElevation
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                     height='38px'
                  />

               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     // disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={props.dataInitial.CivilStatus}
                     onChange={(e,value:any)=>{
                        setErrors((prevErrors) => ({
                           ...prevErrors,
                           idcivilstatus: '',
                         }));
                        setvaluePersonalData({...valuePersonalData, idcivilstatus: value?value.id:null})}}
                     getOptionLabel={(option: any) => option.name ? option.name : ''}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     value={
                        // props.valuePersonalData?.idcivilstatus?(
                        //    props.dataInitial.CivilStatus.filter((value)=>{return value.id == props.valuePersonalData?.idcivilstatus})[0]
                        // ):(
                           valuePersonalData?.idcivilstatus?(
                              props.dataInitial.CivilStatus.filter((value)=>{return value.id == valuePersonalData?.idcivilstatus})[0]
                           ):(
                              null
                           )
                        // )
                        }
                     renderInput={(params) => <TextField {...params} label={___PHRASE("estadoCivil")}
                     error={!!errors.idcivilstatus} 
                     helperText={errors.idcivilstatus && ___PHRASE("campoObligatorio")} />}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     // disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={nationalityOptions}
                     onChange={(e,value:any)=>{
                        setErrors((prevErrors) => ({
                           ...prevErrors,
                           idnationality: '',
                         }));
                        setvaluePersonalData({...valuePersonalData, idnationality: value?value.id:null})}}
                     getOptionLabel={(option: any) => option.name ? option.name : ''}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     value={
                        // props.valuePersonalData?.idnationality?(
                        //    props.dataInitial.CivilStatus.filter((value)=>{return value.id == props.valuePersonalData?.idnationality})[0]
                        // ):(
                           valuePersonalData?.nationality?(
                              nationalityOptions.filter((value)=>{return value.id == valuePersonalData?.nationality})[0]
                           ):(
                              null
                           )
                        // )
                        }
                     renderInput={(params) => <TextField {...params} label={___PHRASE("nacionalidad")}
                     error={!!errors.idnationality} 
                     helperText={errors.idnationality && ___PHRASE("campoObligatorio")} />}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     // disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={props.dataInitial.EmployeeType}
                     onChange={(e, value:any)=>{
                        const validpensioner = (value.code == 0 || value.code == 3 ) ? 0 : 1;
                        setErrors((prevErrors) => ({
                           ...prevErrors,
                           idemployeetype: '',
                         }));
                        setvaluePersonalData(prev => ({ ...valuePersonalData, idemployeetype: value?value.id:null, pensioner: validpensioner}));
                     }}
                     // sx={{ bgcolor: '#F3F3F3'}}
                     value={
                        // props.valuePersonalData?.idemployeetype?(
                        //    props.dataInitial.EmployeeType.filter((value)=>{return value.id == props.valuePersonalData?.idemployeetype})[0]
                        // ):(
                           valuePersonalData?.idemployeetype?(
                              props.dataInitial.EmployeeType.filter((value)=>{return value.id == valuePersonalData?.idemployeetype})[0]
                           ):(
                              null
                           )
                        // )
                        }
                     getOptionLabel={(option: any) => option.name ? option.name : ''}
                     renderInput={(params) => <TextField {...params} label={___PHRASE("tipoDeTrabajador")}
                     error={!!errors.idemployeetype}
                     helperText={errors.idemployeetype && ___PHRASE("campoObligatorio")}/>}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     // disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={sexoValue}
                     onChange={(e,value:any)=>{
                        setErrors((prevErrors) => ({
                           ...prevErrors,
                           sexo: '',
                         }));
                        setvaluePersonalData({...valuePersonalData, sexo:value?value.id:null })}}
                     value={
                        // props.valuePersonalData?.sexo?(
                        //   sexoValue.filter((value)=>{return value.id == props.valuePersonalData?.sexo})[0]
                        // ):(
                           valuePersonalData?.sexo?(
                             sexoValue.filter((value)=>{return value.id == valuePersonalData?.sexo})[0]
                           ):(
                              null
                           )
                        // )
                        }
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label={___PHRASE("sexo")}
                     error={!!errors.sexo}
                     helperText={errors.sexo && ___PHRASE("campoObligatorio")} />}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  {/* <LocalizationProvider dateAdapter={AdapterDateFns} color="primary" focused>
                     <Stack>
                        <DatePicker
                            inputFormat="MM/dd/yyyy"
                           // views={['year', 'month', 'day']}
                           label="Fecha de Nacimiento"
                           value={valuePersonalData.birth_date}
                           onChange={handleChangeBirthDate}
                           renderInput={(params) => <TextField {...params} 
                           error={!!errors.birth_date}
                           helperText={errors.birth_date && 'Campo obligatorio'} />}
                        />
                     </Stack>                                               
                  </LocalizationProvider> */}
                  <TextField
                           variant="standard"
                           fullWidth
                           name="birth_date"
                           type="date"
                           placeholder='dd/mm/aaaa'
                           label={___PHRASE("fechaDeNacimiento")}
                           onChange={handleChangeBirthDate}
                           value={valuePersonalData.birth_date}
                           InputLabelProps={{
                              shrink: true,
                           }}
                           error={!!errors.birth_date}
                           helperText={errors.birth_date && ___PHRASE("campoObligatorio")}
                        />
               </Grid>

               {/* <Grid item xs={12} sm={3}>
                  <FormControl >
                     <FormControlLabel control={<Checkbox />} label="Pensionado" />                     
                  </FormControl>
               </Grid>  */}

               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     // disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={props.dataInitial.State}
                     onChange={(e, value:any)=>{
                        setErrors((prevErrors) => ({
                           ...prevErrors,
                           state: '',
                         }));
                        setvaluePersonalData({...valuePersonalData, state: value?value.valueStatus:null})}}
                     getOptionLabel={(option: any) => option.status ? option.status : ''}
                     value={
                        // props.valuePersonalData?.state?(
                        //    props.dataInitial.State.filter((value)=>{return value.valueStatus == props.valuePersonalData?.state})[0]
                        // ):(
                           valuePersonalData?.state?(
                              props.dataInitial.State.filter((value)=>{return value.valueStatus == valuePersonalData?.state})[0]
                           ):(
                              null
                           )
                        // )
                        }
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label={___PHRASE("estadoDelTrabajador")}
                     error={!!errors.state}
                     helperText={errors.state && ___PHRASE("campoObligatorio")} />}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     // disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={props.dataInitial.Education}
                     onChange={(e, value:any)=>{
                        setErrors((prevErrors) => ({
                           ...prevErrors,
                           ideducation: '',
                         }));
                        setvaluePersonalData({...valuePersonalData, ideducation: value?value.id:null})}}
                     getOptionLabel={(option: any) => option.name ? option.name : ''}
                     value={
                        // props.valuePersonalData?.ideducation?(
                        //    props.dataInitial.Education.filter((value)=>{return value.id == props.valuePersonalData?.ideducation})[0]
                        // ):(
                           valuePersonalData?.ideducation?(
                              props.dataInitial.Education.filter((value)=>{return value.id == valuePersonalData?.ideducation})[0]
                           ):(
                              null
                           )
                        // )
                        }
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label={___PHRASE("ultimoNivelDeEstudios")} 
                     error={!!errors.ideducation} 
                     helperText={errors.ideducation && ___PHRASE("campoObligatorio")} />}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Input
                     ref={ref => inputRefs.current[1] = ref}
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <HeightIcon />

                        </InputAdornment>
                     }
                     name="direccion"
                     placeholder={___PHRASE("direccion")}
                     value={valuePersonalData.direccion}
                     onChange={handleInput}
                     error={!!errors.direccion}
                     errorMessages={errors.direccion && ___PHRASE("campoObligatorio")}
                     disableElevation
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                     height='38px'
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Autocomplete
                     // disablePortal
                     size='small'
                     id="combo-box-demo"
                     options={props.dataInitial.District}
                     onChange={(e,value:any)=>{
                        setErrors((prevErrors) => ({
                           ...prevErrors,
                           iddistrict: '',
                         }));
                        setvaluePersonalData({...valuePersonalData, iddistrict: value?value.id:null})}}
                     getOptionLabel={(option: any) => option.name ? option.name : ''}
                     value={
                        // props.valuePersonalData?.iddistrict?(
                        //    props.dataInitial.District.filter((value)=>{return value.id == props.valuePersonalData?.iddistrict})[0]
                        // ):(
                           valuePersonalData?.iddistrict?(
                              props.dataInitial.District.filter((value)=>{return value.id == valuePersonalData?.iddistrict})[0]
                           ):(
                              null
                           )
                        // )
                        }
                     // sx={{ bgcolor: '#F3F3F3'}}
                     renderInput={(params) => <TextField {...params} label={___PHRASE("comuna")}
                     error={!!errors.iddistrict}
                     helperText={errors.iddistrict && ___PHRASE("campoObligatorio")} />}
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Input
                     ref={ref => inputRefs.current[1] = ref}
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <PhoneIcon />

                        </InputAdornment>
                     }
                     name="telefono"
                     placeholder={___PHRASE("telefonoFijo")}
                     value={valuePersonalData.telefono}
                     onChange={handleInput}
                     error={!!errors.telefono}
                     errorMessages={errors.telefono && ___PHRASE("campoObligatorio")}
                     disableElevation
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                     height='38px'
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Input
                     ref={ref => inputRefs.current[1] = ref}
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <PhoneAndroidIcon />                           
                        </InputAdornment>
                     }
                     name="celular"
                     placeholder={___PHRASE("celular")}
                     value={valuePersonalData.celular}
                     onChange={handleInput}
                     error={!!errors.celular}
                     errorMessages={errors.celular && ___PHRASE("campoObligatorio")}
                     disableElevation
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                     height='38px'
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Input
                     ref={ref => inputRefs.current[1] = ref}
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <PhoneAndroidIcon />                           
                        </InputAdornment>
                     }
                     name="emergency_phone"
                     placeholder={___PHRASE("celularDeEmergencia")}
                     value={valuePersonalData.emergency_phone}
                     onChange={handleInput}
                     error={!!errors.emergency_phone}
                     errorMessages={errors.emergency_phone && ___PHRASE("campoObligatorio")}
                     disableElevation
                     dense    
                     disabled={isDisabled}
                     className='borderInput'
                     height='38px'
                  />
               </Grid>
               <Grid item xs={12} sm={3}>
                  <Input
                     ref={ref => inputRefs.current[1] = ref}
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <AlternateEmailIcon />                          
                        </InputAdornment>
                     }
                     name="email"
                     placeholder={___PHRASE("correo")}
                     value={valuePersonalData.email}
                     onChange={handleInput}
                     error={!!errors.email}
                     errorMessages={errors.email && ___PHRASE("campoObligatorio")}
                     disableElevation
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                     height='38px'
                  />
               </Grid>

               <Grid item container
                  xs={12}
                  justifyContent='right'
               >
                  <Grid item>
                     <Button
                        type='submit'
                        variant='contained'
                        onClick={(e)=>{handleSubmit(e)}}
                        disabled={loading}
                        disableElevation
                        className='btn_save'
                        sx={{ml:2}}
                        endIcon={<ArrowRightIcon />}
                     >
                        {
                           loading ?
                              <CircularProgress className="CircularProgress" size={24} /> :
                              <span>{___PHRASE("siguiente")}</span>
                        }
                     </Button>
                     

                  </Grid>
               </Grid>
            </Grid>
         </form>
      </Container>
      </>
   );
}
