import { EmployeeExpenseRepository } from "../repositories/EmployeeExpense.repository";

export const EmployeeExpenseService = {
    getExpensesByEmployeeSession,
    getDetailsExpense,
    verifyExpenseEmployee,
    listReportType,
    createExpenseEmployee,
    createDetailExpenseEmployee,
    editReportDetail,
    deleteExpenseEmployee,
    deleteDetail,
}

async function getExpensesByEmployeeSession(status, period){
   const res = await EmployeeExpenseRepository.getExpensesByEmployeeSession(status, period);
   return res;
}

async function getDetailsExpense(idExpense:any, review_status:any, report_type:any, status:any){
   const res = await EmployeeExpenseRepository.getDetailsExpense(idExpense, review_status, report_type, status);
   return res;
}

async function verifyExpenseEmployee(year:any, month:any){
    const res = await EmployeeExpenseRepository.verifyExpenseEmployee(year, month);
    return res;
}

async function listReportType(){
const res = await EmployeeExpenseRepository.listReportType();
return res;
}

async function createExpenseEmployee(data:any){
   const res = await EmployeeExpenseRepository.createExpenseEmployee(data);
   return res;
}

async function createDetailExpenseEmployee(idExpense:number, data:any){
   const res = await EmployeeExpenseRepository.createDetailExpenseEmployee(idExpense, data);
   return res;
}

async function editReportDetail(idDetail: number, data:any){
   const res = await EmployeeExpenseRepository.editReportDetail(idDetail, data);
   return res;
}

async function deleteExpenseEmployee(idExpense:number){
   const res = await EmployeeExpenseRepository.deleteExpenseEmployee(idExpense);
   return res;
}

async function deleteDetail(idDetail:number){
   const res = await EmployeeExpenseRepository.deleteDetail(idDetail);
   return res;
}
