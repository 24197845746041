import { useEffect, useState } from "react"
import { TableDataV2 } from "@/components/common/Tablev2"
import { Loading } from "@/components/common/Loading"
import { Props } from "./SpecificationJobOffert.type"
import { JobOffertService } from "@/service/services/JobOffert.service"
import { ModalJobOffertSpecification } from "@/components/Modal/ModalJobOffertSpecification"
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper"
import ___PHRASE from "@/service/translate/translateService"

export const SpecificationJobOffert: React.FC<Props> = (props:any): JSX.Element => {

    const { setShowTeam,  setView, currentView } = props;

    const currentJobOffert = readLocalStorage('newJobOffert');

    const [loading, setLoading] = useState<boolean>(false);
    const [specifications, setSpecifications] = useState([]);
    const [showSpecificationModal, setShowSpecificationModal] = useState<boolean>(false);
    const [actionSelected, setActionSelected] = useState<String>('');
    const [SpecificationSelected, setSpecificationSelected] = useState<String>('');

    useEffect(() => {
        if(currentJobOffert){
            getSpecification(currentJobOffert.id);
        }
    }, []);

    const getSpecification = async(idJobOffert) => {
        setLoading(true);
        await JobOffertService.getSpecification(idJobOffert).then((data) => {
            setSpecifications(data.data || []);
            setLoading(false);
        })
    }

    function RecuperarData(value){
        setSpecificationSelected(value)
    }

    return (
        <>
            {loading && <Loading/>}

            <TableDataV2
                title={'Especificación de ofertas laborales'}
                data={specifications || []}
                header={[
                    { name: 'specification', label: ___PHRASE("nombre"), filter: false, Chip: false },
                    // { name: 'description', label: ___PHRASE("descripcion"), filter: false, Chip: true },
                ]}
                actionSelect={setActionSelected}
                setModalSave={setShowSpecificationModal}
                status_action
                // checkbox
                RecuperarData={RecuperarData}
                currentView={currentView}
            />

            { 
                showSpecificationModal && (
                    <ModalJobOffertSpecification
                        open={showSpecificationModal}
                        closeModal={()=>{setShowSpecificationModal(false)}}
                        actionSelected={actionSelected}
                        onListSpecification={getSpecification}
                        specification={SpecificationSelected}
                        currentJobOffert={currentJobOffert}
                        setShowTeam={setShowTeam}
                        setView={setView}
                        clearState={()=>{setSpecificationSelected('')}}
                    />
                )
            }
            {/* {
                actionSelected === 'delete' && (
                    <ModalPhaseDelete
                        open={true}
                        closeModal={()=>{setShowPhaseModal(false)}}
                        actionSelected={actionSelected}
                        onListPhases={getPhases}
                        phase={phaseSelected}
                    />
                )
            } */}
        </>
    )
}