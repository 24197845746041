import React, { useState, useRef } from 'react';
import { Grid, Container, Button, Autocomplete, CircularProgress, TextField, InputAdornment, Typography } from '@mui/material';
import { Props } from './FormBankInformation.type'
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import "./FormBankInformation_style.css";
import BadgeIcon from '@mui/icons-material/Badge';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ___PHRASE from '@/service/translate/translateService';
import './style.sass'

export const FormBankInformation: React.FC<Props> = (
   props: Props
): JSX.Element => {
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loading, setLoading] = useState(false);
   const [isDisabled, setIsDisabled] = useState<boolean>(false);
   const [estado, setEstado] = useState<any>({ rut: false, nombre_rz: false })

   const [valueBankInformation, setValueBankInformation] = useState<any>(props.valueBankInformation)
   const [error, setError] = useState<any>('');
   const [errors, setErrors] = useState({
      idbank: '',
      idwaypay: '',
      idaccounttype: '',
      account_number: '',
      //idcompensationbox:'',
      last_imponible:'',
   })

   const handleInput = (event) => {
      const name = event.target.name;
      const value = event.target.value;

      setErrors((prevErrors) => ({
         ...prevErrors,
         [name]: '',
       }));

       setValueBankInformation((prevData) => ({
         ...prevData,
         [name]: value,
       }));
      // switch (name) {
      //    case 'account_number':
      //       props.setError('');
      //       setValueBankInformation(prev => ({ ...prev, account_number: value }));
      //       break;
      //    case 'last_imponible':
      //          props.setError('');
      //          setValueBankInformation(prev => ({ ...prev, last_imponible: value }));
      //          break;
      //    default:
      //       break;
      // }
   };

   const handleSubmit = async (event: React.SyntheticEvent) => {
      event.preventDefault();
     
      const newErrors:any = {};

      if (!valueBankInformation.idbank) {
         newErrors.idbank = 'idbank'
      }
      if (!valueBankInformation.idwaypay) {
         newErrors.idwaypay = 'idwaypay'
      }
      if (valueBankInformation.idwaypay != 1  && !valueBankInformation.idaccounttype) {
         newErrors.idaccounttype = 'idaccounttype'
      }
      if (valueBankInformation.idwaypay != 1  && valueBankInformation.idaccounttype != 1 && !valueBankInformation.account_number) {
         newErrors.account_number = 'account_number'
      }
      // if (!valueBankInformation.idcompensationbox) {
      //    newErrors.idcompensationbox = 'idcompensationbox'
      // }
      if (!valueBankInformation.last_imponible || valueBankInformation.last_imponible == 0) {
         newErrors.last_imponible = 'last_imponible'
      }

      setErrors(newErrors);

      if (Object.keys(newErrors).length === 0) {
         const resp =  props.createWorkers(valueBankInformation);
      
         try{
            if(resp){
               console.log('sin errores')
            }
         }catch(exeption){
            console.log("Error de formulario: ",exeption)
         }
      }
      
   }

   return (
      <Container maxWidth="xl">
         <Grid container >

            <Typography>INFORMACIÓN BANCARIA</Typography>
            <Grid item container xs={12} alignItems="center" justifyContent="space-between"
                  sx={{border: '1px #b9b1a9 solid', marginTop: '10px', marginBottom: '15px', padding:'15px'}} > 
               <Grid item xs={12} sm={3} paddingRight={2}>
                  <Autocomplete
                        size='small'
                        id="combo-box-demo"
                        options={props.dataInitial.Bank}
                        onChange={(e, value:any)=>{setValueBankInformation({...valueBankInformation, idbank: value?value.id:null}); 
                        setErrors((prevErrors) => ({
                           ...prevErrors,
                           idbank: '',
                         }))
                     }}
                        getOptionLabel={(option: any) => option.name ? option.name : ''}
                        value={
                              valueBankInformation?.idbank?(
                                 props.dataInitial.Bank.filter((value)=>{return value.id == valueBankInformation?.idbank})[0]
                              ):(
                                 null
                              )
                           }
                        renderInput={(params) => <TextField {...params} label={___PHRASE("banco")}
                        error={!!errors.idbank} 
                        helperText={errors.idbank && ___PHRASE("campoObligatorio")}/>}
                     />
               </Grid>
               <Grid item xs={12} sm={3} paddingRight={2}>
                  <Autocomplete
                        size='small'
                        id="combo-box-demo"
                        options={props.dataInitial.WayPay}
                        onChange={(e, value:any)=>{setValueBankInformation({...valueBankInformation, idwaypay: value?value.id: null}); 
                        setErrors((prevErrors) => ({
                           ...prevErrors,
                           idwaypay: '',
                         }))
                     }}
                        getOptionLabel={(option: any) => option.name ? option.name : ''}
                        value={
                              valueBankInformation?.idwaypay?(
                                 props.dataInitial.WayPay.filter((value)=>{return value.id == valueBankInformation?.idwaypay})[0]
                              ):(
                                 null
                              )
                           }
                        renderInput={(params) => <TextField {...params} label={___PHRASE("formaDePago")}
                        error={!!errors.idwaypay} 
                        helperText={errors.idwaypay && ___PHRASE("campoObligatorio")}/>}
                     />
               </Grid>
               <Grid item xs={12} sm={3} paddingRight={2}>
                  <Autocomplete
                        size='small'
                        id="combo-box-demo"
                        options={props.dataInitial.AccountType}
                        onChange={(e, value:any)=>{setValueBankInformation({...valueBankInformation, idaccounttype:value? value.id:null}); 
                        setErrors((prevErrors) => ({
                           ...prevErrors,
                           idaccounttype: '',
                         }))
                     }}
                        getOptionLabel={(option: any) => option.name ? option.name : ''}
                        value={
                              valueBankInformation?.idaccounttype?(
                                 props.dataInitial.AccountType.filter((value)=>{return value.id == valueBankInformation?.idaccounttype})[0]
                              ):(
                                 null
                              )
                           }
                        renderInput={(params) => <TextField {...params} label={___PHRASE("tipoDeCuenta")}
                        error={!!errors.idaccounttype} 
                        helperText={errors.idaccounttype && ___PHRASE("campoObligatorio")}/>}
                     />
               </Grid>                 
               <Grid item xs={12} sm={3}>
                  <Input
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <BadgeIcon />
                        </InputAdornment>
                     }
                     ref={ref => inputRefs.current[0] = ref}
                     name="account_number"
                     type='text'
                     placeholder={___PHRASE("numero")}
                     value={valueBankInformation.account_number}
                     onChange={handleInput}
                     disableElevation
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                     error={!!errors.account_number}
                     errorMessages={errors.account_number && ___PHRASE("campoObligatorio")}
                     height='38px'
                  />
               </Grid>
            </Grid>

            {/* <Typography>CAJA DE COMPENSACIÓN</Typography>
            <Grid item xs={12} container alignItems="center" justifyContent="space-between"
                  sx={{border: '1px #b9b1a9 solid', marginTop: '10px', padding:'15px' , marginBottom: '15px'}} >
               <Grid item xs={12} sm={3} >
                  <Autocomplete
                        size='small'
                        id="combo-box-demo"
                        options={props.dataInitial.Compensationbox}
                        onChange={(e, value:any)=>{setValueBankInformation({...valueBankInformation, idcompensationbox: value?value.id:null}); 
                        setErrors((prevErrors) => ({
                           ...prevErrors,
                           idcompensationbox: '',
                         }))
                     }}
                        getOptionLabel={(option: any) => option.name ? option.name : ''}
                        value={
                              valueBankInformation?.idcompensationbox?(
                                 props.dataInitial.Compensationbox.filter((value)=>{return value.id == valueBankInformation?.idcompensationbox})[0]
                              ):(
                                 null
                              )
                           }
                        renderInput={(params) => <TextField {...params} label={___PHRASE("cajaDeCompensacion")}
                        error={!!errors.idcompensationbox} 
                        helperText={errors.idcompensationbox && ___PHRASE("campoObligatorio")}/>}
                     />
               </Grid>
            </Grid> */}
            {/* <Typography>MUTUAL</Typography>
            <Grid item xs={12} container alignItems="center" justifyContent="space-between"
                  sx={{border: '1px #b9b1a9 solid', marginTop: '10px', padding:'15px' , marginBottom: '15px'}} >
               <Grid item xs={12} sm={3} >
                  <Autocomplete
                        size='small'
                        id="combo-box-demo"
                        options={props.dataInitial.Mutuality}
                        onChange={(e, value:any)=>{setValueBankInformation({...valueBankInformation, idmutuality: value?value.id:null}); 
                        setErrors((prevErrors) => ({
                           ...prevErrors,
                           idmutuality: '',
                         }))
                     }}
                        getOptionLabel={(option: any) => option.name ? option.name : ''}
                        value={
                              valueBankInformation?.idmutuality?(
                                 props.dataInitial.Mutuality.filter((value)=>{return value.id == valueBankInformation?.idmutuality})[0]
                              ):(
                                 null
                              )
                           }
                        renderInput={(params) => <TextField {...params} label="Mutual"/>}
                     />
               </Grid>
            </Grid> */}
            <Typography>ÚLTIMO IMPONIBLE</Typography>
            <Grid item xs={12} container alignItems="center" justifyContent="space-between"
                  sx={{border: '1px #b9b1a9 solid', marginTop: '10px', padding:'15px', marginBottom: '15px'}} >
               <Grid item xs={12} sm={3} >
                  <Input
                     prependInnerAdornment={
                        <InputAdornment position="end">
                           <BadgeIcon />
                        </InputAdornment>
                     }
                     ref={ref => inputRefs.current[0] = ref}
                     name="last_imponible"
                     type='number'
                     placeholder={"Último Imponible"}
                     value={valueBankInformation.last_imponible}
                     onChange={handleInput}
                     disableElevation
                     dense
                     disabled={isDisabled}
                     className='borderInput'
                     error={!!errors.last_imponible}
                     errorMessages={errors.last_imponible && ___PHRASE("campoObligatorio")}
                     height='38px'
                  />
               </Grid>
            </Grid>

            <Grid item container justifyContent='space-between' >
                  <Grid item xs={6}>
                     <Button
                        variant='contained'
                        onClick={() => { props.setValueBankInformation(valueBankInformation); props.handleBack(); }}
                        disabled={loading}
                        disableElevation
                        className='btn_back'
                        startIcon={<ArrowBackIosIcon />}
                     >
                        {
                           loading ?
                              <CircularProgress className="CircularProgress" size={24} /> :
                              <span>{___PHRASE("atras")}</span>
                        }
                     </Button>
                  </Grid>
               <Grid item xs={6} container justifyContent='right'>
               <Button
                     type='submit'
                     variant='contained'
                     onClick={handleSubmit}
                     disabled={loading}
                     disableElevation
                  >
                     {
                        loading ?
                           <CircularProgress className="CircularProgress" size={24} /> :
                           props.isEdit? <span>{___PHRASE("actualizar")}</span> : <span>{___PHRASE("guardar")}</span>
                     }
               </Button>
               </Grid>
            </Grid>
         </Grid>
      </Container>
   );
}
