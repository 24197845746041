import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

const dataUser = readLocalStorage(KEY_USER_DATA);
const idcompany = dataUser?.user?.id_company;

export const EmploymentHistoryRepository = {

    getEmploymentHistory: async(idEmployee: number): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/employmentHistory/${idEmployee}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    createEmploymentHistory: async (dataHistory) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/employmentHistory`, {
            idemployee: dataHistory.idemployee,
            idtype_contract: dataHistory.idtype_contract,
            status_contract: dataHistory.status_contract,
            start_date: dataHistory.start_date,
            end_date: dataHistory.end_date,
            causal: dataHistory.causal,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    editEmploymentHistory: async (dataHistory, idHistory: number) : Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/employmentHistory/${idHistory}`, {
            idcompany: idcompany,
            idemployee: dataHistory.idemployee,
            idtype_contract: dataHistory.idtype_contract,
            status_contract: dataHistory.status_contract,
            start_date: dataHistory.start_date,
            end_date: dataHistory.end_date,
            causal: dataHistory.causal,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    deleteEmploymentHistory: async(idHistory:number): Promise<any> => {
        const res= await http.delete<any>(`${API_URL_BASE}/v1/employmentHistory/${idHistory}`)
        return res;
    },
}   