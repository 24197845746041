import { branchOfficeRepository } from "../repositories/BranchOffice.repository";

export const branchOfficeService = {
   getBranchOffices,
   getBranchOfficesByCompany,
   getBranchTypes,
   createBranch,
   editBranch,
   deleteBranch,
   createTypeBranchOffice,
   updateTypeBranchOffice,
   deleteTypeBranchOffice,
}

async function getBranchOffices(){
   const res = await branchOfficeRepository.getBranchOffices();
   return res;
}
async function getBranchOfficesByCompany(idCompany){
   const res = await branchOfficeRepository.getBranchOfficesByCompany(idCompany);
   return res;
}

async function createBranch(data:{}){
   const res = await branchOfficeRepository.createBranch(data);
   return res;
}

async function editBranch(data:{}, idBranch){
   const res = await branchOfficeRepository.editBranch(data, idBranch);
   return res;
}

async function deleteBranch(idBranch:number){
   const res = await branchOfficeRepository.deleteBranch(idBranch);
   return res;
}

async function getBranchTypes(){
   const res = await branchOfficeRepository.getBranchTypes();
   return res;
}

async function createTypeBranchOffice(data:{}){
   const res = await branchOfficeRepository.createTypeBranchOffice(data);
   return res;
}

async function updateTypeBranchOffice(data:{}, idTypeBranch){
   const res = await branchOfficeRepository.updateTypeBranchOffice(data, idTypeBranch);
   return res;
}

async function deleteTypeBranchOffice(idTypeBranch:number){
   const res = await branchOfficeRepository.deleteTypeBranchOffice(idTypeBranch);
   return res;
}
