import { PermissionsRepository } from "../repositories/Permissions.repository";

export const PermissionsService = {
    getPermissions,
    getPermissionsByUser,
    createPermission,
    editPermission,
    deletePermission,
}

async function getPermissions(){
    const res = await PermissionsRepository.getPermissions();
    return res;
}

async function getPermissionsByUser(idUser: number, idCompany){
    const res = await PermissionsRepository.getPermissionsByUser(idUser, idCompany);
    return res;
}

async function createPermission(data:any){
    const res = await PermissionsRepository.createPermission(data);
    return res;
}

async function editPermission(idPermission ,data:any){
    const res = await PermissionsRepository.editPermission(idPermission, data);
    return res;
}

async function deletePermission(idPermission){
    const res = await PermissionsRepository.deletePermission(idPermission);
    return res;
}