import { OnBoardingRepository } from '../repositories/OnBoarding.repository';

export const OnBoardingService = {
    getPlantillaStructureExcel,
    uploadPlantillaStructureExcel,
    getStructureOnboarding,
    getPlantillaEmployeeStructureExcel,
    uploadPlantillaEmployeeStructureExcel,
    getStructureEmployeeOnboarding
}

async function getPlantillaStructureExcel(){
    const res = await OnBoardingRepository.getPlantillaStructureExcel();
    return res;
}

async function uploadPlantillaStructureExcel(params){
    const res = await OnBoardingRepository.uploadPlantillaStructureExcel(params);
    return res;
}

async function getStructureOnboarding(idcompany){
    const res = await OnBoardingRepository.getStructureOnboarding(idcompany);
    return res;
}

async function getPlantillaEmployeeStructureExcel(params){
    const res = await OnBoardingRepository.getPlantillaEmployeeStructureExcel(params);
    return res;
}

async function uploadPlantillaEmployeeStructureExcel(params){
    const res = await OnBoardingRepository.uploadPlantillaEmployeeStructureExcel(params);
    return res;
}

async function getStructureEmployeeOnboarding(idcompany){
    const res = await OnBoardingRepository.getStructureEmployeeOnboarding(idcompany);
    return res;
}
