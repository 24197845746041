import React, { useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Loading } from "@components/common/Loading";
import { Container} from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { Props } from './LiquidationProject.type';
import ___PHRASE from '@/service/translate/translateService';

export const LiquidationProjectView: React.FC<Props> = (props:any): JSX.Element => {

   const [loading, setLoading] = useState<boolean>(false);
   const [liquidationProjects, setliquidationProjects] = useState<any>([]);
   const [LiquidationProjectSelected, setLiquidationProjectSelected] = useState<any>('');
   const [showLiquidationProjectModal, setShowLiquidationProjectModal] = useState<boolean>(false);
   const [showLiquidationProjectDeleteModal, setShowLiquidationProjectDeleteModal] = useState<boolean>(false);
   const [actionSelected, setActionSelected] = useState<any>('');

   function RecuperarData(value){
      console.log(value);
      setLiquidationProjectSelected(value);
   }

  return (
      <Protected>
         {loading && <Loading/>}

         <Container>
            <TableDataV2
               title={___PHRASE("liquidacionesPorProyecto")}
               data={liquidationProjects}
               header={[
                  { name: 'code', label: ___PHRASE("codigo"), filter: false, Chip: false },
                  { name: 'address', label: ___PHRASE("direccion"), filter: false, Chip: true },
                  { name: 'phone', label: ___PHRASE("telefono"), filter: false, Chip: false },
               ]}
               actionSelect={setActionSelected}
               setModalSave={setShowLiquidationProjectModal}
               setModalDelete={setShowLiquidationProjectDeleteModal}
               status_action
               // checkbox
               RecuperarData={RecuperarData}
            />
         </Container>
      
      </Protected>
   )
};