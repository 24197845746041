import React, { useEffect, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Loading } from "@components/common/Loading";
import { Alert, Box, Breadcrumbs, Button, Container, Snackbar, Typography } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { WorkerService } from '@/service/services/Worker.service';
import { emphasize, styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import GroupsIcon from '@mui/icons-material/Groups';
import { Props } from './Annexed.type';
import { ContractService } from '@/service/services/Contract.service';
import { dateFormat, dateFormatTime } from '@/toolbox/helpers/date.helper';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import ___PHRASE from '@/service/translate/translateService';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

export const AnnexedView: React.FC<Props> = (props:any): JSX.Element => {

    const [loading, setLoading] = useState<boolean>(false);
    const [workers, setWorkers] = useState<any>([]);
    const [workerSelected, setWorkerSelected] = useState<any>('');
    const [annexed, setAnnexed] = useState<any>([]);
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    })
     
    const [actionSelected, setActionSelected] = useState<any>('');
    const [view, setView] = useState<String>('workers');
    const [showAnnexed, setShowAnnexed] = useState<boolean>(false);

    const cabecera = [
        { name: 'n_document', label: ___PHRASE("rutTrabajador") },
        { name: 'surname', label: ___PHRASE("apellidoPaterno") },
        { name: 'second_surname', label: ___PHRASE("apellidoMaterno") },
        { name: 'name', label: ___PHRASE("nombres") }];

    const cabeceraAnnexed = [
        { name: 'column_name', label: ___PHRASE("campo") },
        { name: 'description_before', label: ___PHRASE("antes") },
        { name: 'description_after', label: ___PHRASE("despues") },
    ];

    useEffect(()=> {
        getWorkers()
    },[]);


    useEffect(()=> {
        if(workerSelected){
            //necesito el id del contrato
            getAnnexedByEmployee()
        }
    },[workerSelected]);

    const getWorkers = async() => {
        setLoading(true);
        const resp:any = await WorkerService.getWorkers();
        setWorkers(resp.data || []);
        setLoading(false);
    }

    const getAnnexedByEmployee = async() => {
        setLoading(true);
        const resp = await WorkerService.getContracts();
        const empContract = resp.data.find(res => { return res.idemployee == workerSelected.id })
        const respAnnexed = await ContractService.getAnnexed(empContract.id)
            if(respAnnexed.data.length == 0){
                setView('workers');
                setWorkerSelected('');
                setShowAnnexed(false);
                setSnackBarConfig(prev => ({ ...prev, open: true, severity:'info', message: ___PHRASE("noExistenAnexosEnElContrato") }))
                setLoading(false);
                return
            } else {
                setShowAnnexed(true);
                setView('annexed');
                setAnnexed(respAnnexed.data || []);
            }
            setLoading(false);
    }

    const viewDocument = async(id) =>{
        const pdfResp: any = await ContractService.getDocumentAnnexed(id);
        if (pdfResp) {
            const blob = new Blob([pdfResp], { type: 'application/pdf' });
            const blobURL = URL.createObjectURL(blob);
            window.open(blobURL, '_blank');
            //aca podemos crear para que se visualize en un ruta y despues de ello atrapar el blob con el mensaje de detail en la parte superior
        }
    }

    function RecuperarData(value){
        console.log(value);
        !workerSelected && setWorkerSelected(value);
    }

    const searchData = async (value) => {
       const resp = await WorkerService.getWorkers(value);
       setWorkers(resp.data || []);
    }

    return (
        <Protected>
            {loading && <Loading/>}
            <Snackbar
                open={snackBarConfig.open} 
                autoHideDuration={snackBarConfig.autoHideDuration} 
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
                <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                    {snackBarConfig.message || ""}
                </Alert>
            </Snackbar>
            <Container>
            <Breadcrumbs aria-label="breadcrumb" sx={{marginY: 3, ml:2}}>
                <StyledBreadcrumb
                    label={___PHRASE("listaDeEmpleados")}
                    onClick={() => {
                        setView('workers');
                        setWorkerSelected('');
                        setShowAnnexed(false);
                    }}
                    icon={<GroupsIcon fontSize="small" />}
                />
                {
                    showAnnexed && (
                        <StyledBreadcrumb
                            label={___PHRASE("anexos")}
                            onClick={() => {setView('annexed')}}
                            icon={<GroupsIcon fontSize="small" />}
                        />
                    )
                }
            </Breadcrumbs>
            {
                view == 'workers' && (
                    <TableDataV2
                        title={___PHRASE("anexosPorEmpleado")}
                        data={workers}
                        header={cabecera}
                        actionSelect={setActionSelected}
                        status_action
                        disabled_title={false}
                        btn_disabled
                        btn_edit_disable={true}
                        btn_delete_disable={true}
                        btn_helper
                        iconBtnHelper={<FileCopyIcon fontSize="small" ></FileCopyIcon>}
                        toolTipBtnHelper={___PHRASE("verAnexos")}
                        currentView={view}
                        textSearch={___PHRASE("buscarTrabajador")}
                        searchData={searchData}
                        RecuperarData={RecuperarData}
                        />
                )
            }
            {
                view == 'annexed' && (
                    annexed.map((item)=>{
                        return (
                            <>
                                <Box
                                    sx={{
                                        alignItems: 'center',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        flexWrap: 'wrap',
                                        m: -1,
                                        mt:5
                                    }}
                                >
                                    {!props?.disabled_title && <Typography variant='h6' component='h1' gutterBottom className="tittle"
                                        sx={{ m: 1, fontWeight: 600 }}
                                    >
                                        { (___PHRASE("anexosDe")+`"${workerSelected?.name}" - ${dateFormat(item[0].date)} ${dateFormatTime(item[0].date)}`).toUpperCase()}
                                    </Typography>}
                                    <Box sx={{ m: 1 }}>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={()=>{viewDocument(item[0].id)}}
                                        >
                                            {___PHRASE("verDocumento")}
                                        </Button>
                                    </Box>
                                </Box>
                            <TableDataV2
                              title={___PHRASE("anexosDe")+`"${workerSelected?.name}" - ${dateFormat(item[0].date)} ${dateFormatTime(item[0].date)}`}
                              data={item}
                              header={cabeceraAnnexed}
                              actionSelect={setActionSelected}
                              disabled_title={true}
                              btn_disabled={true}
                              btn_edit_disable={true}
                              btn_delete_disable={true}
                              currentView={view}
                              RecuperarData={RecuperarData}
                            />
                          </>
                        )
                       
                    }) 
                    
                )
            }
            </Container>
        </Protected>
    )
};