import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik } from 'formik';
import { useEffect, useState } from "react";
import { Alert, Autocomplete, Button, Chip, Divider, Grid, Snackbar, Stack, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { Loading } from "@/components/common/Loading";
import { Props } from "./ModalProfile.type";
import { RleStructureService } from "@/service/services/RleStructure.service";
import { ProfileService } from "@/service/services/Profile.service";
import ___PHRASE from "@/service/translate/translateService";

export const ModalProfile: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, profile, onListProfiles, clearState } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState({
        id: profile ? profile.id : '',
        name: profile ? profile.name : '',
        minimum_salary: profile ? profile.minimum_salary : '',
        maximum_salary: profile ? profile.maximum_salary : '',
        detail_structure: profile ? profile.detail_structure : []
    });

    const [structures, setStructures] = useState([]);
    const [structureSelected, setStructureSelected] = useState<any>({});
    const [arrayDetail, setArrayDetail] = useState([]);
    const [dataDetail, setDataDetail] = useState({
        id: '',
        name: '',
        amount: ''
    });
    
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    useEffect(() => {
        getStructures();
    }, []);

    const getStructures = async() => {
        const requireStructure = 0; //values: 1 - 0
        setLoading(true);
        await RleStructureService.getRLEStructureFilter(requireStructure).then((data) => {
            const resp = data.data;
            const newData = [];
            resp.length > 0 && resp.map((item) => {
                newData.push({
                    id: item.id,
                    name: item.name
                })
            });
            setStructures(newData || []);
            setLoading(false);
        })
    }

    const addStructure = (newStructure) => {
        if(dataDetail.amount == ''){
            return (
                setSnackBarConfig(prev => ({ ...prev, open: true, severity:'warning', message: ___PHRASE("debeInsertarElMontoDeLaEstructura") }))
            );
        }

        let exits = false;
        if(arrayDetail.length > 0){
            for (let i = 0; i < arrayDetail.length; i++) {
                if(arrayDetail[i].name === newStructure.name){
                    exits = true;
                }
            }
        }
        if(!exits){
            setArrayDetail([
                ...arrayDetail,
                newStructure
            ]);
        }else{
            return (
                setSnackBarConfig(prev => ({ ...prev, open: true, severity:'warning', message: `${newStructure.name}`+___PHRASE("yaSeEncuentraSeleccionado") }))
            )
        }
        setStructureSelected({});
        setDataDetail({
            id: '',
            name: '',
            amount: ''
        });
    }

    const removeStructure = (index) => {
        let array: Array<any> = arrayDetail;
        if(array.length > 0){
            array.splice(index, 1);
        }else{
            alert(___PHRASE("noAgregadoNada"))
        }
        setArrayDetail([...arrayDetail])
    }

    const validateForm = (values) => {
        let errors: any = {};
        if(!values.name) errors.name = ___PHRASE("nombreRequerido");
        if(!values.minimum_salary) errors.minimum_salary = "minimo salario requerido";
        if(!values.maximum_salary) errors.maximum_salary = "maximo salario requerido";
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        const formData = {
            id: values.id,
            name: values.name,
            minimum_salary : values.minimum_salary,
            maximum_salary : values.maximum_salary,
            detail_structure: arrayDetail || []
        }
        setLoading(true);
        if(profile && actionSelected=='edit'){
            await ProfileService.updateProfile(profile.id, formData).then((data) => {
                onListProfiles && onListProfiles();
                closeModal && closeModal();
            })
        }else{
            await ProfileService.createProfile(formData).then((data) => {
                setSnackBarConfig(prev => ({ ...prev, open: true, severity:'success', message: ___PHRASE("registroExitoso") }));
                closeModal && closeModal();
                onListProfiles && onListProfiles();
            })
        }
        setLoading(false);
    }

    return(
        <>
        {loading && <Loading/>}

        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>

        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={((profile && actionSelected=='edit') ? ___PHRASE("editar") : ___PHRASE("agregar"))+' '+___PHRASE("perfil")}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("nombre")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="name"
                                            variant="standard"
                                            type="text" 
                                            name="name"
                                            fullWidth
                                            value={values.name}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("nombreDelPerfil")}
                                            error={errors.name && touched.name ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("salarioMinimo")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="minimum_salary"
                                            variant="standard"
                                            type="number" 
                                            name="minimum_salary"
                                            multiline
                                            fullWidth
                                            value={values.minimum_salary}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("salarioMinimo")}
                                            error={errors.minimum_salary && touched.minimum_salary ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("salarioMaximo")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="maximum_salary"
                                            variant="standard"
                                            type="number" 
                                            name="maximum_salary"
                                            multiline
                                            fullWidth
                                            value={values.maximum_salary}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("salarioMaximo")}
                                            error={errors.maximum_salary && touched.maximum_salary ? true : false}
                                        />
                                        </Grid>
                                    </Grid>
                                    <Divider style={{}}/>

                                    {
                                        actionSelected =='save' && (
                                            <>

                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={12}>
                                                <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("agregarEstructuras")+' ('+___PHRASE("opcional")+')'}</b></Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={4}>
                                                <Typography variant="body1"><b>{___PHRASE("perfil")}:</b> </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Autocomplete
                                                        id="disable-portal"
                                                        disablePortal
                                                        value={structureSelected}
                                                        options={structures || []}
                                                        getOptionLabel={(option) => option.name || ""}
                                                        fullWidth
                                                        renderInput={(params) => <TextField {...params} variant="standard" label={___PHRASE("escribeParaBuscar")} />}
                                                        onChange={(e, newValue) => {  
                                                            setStructureSelected(newValue); 
                                                            setDataDetail({...dataDetail, id: newValue?.id, name: newValue?.name}); 
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("monto")}:</b></Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                <TextField
                                                    id="amount"
                                                    variant="standard"
                                                    type="number" 
                                                    name="amount"
                                                    fullWidth
                                                    value={dataDetail.amount}
                                                    onChange={(e) => {setDataDetail({...dataDetail, amount: e.target.value})}}
                                                    placeholder={___PHRASE("montoDeEstructura")}
                                                    // error={errors.maximum_salary && touched.maximum_salary ? true : false}
                                                />
                                                </Grid>
                                            </Grid>

                                            {
                                                dataDetail.id != '' && (
                                                    <Grid item xs={12} container justifyContent="end" marginBottom='10px'>
                                                        <Button onClick={(e) => { addStructure(dataDetail) }} size="small" color={"primary"} variant="contained">
                                                          {___PHRASE("agregarEspecificacion")}
                                                        </Button>
                                                    </Grid>
                                                )
                                            }

                                            <Grid item xs={12} container justifyContent="start" marginBottom='25px'>
                                                <Stack direction="column" spacing={2}>
                                                { arrayDetail && arrayDetail.map((item,index) =>
                                                    <Stack
                                                        direction="column" spacing={2}>
                                                        <Chip onDelete={()=>removeStructure(index)} label={`${item.name} - `+___PHRASE("monto")+` ${item.amount}`} variant="outlined"/>
                                                    </Stack>
                                                )}
                                                </Stack>
                                            </Grid>
                                            </>
                                        )
                                    }

                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(profile && actionSelected=='edit') ? ___PHRASE("actualizar") : ___PHRASE("registrar")}
                                    cancelText={___PHRASE("cancelar")}
                                    onCancel={closeModal}
                                    clearState={clearState}
                                    onConfirm={handleSubmit}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}