import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

const dataUser = readLocalStorage(KEY_USER_DATA);
const idcompany = dataUser?.user?.id_company;

export const politicsRepository = {

   getTypeDocuments: async () : Promise<any>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const users = await http.get<any>(`${API_URL_BASE}/v1/company/getTypeDocument`);
      const { data, error, message } = users;
      return {
         data,
         error,
         message
      }
   },

   getDocuments: async (type) : Promise<any>=> {
    const dataUser = readLocalStorage(KEY_USER_DATA);
    const users = await http.get<any>(`${API_URL_BASE}/v1/company/getPDF?type=${type}`);
    const { data, error, message } = users;
    return {
       data,
       error,
       message
    }
    },

   uploadDocuments: async (dataForm): Promise<any> => {
      const res = await http.post<any>(`${API_URL_BASE}/v1/company/uploadPDF`,dataForm);
      const { data, error, message } = res;
      return { data, error, message };
   },

   editDocuments: async (id: number,dataForm): Promise<any> => {
      const res = await http.post<any>(`${API_URL_BASE}/v1/company/editPDF/${id}`, dataForm);
      const { data, error, message } = res;
      return { data, error, message };
   },

   deleteDocuments: async(id:number): Promise<any> => {
      const res= await http.delete<any>(`${API_URL_BASE}/v1/company/deletePDF/${id}`)
      return res;
   },
  
}


