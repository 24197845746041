import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Container } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { Loading } from '@/components/common/Loading';
import { Props } from './StructureManteiner.type';
import { RleStructureService } from '@/service/services/RleStructure.service';
import { ModalStructureManteiner } from '@/components/Modal/ModalStructureManteiner';
import { ModalStructureManteinerDelete } from '@/components/Modal/ModalStructureManteinerDelete';
import ___PHRASE from '@/service/translate/translateService';
import { apiResponseTranslate } from '@/toolbox/helpers/responseApi.helper';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_TRANSLATE, KEY_USER_DATA } from '@/toolbox/constants/local-storage';

export const StructureMaintenerView: React.FC<Props> = (props:any): JSX.Element => {

   const [loading, setLoading] = useState<boolean>(false);
   const [structureManteiners, setStructureManteiners] = useState([]);
   const [showStructureManteinerModal, setShowStructureManteinerModal] = useState<boolean>(false);
   const [showStructureManteinerDeleteModal, setShowStructureManteinerDeleteModal] = useState<boolean>(false);
   const [actionSelected, setActionSelected] = useState<String>('');
   const [structureManteinerSelected, setStructureManteinerSelected] = useState<String>('');

   const language = readLocalStorage(KEY_TRANSLATE);
   const dataUser = readLocalStorage(KEY_USER_DATA);
   const typeSuperadmin = dataUser?.user?.type_superadmin;

   useEffect(() => {
    getStructureManteiner()
   },[]);

   const getStructureManteiner = async () => {
      setLoading(true);
      const resp = await RleStructureService.getRLEStructure();
      const newData = [];
      resp.data && (resp.data).length > 0 && (resp.data).map((item)=>{
         if(language && language == 'en'){
            newData.push({
               ...item, 
               name: apiResponseTranslate(item.name),
               typestructure: apiResponseTranslate(item.typestructure),
               country_name: apiResponseTranslate(item.country_name),
            })
         }else{
            newData.push(item)
         }
      })

      setStructureManteiners(newData || []);
      setLoading(false);
   }

   function RecuperarData(value){
      setStructureManteinerSelected(value);
   }

   const searchData = async (value) => {
      const resp = await RleStructureService.getRLEStructure(value);
      const newData = [];
      resp.data && (resp.data).length > 0 && (resp.data).map((item)=>{
         if(language && language == 'en'){
            newData.push({
               ...item, 
               name: apiResponseTranslate(item.name),
               typestructure: apiResponseTranslate(item.typestructure),
               country_name: apiResponseTranslate(item.country_name),
            })
         }else{
            newData.push(item)
         }
      })

      setStructureManteiners(newData || []);
   }

  return (
      <Protected>
         {loading && <Loading/>}
         <Container>
            <TableDataV2
               title={___PHRASE("estructurasDeLiquidacion")}
               data={structureManteiners}
               header={[
                  { name: 'code', label: ___PHRASE("codigo"), filter: false, Chip: true },
                  { name: 'name', label: ___PHRASE("nombre"), filter: false, Chip: true },
                  { name: 'typestructure', label: ___PHRASE("estructurasDeLiquidacion"), filter: false, Chip: true },
                  { name: 'required_name', label: ___PHRASE("obligatorio"), filter: false, Chip: true },
                  { name: 'gratification_name', label: ___PHRASE("Gratificacion"), filter: false, Chip: true },
                  { name: 'concept_DT', label: ___PHRASE("conceptoDT"), filter: false, Chip: true },
                  { name: 'country_name', label: ___PHRASE("pais"), filter: false, Chip: true },
               ]}
               actionSelect={setActionSelected}
               setModalSave={setShowStructureManteinerModal}
               setModalDelete={setShowStructureManteinerDeleteModal}
               status_action = {typeSuperadmin == '1' ? true : false}
               btn_disabled={ typeSuperadmin == '2' ? true : false}
               textSearch={___PHRASE("buscarLRE")}
               searchData={searchData}
               // checkbox
               RecuperarData={RecuperarData}
            />
         </Container>

         { 
            showStructureManteinerModal && (
               <ModalStructureManteiner
                  open={showStructureManteinerModal}
                  closeModal={()=>{setShowStructureManteinerModal(false)}}
                  actionSelected={actionSelected}
                  onListStructure={getStructureManteiner}
                  structure={structureManteinerSelected}
                  clearState={()=>{setStructureManteinerSelected('')}}
               />
            )
         }
         {
            actionSelected === 'delete' && (
               <ModalStructureManteinerDelete
                  open={showStructureManteinerDeleteModal}
                  closeModal={()=>{setShowStructureManteinerDeleteModal(false)}}
                  actionSelected={actionSelected}
                  onListStructure={getStructureManteiner}
                  structure={structureManteinerSelected}
                  clearState={()=>{setStructureManteinerSelected('')}}
               />
            )
         }
      
      </Protected>
   )
};