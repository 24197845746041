import React, { useEffect, useRef, useState } from 'react';
import { readLocalStorage } from '../toolbox/helpers/local-storage-helper';
import { KEY_TOKEN_KYTE, KEY_USER_DATA } from '../toolbox/constants/local-storage';
import Pusher from "pusher-js";
import Echo from "laravel-echo";
import toast, { Toaster } from 'react-hot-toast';
import { Link, useHistory, LinkProps } from "react-router-dom";
import { LocationState,  } from 'history';
// import { notifyService } from '@/service/services/Notify.service';
import { Props } from './KyteApp.type'
import * as Routes from '@constants/route-map';

interface MyLinkProps extends LinkProps {
   state: LocationState;
 }

export const KyteApp: React.FC<Props> = (props:any): JSX.Element => {

   window['Pusher'] = require('pusher-js');
   const data: any = readLocalStorage(KEY_USER_DATA) ||[]
   const history = useHistory();

   const redirect = (url: string) => {
      switch (url) {
         case '/planillas-remuneraciones-mensuales':
               history.push(Routes.ROUTE_TEMPLATE_LIQUIDATION_MONTHLY)
            break;

         case '/mi-liquidacion-sueldo':
            console.log('redir')
               history.push('/mi-liquidacion-sueldo')
            break;

         case '/my-request':
            history.push(Routes.ROUTE_MY_REQUEST)
            break;

         case '/liquidacion-sueldo':
            history.push(Routes.ROUTE_LIQUIDATION_SALARY)
            break;

         default:
            console.log('no da')
            break;
      }

   }

useEffect(() => {
    ContNotify();
   if(data.user)
   {
      window['Echo'] = new Echo({
        broadcaster: 'pusher',
        key: 'rrhh_key',
        wsHost: window.location.hostname,
        wsPort: 6005,
        cluster: 'mt1',
      //   wssPort: 6005,
        disableStats: true,
        forceTLS: false,
        encrypted: false,
        enabledTransports: ['ws', 'wss'],
        authEndpoint: `${process.env.REACT_APP_HOST}/api/broadcasting/auth`,
        auth: {
          headers: {
            // 'Access-Control-Allow-Origin': '*',
            Accept: 'application/json',
            Authorization: `${ readLocalStorage(KEY_TOKEN_KYTE)}`
          }
        },
      })
      window['Echo'].private(`notification-${data.user.iduser}-${data.user.id_company}`).listen('NotificationsEvent', (e) => {
         if (e){
            console.log(e)
            const variable = {
               idperiod: 13,
               idemployee: 349
            }
            props.$action.actionSetListNotification(e.count)
            toast.custom((t) => (
               <div style={{padding: '1.5em', display: 'flex', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}}>
                  <Link to={Routes.ROUTE_NOTIFY} target='_blank-' style={{textDecoration: 'none'}}>
                  <div style={{flexGrow: '1', alignItems: 'flex-start', display: 'flex', marginRight: '1.5rem'}}>
                     <div style={{paddingTop: '.125rem', flexShrink: '0'}}>
                        <img style={{
                           borderRadius: '9999px',
                           width: '2.5rem',
                           height: '2.5rem',
                           maxWidth: '100%',
                        }}
                        src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" />
                     </div>
                     <div style={{flex: '1 1 0%', marginLeft: '.75rem'}}>
                        <p style={{fontWeight: '500', fontSize: '0.875rem', lineHeight: '1.25rem', margin: '0', color:'#000'}}>{e.dataSender} te notificó:</p>
                        <p style={{color: 'rgb(107 114 128)', fontSize: '.875rem', lineHeight: '1.25rem', marginTop: '.25rem', margin: '0'}}>{e.content}</p>
                     </div>
                  </div>
                  </Link>
                  <div style={{flexGrow: '2', borderLeft: '2px solid rgb(107 114 128)', borderLeftWidth: '1px', display: 'flex'}}>
                        <button onClick={() => toast.remove(t.id)}
                           style={{color: 'rgb(79 70 229)', fontWeight: '500', fontSize: '.875rem', lineHeight: '1.25rem',
                              padding: '1rem', borderColor: 'transparent', borderWidth: '1px', borderTopRightRadius: '.5rem', borderBottomRightRadius: '.5rem',
                              borderRadius: '0', justifyContent: 'center', alignItems: 'center', width: '100%', display: 'flex', outline: '2px solid transparent',
                              outlineOffset: '2px',
                              background: '#fff'
                        }}>
                           Cerrar
                        </button>
                  </div>
               </div>
            ),
            {
               duration: 6000,
            })
         }
      })
   }
},[])

const ContNotify = async () => {
   // const resNotify = await notifyService.getNotifyNotView(5, 1)
//   props.$action?.actionSetListNotification(resNotify.data?.cantidad)
}

   return (
      <>
         <Toaster
            position="bottom-right"
            reverseOrder={false}
            toastOptions={{
               style:
               {
                  background: '#c9c7c7',
                  color: '#fff'
               }
            }}
         />
      </>
   )
};




