import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { useEffect, useState } from "react";
import { Alert, Button, Grid, MenuItem, Select, Snackbar, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import { Loading } from "@/components/common/Loading";
import { useHistory } from "react-router-dom"
import { PeriodService } from "@/service/services/Period.service";
import { RemunerationService } from "@/service/services/Remuneration.service";
import { ROUTE_CUSTOM_VISUALIZER_PDF, ROUTE_PDF_LIQUIDATION_SALARY, ROUTE_MY_REQUEST } from "@/toolbox/constants/route-map";
import { readLocalStorage, saveLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import ___PHRASE from "@/service/translate/translateService";
import { apiResponseTranslate } from "@/toolbox/helpers/responseApi.helper";
import { KEY_TRANSLATE } from "@/toolbox/constants/local-storage";

export const ModalGenerateLiquidation = (props:any): JSX.Element => {

   const { open, closeModal, workerSelected, dataRequest, setDataRequest, actionSelected } = props;

   const history = useHistory();

   const [loading, setLoading] = useState<boolean>(false);
   const [periods, setPeriods] = useState<any>([]);
   const [years, setYears] = useState<any>([]);
   const [yearSelected, setYearSelected] = useState<any>('');
   const [monthSelected, setMonthSelected] = useState<any>('');
   const [btnLiquitadion, setBtnLiquitadion] = useState<boolean>(false);
   const [liquidation, setLiquidation] = useState<any>('');
   const [messageReprocess, setMessageReprocess] = useState<boolean>(false);
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
   });

   useEffect(()=> {
      getPeriods();
  },[]);

  useEffect(()=>{
   if(dataRequest){
      console.log(dataRequest.idPeriod)
      const period = periods.length > 0 ? periods.find(element => element.id == dataRequest.idPeriod) : '';
      if(period){ setYearSelected(period.year); setMonthSelected(period.month) }
      // showLiquidation({idperiod:dataRequest.idPeriod, idemployee:workerSelected.id, detail: dataRequest.messageDetail});
   }
  },[dataRequest, periods])

   const getPeriods = async() => {
      setLoading(true);
      const resp:any = await PeriodService.getPeriods();
      const newData = [];
      const arrayYears = [];
      resp.data && (resp.data).length > 0 && (resp.data).map((item) => {
         newData.push({
            id: item.id,
            month: item.month,
            year: item.year,
            alias_name: item.month + '-' +  item.year,
         })
         const found = arrayYears.find(element => element == item.year);
         if(!found) { arrayYears.push(item.year); }
      });
      setYears(arrayYears);
      setPeriods(newData || []);
      setLoading(false);
   }

   const showLiquidation = async() => {
      setLoading(true);
      const dataPdf = {
         idperiod: liquidation.idperiod,
         idemployee: liquidation.idemployee,
      }
      const pdfResp:any = await RemunerationService.generatePDFRemuneration(dataPdf);
      if(pdfResp){
         const blob = new Blob([pdfResp], {type: 'application/pdf'});
         const blobURL = URL.createObjectURL(blob);
         console.log(blobURL)
         saveLocalStorage('previewLiquidationRequest',{pdf: blobURL})
         window.open(ROUTE_PDF_LIQUIDATION_SALARY, '_blank');
      }
      setLoading(false);
      closeModal && closeModal();
      dataRequest && setDataRequest(null)
   }

   const reproccess = async() => {
      setLoading(true);
      setLoading(false);

         await RemunerationService.changeStatusRemuneration(liquidation.idemployee, liquidation.idperiod).then(async(data)=>{
            let apimessage = apiResponseTranslate(data?.data?.message)
            if(data?.data?.status == true){
               await onSubmit();
            }else{
               setLoading(false);
               setSnackBarConfig(prev => ({ ...prev, open: true,
                  severity:'error',
                  message: `${ apimessage || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}`
               }))
            }
         });
      dataRequest && setDataRequest(null)
   }

   const onSubmit = async() => {
      setLoading(true);
      setBtnLiquitadion(false)
      if(!monthSelected || !yearSelected){
         setLoading(false);
         return (
            setSnackBarConfig(prev => ({ ...prev, open: true, 
               severity:'warning', 
               message: ___PHRASE("debeLlenarTodosLosCampos") }
            ))
         );
      }

      const periodSelected = monthSelected + '-' + yearSelected;
      const period = periods.find(element => element.alias_name == periodSelected);

      const dataPre = {
         idperiod: period.id,
         month: period.month,
         year: period.year
      }
      const dataPdf = {
         idperiod: period.id,
         idemployee: workerSelected.id,
      }

      const verifyResp:any = await RemunerationService.verfifyRemuneration(workerSelected.id, period.id);
      if((verifyResp.data.message).includes('Existe')){
         let apimessage = apiResponseTranslate(verifyResp.data.message)
         const detail = verifyResp.data?.detail[0];
         setBtnLiquitadion(true);
         setLoading(false);
         setLiquidation(detail)
         return (
            setSnackBarConfig(prev => ({ ...prev, open: true, 
               severity:'warning', 
               message: `${apimessage || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}` }
            ))
         );
      }

      const preResp:any = await RemunerationService.preRemuneration(dataPre, workerSelected.id);
      if(preResp.data.status != true){
         let apimessage = apiResponseTranslate(preResp.data.message)
         setLoading(false);
         return (
            setSnackBarConfig(prev => ({ ...prev, open: true, 
               severity:'error', 
               message: `${apimessage || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}` }
            ))
         );
      }

      const pdfResp:any = await RemunerationService.generatePDFPreRemuneration(dataPdf);
      if(pdfResp){
         const blob = new Blob([pdfResp], {type: 'application/pdf'});
         const blobURL = URL.createObjectURL(blob);

         const dataRemu = {
            pdf: blobURL, 
            idperiod: period.id,
            month: period.month,
            year: period.year,
            idemployee: workerSelected.id,
            dataRequest: dataRequest ? dataRequest : '',
         }
         saveLocalStorage('dataRemu', dataRemu);
         window.open(ROUTE_CUSTOM_VISUALIZER_PDF, '_blank');

      }
      setLoading(false);
      closeModal && closeModal();
      if(dataRequest) history.push(ROUTE_MY_REQUEST)
   }
   
   return(
      <>
      {loading && <Loading/>}
      <Snackbar
         open={snackBarConfig.open} 
         autoHideDuration={snackBarConfig.autoHideDuration} 
         onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
         anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
         <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
               {snackBarConfig.message || ""}
         </Alert>
      </Snackbar>

      <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick handle size="sm">
         <ModalHeader
               text={`${actionSelected == 'helper' ? ___PHRASE("generar") : ___PHRASE("reprocesar")} `+___PHRASE("liquidacionPara")+` ${workerSelected?.name} ${workerSelected?.surname} ${workerSelected?.second_surname}`}
               className='positionElements'
               onCancel={closeModal}
               disabledButton={dataRequest ? true : false}
         >
         </ModalHeader>
         <ModalBody>
            <Grid container >
               <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                  <Grid item xs={4}>
                     <Typography variant="body1"><b>{___PHRASE("periodo")}:</b> </Typography>
                  </Grid>
                  <Grid item xs={8}>
                     <Select
                        id="id_education_level"
                        variant="standard"
                        value={yearSelected}
                        onChange={(event) => {
                           setYearSelected(event.target.value);
                           setBtnLiquitadion(false)
                        }}
                        fullWidth
                     >
                        {
                           years.map((year, i) => {
                              return <MenuItem key={i} value={year}>{year}</MenuItem>;
                           })
                        }
                     </Select>
                  </Grid>
               </Grid>

               <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                  <Grid item xs={4}>
                     <Typography variant="body1">{___PHRASE("mes")} </Typography>
                  </Grid>
                  <Grid item xs={8}>
                     <Select
                        id="id_education_level"
                        variant="standard"
                        value={monthSelected}
                        onChange={(event) => {setMonthSelected(event.target.value); setBtnLiquitadion(false)}}
                        fullWidth
                     >
                        <MenuItem value={'01'}>{___PHRASE("enero")}</MenuItem>
                        <MenuItem value={'02'}>{___PHRASE("febrero")}</MenuItem>
                        <MenuItem value={'03'}>{___PHRASE("marzo")}</MenuItem>
                        <MenuItem value={'04'}>{___PHRASE("abril")}</MenuItem>
                        <MenuItem value={'05'}>{___PHRASE("mayo")}</MenuItem>
                        <MenuItem value={'06'}>{___PHRASE("junio")}</MenuItem>
                        <MenuItem value={'07'}>{___PHRASE("julio")}</MenuItem>
                        <MenuItem value={'08'}>{___PHRASE("agosto")}</MenuItem>
                        <MenuItem value={'09'}>{___PHRASE("septiembre")}</MenuItem>
                        <MenuItem value={'10'}>{___PHRASE("octubre")}</MenuItem>
                        <MenuItem value={'11'}>{___PHRASE("noviembre")}</MenuItem>
                        <MenuItem value={'12'}>{___PHRASE("diciembre")}</MenuItem>
                     </Select>
                  </Grid>
               </Grid>

               {
                  btnLiquitadion && (
                     <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                        <Button onClick={showLiquidation} type={'submit'} size="small" color={"primary"} variant="contained" >
                           {___PHRASE("verLiquidación")}
                        </Button>
                        <Button onClick={()=> setMessageReprocess(true)} type={'submit'} sx={{ml:2}} size="small" color={"success"} variant="contained" >
                           {___PHRASE("reprocesarLiquidacion")}
                        </Button>
                     </Grid>
                  )
               }
               {
                  messageReprocess && btnLiquitadion && 
                  <Grid sx={{ justifyContent:'center', alignItems:'center', bgcolor:'#87CEFA', borderRadius:5, padding:3}}>
                     <Typography sx={{color:'#fff'}}>{___PHRASE("estasSeguroReprocesarLaLiquidacion")}</Typography>
                     <Button sx={{color:'#fff'}} color="primary" variant="contained" onClick={reproccess}>{___PHRASE("Confirmar")}</Button>
                  </Grid>
               }
            </Grid>
         </ModalBody>
         {
            dataRequest ? (
               
                  !!!btnLiquitadion && 
                  <ModalFooter 
                  buttonType="submit"
                  confirmText={___PHRASE("crearNueva")}
                  onConfirm={()=>{onSubmit()}}
               />
               
              
            ) : (
               !!!btnLiquitadion && 
               <ModalFooter 
                  buttonType="submit"
                  confirmText={___PHRASE("crearNueva")}
                  cancelText={___PHRASE("cancelar")} 
                  onCancel={closeModal}
                  onConfirm={()=>{onSubmit()}}
               />
            )
         }
      </Modal>
      </>
   );
}