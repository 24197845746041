import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik } from 'formik';
import { useEffect, useState } from "react";
import { Alert, Divider, Grid, MenuItem, Select, Snackbar, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import { Loading } from "@/components/common/Loading";
import moment from "moment";
import { Props } from "./ModalFamilyEmployee.type";
import { responsabilityFamilyService } from "@/service/services/ResponsabilityFamily.service";
import '../../../assets/styles/modals.scss';
import ___PHRASE from "@/service/translate/translateService";
import { apiResponseTranslate } from "@/toolbox/helpers/responseApi.helper";

const sexoValue = [
    { id:1, label: ___PHRASE("masculino")},
    { id:2, label: ___PHRASE("femenino") }
 ]

export const ModalFamilyEmployee: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, family, onListFamilies, workerSelected, clearState, dataInitial } = props;

    const [loading, setLoading] = useState<boolean>(false);

    const [data, setData] = useState({
        name: family ? family.name : '',
        birth_date: family ? family.birth_date : '',
        valid_since: family ? family.valid_since : '',
        valid_until: family ? family.valid_until : '',
        idrelationship: family ? family.idrelationship : '',
        sexo: family ? family.sexo : '',
        idtyperesponsabilityfamily: family ? family.idtyperesponsabilityfamily : '',
    });

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    useEffect(()=> {
    },[]);

    const validateForm = (values) => {
        let errors: any = {};
        if(!values.name) errors.name = ___PHRASE("nombreRequerido");
        if(!values.birth_date) errors.birth_date = ___PHRASE("fecharequerido");
        if(!values.valid_since) errors.valid_since = ___PHRASE("fecharequerido");
        if(!values.valid_until) errors.valid_until = ___PHRASE("fecharequerido");
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        let dateStart= '';
        let dateEnd= '';
        if(values.valid_since) dateStart = moment(values.valid_since).format("YYYY/MM/DD");
        if(values.valid_until) dateEnd = moment(values.valid_until).format("YYYY/MM/DD");

        if(values.valid_since && values.valid_until){
            if(dateStart >= dateEnd) return (
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                    severity:'warning', 
                    message:  ___PHRASE("asegureseQueLaFechaInicialSeaMenorQueLaFinal") }
                ))
            );
        }

        const formData = {
            name: values.name,
            birth_date: values.birth_date,
            valid_since : values.valid_since,
            valid_until : values.valid_until,
            idrelationship : values.idrelationship,
            sexo : values.sexo,
            idtyperesponsabilityfamily : values.idtyperesponsabilityfamily,
            idemployee : workerSelected.id,
        }
        setLoading(true);

        console.log(formData)

        if(family && actionSelected=='edit'){
            await responsabilityFamilyService.editResponsabilityFamily(formData, family.id).then((data) => {
                let apimessagge = apiResponseTranslate(data?.data?.message || '');
                if(data.data.status == true){
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'success', 
                        message: `${apimessagge || ___PHRASE("datosActualizadosSatisfactoriamente")}`}
                    ))
                    onListFamilies && onListFamilies(workerSelected.id);
                    setTimeout(function(){
                        closeModal && closeModal();
                    }, 1000);
                }else{
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'error', 
                        message: `${apimessagge || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}`}
                    ))
                }
            })
        }

        setLoading(false);
    }
    return(
        <>
        {loading && <Loading/>}

        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>

        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick handle size="sm">
            <ModalHeader
                text={___PHRASE("editarDatosDelFamiliar")}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Nombre:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="name"
                                            variant="standard"
                                            type="text" 
                                            name="name"
                                            fullWidth
                                            value={values.name}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("nombreDelFamiliar")}
                                            error={errors.name && touched.name ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Fecha de nacimiento:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                id="birth_date"
                                                variant="standard"
                                                fullWidth
                                                name="birth_date"
                                                type="date"
                                                onChange={handleChange}
                                                value={values.birth_date}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={errors.birth_date && touched.birth_date ? true : false}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Fecha vigenia inicio:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                id="valid_since"
                                                variant="standard"
                                                fullWidth
                                                name="valid_since"
                                                type="date"
                                                onChange={handleChange}
                                                value={values.valid_since}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={errors.valid_since && touched.valid_since ? true : false}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>Fecha vigenia fin:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                id="valid_until"
                                                variant="standard"
                                                fullWidth
                                                name="valid_until"
                                                type="date"
                                                onChange={handleChange}
                                                value={values.valid_until}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={errors.valid_until && touched.valid_until ? true : false}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>{___PHRASE("parentesco")}:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Select
                                                id="idrelationship"
                                                variant="standard"
                                                value={values.idrelationship}
                                                onChange={(event) => { 
                                                setFieldValue("idrelationship", event.target.value);
                                                }}
                                                error={ errors.idrelationship && touched.idrelationship ? true : false }
                                                fullWidth
                                            >
                                                {
                                                    dataInitial?.relationShip && (dataInitial.relationShip).map((relation, i) => {
                                                        return <MenuItem key={i} value={relation.id}>{relation.name}</MenuItem>;
                                                    })
                                                }
                                            </Select>
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>{___PHRASE("sexo")}:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Select
                                                id="sexo"
                                                variant="standard"
                                                value={values.sexo}
                                                onChange={(event) => { 
                                                setFieldValue("sexo", event.target.value);
                                                }}
                                                error={ errors.sexo && touched.sexo ? true : false }
                                                fullWidth
                                            >
                                                {
                                                    (sexoValue).map((sex, i) => {
                                                        return <MenuItem key={i} value={sex.id}>{sex.label}</MenuItem>;
                                                    })
                                                }
                                            </Select>
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>{___PHRASE("tipoCargaFamiliar")}:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Select
                                                id="idtyperesponsabilityfamily"
                                                variant="standard"
                                                value={values.idtyperesponsabilityfamily}
                                                onChange={(event) => { 
                                                setFieldValue("idtyperesponsabilityfamily", event.target.value);
                                                }}
                                                error={ errors.idtyperesponsabilityfamily && touched.idtyperesponsabilityfamily ? true : false }
                                                fullWidth
                                            >
                                                {
                                                    dataInitial?.typeResponsabilityFamily && (dataInitial.typeResponsabilityFamily).map((relation, i) => {
                                                        return <MenuItem key={i} value={relation.id}>{relation.name}</MenuItem>;
                                                    })
                                                }
                                            </Select>
                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={___PHRASE("actualizar")}
                                    cancelText={___PHRASE("cancelar")} 
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}