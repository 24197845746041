import React, { useEffect, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Loading } from "@components/common/Loading";
import { Props } from './BranchOffices.type'
import { Container } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { branchOfficeRepository } from '@/service/repositories/BranchOffice.repository';
import { ModalBranchOffice } from '@/components/Modal/ModalBranchOffice';
import { ModalBranchOfficeDelete } from '@/components/Modal/ModalBranchOfficeDelete';
import ___PHRASE from '@/service/translate/translateService';


export const BranchOfficeView: React.FC<Props> = (props:any): JSX.Element => {

   const [loading, setLoading] = useState<boolean>(false);
   const [branchOffices, setBranchOffices] = useState<any>([]);
   const [showBranchOfficeModal, setShowBranchOfficeModal] = useState<boolean>(false);
   const [showBranchDeleteModal, setShowBranchDeleteModal] = useState<boolean>(false);
   const [actionSelected, setActionSelected] = useState<any>('');
   const [branchOfficeSelected, setBranchOfficeSelected] = useState<any>('');

   useEffect(() => {
      getBranchOffices()
   },[]);

   const getBranchOffices = async () => {
      setLoading(true);
      const response = await branchOfficeRepository.getBranchOffices();
      if (response.data) {
         setBranchOffices(response.data || [])
      } else {
      console.log('error')
      }
      setLoading(false);
   }

   function RecuperarData(value){
      setBranchOfficeSelected(value);
   }

   const searchData = async (value) => {
      const response = await branchOfficeRepository.getBranchOffices(value);
      if (response.data) {
         setBranchOffices(response.data || [])
      } else {
      console.log('error')
      }
   }

  return (
      <Protected>
         {loading && <Loading/>}

         <Container>
            <TableDataV2
               title={___PHRASE("sucursales")}
               data={branchOffices}
               header={[
                  { name: 'code', label: ___PHRASE("codigo"), filter: false, Chip: false },
                  { name: 'address', label: ___PHRASE("direccion"), filter: false, Chip: true },
                  { name: 'nameDistrict', label: ___PHRASE("distrito"), filter: false, Chip: false },
                  { name: 'nameProvince', label: ___PHRASE("provincia"), filter: false, Chip: false },
                  { name: 'nameDepartament', label: ___PHRASE("departamento"), filter: false, Chip: false },
                  { name: 'phone', label: ___PHRASE("telefono"), filter: false, Chip: false },
               ]}
               actionSelect={setActionSelected}
               setModalSave={setShowBranchOfficeModal}
               setModalDelete={setShowBranchDeleteModal}
               status_action
               textSearch={___PHRASE(("buscarSucursal"))}
               searchData={searchData}
               RecuperarData={RecuperarData}
            />
         </Container>

         { 
            showBranchOfficeModal && (
               <ModalBranchOffice
                  open={showBranchOfficeModal}
                  closeModal={()=>{setShowBranchOfficeModal(false)}}
                  actionSelected={actionSelected}
                  onListBranchOffices={getBranchOffices}
                  branchOffice={branchOfficeSelected}
                  clearState={()=>{setBranchOfficeSelected('')}}
               />
            )
         }
         {
            actionSelected === 'delete' && (
               <ModalBranchOfficeDelete
                  open={showBranchDeleteModal}
                  closeModal={()=>{setShowBranchDeleteModal(false)}}
                  actionSelected={actionSelected}
                  onListBranchOffices={getBranchOffices}
                  branchOffice={branchOfficeSelected}
                  clearState={()=>{setBranchOfficeSelected('')}}
               />
            )
         }
      
      </Protected>
   )
};