import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik } from 'formik';
import { useEffect, useState } from "react";
import { Alert, Autocomplete, Button, Chip, Divider, Grid, MenuItem, Select, Snackbar, Stack, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import { Loading } from "@/components/common/Loading";
import { Props } from "./ModalJobOffert.type";
import { ChargeService } from "@/service/services/Charge.service";
import { JobOffertService } from "@/service/services/JobOffert.service";
import { profileRepository } from "@/service/repositories/Profile.repository";
import { saveLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import '../../../assets/styles/modals.scss';
import ___PHRASE from "@/service/translate/translateService";

export const ModalJobOffert: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, jobOffert, onListJobOfferts, setShowSpecification, setView, clearState } = props;

    const [loading, setLoading] = useState<boolean>(false);

    const [profiles, setProfiles] = useState([]);
    const [profileSelected, setProfileSelected] = useState<any>({});
    const [charges, setCharges] = useState([]);
    const [chargeSelected, setChargeSelected] = useState<any>({});
    const [specifications, setSpecifications] = useState([]);
    const [specification, setSpecification] = useState({
        specification: '',
        type: '1',
        required_doc: '1',

    });
    const workingModes = [
        { id: 1 , name: ___PHRASE("remoto")},
        {id: 2, name: ___PHRASE("presencial")},
        {id: 3, name: ___PHRASE("hibrido")},
    ];

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const [data, setData] = useState({
        id: jobOffert ? jobOffert.id : '',
        id_profile: jobOffert ? jobOffert.id_profile : '',
        start_postulation: jobOffert ? jobOffert.start_postulation : '',
        end_postulation: jobOffert ? jobOffert.end_postulation : '',
        n_available_positions: jobOffert ? jobOffert.n_available_positions : '',
        minimum_salary: jobOffert ? jobOffert.minimum_salary : '',
        maximum_salary: jobOffert ? jobOffert.maximum_salary : '',
        id_charge: jobOffert ? jobOffert.id_charge : '',
        mode: jobOffert ? jobOffert.mode : '',
        type_of_day: jobOffert ? jobOffert.type_of_day : '',
        specification: jobOffert ? jobOffert.specification : [],
    });

    useEffect(() => {
        if(jobOffert && profiles && charges){
            setCustomProfiles(jobOffert.id_profile, profiles);
            setCustomCharges(jobOffert.id_charge, charges);
        }
    }, [jobOffert, profiles, charges]);

    useEffect(() => {
        getChargesCompany();
        getProfiles();
    }, []);

    const getProfiles = async () => {
        setLoading(true);
        await profileRepository.getProfiles().then((data) => {
           setProfiles(data.data || [])
           setLoading(false);
        });
    }

    const getChargesCompany = async() => {
        setLoading(true);
        await ChargeService.getChargesCompany().then((data) => {
            setCharges(data.data || []);
            setLoading(false);
        })
    }

    const validateForm = (values) => {
        let errors: any = {};

        if(!values.start_postulation) errors.start_postulation = "fecha inicio requerido";
        if(!values.end_postulation) errors.end_postulation = "fecha fin requerido";
        if(!values.n_available_positions) errors.n_available_positions = "posiciones disponibles requerido";
        if(!values.minimum_salary) errors.minimum_salary = "minimo salario requerido";
        if(!values.maximum_salary ) errors.maximum_salary = "maximo salario requerido";
        if(!values.mode) errors.mode = "modo re querido";
        if(!values.type_of_day) errors.type_of_day = "tipo dia requerido";

        return errors;
    }

    const addSpecification = (newSpecification) => {
        if(specification){
            let exits = false;
            if(specifications.length > 0){
                for (let i = 0; i < specifications.length; i++) {
                    if(specifications[i].specification === newSpecification.specification){
                        exits = true;
                    }
                }
            }
            if(!exits){
                setSpecifications([
                    ...specifications,
                    newSpecification
                ]);
            }else{
                return (
                    setSnackBarConfig(prev => ({ ...prev, open: true, severity:'warning', message: `${newSpecification.specification} `+___PHRASE("yaSeEncuentraSeleccionado") }))
                )
            }
        }
        setSpecification({
            specification: '',
            type: '1',
            required_doc: '1',
        });
    }

    const removeSpecification = (index) => {
        let arraySpecifications: Array<any> = specifications;
        if(arraySpecifications.length > 0){
            arraySpecifications.splice(index, 1);
        }else{
            alert(___PHRASE("noHaAgregadoNada"))
        }
        setSpecifications([...arraySpecifications])
    }

    const onSubmit = async(values, {resetForm})  => {

        const formData = {
            id: values.id,
            id_profile: profileSelected.id,
            start_postulation: values.start_postulation,
            end_postulation: values.end_postulation,
            n_available_positions: values.n_available_positions,
            minimum_salary: values.minimum_salary,
            maximum_salary: values.maximum_salary,
            id_charge: chargeSelected.id,
            mode: values.mode,
            type_of_day: values.type_of_day,
            specification: specifications || [],
        }
        setLoading(true);

        if(jobOffert && actionSelected=='edit'){
            console.log('edit')
            console.log(formData);
            // await PhasesService.editPhase(formData).then((data) => {
            //     onListJobOfferts && onListJobOfferts();
            //     closeModal && closeModal();
            // })
        }else{
            await JobOffertService.createJobOffert(formData).then((data) => {
                saveLocalStorage('newJobOffert', data.data);
                setShowSpecification && setShowSpecification(true);
                setView && setView('specificOfert');
                closeModal && closeModal();
            })
        }
        
        setLoading(false);
    }

    function setCustomProfiles(idProfile, profiles): any {
        const data = profiles && profiles.find(profile => profile.id === idProfile);
        const newData = {
            id: data?.id,
            name: data?.name
        }
        return setProfileSelected(newData);
    }

    function setCustomCharges(idcharge, chargesCompany): any {
        const data = chargesCompany && chargesCompany.find(charge => charge.id === idcharge);
        const newData = {
            id: data?.id,
            name: data?.name
        }
        return setChargeSelected(newData);
    }

    return(
        <>
        {loading && <Loading/>}

        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>

        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={((jobOffert && actionSelected=='edit') ? ___PHRASE("editar") : ___PHRASE("agregar"))+' '+___PHRASE("ofertaLaboral")}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="body1"><b>{___PHRASE("perfil")}:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Autocomplete
                                                id="disable-portal"
                                                disablePortal
                                                value={profileSelected}
                                                options={profiles || []}
                                                getOptionLabel={(option) => option.name || ""}
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} variant="standard" label={___PHRASE("escribeParaBuscar")} />}
                                                onChange={(e, newValue) => {  setProfileSelected(newValue) }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("inicioDePostulacion")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                id="start_postulation"
                                                variant="standard"
                                                fullWidth
                                                name="start_postulation"
                                                type="date"
                                                onChange={handleChange}
                                                value={values.start_postulation}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("finDePostulacion")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                id="end_postulation"
                                                variant="standard"
                                                fullWidth
                                                name="end_postulation"
                                                type="date"
                                                onChange={handleChange}
                                                value={values.end_postulation}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("vacantesDisponibles")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="n_available_positions"
                                            variant="standard"
                                            type="number" 
                                            name="n_available_positions"
                                            multiline
                                            fullWidth
                                            value={values.n_available_positions}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("vacantesDisponibles")}
                                            error={errors.n_available_positions && touched.n_available_positions ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("salarioMinimo")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="minimum_salary"
                                            variant="standard"
                                            type="number" 
                                            name="minimum_salary"
                                            multiline
                                            fullWidth
                                            value={values.minimum_salary}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("salarioMinimo")}
                                            error={errors.minimum_salary && touched.minimum_salary ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("salarioMaximo")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="maximum_salary"
                                            variant="standard"
                                            type="number" 
                                            name="maximum_salary"
                                            multiline
                                            fullWidth
                                            value={values.maximum_salary}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("salarioMaximo")}
                                            error={errors.maximum_salary && touched.maximum_salary ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="body1"><b>{___PHRASE("cargo")}:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Autocomplete
                                                id="disable-portal"
                                                disablePortal
                                                value={chargeSelected}
                                                options={charges || []}
                                                getOptionLabel={(option) => option.name || ""}
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} variant="standard" label={___PHRASE("escribeParaBuscar")} />}
                                                onChange={(e, newValue) => {  setChargeSelected(newValue) }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="body1"><b>{___PHRASE("modoDeTrabajo")}:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <Select
                                            id="mode"
                                            variant="standard"
                                            value={values.mode}
                                            onChange={(event)=>{
                                            setFieldValue("mode", event.target.value)
                                            }}
                                            fullWidth
                                        >
                                            {
                                                workingModes.map((mode, i) => {
                                                    return <MenuItem key={i} value={mode.name}>{mode.name}</MenuItem>;
                                                })
                                            }
                                        </Select>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("tipoDeDia")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="type_of_day"
                                            variant="standard"
                                            type="text" 
                                            name="type_of_day"
                                            multiline
                                            fullWidth
                                            value={values.type_of_day}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("tipoDeDia")}
                                            error={errors.type_of_day && touched.type_of_day ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    {
                                        actionSelected =='save' && (
                                            <>
                                            <Divider />

                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={12}>
                                                <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("agregarEspecificaciones")}</b></Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("nombre")}:</b></Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                <TextField
                                                    id="specification"
                                                    variant="standard"
                                                    type="text" 
                                                    name="specification"
                                                    multiline
                                                    fullWidth
                                                    value={specification.specification}
                                                    onChange={(e) => {setSpecification({...specification, specification: e.target.value})}}
                                                    placeholder={___PHRASE("nombreDeLaEspecificacion")}
                                                    error={errors.specification && touched.specification ? true : false}
                                                />
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("tipo")}:</b></Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Select
                                                        id="type"
                                                        variant="standard"
                                                        value={specification.type}
                                                        onChange={(e) => {setSpecification({...specification, type: e.target.value})}}
                                                        fullWidth
                                                        >
                                                            <MenuItem value={1}>{___PHRASE("certificado")}</MenuItem>
                                                            <MenuItem value={0}>{___PHRASE("constancia")}</MenuItem>
                                                    </Select>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("documento")}:</b></Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Select
                                                        id="required_doc"
                                                        variant="standard"
                                                        value={specification.required_doc}
                                                        onChange={(e) => {setSpecification({...specification, required_doc: e.target.value})}}
                                                        fullWidth
                                                        >
                                                            <MenuItem value={1}>{___PHRASE("requerido")}</MenuItem>
                                                            <MenuItem value={0}>{___PHRASE("noRequerido")}</MenuItem>
                                                    </Select>
                                                </Grid>
                                            </Grid>

                                            {
                                                specification.specification != '' && (
                                                    <Grid item xs={12} container justifyContent="end" marginBottom='10px'>
                                                        <Button onClick={(e) => { addSpecification(specification) }} size="small" color={"primary"} variant="contained">
                                                            {___PHRASE("agregarEspecificacion")}
                                                        </Button>
                                                    </Grid>
                                                )
                                            }

                                            <Grid item xs={12} container justifyContent="start" marginBottom='25px'>
                                                <Stack direction="column" spacing={2}>
                                                { specifications && specifications.map((item,index) =>
                                                    <Stack
                                                        direction="column" spacing={2}>
                                                        <Chip onDelete={()=>removeSpecification(index)} label={item.specification} variant="outlined"/>
                                                    </Stack>
                                                )}
                                                </Stack>
                                            </Grid>
                                            </>
                                        )
                                    }
                                    {
                                        ( actionSelected =='edit' && jobOffert && jobOffert.specification.length > 0) && (
                                            <>
                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={12}>
                                                <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("especificaciones")}:</b></Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} container justifyContent="start" marginBottom='25px'>
                                                <Stack direction="column" spacing={2}>
                                                { jobOffert.specification.map((item,index) =>
                                                    <Stack
                                                        direction="column" spacing={2}>
                                                        <Chip label={item.specification} variant="outlined"/>
                                                    </Stack>
                                                )}
                                                </Stack>
                                            </Grid>
                                            </>
                                        )
                                    }

                                </Grid>

                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(jobOffert && actionSelected=='edit') ? ___PHRASE("actualizar") : ___PHRASE("registrar")}
                                    cancelText={___PHRASE("cancelar")} 
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}