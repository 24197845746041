import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Loading } from "@components/common/Loading";
import { Alert, Backdrop, Box, Breadcrumbs, Button, CircularProgress, Container, Divider, Grid, IconButton, Menu, MenuItem, Snackbar, Typography } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { WorkerService } from '@/service/services/Worker.service';
import { emphasize, styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import GroupsIcon from '@mui/icons-material/Groups';
import { Props } from './WorkerContact.type';
import { WorkerContactService } from '@/service/services/WorkerContact.service';
import { ModalWorkerContact } from '@/components/Modal/ModalWorkerContact';
import { ModalWorkerContactDelete } from '@/components/Modal/ModalWorkerContactDelete';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import ___PHRASE from '@/service/translate/translateService';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_TRANSLATE } from '@/toolbox/constants/local-storage';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

export const WorkerContactView: React.FC<Props> = (props:any): JSX.Element => {

    const [loading, setLoading] = useState<boolean>(false);
    const [workers, setWorkers] = useState<any>([]);
    const [workerSelected, setWorkerSelected] = useState<any>('');

    const [contacts, setContacts] = useState<any>([]);
    const [showContactModal, setShowContactModal] = useState<boolean>(false);
    const [showContactDeleteModal, setShowContactDeleteModal] = useState<boolean>(false);
    const [contactSelected, setContactSelected] = useState<any>('');
    const [actionSelected, setActionSelected] = useState<any>('');

    const [view, setView] = useState<String>('workers');
    const [showDelays, setShowDelays] = useState<boolean>(false);

    const cabecera = [
        { name: 'n_document', label: ___PHRASE("rutTrabajador") },
        { name: 'surname', label: ___PHRASE("apellidoPaterno") },
        { name: 'second_surname', label: ___PHRASE("apellidoMaterno") },
        { name: 'name', label: ___PHRASE("nombres") }];

    const cabeceraContact = [
        { name: 'email', label: ___PHRASE("correo") },
        { name: 'landline', label: ___PHRASE("telefonoFijo") },
        { name: 'personal_phone', label: ___PHRASE("telefonoPersonal") },
        { name: 'emergency_phone', label: ___PHRASE("telefonoDeEmergencia") },
    ];

    useEffect(()=> {
        getWorkers()
    },[]);

    useEffect(()=> {
        if(workerSelected){
            getEmployeeContact(workerSelected.id);
        }
    },[workerSelected]);

    const getWorkers = async() => {
        setLoading(true);
        const resp:any = await WorkerService.getWorkers();
        setWorkers(resp.data || []);
        setLoading(false);
    }

    const getEmployeeContact = async(idEmployee) => {
        setLoading(true);
        await WorkerContactService.getEmployeeContact(idEmployee).then((data) => {
            setContacts(data.data || []);
            setLoading(false);
        })
    }

    function RecuperarData(value){
        !workerSelected && setWorkerSelected(value);
        workerSelected && setContactSelected(value);
        setShowDelays(true);
        setView('contact');
    }

    const searchData = async (value) => {
       const resp = await WorkerService.getWorkers(value);
       setWorkers(resp.data || []);
    }

    return (
        <Protected>
            {loading && <Loading/>}
            <Container>
            <Breadcrumbs aria-label="breadcrumb" sx={{my:3, ml:1}}>
                <StyledBreadcrumb
                    label={___PHRASE("listaDeEmpleados")}
                    onClick={() => {
                        setView('workers');
                        setWorkerSelected('');
                        setShowDelays(false);
                    }}
                    icon={<GroupsIcon fontSize="small" />}
                />
                {
                    showDelays && (
                        <StyledBreadcrumb
                            label={___PHRASE("informacionDeContacto")}
                            onClick={() => {setView('contact')}}
                            icon={<GroupsIcon fontSize="small" />}
                        />
                    )
                }
            </Breadcrumbs>
            {
                view == 'workers' && (
                    <TableDataV2
                        title={___PHRASE("contactosDelEmpleado")}
                        data={workers}
                        header={cabecera}
                        actionSelect={setActionSelected}
                        status_action
                        // disabled_title={true}
                        btn_disabled
                        btn_edit_disable={true}
                        btn_delete_disable={true}
                        btn_helper
                        iconBtnHelper={<ContactPhoneIcon fontSize="small" ></ContactPhoneIcon>}
                        toolTipBtnHelper={___PHRASE("verInformacionDeContacto")}
                        currentView={view}
                        textSearch={___PHRASE("buscarTrabajador")}
                        searchData={searchData}
                        RecuperarData={RecuperarData}
                        />
                )
            }
            {
                view == 'contact' && (
                    <TableDataV2
                        title={(readLocalStorage(KEY_TRANSLATE) == "es") ? ___PHRASE("contactosde")+` ${workerSelected?.name}, ${workerSelected?.surname && workerSelected?.surname} ${workerSelected?.second_surname && workerSelected?.second_surname}` : `
                            ${workerSelected?.name}, ${workerSelected?.surname && workerSelected?.surname} ${workerSelected?.second_surname && workerSelected?.second_surname} ` + ___PHRASE("contactosde")}
                        data={contacts}
                        header={cabeceraContact}
                        actionSelect={setActionSelected}
                        setModalSave={setShowContactModal}
                        setModalDelete={setShowContactDeleteModal}
                        status_action
                        disabled_title={false}
                        btn_disabled={false}
                        btn_edit_disable={false}
                        btn_delete_disable={false}
                        currentView={view}
                        RecuperarData={RecuperarData}
                    />
                )
            }
            </Container>

            { 
                showContactModal && (
                    <ModalWorkerContact
                        open={showContactModal}
                        closeModal={()=>{setShowContactModal(false)}}
                        actionSelected={actionSelected}
                        onListContacts={getEmployeeContact}
                        contact={contactSelected}
                        workerSelected={workerSelected}
                        clearState={()=>{setContactSelected('')}}
                    />
                )
            }
            {
                actionSelected === 'delete' && (
                    <ModalWorkerContactDelete
                        open={showContactDeleteModal}
                        closeModal={()=>{setShowContactDeleteModal(false)}}
                        actionSelected={actionSelected}
                        onListContacts={getEmployeeContact}
                        contact={contactSelected}
                        workerSelected={workerSelected}
                        clearState={()=>{setContactSelected('')}}
                    />
                )
            }
        
        </Protected>
    )
};
