import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik } from 'formik';
import { useEffect, useRef, useState } from "react";
import { Autocomplete, Button, Divider, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { Loading } from "@/components/common/Loading";
import { Props } from "./ModalPolitics.type";
import { PoliticService } from "@/service/services/Politic.service";
import { API_URL_BASE } from "@/toolbox/defaults/app";
import { KEY_RUTA, KEY_TRANSLATE, KEY_USER_DATA } from "@/toolbox/constants/local-storage";
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { ROLE_ADMIN, ROLE_SUPER_ADMIN, ROLE_TRABAJADOR } from "@/toolbox/defaults/static-roles";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ___PHRASE from "@/service/translate/translateService";
import { apiResponseTranslate } from "@/toolbox/helpers/responseApi.helper";

export const ModalPolitics: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, politic, onListPolitics, clearState, getSnackBar } = props;
    
    const language = readLocalStorage(KEY_TRANSLATE);
    const route = readLocalStorage(KEY_RUTA)
    const userData = readLocalStorage(KEY_USER_DATA);
    const role = userData.user.role
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState({
        idtypedocument: politic ? politic.idtypedocument: '',
        pdf: politic ? politic.pdf :'',
    });

    const [currentPDF, setCurrentPDF] = useState<any>(null)

    const [typeDocuments, setTypeDocuments] = useState<any>([]);
    const [typeDocumentsSelected, setTypeDocumentsSelected] = useState<any>(null)
    const validateForm = (values) => {
        let errors: any = {};
        if(!typeDocumentsSelected) errors.idtypedocument = "El tipo es requerido"
        if(actionSelected !='edit') {
            if(!currentPDF) errors.pdf = "Seleccione un archivo de tipo PDF"
        }
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        let type;
        if(role == ROLE_SUPER_ADMIN){type = '1'}
        if(role == ROLE_ADMIN){
           if( route == 'Politicas y Privacidad'){type = '1'}else{type = '0'}
        }
        if(role == ROLE_TRABAJADOR){type = '0'}
        const formData = new FormData();
        formData.append("idtypedocument", typeDocumentsSelected.id);
        formData.append("PDF", currentPDF)
        formData.append("type", type)
        setLoading(true);
        if(politic && actionSelected=='edit'){
            await PoliticService.editDocuments( politic.id, formData).then((data) => {
                let apimessagge = apiResponseTranslate(data?.data?.message || '');

                if(data.data.status){
                    onListPolitics && onListPolitics();
                    getSnackBar && getSnackBar('success', apimessagge)
                } else {
                    getSnackBar && getSnackBar('error', apimessagge)
                }
                closeModal && closeModal();
            }).catch((data) => {
                let apimessagge = apiResponseTranslate(data?.data?.message || '');

                getSnackBar && getSnackBar('error', apimessagge)
            })
        }else{
            await PoliticService.uploadDocuments(formData).then((data) => {
                let apimessagge = apiResponseTranslate(data?.data?.message || '');

                if(data.data.status){
                    onListPolitics && onListPolitics();
                    getSnackBar && getSnackBar('success', apimessagge)
                } else {
                    getSnackBar && getSnackBar('error', apimessagge)
                }
                closeModal && closeModal();
            }).catch((data) => {
                let apimessagge = apiResponseTranslate(data?.data?.message || '');

                getSnackBar && getSnackBar('error', apimessagge)
            })
        }
        setLoading(false);
    }

    const setNameFile = (event) => {
        let errors: any = {};
        const files = event.target && event.target.files || [];
        const fileSignature = files[0];

        if (fileSignature) {

            if(fileSignature.type !== "application/pdf"){
                return (
                    null
                );
            } else {
                setCurrentPDF(fileSignature)
                return fileSignature
            }
        }
        return "";
    };

    const getTypeDocuments = async() => {
        const resp = await PoliticService.getTypeDocuments();
        const newData = [];

        resp.data && (resp.data).length > 0 && (resp.data).map((item)=>{
            if(language && language == 'en'){
                newData.push({...item, name: apiResponseTranslate(item.name)})
            }else{
                newData.push(item)
            }
        })

        setTypeDocuments(newData);
        if(actionSelected == 'edit'){
            const docsFind = newData.find((value)=> value.id == politic.idtypedocument);
            setTypeDocumentsSelected(docsFind)
        }
    }

    useEffect(()=>{
        getTypeDocuments();
    },[])

    return(
        <>
        {loading && <Loading/>}

        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={((politic ? ___PHRASE("editar") : ___PHRASE("agregar")))+" "+___PHRASE("documento")}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>{___PHRASE("tipoDeDocumento")}:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Autocomplete
                                                id="disable-portal"
                                                disablePortal
                                                value={typeDocumentsSelected}
                                                options={typeDocuments || []}
                                                getOptionLabel={(option) => option.name || ""}
                                                fullWidth
                                                disabled={actionSelected=='edit'}
                                                renderInput={(params) => <TextField
                                                    {...params}
                                                    variant="standard"
                                                    label={actionSelected=='edit' ? '' : ___PHRASE("escribeParaBuscar")}
                                                    error={errors.idtypedocument ? true : false}
                                                    helperText={errors ? errors.idtypedocument : ''}
                                                    />}
                                                onChange={(e, newValue) => { setTypeDocumentsSelected(newValue) }}
                                            />
                                        </Grid>
                                    </Grid>
                                    { actionSelected == 'edit' && <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("ver")} PDF:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Button variant="contained" component="label" fullWidth >
                                                <a href={`${API_URL_BASE}/${politic.route}`} target='_blank' style={{textDecoration:'none', color:'#fff'}}> {___PHRASE("ver")} PDF</a>
                                            </Button>
                                        </Grid>
                                    </Grid> }
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("cargar")} PDF:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Button variant="contained" component="label" fullWidth>
                                                <input
                                                    id="pdf"
                                                    type="file"
                                                    name="pdf"
                                                    accept="application/pdf"
                                                    onChange={(event) => {
                                                        setFieldValue("pdf", setNameFile(event));
                                                    }}
                                                    style={{ display: "none" }}
                                                />
                                                <FileUploadIcon/>
                                                {!currentPDF ? ___PHRASE("subirArchivo") : ___PHRASE("archivoSubido")}
                                            </Button>
                                            {errors.pdf && !currentPDF ? <p style={{color: '#d32f2f', marginTop: '3px', fontSize: '0.80em'}}>{errors.pdf}</p>: ''}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <ModalFooter
                                    buttonType="submit"
                                    confirmText={(politic && actionSelected=='edit') ? ___PHRASE("actualizar") : ___PHRASE("registrar")}
                                    cancelText={___PHRASE("cancelar")}
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}