import { Loading } from "@/components/common/Loading";
import { TimeZoneService } from "@/service/services/TimeZone.service";
import { Typography } from "@mui/material";
import { useState } from "react";
import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalFooter } from "../ModalFooter";
import { ModalHeader } from "../ModalHeader";
import { Props } from "./ModalTimeZoneDelete.type";
import ___PHRASE from "@/service/translate/translateService";
import { apiResponseTranslate } from "@/toolbox/helpers/responseApi.helper";

export const ModalTimeZoneDelete: React.FC<Props> = (props:any): JSX.Element => {
    const { open, closeModal, timeZone, onListZoneTime, clearState, getSnackBar } = props;
    const [loading, setLoading] = useState<boolean>(false);

    const onDeleted= async()=>{
        setLoading(true);
        await TimeZoneService.deleteTimeZone(timeZone.id).then((data) => {
          let apimessagge = apiResponseTranslate(data?.data?.message);

          if(data.data.status){
              getSnackBar(prev => ({
                 ...prev, open: true,
                 severity: 'success',
                 message: `${apimessagge}`
                 }
              ));
              onListZoneTime && onListZoneTime();
          } else {
              getSnackBar(prev => ({
                 ...prev, open: true,
                 severity: 'error',
                 message: `${apimessagge}`
                 }
              ));
          }
            closeModal && closeModal();
        })
        setLoading(false);
    }
  
  return (
    <>
    {loading && <Loading/>}
    <Modal
      open={open}
      handleClose={closeModal}
      size="xs"
    >
      <ModalHeader
        text={___PHRASE("eliminar")+' '+___PHRASE("zonahoraria")}
        className='positionElements'
        onCancel={closeModal}
        clearState={clearState}
      >
      </ModalHeader>

      <ModalBody>
        <Typography variant="subtitle1" className="textColor"> 
          {___PHRASE("estaSeguroDeEliminarEstaZonaHoraria")}
        </Typography>
      </ModalBody>

      <ModalFooter 
        confirmText={___PHRASE("eliminar")}
        cancelText={___PHRASE("cancelar")}
        onCancel={closeModal}
        className="footerRight"
        onConfirm={onDeleted}
        clearState={clearState}
      />
    </Modal>
    </>
  )
}