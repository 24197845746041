import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Loading } from "@components/common/Loading";
import { Props } from './Profile.type'
import { Alert, Backdrop, CircularProgress, Container, Divider, Grid, IconButton, Menu, MenuItem, Snackbar } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { ProfileService } from '@/service/services/Profile.service';
import { ModalProfile } from '@/components/Modal/ModalProfile';
import { ModalProfileDelete } from '@/components/Modal/ModalProfileDelete/ModalProfileDelete';
import ___PHRASE from '@/service/translate/translateService';


export const ProfileView: React.FC<Props> = (props: any): JSX.Element => {

   const [loading, setLoading] = useState<boolean>(false);
   const [profiles, setProfiles] = useState([]);
   const [showProfileModal, setShowProfileModal] = useState<boolean>(false);
   const [showProfileDeleteModal, setShowProfileDeleteModal] = useState<boolean>(false);
   const [actionSelected, setActionSelected] = useState<String>('');
   const [profileSelected, setProfileSelected] = useState<String>('');

   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
   })

   useEffect(() => {
      getProfiles()
   }, [])

   const getProfiles = async () => {
      setLoading(true);
      await ProfileService.getProfiles().then((data) => {
         setProfiles(data.data || [])
         setLoading(false);
      });
   }

   function RecuperarData(value) {
      console.log(value);
      setProfileSelected(value);
   }

   return (
      <Protected>
         {loading && <Loading />}
         <Container>
            <TableDataV2
               title={___PHRASE("perfiles")}
               data={profiles || []}
               header={[
                  { name: 'name', label: ___PHRASE("nombre"), filter: false, Chip: true },
                  { name: 'minimum_salary', label: ___PHRASE("salarioMinimo"), filter: false, Chip: false },
                  { name: 'maximum_salary', label: ___PHRASE("salarioMaximo"), filter: false, Chip: true },
               ]}
               actionSelect={setActionSelected}
               setModalSave={setShowProfileModal}
               setModalDelete={setShowProfileDeleteModal}
               status_action
               // checkbox
               RecuperarData={RecuperarData}
            />
            {
               showProfileModal && (
                  <ModalProfile
                     open={true}
                     closeModal={()=>{setShowProfileModal(false)}}
                     actionSelected={actionSelected}
                     onListProfiles={getProfiles}
                     profile={profileSelected}
                     clearState={()=>{setProfileSelected('')}}
                  />
               )
            }
            {
               actionSelected === 'delete' && (
                  <ModalProfileDelete
                     open={showProfileDeleteModal}
                     closeModal={()=>{setShowProfileDeleteModal(false)}}
                     actionSelected={actionSelected}
                     onListProfiles={getProfiles}
                     profile={profileSelected}
                     clearState={()=>{setProfileSelected('')}}
                  />
               )
            }
         </Container>

      </Protected>
   )
};