import React, { useEffect, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Loading } from "@components/common/Loading";
import { Breadcrumbs, Container } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { WorkerService } from '@/service/services/Worker.service';
import { emphasize, styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import GroupsIcon from '@mui/icons-material/Groups';
import { AssistanceService } from '@/service/services/Assistance.service';
import { ModalAbsence } from '@/components/Modal/ModalAbsence';
import { ModalAbsenceDelete } from '@/components/Modal/ModalAbsenceDelete';
import { Props } from './Absence.type';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import { useLocation } from 'react-router-dom';
import ___PHRASE from '@/service/translate/translateService';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

export const AbsenceView: React.FC<Props> = (props:any): JSX.Element => {

    const [loading, setLoading] = useState<boolean>(false);
    const [workers, setWorkers] = useState<any>([]);
    const [workerSelected, setWorkerSelected] = useState<any>('');
    const [Absences, setAbsences] = useState<any>([]);
    const [showAbsenceModal, setShowAbsenceModal] = useState<boolean>(false);
    const [showAbsenceDeleteModal, setShowAbsenceDeleteModal] = useState<boolean>(false);
    const [absenceSelected, setAbsenceSelected] = useState<any>('');
    const [actionSelected, setActionSelected] = useState<any>('');
    const [view, setView] = useState<String>('workers');
    const [showAbsences, setShowAbsences] = useState<boolean>(false);

    const cabecera = [
        { name: 'n_document', label: ___PHRASE("rutTrabajador") },
        { name: 'surname', label: ___PHRASE("apellidoPaterno") },
        { name: 'second_surname', label: ___PHRASE("apellidoMaterno") },
        { name: 'name', label: ___PHRASE("nombres") }];

    const cabeceraAbsence = [
        { name: 'type_absence_name', label: ___PHRASE("tipoDeInasistencia") },
        { name: 'type_discount_name', label: ___PHRASE("tipoDeDescuento") },
        { name: 'date_registration', label: ___PHRASE("fechaDeInicio") },
        { name: 'date_end', label: ___PHRASE("fechaDeTermino") },
        { name: 'total_absence', label: ___PHRASE("totalInasistencia") },
        { name: 'observation', label: ___PHRASE("observacion") },
    ];

    //Redireccion: props enviados de la vista solicitud (novedades del mes)
    let location: any = useLocation();
    let dataprops: any = location?.state;

    useEffect(()=> {
        if(dataprops){
            setWorkerSelected(dataprops);
            setShowAbsences(true);
            setView('absences');
        }
    },[dataprops]);

    useEffect(()=> {
        getWorkers()
    },[]);

    useEffect(()=> {
        if(workerSelected){
         getAbsencesByEmployee(workerSelected.id);
        }
    },[workerSelected]);

    const getWorkers = async() => {
        setLoading(true);
        const resp:any = await WorkerService.getWorkers();
        const oldData = resp.data || [];
        let newData = [];
        oldData.length > 0 && oldData.map((item) => {
            let obj = {
                ...item,
                name_complete: item.name+' '+item.surname+' '+item.second_surname,
            }
            newData.push(obj);
        });
        setWorkers(newData || []);
        setLoading(false);
    }

    const getAbsencesByEmployee = async(idEmployee) => {
        setLoading(true);
        await AssistanceService.getAbsencesByEmployee(idEmployee).then((data) => {
            setAbsences(data.data || []);
            setLoading(false);
        })
    }

    function RecuperarData(value){
        !workerSelected && setWorkerSelected(value);
        workerSelected && setAbsenceSelected(value);
        setShowAbsences(true);
        setView('absences');
    }

    const searchData = async (value) => {
       const resp = await WorkerService.getWorkers(value);
       const oldData = resp.data || [];
        let newData = [];
        oldData.length > 0 && oldData.map((item) => {
            let obj = {
                ...item,
                name_complete: item.name+' '+item.surname+' '+item.second_surname,
            }
            newData.push(obj);
        });
       setWorkers(newData || []);
    }

    return (
        <Protected>
            {loading && <Loading/>}
            <Container>
            <Breadcrumbs aria-label="breadcrumb" sx={{my:3, ml:2}}>
                <StyledBreadcrumb
                    label={___PHRASE("listaDeEmpleados")}
                    onClick={() => {
                        setView('workers');
                        setWorkerSelected('');
                        setShowAbsences(false);
                    }}
                    icon={<GroupsIcon fontSize="small" />}
                    disabled={dataprops ? true : false}
                />
                {
                    showAbsences && (
                        <StyledBreadcrumb
                            label={___PHRASE("inasistencias")}
                            onClick={() => {setView('absences')}}
                            icon={<GroupsIcon fontSize="small" />}
                        />
                    )
                }
            </Breadcrumbs>
            {
                view == 'workers' && (
                    <TableDataV2
                        title={___PHRASE("inasistenciasPorEmpleado")}
                        data={workers}
                        header={cabecera}
                        actionSelect={setActionSelected}
                        status_action
                        disabled_title={false}
                        btn_disabled
                        btn_edit_disable={true}
                        btn_delete_disable={true}
                        btn_helper
                        iconBtnHelper={<EventBusyIcon fontSize="small" ></EventBusyIcon>}
                        toolTipBtnHelper={___PHRASE("verInasistencia")}
                        currentView={view}
                        textSearch={___PHRASE("buscarTrabajador")}
                        searchData={searchData}
                        RecuperarData={RecuperarData}
                        />
                )
            }
            {
                view == 'absences' && (

                    <>
                     <TableDataV2
                        title={(___PHRASE("inasistenciaDe")+` ${workerSelected?.name_complete ? workerSelected?.name_complete : workerSelected?.name}`).toUpperCase()}
                        data={Absences}
                        header={cabeceraAbsence}
                        actionSelect={setActionSelected}
                        setModalSave={setShowAbsenceModal}
                        setModalDelete={setShowAbsenceDeleteModal}
                        status_action
                        disabled_title={false}
                        btn_disabled={false}
                        btn_edit_disable={false}
                        btn_delete_disable={false}
                        currentView={view}
                        RecuperarData={RecuperarData}
                        note_severity={dataprops && 'warning'}
                        note={dataprops && 
                            ___PHRASE("asegurateDeAgregarLaInasistenciaDeAcuerdoALaSolicitudDe")+` "${dataprops.requestTypeName}" `+___PHRASE("laCualCorrespondeDeLaFecha")+` ${dataprops.from_date_request} `+___PHRASE("hasta")+` ${dataprops.to_date_request}.`}
                        />
                    </>
                   
                )
            }
            </Container>

            { 
                showAbsenceModal && (
                    <ModalAbsence
                        open={showAbsenceModal}
                        closeModal={()=>{setShowAbsenceModal(false)}}
                        actionSelected={actionSelected}
                        onListAbsences={getAbsencesByEmployee}
                        absence={absenceSelected}
                        workerSelected={workerSelected}
                        clearState={()=>{setAbsenceSelected('')}}
                    />
                )
            }
            {
                actionSelected === 'delete' && (
                    <ModalAbsenceDelete
                        open={showAbsenceDeleteModal}
                        closeModal={()=>{setShowAbsenceDeleteModal(false)}}
                        actionSelected={actionSelected}
                        onListAbsences={getAbsencesByEmployee}
                        absence={absenceSelected}
                        workerSelected={workerSelected}
                        clearState={()=>{setAbsenceSelected('')}}
                    />
                )
            }
        
        </Protected>
    )
};