import { useEffect, useState } from "react"
import { TableDataV2 } from "@/components/common/Tablev2"
import { Protected } from "@/components/layout/Protected"
import { Alert, Container, Snackbar } from "@mui/material"
import { Loading } from "@/components/common/Loading"
import { Props } from "./ContractDocuments.type"
import { ContractService } from "@/service/services/Contract.service"
import { useHistory } from "react-router-dom"
import { ROUTE_CREATE_CONTRACT_DOCUMENT, ROUTE_CUSTOMIZE_CONTRACT } from "@/toolbox/constants/route-map"
import ___PHRASE from "@/service/translate/translateService"

export const ContractDocumentsView: React.FC<Props> = (props:any): JSX.Element => {

    const history = useHistory();
    const snackBarSeverity = props?.location?.state?.severity;
    const snackBarMessage = props?.location?.state?.message;
    const [loading, setLoading] = useState<boolean>(false);
    const [documents, setDocuments] = useState([]);
    const [actionSelected, setActionSelected] = useState<any>('');
    const [documentSelected, setDocumentSelected] = useState<any>('');
    const [showEditDocument, setShowEditDocument] = useState<boolean>(false);
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
       open: false,
       severity: 'error',
       message: 'Error',
       autoHideDuration: 3000,
    })

    console.log(actionSelected);


    useEffect(() => {
        if(actionSelected && actionSelected=='helper') showClauses('byContract');
    }, [actionSelected && actionSelected=='helper']);


    useEffect(() => {
        if(actionSelected && actionSelected=='save') showClauses('new');
    }, [actionSelected && actionSelected=='save']);

    useEffect(() => {
        documentContractText();
    }, []);

    useEffect(()=> {
       if(props?.location.state){
          console.log(props?.location.state)
          getSnackBar(snackBarSeverity, snackBarMessage)
       }
 
    },[])

    const documentContractText = async() => {
        setLoading(true);
        await ContractService.documentContractText().then((data) => {
            setDocuments(data.data || []);
            setLoading(false);
        })
    }

    function RecuperarData(value){
        setDocumentSelected(value)
    }

    const showClauses = (type) => {
        if(type == 'byContract'){
            history.push(ROUTE_CUSTOMIZE_CONTRACT, {idEmployee: '13', idContractDocument: documentSelected?.id, documentData: documentSelected})
        }else{
            history.push(ROUTE_CREATE_CONTRACT_DOCUMENT, {})
        }
    }

    const getSnackBar = (severity, message) => {
       setSnackBarConfig(prev => ({
          ...prev, open: true,
          severity: severity,
          message: message
          }
       ));
    }

    return (
        <Protected>
            {loading && <Loading/>}
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
                <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                    {snackBarConfig.message || ""}
                </Alert>
            </Snackbar>
            <Container>
                <TableDataV2
                    title={___PHRASE("documentocontratos")}
                    data={documents || []}
                    header={[
                        { name: 'name', label: ___PHRASE("nombre"), filter: false, Chip: false },
                        { name: 'description', label: ___PHRASE("descripcion"), filter: false, Chip: true },
                    ]}
                    actionSelect={setActionSelected}
                    setModalSave={setShowEditDocument}
                    status_action
                    btn_delete_disable
                    btn_edit_disable
                    btn_helper
                    RecuperarData={RecuperarData}
                />
            </Container>
        </Protected>
    )
}