import { responsabilityFamilyRepository } from "../repositories/ResponsabilityFamily.repository";

export const responsabilityFamilyService = {
   getResponsabilityFamily,
   postCreateResponsabilityFamily,
   editResponsabilityFamily,
   deleteResponsabilityFamily,
   getDataInitial,
};
async function getResponsabilityFamily (id_employee) {
   const resp= await responsabilityFamilyRepository.getResponsabilityFamily(id_employee);
   return resp
}

async function postCreateResponsabilityFamily(data){
    const resp = await responsabilityFamilyRepository.postCreateResponsabilityFamily(data);
    return resp
}

async function editResponsabilityFamily(dataFamily, idFamily){
   const res = await responsabilityFamilyRepository.editResponsabilityFamily(dataFamily, idFamily);
   return res;
}

async function deleteResponsabilityFamily(idFamily){
   const res = await responsabilityFamilyRepository.deleteResponsabilityFamily(idFamily);
   return res;
}

async function getDataInitial(){
   const resp = await responsabilityFamilyRepository.getDataInitial();
   return resp
}


