import { useEffect, useState } from "react"
import { TableDataV2 } from "@/components/common/Tablev2"
import { Protected } from "@/components/layout/Protected"
import { Breadcrumbs, Container, Link, Typography } from "@mui/material"
import { PhasesService } from "@/service/services/Phases.service"
import { Loading } from "@/components/common/Loading"
import { ModalPhases } from "@/components/Modal/ModalPhases"
import { ModalPhaseDelete } from "@/components/Modal/ModalPhaseDelete"
import { Props } from "./Announcement.type"
import { SpecificationJobOffert } from "../SpecificationJobOffert/SpecificationJobOffert"
import { JobOffert } from "../JobOffert"
import { emphasize, styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import HomeIcon from '@mui/icons-material/Home';
import WorkIcon from '@mui/icons-material/Work';
import FeedIcon from '@mui/icons-material/Feed';
import GroupsIcon from '@mui/icons-material/Groups';
import { removeLocalStorage } from "@/toolbox/helpers/local-storage-helper"
import { RecluitingTeamView } from "../RecluitingTeam"
import ___PHRASE from "@/service/translate/translateService"
// import NavigateNextIcon from '@mui/icons-material/NavigateNext';


const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

export const Announcement: React.FC<Props> = (props: any): JSX.Element => {

    const [loading, setLoading] = useState<boolean>(false);
    
    const [view, setView] = useState<String>('jobOfert');

    const [showSpecification, setShowSpecification] = useState<boolean>(false);
    const [showTeam, setShowTeam] = useState<boolean>(false);

    return (
        <Protected>
            {loading && <Loading />}
            <Container>
                <Breadcrumbs aria-label="breadcrumb" sx={{my:3, ml:5}}>
                    <StyledBreadcrumb
                        label={___PHRASE("ofertasLaborales")}
                        onClick={() => { 
                            setView('jobOfert');
                            setShowSpecification(false);
                            setShowTeam(false);
                            removeLocalStorage('newJobOffert');
                        }}
                        icon={<WorkIcon fontSize="small" />}
                    />
                    {
                        showSpecification && (
                            <StyledBreadcrumb
                                label={___PHRASE("especificacionDeOfertasLaborales")}
                                onClick={() => { setView('specificOfert') }}
                                icon={<FeedIcon fontSize="small" />}
                            />
                        )
                    }
                    {
                        showTeam && (
                            <StyledBreadcrumb
                                label={___PHRASE("equipoDeReclutamiento")}
                                onClick={() => { setView('recruitingTeam') }}
                                icon={<GroupsIcon fontSize="small" />}
                            />
                        )
                    }
                </Breadcrumbs>
                
                {
                    view == 'jobOfert' && (
                        <JobOffert
                            setShowSpecification={setShowSpecification}
                            setShowTeam={setShowTeam}
                            setView={setView}
                            currentView={view}
                        />
                    )
                }

                {
                    view == 'specificOfert' && (
                        <SpecificationJobOffert
                            setShowTeam={setShowTeam}
                            setView={setView}
                            currentView={view}
                        />
                    )
                }

                {
                    view == 'recruitingTeam' && (
                        <RecluitingTeamView
                        />
                    )
                }
            </Container>
        </Protected>
    )
}
