import { useEffect, useState } from "react"
import { TableDataV2 } from "@/components/common/Tablev2"
import { Protected } from "@/components/layout/Protected"
import { Breadcrumbs, Container } from "@mui/material"
import { Loading } from "@/components/common/Loading"
import { Props } from "./EmployeeExpense.type"
import { EmployeeExpenseService } from "@/service/services/EmployeeExpense.service"
import { ModalExpenseEmployeeDelete } from "@/components/Modal/ModalExpenseEmployeeDelete/ModalExpenseEmployeeDelete"
import { ModalExpenseEmployee } from "@/components/Modal/ModalExpenseEmployee/ModalExpenseEmployee"
import { emphasize, styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import GroupsIcon from '@mui/icons-material/Groups';
import { ModalExpenseEmployeeDetail } from "@/components/Modal/ModalExpenseEmployeeDetail/ModalExpenseEmployeeDetail"
import { useLocation } from "react-router-dom"
import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined';
import { ModalExpenseEmployeeDetailDelete } from "@/components/Modal/ModalExpenseEmployeeDetailDelete/ModalExpenseEmployeeDetail"
import ___PHRASE from "@/service/translate/translateService"
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper"
import { KEY_TRANSLATE } from "@/toolbox/constants/local-storage"
import { apiResponseTranslate } from "@/toolbox/helpers/responseApi.helper"

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

export const EmployeeExpenseView: React.FC<Props> = (props:any): JSX.Element => {
    let location = useLocation();
    const dataprops: any = location?.state;
    const idExpense: any = dataprops?.idExpense;
    const language = readLocalStorage(KEY_TRANSLATE);
    const [loading, setLoading] = useState<boolean>(false);
    const [actionSelected, setActionSelected] = useState<any>('');

    const [Expenses, setExpenses] = useState([]);
    const [showExpenseModal, setShowExpenseModal] = useState<boolean>(false);
    const [showExpenseDeleteModal, setShowExpenseDeleteModal] = useState<boolean>(false);
    const [expenseSelected, setExpenseSelected] = useState<any>('');
    
    const [details, setDetails] = useState([]);
    const [showDetailModal, setShowDetailModal] = useState<boolean>(false);
    const [showDetailDeleteModal, setShowDetailDeleteModal] = useState<boolean>(false);
    const [detailSelected, setDetailSelected] = useState<any>('');

    const [view, setView] = useState<String>('expense');
    const [showDetails, setShowDetails] = useState<boolean>(false);

    const cabeceraExpense = [
        { name: 'detail', label: ___PHRASE("concepto") },
        { name: 'period', label: ___PHRASE("periodo") },
        // { name: 'review_status', label: 'ESTADO DE REVISIÓN' },
    ];
    const cabeceraDetail = [
        { name: 'reportTypeName', label: ___PHRASE("tipoDeReporte") },
        { name: 'detail', label: ___PHRASE("detalle") },
        { name: 'amount', label: ___PHRASE("gastoTotal")+' ($)' },
        { name: 'amountCompany', label: ___PHRASE("gastoEmpresa")+' ($)' },
        { name: 'review_status', label: ___PHRASE("estadoDeRevision") },
    ];

    useEffect(() => {
        getExpensesByEmployeeSession();
    }, []);

    useEffect(()=> {
        if(expenseSelected && !showExpenseDeleteModal && !dataprops){
            getDetailsExpense(expenseSelected.id);
        }
    },[expenseSelected, !showExpenseDeleteModal, !dataprops]);

    useEffect(()=> {
        if(dataprops && Expenses){
            const findRequest = Expenses.find(item => item.id  == idExpense)
          getDetailsExpense(idExpense)
          setExpenseSelected(findRequest)
          setShowDetails(true)
          setView('details')
       }
    },[dataprops && Expenses]);

    const getExpensesByEmployeeSession = async() => {
        setLoading(true);
        await EmployeeExpenseService.getExpensesByEmployeeSession('', '').then((data) => {
            const oldData = data.data || [];
            let newData = [];
            oldData.length > 0 && oldData.map((item) => {
                data = {
                    ...item,
                    period: item?.period ? item.period.month +'-'+ item.period.year : '---',
                    review_status: item.review_status == '0' ? 'pendiente' : (item.review_status == '1' ? 'aceptada' : 'rechazada'),
                }
                newData.push(data);
            });
            setExpenses(newData || []);
            setLoading(false);
        })
    }

    const getDetailsExpense = async(idExpense) => {
        setLoading(true);
        await EmployeeExpenseService.getDetailsExpense(idExpense, '', '', '').then((data) => {
            const newData = [];
            data.data && (data.data).length > 0 && (data.data).map((item)=>{
                if(language && language == 'en'){
                    newData.push({
                        ...item,
                        reportTypeName: apiResponseTranslate(item.reportTypeName),
                        review_status: item.review_status == '0' ? apiResponseTranslate('pendiente') : (item.review_status == '1' ? apiResponseTranslate('aceptada') : apiResponseTranslate('rechazada')),
                        amountCompany: item.review_status == '1' ? (item.coverage_company * item.amount)/100 : '---',
                    })
                }else{
                    newData.push({
                        ...item,
                        review_status: item.review_status == '0' ? 'pendiente' : (item.review_status == '1' ? 'aceptada' : 'rechazada'),
                        amountCompany: item.review_status == '1' ? (item.coverage_company * item.amount)/100 : '---',
                    })
                }
            })
            setDetails(newData || []);
            setLoading(false);
        })
    }

    function RecuperarData(value){
        
        !expenseSelected && setExpenseSelected(value);
        expenseSelected && setDetailSelected(value);
        if(value.action != 'delete'){
            setShowDetails(true);
            setView('details');
        }
    }

    return (
        <Protected>
            {loading && <Loading/>}
            <Container>
            <Breadcrumbs aria-label="breadcrumb" sx={{mt:3, ml:2}}>
                    <StyledBreadcrumb
                        label={___PHRASE("misgastos")}
                        onClick={() => {
                            setView('expense');
                            setExpenseSelected('');
                            setShowDetails(false);
                        }}
                        icon={<GroupsIcon fontSize="small" />}
                    />
                    {
                        showDetails && (
                            <StyledBreadcrumb
                                label={___PHRASE("detalles")}
                                onClick={() => {setView('details')}}
                                icon={<GroupsIcon fontSize="small" />}
                            />
                        )
                    }
                </Breadcrumbs>

                {
                    view == 'expense' && (
                        <TableDataV2
                            title={___PHRASE("misgastos")}
                            data={Expenses || []}
                            header={cabeceraExpense || []}
                            actionSelect={setActionSelected}
                            setModalSave={setShowExpenseModal}
                            setModalDelete={setShowExpenseDeleteModal}
                            status_action
                            RecuperarData={RecuperarData}
                            btn_edit_disable={true}
                            currentView={view}
                            btn_helper
                            iconBtnHelper={<PriceChangeOutlinedIcon fontSize="small" ></PriceChangeOutlinedIcon>}
                            toolTipBtnHelper={___PHRASE("verDetalleDelGasto")}
                        />
                    )
                }
             {
                    view == 'details' && (
                        <TableDataV2
                            title={___PHRASE("detalles")}
                            data={details || []}
                            header={cabeceraDetail || []}
                            actionSelect={setActionSelected}
                            setModalSave={setShowDetailModal}
                            setModalDelete={setShowDetailDeleteModal}
                            status_action
                            btn_disabled={false}
                            disabled_title={false}
                            btn_edit_disable={false}
                            currentView={view}
                            RecuperarData={RecuperarData}
                        />
                        )
                    }
                
            </Container>

            { 
                showExpenseModal && (
                    <ModalExpenseEmployee
                        open={showExpenseModal}
                        closeModal={()=>{setShowExpenseModal(false)}}
                        actionSelected={actionSelected}
                        onListExpenses={getExpensesByEmployeeSession}
                        expense={expenseSelected}
                        clearState={()=>{setExpenseSelected('')}}
                    />
                )
            }
            {
                showExpenseDeleteModal && (
                    <ModalExpenseEmployeeDelete
                        open={showExpenseDeleteModal}
                        closeModal={()=>{setShowExpenseDeleteModal(false)}}
                        actionSelected={actionSelected}
                        onListExpenses={getExpensesByEmployeeSession}
                        expense={expenseSelected}
                        clearState={()=>{setExpenseSelected('')}}
                    />
                )
            }
            { 
                showDetailModal && (
                    <ModalExpenseEmployeeDetail
                        open={showDetailModal}
                        closeModal={()=>{setShowDetailModal(false)}}
                        actionSelected={actionSelected}
                        onListDetails={getDetailsExpense}
                        detail={detailSelected ? {
                            ...detailSelected, 
                            review_status: detailSelected.review_status == 'Pending' ? 'pendiente' : (detailSelected.review_status == 'accepted' ? 'aceptada' : 'rechazada')
                        } : detailSelected}
                        expenseSelected={expenseSelected}
                        clearState={()=>{setDetailSelected('')}}
                    />
                )
            }
            {
                showDetailDeleteModal && (
                    <ModalExpenseEmployeeDetailDelete
                        open={showDetailDeleteModal}
                        closeModal={()=>{setShowDetailDeleteModal(false)}}
                        actionSelected={actionSelected}
                        onListDetail={getDetailsExpense}
                        expense={expenseSelected}
                        detail={detailSelected ? {
                            ...detailSelected, 
                            review_status: detailSelected.review_status == 'Pending' ? 'pendiente' : (detailSelected.review_status == 'accepted' ? 'aceptada' : 'rechazada')
                        } : detailSelected}
                        clearState={()=>{setDetailSelected('')}}
                    />
                )
            }
        </Protected>
    )
}