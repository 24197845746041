import React, { useEffect, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Props } from './GroupCompaniesAll.type'
import { Loading } from '@/components/common/Loading';
import { Backdrop, Breadcrumbs, Container, Grid } from "@mui/material";
import Chip from '@mui/material/Chip';
import { emphasize, styled } from '@mui/material/styles';
import { SpinnerGrow } from '@/components/common/Spinner';
import { TableDataV2 } from '@/components/common/Tablev2';
import GroupsIcon from '@mui/icons-material/Groups';
import { userService } from '@/service/services/User.service';
import { companiesService } from '@/service/services/Companies.service';
import { ModalGroupCompany } from '@/components/Modal/ModalGroupCompany';
import ___PHRASE from '@/service/translate/translateService';
import { apiResponseTranslate } from '@/toolbox/helpers/responseApi.helper';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
   const backgroundColor =
       theme.palette.mode === 'light'
           ? theme.palette.grey[100]
           : theme.palette.grey[800];
   return {
       backgroundColor,
       height: theme.spacing(3),
       color: theme.palette.text.primary,
       fontWeight: theme.typography.fontWeightRegular,
       '&:hover, &:focus': {
           backgroundColor: emphasize(backgroundColor, 0.06),
       },
       '&:active': {
           boxShadow: theme.shadows[1],
           backgroundColor: emphasize(backgroundColor, 0.12),
       },
   };
});

export const GroupCompaniesAllView: React.FC<Props> = (props: any): JSX.Element => {

   const [openView, setOpenView] = useState(false);
   const [view, setView] = useState<String>('users');
   const [users, setUsers] = useState([])
   const [actionSelected, setActionSelected] = useState<String>('');
   const [userAdminSelected, setuserAdminSelected] = useState<any>(null);
   const [loading, setLoading] = useState<boolean>(false);

   const [groupCompanies, setGroupCompanies] = useState<any>([])
   const [groupCompanySelected, setGroupCompanySelected] = useState<any>(null)
   const [showModal, setShowModal] = useState<boolean>(false);
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
   })

   const dataUser = readLocalStorage(KEY_USER_DATA);

   const typeSuperadmin = dataUser?.user?.type_superadmin;
   const groupCompaniesSession = dataUser?.user?.group_companies;

   const getUsersAdmin = async () => {
      setLoading(true);
      const response = await userService.getUsers();
      let apimessagge = apiResponseTranslate(response?.data?.message || '');
      if (response.data) {
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: apimessagge,
         }));
         const userFilter = response.data.filter(item =>  item.idrole == 2)
        setUsers(userFilter)
        const uniqueUsers = userFilter.reduce((acc, current) => {
          const x = acc.find((item) => item.iduser === current.iduser);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);
          
        setUserFilters(uniqueUsers);
      } else {
         console.log('error')
      }
      setLoading(false);
   }

   const setUserFilters = (usersData) => {
      if(typeSuperadmin == '2'){
         console.log(usersData)
         const filteredCompanies = usersData.filter(user => groupCompaniesSession.includes(user.idcompany));
         setUsers(filteredCompanies)
     }else{
         setUsers(usersData)
     }  
   }

   function RecuperarData(value) {
      !userAdminSelected && setuserAdminSelected(value)
      userAdminSelected && setGroupCompanySelected(value)
      if(value.action == "view-detail"){
         setView('Grupo de Compañias')
      }
   }

   const getCompaniesGroup = async () =>{
      const resp:any = await companiesService.getGroupCompaniesById(userAdminSelected.iduser);
      if(resp){setGroupCompanies(resp.data) }
   }

   useEffect(() => {
      getUsersAdmin()
   }, [])

   useEffect(()=>{
      if(userAdminSelected){
        console.log(userAdminSelected)
         getCompaniesGroup()
      }
   },[userAdminSelected])

   return (
      <Protected>
         {loading && <Loading/>}
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openView}
         >
            <Grid container
               direction="row"
               justifyContent="center"
               alignItems="center">
               <SpinnerGrow />
               <h1 style={{ marginLeft: '10px' }}><strong>{___PHRASE("cargando")}</strong></h1>
            </Grid>
         </Backdrop>
         <Breadcrumbs aria-label="breadcrumb" sx={{marginY:3, ml:5}}>
                <StyledBreadcrumb
                    label={___PHRASE("listaDeUsuariosAdmin")}
                    onClick={() => {
                        setView('users');
                        setuserAdminSelected('');
                        setShowModal(false);
                    }}
                    icon={<GroupsIcon fontSize="small" />}
                />
                {
                   view == 'Grupo de Compañias'&& (
                        <StyledBreadcrumb
                            label={___PHRASE("grupoDeCompania")}
                            onClick={() => {setView('Grupo de Compañias')}}
                            icon={<GroupsIcon fontSize="small" />}
                        />
                    )
                }
            </Breadcrumbs>
         <Container>
           { view == 'users' && <TableDataV2
               data={users}
               header={[
                  { name: 'user_name', label: ___PHRASE("nombre"), filter: false, Chip: false },
                  { name: 'n_document', label: ___PHRASE("nDocumento"), filter: false, Chip: true },
                  { name: 'email', label: ___PHRASE("correo"), filter: false, Chip: false },
                  { name: 'role', label: ___PHRASE("rol"), filter: false, Chip: true },
                 ]}
               status_action
               btn_edit_disable
               btn_delete_disable
               btn_disabled
               btn_detail_view
               title={___PHRASE("usuarioAdmin")}
               RecuperarData={RecuperarData}
               actionSelect={setActionSelected}
            />}
             { view == 'Grupo de Compañias' && <TableDataV2
               data={groupCompanies}
               header={[
                  { name: 'business_name', label: ___PHRASE("compania"), filter: false, Chip: true },
                  { name: 'registry_number', label: 'RUT', filter: false, Chip: true },
                  { name: 'status_name', label: ___PHRASE("estado"), filter: false, Chip: true },
               ]}
               status_action
               btn_delete_disable
               btn_disabled
               //  checkbox
               title={___PHRASE("gruposDeEmpresa")}
               RecuperarData={RecuperarData}
               setModalSave={setShowModal}
               actionSelect={setActionSelected}
            />}
         </Container>

         {
            actionSelected === 'edit' && view == 'Grupo de Compañias' && (
               <ModalGroupCompany
               open={showModal}
               closeModal={()=>{setShowModal(false)}}
               actionSelected={actionSelected}
               onList={getCompaniesGroup}
               groupCompany={groupCompanySelected}
               clearState={()=>{setGroupCompanySelected('')}}
               />
            )
         }
      </Protected>
   )
};