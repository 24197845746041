import { cmRepository } from "../repositories/CM.repository";

export const cmService = {
    getCM,
    createCM,
    editCM,
    deleteCM,
}

async function getCM(year = ''){
    const res = await cmRepository.getCM(year);
    return res;
}

async function createCM(dataCM){
    const res = await cmRepository.createCM(dataCM);
    return res;
}

async function editCM(idCM ,dataCM){
    const res = await cmRepository.editCM(idCM, dataCM);
    return res;
}

async function deleteCM(idCM){
    const res = await cmRepository.deleteCM(idCM);
    return res;
}
