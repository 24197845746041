import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { useEffect, useState } from "react";
import { Alert, Button, Divider, Grid, Snackbar, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import { Loading } from "@/components/common/Loading";
import { Props } from "./ModalSignatureDetail.type";
import { ContractService } from "@/service/services/Contract.service";
import { API_URL_BASE } from "@/toolbox/defaults/app";
import '../../../assets/styles/modals.scss';
import ___PHRASE from "@/service/translate/translateService";

export const ModalSignatureDetail: React.FC<Props> = (props: Props): JSX.Element => {

    const { open, setOpen , idEmployee, getContractStatus, getContract} = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [detail, setDetail] = useState<any>('')
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    useEffect(()=>{
        getDetailSignature();
    },[]);

    const getDetailSignature = async()=>{
        setLoading(true);
        const res = await ContractService.getDetailSignature(idEmployee);
        if(res.length == 0){
            setLoading(false);
            return (
                setSnackBarConfig({...snackBarConfig, open:true, severity:'warning', message: ___PHRASE("elUsuarioEstaPendienteDeEnviarSuFirma")})
            )
        }
        let resObject = res.slice(-1)[0];
        setDetail(resObject);
        setLoading(false);
    }
  
    const handleSubmit = async (e, status) => {
        status == 1 && e.preventDefault();
        setLoading(true);
        const objData = {
            status: status,
            observations: ""
        }
        const res =  await ContractService.editSignatureAdmin(detail.id, objData);
        getContract();
        getContractStatus();
        if(res?.data){
            setLoading(false);
            setSnackBarConfig({...snackBarConfig, open:true, 
                severity:'success', 
                message: ___PHRASE("elEstadoDelContratoFueActualizadoSatisfactoriamente")
            })
            setTimeout(function(){
                setOpen(false);
            }, 2000);
        }else{
            setLoading(false);
            setSnackBarConfig({...snackBarConfig, open:true, 
                severity:'error', 
                message: ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")
            })
        }
        setLoading(false);
    }

    const verImagen = async() => {
        setLoading(true);
        if(detail && detail.img){
            const route = `${API_URL_BASE}/${detail.img}`;
            window.open(route, '_blank');
        }
        setLoading(false);
    }

    return (
        <>
            {loading && <Loading />}
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
                <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                    {snackBarConfig.message || ""}
                </Alert>
            </Snackbar>

            <Modal open={open} handleClose={() => setOpen(false)} disableEscapeKeyDown disableBackdropClick handle size="sm">
                <ModalHeader
                    text={___PHRASE("validarFirma")}
                    className='positionElements'
                    onCancel={() => setOpen(false)}
                >
                </ModalHeader>
                <ModalBody>

                    <form onSubmit={(e)=>handleSubmit(e,1)}>
                        <Grid container >
                            {
                                (detail && !loading) && (
                                    <>
                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>{___PHRASE("tipoDeFirma")}:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                id="idtypesignature"
                                                size='small'
                                                fullWidth
                                                value={detail.typeSignatureName}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("imagen")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Button variant="contained" component="label" fullWidth onClick={()=>{verImagen()}}>
                                                {___PHRASE("verImagen")}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("estado")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                fullWidth
                                                multiline
                                                value={detail.status == 0 ? ___PHRASE("laVerificacionDeLaFirmaEstaEnProceso") : detail.status == 1 ? ___PHRASE("firmaVerificada"): 
                                                detail.status == 2 ? ___PHRASE("solicitudDeVerificacionEliminada") : ___PHRASE("verificacionRechazada") }
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                size='small'
                                            />
                                        </Grid>
                                    </Grid>
                                    </>
                                ) 
                            }
                            
                            {
                                (!detail && !loading) && (
                                    <Grid container item xs={12} display='block' justifyContent="center" marginBottom='25px'>
                                        <Typography variant="subtitle1" className="textColor">
                                            {___PHRASE("elEmpleadoAunNoHaEnviadoSuFirma")}
                                        </Typography>
                                    </Grid>
                                )
                            }
                            {
                                loading && (
                                    <Grid container item xs={12} alignItems="flex-start" justifyContent="center">
                                        <Grid sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', borderRadius: '10px', height: '120px', width:'100%', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                                        </Grid>
                                    </Grid>
                                )
                            }
                        </Grid>

                        <Divider />

                        {
                            (detail && detail.status == 0)  ? (
                                <ModalFooter
                                    buttonType="submit"
                                    confirmText={___PHRASE("confirmar")}
                                    cancelText={___PHRASE("rechazar")}
                                    onCancel={(e)=>handleSubmit(e,3)}
                                    onConfirm={(e)=>handleSubmit(e,1)}
                                />
                            ) : (
                                <ModalFooter
                                    buttonType="submit"
                                    cancelText={___PHRASE("cancelar")}
                                    onCancel={() => setOpen(false)}
                                />
                            )
                        }

                    </form>


                </ModalBody>
            </Modal>
        </>
    );
}