import { ModuleRepository } from "../repositories/Module.repository";

export const ModuleService = {
    getAllModules,
    getByRoles,
    createModule,
    editModule,
    deleteModule,
}

async function getAllModules(){
    const res = await ModuleRepository.getAllModules();
    return res;
}

async function getByRoles(idroles){
    const res = await ModuleRepository.getByRoles(idroles);
    return res;
}

async function createModule(data:{}){
    const res = await ModuleRepository.createModule(data);
    return res;
}

async function editModule(id, data:{}){
    const res = await ModuleRepository.editModule(id, data);
    return res;
}

async function deleteModule(id){
    const res = await ModuleRepository.deleteModule(id);
    return res;
}
