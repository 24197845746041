import { Alert, Snackbar, Typography } from "@mui/material";
import { useState } from "react";
import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalFooter } from "../ModalFooter";
import { ModalHeader } from "../ModalHeader";
import ___PHRASE from "@/service/translate/translateService";
import { Loading } from "@/components/common/Loading";
import { Props } from "./ModalWorkerDelete.type";
import { WorkerService } from "@/service/services/Worker.service";
import { apiResponseTranslate } from "@/toolbox/helpers/responseApi.helper";

export const ModalWorkerDelete: React.FC<Props> = (props:any): JSX.Element => {
    const { open, closeModal, employee, onListEmployees, clearState } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
  });

    const onDeleted= async()=>{
        setLoading(true);
        await WorkerService.deleteEmployee(employee.id).then((data) => {
          let apimessagge = apiResponseTranslate(data?.data?.message || '');
            if(data.data.status === true){
                setLoading(false);
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                  severity:'success', 
                  message: `${apimessagge || ___PHRASE("empleadoEliminadoSatisfactoriamente.")}` 
                }))
                onListEmployees && onListEmployees();
                setTimeout(function(){
                    closeModal && closeModal();
                    clearState && clearState();
                }, 1000);
            }else{
                setLoading(false);
                return (
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'error', 
                        message: `${apimessagge || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}` 
                    }))
                )
            }
        })
        setLoading(false);
    }
  
  return (
    <>
     {loading && <Loading/>}
    <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
    </Snackbar>
    <Modal
      open={open}
      handleClose={closeModal}
      disableEscapeKeyDown disableBackdropClick
      size="xs"
    >
      <ModalHeader
        text={___PHRASE("eliminar")+' '+___PHRASE("empleado")}
        className='positionElements'
        onCancel={closeModal}
        clearState={clearState}
      >
      </ModalHeader>

      <ModalBody>
        <Typography variant="subtitle1" className="textColor"> 
          {___PHRASE("estaSeguroDeEliminarAlEmpleado2")}
        </Typography>
        <Typography variant="subtitle1" sx={{color:'#605f5f', marginLeft: '10px'}}> 
          {___PHRASE("alEliminarUnEmpleadoSeEliminaraElUsuarioyContratoAlCualEsteAfiliadoDentroDeLaEmpresa")}
        </Typography>
      </ModalBody>

      <ModalFooter 
        confirmText={___PHRASE("eliminar")}
        cancelText={___PHRASE("cancelar")}
        onCancel={closeModal}
        className="footerRight"
        onConfirm={onDeleted}
        clearState={clearState}
      />
    </Modal>
    </>
  )
}