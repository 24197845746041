import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik } from 'formik';
import { useEffect, useState } from "react";
import { Alert, Autocomplete, Divider, Grid, Snackbar, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import { Loading } from "@/components/common/Loading";
import { Props } from "./ModalExpenseConfirm.type";
import { RleStructureService } from "@/service/services/RleStructure.service";
import { EmployeeExpenseService } from "@/service/services/EmployeeExpense.service";
import '../../../assets/styles/modals.scss';
import ___PHRASE from "@/service/translate/translateService";

export const ModalExpenseConfirm: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, extraHour, onListDetails, closeModalMain, detail, expenseSelected, coverageCompany, clearState, dataDetail } = props;

    const periodExpense = (expenseSelected?.period).split('-');
    const filterPeriod = periodExpense[1]+'-'+periodExpense[0];
    const amount = (parseFloat(detail?.amount) * parseFloat(coverageCompany)) / 100

    const [loading, setLoading] = useState<boolean>(false);
    const [structures, setStructures] = useState<any>([]);
    const [structureSelected, setStructureSelected] = useState<any>(null);

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const [data, setData] = useState({
        id: extraHour ? extraHour.id : '',
        idemployee: extraHour ? extraHour.idemployee : '',
        iddetstructureemployeeopt: extraHour ? extraHour.iddetstructureemployeeopt : '',
        hour: extraHour ? extraHour.hour : '',
        date: extraHour ? extraHour.date : '',
        code: extraHour ? extraHour.code : '',
    });

    useEffect(()=> {
        if(expenseSelected.idemployee){
            getDetailEmployeeSO(expenseSelected.idemployee);
        }
    },[expenseSelected.idemployee]);

    useEffect(() => {
        if(detail && structures){
            setCustomRleOptional(detail.reportTypeRlestructureoptional, structures);
        }

    },[detail, structures]);

    function setCustomRleOptional(idrlestructureoptional, structures): any {
        const data = structures && structures.find(structure => structure.idrlestructureoptional  === idrlestructureoptional);
        if(!data){
            setStructureSelected(null);
            return true;
        };

        const newData = {
            id: data?.id,
            idrlestructureoptional: data?.idrlestructureoptional,
            alias_name: data?.alias_name,
            code: data?.code,
            value: data?.value,
        }
        return setStructureSelected(newData);
    }

    const getDetailEmployeeSO = async (id_employee) => {
        setLoading(true);
        const resp:any = await RleStructureService.getDetailEmployeeSO(id_employee);
        const arrayStructure = [];
        if(resp.data){
            (resp.data).map((item)=>{
                if(item.code != 'HIT2'){
                    arrayStructure.push(item);
                }
            })
            setStructures(arrayStructure || [])
        }
        setLoading(false);
    }

    const validateForm = (values) => {
        let errors: any = {};
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        if(!structureSelected){
            return (
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                    severity:'warning', 
                    message: ___PHRASE("seleccioneUnaEstructura") }
                ))
            );
        }

        const formData = {
            id: structureSelected.id,
            value: parseFloat(structureSelected.value) + amount,
            validity: filterPeriod,
            status: '1'
        }
        setLoading(true);

        let result = false;

        if(detail && dataDetail){
            await EmployeeExpenseService.editReportDetail(detail.id, dataDetail).then((data) => {
                if(data?.data){
                    result  = true;
                }
            })
        }

        if(result){
            await RleStructureService.editDetailEmployeeSO(formData.id, formData).then((data) => {
                if(data?.data){
                    closeModalMain && closeModalMain();
                    closeModal && closeModal();
                    onListDetails && onListDetails(expenseSelected.id);
                    return (
                        setSnackBarConfig(prev => ({ ...prev, open: true, 
                            severity:'success', 
                            message: ___PHRASE("laOperacionSeRealizoConExito") }
                        ))
                    );
                }
                
            })
        }

        setLoading(false);
    }

    return(
        <>
        {loading && <Loading/>}

        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>

        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={___PHRASE("agregarMontoaEstructura")}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("nombreEmpleado")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            variant="standard"
                                            type="text" 
                                            fullWidth
                                            value={expenseSelected?.name_all}
                                            disabled
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("periodo")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            variant="standard"
                                            type='month'
                                            fullWidth
                                            value={filterPeriod || expenseSelected?.period}
                                            disabled
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("monto")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                variant="standard"
                                                fullWidth
                                                type="number"
                                                value={amount}
                                                disabled
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>{___PHRASE("estructura")}:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Autocomplete
                                                id="disable-portal"
                                                disablePortal
                                                value={structureSelected}
                                                options={structures || []}
                                                getOptionLabel={(option) => option.code +' - '+ option.alias_name || ""}
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} variant="standard" label={___PHRASE("noSeEncontroLaEstructura")} />}
                                                onChange={(e, newValue) => { setStructureSelected(newValue) }}
                                                disabled
                                            />
                                        </Grid>
                                    </Grid>


                                </Grid>
                                <Divider />
                                {
                                    structureSelected != null ? (
                                        <ModalFooter 
                                            buttonType="submit"
                                            confirmText={___PHRASE("confirmar")}
                                            onConfirm={handleSubmit}
                                            clearState={clearState}
                                        />
                                    ) : ( loading ? '' : (
                                        <Typography sx={{paddingTop: '10px', color: 'red'}}>
                                            {___PHRASE("elEmpleadoNoTieneAsignadoLaEstructuraOpcionalaLaCualPerteneceElTipoDeGasto")}
                                        </Typography>
                                    ))
                                }
                                
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}