import {LoanRepository} from '../repositories/Loan.repository';

export const LoanService = {
    getLoan,
    getOptionalFeeLoan,
    getDetailFeeLoan,
    createMaxEmployeeLoan,
    validatorMaxLoan,
    requestLoan,
    createLoanOptional,
    accept,
    limitForLey
}

async function getLoan(status, idEmployee){
    const res = await LoanRepository.getLoan(status, idEmployee);
    return res;
}
async function getOptionalFeeLoan(idLoan){
    const res = await LoanRepository.getOptionalFeeLoan(idLoan);
    return res;
}
async function getDetailFeeLoan(idLoan){
    const res = await LoanRepository.getDetailFeeLoan(idLoan);
    return res;
}
async function limitForLey(idLoan){
    const res = await LoanRepository.limitForLey(idLoan);
    return res;
}
async function createMaxEmployeeLoan(dataLoan){
    const res = await LoanRepository.createMaxEmployeeLoan(dataLoan);
    return res;
}
async function validatorMaxLoan(idEmployee){
    const res = await LoanRepository.validatorMaxLoan(idEmployee);
    return res;
}
async function requestLoan(dataLoan){
    const res = await LoanRepository.requestLoan(dataLoan);
    return res;
}
async function createLoanOptional(dataLoan){
    const res = await LoanRepository.createLoanOptional(dataLoan);
    return res;
}
async function accept(dataLoan){
    const res = await LoanRepository.accept(dataLoan);
    return res;
}
