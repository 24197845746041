import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

const dataUser = readLocalStorage(KEY_USER_DATA);
const rut_empresa = dataUser?.user?.rut_empresa;

export const ViewsRepository = {

    getAllViews: async(): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/views`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    getByModules: async(idmodules): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/views/getByModules?idmodules=${idmodules}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    getViewsByRoles: async(idroles): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/views/getByRoles?idroles=${idroles}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    getAdditionalViews: async(iduser:number): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/views/getAdditionalViews?iduser=${iduser}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    getViewsByProfile: async(idprofile:number): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/views/getByProfile?idprofile=${idprofile}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    getViewsByProfileOfUser: async(iduser:number): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/views/getByProfileOfUser?iduser=${iduser}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    createView: async (dataView) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/views`, {
            code: dataView.code,
            name: dataView.name,
            route: dataView.route,
            required: dataView.required,
            idmodule: dataView.idmodule,
            icon: dataView.icon,
            idrole: dataView.idrole,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    editView: async (idView:number, dataView) : Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/views/${idView}`, {
            code: dataView.code,
            name: dataView.name,
            route: dataView.route,
            required: dataView.required,
            idmodule: dataView.idmodule,
            icon: dataView.icon,
            idrole: dataView.idrole,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    deleteView: async(idView:number): Promise<any> => {
        const res= await http.delete<any>(`${API_URL_BASE}/v1/views/${idView}`)
        return res;
     },
}   