import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

const dataUser = readLocalStorage(KEY_USER_DATA);
const rut_empresa = dataUser?.user?.rut_empresa;

export const PermissionsRepository = {

    getPermissions: async(): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/permissions`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    getPermissionsByUser: async(idUser: number,idCompany): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/permissions/${idUser}?idCompany=${idCompany}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    createPermission: async (dataPermission) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/permissions`, {
            idviews: dataPermission.idviews,
            iduser: dataPermission.iduser,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    editPermission: async (idPermission:number, dataPermission) : Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/permissions/${idPermission}`, {
            idviews: dataPermission.idviews,
            iduser: dataPermission.iduser,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    deletePermission: async(idPermission:number): Promise<any> => {
        const res= await http.delete<any>(`${API_URL_BASE}/v1/permissions/${idPermission}`)
        return res;
     },
}   