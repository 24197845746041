import { Route, Redirect } from 'react-router-dom';
import { authenticationService } from '@service/services/Authentication.service';
import { ROUTE_COMPANIES, ROUTE_LOGIN, ROUTE_MY_VALIDATIONS } from '@toolbox/constants/route-map';
import { ROLE_ADMIN, ROLE_SUPER_ADMIN, ROLE_TRABAJADOR } from '@/toolbox/defaults/static-roles';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import * as qs from 'qs'


export const PrivateRoute = ({ component: Component, ...rest }) => (
   <Route {...rest} render={props => {
      const currentUser = authenticationService.currentUserValue;
      const authCookie  = authenticationService.authCookie();

      if (!currentUser || !authCookie) {
         return <Redirect {...props} to={{ pathname: ROUTE_LOGIN, state: { from: props.location } }} />
      }
      return <Component {...props} />
   }} />
)

export const AuthRoute = ({ component: Component, ...rest }) => (


   <Route {...rest} render={props => {
      const currentUser = authenticationService.currentUserValue;
      const authCookie  = authenticationService.authCookie();
      const dataUser= readLocalStorage(KEY_USER_DATA);


      const ruta = props.location.search + '.';
      const { vista, periodo } = props.location && qs.parse(ruta.slice(1, -1));

      if (currentUser && authCookie) {
          const role = dataUser?.user?.role;

         var decodeView;
         var decodePeriod;

         if(vista && periodo){
            decodeView = atob(vista);
            const jsonString  = atob(periodo)
            decodePeriod = JSON.parse(jsonString)
         }

         switch (role) {
            case ROLE_SUPER_ADMIN:
               if(decodeView && decodeView) return <Redirect to={{ pathname: decodeView, state: decodePeriod }} />
               return <Redirect to={{ pathname: ROUTE_COMPANIES, state: { from: props.location } }} />

            case ROLE_ADMIN:
               if(decodeView && decodeView) return <Redirect to={{ pathname: decodeView, state: decodePeriod }} />
               return <Redirect to={{ pathname: ROUTE_COMPANIES, state: { from: props.location } }} />

            case ROLE_TRABAJADOR:
               if(decodeView && decodeView) return <Redirect to={{ pathname: decodeView, state: decodePeriod }} />
               return <Redirect to={{ pathname: ROUTE_MY_VALIDATIONS, state: { from: props.location } }} />
         }

         // return(
         //    <>
         //   <Redirect to={{ pathname: ROUTE_HOME, state: { from: props.location } }} />
         //   <Redirect to={{ pathname: ROUTE_USER, state: { from: props.location } }} />
         //   <Redirect to={{ pathname: ROUTE_WORKER, state: { from: props.location } }} />
         //   <Redirect to={{ pathname: ROUTE_BRANCH_OFFICE, state: { from: props.location } }} />
         //   <Redirect to={{ pathname: ROUTE_COST_CENTER, state: { from: props.location } }} />
         //   <Redirect to={{ pathname: ROUTE_DEPARMENT, state: { from: props.location } }} />
         //   <Redirect to={{ pathname: ROUTE_CHARGES, state: { from: props.location } }} />
         //   <Redirect to={{ pathname: ROUTE_PROJECT, state: { from: props.location } }} />
         //   <Redirect to={{ pathname: ROUTE_PROFILES, state: { from: props.location } }} />
         //   <Redirect to={{ pathname: ROUTE_PHASES, state: { from: props.location } }} />
         //   <Redirect to={{ pathname: ROUTE_COMPANIES, state: { from: props.location } }} />
         //    </>
         // )

      }
      return <Component {...props} />
   }} />
   
)
