import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useEffect, useState } from "react";
import { Alert, Autocomplete, Divider, Grid, MenuItem, Select, Snackbar, TextField, Typography } from "@mui/material";
import '../../../assets/styles/modals.scss';
import { Loading } from "@/components/common/Loading";
import ___PHRASE from "@/service/translate/translateService";
import { ModalFooter } from "@/components/Modal/ModalFooter";
import { PeriodService } from "@/service/services/Period.service";
import { OnBoardingService } from "@/service/services/OnBoarding.service";
import { Modal } from '@/components/Modal/Modal';
import { ModalHeader } from '@/components/Modal/ModalHeader';
import { ModalBody } from '@/components/Modal/ModalBody';

export const ModalUploadExcel: React.FC<any> = (props:any): JSX.Element => {

    const {closeModal, getStructureOnboarding, idCompany} = props

    const [loading, setLoading] = useState<boolean>(false);
    const [years, setYears] = useState<any>([]);
    const [periods, setPeriods] = useState<any>([]);
    const [yearSelected, setYearSelected] = useState<any>('');
    const [monthSelected, setMonthSelected] = useState<any>('');
    const [selectedFile, setSelectedFile] = useState(null);

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    useEffect(() => {
        getPeriods();
    }, []);

    const getPeriods = async() => {
        setLoading(true);
        const resp:any = await PeriodService.getPeriods();
        const newData = [];
        const arrayYears = [];
        resp.data && (resp.data).length > 0 && (resp.data).map((item) => {
           newData.push({
              id: item.id,
              month: item.month,
              year: item.year,
              alias_name: item.month + '-' +  item.year,
           })
           const found = arrayYears.find(element => element == item.year);
           if(!found) { arrayYears.push(item.year); }
           
        });
        setYears(arrayYears);
        setPeriods(newData || []);
        setLoading(false);
     }

     const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
      };

      const onSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
  
        const periodSelected = monthSelected + '-' + yearSelected;
        const period = periods.find(element => element.alias_name == periodSelected);
  
        const formdata = new FormData();
        formdata.append("excel", selectedFile);
        formdata.append("idperiod", period.id);
        formdata.append("idCompany", idCompany);
        formdata.append("year", yearSelected);
        formdata.append("month", monthSelected);
  
        const resp = await OnBoardingService.uploadPlantillaStructureExcel(formdata);
        if(!!!resp.data.status){
           setSnackBarConfig({...snackBarConfig, open:true, severity:'error', message: resp.data.message})
           setLoading(false)
           return
        }
        setSnackBarConfig({...snackBarConfig, open:true, severity:'success', message: resp.data.message})
        await getStructureOnboarding();
        setLoading(false)
        closeModal()
     }

    return(
        <>
        {loading && <Loading/>}

        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>

        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={___PHRASE("subirPlantilla")}
                className='positionElements'
                onCancel={closeModal}

            >
            </ModalHeader>
            <ModalBody>
                            <form onSubmit={onSubmit}>
                                <Grid container >
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("periodo")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Select
                                                id="id_education_level"
                                                variant="standard"
                                                onChange={(event) => {
                                                    setYearSelected(event.target.value);
                                                }}
                                                sx={{ width: '12vw', ml: 1 }}
                                            >
                                                {
                                                    years.map((year, i) => {
                                                        return <MenuItem key={i} value={year}>{year}</MenuItem>;
                                                    })
                                                }
                                            </Select>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("mes")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <Select
                                            id="id_education_level"
                                            variant="standard"
                                            onChange={(event) => { setMonthSelected(event.target.value) }}
                                            sx={{ width: '12vw', ml: 1 }}
                                        >
                                            <MenuItem value={'01'}>{___PHRASE("enero")}</MenuItem>
                                            <MenuItem value={'02'}>{___PHRASE("febrero")}</MenuItem>
                                            <MenuItem value={'03'}>{___PHRASE("marzo")}</MenuItem>
                                            <MenuItem value={'04'}>{___PHRASE("abril")}</MenuItem>
                                            <MenuItem value={'05'}>{___PHRASE("mayo")}</MenuItem>
                                            <MenuItem value={'06'}>{___PHRASE("junio")}</MenuItem>
                                            <MenuItem value={'07'}>{___PHRASE("julio")}</MenuItem>
                                            <MenuItem value={'08'}>{___PHRASE("agosto")}</MenuItem>
                                            <MenuItem value={'09'}>{___PHRASE("septiembre")}</MenuItem>
                                            <MenuItem value={'10'}>{___PHRASE("octubre")}</MenuItem>
                                            <MenuItem value={'11'}>{___PHRASE("noviembre")}</MenuItem>
                                            <MenuItem value={'12'}>{___PHRASE("diciembre")}</MenuItem>
                                        </Select>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1"><b>{___PHRASE("documento")}:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <input
                                                type="file"
                                                accept=".xlsx, .xls"
                                                onChange={handleFileChange}
                                                style={{ marginBottom: '16px' }}
                                            />
                                        </Grid>
                                    </Grid>            
                                </Grid>
                                <Divider />
                                <ModalFooter
                                    buttonType="submit"
                                    confirmText={___PHRASE("cargar")}
                                    cancelText={___PHRASE("cancelar")} 
                                    onCancel={closeModal}
                                    onConfirm={onSubmit}
                    
                                />
                            </form>
            </ModalBody>
        </Modal>
        </>
    );
}