import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_TRANSLATE, KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import moment from 'moment';

const language = readLocalStorage(KEY_TRANSLATE);
const dataUser = readLocalStorage(KEY_USER_DATA);
const rut_empresa = dataUser?.user?.rut_empresa;

export const VariableGlobalRepository = {

    getVaribaleGlobal: async(): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/variableGlobal/country/1`);
        const {data, error, message} = res;
        return { data: (data||[]).map((raw) => ({
          ...raw,
          dateFormated: moment(raw.date).format('MMMM [del] YYYY')
         })), error, message };
    },

    createVariableGlobal: async (dataVG) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/variableGlobal`, dataVG);
        const {data, error, message} = res;
        return { data, error, message };
    },

    editVariableGlobal: async (id, dataVG) : Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/variableGlobal/${id}`, dataVG);
        const {data, error, message} = res;
        return { data, error, message };
    },

    deleteVariableGlobal: async(id): Promise<any> => {
        const res = await http.delete<any>(`${API_URL_BASE}/v1/variableGlobal/${id}`)
        const {data, error, message} = res;
        return { data, error, message };
    }
}   