import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { Project, Projects } from '../models/Projects';
import { ProjectsDTO } from '../http/dto/ProjectsDTO';


export const projectRepository = {

   getProjects: async (search = '') : Promise<Project>=> {
      const dataUser = readLocalStorage(KEY_USER_DATA);
      const users = await http.get<ProjectsDTO>(`${API_URL_BASE}/v1/project?search=${search}`);
      const { data, error, message } = users;
      return {
         data: (data||[]).map((raw) => ({
            id: raw?.id,
            name: raw?.name,
            description: raw?.description,
            start_date:raw?.start_date,
            end_date:raw?.end_date,
            projected_total:raw?.projected_total,
            total:raw?.total,
            idtypeproject: raw?.idtypeproject
         })),
         error,
         message
      }
   },

   createProject: async (dataProject) : Promise<any> => {
      const res = await http.post<any>(`${API_URL_BASE}/v1/project`, {
         id: dataProject?.id,
         name: dataProject?.name,
         description: dataProject?.description,
         start_date: dataProject?.start_date,
         end_date: dataProject?.end_date,
         projected_total: dataProject?.projected_total,
         idtypeproject: dataProject?.idtypeproject,
      });
      const {data, error, message} = res;
      return { data, error, message };
   },

   editProject: async (dataProject, idProject:number) : Promise<any> => {
      const res = await http.patch<any>(`${API_URL_BASE}/v1/project/${idProject}`, {
         id: dataProject?.id,
         name: dataProject?.name,
         description: dataProject?.description,
         start_date: dataProject?.start_date,
         end_date: dataProject?.end_date,
         projected_total: dataProject?.projected_total,
         idtypeproject: dataProject?.idtypeproject,
      });
      const {data, error, message} = res;
      return { data, error, message };
   },

   deleteProject: async(idProject:number): Promise<any> => {
      const res= await http.delete<any>(`${API_URL_BASE}/v1/project/${idProject}`)
      return res;
   },

   //crud de empleados por proyectos

   getDetailProject: async(idProject:number): Promise<any> => {
      const res = await http.get<any>(`${API_URL_BASE}/v1/project/getDetailProject/${idProject}`);
      const {data, error, message} = res;
      return { data, error, message };
   },

   addEmployee: async (dataProject) : Promise<any> => {
         const res = await http.post<any>(`${API_URL_BASE}/v1/project/addEmployee`, {
            function_project: dataProject.function_project,
            idproject: dataProject.idproject,
            idemployee: dataProject.idemployee,
         });
         const {data, error, message} = res;
         return { data, error, message };
   },

   editEmployee: async (dataProject, idProject:number) : Promise<any> => {
         const res = await http.patch<any>(`${API_URL_BASE}/v1/project/editEmployee/${idProject}`, {
            function_project: dataProject.function_project,
            idproject: dataProject.idproject,
            idemployee: dataProject.idemployee,
         });
         const {data, error, message} = res;
         return { data, error, message };
   },

   deleteEmployee: async(idDetailProject): Promise<any> => {
      const res= await http.post<any>(`${API_URL_BASE}/v1/project/deleteEmployee/${idDetailProject}`)
      return res;
   },

   getDetailParticipation: async(idDetail:number): Promise<any> => {
      const res = await http.get<any>(`${API_URL_BASE}/v1/project/getDetailParticipation/${idDetail}`);
      const {data, error, message} = res;
      return { data, error, message };
   },

   createDetailParticipation: async (dataProject) : Promise<any> => {
      const res = await http.post<any>(`${API_URL_BASE}/v1/project/createDetailParticipation`, {
         idperiod: dataProject.idperiod,
         idemployee: dataProject.idemployee,
         participation_percentage: dataProject.participation_percentage,
         total: dataProject.total,
         iddetailproject: dataProject.iddetailproject,
      });
      const {data, error, message} = res;
      return { data, error, message };
   },

   verifyTotal: async (idemployee, idperiod, total) : Promise<any> => {
      const res = await http.get<any>(`${API_URL_BASE}/v1/project/verifyTotal?idemployee=${idemployee}&idperiod=${idperiod}&total=${total}`);
      const {data, error, message} = res;
      return { data, error, message };
   },

   verifyParticipation: async (idemployee, idperiod, participation_percentage) : Promise<any> => {
      const res = await http.get<any>(`${API_URL_BASE}/v1/project/verifyParticipation?idemployee=${idemployee}&idperiod=${idperiod}&participation_percentage=${participation_percentage}`);
      const {data, error, message} = res;
      return { data, error, message };
   },

   seePercentageOrTotal: async (dataProject) : Promise<any> => {
      const res = await http.post<any>(`${API_URL_BASE}/v1/project/seePercentageOrTotal`, dataProject);
      const {data, error, message} = res;
      return { data, error, message };
   },
}


