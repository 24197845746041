import { Alert, Snackbar, Typography } from "@mui/material";
import { useState } from "react";
import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalFooter } from "../ModalFooter";
import { ModalHeader } from "../ModalHeader";
import { Loading } from "@/components/common/Loading";
import { Props } from "./ModalExpenseEmployeeDetailDelete.type";
import { EmployeeExpenseService } from "@/service/services/EmployeeExpense.service";
import ___PHRASE from "@/service/translate/translateService";

export const ModalExpenseEmployeeDetailDelete: React.FC<Props> = (props:any): JSX.Element => {
    const { open, closeModal, expense, detail, onListDetail, clearState } = props;
    const [loading, setLoading] = useState<boolean>(false);

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
    });

    const onDeleted= async()=>{
        setLoading(true);
        await EmployeeExpenseService.deleteDetail(detail.id).then((data) => {
            setSnackBarConfig(prev => ({ ...prev, open: true, 
                severity:'success', 
                message: ___PHRASE("detalleEliminado")
            }))
            onListDetail && onListDetail(expense.id);
            setTimeout(function(){
                closeModal && closeModal();
                clearState && clearState();
            }, 1000);
        })
        setLoading(false);
    }
  
  return (
    <>
    {loading && <Loading/>}
    <Snackbar
        open={snackBarConfig.open} 
        autoHideDuration={snackBarConfig.autoHideDuration} 
        onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
        <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
            {snackBarConfig.message || ""}
        </Alert>
    </Snackbar>

    <Modal
      open={open}
      handleClose={closeModal}
      size="xs"
      disableEscapeKeyDown disableBackdropClick
    >
      <ModalHeader
        text={___PHRASE("eliminarDetalleDeGasto")}
        className='positionElements'
        onCancel={closeModal}
        clearState={clearState}
      >
      </ModalHeader>

      <ModalBody>
        <Typography variant="subtitle1" className="textColor"> 
          {detail && detail.review_status != 'pendiente' ? ___PHRASE("noEsPosibleEliminarElDetalle") : ___PHRASE("estaSeguroDeEliminarElDetalle")}
        </Typography>
      </ModalBody>

      {
        detail && detail.review_status == 'pendiente' && (
          <ModalFooter 
            confirmText={___PHRASE("eliminar")}
            cancelText={___PHRASE("cancelar")}
            onCancel={closeModal}
            className="footerRight"
            onConfirm={onDeleted}
            clearState={clearState}
          />
        ) 
      }

    </Modal>
    </>
  )
}