import { AdvanceService } from "@/service/services/Advance.service";
import { Alert, Snackbar, Typography } from "@mui/material";
import { useState } from "react";
import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalFooter } from "../ModalFooter";
import { ModalHeader } from "../ModalHeader";
import { Props } from "./ModalAdvanceDelete.type";
import { Loading } from "@/components/common/Loading";
import ___PHRASE from "@/service/translate/translateService";

export const ModalAdvanceDelete: React.FC<Props> = (props:any): JSX.Element => {
    const { open, closeModal, advance, onListAdvances, workerSelected, clearState } = props;
    const [loading, setLoading] = useState<boolean>(false);

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
    });

    const onDeleted= async()=>{
        setLoading(true);
        await AdvanceService.deleteAdvance(advance.id).then((data) => {
            setSnackBarConfig(prev => ({ ...prev, open: true, 
              severity:'success', 
              message: `${___PHRASE("adelantoeliminado")}` }
            ));
            onListAdvances && onListAdvances(workerSelected.id);
            closeModal && closeModal();
            clearState && clearState();
        })
        setLoading(false);
    }
  
  return (
    <>
    {loading && <Loading/>}
    <Snackbar
        open={snackBarConfig.open} 
        autoHideDuration={snackBarConfig.autoHideDuration} 
        onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
        <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
            {snackBarConfig.message || ""}
        </Alert>
    </Snackbar>

    <Modal
      open={open}
      handleClose={closeModal}
      size="xs"
      disableEscapeKeyDown disableBackdropClick
    >
      <ModalHeader
        text={___PHRASE("eliminarAnticipo")}
        className='positionElements'
        onCancel={closeModal}
        clearState={clearState}
      >
      </ModalHeader>

      <ModalBody>
        <Typography variant="subtitle1" className="textColor"> 
          {advance && advance.request_status != 'pendiente' ? ___PHRASE("noesposibleeliminarelanticipolasolicitudyahasidorespondida") : ___PHRASE("estasegurodeeliminaresteanticipo")}
        </Typography>
      </ModalBody>

      {
        advance && advance.request_status == 'pendiente' && (
          <ModalFooter 
            confirmText={___PHRASE("eliminar")}
            cancelText={___PHRASE("cancelar")}
            onCancel={closeModal}
            className="footerRight"
            onConfirm={onDeleted}
            clearState={clearState}
          />
        ) 
      }

    </Modal>
    </>
  )
}