import React, { useEffect, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Container, Breadcrumbs } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { Loading } from '@/components/common/Loading';
import { Props } from './AdminExpense.type';
import { ExpenseAdminService } from '@/service/services/ExpenseAdmin.service';
import { ModalAdminExpense } from '@/components/Modal/ModalAdminExpense';
import { emphasize, styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import GroupsIcon from '@mui/icons-material/Groups';
import { EmployeeExpenseService } from '@/service/services/EmployeeExpense.service';
import { ModalExpenseEmployeeDetail } from '@/components/Modal/ModalExpenseEmployeeDetail/ModalExpenseEmployeeDetail';
import { useLocation } from 'react-router-dom';
import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined';
import ___PHRASE from '@/service/translate/translateService';
import { apiResponseTranslate } from '@/toolbox/helpers/responseApi.helper';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_TRANSLATE } from '@/toolbox/constants/local-storage';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
   const backgroundColor =
       theme.palette.mode === 'light'
           ? theme.palette.grey[100]
           : theme.palette.grey[800];
   return {
       backgroundColor,
       height: theme.spacing(3),
       color: theme.palette.text.primary,
       fontWeight: theme.typography.fontWeightRegular,
       '&:hover, &:focus': {
           backgroundColor: emphasize(backgroundColor, 0.06),
       },
       '&:active': {
           boxShadow: theme.shadows[1],
           backgroundColor: emphasize(backgroundColor, 0.12),
       },
   };
});

export const AdminExpenseView: React.FC<Props> = (props: any): JSX.Element => {

   const language = readLocalStorage(KEY_TRANSLATE);
   let location = useLocation();
   const dataprops: any = location?.state;
   const idExpense: any = dataprops?.idExpense;

   const [loading, setLoading] = useState<boolean>(false);
   const [value, setValue] = useState('1');

   const [requestExpense, setRequestExpense] = useState([]);
   const [actionSelected, setActionSelected] = useState<String>('');
   const [showAdminExpenseModal, setShowAdminExpenseModal] = useState<boolean>(false);
   const [expenseSelected, setExpenseSelected] = useState<any>('');

   const [view, setView] = useState<String>('expense');
   const [showDetails, setShowDetails] = useState<boolean>(false);
   const [details, setDetails] = useState([]);
   const [detailSelected, setDetailSelected] = useState<any>('');
   const [showDetailModal, setShowDetailModal] = useState<boolean>(false);

   useEffect(() => {
      getExpenseRequests()
   }, []);

   useEffect(()=> {
      if(expenseSelected && !showAdminExpenseModal){
          getDetailsExpense(expenseSelected.id);
      }
  },[expenseSelected, !showAdminExpenseModal]);

   useEffect(()=> {
      if(dataprops && requestExpense){
         const findRequest = requestExpense.find(item => item.id  == idExpense)
         getDetailsExpense(idExpense)
         setExpenseSelected(findRequest)
         setShowDetails(true)
         setView('details')
      }
   },[dataprops, requestExpense]);

   const cabeceraDetail = [
      { name: 'reportTypeName', label: ___PHRASE("tipoDeReporte") },
      { name: 'detail', label: ___PHRASE("conceptoDeDetalle") },
      { name: 'amount', label: `${___PHRASE("gastoTotal")} ($)` },
      { name: 'amountCompany', label: `${___PHRASE("gastoDeLaEmpresa")} ($)` },
      { name: 'review_status', label: `${___PHRASE("estadoDeRevision")}` },
  ];

   const getExpenseRequests = async () => {
      setLoading(true);
      const response = await ExpenseAdminService.getExpenseAdmin('', '');
      if (response.data) {
         setRequestExpense(response.data || [])
      } else {
         console.log('error')
      }
      setLoading(false);
   }

   const getDetailsExpense = async(idExpense) => {
      await EmployeeExpenseService.getDetailsExpense(idExpense, '', '', '').then((data) => {
         const newData = [];
         data.data && (data.data).length > 0 && (data.data).map((item)=>{
            if(language && language == 'en'){
               newData.push({
                  ...item,
                  reportTypeName: apiResponseTranslate(item.reportTypeName),
                  review_status: item.review_status == '0' ? ___PHRASE("pendiente") : (item.review_status == '1' ? ___PHRASE('aceptada') : ___PHRASE('rechazado')),
                  amountCompany: item.review_status == '1' ? (item.coverage_company * item.amount)/100 : '---'
               })
            }else{
               newData.push({
                  ...item,
                  reportTypeName: item.reportTypeName,
                  review_status: item.review_status == '0' ? ___PHRASE("pendiente") : (item.review_status == '1' ? ___PHRASE('aceptada') : ___PHRASE('rechazado')),
                  amountCompany: item.review_status == '1' ? (item.coverage_company * item.amount)/100 : '---'
               })
            }
         })
         setDetails(newData || []);
         setLoading(false);
      })
   }

   function RecuperarData(value){
      !expenseSelected && setExpenseSelected(value);
      expenseSelected && setDetailSelected(value);
      if(value.action != 'edit'){
         setShowDetails(true);
         setView('details');
     }
   }

   return (
      <Protected>
         {loading && <Loading />}
         <Container>

            <Breadcrumbs aria-label="breadcrumb" sx={{my:3, ml:2}}>
               <StyledBreadcrumb
                  label={___PHRASE("solicitudesDeGastos")}
                  onClick={() => {
                     setView('expense');
                     setExpenseSelected('');
                     setShowDetails(false);
                  }}
                  icon={<GroupsIcon fontSize="small" />}
               />
               {
                  showDetails && (
                     <StyledBreadcrumb
                           label={___PHRASE("detalleDelGasto")}
                           onClick={() => {setView('details')}}
                           icon={<GroupsIcon fontSize="small" />}
                     />
                  )
               }
            </Breadcrumbs>

            {
               view == 'expense' && (
                  <>
                     <TableDataV2
                        title={___PHRASE("solicitudesDeGastos")}
                        data={requestExpense}
                        header={[
                           { name: 'name_all', label: ___PHRASE("empleado"), filter: false, Chip: false },
                           { name: 'n_document', label: ___PHRASE("nDocumento"), filter: false, Chip: false },
                           { name: 'detail', label: ___PHRASE("conceptoDeDetalle"), filter: false, Chip: false },
                           { name: 'period', label: ___PHRASE("periodo"), filter: false, Chip: true },
                        ]}
                        actionSelect={setActionSelected}
                        setModalSave={setShowAdminExpenseModal}
                        status_action
                        btn_helper
                        iconBtnHelper={<PriceChangeOutlinedIcon fontSize="small" ></PriceChangeOutlinedIcon>}
                        toolTipBtnHelper={___PHRASE("verDetalleDelGasto")}
                        RecuperarData={RecuperarData}
                        btn_disabled={true}
                        btn_delete_disable={true}
                        btn_edit_disable={true}
                     />  
                  </>
               )
            }    

            {
               view == 'details' && (
                  <TableDataV2
                        title={___PHRASE("detalles")}
                        data={details || []}
                        header={cabeceraDetail || []}
                        actionSelect={setActionSelected}
                        setModalSave={setShowDetailModal}
                        status_action
                        btn_disabled={true}
                        disabled_title={false}
                        btn_edit_disable={false}
                        btn_delete_disable={true}
                        currentView={view}
                        RecuperarData={RecuperarData}
                  />
               )
            }

         </Container>
         { 
            showAdminExpenseModal && (
               <ModalAdminExpense
                  open={showAdminExpenseModal}
                  closeModal={()=>{setShowAdminExpenseModal(false)}}
                  actionSelected={actionSelected}
                  onListExpense={getExpenseRequests}
                  expense={expenseSelected}
                  clearState={()=>{setExpenseSelected('')}}
               />
            )
         }
         { 
             showDetailModal && (
                  <ModalExpenseEmployeeDetail
                     open={showDetailModal}
                     closeModal={()=>{setShowDetailModal(false)}}
                     actionSelected={actionSelected}
                     onListDetails={getDetailsExpense}
                     detail={detailSelected}
                     expenseSelected={expenseSelected}
                     clearState={()=>{setDetailSelected('')}}
                  />
               )
            }
      </Protected>
   )
};