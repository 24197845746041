import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik } from 'formik';
import { useEffect, useState } from "react";
import { Alert, Autocomplete, Divider, Grid, MenuItem, Select, Snackbar, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { Loading } from "@/components/common/Loading";
import { branchOfficeService } from "@/service/services/BranchOffice.service";
import { WorkerService } from "@/service/services/Worker.service";
import { ProductService } from "@/service/services/Products.service";
import { Props } from "./ModalProductMovement";
import ___PHRASE from "@/service/translate/translateService";

export const ModalProductMovement: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, movement, onListMovements, purchaseSelected, clearState } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [branchOffices, setBranchOffices] = useState<any>([]);
    const [employees, setEmployees] = useState<any>([]);
    const [employeeSelected, setEmployeeSelected] = useState<any>('');
    const [actionType, setActionType] = useState<any>('');

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    })

    const [data, setData] = useState<any>({
        id: movement ? movement.id : '',
        observations: movement ? movement.observations : '',
        id_employee: movement ? movement.id_employee : '',
        id_branch_office: movement ? movement.id_branch_office : '',
        date: movement ? movement.date : '',
        agreed_return_date: movement ? movement.agreed_return_date : '',
    });

    useEffect(() => {
        getBranchOffices();
        getWorkers();
    }, []);

    useEffect(() => {
        if(movement?.id_employee && employees){
            setCustomEmployee(movement.id_employee, employees);
        }

    },[movement?.id_employee , employees]);

    const getBranchOffices = async () => {
        setLoading(true);
        const response = await branchOfficeService.getBranchOffices();
        setBranchOffices(response.data || []);
        setLoading(false);
    }

    const getWorkers = async() => {
        setLoading(true);
        const resp:any = await WorkerService.getWorkers();
        
        const oldData = resp.data || [];
        let newData = [];
        oldData.length > 0 && oldData.map((item) => {
            let obj = {
                id: item.id,
                name: item.name+' '+item.surname+' '+item.second_surname,
            }
            newData.push(obj);
        });
        setEmployees(newData || []);
        setLoading(false);
    }

    const validateForm = (values) => {
        let errors: any = {};
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        const formData = {
            id: values.id,
            id_products_purchase: purchaseSelected.id,
            description : values.description,
            id_employee : employeeSelected && employeeSelected.id,
            id_branch_office : values.id_branch_office,
            date : values.date,
            agreed_return_date : values.agreed_return_date,
        }

        setLoading(true);

        if(movement && actionSelected=='edit' && actionType=='1'){
            await ProductService.changeBranchOfficeMovement(formData).then((data) => {
                onListMovements && onListMovements(purchaseSelected.id);
                clearState && clearState();
                closeModal && closeModal();
            })
        }
        if(movement && actionSelected=='edit' && actionType=='2'){
            await ProductService.benefitMovemnt(formData).then((data) => {
                onListMovements && onListMovements(purchaseSelected.id);
                clearState && clearState();
                closeModal && closeModal();
            })
        }
        if(movement && actionSelected=='edit' && actionType=='3'){
            await ProductService.refundMovement(formData).then((data) => {
                onListMovements && onListMovements(purchaseSelected.id);
                clearState && clearState();
                closeModal && closeModal();
            })
        }

        setLoading(false);
    }

    function setCustomEmployee(idEmployee, workers): any {
        const data = workers && workers.find(worker => worker.id === idEmployee);
        const newData = {
            id: data?.id,
            name: data?.name
        }
        return setEmployeeSelected(newData);
    }

    return(
        <>
        {loading && <Loading/>}

        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>

        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={___PHRASE("queTipoDeMovimientoRealizaraHoy")}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >

                                <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                  <Select
                                      id=""
                                      name=''
                                      variant="standard"
                                      value={actionType}
                                      onChange={(event) => { 
                                          setActionType(event.target.value);
                                      }}
                                      fullWidth
                                  >
                                    <MenuItem key={0} value={1}>{___PHRASE("cambiarSucursal")}</MenuItem>;
                                    <MenuItem key={1} value={2}>{___PHRASE("beneficio")}</MenuItem>;
                                    <MenuItem key={2} value={3}>{___PHRASE("reembolso")}</MenuItem>;
                                  </Select>
                                </Grid> 

                                {
                                    actionType != "" && (
                                        <>
                                        <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                            <Grid item xs={4}>
                                            <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("observacion")}:</b></Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                            <TextField
                                                id="observations"
                                                variant="standard"
                                                type="text" 
                                                name="observations"
                                                multiline
                                                maxRows={4}
                                                fullWidth
                                                value={values.observations}
                                                onChange={handleChange}
                                                placeholder={___PHRASE("observacion")}
                                                error={errors.observations && touched.observations ? true : false}
                                            />
                                            </Grid>
                                        </Grid>

                                        <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                            <Grid item xs={4}>
                                                <Typography variant="body1"><b>{___PHRASE("empleado")}:</b> </Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Autocomplete
                                                    id="disable-portal"
                                                    //disablePortal
                                                    value={employeeSelected}
                                                    options={employees || []}
                                                    getOptionLabel={(option) => option.name || ""}
                                                    fullWidth
                                                    renderInput={(params) => <TextField {...params} variant="standard" label={___PHRASE("escribeParaBuscar")} />}
                                                    onChange={(e, newValue) => { setEmployeeSelected(newValue) }}
                                                />
                                            </Grid>
                                        </Grid>

                                        {
                                            actionType == '1' && (
                                                <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                                    <Grid item xs={4}>
                                                        <Typography variant="body1"><b>{___PHRASE("sucursal")}:</b> </Typography>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <Select
                                                            id="id_branch_office"
                                                            variant="standard"
                                                            value={values.id_branch_office}
                                                            onChange={(event) => { 
                                                            setFieldValue("id_branch_office", event.target.value);
                                                            }}
                                                            error={ errors.id_branch_office && touched.id_branch_office ? true : false }
                                                            fullWidth
                                                        >
                                                            {
                                                            branchOffices.map((branch, i) => {
                                                                return <MenuItem key={i} value={branch.id}>{branch.address}</MenuItem>;
                                                            })
                                                            }
                                                        </Select>
                                                    </Grid>
                                                </Grid>
                                            )
                                        }

                                        {
                                            actionType != '3' && (
                                                <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                    <Grid item xs={4}>
                                                    <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("fecha")}:</b></Typography>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <TextField
                                                            id="date"
                                                            variant="standard"
                                                            fullWidth
                                                            name="date"
                                                            type="date"
                                                            onChange={handleChange}
                                                            value={values.date}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )
                                        }

                                        {
                                            actionType == '1' && (
                                                <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                    <Grid item xs={4}>
                                                    <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("fechaDeRegresoAcordado")}:</b></Typography>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <TextField
                                                            id="agreed_return_date"
                                                            variant="standard"
                                                            fullWidth
                                                            name="agreed_return_date"
                                                            type="date"
                                                            onChange={handleChange}
                                                            value={values.agreed_return_date}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )
                                        }
                                        
                                        </>
                                    )
                                }
                                    
                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(movement && actionSelected=='edit') ? ___PHRASE("actualizar") : ___PHRASE("registrar")}
                                    cancelText={___PHRASE("cancelar")} 
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}