import { Formik } from 'formik';
import { useState } from "react";
import { Button, Card, Grid, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Loading } from "@/components/common/Loading";
import { companiesService } from '@/service/services/Companies.service';
import { Props } from './CloseDay.type';
import ___PHRASE from '@/service/translate/translateService';

export const CloseDay: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, dataCompany, onListDataCompany, mutuality, clearState } = props;

    const [loading, setLoading] = useState<boolean>(false);
    

    const [data, setData] = useState({
        id: dataCompany ? dataCompany.idcompany : '',
        close_day: dataCompany ? dataCompany.close_day : '',
    });

    const validateForm = (values) => {
        let errors: any = {};
        if(!values.close_day) errors.close_day = "cierre requerido";
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        const formData = {
            close_day : values.close_day,
        }
        setLoading(true);
        await companiesService.updateCloseDay(formData).then((data) => {
            onListDataCompany && onListDataCompany();
            closeModal && closeModal();
        })
        setLoading(false);
    }
    return(
        <>
        {loading && <Loading/>}

        
            <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                    return(
                        <form onSubmit={handleSubmit}>
                            <Card sx={{ width: "100%", marginTop: '20px' }}>
                                <TableContainer>
                                    <Table>
                                        <TableHead sx={{ bgcolor: '#EDEFF1' }}>
                                            <TableRow >
                                                <TableCell >
                                                    <Typography>{___PHRASE("diaDeCierre")}</Typography>
                                                </TableCell>
                                                <TableCell >
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>

                                            <TableRow >
                                                <TableCell size={'small'} sx={{ bgcolor: '#F4F6F8', width: '30%' }}>
                                                    {___PHRASE("diaDeCierre")}
                                                </TableCell>
                                                <TableCell >
                                                    <Select
                                                        id="close_day"
                                                        name='close_day'
                                                        variant="standard"
                                                        value={values.close_day}
                                                        onChange={(event) => { 
                                                        setFieldValue("close_day", event.target.value);
                                                        }}
                                                        sx={{ width: '400px' }}
                                                        error={errors.close_day && touched.close_day ? true : false}
                                                    >
                                                    <MenuItem key={0} value={25}>25 {___PHRASE("deCadaMes")}</MenuItem>;
                                                    <MenuItem key={1} value={26}>26 {___PHRASE("deCadaMes")}</MenuItem>;
                                                    <MenuItem key={2} value={27}>27 {___PHRASE("deCadaMes")}</MenuItem>;
                                                    <MenuItem key={2} value={28}>28 {___PHRASE("deCadaMes")}</MenuItem>;
                                                    <MenuItem key={2} value={29}>29 {___PHRASE("deCadaMes")}</MenuItem>;
                                                    <MenuItem key={2} value={30}>30 {___PHRASE("deCadaMes")}</MenuItem>;
                                                        
                                                    </Select>
                                                    {/* <TextField
                                                        id="close_day"
                                                        variant="standard"
                                                        sx={{ width: '400px' }}
                                                        name="close_day"
                                                        type="number"
                                                        onChange={handleChange}
                                                        value={values.close_day}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        error={errors.close_day && touched.close_day ? true : false}
                                                    /> */}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Grid sx={{ textAlign: 'center', margin: '10px' }}>
                                    <Button color="primary" variant="contained" onClick={() => {handleSubmit()}}>
                                        {___PHRASE("actualizar")}
                                    </Button>
                                </Grid>
                            </Card>
                        </form>
                    )
                }}
            </Formik>
        </>
    );
}