import { projectRepository } from "../repositories/Project.repository";

export const ProjectService = {
    getProjects,
    createProject,
    editProject,
    deleteProject,
    getDetailProject,
    addEmployee,
    editEmployee,
    deleteEmployee,
    getDetailParticipation,
    createDetailParticipation,
    verifyParticipation,
    verifyTotal,
    seePercentageOrTotal,
}

async function getProjects(search = ''){
    const res = await projectRepository.getProjects(search);
    return res;
}

async function createProject(data:{}){
    const res = await projectRepository.createProject(data);
    return res;
}

async function editProject(data:{}, idProject: number){
    const res = await projectRepository.editProject(data, idProject);
    return res;
}

async function deleteProject(idProject:number){
    const res = await projectRepository.deleteProject(idProject);
    return res;
}

//crud de empleados por proyectos

async function getDetailProject(idProject:number){
    const res = await projectRepository.getDetailProject(idProject);
    return res;
}

async function addEmployee(data:{}){
    const res = await projectRepository.addEmployee(data);
    return res;
}

async function editEmployee(data:{}, idProject:number){
    const res = await projectRepository.editEmployee(data, idProject);
    return res;
}

async function deleteEmployee(idDetailProject){
    const res = await projectRepository.deleteEmployee(idDetailProject);
    return res;
}

async function getDetailParticipation(idDetail:number){
    const res = await projectRepository.getDetailParticipation(idDetail);
    return res;
}

async function createDetailParticipation(data){
    const res = await projectRepository.createDetailParticipation(data);
    return res;
}

async function verifyTotal(idemployee, idperiod, total){
    const res = await projectRepository.verifyTotal(idemployee, idperiod, total);
    return res;
}

async function verifyParticipation(idemployee, idperiod, participation_percentage){
    const res = await projectRepository.verifyParticipation(idemployee, idperiod, participation_percentage);
    return res;
}

async function seePercentageOrTotal(data:any){
    const res = await projectRepository.seePercentageOrTotal(data);
    return res;
}