import { Protected } from "@/components/layout/Protected"
import { Backdrop, Button, Grid, Typography } from "@mui/material"
import { SpinnerGrow } from '@/components/common/Spinner';
import { useEffect, useState } from "react";
import { Alert, Breadcrumbs, CircularProgress, Container, Divider, IconButton, Menu, MenuItem, Snackbar } from "@mui/material";
import { emphasize, styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import GroupsIcon from '@mui/icons-material/Groups';
import { TableDataV2 } from "@/components/common/Tablev2";
import { WorkerService } from "@/service/services/Worker.service";
import { Loading } from "@components/common/Loading";
import { LoanService } from "@/service/services/Loan.service";
import { ModalMaxLoan } from "@/components/Modal/ModalMaxLoan";
import { ModalFeeLoan } from "@/components/Modal/ModalFeeLoan";
import ___PHRASE from "@/service/translate/translateService";
import { ModalFeeLoanDetail } from "@/components/Modal/ModalFeeLoanDetail";

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
 });

export const LoanManteiner = () => {
    const VIEW_LIST_EMPLOYEE = 'Lista de Empleados'
    const VIEW_LOAN = "Prestamos"
    const [loading, setLoading] = useState<boolean>(false);
    const [openView, setOpenView] = useState(false);
    const [view, setView] = useState(VIEW_LIST_EMPLOYEE);
    const [workers, setWorkers] = useState<any>([]);
    const [employeeSelected, setEmployeeSelected] = useState<any>('');
    const [actionSelected, setActionSelected] = useState<any>('');
    const [loanSelected, setLoanSelected] = useState<any>();
    const [dataLoans, setDataLoans] = useState<any>([]);
    const [validateMaxLoan, setValidateMaxLoan] = useState<any>(false)
    const [modalMaxLoan,setModalMaxLoan] = useState(false);
    const [modalFeeLoan, setModalFeeLoan] = useState(false);
    const [modalRequestLoan, setModalRequestLoan] = useState(false);
    const [modalFeeLoanDetail, setModalFeeLoanDetail] = useState(false);
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
      });

  const cabecera = [
    { name: 'n_document', label: ___PHRASE("rutTrabajador") },
    { name: 'surname', label: ___PHRASE("apellidoPaterno") },
    { name: 'second_surname', label: ___PHRASE("apellidoMaterno") },
    { name: 'name', label: ___PHRASE("nombres") }];

  const headerLoans = [
    { name: 'year_initial', label: ___PHRASE("periodo") },
    { name: 'month_initial', label: ___PHRASE("mes") },
    { name: 'loan', label: ___PHRASE("monto") },
    { name: 'status_name', label: ___PHRASE("estado") },
  ]

    const getWorkers = async() => {
        setLoading(true);
        const resp:any = await WorkerService.getWorkers();
        setWorkers(resp.data || []);
        setLoading(false);
    }

    const RecuperarData = (value) => {
        setLoading(true)
        !employeeSelected && setEmployeeSelected(value);
        employeeSelected && setLoanSelected(value);
        setView(VIEW_LOAN);
        setLoading(false)
    }


    useEffect(()=> {
        getWorkers()
    },[]);

    const getLoans = async(status, idEmployee) => {
        setLoading(true)
        const resp = await LoanService.getLoan(status, idEmployee);
        setDataLoans(resp.data)
        const validate = await LoanService.validatorMaxLoan(idEmployee);
        setValidateMaxLoan(validate.data)
        setLoading(false)
    }

    useEffect(()=>{
        if(employeeSelected){
            getLoans(0, employeeSelected.id)
        }
    },[employeeSelected])

    const searchData = async (value) => {
        const resp = await WorkerService.getWorkers(value);
        setWorkers(resp.data || []);
     }
    
    const validateActive = () => {
       if( dataLoans.find((item)=> item.status_loan == "1")){
        //  setSnackBarConfig({...snackBarConfig, open:true, severity:'info', message:'Este empleado ya tiene un préstamo activo.'})
        return false
       }else{
        return true
       }
    }
    
    return (
        <Protected>
             {loading && <Loading/>}
            <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openView}
         >
            <Grid container
               direction="row"
               justifyContent="center"
               alignItems="center">
               <SpinnerGrow />
               <h1 style={{ marginLeft: '10px' }}><strong>{___PHRASE("cargando")}</strong></h1>
            </Grid>
         </Backdrop>
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
                <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                    {snackBarConfig.message || ""}
                </Alert>
            </Snackbar>
         <Breadcrumbs aria-label="breadcrumb" sx={{my:3, ml:5}}>
                <StyledBreadcrumb
                    label={___PHRASE("listaDeEmpleados")}
                    onClick={() => {
                        setView(VIEW_LIST_EMPLOYEE);
                        setEmployeeSelected(null);
                    }}
                    icon={<GroupsIcon fontSize="small" />}
                />
                {
                   view == VIEW_LOAN && (
                        <StyledBreadcrumb
                            label={___PHRASE("prestamos")}
                            onClick={() => {setView(VIEW_LOAN)}}
                            icon={<GroupsIcon fontSize="small" />}
                        />
                    )
                }
        </Breadcrumbs>
        {
               view == VIEW_LIST_EMPLOYEE && (
                    <TableDataV2
                        title={___PHRASE("prestamosPorEmpleado")}
                        data={workers}
                        header={cabecera}
                        actionSelect={setActionSelected}
                        status_action
                        disabled_title={false}
                        btn_disabled
                        btn_edit_disable={true}
                        btn_delete_disable={true}
                        btn_helper
                        currentView={view}
                        textSearch={___PHRASE("buscarTrabajador")}
                        searchData={searchData}
                        RecuperarData={RecuperarData}
                        />
                )
            }
            {
                view == VIEW_LOAN && (
                    <>
                        <Typography variant='h6' component='h1' gutterBottom className="tittle" sx={{ mt: 1 , fontWeight:600, ml:4}}>{___PHRASE("prestamosDe")+` ${employeeSelected?.name}`}</Typography>
                    
                    {
                        validateMaxLoan  &&
                        <>
                        <Typography sx={{fontWeight: 'bold', ml:5}}>{___PHRASE("tieneUnLimiteDePrestamoDe")+` $ ${validateMaxLoan.limit_max}`}</Typography>
                        </>
                    }
                    <TableDataV2
                        disabled_title
                        data={dataLoans}
                        header={headerLoans}
                        currentView={view}
                        RecuperarData={RecuperarData}
                        actionSelect={setActionSelected}
                        btn_disabled={true}
                        btn_validateMaxLoan={!validateMaxLoan}
                        setModalMaxLoan={setModalMaxLoan}
                        btn_feeLoan={true}
                        setModalFeeLoan={setModalFeeLoan}
                        status_action
                        btn_detail={false}
                        btn_edit_disable
                        btn_delete_disable
                        btn_feeLoanDetail
                        setModalFeeLoanDetail={setModalFeeLoanDetail}
                    />
                    </>
                )
            }
            {
                modalMaxLoan &&
                 <ModalMaxLoan
                    open={modalMaxLoan}
                    closeModal = {()=> setModalMaxLoan(false)}
                    onList ={getLoans}
                    workerSelected={employeeSelected}
                 />
            }
            {
                modalFeeLoan && validateActive() && 
                <ModalFeeLoan
                    open={modalFeeLoan}
                    closeModal = {()=> setModalFeeLoan(false)}
                    onList ={getLoans}
                    workerSelected={employeeSelected}
                    loanSelected = {loanSelected}
                    setSnackbar={setSnackBarConfig}
                    snackbar={snackBarConfig}
                />
            }
            {
                modalFeeLoanDetail && 
                <ModalFeeLoanDetail
                    open={modalFeeLoanDetail}
                    closeModal = {()=> setModalFeeLoanDetail(false)}
                    onList ={getLoans}
                    workerSelected={employeeSelected}
                    loanSelected = {loanSelected}
                    setSnackbar={setSnackBarConfig}
                    snackbar={snackBarConfig}
                />
            }
        </Protected>
    )
}