import { useEffect, useState } from "react"
import { Protected } from "@/components/layout/Protected"
import { Alert, Box, Button, ButtonGroup, Card, Container, IconButton, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material"
import { Loading } from "@/components/common/Loading"
import { Props } from "./WorkerValidation.type"
import { WorkerValidationService } from "@/service/services/WorkerValidation.service"
import { ModalWorkerValidation } from "@/components/Modal/ModalWorkerValidation"
import EditIcon from '@mui/icons-material/Edit';
import { API_URL_BASE } from "@/toolbox/defaults/app"
import { KEY_USER_DATA } from "@/toolbox/constants/local-storage"
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper"
import { ModalUpdatePassword } from "@/components/Modal/ModalUpdatePassword/ModalUpdatePassword"
import { ModalValidationAdmin } from "@/components/Modal/ModalValidationAdmin"
import ___PHRASE from "@/service/translate/translateService"

export const WorkerValidationView: React.FC<Props> = (props:any): JSX.Element => {

    const [loading, setLoading] = useState<boolean>(false);
    const [showUpdatePassModal, setShowUpdatePassModal] = useState<boolean>(false);
    const [validations, setValidations] = useState([]);
    const [showValidationModal, setShowValidationModal] = useState<boolean>(false);
    const [validationSelected, setValidationSelected] = useState<any>('');
    const [actionSelected, setActionSelected] = useState<any>('');
    
    const [showModalValidationAdmin, setShowModalValidationAdmin] = useState<boolean>(false);
    const [validations2, setValidations2] = useState([]);
    const [typeFiles, setTypeFiles] = useState<any>('');


    const dataUser = readLocalStorage(KEY_USER_DATA);
    const statusConfirm = dataUser?.user?.status_confirm;
    const validation_confirm = dataUser?.user?.validation_confirm;

    const idemployee = dataUser?.user?.idemployee;

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    useEffect(() => {
        if(validation_confirm=='0'){
            getValidationsAdmin();
        }
    }, [validation_confirm=='0']);

    useEffect(() => {
        if(validation_confirm=='0' && validations2.length > 0){
            setShowModalValidationAdmin(true);
        }
    }, [validation_confirm=='0' && validations2.length > 0]);

    useEffect(() => {
        if(statusConfirm=='0'){
            setShowUpdatePassModal(true);
        }
    }, [statusConfirm=='0']);

    useEffect(() => {
        getWorkerValidations();
    }, []);

    const getValidationsAdmin = async() => {
        setLoading(true);
        await WorkerValidationService.listValidationIdentityAdmin(idemployee).then((data) => {
            console.log(data)
            const oldData = data.data || [];
            let newData = [];
            let countPDF = 0;
            let countImages = 0;
            oldData.length > 0 && oldData.map((item) => {
                if(item.typeDocument == 'pdf'){countPDF = countPDF+1}
                if(item.typeDocument == 'Imagen'){countImages = countImages+1}
                data = {
                    id: item.id,
                    nameDocument: item.nameDocument,
                    typeDocument: item.typeDocument,
                    doc: `${API_URL_BASE}/${item.doc}`,
                    idemployee: item.idemployee,
                    idcompany: item.idcompany,
                    reviewStatus: item.reviewStatus,
                    status: item.status,
                }
                newData.push(data);
            });

            let typeFiles = {
                pdf: countPDF,
                image: countImages,
            }
            setTypeFiles(typeFiles)
            setValidations2(newData|| []);
            setLoading(false);
        })
    }

    const getWorkerValidations = async() => {
        setLoading(true);
        await WorkerValidationService.getWorkerValidations().then((data) => {
            const oldData = data.data || [];
            let newData = [];
            oldData.length > 0 && oldData.map((item) => {
                data = {
                    id: item.id,
                    iduser: item.iduser,
                    face_img: `${API_URL_BASE}/${item.face_img}`,
                    doc_img: `${API_URL_BASE}/${item.doc_img}`,
                    face_matching: (item.face_matching == '1' ? 'APROBADO' : (item.face_matching == '2' ? 'RECHAZADO' : 'PENDIENTE')),
                    observations: item.observations,
                    status: item.status,
                }
                newData.push(data);
            });

            setValidations(newData|| []);
            setLoading(false);
        })
    }

    const showDocument = (image) => {
        setLoading(true);

        if(image){
            window.open(image, '_blank');
        }

        setLoading(false);
    }

    return (
        <Protected>
            {loading && <Loading/>}
            <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
                <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                    {snackBarConfig.message || ""}
                </Alert>
            </Snackbar>
            <Container>
                <Box
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                    m: -1,
                    py: 3
                }}
                >
                    <Typography variant='h6' component='h1' gutterBottom className="tittle"
                    sx={{ m: 1 , fontWeight:600}}
                    >
                        {___PHRASE("misvalidaciones")}
                    </Typography>

                    <Button
                     color="primary"
                     variant="contained"
                     onClick={()=>{
                        if(validations.find((item)=>item.face_matching === "APROBADO")){
                            setSnackBarConfig(prev => ({ ...prev, open: true, 
                                severity:'info', 
                                message: ___PHRASE("encontramosUnRegistroAprobadoExistenteNoEsNecesarioVolverAEnviarTuValidacionDeIdentidad") }
                            ))
                        }else if(validations.find((item)=>item.face_matching === "PENDIENTE")){
                            setSnackBarConfig(prev => ({ ...prev, open: true, 
                                severity:'info', 
                                message: ___PHRASE("yaCuentaConUnValidacionEnEstadoPendienteConsidereEditarla") }
                            ))
                        }
                        else{
                            setShowValidationModal(true);
                            setActionSelected('save');
                        }
                     }}
                  >
                   {___PHRASE("agregar")}
                  </Button>
                    
                </Box>
            <Card sx={{width:"100%"}}>
                <TableContainer>
                    <Table>
                        <TableHead sx={{ bgcolor: '#F4F6F8' }}>
                            <TableRow >
                                <TableCell >
                                    <Typography>{___PHRASE("imagenRostro")}</Typography>
                                </TableCell>
                                <TableCell >
                                    <Typography>{___PHRASE("imagenDocumento")}</Typography>
                                </TableCell>
                                <TableCell >
                                    <Typography>{___PHRASE("coincidenciasDeRostro")}</Typography>
                                </TableCell>
                                <TableCell >
                                    <Typography>{___PHRASE("observaciones")}</Typography>
                                </TableCell>
                                    {
                                        !validations.find((item) => item.face_matching === "APROBADO") &&
                                        <TableCell >
                                            <Typography>{___PHRASE("acciones")}</Typography>
                                        </TableCell>
                                    }
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {
                                validations.length > 0 && validations.map((item) => (
                                    <TableRow sx={{maxHeight:'50px'}}>
                                        <TableCell size={'small'} sx={{maxHeight:'50px'}}>
                                            <img src={item.face_img} style={{maxHeight:'100px' ,maxWidth:'100px', cursor: 'pointer'}} onClick={()=>{showDocument(item.face_img)}}/>
                                        </TableCell>
                                        <TableCell size={'small'}>
                                        <img src={item.doc_img} style={{maxHeight:'100px', maxWidth:'100px', cursor: 'pointer'}} onClick={()=>{showDocument(item.doc_img)}}/>
                                        </TableCell>
                                        <TableCell size={'small'}>
                                            {item.face_matching }
                                        </TableCell>
                                        <TableCell size={'small'}>
                                            {item.observations || '---'}
                                        </TableCell>
                                        <TableCell size={'small'} align="center" >
                                            {
                                                item.face_matching === "PENDIENTE" &&
                                                <ButtonGroup variant="contained" color="inherit" aria-label="button-group-custom-table" style={{ padding: "0px 5px" }}>
                                                    <Tooltip title={___PHRASE("editar")}>
                                                        <IconButton size="small" color="success" aria-label="view"
                                                            onClick={() => {
                                                                setValidationSelected(item);
                                                                setShowValidationModal(true);
                                                                setActionSelected('edit');
                                                            }}
                                                        >
                                                            <EditIcon fontSize='small' />
                                                        </IconButton>
                                                    </Tooltip>
                                                </ButtonGroup>
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))
                            }

                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
            </Container>


            {
                showUpdatePassModal && (
                    <ModalUpdatePassword
                        closeModal={() => {setShowUpdatePassModal(false)}}
                    />
                )
            }

            { 
                showModalValidationAdmin && (
                    <ModalValidationAdmin
                        // open={showModalValidationAdmin}
                        closeModal={()=>{setShowModalValidationAdmin(false)}}
                        validations={validations2}
                        typeFiles={typeFiles}
                    />
                )
            }

            { 
                showValidationModal && (
                    <ModalWorkerValidation
                        open={showValidationModal}
                        closeModal={()=>{setShowValidationModal(false)}}
                        actionSelected={actionSelected}
                        onListValidations={getWorkerValidations}
                        validation={validationSelected}
                        clearState={()=>{setValidationSelected('')}}
                    />
                )
            }

        </Protected>
    )
}