import { CountryService } from "@/service/services/Country.service";
import { Typography } from "@mui/material";
import { useState } from "react";
import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalFooter } from "../ModalFooter";
import { ModalHeader } from "../ModalHeader";
import { Props } from "./ModalCountryDistrictDelete.type";
import ___PHRASE from "@/service/translate/translateService";

export const ModalCountryDistrictDelete: React.FC<Props> = (props:any): JSX.Element => {
    const { open, closeModal, district, onListDistrict, clearState, provinceSelected } = props;
    const [loading, setLoading] = useState<boolean>(false);

    const onDeleted= async()=>{
        setLoading(true);
        await CountryService.deleteDistrict(district.id).then((data) => {
            onListDistrict && onListDistrict(provinceSelected.id);
            closeModal && closeModal();
        })
        setLoading(false);
    }
  
  return (
    <>
    <Modal
      open={open}
      handleClose={closeModal}
      size="xs"
    >
      <ModalHeader
        text={___PHRASE("eliminar")+' '+___PHRASE("comuna")}
        className='positionElements'
        onCancel={closeModal}
        clearState={clearState}
      >
      </ModalHeader>

      <ModalBody>
        <Typography variant="subtitle1" className="textColor"> 
          {___PHRASE("estaSeguroDeEliminarEstaComuna")}
        </Typography>
      </ModalBody>

      <ModalFooter 
        confirmText={___PHRASE("eliminar")}
        cancelText={___PHRASE("cancelar")}
        onCancel={closeModal}
        className="footerRight"
        onConfirm={onDeleted}
        clearState={clearState}
      />
    </Modal>
    </>
  )
}