import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik } from 'formik';
import { useEffect, useState } from "react";
import { Alert, Divider, Grid, MenuItem, Select, Snackbar, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import { Loading } from "@/components/common/Loading";
import { Props } from "./ModalAdminExpense.type";
import { ExpenseAdminService } from "@/service/services/ExpenseAdmin.service";
import '../../../assets/styles/modals.scss';
import ___PHRASE from "@/service/translate/translateService";

export const ModalAdminExpense: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, expense, onListExpense, clearState } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const [data, setData] = useState<any>({
        id: '',
        idemployee: '',
        name_all:'',
        n_document:'',
        idcompany: '',
        detail: '',
        date: '',
        review_status: '',
        iduserreviewed: '',
        status: '',
    });

    // useEffect(() => {
    //     getRoles();
    // }, []);

    useEffect(() => {
        if(open){
            if(expense){
                setData({
                    id: expense ? expense.id : '',
                    idemployee: expense ? expense.idemployee : '',
                    name_all: expense ? expense.name_all : '',
                    n_document: expense ? expense.n_document : '',
                    idcompany: expense ? expense.idcompany : '',
                    detail: expense ? expense.detail : '',
                    date: expense ? expense.date : '',
                    review_status: expense ? expense.review_status : '',
                    iduserreviewed: expense ? expense.iduserreviewed : '',
                    status: expense ? expense.status : '',
                });
            }
        }
        
    }, [open]);

    const onSubmit = async(values, {resetForm})  => {
        const idExpense= data.id;

        const formData = {
            review_status : values.review_status,           
        }
        setLoading(true);
        if(expense && actionSelected=='edit'){
            console.log('edit');
            await ExpenseAdminService.editExpenseAdmin(formData, idExpense).then((data) => {
                onListExpense && onListExpense();
                closeModal && closeModal();
            })
        }
        setLoading(false);
    }

    const getCustomRoleName = (oldRolName, roles) => {
        const customRol = roles.find(rol => rol.name == oldRolName);
        return  customRol ? customRol.id : ''
    }

    return(
        <>
        {loading && <Loading/>}
        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>

        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={(expense && actionSelected=='edit') ? ___PHRASE("editarestadodereporte") : ___PHRASE("agregarreporte")}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize  onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("empleado")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="email"
                                            variant="standard"
                                            type="email" 
                                            name="name_all"
                                            fullWidth
                                            value={values.name_all}
                                            // onChange={handleChange}
                                            placeholder={___PHRASE("empleado")}
                                            disabled
                                            // error={errors.email && touched.email ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("documento")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="n_document"
                                            variant="standard"
                                            type="text" 
                                            name="n_document"
                                            multiline
                                            fullWidth
                                            value={values.n_document}
                                            // onChange={handleChange}
                                            placeholder={`${___PHRASE("documento")} ${___PHRASE("usuario")}`}
                                            disabled
                                            // error={errors.user_name && touched.user_name ? true : false}
                                        />
                                        </Grid>
                                    </Grid>           
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("detalle")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="detail"
                                            variant="standard"
                                            type="text" 
                                            name="detail"
                                            fullWidth
                                            value={values.detail}
                                            // onChange={handleChange}
                                            placeholder={___PHRASE("detalle")}
                                            disabled
                                            // error={errors.email && touched.email ? true : false}
                                        />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("fechaInicio")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                id="date"
                                                variant="standard"
                                                fullWidth
                                                name="date"
                                                type="date"
                                                // onChange={handleChange}
                                                value={values.date}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                disabled
                                                // error={errors.date_registration && touched.date_registration ? true : false}
                                            />
                                        </Grid>
                                    </Grid>                      

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>{___PHRASE("estadoreporte")}:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Select
                                                id="review_status"
                                                name='review_status'
                                                variant="standard"
                                                value={values.review_status}
                                                onChange={(event) => { 
                                                setFieldValue("review_status", event.target.value);
                                                }}
                                                // error={ errors.role && touched.role ? true : false }
                                                fullWidth
                                            >
                                               <MenuItem key={0} value={0}>{___PHRASE("norevisado")}</MenuItem>;
                                               <MenuItem key={1} value={1}>{___PHRASE("revisado")}</MenuItem>;
                                               <MenuItem key={2} value={2}>{___PHRASE("rechazado")}</MenuItem>;
                                                
                                            </Select>
                                        </Grid>
                                    </Grid>                                
                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(expense && actionSelected=='edit') ? ___PHRASE("actualizar") : ___PHRASE("registrar")}
                                    cancelText={___PHRASE("cancelar")} 
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}