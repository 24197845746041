import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

const dataUser = readLocalStorage(KEY_USER_DATA);

export const DigitalSignatureRepository = {

    create: async (dataSignature) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/xml/digitalSignature`, {
            idemployee: dataSignature.idemployee,
        });
        return res;
    },
}   