import React from 'react';

import Box from '@mui/material/Box';
import { Tabs, Tab, Grid, Typography } from "@mui/material";
import { HaberEmployee } from './HaberEmployee';
import { DiscountEmployee } from './DiscountEmployee';
import { DetailEmployee } from './DetailEmployee';
import ___PHRASE from '@/service/translate/translateService';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const DetailLiquidation = (props: any): JSX.Element => {

    const [value, setValue] = React.useState(0);
    const [data, setData] = React.useState(props.data);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (

        <Box sx={{ width: '100%', marginTop: '10px'}} >
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label={___PHRASE("datosdelempleado")} {...a11yProps(0)} />
                    <Tab label={___PHRASE("haberes")} {...a11yProps(1)} />
                    <Tab label={___PHRASE("descuentos")} {...a11yProps(2)} />
                </Tabs>
            </Box>

            <TabPanel value={value} index={0}>
                <DetailEmployee data={data} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <HaberEmployee data={data} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <DiscountEmployee data={data} />
            </TabPanel>
        </Box>

    )
}