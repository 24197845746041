import { useMemo, useEffect, useRef, useState } from "react";
import { Button, InputAdornment, IconButton, Grid, CircularProgress, Snackbar, Alert, Stack, TextField, Divider } from '@mui/material';
import { VALIDATORS } from '@toolbox/helpers/validation-rules';
import { Icon } from '@components/common/Icon';
import { Input } from '@components/common/Input';
import { InputRef } from '@components/common/Input/InputInterfaces';
import { Loading } from "@components/common/Loading";
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import logoLogin from "@assets/img/logo-login.jpg";
import { VisibilityIcon, VisibilityOffIcon } from '@toolbox/constants/icons';
import * as qs from 'qs'
import { ROUTE_COMPANIES, ROUTE_HOME, ROUTE_MY_VALIDATIONS } from "@/toolbox/constants/route-map";
import { authenticationService } from '@service/services/Authentication.service';
import { useHistory, Link } from 'react-router-dom'
import { Props } from './login.type'
import "./Login.sass";
import { KEY_RUTA, KEY_SUB_RUTA, KEY_USER_DATA } from "@/toolbox/constants/local-storage";
import { readLocalStorage, saveLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { ROLE_ADMIN, ROLE_SUPER_ADMIN, ROLE_TRABAJADOR } from "@/toolbox/defaults/static-roles";
import Echo from 'laravel-echo'
import toast, { Toaster } from 'react-hot-toast';
import * as Routes from '@constants/route-map';
import ___PHRASE from "@/service/translate/translateService";
import { userService } from "@/service/services/User.service";
import { apiResponseTranslate } from "@/toolbox/helpers/responseApi.helper";
import logo from '../../assets/img/remy_login.png';
import './Login.css';
import { Formik } from "formik";
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const theme = createTheme();
const themeAvenir = createTheme({
   typography: {
     fontFamily: 'Source Sans Pro !important',
     fontSize: 15
   },
})

export const LoginView: React.FC<Props> = (props: any): JSX.Element => {

   window['Pusher'] = require('pusher-js');
   const history = useHistory();
   const inputRefs = useRef<Array<InputRef | null>>([]);
   const [loadData, setLoadData] = useState<boolean>(false);
   const [open, setOpen] = useState<boolean>(false)
   const [tokenNotify, setTokenNotify] = useState<any>();
   const [loading, setLoading] = useState(false);
   const [data, setData] = useState({
      user_nick: '',
      rut_empresa: '',
      password: '',
      textError: '',
      showPassword: false
   });
   const [showPassword, setShowPassword] = useState<boolean>(false)
   // const msg = firebase.messaging();
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
   })
   const [verify, setVerify] = useState(false);

   const ruta = props.location.search + '.';
   const { rut_empresa, usuario, password, vista, periodo } = props.location && qs.parse(ruta.slice(1, -1));
   useEffect(() => {
      if (rut_empresa && usuario && password) {
         var decodeRutEmpresa;
         var decodeUser;
         var decodePassword;
         var decodeView;
         var decodePeriod;

         try {
            decodeRutEmpresa = atob(rut_empresa);
            decodeUser = atob(usuario);
            decodePassword = atob(password);
            if(!!vista){
               decodeView = atob(vista)
            }
            if(!!periodo){
               const jsonString  = atob(periodo)
               decodePeriod = JSON.parse(jsonString)
            }
            authSubmit(decodeUser, decodeRutEmpresa, decodePassword, 'erp', decodeView, decodePeriod ? decodePeriod : null);
         } catch (error) {
         }
      } 
   }, []);

   const rules = useMemo(() => ({
      user_nick: [
         VALIDATORS.REQUIRED,
         VALIDATORS.ONLY_LOWERCASE,
      ],
      password: [
         VALIDATORS.REQUIRED,
         VALIDATORS.PASSWORD.SIZE,
         VALIDATORS.PASSWORD.ALLOWED_CHARACTERS,
         VALIDATORS.PASSWORD.VALID,
      ],
      rut_empresa: [
         VALIDATORS.REQUIRED,
         // VALIDATORS.ONLY_LOWERCASE,
      ]
   }), []);

   const validate = () => {
      const refs = inputRefs.current;
      const valid = refs.reduce((prev, ref) => {
         const isValid = ref && ref.validate(true) || false
         return prev && isValid;
      }, true);

      return valid;
   }

   const validateForm = (values) => {
      let errors:any = {};
      let regRut = /^[0-9]{8}[-|‐]{1}[0-9kK]{1}$/;
      if(!values.rut_empresa) errors.rut_empresa = "rut requerido";
      if(values.rut_empresa && !(regRut.test(values.rut_empresa))) errors.rut_empresa = "rut no válido";
      if(!values.password) errors.password = "contraseña requerida";
      if(!values.user_nick) errors.user_nick = "usuario requerido";
      return errors;
    }

    const onSubmit = async (values) => {
      const { user_nick, rut_empresa, password } = values;
      authSubmit(user_nick, rut_empresa, password, '');
    }

   const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
      const name = event.target.name;
      const value = event.target.value;

      switch (name) {
         case 'user_nick':
            // if ((/^[\w@.-]{0,86}$/).test(value)) {

            // }
            setVerify(false)
            setData(prev => ({ ...prev, user_nick: value, textError: '' }));
            break;
         case 'password':
            if (/^.{0,15}$/.test(value)) {
               setData(prev => ({ ...prev, password: value, textError: '' }));
            }
            break;
         case 'rut_empresa':
            setVerify(false)
            var Fn = {
               // Valida el rut con su cadena completa "XXXXXXXX-X"
               validaRut: function (rutCompleto) {
                  if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto))
                     return false;
                  var tmp = rutCompleto.split('-');
                  var digv = tmp[1];
                  var rut = tmp[0];
                  if (digv == 'K') digv = 'k';
                  return (Fn.dv(rut) == digv);
               },
               dv: function (T) {
                  var M = 0, S = 1;
                  for (; T; T = Math.floor(T / 10))
                     S = (S + T % 10 * (9 - M++ % 6)) % 11;
                  return S ? S - 1 : 'k';
               }
            }

            var foo = value.split("-").join("")
            if (foo.length > 0 && foo.length < 10) {
               foo = foo.match(new RegExp('.{1,8}', 'g')).join("-");
               setData(prev => ({ ...prev, rut_empresa: foo, textError: '' }))
            } else if (foo.length == 0) {
               setData(prev => ({ ...prev, rut_empresa: "", textError: '' }))
            }

            break;
         default:
            break;
      }
   };

   const handleInputRut = (setFieldValue, value) => {
            var foo = value.split("-").join("")
            if (foo.length > 0 && foo.length < 10) {
               foo = foo.match(new RegExp('.{1,8}', 'g')).join("-");
               setFieldValue('rut_empresa', foo)
            } else if (foo.length == 0) {
               setFieldValue('rut_empresa', "")
            }
   }

   const handleInputBlur = (event: any) => {
      const newVal = (event.target.value).trim();
      const name = event.target.name;
      setData(prev => ({ ...prev, [name]: newVal }));
   }

   const handleSubmit = (event: React.SyntheticEvent) => {
      event.preventDefault();

      const { user_nick, rut_empresa, password } = data;
      try {
         if (validate()) {
            authSubmit(user_nick, rut_empresa, password, '');
         }
      } catch (error) {
         setData(prev => ({ ...prev, textError: 'Lo sentimos, ocurrió un error inesperado.' }));
      } finally {

      }
   }

   const recoveryPassword = async () => {
      const { user_nick, rut_empresa} = data;
      try{
         setData(prev => ({ ...prev, textError: '' }));
         if(!user_nick || !rut_empresa){
            setData(prev => ({ ...prev, textError: 'Para recuperar su contraseña, ingrese su usuario y rut empresa' }));
            return
         }
         const resp = await userService.recoveryPassword({user_name: user_nick, rut_empresa: rut_empresa})
         console.log(resp)
         setSnackBarConfig({...snackBarConfig, open:true, severity: resp.data.status ? 'success' : 'error', message: resp.data.message})

      }catch{
         setData(prev => ({ ...prev, textError: 'Lo sentimos, ocurrió un error inesperado.' }));
      }
   }

   const authSubmit = async (user_nick, rut_empresa, password, from, view=null , paramsliquidation=null) => {
      setLoading(true);
      const response = await authenticationService.login(user_nick, rut_empresa, password, from);
      let apimessagge = apiResponseTranslate(response?.data?.message || '');
      console.log(apimessagge)
      if (!!!response?.data?.token) {
         console.log(apimessagge)
         setSnackBarConfig(prev => ({
            ...prev,
            open: true,
            message: apimessagge,
         }));
      } else {
         const data = readLocalStorage(KEY_USER_DATA)
         if (data.user.role == ROLE_SUPER_ADMIN) {
            saveLocalStorage(KEY_RUTA, 'Maestros')
            saveLocalStorage(KEY_SUB_RUTA, ROUTE_COMPANIES)
            if(!!view && !!paramsliquidation){
               history.push(view, paramsliquidation)
            }else{
               history.push(ROUTE_COMPANIES);
            }
         }
         if (data.user.role == ROLE_ADMIN) {
            saveLocalStorage(KEY_RUTA, 'Maestros')
            saveLocalStorage(KEY_SUB_RUTA, ROUTE_COMPANIES)
            if(!!view){
               history.push(view)
            }else{
            history.push(ROUTE_COMPANIES)
            }
         }
         if (data.user.role == ROLE_TRABAJADOR) {
            saveLocalStorage(KEY_RUTA, 'Validaciones de Identidad')
            saveLocalStorage(KEY_SUB_RUTA, ROUTE_MY_VALIDATIONS)
            if(!!view && !!paramsliquidation){
               history.push(view, paramsliquidation)
            }else{
            history.push(ROUTE_MY_VALIDATIONS)
            }
         }
         connectPusher(data.user.iduser, data.user.id_company, `${response.data.token_type} ${response.data.token} `)
      }

      setLoading(false);
   }
   const handleClickShowPassword = () => {
      setShowPassword(!showPassword)
   };

   const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
   };

   const connectPusher = (UserId, IdCompany, token) => {
     if (UserId) {
       window['Echo'] = new Echo({
         broadcaster: 'pusher',
         key: 'rrhh_key',
         wsHost: window.location.hostname,
         wsPort: 6005,
         cluster: 'mt1',
         // wssPort: 6005,
         disableStats: true,
         forceTLS: false,
         encrypted: false,
         enabledTransports: ['ws', 'wss'],
         authEndpoint: `${process.env.REACT_APP_HOST}/api/broadcasting/auth`,
         auth: {
           headers: {
            // 'Access-Control-Allow-Origin': '*',
             Accept: 'application/json',
             Authorization: `Bearer ${token}`
           }
         },
       })
       window['Echo'].private(`notification-${UserId}-${IdCompany}`).listen('NotificationsEvent', (e) => {
         if (e){
            console.log(e)
            props.$action.actionSetListNotification(e.count)
            toast.custom((t) => (
               <div style={{padding: '1.5em', display: 'flex', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}} >
               <Link to={Routes.ROUTE_NOTIFY} target='_blank-' style={{textDecoration: 'none'}}>
                  <div style={{flexGrow: '1', alignItems: 'flex-start', display: 'flex', marginRight: '1.5rem'}}>
                     <div style={{paddingTop: '.125rem', flexShrink: '0'}}>
                        <img style={{
                           borderRadius: '9999px',
                           width: '2.5rem',
                           height: '2.5rem',
                           maxWidth: '100%',
                        }}
                        src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" />
                     </div>
                     <div style={{flex: '1 1 0%', marginLeft: '.75rem'}}>
                        <p style={{fontWeight: '500', fontSize: '0.875rem', lineHeight: '1.25rem', margin: '0', color:'#000'}}>{e.dataSender} te notificó:</p>
                        <p style={{color: 'rgb(107 114 128)', fontSize: '.875rem', lineHeight: '1.25rem', marginTop: '.25rem', margin: '0'}}>{e.content}</p>
                     </div>
                  </div>
                  </Link>
                  <div style={{flexGrow: '2', borderLeft: '2px solid rgb(107 114 128)', borderLeftWidth: '1px', display: 'flex'}}>
                        <button onClick={() => toast.remove(t.id)}
                           style={{color: 'rgb(79 70 229)', fontWeight: '500', fontSize: '.875rem', lineHeight: '1.25rem',
                              padding: '1rem', borderColor: 'transparent', borderWidth: '1px', borderTopRightRadius: '.5rem', borderBottomRightRadius: '.5rem',
                              borderRadius: '0', justifyContent: 'center', alignItems: 'center', width: '100%', display: 'flex', outline: '2px solid transparent',
                              outlineOffset: '2px',
                              background: '#fff'
                        }}>
                           Cerrar
                        </button>
                  </div>
               </div>
            ),
            {
               duration: 6000,
            })
         }
       })
     }
   }

   return (
      <>
      <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
         >
            <Alert
               onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
               severity={snackBarConfig.severity}
               variant="filled"
            >
               {snackBarConfig.message}
            </Alert>
         </Snackbar>
        <Box className='login-main-container'>
          <Grid container justifyContent="center">

            <Grid item xs={12} className='login-container'>
              <Grid item xs={12} sm={12} md={5} lg={5} sx={{textAlign: { md: 'end', xs: 'start' }}}>
                <img style={{ width: '300px' }} src={logo} />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} p={ {md: '0px 5px', xs: '0px 20px', lg:'0px 80px 0px 0px'}}>
                <Paper elevation={5}  sx={{ borderRadius: '16px'}}>
                    <Grid xs={12} sx={{height:'40px', backgroundColor:'#a9d49b', borderTopLeftRadius: '16px', borderTopRightRadius: '16px'}}></Grid>
                    <Grid sx={{padding: '20px 60px',}}>
                      {/* Title */}
                      <Grid item xs={12}>
                        <Stack direction="row" justifyContent="start">
                          <Typography variant='h5' color='#212D39' sx={{ fontWeight:600}}>Ingresa tu </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack direction="row" justifyContent="start">
                          <Typography variant='h5' color='#212D39' sx={{ fontWeight:600, paddingBottom: 2}}>Usuario</Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack direction="row" justifyContent="start">
                          <Typography variant='body2' color='#808080' sx={{ fontWeight:400, paddingBottom: 3}}>Si ya eres parte de nosotros, ingresa tus datos:</Typography>
                        </Stack>
                      </Grid>
                      <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                        {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
                          return (
                            <form onSubmit={handleSubmit}>
                                <ThemeProvider theme={themeAvenir}>
                                  <Grid container spacing={2}>
                                    <Grid item container xs={12} spacing={2}>
                                       <Grid item xs={12}>
                                        <TextField
                                          id="user_nick"
                                          name='user_nick'
                                          fullWidth
                                          variant='outlined'
                                          value={values.user_nick}
                                          onChange={handleChange}
                                          placeholder='Ingrese su Usuario'
                                          size='small'
                                          type="text"
                                          InputProps={{
                                            startAdornment: (
                                              <InputAdornment position='start'>
                                                <PersonIcon sx={{ color: '#29333B' }} />
                                              </InputAdornment>)
                                          }}
                                          error={errors.user_nick && touched.user_nick ? true : false}
                                          helperText={errors.user_nick && touched.user_nick ? errors.user_nick : ''}
                                        />
                                      </Grid>
                                      <Grid item xs={12}>
                                        <TextField
                                          id="rut_empresa"
                                          name='rut_empresa'
                                          fullWidth
                                          variant='outlined'
                                          value={values.rut_empresa}
                                          onChange={(e) => handleInputRut( setFieldValue ,e.target.value)}
                                          placeholder='Ingrese su RUT'
                                          size='small'
                                          type="text"
                                          InputProps={{
                                            startAdornment: (
                                              <InputAdornment position='start'>
                                                <PersonIcon sx={{ color: '#29333B' }} />
                                              </InputAdornment>)
                                          }}
                                          error={errors.rut_empresa && touched.rut_empresa ? true : false}
                                          helperText={errors.rut_empresa && touched.rut_empresa ? errors.rut_empresa : ''}
                                        />
                                      </Grid>
                                      <Grid item xs={12}>
                                        <TextField
                                          id="password"
                                          name="password"
                                          fullWidth
                                          variant='outlined'
                                          type={showPassword ? 'text' : 'password'}
                                          value={values.password}
                                          onChange={handleChange}
                                          size='small'
                                          placeholder='Ingresa su clave'
                                          InputProps={{
                                            startAdornment: (
                                              <InputAdornment position='start'  >
                                                <LockIcon sx={{ color: '#29333B' }} />
                                              </InputAdornment>),
                                            endAdornment: (
                                              <InputAdornment position="end">
                                                <IconButton
                                                  aria-label="toggle password visibility"
                                                  onClick={handleClickShowPassword}
                                                  onMouseDown={handleMouseDownPassword}
                                                  edge="end"
                                                  sx={{ color: '#29333B' }}
                                                >
                                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                              </InputAdornment>
                                            )
                                          }}
                                          error={errors.password && touched.password ? true : false}
                                          helperText={errors.password && touched.password ? errors.password : ''}
                                        />
                                      </Grid>
                                      <Grid item xs={12} display='flex' justifyContent='center'>
                                        <Button
                                          type="submit"
                                          disabled={loading}
                                          fullWidth
                                          size="small"
                                          variant="contained"
                                          sx={{ width:'150px', background:'#516E80',color:'#fff', '&:hover':{bgcolor:'#007ea7'}}}
                                          onClick={()=>{handleSubmit()}}
                                        >
                                          { loading ? <CircularProgress size={28} /> :  'Continuar' }
                                        </Button>
                                      </Grid>

                                      <Grid item xs={12} display='flex' justifyContent='center'>
                                        <Button
                                          fullWidth
                                          size="small"
                                          variant="contained"
                                          sx={{ width:'150px', background:'#FFFFFF',color:'#516E80', border:'solid 1px #516E80', '&:hover':{bgcolor:'#73B2FF', color:'#FFFFFF'}}}
                                        >
                                          Crear cuenta
                                        </Button>
                                      </Grid>
                                      <Button fullWidth onClick={()=>{recoveryPassword()}}>Recuperar contraseña</Button>


                                      <Grid item xs={12}>
                                        <Divider variant="middle" />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </ThemeProvider>
                                <span className="TextError">{data.textError}</span>
                            </form>
                          )
                        }} 
                      </Formik>
                    </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
      </Box>
         {loadData && <Loading title="Iniciando Sesión" />}
      </>
      // <>
      //    <ThemeProvider theme={theme}>
      //       <Toaster
      //          position="bottom-right"
      //          reverseOrder={false}

      //       />
      //       <Grid container component="main" sx={{ height: '100vh' }}>
      //          <CssBaseline />
      //          <Grid
      //             item
      //             xs={false}
      //             sm={6}
      //             md={7}
      //             sx={{
      //                backgroundImage: `url(${logoLogin})`,
      //                backgroundRepeat: 'no-repeat',
      //                backgroundColor: (t) =>
      //                   t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
      //                backgroundSize: 'cover',
      //                backgroundPosition: 'center',
      //             }}
      //          />
      //          <Grid item xs={12} sm={6} md={5} component={Paper} elevation={6} square >
      //             <Box
      //                sx={{
      //                   my: 5,
      //                   mx: 2,
      //                   display: 'flex',
      //                   flexDirection: 'column',
      //                   alignItems: 'center',
      //                }}>

      //                <Grid sx={{ border: '1px solid #155172', borderTop: '8px solid #155172', borderRadius: '7px', width: '80%' }}>
      //                   <form className="LoginForm" onSubmit={handleSubmit}>
      //                      <Grid container spacing={2} className={`e-mb-lg`}>
      //                         <Grid item xs={12}>
      //                            <Typography textAlign={'center'} sx={{ color: '#155172', fontWeight: '700', fontSize: '1.6em' }} >Sesión de Usuario</Typography>
      //                         </Grid>
      //                         <Grid item xs={12}>
      //                            <Input
      //                               ref={ref => inputRefs.current[0] = ref}
      //                               label="Nombre de Usuario"
      //                               name="user_nick"
      //                               placeholder="Ingrese su nombre de usuario"
      //                               value={data.user_nick}
      //                               onChange={handleInput}
      //                               onBlur={handleInputBlur}
      //                               backgroundColor="#eeeff1"
      //                               rules={rules.user_nick}
      //                               disableElevation
      //                               validateOnBlur
      //                               dense
      //                            />
      //                         </Grid>
      //                         <Grid item xs={12}>
      //                            <Input
      //                               ref={ref => inputRefs.current[1] = ref}
      //                               label="RUT Empresa"
      //                               name="rut_empresa"
      //                               type="text"
      //                               placeholder="Ingrese su RUT Empresa"
      //                               value={data.rut_empresa}
      //                               onChange={handleInput}
      //                               onBlur={handleInputBlur}
      //                               backgroundColor="#eeeff1"
      //                               rules={rules.rut_empresa}
      //                               disableElevation
      //                               validateOnBlur
      //                               dense
      //                            />
      //                         </Grid>
      //                         <Grid item xs={12}>
      //                            <Input
      //                               ref={ref => inputRefs.current[2] = ref}
      //                               label="Contraseña"
      //                               name="password"
      //                               type={data.showPassword ? 'text' : 'password'}
      //                               placeholder="Ingrese su contraseña"
      //                               value={data.password}
      //                               onChange={handleInput}
      //                               onBlur={handleInputBlur}
      //                               backgroundColor="#eeeff1"
      //                               //rules={rules.password}
      //                               appendAdornment={
      //                                  <InputAdornment position="end">
      //                                     <IconButton
      //                                        aria-label="toggle password visibility"
      //                                        onClick={handleClickShowPassword}
      //                                        onMouseDown={handleMouseDownPassword}
      //                                     >
      //                                        {data.showPassword ? <Icon Svg={VisibilityIcon} /> : <Icon Svg={VisibilityOffIcon} />}
      //                                     </IconButton>
      //                                  </InputAdornment>
      //                               }
      //                               disableElevation
      //                               validateOnBlur
      //                               dense
      //                            />
      //                         </Grid>
      //                         <Grid item xs={12}>

      //                            {verify ? (<span style={{ color: 'red' }}>(*) Ingresa el Usuario y Rut</span>) : (<></>)}
      //                            <Box sx={{ m: 1, position: 'relative' }}>
      //                               <Button
      //                                  fullWidth={true}
      //                                  type='submit'
      //                                  variant='contained'
      //                                  onClick={handleSubmit}
      //                                  disabled={loading}
      //                                  sx={{
      //                                     background: '#E4980D', color: '#fff', fontWeight: '700', '&:hover': {
      //                                        bgcolor: '#E4980D'
      //                                     }
      //                                  }}
      //                                  disableElevation
      //                               >
      //                                  <span>Iniciar sesión</span>
      //                               </Button>
      //                               {loading && (
      //                                  <CircularProgress
      //                                     size={24}
      //                                     sx={{
      //                                        position: 'absolute',
      //                                        top: '50%',
      //                                        left: '50%',
      //                                        marginTop: '-12px',
      //                                        marginLeft: '-12px',
      //                                     }}
      //                                  />
      //                               )}
      //                               <Button fullWidth onClick={()=>{recoveryPassword()}}>Recuperar contraseña</Button>
      //                            </Box>
      //                         </Grid>
      //                      </Grid>
      //                      <span className="TextError">{data.textError}</span>
      //                   </form>
      //                </Grid>
      //                <Snackbar
      //                      open={snackBarConfig.open} 
      //                      autoHideDuration={snackBarConfig.autoHideDuration} 
      //                      onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
      //                      anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
      //                      <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
      //                         {snackBarConfig.message || ""}
      //                      </Alert>
      //                </Snackbar>
      //             </Box>
      //          </Grid>
      //       </Grid>
           
      //    </ThemeProvider>

      //    {loadData && <Loading title="Iniciando Sesión" />}
      // </>
   )
}
