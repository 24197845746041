import { AssistanceService } from "@/service/services/Assistance.service";
import { branchOfficeService } from "@/service/services/BranchOffice.service";
import { ChargeService } from "@/service/services/Charge.service";
import { CostCenterService } from "@/service/services/CostCenter.service";
import { Alert, Snackbar, Typography } from "@mui/material";
import { useState } from "react";
import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalFooter } from "../ModalFooter";
import { ModalHeader } from "../ModalHeader";
import { Props } from "./ModalBranchOfficeTypeDelete.type";
import ___PHRASE from "@/service/translate/translateService";
import { apiResponseTranslate } from "@/toolbox/helpers/responseApi.helper";

export const ModalBranchOfficeTypeDelete: React.FC<Props> = (props:any): JSX.Element => {
    const { open, closeModal, branchOfficeType, onListTypes, clearState } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
     })

    const onDeleted= async()=>{
        setLoading(true);
        await branchOfficeService.deleteTypeBranchOffice(branchOfficeType.id).then((data) => {
            let apimessagge = apiResponseTranslate(data?.data?.message || '');

            if(data.data.status){
                setSnackBarConfig({...snackBarConfig, open: true, severity: 'success', message: apimessagge})
            }else{
                setSnackBarConfig({...snackBarConfig, open: true, severity: 'error', message:apimessagge})
            }
            setTimeout(()=>{
            onListTypes && onListTypes();
            closeModal && closeModal();
             },2000)
        })
        setLoading(false);
    }
  
    return (
        <>
         <Snackbar
                open={snackBarConfig.open}
                autoHideDuration={snackBarConfig.autoHideDuration}
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
                <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                    {snackBarConfig.message || ""}
                </Alert>
        </Snackbar>
        <Modal
        open={open}
        handleClose={closeModal}
        size="xs"
        >
        <ModalHeader
            text={___PHRASE("eliminar")+' '+___PHRASE("tipoDeSucursal")}
            className='positionElements'
            onCancel={closeModal}
            clearState={clearState}
        >
        </ModalHeader>

        <ModalBody>
            <Typography variant="subtitle1" className="textColor"> 
              {___PHRASE("estaSeguroDeEliminarEsteTipoDeSucursal")}
            </Typography>
        </ModalBody>

        <ModalFooter 
            confirmText={___PHRASE("eliminar")}
            cancelText={___PHRASE("cancelar")}
            onCancel={closeModal}
            className="footerRight"
            onConfirm={onDeleted}
            clearState={clearState}
        />
        </Modal>
        </>
    )
}