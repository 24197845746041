import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

const dataUser = readLocalStorage(KEY_USER_DATA);
const rut_empresa = dataUser?.user?.rut_empresa;

export const ConfigurationIPRepository = {

    //IPS ACEPTADAS

    getIPAccessByCompany: async(): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/configIP/getAcceptIP`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    getIPAccessByUser: async(idUser: number): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/configIP/getAcceptIP/${idUser}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    acceptIP: async (dataIP) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/configIP/acceptIP`, {
            rangeStart: dataIP.rangeStart,
            rangeEnd: dataIP.rangeEnd,
            iduser: dataIP.iduser
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    editAccessIP: async (dataIP, idIP:any) : Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/configIP/updateAcceptIP/${idIP}`, {
            rangeStart: dataIP.rangeStart,
            rangeEnd: dataIP.rangeEnd,
            permission: dataIP.permission, //nullable
            iduser: dataIP.iduser
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    deleteAccessIP: async(id:any): Promise<any> => {
        const res= await http.delete<any>(`${API_URL_BASE}/v1/configIP/deleteAcceptIP/${id}`)
        return res;
    },

    //IPS DENEGADAS

    getIPDeniedByCompany: async(): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/configIP/getDeniedIP`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    getIPDeniedByUser: async(idUser: number): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/configIP/getDeniedIP/${idUser}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    deniedIP: async (dataIP) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/configIP/deniedIP`, {
            ipDenied: dataIP.ipDenied,
            iduser: dataIP.iduser
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    editDeniedIP: async (dataIP, idIP:number) : Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/configIP/updateDeniedIP/${idIP}`, {
            ipDenied: dataIP.ipDenied,
            permission: dataIP.permission, //nullable
            iduser: dataIP.iduser
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    deleteDeniedIP: async(id:number): Promise<any> => {
        const res= await http.delete<any>(`${API_URL_BASE}/v1/configIP/deleteDeniedIP/${id}`)
        return res;
    },
    
    assignPermissionIP: async (dataIP) : Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/user/updateAssignIp`, {
            iduser: dataIP.iduser,
            assign_ip: dataIP.assign_ip,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    //SETTING IP BY COMPANY
    getCompanyIP: async(): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/configIP/getCompanyIP`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    editSettingIP: async (dataIP) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/configIP`, {
            ip_access_request: dataIP.ip_access_request,
            start_access: dataIP.start_access,  //nullable
            end_access: dataIP.end_access, //nullable
        });
        const {data, error, message} = res;
        return { data, error, message };
    },
}   