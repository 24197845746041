import React, { useEffect, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Loading } from "@components/common/Loading";
import { Alert, Container, Snackbar } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { Props } from './LiquidationSalary.type';
import { WorkerService } from '@/service/services/Worker.service';
import { ModalGenerateLiquidation } from '@/components/Modal/ModalGenerateLiquidation';
import { RemunerationService } from "@/service/services/Remuneration.service";
import { useLocation } from 'react-router-dom';
import { PeriodService } from '@/service/services/Period.service';
import moment from 'moment';
import ___PHRASE from '@/service/translate/translateService';
import { apiResponseTranslate } from '@/toolbox/helpers/responseApi.helper';

export const LiquidationSalaryView: React.FC<Props> = (props:any): JSX.Element => {

   const [loading, setLoading] = useState<boolean>(false);
   const [workers, setWorkerts] = useState<any>([]);
   const [workerSelected, setWorkerSelected] = useState<any>('');
   const [openModal, setOpenModal] = useState<Boolean>(false)
   const [actionSelected, setActionSelected] = useState<any>('');
   const [view, setView] = useState<String>('workers');
   const [showLiquidation, setShowLiquitacion] = useState<boolean>(false);
   const [liquidationExists, setLiquitacionExists] = useState<any>('');
   const [periods, setPeriods] = useState<any>([]);
   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
   });
   const [dataRequest, setDataRequest] = useState<any>('');

   const cabecera = [
      { name: 'n_document', label: ___PHRASE("rutTrabajador") },
      { name: 'surname', label: ___PHRASE("apellidoPaterno") },
      { name: 'second_surname', label: ___PHRASE("apellidoMaterno") },
      { name: 'name', label: ___PHRASE("nombres") }
   ];

   //Redireccion: props enviados de la vista solicitud (novedades del mes)
   let location: any = useLocation();
   let dataprops: any = location?.state;

   useEffect( ()=> {
      if(dataprops){
         changeStatusRemuneration(dataprops.id, dataprops.idPeriod);
         setWorkerSelected(dataprops);
         setDataRequest(dataprops);
         setOpenModal(true)
      }
   },[dataprops]);

   useEffect(()=> {
      getPeriods();
      getWorkers();
   },[])

   const getWorkers = async() => {
      setLoading(true);
      const resp:any = await WorkerService.getWorkers();
      const oldData = resp.data || [];
      let newData = [];
      oldData.length > 0 && oldData.map((item) => {
         let obj = {
               ...item,
               name_complete: item.name+' '+item.surname+' '+item.second_surname,
         }
         newData.push(obj);
      });
      setWorkerts(newData);
      setLoading(false);
   }

   const getPeriods = async () => {
      const resp: any = await PeriodService.getPeriods();
      const newData = [];
      resp.data && (resp.data).length > 0 && (resp.data).map((item) => {
         newData.push({
            ...item,
            alias_name: item.month + '-' + item.year,
         })
      });
      setPeriods(newData || []);
   }

   const searchData = async (value) => {
      const resp:any = await WorkerService.getWorkers(value);
      const oldData = resp.data || [];
      let newData = [];
      oldData.length > 0 && oldData.map((item) => {
         let obj = {
               ...item,
               name_complete: item.name+' '+item.surname+' '+item.second_surname,
         }
         newData.push(obj);
      });
      setWorkerts(newData);
   }

   const changeStatusRemuneration = async(idEmployee, idperiod) => {
      setLoading(true);
      await RemunerationService.changeStatusRemuneration(idEmployee, idperiod).then((data)=>{
         let apimessage = apiResponseTranslate(data?.data?.message)
         if(data?.data?.status == true){
            setLoading(false);
            setSnackBarConfig(prev => ({ ...prev, open: true,
               severity:'success',
               message: `${apimessage || ___PHRASE("vuelvaaGenerarLaLiquidacion")}`
            }))
         }else{
            setLoading(false);
            setSnackBarConfig(prev => ({ ...prev, open: true,
               severity:'error',
               message: `${apimessage || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}`
            }))
         }
      });
      setLoading(false);
   }


  async function RecuperarData(value){
      if(value.action == 'reprocess') {
         setLoading(true);
         let currentMonth = moment().format('MM-YYYY');
         const period= periods.length > 0 ? periods.find(element => element.alias_name == currentMonth) : '';
         if(!period){
            setLoading(false);
            return (
               setSnackBarConfig(prev => ({ ...prev, open: true, 
                  severity:'error', 
                  message: ___PHRASE("elPeriodoActual")+` (${currentMonth}) `+___PHRASE("noSeEncuentraDisponible")
               }))
            )
         }
         await RemunerationService.changeStatusRemuneration(value.id, period.id).then((data)=>{
            let apimessage = apiResponseTranslate(data?.data?.message)
            if(data?.data?.status == true){
               setLoading(false);
               setSnackBarConfig(prev => ({ ...prev, open: true,
                  severity:'success',
                  message: `${apimessage || ___PHRASE("vuelvaaGenerarLaLiquidacion")}`
               }))
               setWorkerSelected(value);
               setOpenModal(value);
            }else{
               setLoading(false);
               setSnackBarConfig(prev => ({ ...prev, open: true,
                  severity:'error',
                  message: `${ apimessage || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}`
               }))
            }
         });
         setLoading(false);
      }else {
         setWorkerSelected(value);
         setOpenModal(true)
      }
   }

  return (
      <Protected>
         {loading && <Loading/>}
         <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
               {snackBarConfig.message || ""}
            </Alert>
         </Snackbar>
         <Container maxWidth='xl'>

            <TableDataV2
               title={___PHRASE("liquidacionDeSueldoPorTrabajadores")}
               data={workers}
               header={cabecera}
               actionSelect={setActionSelected}
               status_action
               btn_disabled
               btn_edit_disable={true}
               btn_delete_disable={true}
               btn_helper
               // btn_reprocess
               textSearch={___PHRASE("buscarTrabajador")}
               searchData={searchData}
               RecuperarData={RecuperarData}
            />
         </Container>
         {
            openModal && (
               <ModalGenerateLiquidation
                  open={openModal}
                  closeModal = {()=> {setOpenModal(false)}}
                  actionSelected={actionSelected}
                  workerSelected={workerSelected}
                  setShowLiquitacion={setShowLiquitacion}
                  setView={setView}
                  setLiquitacionExists={setLiquitacionExists}
                  dataRequest={dataRequest}
                  setDataRequest={setDataRequest}
                  periods={periods}
               />
            )
         }
      
      </Protected>
   )
};