import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik } from 'formik';
import { useEffect, useState } from "react";
import { Alert, Button, Divider, Grid, InputAdornment, MenuItem, Select, Snackbar, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import { Loading } from "@/components/common/Loading";
import { Props } from "./ModalExpenseEmployee.type";
import { EmployeeExpenseService } from "@/service/services/EmployeeExpense.service";
import { PeriodService } from "@/service/services/Period.service";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { typesExpenseService } from "@/service/services/TypesExpense.service";
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_TRANSLATE, KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import ___PHRASE from "@/service/translate/translateService";
import { apiResponseTranslate } from "@/toolbox/helpers/responseApi.helper";
import '../../../assets/styles/modals.scss';

export const ModalExpenseEmployee: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, expense, onListExpenses, clearState } = props;

    const language = readLocalStorage(KEY_TRANSLATE);
    const ArrayData = readLocalStorage(KEY_USER_DATA);
    const userSession = ArrayData?.user;
    const id_detail_user = userSession?.id;

    const [loading, setLoading] = useState<boolean>(false);
    const [countReport, setCountReport] = useState<any>([]);
    const [expenseReportType, setExpenseReportType] = useState<any>([]);

    const [periods, setPeriods] = useState<any>([]);
    const [years, setYears] = useState<any>([]);
    const [yearSelected, setYearSelected] = useState<any>('');
    const [monthSelected, setMonthSelected] = useState<any>('');
    const [detail, setDetail] = useState<any>('');

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonthJS = currentDate.getMonth() + 1; //getMonth() su indice empieza desde 0
    const currentMonth = currentMonthJS <= 9 ? `0${currentMonthJS}` : `${currentMonthJS}`;

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const [data, setData] = useState<any>({
        id: expense ? expense.id : '',
        detail: expense ? expense.detail : '',
        idperiod: expense ? expense.idperiod : '',
        numberReport: expense ? expense.numberReport : 0,
    });

    useEffect(() => {
        setYearSelected(`${currentYear}`);
        setMonthSelected(currentMonth);
    }, [open]);

    useEffect(() => {
        listReportType();
        getPeriods();
    }, []);

    const listReportType = async () => {
        setLoading(true);
        await typesExpenseService.getTypesExpense().then((data) => {
            const newData = [];
            data.data && (data.data).length > 0 && (data.data).map((item)=>{
                if(language && language == 'en'){
                    newData.push({...item, name: apiResponseTranslate(item.name)})
                }else{
                    newData.push(item)
                }
            })
            setExpenseReportType(newData || [])
            // setExpenseReportType(data.data || [])
           setLoading(false);
        });
    }

    const getPeriods = async() => {
        setLoading(true);
        const resp:any = await PeriodService.getPeriods();
        const newData = [];
        const arrayYears = [];
        resp.data && (resp.data).length > 0 && (resp.data).map((item) => {
           newData.push({
              id: item.id,
              month: item.month,
              year: item.year,
              alias_name: item.month + '-' +  item.year,
           })
           const found = arrayYears.find(element => element == item.year);
           if(!found) { arrayYears.push(item.year); }
           
        });
        setYears(arrayYears);
        setPeriods(newData || []);
        setLoading(false);
    }

    const selectCountReport = (operador, num) => {
        let count = num;
        if(operador == '+') {count = num +1};
        if(operador == '-' && num > 1) {count = num -1};
        setData({...data, numberReport: count});
        if(count > 0){
            let array = [];
            let newData;
            for (let index = 0; index < count; index++) {
                let obj = {
                    name: `Detalle N° ${index + 1}`
                }
                array.push(obj);

                newData = {
                    ...data,
                    ...newData,
                    detail: detail,
                    numberReport: count,
                    ['idexpense_report_type'+''+index]: '',
                    ['detailReport'+''+index]: '',
                    ['amount'+''+index]: '',
                    ['doc'+''+index]: '',
                }
            }
            setCountReport(array);
            setData(newData);
        }
    }

    const setNameFile = (event) => {
        const files = event.target && event.target.files || [];
        const currentCv = files[0];
        
        if(currentCv){
            const nameAsArray = currentCv.name.split(".") || [];
            const nameWithoutExtension = nameAsArray.filter((word, i) => i != nameAsArray.length-1);
            const nameFile = nameWithoutExtension.join(".") || "";
            return currentCv;
        }
        return "";
    };


    const validateForm = (values) => {
        let errors: any = {};
        if(!values.detail) errors.detail = ___PHRASE("nombreRequerido");
        if(!values.numberReport) errors.numberReport = ___PHRASE("numeroDeReporteRequerido");

        countReport.length >= 0 && countReport.map((item, index)=>{
            if(!values[`idexpense_report_type${index}`]) errors[`idexpense_report_type${index}`] = ___PHRASE("typoDeReporteRequerido");
            if(!values[`detailReport${index}`]) errors[`detailReport${index}`] = ___PHRASE("detalleRequerido");
            if(!values[`amount${index}`]) errors[`amount${index}`] = ___PHRASE("montoRequerido");
            if(!values[`doc${index}`]) errors[`doc${index}`] = ___PHRASE("documentoRequerido");
        })
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {
        
        if(!monthSelected || !yearSelected){
            return (
               setSnackBarConfig(prev => ({ ...prev, open: true, 
                  severity:'warning', 
                  message: ___PHRASE("debeSeleccionarMesyAno") }
               ))
            );
        }

        if((currentYear != yearSelected) || (currentMonth != monthSelected)) {
            return (
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                   severity:'warning', 
                   message: ___PHRASE("debeSeleccionarElAnoyMesActual") }
                ))
            );
        }

        const periodSelected = monthSelected + '-' + yearSelected;
        const period = periods.find(element => element.alias_name == periodSelected);

        const formData = new FormData();
        formData.append("id", values.id);
        formData.append("detail", values.detail);
        formData.append("idperiod", period.id);
        formData.append("numberReport", values.numberReport);
        formData.append("iddetailuser", id_detail_user);
        formData.append("language", language);

        countReport.map((item, index)=>{
            formData.append(`idexpense_report_type${index}`, values[`idexpense_report_type${index}`]);
            formData.append(`detailReport${index}`, values[`detailReport${index}`]);
            formData.append(`amount${index}`, values[`amount${index}`]);
            formData.append(`doc${index}`, values[`doc${index}`]);
        })

        setLoading(true);
        await EmployeeExpenseService.createExpenseEmployee(formData).then((data) => {
            let apimessagge = apiResponseTranslate(data?.data?.message || '');
            if(data.data.status == true){
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                    severity:'success', 
                    message: `${apimessagge || ___PHRASE("gastoCreadoSatisfactoriamente")}`
                }))
                onListExpenses && onListExpenses();
                setTimeout(function(){
                    closeModal && closeModal();
                }, 1000);
            }else{
                setLoading(false);
                return (
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'warning', 
                        message: `${apimessagge}` }
                    ))
                );
            }
        })
        setLoading(false);
    }

    return(
        <>
        {loading && <Loading/>}
        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>

        <Modal open={open} disableEscapeKeyDown disableBackdropClick handleClose={closeModal} handle size="lg">
            <ModalHeader
                text={((expense && actionSelected=='edit') ? ___PHRASE("editar") : ___PHRASE("agregar"))+' '+___PHRASE("gasto")}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return( 
                            <form onSubmit={handleSubmit}>
                                
                                <Grid container >
                                    <Grid item xs={6} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={3}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("concepto")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="detail"
                                            variant="standard"
                                            type="text" 
                                            name="detail"
                                            multiline
                                            maxRows={4}
                                            fullWidth
                                            value={values.detail}
                                            onChange={(e)=>{
                                                setFieldValue("detail", e.target.value)
                                                setDetail(e.target.value)
                                            }}
                                            placeholder={___PHRASE("conceptoDeDetalle")}
                                            error={errors.detail && touched.detail ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={6} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={3}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("periodo")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <Select
                                            id="id_education_level"
                                            variant="standard"
                                            value={yearSelected}
                                            onChange={(event) => {
                                            setYearSelected(event.target.value);
                                            }}
                                            fullWidth
                                            inputProps={{ readOnly: true }}
                                        >
                                            {
                                            years.map((year, i) => {
                                                return <MenuItem key={i} value={year}>{year}</MenuItem>;
                                            })
                                            }
                                        </Select>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={6} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={3}>
                                        <Typography variant="subtitle1" className="custom-input">{___PHRASE("mes")}</Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <Select
                                            id="id_education_level"
                                            variant="standard"
                                            value={monthSelected}
                                            onChange={(event) => {setMonthSelected(event.target.value)}}
                                            fullWidth
                                            inputProps={{ readOnly: true }}
                                        >
                                            <MenuItem value={'01'}>{___PHRASE("enero")}</MenuItem>
                                            <MenuItem value={'02'}>{___PHRASE("febrero")}</MenuItem>
                                            <MenuItem value={'03'}>{___PHRASE("marzo")}</MenuItem>
                                            <MenuItem value={'04'}>{___PHRASE("abril")}</MenuItem>
                                            <MenuItem value={'05'}>{___PHRASE("mayo")}</MenuItem>
                                            <MenuItem value={'06'}>{___PHRASE("junio")}</MenuItem>
                                            <MenuItem value={'07'}>{___PHRASE("julio")}</MenuItem>
                                            <MenuItem value={'08'}>{___PHRASE("agosto")}</MenuItem>
                                            <MenuItem value={'09'}>{___PHRASE("septiembre")}</MenuItem>
                                            <MenuItem value={'10'}>{___PHRASE("octubre")}</MenuItem>
                                            <MenuItem value={'11'}>{___PHRASE("noviembre")}</MenuItem>
                                            <MenuItem value={'12'}>{___PHRASE("diciembre")}</MenuItem>
                                        </Select>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={6} container alignItems="center" justifyContent="center" marginBottom='25px' >                
                                        <Grid item xs={3}>
                                            <Typography variant="subtitle1"><b>{___PHRASE("nDeReportes")}:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8} sx={{display:'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                            <Grid onClick={()=>{selectCountReport('-', values.numberReport)}} sx={{cursor: 'pointer'}}>
                                                <RemoveCircleIcon sx={{fontSize:'30px'}}/>
                                            </Grid>
                                            <Grid>
                                                <TextField
                                                    size='small'
                                                    id="numberReport"
                                                    type="text" 
                                                    name="numberReport"
                                                    value={values.numberReport}
                                                    error={errors.numberReport && touched.numberReport ? true : false}
                                                    sx={{textAlign:'center', alignItems:'center'}}
                                                />
                                            </Grid>
                                            <Grid onClick={()=>{selectCountReport('+', values.numberReport)}} sx={{cursor: 'pointer'}}>
                                                <AddCircleIcon sx={{fontSize:'30px'}}/>
                                            </Grid>
                                        </Grid> 
                                    </Grid>
                                    {
                                        (countReport && countReport.length > 0) && (
                                            <Grid  item xs={12} container alignItems="center" justifyContent="space-between" 
                                                sx={{paddingTop: '5px', marginBottom: '25px', overflowX: 'hidden', maxHeight:'400px'}}>
                                                    {
                                                        (countReport && countReport.length > 0) && countReport.map((item, index)=>{
                                                            const idexpense_report_type = `idexpense_report_type${index}`;
                                                            const detailReport = `detailReport${index}`;
                                                            const amount = `amount${index}`;
                                                            const doc = `amount${index}`;

                                                            const numDetail = index + 1;
                                                            return (
                                                            <>
                                                            <Grid item xs={12} container alignItems="center" justifyContent="start" marginBottom='10px' >
                                                                {___PHRASE("nDetalle")+` ${numDetail}`}
                                                            </Grid>
                                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' sx={{border: '1px #b9b1a9 solid'}} >
                                                                <Grid item xs={6} container alignItems="center" justifyContent="center"  marginTop='25px' marginBottom='25px' >                
                                                                    <Grid item xs={3}>
                                                                        <Typography variant="subtitle1"><b>{___PHRASE("tipoDeReporte")}:</b> </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={8}>
                                                                        <Select
                                                                            id={`idexpense_report_type${index}`}
                                                                            name={`idexpense_report_type${index}`}
                                                                            variant="standard"
                                                                            value={values[idexpense_report_type]}
                                                                            onChange={(event) => {
                                                                                setFieldValue(`idexpense_report_type${index}`, event.target.value)
                                                                            }}
                                                                            fullWidth
                                                                            error={errors[`idexpense_report_type${index}`] && touched[`idexpense_report_type${index}`]? true : false}
                                                                        >
                                                                            {
                                                                                expenseReportType.length > 0 && expenseReportType.map((type, i) => {
                                                                                    return <MenuItem key={i} value={type.id}>{type.name}</MenuItem>;
                                                                                })
                                                                            }
                                                                        </Select>
                                                                    </Grid> 
                                                                </Grid>

                                                                <Grid item xs={6} container alignItems="center" justifyContent="center" marginBottom='25px' marginTop='25px'  >
                                                                    <Grid item xs={3}>
                                                                    <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("descripcion")}:</b></Typography>
                                                                    </Grid>
                                                                    <Grid item xs={8}>
                                                                    <TextField
                                                                        id={`detailReport${index}`}
                                                                        variant="standard"
                                                                        type="text" 
                                                                        name={`detailReport${index}`}
                                                                        multiline
                                                                        maxRows={4}
                                                                        fullWidth
                                                                        value={values[detailReport]}
                                                                        onChange={handleChange}
                                                                        placeholder={___PHRASE("detalle")}
                                                                        error={errors[`detailReport${index}`] && touched[`detailReport${index}`] ? true : false}
                                                                    />
                                                                    </Grid>
                                                                </Grid>

                                                                <Grid item xs={6} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                                                    <Grid item xs={3}>
                                                                    <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("monto")}:</b></Typography>
                                                                    </Grid>
                                                                    <Grid item xs={8}>
                                                                    <TextField
                                                                        id={`amount${index}`}
                                                                        variant="standard"
                                                                        type="number" 
                                                                        name={`amount${index}`}
                                                                        fullWidth
                                                                        value={values[amount]}
                                                                        onChange={handleChange}
                                                                        placeholder={___PHRASE("monto")}
                                                                        error={errors[`amount${index}`] && touched[`amount${index}`] ? true : false}
                                                                        InputProps={{
                                                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                                        }}
                                                                    />
                                                                    </Grid>
                                                                </Grid>

                                                                <Grid item xs={6} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                                                    <Grid item xs={3}>
                                                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("documento")}:</b></Typography>
                                                                    </Grid>
                                                                    <Grid item xs={8}>
                                                                        <Button variant="contained" component="label" fullWidth>
                                                                            <input
                                                                                id={`doc${index}`}
                                                                                type="file"
                                                                                name={`doc${index}`}
                                                                                onChange={(event) => {
                                                                                    setFieldValue(`doc${index}`, setNameFile(event));
                                                                                }}
                                                                            />
                                                                        </Button>
                                                                        {
                                                                        errors[`doc${index}`] && touched[`doc${index}`] && (
                                                                            <Typography sx={{color: 'red', fontSize:'15px', marginTop: '10px'}}>
                                                                                {___PHRASE("debeAgregarUnDocumento")}
                                                                            </Typography>
                                                                        ) 
                                                                        }
                                                                    </Grid>
                                                                </Grid> 

                                                            </Grid>
                                                            </>
                                                            )
                                                        })  
                                                    }
                                            </Grid>
                                        )
                                    }
                                </Grid>

                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(expense && actionSelected=='edit') ? ___PHRASE("actualizar") : ___PHRASE("registrar")}
                                    cancelText={___PHRASE("cancelar")} 
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}