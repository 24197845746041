import { Loading } from "@/components/common/Loading";
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { Alert, Grid, Snackbar, Typography } from "@mui/material";
import { useState } from "react";

export const PreviewAmountGeneral: React.FC<any> = (props:any): JSX.Element => {

    const [loading, setLoading] = useState<boolean>(false);
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });
    const data = readLocalStorage('previewAmountGeneral');
    console.log(data?.pdf)

    const [messageRequest, setMessageRequest] = useState<any>(data?.detail || null)
    return (
        <>
        {loading && <Loading/>}
        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>
        
       {messageRequest &&  <Grid container p={2}>
            <Typography  variant='h6' component='h1' gutterBottom className="tittle">Mensaje de la solicitud: {messageRequest}</Typography>
        </Grid>}
        {
            data?.pdf && (
                <Grid container style={{ overflow: 'hidden !important', height:'100%', alignItems: 'center', textAlign: 'center'}}>
                    <iframe src={data?.pdf} id="pdfVer" height="1000px" width="100%"></iframe>
                </Grid>
            )
        }
        </>
    )
}