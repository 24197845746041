import { CostCenterService } from "@/service/services/CostCenter.service";
import { Alert, Snackbar, Typography } from "@mui/material";
import { useState } from "react";
import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalFooter } from "../ModalFooter";
import { ModalHeader } from "../ModalHeader";
import { Props } from "./ModalCostCenterDelete.type";
import { Loading } from "@/components/common/Loading";
import ___PHRASE from "@/service/translate/translateService";
import { apiResponseTranslate } from "@/toolbox/helpers/responseApi.helper";

export const ModalCostCenterDelete: React.FC<Props> = (props:any): JSX.Element => {
    const { open, closeModal, costCenter, onListCostCenter, clearState } = props;
    const [loading, setLoading] = useState<boolean>(false);

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
    });

    const onDeleted= async()=>{
        setLoading(true);
        await CostCenterService.deleteCostCenter(costCenter.id).then((data) => {
            let apimessagge = apiResponseTranslate(data?.data?.message || '');
            setSnackBarConfig(prev => ({ ...prev, open: true, 
              severity:'success', 
              message: `${apimessagge || ___PHRASE("centroDeCostoEliminado")} ` }
            ));
            onListCostCenter && onListCostCenter();
            clearState && clearState();
            closeModal && closeModal();
        })
        setLoading(false);
    }
  
  return (
    <>
    {loading && <Loading/>}
    <Snackbar
        open={snackBarConfig.open} 
        autoHideDuration={snackBarConfig.autoHideDuration} 
        onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
        <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
            {snackBarConfig.message || ""}
        </Alert>
    </Snackbar>
    <Modal
      open={open}
      handleClose={closeModal}
      size="xs"
    >
      <ModalHeader
        text={___PHRASE("eliminar")+' '+___PHRASE("centroDeCosto")}
        className='positionElements'
        onCancel={closeModal}
        clearState={clearState}
      >
      </ModalHeader>

      <ModalBody>
        <Typography variant="subtitle1" className="textColor"> 
          {___PHRASE("estaSeguroDeEliminarEsteCentroDeCosto")}
        </Typography>
      </ModalBody>

      <ModalFooter 
        confirmText={___PHRASE("eliminar")}
        cancelText={___PHRASE("cancelar")}
        onCancel={closeModal}
        className="footerRight"
        onConfirm={onDeleted}
        clearState={clearState}
      />
    </Modal>
    </>
  )
}