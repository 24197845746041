import React, { useEffect, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Loading } from "@components/common/Loading";
import { Alert, Container, Grid, Snackbar, Typography,Button} from "@mui/material";
import { Props } from './LiquidationMasive.type';
import { Select, MenuItem } from '@mui/material';
import {useHistory} from 'react-router-dom'
import * as Routes from '@constants/route-map';
import { PeriodService } from '@/service/services/Period.service';
import { RemunerationService } from '@/service/services/Remuneration.service';
import ___PHRASE from '@/service/translate/translateService';
import { apiResponseTranslate } from '@/toolbox/helpers/responseApi.helper';


export const LiquidationMasiveView: React.FC<Props> = (props: any): JSX.Element => {
   const history= useHistory();
   const [loading, setLoading] = useState<boolean>(false);

   const [periods, setPeriods] = useState<any>([]);
   const [years, setYears] = useState<any>([]);
   const [yearSelected, setYearSelected] = useState<any>('');
   const [monthSelected, setMonthSelected] = useState<any>('');

   const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
   });

   useEffect(()=> {
      getPeriods();
   },[]);

   const getPeriods = async() => {
      setLoading(true);
      const resp:any = await PeriodService.getPeriods();
      const newData = [];
      const arrayYears = [];
      resp.data && (resp.data).length > 0 && (resp.data).map((item) => {
         newData.push({
            id: item.id,
            month: item.month,
            year: item.year,
            alias_name: item.month + '-' +  item.year,
         })
         const found = arrayYears.find(element => element == item.year);
         if(!found) { arrayYears.push(item.year); }
         
      });
      setYears(arrayYears);
      setPeriods(newData || []);
      setLoading(false);
   }

   const showLiquidationMasive = async() => {
      setLoading(true);
      if(!monthSelected || !yearSelected){
         setLoading(false);
         return (
            setSnackBarConfig(prev => ({ ...prev, open: true, 
               severity:'warning', 
               message: ___PHRASE("debeSeleccionarMesyAno") }
            ))
         );
      }

      const periodSelected = monthSelected + '-' + yearSelected;
      const period = periods.find(element => element.alias_name == periodSelected);
      const dataPre = {
         idperiod: period.id,
         month: period.month,
         year: period.year
      }

      const resp:any = await RemunerationService.preRemunerationStoreMassive(dataPre);
     
      let apimessage = apiResponseTranslate(resp?.data?.message)
      if(resp?.data?.status != true){
         setLoading(false);
         return (
            setSnackBarConfig(prev => ({ ...prev, open: true, 
               severity:'error',
               message: `${apimessage || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}` }
            ))
         );
      }

      let detailEmployees = [];
      if(resp.data.detail && (resp.data.detail).length > 0){
         for (let i = 0; i < (resp.data.detail).length; i++) {

            let detailEmployee = resp?.data?.detail[i]?.employee && resp.data.detail[i].employee;

            if(detailEmployee){
               const TDL = resp.data.detail[i].TDL;
               detailEmployee.TDL = TDL;

               const TotalDP = resp.data.detail[i].TDP;
               detailEmployee.TotalDP = TotalDP;

               const TotalDV = resp.data.detail[i].TDV;
               detailEmployee.TotalDV = TotalDV;

               const TotalHINT = resp.data.detail[i].THINT;
               detailEmployee.TotalHINT = TotalHINT;

               const TotalHIT = resp.data.detail[i].THIT;
               detailEmployee.TotalHIT = TotalHIT;

               const TotalHNINT = resp.data.detail[i].THNINT;
               detailEmployee.TotalHNINT = TotalHNINT;
               
               const TotalHNIT = resp.data.detail[i].THNIT;
               detailEmployee.TotalHNIT = TotalHNIT;

               detailEmployees.push(detailEmployee);
            }
         }
      }

      const dataPreMassive = {
         dataMassive: detailEmployees,
         period: period,
      }

      setLoading(false);
      history.push(Routes.ROUTE_LIQUIDATION_MASIVE_PREVIEW, dataPreMassive);
   }

   return (
      <Protected>
         {loading && <Loading />}
         <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                  {snackBarConfig.message || ""}
            </Alert>
         </Snackbar>

         <Container>
            <Grid container spacing={3}>
               <Grid item xs={12} md={12}>
                  <Typography variant='h6' component='h1' gutterBottom className="tittle">
                     {___PHRASE("liquidacionesMasivas")}
                  </Typography>
                  <Grid sx={{ pb: 2 }}>
                     <Typography >{___PHRASE("seleccionePeriodoyMesParaGenerarRemuneracionMasiva")}</Typography>
                  </Grid>
               </Grid>
            </Grid>

            <Grid container xs={12} direction="row" justifyContent="flex-start" alignItems="center">
               <Grid xs={3} display='flex' alignItems="center" 
                  sx={{border: 'solid 1px #e9e2e2', borderRadius:'10px', padding: '5px 8px 8px 8px', marginRight: '10px'}}>
                     <Grid item xs={4}>
                        <Typography variant="body1" style={{marginRight:'10px'}}><b>{___PHRASE("periodo")}:</b> </Typography>
                     </Grid>
                     <Grid item xs={8}>
                        <Select
                           id="id_education_level"
                           variant="standard"
                           value={yearSelected}
                           onChange={(event) => {
                              setYearSelected(event.target.value);
                           }}
                           fullWidth
                        >
                           {
                              years.map((year, i) => {
                                 return <MenuItem key={i} value={year}>{year}</MenuItem>;
                              })
                           }
                        </Select>
                     </Grid>
               </Grid>
               <Grid xs={3} display='flex' alignItems="center" 
                  sx={{border: 'solid 1px #e9e2e2', borderRadius:'10px', padding: '5px 8px 8px 8px', marginRight: '10px'}}>
                     <Grid item xs={4}>
                        <Typography style={{marginRight:'10px'}}variant="body1">{___PHRASE("mes")} </Typography>
                     </Grid>
                     <Grid item xs={8}>
                        <Select
                           id="id_education_level"
                           variant="standard"
                           value={monthSelected}
                           onChange={(event) => {setMonthSelected(event.target.value)}}
                           fullWidth
                        >
                           <MenuItem value={'01'}>{___PHRASE("enero")}</MenuItem>
                           <MenuItem value={'02'}>{___PHRASE("febrero")}</MenuItem>
                           <MenuItem value={'03'}>{___PHRASE("marzo")}</MenuItem>
                           <MenuItem value={'04'}>{___PHRASE("abril")}</MenuItem>
                           <MenuItem value={'05'}>{___PHRASE("mayo")}</MenuItem>
                           <MenuItem value={'06'}>{___PHRASE("junio")}</MenuItem>
                           <MenuItem value={'07'}>{___PHRASE("julio")}</MenuItem>
                           <MenuItem value={'08'}>{___PHRASE("agosto")}</MenuItem>
                           <MenuItem value={'09'}>{___PHRASE("septiembre")}</MenuItem>
                           <MenuItem value={'10'}>{___PHRASE("octubre")}</MenuItem>
                           <MenuItem value={'11'}>{___PHRASE("noviembre")}</MenuItem>
                           <MenuItem value={'12'}>{___PHRASE("diciembre")}</MenuItem>
                        </Select>
                     </Grid>
               </Grid>
               <Grid xs={3} display='flex'>
                  <Button onClick={showLiquidationMasive} >
                     {___PHRASE("siguiente")}
                  </Button>
               </Grid>
            </Grid>
         </Container>
      </Protected>
   )
};