import ___PHRASE from "@/service/translate/translateService";

export function apiResponseTranslate (text: string) {
    const value = text;
    const lower = value?.toLowerCase();
    const notSpace = lower?.replace(/\s+/g, '');
    const notAccent = notSpace.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    const notScript = notAccent.replace(/-/gi, "");
    const noendpoint = notScript?.replace(/\.$/, '');
    const notcommas = noendpoint?.replace(/[,;]/g, '');
    const notparentesis = notcommas?.replace(/\(([^)]+)\)/g, '$1'); //mantiene el valor dentro de los parentesis
    const notsimbolos = notparentesis?.replace(/[<>]/g, ''); // simbolos <>
    const notinitialnumbers = notsimbolos?.replace(/^\d+/, ''); // eliminar numeros iniciales
    const notpoints = notinitialnumbers?.replace(/[.:]/g, ""); // eliminar puntos y dos puntos

     const translateSub = ___PHRASE(notpoints) == notpoints ? value : ___PHRASE(notpoints);
    // console.log(noendpoint)
    // console.log(___PHRASE(noendpoint))

    // console.log(___PHRASE(value))
    // console.log(___PHRASE(___PHRASE(noendpoint)))

    return ___PHRASE(translateSub);
}