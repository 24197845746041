import { Props } from './Advances.type';
import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Loading } from "@components/common/Loading";
import { Box, Breadcrumbs, Container, Grid, Typography } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { WorkerService } from '@/service/services/Worker.service';
import { emphasize, styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import GroupsIcon from '@mui/icons-material/Groups';
import { AdvanceService } from '@/service/services/Advance.service';
import { ModalAdvance } from '@/components/Modal/ModalAdvance';
import { ModalAdvanceDelete } from '@/components/Modal/ModalAdvanceDelete';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_TRANSLATE, KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { RleStructureService } from '@/service/services/RleStructure.service';
import { ROLE_ADMIN } from "@/toolbox/defaults/static-roles";
import { useLocation } from 'react-router-dom';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import ___PHRASE from '@/service/translate/translateService';
import { ROLE_TRABAJADOR } from '@/toolbox/constants/role-type';
import { apiResponseTranslate } from '@/toolbox/helpers/responseApi.helper';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

const Div = styled('div')(({ theme }) => ({
    ...theme.typography.button,
    padding: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: '20px',
    color: 'rgba(102, 102, 102, 0.4)',
}));

const cabecera = [
    { name: 'n_document', label: 'RUT TRABAJADOR' },
    { name: 'surname', label: 'APELLIDO PATERNO' },
    { name: 'second_surname', label: 'APELLIDO MATERNO' },
    { name: 'name', label: 'NOMBRES' }
];

const cabeceraAdvance = [
    { name: 'periodo', label: 'PERIODO' },
    { name: 'value', label: 'VALOR ($)' },
    { name: 'request_status', label: 'ESTADO' },
];

export const AdvancesView: React.FC<Props> = (props:any): JSX.Element => {
    let location = useLocation();
    const dataprops: any = location?.state;
    const idEmployee: any = dataprops?.idEmployee;
    const nameEmployee: any = dataprops?.name;
    const language = readLocalStorage(KEY_TRANSLATE);

    const [loading, setLoading] = useState<boolean>(false);
    const [workers, setWorkers] = useState<any>([]);
    const [workerSelected, setWorkerSelected] = useState<any>('');
    const [advances, setAdvances] = useState<any>([]);
    const [showAdvanceModal, setShowAdvanceModal] = useState<boolean>(false);
    const [showAdvanceDeleteModal, setShowAdvanceDeleteModal] = useState<boolean>(false);
    const [advanceSelected, setAdvanceSelected] = useState<any>('');
    const [actionSelected, setActionSelected] = useState<any>('');
    const [view, setView] = useState<String>('workers');
    const [showAdvances, setShowAdvances] = useState<boolean>(false);

    const [statusEmployee, setStatusEmployee] = useState<any>('0');


    const cabecera = [
        { name: 'n_document', label: ___PHRASE("rutTrabajador") },
        { name: 'surname', label: ___PHRASE("apellidoPaterno") },
        { name: 'second_surname', label: ___PHRASE("apellidoMaterno") },
        { name: 'name', label: ___PHRASE("nombres") }
    ];

  const cabeceraAdvance = [
    { name: 'periodo', label: ___PHRASE("periodo") },
    { name: 'value', label: ___PHRASE("valor") },
    { name: 'request_status', label: ___PHRASE("estado") },
  ];

    const dataUser = readLocalStorage(KEY_USER_DATA);
    const role = dataUser?.user?.role;
    const sessionEmployee = {
        id: dataUser?.user?.idemployee || '',
        date_assignment: dataUser?.user?.date_assignment,
        email: dataUser?.user?.email,
        id_company: dataUser?.user?.id_company,
        idrole: dataUser?.user?.idrole,
        iduser: dataUser?.user?.iduser,
        n_document: dataUser?.user?.n_document || '',
        role: dataUser?.user?.role,
        status: dataUser?.user?.status,
        status_confirm: dataUser?.user?.status_confirm,
        user_name: dataUser?.user?.user_name,
        validation_confirm: dataUser?.user?.validation_confirm,
    };

    useEffect(()=> {
        if(role != 'TRABAJADOR') getWorkers();
    },[]);

    useEffect(()=> {
        if(role == 'TRABAJADOR' && sessionEmployee.id) {
            getDetailEmployeeSO(sessionEmployee?.id);
            setView('advances');
        }
    },[]);

    useEffect(()=> {
        if(workerSelected){
            if(workerSelected.id){
                getDetailEmployeeSO(workerSelected.id);
            }
        }
    },[workerSelected]);

    useEffect(()=> {
       if(dataprops){
            setWorkerSelected({id: idEmployee, name: nameEmployee})
            //   getAdvancesByEmployee(idEmployee)
            getDetailEmployeeSO(idEmployee)
            setShowAdvances(true)
            setView('advances')
       }
    },[dataprops]);

    const getWorkers = async() => {
        setLoading(true);
        const resp:any = await WorkerService.getWorkers();
        const oldData = resp.data || [];
        let newData = [];
        oldData.length > 0 && oldData.map((item) => {
            let obj = {
                ...item,
                name_complete: item.name+' '+item.surname+' '+item.second_surname,
            }
            newData.push(obj);
        });
        setWorkers(newData || []);
        setLoading(false);
    }

    const getAdvancesByEmployee = async(idEmployee) => {
        setLoading(true);
        await AdvanceService.getAdvancesByEmployee(idEmployee).then((data) => {
            const newData = [];
            data.data && (data.data).length > 0 && (data.data).map((item)=>{
                if(language && language == 'en'){
                    newData.push({
                        ...item,
                        request_status: item.request_status == '0' ? ___PHRASE("pendiente") : (item.request_status == '1' ? ___PHRASE('aceptada') : ___PHRASE('rechazada')),
                    })
                }else{
                    newData.push({
                        ...item,
                        request_status: item.request_status == '0' ? 'pendiente' : (item.request_status == '1' ? 'aceptada' : 'rechazada'),
                    })
                }
            })
            setAdvances(newData || []);
            setLoading(false);
        })
        setLoading(false);
    }

    const getDetailEmployeeSO = async (id_employee) => {
        setLoading(true);
        let status = '';
        const resp: any = await RleStructureService.getDetailEmployeeSO(id_employee);
        if (resp.data) {
            const registro_dv4 = resp?.data.filter(code_dv4 => code_dv4.code == 'DV4');
            registro_dv4?.map((item_code) => {
                status = item_code.status;
                setStatusEmployee(item_code.status);
            })
            setLoading(false);
        }else{
            setLoading(false);
        }
        if(status == '1') getAdvancesByEmployee(id_employee);
    }

    function RecuperarData(value){
        if(role == ROLE_ADMIN){
            !workerSelected && setWorkerSelected(value);
            workerSelected && setAdvanceSelected(value);
        }else{
            setAdvanceSelected(value);
        }
        
        setShowAdvances(true);
        setView('advances');
    }
 
    const searchData = async (value) => {
        const resp = await WorkerService.getWorkers(value);
        const oldData = resp.data || [];
        let newData = [];
        oldData.length > 0 && oldData.map((item) => {
            let obj = {
                ...item,
                name_complete: item.name+' '+item.surname+' '+item.second_surname,
            }
            newData.push(obj);
        });
        setWorkers(newData|| []);
    }


    return (
        <Protected>
            {loading && <Loading/>}
            <Container>
            {
                role == ROLE_ADMIN && (
                    <Breadcrumbs aria-label="breadcrumb" sx={{my:3, ml:2}}>
                        <StyledBreadcrumb
                            label={___PHRASE("listaDeEmpleados")}
                            onClick={() => {
                                setView('workers');
                                setWorkerSelected('');
                                setShowAdvances(false);
                                setStatusEmployee('0');
                            }}
                            icon={<GroupsIcon fontSize="small" />}
                        />
                        {
                            showAdvances && (
                                <StyledBreadcrumb
                                    label={___PHRASE("anticipos")}
                                    onClick={() => {
                                        setView('advances')
                                    }}
                                    icon={<GroupsIcon fontSize="small" />}
                                />
                            )
                        }
                    </Breadcrumbs>
                )
            }

            {
                (role == ROLE_ADMIN && view == 'workers') && (
                    <TableDataV2
                        title={___PHRASE("anticipos")}
                        data={workers}
                        header={cabecera}
                        actionSelect={setActionSelected}
                        status_action
                        btn_disabled
                        btn_edit_disable={true}
                        btn_delete_disable={true}
                        btn_helper
                        iconBtnHelper={<RequestQuoteIcon fontSize="small" ></RequestQuoteIcon>}
                        toolTipBtnHelper={___PHRASE("verAnticipos")}
                        currentView={view}
                        textSearch={___PHRASE("buscarTrabajador")}
                        searchData={searchData}
                        RecuperarData={RecuperarData}
                        />
                )
            }
            {
                view == 'advances' && statusEmployee == '1' &&  (
                    <TableDataV2
                        title={role == ROLE_ADMIN ? ___PHRASE("anticiposDe")+` ${workerSelected?.name_complete ? workerSelected?.name_complete : workerSelected?.name}` : ___PHRASE("misanticipos")}
                        data={advances}
                        header={cabeceraAdvance}
                        actionSelect={setActionSelected}
                        setModalSave={setShowAdvanceModal}
                        setModalDelete={setShowAdvanceDeleteModal}
                        status_action
                        disabled_title={false}
                        btn_disabled={false}
                        btn_edit_disable={false}
                        btn_delete_disable={false}
                        currentView={view}
                        RecuperarData={RecuperarData}
                    />
                )
            }
            {
                view == 'advances' && statusEmployee == '0' &&  (
                    <>
                        <Typography variant='h6' component='h1' gutterBottom className="tittle" sx={{ mt: 1 , fontWeight:600, marginLeft:'10px'}}>
                            {role == ROLE_ADMIN ? (___PHRASE("anticiposDe")+` ${workerSelected?.name_complete ? workerSelected?.name_complete : workerSelected?.name}`).toUpperCase() : ___PHRASE("misanticipos")}
                        </Typography>
                        {
                            loading && (
                                <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'150px', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '45px' }}>
                                        <Div>{___PHRASE("cargandoDatosEspereUnMomento")}</Div>
                                </Box>
                            )
                        }
                        {
                            !loading && (
                                <Grid item xs={12} container alignItems="center" justifyContent="space-between" sx={{pamarginTop:'20px'}} >
                                    <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'150px', justifyContent: 'center', alignItems: 'center', display: 'flex', width:'100%'}}>
                                        {
                                            role == ROLE_TRABAJADOR ? (
                                                <Div>{___PHRASE("noPuedeSolicitarAnticiposComuniqueseConSuAdministrador")}</Div>
                                            ) : (
                                                <>
                                                <Grid sx={{textAlign: 'center'}}>
                                                <Div>{___PHRASE("elEmpleadoNoTienePermisoParaSolicitarAnticipos")}</Div>
                                                <Typography variant="subtitle2" className="custom-input" sx={{ color: '#86878d'}}>
                                                   {`-> ${___PHRASE("asegureseQueTengaAsignadoDescuentoPorAnticipos")} "DV4"`}
                                                </Typography>
                                                </Grid>
                                                </>
                                            )
                                        }
                                    </Box>
                                </Grid>
                            )
                        }
                    </>
                )
            }
            </Container>

            { 
                showAdvanceModal && (
                    <ModalAdvance
                        open={showAdvanceModal}
                        closeModal={()=>{setShowAdvanceModal(false)}}
                        actionSelected={actionSelected}
                        onListAdvances={getAdvancesByEmployee}
                        workerSelected={workerSelected || sessionEmployee}
                        clearState={()=>{setAdvanceSelected('')}}
                        advance={advanceSelected ? {
                            ...advanceSelected,
                            request_status: advanceSelected.request_status == 'Pending' ? "pendiente" : (advanceSelected.request_status == 'accepted' ? 'aceptada' : 'rechazada'),
                        } : advanceSelected}
                    />
                )
            }
            {
                actionSelected === 'delete' && (
                    <ModalAdvanceDelete
                        open={showAdvanceDeleteModal}
                        closeModal={()=>{setShowAdvanceDeleteModal(false)}}
                        actionSelected={actionSelected}
                        onListAdvances={getAdvancesByEmployee}
                        workerSelected={workerSelected || sessionEmployee}
                        clearState={()=>{setAdvanceSelected('')}}
                        advance={advanceSelected ? {
                            ...advanceSelected,
                            request_status: advanceSelected.request_status == 'Pending' ? "pendiente" : (advanceSelected.request_status == 'accepted' ? 'aceptada' : 'rechazada'),
                        } : advanceSelected}
                    />
                )
            }
        
        </Protected>
    )
};