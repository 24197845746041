import React, { useEffect, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Props } from './User.type'
import { Container } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { userService } from '@/service/services/User.service';
import { Loading } from '@/components/common/Loading';
import { ModalUser } from '@/components/Modal/ModalUser';
import { KEY_TRANSLATE, KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { ROLE_ADMIN, ROLE_TRABAJADOR } from '@/toolbox/defaults/static-roles';
import { ROLE_SUPER_ADMIN } from '@/toolbox/constants/role-type';
import ___PHRASE from '@/service/translate/translateService';
import { ModalUserDelete } from '@/components/Modal/ModalUserDelete';
import { apiResponseTranslate } from '@/toolbox/helpers/responseApi.helper';

export const UsersView: React.FC<Props> = (props:any): JSX.Element => {
   const [loading, setLoading] = useState<boolean>(false);
   const [users, setUsers]= useState([]);
   const [showUserModal, setShowUserModal] = useState<boolean>(false);
   const [actionSelected, setActionSelected] = useState<String>('');
   const [userSelected, setUserSelected] = useState<String>('');
   const [showDeleteUserModal, setShowDeleteUserModal] = useState<boolean>(false)

   const language = readLocalStorage(KEY_TRANSLATE);
   const dataUser = readLocalStorage(KEY_USER_DATA);
   const role = dataUser?.user?.role;
   const typeSuperadmin = dataUser?.user?.type_superadmin;
   const groupCompanies = dataUser?.user?.group_companies;

   useEffect(() => {
     getUsers();
     
   },[]);

   const filterUSers = (users) => {
      let newArray = [];
      let arrayTemporal = [];

      for(let i=0; i<users.length; i++){
         arrayTemporal = newArray.filter(resp => resp["iduser"] == users[i]["iduser"]);

         if(arrayTemporal.length > 0){
            newArray[newArray.indexOf(arrayTemporal[0])]["roles"].push(users[i]["role"])
         }else{
            newArray.push(
            {
               'id' : i+1,
               'email' : users[i]["email"] , 
               'employee' : users[i]["employee"] , 
               'idemployee' : users[i]["idemployee"] , 
               'idprofile' : users[i]["idprofile"] , 
               'iduser' : users[i]["iduser"] , 
               'idcompany' : users[i]["idcompany"],
               'group_companies' : users[i]["group_companies"],
               'type_superadmin' : users[i]["type_superadmin"],
               'companies_superadmin' : users[i]['companies_superadmin'],
               'n_document' : users[i]["n_document"] , 
               'profile' : users[i]["profile"] , 
               'user_name' : users[i]["user_name"] , 
               'roles' : [users[i]["role"]],
               'assign_ip': users[i]['assign_ip'],
            }
            )
         }
      }

      return newArray || [];
   }

   const getUsers = async () => {
      setLoading(true);
      
      if(role == ROLE_SUPER_ADMIN){
         const response = await userService.getUsers();

         if (response.data && (response.data).length > 0) {
            const newArray = filterUSers(response.data);
            let newUsers = [];

            let dataTranslate = [];

            newArray.length > 0 && newArray.map((item)=> {
               const validate = (item.roles).find(role => role == ROLE_ADMIN)
               if(validate) newUsers.push({...item, 'roles': 'ADMINISTRADOR'});
               const validateSuperAdmin = (item.roles).find(role => role == ROLE_SUPER_ADMIN)
               if(validateSuperAdmin) newUsers.push({...item, 'roles': 'SUPERADMIN'});
            })

            newUsers && (newUsers).length > 0 && (newUsers).map((item)=>{
               if(language && language == 'en'){
                  dataTranslate.push({
                     ...item, 
                     profile: item?.profile && apiResponseTranslate(item.profile),
                     roles: item?.roles && apiResponseTranslate(item.roles),
                     rolesArray: (item.rolesArray && (item.rolesArray).length > 0) && (item.rolesArray).map((role) => apiResponseTranslate(role)),
                  })
               }else{
                  dataTranslate.push(item)
               }
            });

            setFilterUser(dataTranslate || [])

        
         }
      }else{
         const response = await userService.getUserByCompany();
         let newUsers = [];
         let dataTranslate = [];
         if (response.data && (response.data).length > 0) {
            const newArray = filterUSers(response.data);
            newArray.length > 0 && newArray.map((item)=> {
               const validate = (item.roles).find(role => role == ROLE_ADMIN || role == ROLE_TRABAJADOR)
               if(validate) newUsers.push({...item, 'roles': (item.roles).toString(), 'rolesArray': item.roles});
            })
           
         }

         newUsers && (newUsers).length > 0 && (newUsers).map((item)=>{
            if(language && language == 'en'){
               dataTranslate.push({
                  ...item, 
                  profile: item?.profile && apiResponseTranslate(item.profile),
                  roles: item?.roles && apiResponseTranslate(item.roles),
                  rolesArray: (item.rolesArray && (item.rolesArray).length > 0) && (item.rolesArray).map((role) => apiResponseTranslate(role)),
               })
            }else{
               dataTranslate.push(item)
            }
         });

         setUsers(dataTranslate || [])
      }
      
      setLoading(false);
   }

   const setFilterUser = (dataUser) => {
      if(typeSuperadmin == '2'){
         const filteredCompanies = dataUser.filter(user => groupCompanies.includes(user.idcompany));
         setUsers(filteredCompanies)
     }else{
         setUsers(dataUser)
     }  
   }

   function RecuperarData(value){
      setUserSelected(value);
   }

   const searchData = async (value) => {
      if(role == ROLE_SUPER_ADMIN){
         const response = await userService.getUsers(value);
         if (response.data && (response.data).length > 0) {
            let newUsers = [];
            let dataTranslate = [];
            const newArray = filterUSers(response.data)
            newArray.length > 0 && newArray.map((item)=> {
               const validate = (item.roles).find(role => role == ROLE_ADMIN)
               if(validate) newUsers.push({...item, 'roles': 'ADMINISTRADOR'});
               const validateSuperAdmin = (item.roles).find(role => role == ROLE_SUPER_ADMIN)
               if(validateSuperAdmin) newUsers.push({...item, 'roles': 'SUPERADMIN'});
            })
            newUsers && (newUsers).length > 0 && (newUsers).map((item)=>{
               if(language && language == 'en'){
                  dataTranslate.push({
                     ...item, 
                     profile: item?.profile && apiResponseTranslate(item.profile),
                     roles: item?.roles && apiResponseTranslate(item.roles),
                     rolesArray: (item.rolesArray && (item.rolesArray).length > 0) && (item.rolesArray).map((role) => apiResponseTranslate(role)),
                  })
               }else{
                  dataTranslate.push(item)
               }
            });
            setFilterUser(dataTranslate || [])
         }
      }else{
         const response = await userService.getUserByCompany(value);
         if (response.data && (response.data).length > 0) {
            let newUsers = [];
            let dataTranslate = [];
            const newArray = filterUSers(response.data)
            newArray.length > 0 && newArray.map((item)=> {
               const validate = (item.roles).find(role => role == ROLE_ADMIN || role == ROLE_TRABAJADOR)
               if(validate) newUsers.push({...item, 'roles': (item.roles).toString(), 'rolesArray': item.roles});
            })
            newUsers && (newUsers).length > 0 && (newUsers).map((item)=>{
               if(language && language == 'en'){
                  dataTranslate.push({
                     ...item, 
                     profile: item?.profile && apiResponseTranslate(item.profile),
                     roles: item?.roles && apiResponseTranslate(item.roles),
                     rolesArray: (item.rolesArray && (item.rolesArray).length > 0) && (item.rolesArray).map((role) => apiResponseTranslate(role)),
                  })
               }else{
                  dataTranslate.push(item)
               }
            });
            setUsers(dataTranslate || [])
         }
      }
   }

   const header = [
      { name: 'user_name', label: ___PHRASE("nombreDeUsuario"), filter: false, Chip: false },
      { name: 'n_document', label: ___PHRASE("nDocumento"), filter: false, Chip: true },
      { name: 'email', label: ___PHRASE("correo"), filter: false, Chip: false },
      { name: 'roles', label: 'Roles', filter: false, Chip: true },
      { name: 'profile', label: ___PHRASE("perfil"), filter: false, Chip: true },
   ]

   const headerSuperAdmin = [
      { name: 'user_name', label: ___PHRASE("nombreDeUsuario"), filter: false, Chip: false },
      { name: 'n_document', label: ___PHRASE("nDocumento"), filter: false, Chip: true },
      { name: 'email', label: ___PHRASE("correo"), filter: false, Chip: false },
      { name: 'roles', label: 'Roles', filter: false, Chip: true },
      // { name: 'profile', label: ___PHRASE("perfil"), filter: false, Chip: true },
   ]

  return (
      <Protected>
         {loading && <Loading/>}
         <Container>
            <TableDataV2
               title={role === ROLE_SUPER_ADMIN ? ___PHRASE("usuariosAdmin") : ___PHRASE("usuarios")}
               data={users}
               header={role === ROLE_SUPER_ADMIN ? headerSuperAdmin: header}
               actionSelect={setActionSelected}
               setModalSave={setShowUserModal}
               setModalHelper={setShowUserModal}
               setModalDelete={setShowDeleteUserModal}
               btn_edit_disable={(role && (role == ROLE_ADMIN || ROLE_SUPER_ADMIN)) ? false : true}
               // btn_helper={(role && role == ROLE_SUPER_ADMIN) ? true : false}
               status_action
               RecuperarData={RecuperarData}
               textSearch={___PHRASE("buscarUsuarios")}
               searchData={searchData}
            />
         </Container>

         { 
            showUserModal && (
               <ModalUser
                  open={showUserModal}
                  closeModal={()=>{setShowUserModal(false)}}
                  actionSelected={actionSelected}
                  onListUsers={getUsers}
                  user={userSelected}
                  clearState={()=>{setUserSelected('')}}
               />
            )
         }

         {
                actionSelected === 'delete' && (
                <ModalUserDelete
                    open={showDeleteUserModal}
                    closeModal={()=>{setShowDeleteUserModal(false)}}
                    actionSelected={actionSelected}
                    onListUsers={getUsers}
                    user={userSelected}
                    clearState={()=>{setUserSelected('')}}
                    role={role}
                />
                )
            }
      
      </Protected>
   )
};