import { KEY_TRANSLATE } from "@/toolbox/constants/local-storage";
import { lang } from "./dictionary"
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";

const ___PHRASE = (name) => {
  const language = readLocalStorage(KEY_TRANSLATE);
  let translate = lang[name] ? lang[name][language] : name;  
  return translate;
}

export default ___PHRASE