import { DigitalSignatureRepository } from "../repositories/digitalSignature.repository";

export const DigitalSignatureService = {
    create,
}

async function create(data){
    const res = await DigitalSignatureRepository.create(data);
    return res;
}


