import React, { useEffect, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Loading } from "@components/common/Loading";
import { Container } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { AfpService } from '@/service/services/Afp.service';
import { Props } from './AFP.type';
import ___PHRASE from '@/service/translate/translateService';
import moment from 'moment'
import { ModalAFP } from '@/components/Modal/ModalAFP';
import { ModalAFPDelete } from '@/components/Modal/ModalAFPDelete';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { ROLE_SUPER_ADMIN } from '@/toolbox/defaults/static-roles';


export const AFPView: React.FC<Props> = (props: any): JSX.Element => {

    const [loading, setLoading] = useState<boolean>(false);
    const [afp, setAFP] = useState<any>([]);
    const [afpType, setAFPType] = useState<any>([]);
    const [showAFPModal, setShowAFPModal] = useState<boolean>(false);
    const [showAFPDeleteModal, setShowAFPDeleteModal] = useState<boolean>(false);
    const [actionSelected, setActionSelected] = useState<any>('');
    const [afpSelected, setAFPSelected] = useState<any>('');
    const [year, setYear] = useState<any>([]);
    moment.locale('es')

    const dataUser = readLocalStorage(KEY_USER_DATA);
   const role = dataUser?.user?.role;
   const typeSuperadmin = dataUser?.user?.type_superadmin;

    const headerAFP = [
        { name: 'afpcodigo', label: ___PHRASE("codigo"), filter: false, Chip: true },
        { name: 'afpname', label: ___PHRASE("AFP"), filter: false, Chip: true },
        { name: 'cot', label: "Cot", filter: false, Chip: true },
        { name: 'sis', label: "Sis", filter: false, Chip: true },
        { name: 'year', label: ___PHRASE("periodo"), filter: false, Chip: false },
        { name: 'nameMonth', label: ___PHRASE("mes"), filter: false, Chip: false },
    ]

    useEffect(() => {
        getDetailAFP();
        getTypeAFP()
    }, []);


    const getDetailAFP = async () => {
        setLoading(true);
    
        await AfpService.getDetailAfp().then(async (data) => {
            const oldData = data.data || []
            let newData = [];
            oldData.length > 0 && oldData.map((item) => {
                const [ano, mes, dia] = item.date.split("-");
                const date = new Date(ano, mes - 1, dia);
                const nameMonth = date.toLocaleString('es-CL', { month: 'long' });

                let obj = {
                    id: item.id,
                    idafp: item.idafp,
                    afpcodigo: item.afpcodigo,
                    afpname: item.afpname,
                    cot: item.cot,
                    sis: item.sis,
                    year: date.getFullYear(),
                    month: date.getMonth() + 1,
                    nameMonth: ___PHRASE(nameMonth),
                    status: item.status,
                }
                newData.push(obj);
            });
            setAFP(newData || [])

            await AfpService.getYearDetailAFP().then((data) =>{
                setYear(data.data)
            })
        })
        setLoading(false);
    }

    const getTypeAFP = async () => {
        await AfpService.getAfpType().then((data) => {
            setAFPType(data.data)
        })
    }

    function RecuperarData(value){
        setAFPSelected(value);
    }

    return (
        <Protected>
            {loading && <Loading/>}

            <Container>
                <TableDataV2
                title={___PHRASE("variablesAFP")}
                data={afp || []}
                header={headerAFP}
                actionSelect={setActionSelected}
                setModalSave={setShowAFPModal}
                setModalDelete={setShowAFPDeleteModal}
                btn_disabled={ role == ROLE_SUPER_ADMIN && typeSuperadmin == '1' ? false : true}
                btn_delete_disable={ role == ROLE_SUPER_ADMIN && typeSuperadmin == '1' ? false : true}
                status_action = { role == ROLE_SUPER_ADMIN && typeSuperadmin == '1' ? true : false}
                RecuperarData={RecuperarData}
                />
            </Container>

            {
                showAFPModal && (
                <ModalAFP
                    open={showAFPModal}
                    closeModal={()=>{setShowAFPModal(false)}}
                    actionSelected={actionSelected}
                    onListAFP={getDetailAFP}
                    afp={afpSelected}
                    afpType={afpType}
                    year={year}
                    clearState={()=>{setAFPSelected('')}}
                />
                )
            }
            {
                actionSelected === 'delete' && (
                <ModalAFPDelete
                    open={showAFPDeleteModal}
                    closeModal={()=>{setShowAFPDeleteModal(false)}}
                    actionSelected={actionSelected}
                    onListAFP={getDetailAFP}
                    afp={afpSelected}
                    clearState={()=>{setAFPSelected('')}}
                />
                )
            }
        </Protected>
    )
};