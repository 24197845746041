import React, { useEffect, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Loading } from '@/components/common/Loading';
import { Alert,  Box,  Button,  Card,  Container,  Grid,  Snackbar, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { ProductService } from '@/service/services/Products.service';
import { moneyFormat } from '@/toolbox/helpers/money.helper';
import ___PHRASE from '@/service/translate/translateService';
import { TableDataV2 } from '@/components/common/Tablev2';
import { apiResponseTranslate } from '@/toolbox/helpers/responseApi.helper';

const Div = styled('div')(({ theme }) => ({
    ...theme.typography.button,
    padding: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: '20px',
    color: 'rgba(102, 102, 102, 0.4)',
}));


export const ReportProducts: React.FC<any> = (props: any): JSX.Element => {
   
    const [loading, setLoading] = useState<boolean>(false);
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const [reports, setReports] = useState<any>([]);

    useEffect(()=> {
        getReportEmployee();
     },[]);

     const downloadExcel = async() => {
        setLoading(true)
        const respExcel = await ProductService.inventaryGeneralProductExcel();

        const btn_excel = document.createElement("a")
        btn_excel.href = `${respExcel.data.link}`
        btn_excel.download = 'inventario.xlsx'

        document.body.appendChild(btn_excel);
        btn_excel.click();
        document.body.removeChild(btn_excel);
        setLoading(false);
    }

    const getReportEmployee = async () => {

        setLoading(true);

        await ProductService.inventaryGeneralProduct().then((data) => {
            let apimessagge = apiResponseTranslate(data?.data?.message || '');
            if(data.data.status == false){
                setLoading(false);
                return (
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'warning', 
                        message: `${apimessagge || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}` }
                    ))
                );
            }
            setReports(data.data || []);
            setLoading(false);
        })
    }

    return (
        <Protected>
            {loading && <Loading/>}
            <Snackbar
                open={snackBarConfig.open} 
                autoHideDuration={snackBarConfig.autoHideDuration} 
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
                <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                    {snackBarConfig.message || ""}
                </Alert>
            </Snackbar>
            <Container>
                <Typography variant='h6' component='h1' gutterBottom className="tittle" sx={{ m: 1, fontWeight: 600 }}>
                    {___PHRASE("reportemateriales")}
                </Typography>

                {
                    (reports.length == 0 && !loading) && (
                        <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'150px', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '20px' }} >
                            <Div>{___PHRASE("noSeEncontraronDatos")}</Div>
                        </Box>
                    )
                }
                {
                    loading && (
                        <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'150px', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '20px' }}>
                            <Div>{___PHRASE("cargandoDatosEspereUnMomento")}</Div>
                        </Box>
                    )
                }
                
                {/* {
                    (reports.length > 0 && !loading) && reports.map((item)=>(
                        <>
                        <Grid container sx={{ width: "100%"}}>
                            <Card sx={{ width: "100%", marginTop: '20px', padding: '5px, 10px, 10px, 10px', marginBottom: '20px'}}>
                                <Grid sx={{ width: "100%", padding: '20px 20px 10px 20px'}}>

                                    <Typography variant="h6" sx={{ fontWeight:'bold', color:'#16556b' }}>
                                        {(`${item.name}`).toUpperCase()}
                                    </Typography>

                                    <Typography variant="subtitle1" sx={{ pt: 1 }}>
                                    {`${(___PHRASE("marca")+':').toUpperCase()} ${item.brand || ___PHRASE("sinInformacion")}`}
                                    </Typography>

                                    <Typography variant="subtitle1" sx={{ pt: 1 }}>
                                    {`${(___PHRASE("modelo")+':').toUpperCase()} ${item.purchase?.model || ___PHRASE("sinInformacion")}`}
                                    </Typography>

                                    <Typography variant="subtitle1" sx={{ pt: 1 }}>
                                        {`${(___PHRASE("fechaDeCompra")+':').toUpperCase()} ${item.purchase?.date_purchase || '---'}`}
                                    </Typography>

                                    <Grid display='flex' sx={{ width: "100%"}}>
                                        <Typography variant="subtitle1" sx={{ pt: 1, marginRight: '40px' }}>
                                            {`${(___PHRASE("precioDeCompra")+':').toUpperCase()} ${moneyFormat(parseFloat(item.purchase?.net_cost  || 0))}`}
                                        </Typography>
                                        <Typography variant="subtitle1" sx={{ pt: 1 }}>
                                            {`${(___PHRASE("estadoDelProducto")+':').toUpperCase()} ${item.purchase?.status_initial || '---'}`}
                                        </Typography>
                                    </Grid>
                                
                                </Grid>
                                {
                                    (item.detalle).length > 0 ? (
                                        <Grid sx={{ width: "100%", padding: '10px 20px 20px 20px'}}>
                                            <Typography variant='h6' component='h2' gutterBottom className="tittle" sx={{fontWeight: 600 }}>
                                                {(___PHRASE("movimientosDelMaterial")+':').toUpperCase()}
                                            </Typography>

                                            <TableContainer>
                                                <Table>
                                                    <TableHead sx={{ bgcolor: '#EDEFF1' }}>
                                                        <TableRow >
                                                            <TableCell >
                                                                <Typography>{___PHRASE("tipoDeMovimiento")}</Typography>
                                                            </TableCell>
                                                            <TableCell >
                                                                <Typography>{___PHRASE("fecha")}</Typography>
                                                            </TableCell>
                                                            <TableCell >
                                                                <Typography>{___PHRASE("fechaAcordadaDeRetorno")}</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>

                                                    <TableBody>
                                                        {
                                                            (item.detalle).map((detail)=>(
                                                                <TableRow >
                                                                    <TableCell size={'small'}>
                                                                        {detail.type_movement}
                                                                    </TableCell>
                                                                    <TableCell size={'small'}>
                                                                        {detail.date || ___PHRASE("sinInformacion")}
                                                                    </TableCell>
                                                                    <TableCell size={'small'}>
                                                                        {detail.agreed_return_date || ___PHRASE("sinInformacion")}
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))
                                                        }
                                                        
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Grid>
                                    ) : (
                                        <Grid item xs={12} container alignItems="center" marginBottom='20px' paddingLeft='20px'>
                                        <Typography variant="subtitle2" className="custom-input" sx={{ border: '1px #e37474 solid', borderRadius:'8px', padding: '5px', color:'#df5959'}}
                                        >
                                            {___PHRASE("noSeEncontraronMovimientos")}
                                        </Typography>
                                    </Grid>
                                    )
                                }
                            </Card>
                        </Grid>
                        </>
                    ))
                } */}

                {
                    (reports.length > 0 && !loading) &&
                    <>

                        <Grid container spacing={3} sx={{ marginTop: '10px', marginBottom: '20px', paddingRight:'30px' }}>
                            <Grid item xs={12} md={12} display='flex' justifyContent='flex-end'>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => downloadExcel()}
                                >{___PHRASE("descargarExcel")}
                                </Button>
                            </Grid>
                        </Grid>
                        <TableDataV2
                            data={reports}
                            header={[
                                { name: 'name', label: ___PHRASE("nombre"), filter: false, Chip: true },
                                { name: 'brand', label: ___PHRASE("marca"), filter: false, Chip: true },
                                { name: 'purchase_count', label: ___PHRASE("cantidadd"), filter: false, Chip: true },
                                { name: 'net_cost_sum', label: ___PHRASE("precioDeCompra"), filter: false, Chip: true },
                            ]}
                            btn_disabled
                        />
                    </>
                }

            </Container>
        </Protected>
    )
};
