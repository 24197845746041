import { Alert, Snackbar, Typography } from "@mui/material";
import { useState } from "react";
import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalFooter } from "../ModalFooter";
import { ModalHeader } from "../ModalHeader";
import { Loading } from "@/components/common/Loading";
import { Props } from "./ModalFamilyEmployeeDelete.type";
import { responsabilityFamilyService } from "@/service/services/ResponsabilityFamily.service";
import ___PHRASE from "@/service/translate/translateService";
import { apiResponseTranslate } from "@/toolbox/helpers/responseApi.helper";

export const ModalFamilyEmployeeDelete: React.FC<Props> = (props:any): JSX.Element => {
    const { open, closeModal, family, onListFamilies, workerSelected, clearState } = props;
    const [loading, setLoading] = useState<boolean>(false);

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
      open: false,
      severity: 'error',
      message: 'Error',
      autoHideDuration: 3000,
    });

    const onDeleted= async()=>{
        setLoading(true);
        await responsabilityFamilyService.deleteResponsabilityFamily(family.id).then((data) => {
            let apimessagge = apiResponseTranslate(data?.data?.message || '');
            setSnackBarConfig(prev => ({ ...prev, open: true, 
              severity:'success', 
              message: `${ apimessagge || ___PHRASE("eliminadoCorrectamente")}` }
            ));
            onListFamilies && onListFamilies(workerSelected.id);
            setTimeout(function(){
                closeModal && closeModal();
                clearState && clearState();
            }, 1000);
        })
        setLoading(false);
    }
  
  return (
    <>
    {loading && <Loading/>}
    <Snackbar
        open={snackBarConfig.open} 
        autoHideDuration={snackBarConfig.autoHideDuration} 
        onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
        <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
            {snackBarConfig.message || ""}
        </Alert>
    </Snackbar>

    <Modal
      open={open}
      handleClose={closeModal}
      size="xs"
      disableEscapeKeyDown disableBackdropClick
    >
        <ModalHeader
            text={___PHRASE("eliminarFamiliar")}
            className='positionElements'
            onCancel={closeModal}
            clearState={clearState}
        >
        </ModalHeader>

        <ModalBody>
            <Typography variant="subtitle1" className="textColor"> 
            {___PHRASE("estaSeguroDeEliminarAlFamiliar")}
            </Typography>
        </ModalBody>

        <ModalFooter 
        confirmText={___PHRASE("eliminar")}
        cancelText={___PHRASE("cancelar")}
        onCancel={closeModal}
        className="footerRight"
        onConfirm={onDeleted}
        clearState={clearState}
        />

    </Modal>
    </>
  )
}