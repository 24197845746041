
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Typography, Grid, Badge, Select } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { bgBlur } from './Header-styles';
import AccountPopover from './AccountPopover'
import Paper from '@mui/material/Paper';
import NotificationsIcon from "@mui/icons-material/Notifications";
import { readLocalStorage, saveLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import { KEY_ARRAY_ROLES, KEY_TRANSLATE, KEY_USER_DATA, KEY_USER_DATA_OPTIONS } from '@/toolbox/constants/local-storage';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROLE_ADMIN, ROLE_SUPER_ADMIN, ROLE_TRABAJADOR } from '@/toolbox/constants/role-type';
import { ROUTE_COMPANIES, ROUTE_MY_VALIDATIONS, ROUTE_USER } from '@/toolbox/constants/route-map';
import { NotificationPopover } from './components';
import LanguageIcon from '@mui/icons-material/Language';
import ___PHRASE from '@/service/translate/translateService';
// import { ComboBox } from '../ComboBox/ComboBox';


const NAV_WIDTH = 350;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  //...bgBlur({ color: theme.palette.background.default }),
  backdropFilter: `blur(6px)`,
  WebkitBackdropFilter: `blur(6px)`,
  backgroundColor: '#fff',
  //  alpha('#fff', 0.8),
  paddingTop: 15,
  boxShadow: 'none',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
  //color: theme.palette.text.secondary,
}));


const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));



// ----------------------------------------------------------------------

// HeaderView.propTypes = {
//   onOpenNav: PropTypes.func,
// };
export const HeaderView = (props: any): JSX.Element => {

  
  const arrayRoles = readLocalStorage(KEY_ARRAY_ROLES);

  const ArrayData = readLocalStorage(KEY_USER_DATA);

  const ArrayDataOptions = readLocalStorage(KEY_USER_DATA_OPTIONS);

  const role = ArrayData?.user?.role;
  const [currentRole, setCurrentRole] = useState(role || '');

  const language = readLocalStorage(KEY_TRANSLATE);
  
  const changeTranslate = (event) => {      
    props.$action.actionSetUpdateTranslate(true);
    props.$action.actionSetTranslate(event.target.value);  
    props.$action.actionSetUpdateTranslate(false);
  }
  
  const changeRol = (event) => {
    
    const roleSelect = event.target.value;
    const userSelected = ArrayDataOptions.find(option => option.roleName == roleSelect);

    const newRole = arrayRoles && arrayRoles.find(role => roleSelect == role.name);

    setCurrentRole(roleSelect);

    const newObj = {
      ...ArrayData,
      'user': {...ArrayData.user, role: newRole.name, idrole: newRole.id, idemployee:userSelected ? userSelected.idemployee : ''},
    }

    saveLocalStorage(KEY_USER_DATA, newObj);

    switch (newRole.name) {
      case ROLE_SUPER_ADMIN:
          window.location.replace(ROUTE_COMPANIES);
          break;

      case ROLE_ADMIN:
          window.location.replace(ROUTE_USER);
          break;

      case ROLE_TRABAJADOR:
          window.location.replace(ROUTE_MY_VALIDATIONS);
          break;
      }
  }

  return (
    <header>
      <StyledRoot>
        <StyledToolbar>
          <IconButton
            //  onClick={}
            sx={{
              mr: 1,
              color: 'text.primary',
              display: { lg: 'none' },
            }}
          >
            {/* <Iconify icon="eva:menu-2-fill" /> */}
            <MenuIcon />
          </IconButton>
          {
            arrayRoles && arrayRoles.length > 1 ? (
              <Grid sx={{display: 'flex', alignItems: 'center', padding:'10px', border: 'solid 1px #b9b1a9', borderRadius:'8px'}}>
                <Typography sx={{ color: 'black' }} variant="subtitle1"><b>{ ___PHRASE("cambiarRol")}:</b> </Typography>
                  <Select
                    id="idexpense_report_type1"
                    variant="standard"
                    value={currentRole}
                    onChange={(event) => {
                      changeRol(event)
                    }}
                    size='small'
                    sx={{width: '250px', marginLeft:'20px'}}
                  >
                      {
                        arrayRoles.map((role, i) => {
                            return <MenuItem key={i} value={role.name}>{role.name}</MenuItem>;
                        })
                      }
                  </Select>
              </Grid>

            ) : (
              <Typography sx={{ color: "#3b3b3b", fontWeight: 700 }} variant={"h5"} >
                {___PHRASE("hola")}, {___PHRASE("bienvenido")} 👋 
              </Typography>
            )
          }


          
          {<Box sx={{ flexGrow: 1 }} />}

          <Grid item xs={2} md={6} mt={2} >
            <Grid display="flex" justifyContent="flex-end" alignItems="center" container spacing={1} direction="row">
              <LanguageIcon color="primary" sx={{ marginRight: "5px" }}/>
              <Select
                id="id_education_level"
                variant="standard"
                value={language}
                onChange={(event) => {changeTranslate(event)}}
                sx={{width:100}}
              >
                <MenuItem value={'es'}>Español</MenuItem>
                <MenuItem value={'en'}>English</MenuItem>
              </Select>

              {/*Icono Notificacion*/}
              {/* <IconButton
                sx={{ marginLeft: "5px" }}
                aria-label="delete"
                size="large"
              >
                <Badge badgeContent={0} color="primary">
                  <NotificationsIcon sx={{ color: "#28c4ac" }} fontSize="inherit" />
                </Badge>
              </IconButton> */}
              <NotificationPopover/>

              {/*Icono User*/}
              <IconButton>
                <AccountPopover />
              </IconButton>



            </Grid>


            {/*Combo Box*/}

          </Grid>
          {/*<Stack
            direction="row"
            alignItems="center"
            spacing={0.5}
            >
              <Item><NotificationsIcon sx={{ color: "#28c4ac" }}/></Item>
              <Item><AccountPopover/></Item>
          </Stack>*/}

          {/* <LanguagePopover /> */}
          {/* <NotificationsPopover /> */}
        </StyledToolbar>
      </StyledRoot>
    </header>
  );
}
