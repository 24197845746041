import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik } from 'formik';
import { useEffect, useState } from "react";
import { Alert, Button, Divider, Grid, InputAdornment, MenuItem, Select, Snackbar, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import { Loading } from "@/components/common/Loading";
import { Props } from "./ModalExpenseEmployeeDetail.type";
import { EmployeeExpenseService } from "@/service/services/EmployeeExpense.service";
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { KEY_TRANSLATE, KEY_USER_DATA } from "@/toolbox/constants/local-storage";
import { ModalExpenseConfirm } from "../ModalExpenseConfirm";
import { ROLE_ADMIN, ROLE_TRABAJADOR } from "@/toolbox/defaults/static-roles";
import { typesExpenseService } from "@/service/services/TypesExpense.service";
import { companiesService } from "@/service/services/Companies.service";
import moment from 'moment-timezone';
import ___PHRASE from "@/service/translate/translateService";
import { apiResponseTranslate } from "@/toolbox/helpers/responseApi.helper";
import '../../../assets/styles/modals.scss';

export const ModalExpenseEmployeeDetail: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, detail, onListDetails, expenseSelected, clearState } = props;

    const language = readLocalStorage(KEY_TRANSLATE);
    const dataUser = readLocalStorage(KEY_USER_DATA);
    const role = dataUser?.user?.role;

    const [loading, setLoading] = useState<boolean>(false);
    const [expenseReportType, setExpenseReportType] = useState<any>([]);
    const [currentCv, setCurrentCv] = useState<any>(null); 
    const [reviewStatus, setReviewStatus] = useState<any>(''); 
    const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);
    const [coverageCompany, setCoverageCompany] = useState<boolean>(false);
    const [dataDetail, setDataDetail] = useState<any>(''); 
    const [mecompany, setMeCompany] = useState<any>([]);

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const [data, setData] = useState<any>({
        id: detail ? detail.id : '',
        idexpense_report_type: detail ? detail.idexpense_report_type : '',
        detail: detail ? detail.detail : '',
        amount: detail ? detail.amount : '',
        coverage_company: detail ? detail.coverage_company : 100,
        observation: detail ? detail.observation : '',
        review_status: detail ? detail.review_status : '',
        status: detail ? detail.status : '',
        doc: detail ? detail.doc : '',
    });

    const timeZone = mecompany?.time_zone ? mecompany.time_zone : 'America/Santiago';
    const currentDate = moment().tz(timeZone).format("MM-YYYY");

    // useEffect(() => {
    //     if(detail){
    //         listReportType();
    //     }
    // }, [detail]);

    useEffect(() => {
        listReportType();
        getDataCompany();
    }, []);

    useEffect(() => {
       
        if((expenseSelected.period != currentDate) && role == ROLE_ADMIN){
            return (
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                    severity:'warning', 
                    message: ___PHRASE("noSePuedeTomarAccionesSolicitudNoRealizada") }
                ))
            );
        }
    }, [currentDate, expenseSelected]);

    const getDataCompany = async () => {
        const resp = await companiesService.getDataCompany();
        setMeCompany(resp.data)
    }

    const listReportType = async () => {
        setLoading(true);
        await typesExpenseService.getTypesExpense().then((data) => {
            const newData = [];
            data.data && (data.data).length > 0 && (data.data).map((item)=>{
                if(language && language == 'en'){
                    newData.push({...item, name: apiResponseTranslate(item.name)})
                }else{
                    newData.push(item)
                }
            })
            setExpenseReportType(newData || [])
           setLoading(false);
        });
    }
    const setNameFile = (event) => {
        const files = event.target && event.target.files || [];
        const currentCv = files[0];
        
        if(currentCv){
            setCurrentCv(currentCv);

            const nameAsArray = currentCv.name.split(".") || [];
            const nameWithoutExtension = nameAsArray.filter((word, i) => i != nameAsArray.length-1);
            const nameFile = nameWithoutExtension.join(".") || "";
            return currentCv;
        }
        return "";
    };

    const showDocument = async() => {
        setLoading(true);

        if(detail && detail.doc){
            const route = `${API_URL_BASE}/${detail.doc}`;
            window.open(route, '_blank');
        }

        setLoading(false);
    }

    const validateForm = (values) => {
        let errors: any = {};
        if(!values.idexpense_report_type) errors.idexpense_report_type = ___PHRASE("tipoDeReporteRequerido");
        if(!values.detail) errors.detail = ___PHRASE("detalleRequerido");
        if(!values.amount) errors.amount = ___PHRASE("montoRequerido");
        if(role == ROLE_ADMIN && !values.observation) errors.observation = ___PHRASE("observacionrequerido");
        if(role == ROLE_TRABAJADOR && !values.status && detail) errors.status = ___PHRASE("estadoRequerido");
        if(role == ROLE_ADMIN && !values.coverage_company) errors.coverage_company = ___PHRASE("porcentajeRequerido");
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        if(!detail && !currentCv){
            return (
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                    severity:'warning', 
                    message: ___PHRASE("seleccioneUnArchivo") }
                ))
            );
        }

        if(detail && actionSelected=='edit' && detail.review_status == 'aceptada'){
            return (
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                    severity:'warning', 
                    message: ___PHRASE("laSolicitudHaSidoAceptada") }
                ))
            );
        }

        if(detail && actionSelected=='edit' && detail.review_status == 'rechazada'){
            return (
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                    severity:'warning', 
                    message: ___PHRASE("noSePuedeRealizarAccionesRechazada") }
                ))
            );
        }

        const formData = new FormData();
        formData.append("id", values.id);
        formData.append("idexpense_report_type", values.idexpense_report_type);
        formData.append("detail", values.detail);
        formData.append("amount", values.amount);
        formData.append("coverage_company", values.coverage_company);

        if(role == ROLE_ADMIN)  formData.append("observation", values.observation);
        if(role == ROLE_TRABAJADOR)  formData.append("observation", '');

        if(role == ROLE_ADMIN) formData.append("status", detail?.status || '1');
        if(role == ROLE_TRABAJADOR) formData.append("status", values.status || '1');

        if(role == ROLE_ADMIN && reviewStatus=='aceptar') formData.append("review_status", '1');
        if(role == ROLE_ADMIN && reviewStatus=='rechazar') formData.append("review_status", '2');
        if(role == ROLE_TRABAJADOR) formData.append("review_status", '0');

        if(currentCv) formData.append("doc", values.doc);
        formData.append("language", language);
        setLoading(true);
        
        if(detail && actionSelected=='edit'){
            if(reviewStatus == 'aceptar'){
                setShowModalConfirm(true);
                setCoverageCompany(values.coverage_company);
                setDataDetail(formData);
            }else{
                await EmployeeExpenseService.editReportDetail(detail.id, formData).then((data) => {
                    let apimessagge = apiResponseTranslate(data?.data?.message || '');
                    if(data?.data){
                        setSnackBarConfig(prev => ({ ...prev, open: true, 
                            severity:'success', 
                            message: ___PHRASE("detalleEditadoSatisfactoriamente")}
                        ))
                        onListDetails && onListDetails(expenseSelected.id);
                        setTimeout(function(){
                            closeModal && closeModal();
                            clearState && clearState();
                        }, 1000);
                    }else{
                        setSnackBarConfig(prev => ({ ...prev, open: true, 
                            severity:'error', 
                            message: `${apimessagge|| ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}`}
                        ))
                    }
                })
            }
            
        }else{
            await EmployeeExpenseService.createDetailExpenseEmployee(expenseSelected.id, formData).then((data) => {
                let apimessagge = apiResponseTranslate(data?.data?.message || '');
                if(data?.data?.status == true){
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'success', 
                        message: `${apimessagge}`
                    }))
                    onListDetails && onListDetails(expenseSelected.id);
                    setTimeout(function(){
                        closeModal && closeModal();
                        clearState && clearState();
                    }, 1000);
                }else{
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'error', 
                        message: `${apimessagge || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}`}
                    ))
                }
            })
        }

        setLoading(false);
    }

    return(
        <>
        {loading && <Loading/>}

        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>

        <Modal open={open} disableEscapeKeyDown disableBackdropClick handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={role == ROLE_ADMIN ? ___PHRASE("aceptarRechazarSolicitud") : 
                        (detail ? ( detail.review_status == 'pendiente' ? ___PHRASE("editarDetalle") : ___PHRASE("laSolicitudYaHaSidoRespondida") ) : ___PHRASE("agregarDetalle"))}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >

                                    <Grid item xs={12} container alignItems="center" justifyContent="center"  marginTop='25px' marginBottom='25px' >                
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1"><b>{___PHRASE("tipoDeReporte")}:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Select
                                                id="idexpense_report_type"
                                                variant="standard"
                                                value={values.idexpense_report_type}
                                                onChange={(event) => {
                                                    setFieldValue("idexpense_report_type", event.target.value)
                                                }}
                                                fullWidth
                                                inputProps={{readOnly: detail ? true : false}}
                                                error={errors.idexpense_report_type && touched.idexpense_report_type ? true : false}
                                            >
                                                {
                                                    expenseReportType.length > 0 && expenseReportType.map((type, i) => {
                                                        return <MenuItem key={i} value={type.id}>{type.name}</MenuItem>;
                                                    })
                                                }
                                            </Select>
                                        </Grid> 
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("detalle")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="detail"
                                            variant="standard"
                                            type="text" 
                                            name="detail"
                                            multiline
                                            maxRows={4}
                                            fullWidth
                                            value={values.detail}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("detalle")}
                                            InputProps={{readOnly: (detail && detail.review_status != 'pendiente') && true}}
                                            error={errors.detail && touched.detail ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("monto")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="amount"
                                            variant="standard"
                                            type="number" 
                                            name="amount"
                                            fullWidth
                                            value={values.amount}
                                            onChange={handleChange}
                                            InputProps={{
                                                readOnly: (detail && detail.review_status != 'pendiente') && true,
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            }}
                                            placeholder={___PHRASE("monto")}
                                            error={errors.amount && touched.amount ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    {
                                        role == ROLE_ADMIN && (
                                            <>
                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                                <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("coberturaEmpresa")} (%):</b></Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                <TextField
                                                    id="coverage_company"
                                                    variant="standard"
                                                    type="number" 
                                                    name="coverage_company"
                                                    fullWidth
                                                    value={values.coverage_company}
                                                    onChange={handleChange}
                                                    placeholder={___PHRASE("coberturaEmpresa")}
                                                    error={errors.coverage_company && touched.coverage_company ? true : false}
                                                />
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("observacion")}:</b></Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                <TextField
                                                    id="observation"
                                                    variant="standard"
                                                    type="text" 
                                                    name="observation"
                                                    multiline
                                                    maxRows={4}
                                                    fullWidth
                                                    value={values.observation}
                                                    onChange={handleChange}
                                                    placeholder={___PHRASE("observacion")}
                                                    error={errors.observation && touched.observation ? true : false}
                                                />
                                                </Grid>
                                            </Grid>
                                            </>
                                        )
                                    }

                                    {
                                        (role == ROLE_TRABAJADOR && detail && detail.review_status == 'pendiente') && (
                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >                
                                                <Grid item xs={4}>
                                                    <Typography variant="subtitle1"><b>{___PHRASE("estadoDelDetalle")}:</b> </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Select
                                                        id="status"
                                                        name="status"
                                                        variant="standard"
                                                        value={values.status}
                                                        onChange={(event) => {
                                                            setFieldValue("status", event.target.value);
                                                        }}
                                                        fullWidth
                                                        error={errors.status && touched.status ? true : false}
                                                    >
                                                        <MenuItem value="1">{___PHRASE("habilitado")}</MenuItem>
                                                        <MenuItem value="2">{___PHRASE("deshabilitado")}</MenuItem>
                                                    </Select>
                                                </Grid> 
                                            </Grid>
                                        )
                                    }

                                    {
                                        detail && (
                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("actualDocumento")}:</b></Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                <Button onClick={showDocument} size="small" color={"primary"} variant="contained" >
                                                    {___PHRASE("verDocumento")}
                                                </Button>
                                                </Grid>
                                            </Grid>
                                        )
                                    }

                                    {
                                        ((role == ROLE_TRABAJADOR &&  detail?.review_status == 'pendiente') || 
                                        (role == ROLE_TRABAJADOR &&  !detail)) && (
                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                                <Grid item xs={4}>
                                                    <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("nuevoDocumento")}:</b></Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Button variant="contained" component="label" fullWidth>
                                                        <input
                                                            id="doc"
                                                            type="file"
                                                            name="doc"
                                                            onChange={(event) => {
                                                                setFieldValue("doc", setNameFile(event));
                                                            }}
                                                        />
                                                    </Button>
                                                </Grid>
                                            </Grid> 
                                        )
                                    }
                                </Grid>
                                <Divider />
                                {
                                    ((role == ROLE_TRABAJADOR && detail && detail.review_status == 'pendiente') || 
                                    (role == ROLE_TRABAJADOR && !detail) ) && (
                                        <ModalFooter 
                                            buttonType="submit"
                                            confirmText={___PHRASE("aceptar")}
                                            onConfirm={handleSubmit}
                                            cancelText={___PHRASE("cancelar")} 
                                            onCancel={closeModal}
                                            clearState={clearState}
                                        />
                                    )
                                }
                                {
                                    (role == ROLE_ADMIN) && (expenseSelected && currentDate && expenseSelected.period == currentDate) && (
                                        <ModalFooter 
                                            buttonType="submit"
                                            confirmText={___PHRASE("aceptarSolicitud")}
                                            secondaryText={___PHRASE("rechazarSolicitud")}
                                            onConfirm={()=> {setReviewStatus('aceptar'); handleSubmit}}
                                            onSecondaryConfirm={()=> {setReviewStatus('rechazar'); handleSubmit}}
                                            clearState={clearState}
                                        />
                                    )
                                }
                                
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        {
            showModalConfirm && (
                <ModalExpenseConfirm
                    open={showModalConfirm}
                    closeModal={()=>{setShowModalConfirm(false)}}
                    closeModalMain={closeModal}
                    onListDetails={onListDetails}
                    actionSelected={actionSelected}
                    expenseSelected={expenseSelected}
                    detail={detail}
                    dataDetail={dataDetail}
                    coverageCompany={coverageCompany}
                />
            )
        }
        </>
    );
}