import { useEffect, useState } from 'react';
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { authenticationService } from '@/service/services/Authentication.service';
import { ROUTE_COMPANY_SETTING } from '@/toolbox/constants/route-map';
import { useHistory } from 'react-router-dom';
import { ROLE_ADMIN, ROLE_SUPER_ADMIN, ROLE_TRABAJADOR } from '@/toolbox/constants/role-type';
import ___PHRASE from '@/service/translate/translateService';
import { ModalUpdatePassword } from '@/components/Modal/ModalUpdatePassword/ModalUpdatePassword';

export default function AccountPopover() {
  const [open, setOpen] = useState(null);
  const data_user = readLocalStorage(KEY_USER_DATA);
  const role = data_user?.user?.role;
  const history= useHistory();

  const [showUpdatePassModal, setShowUpdatePassModal] = useState<boolean>(false);

  const account = {
    photoURL: '',
    displayName: data_user.user.user_name,
    role: data_user.user.role
  }

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = (option) => {
    setOpen(null);
    switch(option)
    {
      case ___PHRASE("configuracionempresa"):
        history.push(ROUTE_COMPANY_SETTING);
        break;
      case 'updatePass':
        setShowUpdatePassModal(true);
        break;
    }
  };

  const logout = () => {
    handleClose('');
    authenticationService.logout().then(res => {
        if (!res.error) {
               window.location.replace('/login');
        }
    }
    )
  }

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={account.photoURL} alt="photoURL" />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {account.displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {/* {account.email} */}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          <MenuItem key={"inicio"} onClick={()=>handleClose(___PHRASE("inicio"))}>
            {___PHRASE("inicio")}
          </MenuItem>
          {/* <MenuItem key={"perfil"} onClick={()=>handleClose(___PHRASE("perfil"))}>
            {___PHRASE("perfil")}
          </MenuItem> */}
          {
            role == ROLE_ADMIN && (
              <MenuItem key={"configuracionEmpresa"} onClick={()=>handleClose(___PHRASE("configuracionempresa"))}>
                {___PHRASE("configuracionempresa")}
              </MenuItem>
            )
          }
          <MenuItem key={"updatePass"} onClick={()=>handleClose(___PHRASE("updatePass"))}>
            {___PHRASE("cambiarContrasena")}
          </MenuItem>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={()=>{logout()}} sx={{ m: 1 }}>
          {___PHRASE("salir")}
        </MenuItem>
      </Popover>

      {
        showUpdatePassModal && (
            <ModalUpdatePassword
                closeModal={() => {setShowUpdatePassModal(false)}}
            />
        )
      }
    </>
  );
}
