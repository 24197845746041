import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';

export const templateRemunerationMonthlyRepository = {

    //PREVIRED
    getPrevired: async (idperiod, idcostcenter): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/previred?idperiod=${idperiod}&idcostcenter=${idcostcenter}`);
        const { data, error, message } = res;
        return { data, error, message };
    },

    createPreviredExcel: async (idperiod, idcostcenter): Promise<any> => {
        const resp = await http.post<any>(`${API_URL_BASE}/v1/previred/generateExcel`, {
            idperiod: idperiod,
            idcostcenter: idcostcenter,
        })
        return { status: resp.status, message: resp.message, data: resp.data };
    },

    createPreviredCsv: async (idperiod, idcostcenter): Promise<any> => {
        const resp = await http.post<any>(`${API_URL_BASE}/v1/previred/generateCSV`, {
            idperiod: idperiod,
            idcostcenter: idcostcenter,
        })
        return { status: resp.status, message: resp.message, data: resp.data };
    },

    getAbstractPrevired: async (idperiod): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/previred/getAbstractPrevired?idperiod=${idperiod}`);
        const { data, error, message } = res;
        return { data, error, message };
    },

    getDetailPreviredAFP: async (idperiod): Promise<any> => {
        const res = await http.getPDF<any>(`${API_URL_BASE}/v1/previred/getDetailPreviredAFP?idperiod=${idperiod}`);
        return res;
    },

    getDetailPreviredISAPRE: async (idperiod): Promise<any> => {
        const res = await http.getPDF<any>(`${API_URL_BASE}/v1/previred/getDetailPreviredISAPRE?idperiod=${idperiod}`);
        return res;
    },

    getDetailPreviredMutual: async (idperiod): Promise<any> => {
        const res = await http.getPDF<any>(`${API_URL_BASE}/v1/previred/getDetailPreviredMutual?idperiod=${idperiod}`);
        return res;
    },

    //LIBRO ELECTRONICO

    getElectronicBook: async (idperiod, idcostcenter): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/electronicBookSalaries?idperiod=${idperiod}&idcostcenter=${idcostcenter}`);
        const { data, error, message } = res;
        return { data, error, message };
    },

    createElectronicBookExcel: async (idperiod, idcostcenter): Promise<any> => {
        const resp = await http.post<any>(`${API_URL_BASE}/v1/electronicBookSalaries/generateExcel`, {
            idperiod: idperiod,
            idcostcenter: idcostcenter,
        })
        return { status: resp.status, message: resp.message, data: resp.data };
    },

    createElectronicBookExcelNotHeaders: async (idperiod, idcostcenter): Promise<any> => {
        const resp = await http.post<any>(`${API_URL_BASE}/v1/electronicBookSalaries/generateExcelNoHeader`, {
            idperiod: idperiod,
            idcostcenter: idcostcenter,
        })
        return { status: resp.status, message: resp.message, data: resp.data };
    },

    createElectronicBookCsv: async (idperiod, idcostcenter): Promise<any> => {
        const resp = await http.post<any>(`${API_URL_BASE}/v1/electronicBookSalaries/generateCSV`, {
            idperiod: idperiod,
            idcostcenter: idcostcenter,
        })
        return { status: resp.status, message: resp.message, data: resp.data };
    },

    //DJ
    getDJ: async (year): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/DJ?year=${year}`);
        const { data, error, message } = res;
        return { data, error, message };
    },

    createDJExcel: async (year): Promise<any> => {
        const resp = await http.post<any>(`${API_URL_BASE}/v1/DJ/generateExcel`, {
            year: year,
          })
        return { status: resp.status, message: resp.message, data: resp.data };
    },

    createDJCsv: async (year): Promise<any> => {
        const resp = await http.post<any>(`${API_URL_BASE}/v1/DJ/generateCSV`, {
            year: year
        })
        return { status: resp.status, message: resp.message, data: resp.data };
    },

    //certificado de remuneracion

    getCertificateRemuneration: async (year, idEmployee): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/certificateRemuneration/${idEmployee}?year=${year}`);
        const { data, error, message } = res;
        return { data, error, message };
    },

    createCertificateRemuneration:async (year, idEmployee):Promise<any> => {
        const resp = await http.post<any>(`${API_URL_BASE}/v1/certificateRemuneration/${idEmployee}`, {
            year: year,
          })
        return { status: resp.status, message: resp.message, data: resp.data };
    },

    createPDFCertificateRemuneration: async (year, idEmployee): Promise<any> => {
        const res = await http.getPDF<any>(`${API_URL_BASE}/v1/certificateRemuneration/generatePDF/${idEmployee}?year=${year}`);
        return res;
    },


}


