import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik } from 'formik';
import { useEffect, useState } from "react";
import { Alert, Autocomplete, Divider, Grid, Snackbar, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import { Loading } from "@/components/common/Loading";
import { Props } from "./ModalTypeNotifications.type";
import { notificationsTypeService } from "@/service/services/NotificationsType.service";
import { ViewsService } from "@/service/services/Views.service";
import ___PHRASE from "@/service/translate/translateService";
import { apiResponseTranslate } from "@/toolbox/helpers/responseApi.helper";
import '../../../assets/styles/modals.scss';

export const ModalNotificationsType: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, notificationsType, onListNotificationsType, clearState } = props;
   
    const [loading, setLoading] = useState<boolean>(false);

    const [data, setData] = useState({
        id: notificationsType ? notificationsType.id : '',
        name: notificationsType ? notificationsType.name : '',
        // url: notificationsType ? notificationsType.url : '',
    });
    const [urls, setUrls] = useState();
    const [urlSelected, setUrlSelected] = useState<any>(null);

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const validateForm = (values) => {
        let errors: any = {};
        if(!values.name) errors.name = ___PHRASE("nombreRequerido");
        // if(!values.url) errors.url = "URL requerido";
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        if(!urlSelected){
            return (
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                    severity:'warning', 
                    message: ___PHRASE("debeSeleccionarUnaURL") }
                ))
            );
        }

        const formData = {
            id: values.id,
            name : values.name,
             url : urlSelected.route,
        }
        setLoading(true);
        if(notificationsType && actionSelected=='edit'){
            await notificationsTypeService.editNotificationsType(formData, notificationsType.id).then((data) => {
                let apimessagge = apiResponseTranslate(data?.data?.message);
                if(data.data.status){
                    setSnackBarConfig({
                        ...snackBarConfig, open:true, severity: 'success', message: apimessagge
                    })
                }else{
                    setSnackBarConfig({
                        ...snackBarConfig, open:true, severity: 'error', message: apimessagge
                    })
                }
                setTimeout(()=>{
                    onListNotificationsType && onListNotificationsType();
                    closeModal && closeModal();
                    setLoading(false);
                },2000) 
            })
        }else{
            await notificationsTypeService.createNotificationsType(formData).then((data) => {
                setSnackBarConfig({...snackBarConfig, open:true, severity:'success', message: ___PHRASE("creadoConExito")})
                setTimeout(()=>{
                    onListNotificationsType && onListNotificationsType();
                    closeModal && closeModal();
                    setLoading(false);
                },2000)
            })
        }
    }
    
    const getAllViews = async() => {
        setLoading(true)
        await ViewsService.getAllViews().then((data) => {
            if(data.data.length > 0){
                const uniqueRoutes = data.data.filter((item, index) => {
                    return (
                      index ===
                      data.data.findIndex((obj) => {
                        return obj.route === item.route;
                      })
                    );
                  });
                setUrls(uniqueRoutes)
                if(actionSelected == 'edit'){
                    setUrlSelected( uniqueRoutes.find((item) => item.route == notificationsType.url))
                }
            }
        })
        setLoading(false)
    }

    useEffect(()=>{
        if(open){
            getAllViews();
        }
    },[open])

    return(
        <>
        {loading && <Loading/>}
        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>
        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={(notificationsType ? ___PHRASE("editar") : ___PHRASE("agregar"))+' '+___PHRASE("tipoDeNotificacion")}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("nombre")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="name"
                                            variant="standard"
                                            type="text" 
                                            name="name"
                                            fullWidth
                                            value={values.name}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("nombre")}
                                            error={errors.name && touched.name ? true : false}
                                        />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>URL:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <Autocomplete
                                                id="disable-portal"
                                                value={urlSelected}
                                                options={urls || []}
                                                getOptionLabel={(option) =>  option ? `${option.route}` : ""}
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} variant="standard" label={___PHRASE("escribeParaBuscar")} />}
                                                onChange={(e, newValue) => { setUrlSelected(newValue) }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(notificationsType && actionSelected=='edit') ? ___PHRASE("actualizar") : ___PHRASE("registrar")}
                                    cancelText={___PHRASE("cancelar")} 
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}