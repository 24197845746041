import { Formik } from 'formik';
import { useState } from "react";
import { Button, Card, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { Loading } from "@/components/common/Loading";
import { companiesService } from '@/service/services/Companies.service';
import { Props } from './ExtraHourValue.type';
import ___PHRASE from '@/service/translate/translateService';

export const ExtraHourValue: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, dataCompany, onListDataCompany, mutuality, clearState } = props;

    const [loading, setLoading] = useState<boolean>(false);
    

    const [data, setData] = useState({
        id: dataCompany ? dataCompany.idcompany : '',
        extra_hours1: dataCompany ? dataCompany.extra_hours1 : '',
        extra_hours2: dataCompany ? dataCompany.extra_hours2 : '',
        extra_hours3: dataCompany ? dataCompany.extra_hours3 : '',
    });

    const validateForm = (values) => {
        let errors: any = {};
        let regex = "^([0-1]+\.?[0-9]{0,15})$";

        if(!values.extra_hours1) errors.extra_hours1 = ___PHRASE("horasExtras") +" 1 " + ___PHRASE("requerido");
        if(values.extra_hours1 && !(values.extra_hours1.match(regex))) errors.extra_hours1 = ___PHRASE("soloSeAceptanNumerosDel0Al1");
        if(values.extra_hours1 > 1) errors.extra_hours1 = ___PHRASE("horasExtras") +" 1 " + ___PHRASE("requerido");

        if(!values.extra_hours2) errors.extra_hours2 = ___PHRASE("horasExtras") +" 2 " + ___PHRASE("requerido");
        if(values.extra_hours2 && !(values.extra_hours2.match(regex))) errors.extra_hours2 = ___PHRASE("soloSeAceptanNumerosDel0Al1");
        if(values.extra_hours2 > 1) errors.extra_hours2 = ___PHRASE("horasExtras") +" 2 " + ___PHRASE("requerido");

        if(!values.extra_hours3) errors.extra_hours3 = ___PHRASE("horasExtras") +" 3 " + ___PHRASE("requerido");
        if(values.extra_hours3 && !(values.extra_hours3.match(regex))) errors.extra_hours3 = ___PHRASE("soloSeAceptanNumerosDel0Al1");
        if(values.extra_hours3 > 1) errors.extra_hours3 = ___PHRASE("horasExtras") +" 3 " + ___PHRASE("requerido");
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        const formData = {
            extra_hours1 : values.extra_hours1,
            extra_hours2 : values.extra_hours2,
            extra_hours3 : values.extra_hours3,
        }
        setLoading(true);
        await companiesService.updateExtraHours(formData).then((data) => {
            onListDataCompany && onListDataCompany();
            closeModal && closeModal();
        })
        setLoading(false);
    }
    return(
        <>
        {loading && <Loading/>}

        
            <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                    return(
                        <form onSubmit={handleSubmit}>
                            <Card sx={{ width: "100%", marginTop: '20px' }}>
                                <TableContainer>
                                    <Table>
                                        <TableHead sx={{ bgcolor: '#EDEFF1' }}>
                                            <TableRow >
                                                <TableCell >
                                                    <Typography>{___PHRASE("valorDeHorasExtras")}</Typography>
                                                </TableCell>
                                                <TableCell >
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            <TableRow >
                                                <TableCell size={'small'} sx={{ bgcolor: '#F4F6F8', width: '30%' }}>
                                                    {___PHRASE("horasExtras")} 1
                                                </TableCell>
                                                <TableCell >
                                                    <TextField
                                                        id="extra_hours1"
                                                        variant="standard"
                                                        sx={{ width: '400px' }}
                                                        name="extra_hours1"
                                                        type="text"
                                                        onChange={handleChange}
                                                        value={values.extra_hours1}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        error={errors.extra_hours1 && touched.extra_hours1 ? true : false}
                                                    />
                                                </TableCell>
                                            </TableRow>

                                            <TableRow >
                                                <TableCell size={'small'} sx={{ bgcolor: '#F4F6F8', width: '30%' }}>
                                                {___PHRASE("horasExtras")} 2
                                                </TableCell>
                                                <TableCell >
                                                    <TextField
                                                        id="extra_hours2"
                                                        variant="standard"
                                                        sx={{ width: '400px' }}
                                                        name="extra_hours2"
                                                        type="text"
                                                        onChange={handleChange}
                                                        value={values.extra_hours2}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        error={errors.extra_hours2 && touched.extra_hours2 ? true : false}
                                                    />
                                                </TableCell>
                                            </TableRow>

                                            <TableRow >
                                                <TableCell size={'small'} sx={{ bgcolor: '#F4F6F8', width: '30%' }}>
                                                {___PHRASE("horasExtras")} 3
                                                </TableCell>
                                                <TableCell >
                                                    <TextField
                                                        id="extra_hours3"
                                                        variant="standard"
                                                        sx={{ width: '400px' }}
                                                        name="extra_hours3"
                                                        type="text"
                                                        onChange={handleChange}
                                                        value={values.extra_hours3}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        error={errors.extra_hours3 && touched.extra_hours3 ? true : false}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Grid sx={{ textAlign: 'center', margin: '10px' }}>
                                    <Button color="primary" variant="contained" onClick={() => {handleSubmit()}}>
                                        {___PHRASE("actualizar")}
                                    </Button>
                                </Grid>
                            </Card>
                        </form>
                    )
                }}
            </Formik>
        </>
    );
}