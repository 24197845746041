import PropTypes from 'prop-types';
// @mui
import { Box, Checkbox, TableRow, TableCell, TableHead, TableSortLabel,Typography} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import ___PHRASE from '@/service/translate/translateService';
// ----------------------------------------------------------------------

UserListHead.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  headLabel: PropTypes.array,
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
};

export default function UserListHead({
  order,
  orderBy,
  rowCount,
  headLabel,
  numSelected,
  onRequestSort,
  onSelectAllClick,
  status_action,
  checkbox,
  bg_header,
  color_text_header,
  sticky_column,
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property.name);
  };

  return (
    <TableHead sx={{position:'relative'}}>
      <TableRow>
        {
          status_action && (
            <TableCell
              align={'center'}
              sx={{ bgcolor: `${bg_header ? bg_header : '#8BCBBA'}`, position:'sticky', left:0, zIndex:10 }}
              width={'230px'}
              >
            </TableCell> 
          )
        }
        {headLabel.map((headCell) => {
          const stickyColumn = sticky_column && sticky_column == headCell.name;
          return (
          <>
            <TableCell
              key={headCell.id}
              align={'center'}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{ bgcolor: `${bg_header ? bg_header : '#8BCBBA'}`, position: `${stickyColumn ? 'sticky' : ''}` , 
                    left:`${stickyColumn ? 0 : ''}`,zIndex:`${sticky_column ? 10 : ''}`}}
              width={headCell.width ? headCell.width : '230px'}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell)}
              >
                <Typography sx={{
                      fontWeight:600,fontSize:14, color: `${ color_text_header ? color_text_header : '#281A1A'}` }}>
                          {headCell.label}
                </Typography>
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          </>
          )
        })}
        
        {
          checkbox && (
            <TableCell padding="checkbox" sx={{ bgcolor: '#8BCBBA' }} width={'50px'} align={'left'} >
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
              />
            </TableCell>
          )
        }
      </TableRow>
    </TableHead>
  );
}
