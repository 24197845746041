import { AssistanceService } from "@/service/services/Assistance.service";
import { ChargeService } from "@/service/services/Charge.service";
import { CostCenterService } from "@/service/services/CostCenter.service";
import { Alert, Snackbar, Typography } from "@mui/material";
import { useState } from "react";
import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalFooter } from "../ModalFooter";
import { ModalHeader } from "../ModalHeader";
import { Loading } from "@/components/common/Loading";
import { Props } from "./ModalAssistanceDelete.type";
import { AssistsWorkerService } from "@/service/services/AssistanceWorker.service";
import moment from "moment";
import ___PHRASE from "@/service/translate/translateService";

export const ModalAssistanceDelete: React.FC<Props> = (props:any): JSX.Element => {
    const { open, closeModal, assistance, onListAssistances, workerSelected, startDateSelected, endDateSelected, clearState } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const onDeleted= async()=>{
        
        let currentMonth = moment().format("MMMM-YYYY");
        const dateAssistance = assistance && assistance.date;
        
        // if(currentMonth != moment(dateAssistance).format("MMMM-YYYY")) return (
        //     setSnackBarConfig(prev => ({ ...prev, open: true, 
        //         severity:'warning', 
        //         message: ___PHRASE("noPuedeEliminarAsistenciasQueNoPertenecenAlMesActual")+` (${currentMonth}).` }
        //     ))
        // );

        setLoading(true);

        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonthJS = currentDate.getMonth() + 1; //getMonth() su indice empieza desde 0
        const currentMonthFilter = currentMonthJS <= 9 ? `0${currentMonthJS}` : `${currentMonthJS}`;

        const previousDate = currentYear+'-'+currentMonthFilter+'-01';
        const nexDate = moment().format("YYYY-MM-DD");

        let start_date = startDateSelected ? startDateSelected : previousDate;
        let end_date = endDateSelected ? endDateSelected : nexDate;

        await AssistsWorkerService.deleteAssists(assistance.id).then((data) => {
            setSnackBarConfig(prev => ({ ...prev, open: true, 
            severity:'success', 
            message: ___PHRASE("asistenciaEliminada") }
            ));
            onListAssistances && onListAssistances(workerSelected.id, start_date, end_date);
            setTimeout(function(){
                closeModal && closeModal();
                clearState && clearState();
            }, 1000);
        })

        setLoading(false);
    }
  
    return (
        <>

        {loading && <Loading/>}
        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>

        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick size="xs">
            <ModalHeader
                text={___PHRASE("Eliminar")+' '+___PHRASE("asistencia")}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>

            <ModalBody>
                <Typography variant="subtitle1" className="textColor"> 
                {___PHRASE("estaSeguroDeEliminarLaAsistencia")}
                </Typography>
            </ModalBody>

            <ModalFooter 
                confirmText={___PHRASE("eliminar")}
                cancelText={___PHRASE("cancelar")}
                onCancel={closeModal}
                className="footerRight"
                onConfirm={onDeleted}
                clearState={clearState}
            />
        </Modal>
        </>
    )
}