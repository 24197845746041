import React, { useEffect, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Loading } from '@/components/common/Loading';
import { Alert, Box, Button, Card, Container, Grid, MenuItem, Select, Snackbar, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { ReportService } from '@/service/services/Report.service';
import { PeriodService } from '@/service/services/Period.service';
import { moneyFormat } from '@/toolbox/helpers/money.helper';
import moment from 'moment';
import ___PHRASE from '@/service/translate/translateService';

const Div = styled('div')(({ theme }) => ({
    ...theme.typography.button,
    padding: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: '20px',
    color: 'rgba(102, 102, 102, 0.4)',
}));

export const ReportEmployeesView: React.FC<any> = (props: any): JSX.Element => {
   
    const [loading, setLoading] = useState<boolean>(false);
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });
    const [excelDissable, setExcelDissable]= useState<any>(false);
    const [periods, setPeriods] = useState<any>([]);
    const [years, setYears] = useState<any>([]);
    const [yearSelected, setYearSelected] = useState<any>(moment().format('YYYY'));
    const [monthSelected, setMonthSelected] = useState<any>(moment().format('MM'));
    const [contractType, setContractType] = useState<any>([]);
    const [contractTypeQuantity, setContractTypeQuantity] = useState<any>(0);
    const [gratificationType, setGratificationType] = useState<any>([]);
    const [gratificationTypeQuantity, setGratificationTypeQuantity] = useState<any>(0);
    const [familyStech, setFamilyStech] = useState<any>([]);
    const [familyStechQuantity, setFamilyStechQuantity] = useState<any>(0);
    const [countEmployees, setCountEmployees] = useState<any>(0);
    const [employeesWithRemu, SetEmployeesWithRemu] = useState<any>(0);

    useEffect(()=> {
        getPeriods();
    },[]);

    useEffect(()=> {
        if(periods.length > 0) getReportEmployee();
    },[periods]);
  
    const getPeriods = async() => {
        setLoading(true);
        const resp:any = await PeriodService.getPeriods();
        const newData = [];
        const arrayYears = [];
        resp.data && (resp.data).length > 0 && (resp.data).map((item) => {
           newData.push({
              id: item.id,
              month: item.month,
              year: item.year,
              alias_name: item.month + '-' +  item.year,
           })
           const found = arrayYears.find(element => element == item.year);
           if(!found) { arrayYears.push(item.year); }
           
        });
        setYears(arrayYears);
        setPeriods(newData || []);
        setLoading(false);
    }

    const getReportEmployee = async () => {
        const periodSelected = monthSelected + '-' + yearSelected;
        const period = periods.find(element => element.alias_name == periodSelected);

        setLoading(true);
        if(!monthSelected || !yearSelected){
            setLoading(false);
            return (
               setSnackBarConfig(prev => ({ ...prev, open: true, 
                  severity:'warning', 
                  message: ___PHRASE("debeSeleccionarMesyAno") }
               ))
            );
        }

        const anioActual = new Date().getFullYear()

        if(yearSelected > anioActual ) {
            setLoading(false);
            return (
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                   severity:'warning', 
                   message: ___PHRASE("asegureseQueLaFechaSeleccionadaSeaMenorQueLaFechaActual") }
                ))
             );
        }
        const mesActual = new Date().getMonth() + 1;

        if(yearSelected == anioActual && mesActual < monthSelected){
            setLoading(false);

            return (
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                   severity:'warning', 
                   message: ___PHRASE("asegureseQueLaFechaSeleccionadaSeaMenorQueLaFechaActual") }
                ))
             );
        }
        

        await ReportService.getReportEmployee(period.id).then((data) => {

            const countEmployees = data?.data?.employee?.countEmployees || 0;
            const countEmployeesWithRemu = data?.data?.employee?.countEmployeesWithRemu || 0;
            const contract_type = data?.data?.employee?.contract_type || [] ;
            const family_strech = data?.data?.employee?.family_strech || [] ;
            const gratification_type = data?.data?.employee?.gratification_type || [] ;

            let contractTypeFilter = [];
            let familyStrechFilter = [];
            let gratificationTypeFilter = [];
            
            let contract_typeQuantity = 0;
            let family_strechQuantity = 0;
            let gratification_typeQuantity = 0;

            if(contract_type.length > 0){
                let obj;
                for (let index = 0; index < contract_type.length; index++) {
                    const keys =  Object.keys(contract_type[index]);
                    const values = Object.values(contract_type[index]);
                    obj = {
                        ...obj,
                        name: keys[0],
                        countEmployees: values[0],
                        FullPay: values[1]
                    }
                    contractTypeFilter.push(obj);
                    contract_typeQuantity = contract_typeQuantity + contract_type[index].quantity;
                }
                setContractTypeQuantity(contract_typeQuantity)
            }

            if(family_strech.length > 0){
                let obj;
                for (let index = 0; index < family_strech.length; index++) {
                    const keys =  Object.keys(family_strech[index]);
                    const values = Object.values(family_strech[index]);
                    obj = {
                        ...obj,
                        name: keys[0],
                        countEmployees: values[0],
                        FullPay: values[1]
                    }
                    familyStrechFilter.push(obj);
                    family_strechQuantity = family_strechQuantity + family_strech[index].quantity;
                }
                setFamilyStechQuantity(family_strechQuantity)
            }

            if(gratification_type.length > 0){
                let obj;
                for (let index = 0; index < gratification_type.length; index++) {
                    const keys =  Object.keys(gratification_type[index]);
                    const values = Object.values(gratification_type[index]);
                    obj = {
                        ...obj,
                        name: keys[0],
                        countEmployees: values[0],
                        FullPay: values[1]
                    }
                    gratificationTypeFilter.push(obj);
                    gratification_typeQuantity = gratification_typeQuantity + gratification_type[index].quantity;
                }
                setGratificationTypeQuantity(gratification_typeQuantity)
            }
            setCountEmployees(countEmployees);
            SetEmployeesWithRemu(countEmployeesWithRemu);
            setContractType(contractTypeFilter || []);
            setFamilyStech(familyStrechFilter || []);
            setGratificationType(gratificationTypeFilter || []);

            if(contract_typeQuantity != 0 && family_strechQuantity != 0 && gratification_typeQuantity != 0 ) setExcelDissable(true);
            setLoading(false);
        })
    }

    const getReportExcelAbsencePDF = async (typeDownload) => {
        const periodSelected = monthSelected + '-' + yearSelected;
        const period = periods.find(element => element.alias_name == periodSelected);

        setLoading(true);

        if (typeDownload == 'excel') {
            const respExcel = await ReportService.getReportExcelEmployees(period.id);
            const btn_excel = document.createElement("a")
            btn_excel.href = `${respExcel.data.link}`
            btn_excel.download = 'Employee_report.xlsx'

            document.body.appendChild(btn_excel);
            btn_excel.click();
            document.body.removeChild(btn_excel);
            setLoading(false);
        } else {
            const respCSV = await ReportService.getReportExcelEmployees(period.id);
            const btn_csv = document.createElement("a")
            btn_csv.href = `${respCSV.data.link}`
            btn_csv.download = 'Employee_report.csv'

            document.body.appendChild(btn_csv);
            btn_csv.click();
            document.body.removeChild(btn_csv);
            setLoading(false);
        }
        setLoading(false);
    }
    return (
        <Protected>
            {loading && <Loading/>}
            <Snackbar
                open={snackBarConfig.open} 
                autoHideDuration={snackBarConfig.autoHideDuration} 
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
                <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                    {snackBarConfig.message || ""}
                </Alert>
            </Snackbar>

            <Container>
                <Typography variant='h6' component='h1' gutterBottom className="tittle" sx={{ m: 1, fontWeight: 600 }}>
                    {___PHRASE("reporteDeEmpleados")}
                </Typography>

                <Grid container xs={12} direction="row" justifyContent="flex-start" alignItems="center">
                    <Grid xs={3} display='flex' alignItems="center" 
                        sx={{border: 'solid 1px #e9e2e2', borderRadius:'10px', padding: '5px 8px 8px 8px', marginRight: '10px'}}>
                        <Grid item xs={4}>
                            <Typography variant="body1"><b>{___PHRASE("periodo")}:</b> </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Select
                                id="id_education_level"
                                variant="standard"
                                value={yearSelected}
                                onChange={(event) => {
                                setYearSelected(event.target.value);
                                }}
                                fullWidth
                            >
                                {
                                years.map((year, i) => {
                                    return <MenuItem key={i} value={year}>{year}</MenuItem>;
                                })
                                }
                            </Select>
                        </Grid>
                    </Grid>
                    <Grid xs={3} display='flex' alignItems="center" 
                        sx={{border: 'solid 1px #e9e2e2', borderRadius:'10px', padding: '5px 8px 8px 8px', marginRight: '10px'}}>
                        <Grid item xs={4}>
                            <Typography variant="body1">{___PHRASE("mes")} </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Select
                                id="id_education_level"
                                variant="standard"
                                value={monthSelected}
                                onChange={(event) => {setMonthSelected(event.target.value)}}
                                fullWidth
                            >
                                <MenuItem value={'01'}>{___PHRASE("enero")}</MenuItem>
                                <MenuItem value={'02'}>{___PHRASE("febrero")}</MenuItem>
                                <MenuItem value={'03'}>{___PHRASE("marzo")}</MenuItem>
                                <MenuItem value={'04'}>{___PHRASE("abril")}</MenuItem>
                                <MenuItem value={'05'}>{___PHRASE("mayo")}</MenuItem>
                                <MenuItem value={'06'}>{___PHRASE("junio")}</MenuItem>
                                <MenuItem value={'07'}>{___PHRASE("julio")}</MenuItem>
                                <MenuItem value={'08'}>{___PHRASE("agosto")}</MenuItem>
                                <MenuItem value={'09'}>{___PHRASE("septiembre")}</MenuItem>
                                <MenuItem value={'10'}>{___PHRASE("octubre")}</MenuItem>
                                <MenuItem value={'11'}>{___PHRASE("noviembre")}</MenuItem>
                                <MenuItem value={'12'}>{___PHRASE("diciembre")}</MenuItem>
                            </Select>
                        </Grid>
                    </Grid>
                    <Grid xs={2} display='flex'>
                        <Button onClick={getReportEmployee} type={'submit'} size="small" color={"primary"} variant="contained" >
                            {___PHRASE("filtrar")}
                        </Button>
                    </Grid>
                </Grid>
                
                {
                    loading && (
                        <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'150px', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '20px' }}>
                            <Div>{___PHRASE("cargandoDatosEspereUnMomento")}</Div>
                        </Box>
                    )
                }

                {
                    (!loading)  && (
                        <>
                        <Grid container xs={12} sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px'}}>
                            <Grid item xs={9} sx={{display: 'flex', alignItems: 'center'}}>
                                <Typography variant="subtitle2" className="custom-input" 
                                    sx={{ border: `1px #81a4cb solid`, borderRadius:'8px', padding: '5px', color: '#707ac3', width:'610px', textAlign: 'center', marginRight: '5px'}}>
                                    {___PHRASE("seEncontro")+' '+employeesWithRemu+' '+___PHRASE("empleadosConLiquidacionGeneradaEnElPeriodoyMesSeleccionado")}
                                </Typography>

                                <Typography variant="subtitle2" className="custom-input" 
                                    sx={{ border: `1px #81a4cb solid`, borderRadius:'8px', padding: '5px', color: '#707ac3', width:'235px',textAlign: 'center' }}>
                                    {___PHRASE("totalEmpleadosConContrato")+': '+countEmployees}
                                </Typography>
                            </Grid>

                            {
                                excelDissable && (
                                    <Grid item xs={3} sx={{textAlign: 'right'}}>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={() => getReportExcelAbsencePDF('excel')}
                                        >
                                            {___PHRASE("descargarExcel")}
                                        </Button>
                                    </Grid>
                                )
                            }
                        </Grid>
                        <Grid container sx={{ width: "100%"}}>
                        <Card sx={{ width: "100%", marginTop: '20px', padding: '10px', marginBottom: '20px'}}>
                            <Typography variant='h6' component='h2' gutterBottom className="tittle" sx={{ m: 1, fontWeight: 600 }}>
                                {___PHRASE("tiposDeContrato")}
                            </Typography>
                            {
                                contractTypeQuantity > 0 ? (
                                    <TableContainer>
                                        <Table>
                                            <TableHead sx={{ bgcolor: '#EDEFF1' }}>
                                                <TableRow >
                                                    <TableCell >
                                                        <Typography>{___PHRASE("nombre")}</Typography>
                                                    </TableCell>
                                                    <TableCell >
                                                        <Typography>{___PHRASE("cantidadEmpleados")}</Typography>
                                                    </TableCell>
                                                    <TableCell >
                                                        <Typography>{___PHRASE("totalPago")}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {
                                                    contractType.length > 0 && contractType.map((item) => (
                                                        <TableRow >
                                                            <TableCell size={'small'}>
                                                                {item.name}
                                                            </TableCell>
                                                            <TableCell size={'small'}>
                                                                {item.countEmployees}
                                                            </TableCell>
                                                            <TableCell size={'small'}>
                                                                {moneyFormat(item.FullPay)}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                }

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                ) : (
                                    <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'150px', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '20px', margin:'10px' }} >
                                        <Div>{___PHRASE("noSeEncontraronEmpleadosConLiquidacion")}</Div>
                                    </Box>
                                )
                            }
                            
                        </Card>
                        </Grid>

                        <Grid container sx={{ width: "100%"}}>
                        <Card sx={{ width: "100%", marginTop: '20px', padding: '10px', marginBottom: '20px'}}>
                            <Typography variant='h6' component='h2' gutterBottom className="tittle" sx={{ m: 1, fontWeight: 600 }}>
                                {___PHRASE("tiposDeGratificaciones")}
                            </Typography>
                            {
                                gratificationTypeQuantity > 0 ? (
                                    <TableContainer>
                                        <Table>
                                            <TableHead sx={{ bgcolor: '#EDEFF1' }}>
                                                <TableRow >
                                                    <TableCell >
                                                        <Typography>{___PHRASE("nombre")}</Typography>
                                                    </TableCell>
                                                    <TableCell >
                                                        <Typography>{___PHRASE("cantidadEmpleados")}</Typography>
                                                    </TableCell>
                                                    <TableCell >
                                                        <Typography>{___PHRASE("totalPago")}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {
                                                    gratificationType.length > 0 && gratificationType.map((item) => (
                                                        <TableRow >
                                                            <TableCell size={'small'}>
                                                                {item.name}
                                                            </TableCell>
                                                            <TableCell size={'small'}>
                                                                {item.countEmployees}
                                                            </TableCell>
                                                            <TableCell size={'small'}>
                                                                {moneyFormat(item.FullPay)}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                }

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                ) : (
                                    <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'150px', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '20px', margin:'10px'  }} >
                                        <Div>{___PHRASE("noSeEncontraronEmpleadosConLiquidacion")}</Div>
                                    </Box>
                                )
                            }
                        </Card>
                        </Grid>

                        <Grid container sx={{ width: "100%"}}>
                        <Card sx={{ width: "100%", marginTop: '20px', padding: '10px', marginBottom: '20px'}}>
                            <Typography variant='h6' component='h2' gutterBottom className="tittle" sx={{ m: 1, fontWeight: 600 }}>
                                {___PHRASE("tramosFamiliares")}
                            </Typography>
                            {
                                familyStechQuantity > 0 ? (
                                    <TableContainer>
                                        <Table>
                                            <TableHead sx={{ bgcolor: '#EDEFF1' }}>
                                                <TableRow >
                                                    <TableCell >
                                                        <Typography>{___PHRASE("nombre")}</Typography>
                                                    </TableCell>
                                                    <TableCell >
                                                        <Typography>{___PHRASE("cantidadEmpleados")}</Typography>
                                                    </TableCell>
                                                    <TableCell >
                                                        <Typography>{___PHRASE("totalPago")}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {
                                                    familyStech.length > 0 && familyStech.map((item) => (
                                                        <TableRow >
                                                            <TableCell size={'small'}>
                                                                {item.name}
                                                            </TableCell>
                                                            <TableCell size={'small'}>
                                                                {item.countEmployees}
                                                            </TableCell>
                                                            <TableCell size={'small'}>
                                                                {moneyFormat(item.FullPay)}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))
                                                }

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                ) : (
                                    <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'150px', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '20px', margin:'10px'  }} >
                                        <Div>{___PHRASE("noSeEncontraronEmpleadosConLiquidacion")}</Div>
                                    </Box>
                                )
                            }
                        </Card>
                        </Grid>

                        </>
                    )
                }
            </Container>
        </Protected>
    )
};