import React, { useEffect, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Container } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { Loading } from '@/components/common/Loading';
import { Props } from './RequestType.type';
import { requestTypeService } from '@/service/services/RequestType.service';
import { ModalRequestType } from '@/components/Modal/ModalRequestType';
import { ModalRequestTypeDelete } from '@/components/Modal/ModalRequestTypeDelete';
import ___PHRASE from '@/service/translate/translateService';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_TRANSLATE, KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { apiResponseTranslate } from '@/toolbox/helpers/responseApi.helper';

export const RequestTypeView: React.FC<Props> = (props:any): JSX.Element => {

   const [loading, setLoading] = useState<boolean>(false);
   const [requestTypes, serequestTypes] = useState([]);
   const [showRequestTypeModal, setShowRequestTypeModal] = useState<boolean>(false);
   const [showRequestTypeDeleteModal, setShowRequestTypeDeleteModal] = useState<boolean>(false);
   const [actionSelected, setActionSelected] = useState<String>('');
   const [requestTypeSelected, setRequestTypeSelected] = useState<String>('');

   const language = readLocalStorage(KEY_TRANSLATE);
   const dataUser = readLocalStorage(KEY_USER_DATA);
   const typeSuperadmin = dataUser?.user?.type_superadmin;

   useEffect(() => {
    getRequestTypes()
   },[]);

   const getRequestTypes = async () => {
      setLoading(true);
      const response = await requestTypeService.getRequestTypes();
      const newData = [];

      response.data && (response.data).length > 0 && (response.data).map((item) => {
         if(language == 'en') {
            newData.push({...item, name: apiResponseTranslate(item.name)})
         }else{
            newData.push(item)
         }
      })

      serequestTypes(newData || [])
      setLoading(false);
   }

   function RecuperarData(value){
      setRequestTypeSelected(value);
   }

   const searchData = async (value) => {
      const response = await requestTypeService.getRequestTypes(value);
      const newData = [];

      response.data && (response.data).length > 0 && (response.data).map((item) => {
         if(language == 'en') {
            newData.push({...item, name: apiResponseTranslate(item.name)})
         }else{
            newData.push(item)
         }
      })

      serequestTypes(newData || [])
   }

  return (
      <Protected>
         {loading && <Loading/>}
         <Container>
            <TableDataV2
               title={___PHRASE("tiposdesolicitudes")}
               data={requestTypes}
               header={[
                  { name: 'name', label: ___PHRASE("nombre"), filter: false, Chip: true },
               ]}
               actionSelect={setActionSelected}
               setModalSave={setShowRequestTypeModal}
               setModalDelete={setShowRequestTypeDeleteModal}
               status_action={typeSuperadmin == '1' ? true : false}
               btn_disabled={typeSuperadmin == '1' ? false : true}
               textSearch={___PHRASE("buscar")+' '+___PHRASE("tipoDeSolicitud")}
               searchData={searchData}
               // checkbox
               RecuperarData={RecuperarData}
            />
         </Container>

         { 
            showRequestTypeModal && (
               <ModalRequestType
                  open={showRequestTypeModal}
                  closeModal={()=>{setShowRequestTypeModal(false); setRequestTypeSelected('')}}
                  actionSelected={actionSelected}
                  onListRequestType={getRequestTypes}
                  requestType={requestTypeSelected}
                  clearState={()=>{setRequestTypeSelected('')}}
               />
            )
         }
         {
            actionSelected === 'delete' && (
               <ModalRequestTypeDelete
                  open={showRequestTypeDeleteModal}
                  closeModal={()=>{setShowRequestTypeDeleteModal(false)}}
                  actionSelected={actionSelected}
                  onListRequestType={getRequestTypes}
                  requestType={requestTypeSelected}
                  clearState={()=>{setRequestTypeSelected('')}}
               />
            )
         }
      
      </Protected>
   )
};