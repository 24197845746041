import { combineReducers } from 'redux';
import userReducer from './user.reducer';
import NotificationReducer from './Notification.reducer';
import CampanaReducer from './Campana.reducer';
import TranslateReducer from './Translate.reducer';

export default combineReducers({
  userReducer,
  NotificationReducer,
  CampanaReducer,
  TranslateReducer
});
