// import { Modal } from "../Modal";
// import { ModalBody } from "../ModalBody";
// import { ModalHeader } from "../ModalHeader";
// import { Formik, Form, Field, ErrorMessage } from 'formik';
// import { useEffect, useState } from "react";
// import { Autocomplete, Divider, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
// import { ModalFooter } from "../ModalFooter";
// import '../../../assets/styles/modals.scss';
// import { Loading } from "@/components/common/Loading";
// import { AssistanceService } from "@/service/services/Assistance.service";
// import { Props } from "./ModalContractVariables.type";

// export const ModalContractVariables: React.FC<Props> = (props:any): JSX.Element => {

//     const { open, closeModal, dataCompany, dataCompanySelected, setDataCompanySelected, variablesEmployee, variablesEmployeeSelected, setVariablesEmployeeSelected, evaluateVar} = props;

//     const onSubmit = async(values, {resetForm})  => {

        
//     }

//     let inputRef;

//     useEffect(()=>{
//         if(open) inputRef.focus();
//     }, [open])

//     return(
//         <>

//         <Modal open={open} handleClose={closeModal} handle size="sm">
//             <ModalBody >
//             {
//                 dataCompany.length > 0 && (
//                     <Grid style={{height: '400px'}}>
//                     <Autocomplete
//                         id="variables"
//                         disablePortal
//                         value={dataCompanySelected}
//                         options={dataCompany || []}
//                         getOptionLabel={(option) => option.name || ""}
//                         fullWidth
//                         openOnFocus
//                         renderInput={
//                             (params) => 
//                                 <TextField 
//                                 {...params}
//                                 autoFocus 
//                                 inputRef={input => {
//                                     inputRef = input;
//                                 }}
//                                 variant="standard" label={___PHRASE("escribeParaBuscar")} />
//                         }
//                         onChange={(e, newValue) =>{
//                             setDataCompanySelected(newValue || '')
//                             evaluateVar(e, newValue);
//                         }}
//                     />
//                     </Grid>
//                 )
//             }
//             {
//                 variablesEmployee.length > 0  && (
//                     <Grid style={{height: '400px'}}>
//                     <Autocomplete
//                         id="variables"
//                         disablePortal
//                         value={variablesEmployeeSelected}
//                         options={variablesEmployee || []}
//                         getOptionLabel={(option) => option.name || ""}
//                         fullWidth
//                         openOnFocus
//                         renderInput={
//                             (params) => 
//                             <TextField
//                                 {...params} 
//                                 inputRef={input => {
//                                     inputRef = input;
//                                 }}
//                                 variant="standard" label={___PHRASE("escribeParaBuscar")} autoFocus />
//                         }
//                         onChange={(e, newValue) =>{
//                             setVariablesEmployeeSelected(newValue || '')
//                             evaluateVar(e, newValue);
//                         }}
//                     />
//                     </Grid>
//                 )
//             }
//             </ModalBody>
//         </Modal>
//         </>
//     );
// }
import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { Autocomplete, Divider, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { Loading } from "@/components/common/Loading";
import { AssistanceService } from "@/service/services/Assistance.service";
import { Props } from "./ModalContractVariables.type";
import { useEffect } from "react";
import ___PHRASE from "@/service/translate/translateService";

export const ModalContractVariables: React.FC<any> = (props:any): JSX.Element => {

    const { open, closeModal, dataCompany, dataCompanySelected, setDataCompanySelected, variablesEmployee, variablesEmployeeSelected, setVariablesEmployeeSelected, evaluateVar} = props;

    let inputRef;

    useEffect(()=>{
        if(open) inputRef?.focus();
    }, [open])

    return(
        <>

        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalBody >
            {
                dataCompany.length > 0 && (
                    <Grid style={{height: '400px'}}>
                    <Autocomplete
                        id="variables"
                        disablePortal
                        value={dataCompanySelected}
                        options={dataCompany || []}
                        getOptionLabel={(option) => option.name || ""}
                        fullWidth
                        openOnFocus
                        renderInput={
                            (params) => 
                                <TextField 
                                {...params}
                                inputRef={input => {
                                    inputRef = input;
                                }}
                                autoFocus 
                                variant="standard"
                                label="Escribe para buscar" />
                        }
                        onChange={(e, newValue) =>{
                            setDataCompanySelected(newValue || null)
                            evaluateVar(e, newValue);
                        }}
                    />
                    </Grid>
                )
            }
            </ModalBody>
        </Modal>
        </>
    );
}