import { ViewsRepository } from "../repositories/Views.repository";

export const ViewsService = {
    getAllViews,
    getViewsByRoles,
    getAdditionalViews,
    getViewsByProfile,
    getViewsByProfileOfUser,
    getByModules,
    createView,
    editView,
    deleteView,
}

async function getAllViews(){
    const res = await ViewsRepository.getAllViews();
    return res;
}

async function getByModules(idmodules){
    const res = await ViewsRepository.getByModules(idmodules);
    return res;
}

async function getViewsByRoles(idroles){
    const res = await ViewsRepository.getViewsByRoles(idroles);
    return res;
}

async function getAdditionalViews(iduser:number){
    const res = await ViewsRepository.getAdditionalViews(iduser);
    return res;
}

async function getViewsByProfile(idprofile:number){
    const res = await ViewsRepository.getViewsByProfile(idprofile);
    return res;
}

async function getViewsByProfileOfUser(iduser:number){
    const res = await ViewsRepository.getViewsByProfileOfUser(iduser);
    return res;
}

async function createView(data: any){
    const res = await ViewsRepository.createView(data);
    return res;
}

async function editView( idView ,data: any){
    const res = await ViewsRepository.editView(idView, data);
    return res;
}

async function deleteView( idView){
    const res = await ViewsRepository.deleteView(idView);
    return res;
}