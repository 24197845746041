import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik } from 'formik';
import { useEffect, useState } from "react";
import { Alert, Autocomplete, Divider, Grid, Snackbar, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { Loading } from "@/components/common/Loading";
import { ConfigurationIPService } from "@/service/services/ConfigurationIP.service";
import { Props } from "./ModalIpDenied.type";
import { userService } from "@/service/services/User.service";
import { ROLE_SUPER_ADMIN } from "@/toolbox/constants/role-type";
import ___PHRASE from "@/service/translate/translateService";
import { apiResponseTranslate } from "@/toolbox/helpers/responseApi.helper";

export const ModalIpDenied: React.FC<Props> = (props:any): JSX.Element => {
    const { open, closeModal, actionSelected, userIp, onListIps, clearState } = props;

    const [loading, setLoading] = useState<boolean>(false);

    const [users, setUsers] = useState([]);
    const [userSelected, setUserSelected] = useState<any>('');

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });
    
    const [data, setData] = useState<any>({
        id: userIp ? userIp.id : '',
        ipDenied: userIp ? userIp.ipDenied : '',
        iduser:  userIp ? userIp.iduser : '',
        permission:  userIp ? userIp.permission : '',
    });

    useEffect(() => {
        getUsers();
    }, []);

    useEffect(() => {
        if(userIp && users){
            setCustomUser(userIp.iduser, users);
        }

    },[userIp, users]);

    const filterUSers = (users) => {
        let newArray = [];
        let arrayTemporal = [];
  
        for(let i=0; i<users.length; i++){
           arrayTemporal = newArray.filter(resp => resp["iduser"] == users[i]["iduser"]);
  
           if(arrayTemporal.length > 0){
              newArray[newArray.indexOf(arrayTemporal[0])]["roles"].push(users[i]["role"])
           }else{
              newArray.push(
              {
                'id' : i+1,
                'email' : users[i]["email"] , 
                'employee' : users[i]["employee"] , 
                'idemployee' : users[i]["idemployee"] , 
                'idprofile' : users[i]["idprofile"] , 
                'iduser' : users[i]["iduser"] , 
                'n_document' : users[i]["n_document"] , 
                'profile' : users[i]["profile"] , 
                'user_name' : users[i]["user_name"] , 
                'roles' : [users[i]["role"]]
              }
              )
           }
        }
  
        return newArray || [];
    }

    const getUsers = async () => {
        setLoading(true);

        const response = await userService.getUserByCompany();

        if (response.data) {
        let newUsers = [];

        const users = response.data;
        const newArray = filterUSers(users)
        
        newArray.length > 0 && newArray.map((item)=> {
            const validate = (item.roles).find(role => role != ROLE_SUPER_ADMIN)
            if(validate) newUsers.push({...item, 'roles': (item.roles).toString()});
        })

        setUsers(newUsers || [])
        setLoading(false);

        } else {
            console.log('error')
        }
        setLoading(false);
    }

    const validateForm = (values) => {
        let errors: any = {};
        let ipv4_regex = /^(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}$/;

        if(!values.ipDenied ) errors.ipDenied = apiResponseTranslate("ip requerido");
        if(values.ipDenied && !(ipv4_regex.test(values.ipDenied))) apiResponseTranslate("ip inválido");
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        if(!userSelected && !userIp){
            return (
               setSnackBarConfig(prev => ({ ...prev, open: true, 
                  severity:'warning', 
                  message: ___PHRASE(`debeSeleccionarUnUsuario`) }
               ))
            );
        }

        const formData = {
            id: values.id,
            ipDenied: values.ipDenied,
            iduser: userIp ? userIp.iduser : userSelected.iduser,
            permission : values.permission
        }

        setLoading(true);
        if(userIp && actionSelected=='edit'){
            await ConfigurationIPService.editDeniedIP(formData, userIp.id).then((data) => {
                let apimessage = apiResponseTranslate(data?.data?.message)
                if(data?.data?.status == true){
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'success', 
                        message: ___PHRASE('ipeditadosatisfactoriamente')}
                    ))
                    onListIps && onListIps();
                    setTimeout(function(){
                        closeModal && closeModal();
                        clearState && clearState();
                    }, 1000);
                }else{
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'error',
                        message: `${apimessage || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}`}
                    ))
                }
            })
        }else{
            await ConfigurationIPService.deniedIP(formData).then((data) => {
                let apimessage = apiResponseTranslate(data?.data?.message)
                if(data?.data?.status == true){
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'success',
                        message: ___PHRASE('ipcreadosatisfactoriamente')}
                    ))
                    onListIps && onListIps();
                    setTimeout(function(){
                        closeModal && closeModal();
                        clearState && clearState();
                    }, 1000);
                }else{
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'error',
                        message: `${apimessage || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}`}
                    ))
                }
            })
        }
        setLoading(false);
    }

    function setCustomUser(idUser, users): any {
        const data = users && users.find(user => user.iduser === idUser);
        const newData = {
            id: data?.id,
            iduser: data?.iduser,
            email: data?.email,
            user_name: data?.user_name,
            role: data?.role,
            n_document: data?.n_document,
        }
        return setUserSelected(newData);
    }

    return(
        <>

        {loading && <Loading/>}

        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>

        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick  handle size="sm">
            <ModalHeader
                text={(userIp && actionSelected=='edit') ? ___PHRASE('editarIP') : ___PHRASE('agregarIP')}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >

                                    <Grid item xs={12} container  marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>IP:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="ipDenied"
                                            variant="standard"
                                            type="text" 
                                            name="ipDenied"
                                            multiline
                                            maxRows={4}
                                            fullWidth
                                            value={values.ipDenied}
                                            onChange={handleChange}
                                            placeholder={___PHRASE('escribirIP')}
                                            error={errors.ipDenied && touched.ipDenied ? true : false}
                                        />
                                        </Grid>
                                    </Grid>

                                    {
                                        !userIp && (
                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE('usuario')}:</b></Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Autocomplete
                                                        id="disable-portal"
                                                        // disablePortal
                                                        value={userSelected}
                                                        options={users || []}
                                                        getOptionLabel={(option) => option.user_name || ""}
                                                        fullWidth
                                                        renderInput={(params) => <TextField {...params} variant="standard" label={___PHRASE("escribeParaBuscar")}/>}
                                                        onChange={(e, newValue) => {  setUserSelected(newValue) }}
                                                    />
                                                </Grid>
                                            </Grid> 
                                        )
                                    }                                  

                                    {/* {
                                        userIp && (
                                            <Grid container item xs={12} alignItems="center" justifyContent="center">
                                                <Grid item xs={4}>
                                                    <Typography variant="body1"><b>Permiso:</b> </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Select
                                                        id="permission"
                                                        name='permission'
                                                        variant="standard"
                                                        value={values.permission}
                                                        onChange={(event) => { 
                                                        setFieldValue("permission", event.target.value);
                                                        }}
                                                        fullWidth
                                                    >
                                                    <MenuItem key={1} value={1}>Aceptado</MenuItem>;
                                                    <MenuItem key={2} value={0}>Denegado</MenuItem>;
                                                        
                                                    </Select>
                                                </Grid>
                                            </Grid> 
                                        )
                                    } */}

                                </Grid>
                                <Divider style={{margin: '30px 0px 10px 0px'}}/>
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(userIp && actionSelected=='edit') ? ___PHRASE("actualizar") : ___PHRASE("registrar")}
                                    cancelText={___PHRASE("cancelar")} 
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}