import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik} from 'formik';
import { useEffect, useState } from "react";
import { Alert, Autocomplete, Divider, Grid, MenuItem, Select, Snackbar, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import { Loading } from "@/components/common/Loading";
import { Props } from "./ModalBranchOffice.type";
import { branchOfficeService } from "@/service/services/BranchOffice.service";
import { CountryService } from "@/service/services/Country.service";
import '../../../assets/styles/modals.scss';
import ___PHRASE from "@/service/translate/translateService";
import { apiResponseTranslate } from "@/toolbox/helpers/responseApi.helper";
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { KEY_TRANSLATE } from "@/toolbox/constants/local-storage";

export const ModalBranchOffice: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, branchOffice, onListBranchOffices, clearState } = props;
    const language = readLocalStorage(KEY_TRANSLATE);

    const [loading, setLoading] = useState<boolean>(false);
    const [branchTypes, setBranchTypes] = useState<any>([]);
    //for get idDistrict
    const [countries, setCountries] = useState<any>([]);
    const [countrySelected, setCountrySelected] = useState<any>('');
    const [departaments, setDepartaments] = useState<any>([]);
    const [departamentSelected, setDepartamentSelected] = useState<any>('');
    const [provinces, setProvinces] = useState<any>([]);
    const [provinceSelected, setProvinceSelected] = useState<any>('');
    const [districts, setDistricts] = useState<any>([]);
    const [districtSelected, setDistrictSelected] = useState<any>('');

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const [data, setData] = useState({
        id: branchOffice ? branchOffice.id : '',
        code: branchOffice ? branchOffice.code : '',
        address: branchOffice ? branchOffice.address : '',
        phone: branchOffice ? branchOffice.phone : '',
        idofficetype: branchOffice ? branchOffice.idofficetype : '1',
        iddistrict: branchOffice ? branchOffice.iddistrict : '',
    });

    useEffect(() => {
        getBranchTypes();
        if(!branchOffice) getCountries();
    }, []);

    useEffect(() => {
        if(branchOffice) getUbigeoSucursalSelected();
    }, [branchOffice]);

    useEffect(() => {
        if(open){
            if(branchOffice){
                setData({
                    id: branchOffice ? branchOffice.id : '',
                    code: branchOffice ? branchOffice.code : '',
                    address: branchOffice ? branchOffice.address : '',
                    phone: branchOffice ? branchOffice.phone : '',
                    idofficetype: branchOffice ? getCustomBranchType(branchOffice.idofficetype, branchTypes) : 1,
                    iddistrict: branchOffice ? branchOffice.iddistrict : '',
                })
            }
        }else{
            clearState()
        }
    }, [open, branchTypes]);

    const getBranchTypes = async() => {
        await branchOfficeService.getBranchTypes().then((data) => {
            const newData = [];
            data.data && (data.data).length > 0 && (data.data).map((item)=>{
                if(language && language == 'en'){
                    newData.push({...item, name: apiResponseTranslate(item.name)})
                }else{
                    newData.push(item)
                }
            })

            setBranchTypes(newData || []);
            // setBranchTypes(data.data || []);
        })
    }

    const getCountries = async () => {
        setLoading(true);
        await CountryService.getCountries().then((data) => {
            const newData = [];
            data.data && (data.data).length > 0 && (data.data).map((item)=>{
                if(language && language == 'en'){
                    newData.push({...item, name: apiResponseTranslate(item.name)})
                }else{
                    newData.push(item)
                }
            })

            setCountries(newData || [])
            setLoading(false);
        });
    }

    const getDepartments = async (idCountry) => {
        setLoading(true);
        await CountryService.getDepartments(idCountry).then((data) => {
            setDepartaments(data.data || [])
           setLoading(false);
        });
    }

    const getProvinces = async (idDepartament) => {
        setLoading(true);
        await CountryService.getProvinces(idDepartament).then((data) => {
            setProvinces(data.data || [])
           setLoading(false);
        });
    }

    const getDistricts = async (idProvince) => {
        setLoading(true);
        await CountryService.getDistricts(idProvince).then((data) => {
            setDistricts(data.data || [])
           setLoading(false);
        });
    }

    const getUbigeoSucursalSelected = async () => {
        setLoading(true);

        await CountryService.getCountries().then((data) => {
            const newData = [];
            data.data && (data.data).length > 0 && (data.data).map((item)=>{
                if(language && language == 'en'){
                    newData.push({...item, name: apiResponseTranslate(item.name)})
                }else{
                    newData.push(item)
                }
            })

            let countries = newData;
            setCountries(countries || []);
            let country = countries && countries.find(country => country.id == branchOffice?.idcountry);
            setCountrySelected(country.id || '')
        });

        await CountryService.getDepartments(branchOffice?.idcountry).then((data) => {
            let departaments = data.data;
            setDepartaments(departaments || [])
            let departament = departaments && departaments.find(departament => departament.id == branchOffice?.iddepartament);
            setDepartamentSelected(departament || '')
        });

        await CountryService.getProvinces(branchOffice?.iddepartament).then((data) => {
            let provinces = data.data;
            setProvinces(provinces || []);
            let province = provinces && provinces.find(province => province.id == branchOffice?.idprovince);
            setProvinceSelected(province || '');
        });

        await CountryService.getDistricts(branchOffice?.idprovince).then((data) => {
            let districtis = data.data;
            setDistricts(districtis);
            let district = districtis && districtis.find(district => district.id == branchOffice?.iddistrict);
            setDistrictSelected(district || '');
        });

        setLoading(false);
    }

    const validateForm = (values) => {
        let errors: any = {};
        let regPhone = /^(\+\d{1,3}( )?)?((\(\d{1,3}\))|\d{1,3})[- .]?\d{3,4}[- .]?\d{4}$/;

        if(!values.code) errors.code = ___PHRASE("codigoRequerido");
        if(!values.address) errors.address = ___PHRASE("direccionRequerido");
        if(!values.phone) errors.phone = ___PHRASE("telefonoRequerido");
        if(!(values.phone.match(regPhone))) errors.phone = ___PHRASE("telefonoNoValido");
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        if(!districtSelected){
            return (
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                    severity:'warning', 
                    message: ___PHRASE("debeSeleccionarUnDistrito") }
                ))
            );
        }
        const formData = {
            id: values.id,
            code: values.code,
            address : values.address,
            phone : values.phone,
            idofficetype : values.idofficetype,
            iddistrict : districtSelected?.id,
        }
        setLoading(true);
        if(branchOffice && actionSelected=='edit'){
            await branchOfficeService.editBranch(formData, branchOffice.id).then((data) => {
                let apimessagge = apiResponseTranslate(data?.data?.message || '');
                if(data.data.status == 'true') {
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'success', 
                        message: `${ apimessagge || ___PHRASE("registroActualizadoConExito")}` }
                      ));
                    onListBranchOffices && onListBranchOffices();
                    setTimeout(function(){
                        closeModal && closeModal();
                        clearState && clearState();
                    }, 1000);
                }else{
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'error', 
                        message: `${apimessagge || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")} ` }
                    ))
                }
            })
        }else{
            await branchOfficeService.createBranch(formData).then((data) => {
                let apimessagge = apiResponseTranslate(data?.data?.message || '');
                if(data.data.status == 'true') {
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'success', 
                        message: `${ apimessagge || ___PHRASE("creadoConExito")}` }
                      ));
                    onListBranchOffices && onListBranchOffices();
                    setTimeout(function(){
                        closeModal && closeModal();
                        clearState && clearState();
                    }, 1000);
                }else{
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'error', 
                        message: `${apimessagge|| ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")} ` }
                    ))
                }
            })
        }
        setLoading(false);
    }

    const getCustomBranchType = (oldIdofficetype, branchTypes) => {
        const customRol = branchTypes.find(rol => rol.id == oldIdofficetype);
        return  customRol ? customRol.id : ''
    }

    return(
        <>
        {loading && <Loading/>}
        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>

        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick handle size="sm">
            <ModalHeader
                text={((branchOffice && actionSelected=='edit') ? ___PHRASE("editar") : ___PHRASE("agregar")) +' '+ ___PHRASE("sucursal")}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("codigo")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="code"
                                            variant="standard"
                                            type="text" 
                                            name="code"
                                            fullWidth
                                            value={values.code}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("codigoDeSucursal")}
                                            error={errors.code && touched.code ? true : false}
                                            helperText={errors.code && touched.code ? errors.code : ""}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>{___PHRASE("tipoDeSucursal")}:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Select
                                                id="idofficetype"
                                                variant="standard"
                                                value={values.idofficetype}
                                                onChange={(event) => { 
                                                setFieldValue("idofficetype", event.target.value);
                                                }}
                                                error={ errors.idofficetype && touched.idofficetype ? true : false }
                                                fullWidth
                                            >
                                                {
                                                branchTypes.map((bracnhType, i) => {
                                                    // let formatRole = {...bracnhType, name: getCustomBranchType(bracnhType.name)};
                                                    return <MenuItem key={i} value={bracnhType.id}>{bracnhType.name}</MenuItem>;
                                                })
                                                }
                                            </Select>
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>{___PHRASE("pais")}:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Select
                                                id="id_nationality"
                                                variant="standard"
                                                value={countrySelected}
                                                onChange={(event) => { 
                                                    setCountrySelected(event.target.value);
                                                    setDepartamentSelected('');
                                                    setProvinceSelected('');
                                                    setDistrictSelected('');
                                                }}
                                                fullWidth
                                            >
                                                {
                                                countries.map((country, i) => {
                                                    return <MenuItem key={i} value={country.id} onClick={ () => {getDepartments(country.id)}}>{country.name}</MenuItem>;
                                                })
                                                }
                                            </Select>
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>{___PHRASE("departamento")}:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Autocomplete
                                                id="disable-portal"
                                                disablePortal
                                                value={departamentSelected}
                                                options={departaments || []}
                                                getOptionLabel={(option) => option.name || ""}
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} variant="standard" label={___PHRASE("escribeParaBuscar")} />}
                                                onChange={(e, newValue) => { 
                                                    setDepartamentSelected(newValue); 
                                                    setProvinceSelected('');
                                                    setDistrictSelected('');
                                                    newValue && getProvinces(newValue?.id);
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>{___PHRASE("provincia")}:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Autocomplete
                                                id="disable-portal"
                                                disablePortal
                                                value={provinceSelected}
                                                options={provinces || []}
                                                getOptionLabel={(option) => option.name || ""}
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} variant="standard" label={___PHRASE("escribeParaBuscar")} />}
                                                onChange={(e, newValue) => { 
                                                    setProvinceSelected(newValue);
                                                    setDistrictSelected('');
                                                    newValue && getDistricts(newValue?.id); 
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="body1"><b>{___PHRASE("distrito")}:</b> </Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Autocomplete
                                                id="disable-portal"
                                                disablePortal
                                                value={districtSelected}
                                                options={districts || []}
                                                getOptionLabel={(option) => option.name || ""}
                                                fullWidth
                                                renderInput={(params) => <TextField {...params} variant="standard" label={___PHRASE("escribeParaBuscar")} />}
                                                onChange={(e, newValue) => { setDistrictSelected(newValue) }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("direccion")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="address"
                                            variant="standard"
                                            type="text" 
                                            name="address"
                                            multiline
                                            maxRows={4}
                                            fullWidth
                                            value={values.address}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("direccion")}
                                            error={errors.address && touched.address ? true : false}
                                            helperText={errors.address && touched.address ? errors.address : ""}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("telefono")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="phone"
                                            variant="standard"
                                            type="text" 
                                            name="phone"
                                            multiline
                                            maxRows={4}
                                            fullWidth
                                            value={values.phone}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("telefono")}
                                            error={errors.phone && touched.phone ? true : false}
                                            helperText={errors.phone && touched.phone ? errors.phone : ""}
                                        />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(branchOffice && actionSelected=='edit') ? ___PHRASE("actualizar") : ___PHRASE("registrar")}
                                    cancelText={___PHRASE("cancelar")}
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}