import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Loading } from '@/components/common/Loading';
import { Alert, Box, Button, Card, Container, Grid, MenuItem, Select, Snackbar, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { ReportService } from '@/service/services/Report.service';
import { PeriodService } from '@/service/services/Period.service';
import moment from 'moment';
import { moneyFormat } from '@/toolbox/helpers/money.helper';
import ___PHRASE from '@/service/translate/translateService';
import { apiResponseTranslate } from '@/toolbox/helpers/responseApi.helper';

const Div = styled('div')(({ theme }) => ({
    ...theme.typography.button,
    padding: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: '20px',
    color: 'rgba(102, 102, 102, 0.4)',
}));

export const ReportLiquidationByProject: React.FC<any> = (props: any): JSX.Element => {
   
    const [loading, setLoading] = useState<boolean>(false);
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });
    
    const [periods, setPeriods] = useState<any>([]);
    const [years, setYears] = useState<any>([]);
    const [yearSelected, setYearSelected] = useState<any>(moment().format('YYYY'));
    const [monthSelected, setMonthSelected] = useState<any>(moment().format('MM'));
    const [reports, setReports] = useState<any>([]);
    const [totalProject, setTotalProject] = useState<any>(0);
    const [totalProjectFilter, setTotalProjectFilter] = useState<any>(0);

    useEffect(()=> {
        getPeriods();
    },[]);

    useEffect(()=> {
        if(periods.length > 0) getReportliquidationByProjects();
    },[periods]);
  
     const getPeriods = async() => {
        setLoading(true);
        const resp:any = await PeriodService.getPeriods();
        const newData = [];
        const arrayYears = [];
        resp.data && (resp.data).length > 0 && (resp.data).map((item) => {
           newData.push({
              ...item,
              alias_name: item.month + '-' +  item.year,
           })
           const found = arrayYears.find(element => element == item.year);
           if(!found) { arrayYears.push(item.year); }
           
        });
        setYears(arrayYears);
        setPeriods(newData || []);
        setLoading(false);
    }

    const getReportliquidationByProjects = async () => {
        setReports([]);

        if(!monthSelected || !yearSelected){
            setLoading(false);
            return (
               setSnackBarConfig(prev => ({ ...prev, open: true, 
                  severity:'warning', 
                  message: ___PHRASE("debeSeleccionarMesyAno") }
               ))
            );
        }

        const anioActual = new Date().getFullYear()

        if(yearSelected > anioActual ) {
            setLoading(false);
            return (
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                   severity:'warning', 
                   message: ___PHRASE("asegureseQueLaFechaSeleccionadaSeaMenorQueLaFechaActual") }
                ))
             );
        }
        const mesActual = new Date().getMonth() + 1;

        if(yearSelected == anioActual && mesActual < monthSelected){
            setLoading(false);

            return (
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                   severity:'warning', 
                   message: ___PHRASE("asegureseQueLaFechaSeleccionadaSeaMenorQueLaFechaActual") }
                ))
             );
        }
        

        const periodSelected = monthSelected + '-' + yearSelected;
        const period = periods.find(element => element.alias_name == periodSelected);

        setLoading(true);

        await ReportService.getReportliquidationByProjects(period.id).then((data) => {
            console.log(data)
            let apimessage = apiResponseTranslate(data?.data?.message || '')
            if(data?.data?.status == false){
                console.log(2)
                if (apimessage.includes(yearSelected)) {
                    apimessage = apimessage.replace(yearSelected, '');
                    apimessage = apiResponseTranslate(apimessage) + ' ' +yearSelected
                    console.log(3)
                }
                setLoading(false);
                return (
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'warning', 
                        message: `${apimessage || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}` }
                    ))
                );
            }

            console.log(4)
            let projects = data?.data || [];
            let projectWithFilter = [];

            projects.length > 0 && projects.map((item)=> {
                let startProject = moment(item.start_date).format('MM-YYYY');
                let endProject = moment(item.end_date).format('MM-YYYY');
                let periodSelected = moment(yearSelected + '-' + monthSelected + '-' + '01').format('MM-YYYY');
                console.log(5)
                if(periodSelected >= startProject && periodSelected <= endProject) projectWithFilter.push(item);
            });

            console.log(6)
            setTotalProject(projects.length || 0);
            setTotalProjectFilter(projectWithFilter.length || 0);
            setReports(projectWithFilter || []);
            setLoading(false);
        })
    }

    return (
        <Protected>
            {loading && <Loading/>}
            <Snackbar
                open={snackBarConfig.open} 
                autoHideDuration={snackBarConfig.autoHideDuration} 
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
                <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                    {snackBarConfig.message || ""}
                </Alert>
            </Snackbar>

            <Container>
                <Typography variant='h6' component='h1' gutterBottom className="tittle" sx={{ m: 1, fontWeight: 600 }}>
                    {___PHRASE("reporteDeLiquidacionesPorProyecto")}
                </Typography>

                <Grid container xs={12} direction="row" justifyContent="flex-start" alignItems="center">
                <Grid xs={3} display='flex' alignItems="center" 
                        sx={{border: 'solid 1px #e9e2e2', borderRadius:'10px', padding: '5px 8px 8px 8px', marginRight: '10px'}}>
                        <Grid item xs={4}>
                            <Typography variant="body1"><b>{___PHRASE("periodo")}:</b> </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Select
                                id="id_education_level"
                                variant="standard"
                                value={yearSelected}
                                onChange={(event) => {
                                setYearSelected(event.target.value);
                                }}
                                fullWidth
                            >
                                {
                                years.map((year, i) => {
                                    return <MenuItem key={i} value={year}>{year}</MenuItem>;
                                })
                                }
                            </Select>
                        </Grid>
                    </Grid>
                    <Grid xs={3} display='flex' alignItems="center" 
                        sx={{border: 'solid 1px #e9e2e2', borderRadius:'10px', padding: '5px 8px 8px 8px', marginRight: '10px'}}>
                        <Grid item xs={4}>
                            <Typography variant="body1">{___PHRASE("mes")} </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Select
                                id="id_education_level"
                                variant="standard"
                                value={monthSelected}
                                onChange={(event) => {setMonthSelected(event.target.value)}}
                                fullWidth
                            >
                                <MenuItem value={'01'}>{___PHRASE("enero")}</MenuItem>
                                <MenuItem value={'02'}>{___PHRASE("febrero")}</MenuItem>
                                <MenuItem value={'03'}>{___PHRASE("marzo")}</MenuItem>
                                <MenuItem value={'04'}>{___PHRASE("abril")}</MenuItem>
                                <MenuItem value={'05'}>{___PHRASE("mayo")}</MenuItem>
                                <MenuItem value={'06'}>{___PHRASE("junio")}</MenuItem>
                                <MenuItem value={'07'}>{___PHRASE("julio")}</MenuItem>
                                <MenuItem value={'08'}>{___PHRASE("agosto")}</MenuItem>
                                <MenuItem value={'09'}>{___PHRASE("septiembre")}</MenuItem>
                                <MenuItem value={'10'}>{___PHRASE("octubre")}</MenuItem>
                                <MenuItem value={'11'}>{___PHRASE("noviembre")}</MenuItem>
                                <MenuItem value={'12'}>{___PHRASE("diciembre")}</MenuItem>
                            </Select>
                        </Grid>
                    </Grid>
                    <Grid xs={2} display='flex'>
                        <Button onClick={getReportliquidationByProjects} type={'submit'} size="small" color={"primary"} variant="contained" >
                            {___PHRASE("filtrar")}
                        </Button>
                    </Grid>
                </Grid>

                {
                    loading && (
                        <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'150px', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '20px' }}>
                            <Div>{___PHRASE("cargandoDatosEspereUnMomento")}</Div>
                        </Box>
                    )
                }

                {
                    !loading && (
                        <Grid container xs={12} sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px'}}>
                            <Grid item xs={9} sx={{display: 'flex', alignItems: 'center'}}>
                                <Typography variant="subtitle2" className="custom-input" 
                                    sx={{ border: `1px #81a4cb solid`, borderRadius:'8px', padding: '5px', color: '#707ac3', width:'450px', textAlign: 'center', marginRight: '5px'}}>
                                    {___PHRASE("seEncontro")+' '+ totalProjectFilter+' '+___PHRASE("proyectosEnElPeriodoyMesSeleccionado")}
                                </Typography>

                                <Typography variant="subtitle2" className="custom-input" 
                                    sx={{ border: `1px #81a4cb solid`, borderRadius:'8px', padding: '5px', color: '#707ac3', width:'180px',textAlign: 'center' }}>
                                    {___PHRASE("totalProyectos")+': '+ totalProject}.
                                </Typography>
                            </Grid>
                        </Grid>
                    )
                }

                {
                    (reports.length == 0 && !loading) && (
                        <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'150px', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '20px' }} >
                            <Div>{___PHRASE("noSeEncontraronDatos")}</Div>
                        </Box>
                    )
                }
                
                {
                    (reports.length > 0 && !loading) && reports.map((item)=>(
                        <>
                        <Grid container sx={{ width: "100%"}}>
                        <Card sx={{ width: "100%", marginTop: '20px', padding: '10px', marginBottom: '20px'}}>
                            <Grid sx={{ width: "100%", padding: '20px 20px 10px 20px'}}>
                                <Typography variant="h6" sx={{ fontWeight:'bold', color:'#16556b' }}>
                                    {(___PHRASE("proyecto")+` ${item.name}`).toUpperCase()}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ pt: 1 }}>
                                    {`${(___PHRASE("descripcionDelProyecto")).toUpperCase()} ${item.description || '---'}`}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ pt: 1 }}>
                                    {`${(___PHRASE("costoTotalDelProyecto")+': ').toUpperCase()} ${moneyFormat(parseFloat(item.projected_total || 0))}`}
                                </Typography>
                                <Grid display='flex' sx={{ width: "100%"}}>
                                    <Typography variant="subtitle1" sx={{ pt: 1, marginRight: '40px' }}>
                                    {`${(___PHRASE("fechaInicio")+':').toUpperCase()} ${item.start_date}`}
                                    </Typography>
                                    <Typography variant="subtitle1" sx={{ pt: 1 }}>
                                    {`${(___PHRASE("fechaFin")+':').toUpperCase()} ${item.end_date}`}
                                    </Typography>
                                </Grid>
                            </Grid>

                            {
                                (item.details).length > 0 ? (
                                    <Grid sx={{ width: "100%", padding: '10px 20px 20px 20px'}}>
                                        <Typography variant="h6" sx={{ marginBottom: '10px', fontWeight:'bold', color: '#16556b'}}>
                                            {(___PHRASE("detalleDeParticipacionEnElPeriodoSeleccionado")).toUpperCase()}
                                        </Typography>
                                        <TableContainer>
                                            <Table>
                                                <TableHead sx={{ bgcolor: '#EDEFF1' }}>
                                                    <TableRow >
                                                        <TableCell >
                                            <Typography>{ ___PHRASE("nombreDelEmpleado")}</Typography>
                                                        </TableCell>
                                                        <TableCell >
                                                            <Typography>{___PHRASE("porcentajeDeParticipacion")}</Typography>
                                                        </TableCell>
                                                        <TableCell >
                                                            <Typography>Total</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>

                                                <TableBody>
                                                    {
                                                        (item.details).map((detail)=>(
                                                            <TableRow >
                                                                <TableCell size={'small'}>
                                                                    {detail.name +' '+ detail.surname +' '+ detail.second_surname }
                                                                </TableCell>
                                                                <TableCell size={'small'}>
                                                                    {detail.participation_percentage +' %' }
                                                                </TableCell>
                                                                <TableCell size={'small'}>
                                                                    {moneyFormat(parseFloat(detail.total_participation))}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))
                                                    }
                                                    <TableRow>
                                                        <TableCell colSpan={2}>TOTAL</TableCell>
                                                        <TableCell align="left">{moneyFormat(parseFloat(item.SumTotalEmployee))}</TableCell>
                                                    </TableRow>
                                                    
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                ) : (
                                    <Grid item xs={12} container alignItems="center" marginBottom='20px' paddingLeft='20px'>
                                        <Typography variant="subtitle2" className="custom-input" sx={{ border: '1px #e37474 solid', borderRadius:'8px', padding: '5px', color:'#df5959'}}
                                        >
                                            {___PHRASE("noSeEncontroParticipacionParaElPeriodoyMesSeleccionado")}
                                        </Typography>
                                    </Grid>
                                )
                            }

                        </Card>
                        </Grid>
                        </>
                    ))
                }

            </Container>
        </Protected>
    )
};