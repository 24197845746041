import { http } from '../http/http';
import { API_URL_BASE } from '@toolbox/defaults/app';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';

const dataUser = readLocalStorage(KEY_USER_DATA);
const rut_empresa = dataUser?.user?.rut_empresa;

export const ModuleRepository = {

    getAllModules: async(): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/module`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    getByRoles: async(idroles): Promise<any> => {
        const res = await http.get<any>(`${API_URL_BASE}/v1/module/getByRoles?idroles=${idroles}`);
        const {data, error, message} = res;
        return { data, error, message };
    },

    createModule: async (dataModule) : Promise<any> => {
        const res = await http.post<any>(`${API_URL_BASE}/v1/module`, {
            code: dataModule.code,
            name: dataModule.name,
            description: dataModule.description,
            icon: dataModule.icon,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    editModule: async (id, dataModule) : Promise<any> => {
        const res = await http.patch<any>(`${API_URL_BASE}/v1/module/${id}`, {
            code: dataModule.code,
            name: dataModule.name,
            description: dataModule.description,
            icon: dataModule.icon,
        });
        const {data, error, message} = res;
        return { data, error, message };
    },

    deleteModule: async(id): Promise<any> => {
        const res = await http.delete<any>(`${API_URL_BASE}/v1/module/${id}`)
        const {data, error, message} = res;
        return { data, error, message };
    }
}   