import ___PHRASE from "@/service/translate/translateService";
import { apiResponseTranslate } from "@/toolbox/helpers/responseApi.helper";
import { Grid, Typography, List, ListItem, ListItemText, Divider } from "@mui/material";
import { styled } from '@mui/material/styles';

const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
}));

export const HaberEmployee = (props: any): JSX.Element => {
    const { data } = props;

    return (
        <>
        <Grid display='flex'>
            {
                data.TotalHINT && (data.TotalHINT).length > 0 && (
                    <Grid item xs={12} md={6}>
                         <Typography variant="body2" style={{marginTop: '10px'}}>{___PHRASE("HABERESIMPONIBLESYNOTRIBUTABLES")}</Typography>
                        <Demo>
                            <List>
                                {
                                    (data.TotalHINT).map((item) => (
                                        <ListItem>
                                            <ListItemText style={{minWidth: '230px', maxWidth: '230px'}}>
                                                <Typography variant="body2" >{apiResponseTranslate(item.alias_name)}</Typography>
                                            </ListItemText>
                                            <ListItemText >
                                                <Typography variant="body2">{`: $ ${item.value}`}</Typography>
                                            </ListItemText>
                                        </ListItem>
                                    ))
                                }
                                <ListItem>
                                    <ListItemText style={{minWidth: '230px', maxWidth: '230px'}}>
                                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{___PHRASE("totalHaberesImpNoTrib")}</Typography>
                                    </ListItemText>
                                    <ListItemText >
                                        <Typography variant="body2">{`: $ ${data.THINT}`}</Typography>
                                    </ListItemText>
                                </ListItem>
                            </List>
                        </Demo>
                    </Grid>
                )
            }

            {
                data.TotalHNIT && (data.TotalHNIT).length > 0 && (
                    <Grid item xs={12} md={6}>
                        <Typography variant="body2" style={{marginTop: '10px'}}>{___PHRASE("HABERESNOIMPONIBLESYTRIBUTABLES")}</Typography>
                        <Demo>
                            <List>
                                {
                                    (data.TotalHNIT).map((item) => (
                                        <ListItem>
                                            <ListItemText style={{minWidth: '230px', maxWidth: '230px'}}>
                                                <Typography variant="body2">{item.code} - {apiResponseTranslate(item.alias_name)}</Typography>
                                            </ListItemText>
                                            <ListItemText >
                                                <Typography variant="body2">{`: $ ${item.value}`}</Typography>
                                            </ListItemText>
                                        </ListItem>
                                    ))
                                }
                                <ListItem>
                                    <ListItemText style={{minWidth: '230px', maxWidth: '230px'}}>
                                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{___PHRASE("totalHaberesNoImpytrib")}</Typography>
                                    </ListItemText>
                                    <ListItemText >
                                        <Typography variant="body2">{`: $ ${data.THNIT}`}</Typography>
                                    </ListItemText>
                                </ListItem>
                            </List>
                        </Demo>
                    </Grid>
                )
            }
        </Grid>

        <Divider></Divider>

        <Grid display='flex'>
            {
                data.TotalHIT && (data.TotalHIT).length > 0 && (
                    <Grid item xs={12} md={6}>
                         <Typography variant="body2" style={{marginTop: '10px'}}>{___PHRASE("HABERESIMPONIBLESYTRIBUTABLES")}</Typography>
                        <Demo>
                            <List>
                                {
                                    (data.TotalHIT).map((item) => (
                                        <ListItem>
                                            <ListItemText style={{minWidth: '230px', maxWidth: '230px'}}>
                                                <Typography variant="body2" >{item.code} - {apiResponseTranslate(item.alias_name)}</Typography>
                                            </ListItemText>
                                            <ListItemText >
                                                <Typography variant="body2">{`: $ ${item.value}`}</Typography>
                                            </ListItemText>
                                        </ListItem>
                                    ))
                                }
                                <ListItem>
                                    <ListItemText style={{minWidth: '230px', maxWidth: '230px'}}>
                                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{___PHRASE("totalHaberesImpTrib")}</Typography>
                                    </ListItemText>
                                    <ListItemText >
                                        <Typography variant="body2">{`: $ ${data.THIT}`}</Typography>
                                    </ListItemText>
                                </ListItem>
                            </List>
                        </Demo>
                    </Grid>
                )
            }

            {
                data.TotalHNINT && (data.TotalHNINT).length > 0 && (
                    <Grid item xs={12} md={6}>
                        <Typography variant="body2" style={{marginTop: '10px'}}>{___PHRASE("HABERESNOIMPONIBLESYNOTRIBUTABLES")}</Typography>
                        <Demo>
                            <List>
                                {
                                    (data.TotalHNINT).map((item) => (
                                        <ListItem>
                                            <ListItemText style={{minWidth: '230px', maxWidth: '230px'}}>
                                                <Typography variant="body2">{apiResponseTranslate(item.alias_name)}</Typography>
                                            </ListItemText>
                                            <ListItemText >
                                                <Typography variant="body2">{`: $ ${item.value}`}</Typography>
                                            </ListItemText>
                                        </ListItem>
                                    ))
                                }
                                <ListItem>
                                    <ListItemText style={{minWidth: '230px', maxWidth: '230px'}}>
                                        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{___PHRASE("totalHaberesNoImpyNotrib")}</Typography>
                                    </ListItemText>
                                    <ListItemText >
                                        <Typography variant="body2">{`: $ ${data.THNINT}`}</Typography>
                                    </ListItemText>
                                </ListItem>
                            </List>
                        </Demo>
                    </Grid>
                )
            }
        </Grid>
        <Divider></Divider>
        <Grid display='flex'>
            <Grid item xs={12} md={6}>
                <Demo>
                    <List>
                        <ListItem>
                            <ListItemText style={{minWidth: '200px', maxWidth: '200px'}}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{___PHRASE("sueldobase")}</Typography>
                            </ListItemText>
                            <ListItemText >
                                <Typography variant="body2">{`: $ ${data.base_salary}`}</Typography>
                            </ListItemText>
                        </ListItem>
                    </List>
                </Demo>
            </Grid>

            <Grid item xs={12} md={6}>
                <Demo>
                    <List>
                        <ListItem>
                            <ListItemText style={{minWidth: '200px', maxWidth: '200px'}}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold'}}>{___PHRASE("gratificacionLegalTope")}</Typography>
                            </ListItemText>
                            <ListItemText >
                                <Typography variant="body2">{`: $ ${data.legal_gratification}`}</Typography>
                            </ListItemText>
                        </ListItem>
                    </List>
                </Demo>
            </Grid>

        </Grid>
        <Divider></Divider>
        <Grid display='flex'>
            <Grid item xs={12} md={6}>
                <Demo>
                    <List>
                        <ListItem>
                            <ListItemText style={{minWidth: '200px', maxWidth: '200px'}}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold'}}>{___PHRASE("TOTALHABERES")}</Typography>
                            </ListItemText>
                            <ListItemText >
                                <Typography variant="body2">{`: $ ${data.TH}`}</Typography>
                            </ListItemText>
                        </ListItem>
                    </List>
                </Demo>
            </Grid>
        </Grid>
        </>
    )
}