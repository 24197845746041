import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik } from 'formik';
import { useState } from "react";
import { Divider, Grid, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { Loading } from "@/components/common/Loading";
import { Props } from "./ModalDepartamentResponsability.type";
import { departmentService } from "@/service/services/Deparment.service";
import ___PHRASE from "@/service/translate/translateService";

export const ModalDepartamentResponsability: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, responsability, onListResponsability, clearState, areaSelected } = props;

    const [loading, setLoading] = useState<boolean>(false);

    const [data, setData] = useState({
        id: responsability ? responsability.id : '',
        description: responsability ? responsability.description : '',
    });

    const validateForm = (values) => {
        let errors: any = {};
        if(!values.description) errors.description = ___PHRASE("descripcionRequerido");
        if(values.description.length > 150) errors.description = ___PHRASE("noPuedeExcederLos150Caracteres");
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        const formData = {
            description: values.description,
            idbusiness_area : areaSelected.id
        }
        setLoading(true);
        if(responsability && actionSelected=='edit'){
            await departmentService.editResponsibilities(formData, responsability.id)
            areaSelected && onListResponsability(areaSelected.id);
            closeModal && closeModal();
        }else{
            await departmentService.createResponsibilities(formData).then((data) => {
                areaSelected && onListResponsability(areaSelected.id);
                closeModal && closeModal();
            })
        }
        setLoading(false);
    }
   
    return(
        <>
        {loading && <Loading/>}

        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={((responsability && actionSelected=='edit') ? ___PHRASE("editar") : ___PHRASE("agregar"))+___PHRASE("responsabilidad")}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container>
                                    <Grid item xs={12} container  marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography style={{verticalAlign: 'text-top'}} variant="subtitle1" className="custom-input"><b>{___PHRASE("descripcion")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="description"
                                            variant="standard"
                                            type="textArea"
                                            multiline
                                            rows={4}
                                            maxRows={4}
                                            name="description"
                                            fullWidth
                                            value={values.description}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("descripcion")}
                                            error={errors.description && touched.description ? true : false}
                                            helperText={errors ? errors.description : ''}
                                        />
                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(responsability && actionSelected=='edit') ? ___PHRASE("actualizar") : ___PHRASE("registrar")}
                                    cancelText={___PHRASE("cancelar")}
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}