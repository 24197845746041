import {
  TRANSLATE,
  UPDATETRANSLATE
} from '@constants/action-type' 

export function actionSetTranslate(data: any) {
   return {
      type: TRANSLATE,
      payload: data
   }
}

export function actionSetUpdateTranslate(data: any) {
   return {
      type: UPDATETRANSLATE,
      payload: data
   }
}