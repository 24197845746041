import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik } from 'formik';
import { useState } from "react";
import { Autocomplete, Divider, Grid, Alert, Snackbar, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import '../../../assets/styles/modals.scss';
import { Loading } from "@/components/common/Loading";
import { Props } from "./ModalAssistance.type";
import { AssistsWorkerService } from "@/service/services/AssistanceWorker.service";
import moment from "moment";
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { KEY_TRANSLATE, KEY_USER_DATA } from "@/toolbox/constants/local-storage";
import { ROLE_TRABAJADOR } from "@/toolbox/constants/role-type";
import { IntegrationDiviceService } from "@/service/services/IntegrationDiviceService";
import ___PHRASE from "@/service/translate/translateService";
import { apiResponseTranslate } from "@/toolbox/helpers/responseApi.helper";

export const ModalAssistance: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, assistance, onListAssistances, workerSelected, clearState, massive, workers, isIntegration, startDateSelected, endDateSelected } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [workersSelected, setWorkersSelected] = useState<any>('');

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonthJS = currentDate.getMonth() + 1; //getMonth() su indice empieza desde 0
    const currentMonthFilter = currentMonthJS <= 9 ? `0${currentMonthJS}` : `${currentMonthJS}`;
    const startDate = currentYear+'-'+currentMonthFilter+'-01';
    const endDate = moment().format("YYYY-MM-DD");
    let previousDate = startDateSelected ? startDateSelected : startDate;
    let nexDate = endDateSelected ? endDateSelected : endDate;

    const dataUser = readLocalStorage(KEY_USER_DATA);
    const language = readLocalStorage(KEY_TRANSLATE);

    const role = dataUser?.user?.role;
    const onBoarding = dataUser?.user?.on_boarding

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const [data, setData] = useState({
        id: assistance ? assistance.id : '',
        idemployee: assistance ? assistance.idemployee : '',
        date: assistance ? assistance.date : '',
        start_time: assistance ? assistance.start_time : '',
        start_refreshment: assistance ? assistance.start_refreshment : '',
        end_refreshment: assistance ? assistance.end_refreshment : '',
        end_time: assistance ? assistance.end_time : '',
        observations: assistance ? assistance.observations : '',
        start_date: '',
        end_date: '',
    });

    const validateForm = (values) => {
        let errors: any = {};
        if(massive && !values.start_date) errors.start_date = ___PHRASE("fecharequerido");
        if(massive && !values.end_date) errors.end_date = ___PHRASE("fecharequerido");
        if(!massive && !values.date) errors.date = ___PHRASE("fecharequerido");

        if(!values.start_time) errors.start_time = ___PHRASE("horaRequerido");
        if(!values.start_refreshment) errors.start_refreshment = ___PHRASE("horaRequerido");
        if(!values.end_refreshment) errors.end_refreshment = "fechoraha requerido";
        if(!values.end_time) errors.end_time = ___PHRASE("horaRequerido");
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        let arrayIdsEmployees= [];

        let currentDate = moment().format("YYYY/MM/DD");
        let currentMonth = moment().format("MMMM-YYYY");
        let lastDayOfMonth = moment().endOf('month').format("YYYY/MM/DD");

        let dateStart= '';
        let dateEnd= '';
        if(values.start_date) dateStart = moment(values.start_date).format("YYYY/MM/DD");
        if(values.end_date) dateEnd = moment(values.end_date).format("YYYY/MM/DD");
        
        if(values.start_time > values.start_refreshment || values.start_refreshment > values.end_refreshment || 
            values.end_refreshment > values.end_time) return (
            setSnackBarConfig(prev => ({ ...prev, open: true, 
                severity:'warning', 
                message: ___PHRASE("asegureseDeQueLasHorasSeEncuentrenDeManeraAscendente") }
            ))
        );

        if(values.start_date && values.end_date){
            if(dateStart > dateEnd) return (
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                    severity:'warning', 
                    message: ___PHRASE("asegureseQueLaFechaInicialSeaMenorQueLaFinal") }
                ))
            );

            if(onBoarding == "0"){
                // if(dateStart > currentDate || dateEnd > currentDate ) return (
                //     setSnackBarConfig(prev => ({ ...prev, open: true, 
                //         severity:'warning', 
                //         message: ___PHRASE("asegureseQueLaFechaDeInicioyFinSeaMenorQueLaFechaActual") }
                //     ))
                // );
        
                // if(currentMonth != moment(values.start_date).format("MMMM-YYYY") || currentMonth != moment(values.end_date).format("MMMM-YYYY")) return (
                //     setSnackBarConfig(prev => ({ ...prev, open: true, 
                //         severity:'warning', 
                //         message: ___PHRASE("elMesDeLasFechasSeleccionadasDebeSerIgualAlDelMesActual")+` (${currentMonth}).` }
                //     ))
                // );
            }

            if(dateEnd > lastDayOfMonth) return (
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                    severity:'warning', 
                    message: ___PHRASE("asegureseQueLaFechaDeFinSeaMenorAlUltimoDiaDelMesActual") }
                ))
            );
        }

        // if(values.date && onBoarding == "0"){
        //     if(currentMonth != moment(values.date).format("MMMM-YYYY")) return (
        //         setSnackBarConfig(prev => ({ ...prev, open: true, 
        //             severity:'warning', 
        //             message: ___PHRASE("elMesDeLaFechaSeleccionadaDebeSerIgualAlDelMesActual")+` (${currentMonth}).` }
        //         ))
        //     );
    
        //     if(moment(values.date).format("YYYY/MM/DD") > currentDate ) return (
        //         setSnackBarConfig(prev => ({ ...prev, open: true, 
        //             severity:'warning', 
        //             message: ___PHRASE("asegureseQueLaFechaSeleccionadaSeaMenorQueLaFechaActual") }
        //         ))
        //     );
        // }

        if(massive && workersSelected.length == 0 ) return (
            setSnackBarConfig(prev => ({ ...prev, open: true, 
                severity:'warning', 
                message: ___PHRASE("seleccioneAlMenosUnEmpleado") }
            ))
        );

        const formData = {
            id: values.id,
            idemployee: workerSelected.id,
            date : values.date,
            start_time : values.start_time,
            start_refreshment : values.start_refreshment,
            end_refreshment : values.end_refreshment,
            end_time : values.end_time,
            observations : values.observations,
        }

        workersSelected.length > 0 && workersSelected.map((item) => {
            arrayIdsEmployees.push(item.id);
        });

        const formDataMassive = {
            id: values.id,
            idemployees: JSON.stringify(arrayIdsEmployees),
            start_date : values.start_date,
            end_date : values.end_date,
            start_time : values.start_time,
            start_refreshment : values.start_refreshment,
            end_refreshment : values.end_refreshment,
            end_time : values.end_time,
            observations : values.observations,
        }

        setLoading(true);
        if(assistance && actionSelected=='edit'){
            await AssistsWorkerService.updateAssists(assistance.id, formData).then((data) => {
                let apimessagge = apiResponseTranslate(data?.data?.message);

                if(data.data.status == true){
                    setSnackBarConfig(prev => ({ ...prev, open: true, 
                        severity:'success', 
                        message: `${apimessagge}` }
                    ))
                    onListAssistances && onListAssistances(workerSelected.id, previousDate, nexDate);
                    setTimeout(function(){
                        closeModal && closeModal();
                        clearState && clearState();
                    }, 1000);
                    setLoading(false);
                }else{
                    setLoading(false);
                    return (
                        setSnackBarConfig(prev => ({ ...prev, open: true, 
                            severity:'error', 
                            message: `${apimessagge || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}` }
                        ))
                    );
                }
            })
        }else{
            if(massive){
                if(isIntegration){
                    await IntegrationDiviceService.createAssistMasive(formDataMassive).then((data) => {
                        let apimessagge = apiResponseTranslate(data?.data?.message);

                        if(data.data.status == true){
                            setLoading(false);
                            setSnackBarConfig(prev => ({ ...prev, open: true, 
                                severity:'success', 
                                message: `${apimessagge}`}
                            ))
                            setTimeout(function(){
                                closeModal && closeModal();
                            }, 1000);
                        }else{
                            setLoading(false);
                            return (
                                setSnackBarConfig(prev => ({ ...prev, open: true, 
                                    severity:'error', 
                                    message: `${apimessagge || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}` }
                                ))
                            );
                        }
                    })
                }else{
                    await AssistsWorkerService.createAssistMasive(formDataMassive).then((data) => {
                        setLoading(false);
                        let apimessagge = apiResponseTranslate(data?.data?.message);

                        if(data.data.status == true){
                            setSnackBarConfig(prev => ({ ...prev, open: true, 
                                severity:'success', 
                                message: `${apimessagge}`}
                            ))
                            setTimeout(function(){
                                closeModal && closeModal();
                            }, 1000);
                        }else{
                            setLoading(false);
                            return (
                                setSnackBarConfig(prev => ({ ...prev, open: true, 
                                    severity:'error', 
                                    message: `${apimessagge || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}` }
                                ))
                            );
                        }
                    })
                }
                
            }else{
                if(isIntegration){
                      await IntegrationDiviceService.createAssists({...formData, language:language}).then((data) => {
                        let apimessagge = apiResponseTranslate(data?.data?.message);

                        if(data.data.status == true){
                            setLoading(false);
                            setSnackBarConfig(prev => ({ ...prev, open: true, 
                                severity:'success', 
                                message: `${apimessagge}`}
                            ))
                            onListAssistances && onListAssistances(workerSelected.id, previousDate, nexDate);
                            setTimeout(function(){
                                closeModal && closeModal();
                            }, 1000);
                        }else{
                            setLoading(false);
                            return (
                                setSnackBarConfig(prev => ({ ...prev, open: true, 
                                    severity:'error', 
                                    message: role == ROLE_TRABAJADOR ? `${ apimessagge || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}` : apiResponseTranslate("La asistencia para la fecha seleccionada ya ha sido registrada.")} 
                                ))
                            );
                        }
                    })
                }else{
                    await AssistsWorkerService.createAssists(formData).then((data) => {
                        let apimessagge = apiResponseTranslate(data?.data?.message);

                        if(data.data.status == true){
                            setLoading(false);
                            setSnackBarConfig(prev => ({ ...prev, open: true, 
                                severity:'success', 
                                message: `${apimessagge}`}
                            ))
                            onListAssistances && onListAssistances(workerSelected.id, previousDate, nexDate);
                            setTimeout(function(){
                                closeModal && closeModal();
                            }, 1000);
                        }else{
                            setLoading(false);
                            return (
                                setSnackBarConfig(prev => ({ ...prev, open: true, 
                                    severity:'error', 
                                    message: role == ROLE_TRABAJADOR ? `${ apimessagge || ___PHRASE("ocurrioAlgunErrorIntentaloMasTarde")}` : `La Asistencia para la fecha seleccionada ya ha sido registrada.`} 
                                ))
                            );
                        }
                    })
                }
            }
            
        }
        setLoading(false);
    }
    
    return(
        <>
        {loading && <Loading/>}

        <Snackbar
            open={snackBarConfig.open} 
            autoHideDuration={snackBarConfig.autoHideDuration} 
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>

        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick handle size="sm">
            <ModalHeader
                text={(assistance ? ___PHRASE("editar") : ___PHRASE("agregar"))+' '+___PHRASE("asistencia")}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >

                                    {
                                        !massive && (
                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("fecha")}:</b></Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <TextField
                                                        id="date"
                                                        variant="standard"
                                                        fullWidth
                                                        name="date"
                                                        type="date"
                                                        onChange={handleChange}
                                                        value={values.date}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        error={errors.date && touched.date ? true : false}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )
                                    }

                                    

                                    {
                                        massive && (
                                            <>
                                            <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={4}>
                                                    <Typography variant="body1"><b>{___PHRASE("empleados")}:</b></Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <Autocomplete
                                                        multiple={true}
                                                        disablePortal
                                                        id="combo-box-demo"
                                                        value={workersSelected|| []}
                                                        options={workers || []}
                                                        getOptionLabel={(option) => option.name_complete || ""}
                                                        fullWidth
                                                        renderInput={(params) => <TextField {...params} variant="standard" label={___PHRASE("escribeParaBuscar")} />}
                                                        onChange={(e, newValue) => {
                                                            setWorkersSelected(newValue);
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("fechaInicio")}:</b></Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <TextField
                                                        id="start_date"
                                                        variant="standard"
                                                        fullWidth
                                                        name="start_date"
                                                        type="date"
                                                        onChange={handleChange}
                                                        value={values.start_date}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        error={errors.start_date && touched.start_date ? true : false}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                                <Grid item xs={4}>
                                                <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("fechaFin")}:</b></Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <TextField
                                                        id="end_date"
                                                        variant="standard"
                                                        fullWidth
                                                        name="end_date"
                                                        type="date"
                                                        onChange={handleChange}
                                                        value={values.end_date}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        error={errors.end_date && touched.end_date ? true : false}
                                                    />
                                                </Grid>
                                            </Grid>
                                            </>
                                        )
                                    }

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("horaIngreso")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                id="start_time"
                                                variant="standard"
                                                fullWidth
                                                name="start_time"
                                                type="time"
                                                onChange={handleChange}
                                                value={values.start_time}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={errors.start_time && touched.start_time ? true : false}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("inicioRefrigerio")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                id="start_refreshment"
                                                variant="standard"
                                                fullWidth
                                                name="start_refreshment"
                                                type="time"
                                                onChange={handleChange}
                                                value={values.start_refreshment}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={errors.start_refreshment && touched.start_refreshment ? true : false}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("finRefrigerio")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                id="end_refreshment"
                                                variant="standard"
                                                fullWidth
                                                name="end_refreshment"
                                                type="time"
                                                onChange={handleChange}
                                                value={values.end_refreshment}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={errors.end_refreshment && touched.end_refreshment ? true : false}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("horaSalida")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                id="end_time"
                                                variant="standard"
                                                fullWidth
                                                name="end_time"
                                                type="time"
                                                onChange={handleChange}
                                                value={values.end_time}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={errors.end_time && touched.end_time ? true : false}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("observacion")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="observations"
                                            variant="standard"
                                            type="text" 
                                            name="observations"
                                            multiline
                                            maxRows={4}
                                            fullWidth
                                            value={values.observations}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("observacion")}
                                            error={errors.observations && touched.observations ? true : false}
                                        />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(assistance && actionSelected=='edit') ? ___PHRASE("actualizar") : ___PHRASE("registrar")}
                                    cancelText={___PHRASE("cancelar")} 
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}