import { TRANSLATE, UPDATETRANSLATE } from '@constants/action-type';
import { readLocalStorage, saveLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { KEY_TRANSLATE, KEY_UPDATE_TRANSLATE } from '@/toolbox/constants/local-storage';

const initialState = {
  language: 'es',
  update: false
}

export default function(state = initialState, action:any){
  switch(action.type){
    case TRANSLATE:
      saveLocalStorage(KEY_TRANSLATE, action.payload);
      return {
        ...state,
        language: action.payload
      }
    case UPDATETRANSLATE:
      saveLocalStorage(KEY_UPDATE_TRANSLATE, action.payload);
      return {
        ...state,
        update: action.payload
      }

    default:
      if (!readLocalStorage(KEY_TRANSLATE)) {
        saveLocalStorage(KEY_TRANSLATE, state.language);
      }
      if (!readLocalStorage(KEY_UPDATE_TRANSLATE)) {
        saveLocalStorage(KEY_UPDATE_TRANSLATE, state.update);        
      }
    return state
  }
}
