import { profileRepository } from "../repositories/Profile.repository";

export const ProfileService = {
    getProfiles,
    getRoles,
    createProfile,
    updateProfile,
    deleteProfile,
}

async function getProfiles(){
    const res = await profileRepository.getProfiles();
    return res;
}

async function getRoles(idRoles:any = ''){
    const res = await profileRepository.getRoles(idRoles);
    return res;
}

async function createProfile(data:{}){
    const res = await profileRepository.createProfile(data);
    return res;
}

async function updateProfile( idProfile ,data:{}){
    const res = await profileRepository.updateProfile(idProfile, data);
    return res;
}

async function deleteProfile( idProfile){
    const res = await profileRepository.deleteProfile(idProfile);
    return res;
}