import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik } from 'formik';
import { useEffect, useState } from "react";
import { Autocomplete, Divider, Grid, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import { Loading } from "@/components/common/Loading";
import { Props } from "./ModalTimeZone.type";
import { TimeZoneService } from "@/service/services/TimeZone.service";
import ___PHRASE from "@/service/translate/translateService";
import { apiResponseTranslate } from "@/toolbox/helpers/responseApi.helper";
import '../../../assets/styles/modals.scss';

export const ModalTimeZone: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, timeZone, onListZoneTime, clearState, comboTimeZones, getSnackBar } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [timeSelected, setTimeSelected] = useState(null);

    const [data, setData] = useState({
        id: timeZone ? timeZone.id : '',
        description: timeZone ? timeZone.description : '',
        // operation: timeZone ? timeZone.operation : '',
        time_zone: timeZone ? timeZone.time_zone : ''
    });

    const validateForm = (values) => {
        let errors: any = {};
        if(!values.description) errors.description = ___PHRASE("nombreRequerido");
        // if(!values.operation) errors.operation = ___PHRASE("nombreRequerido");
        if(!timeSelected) errors.time_zone = ___PHRASE("nombreRequerido");
        // if(!values.time_zone) errors.time_zone = ___PHRASE("nombreRequerido");
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {

        const formData = {
            id: values.id,
            description : values.description,
            // operation : values.operation,
            time_zone : timeSelected
        }
        setLoading(true);
        if(timeZone && actionSelected=='edit'){
            await TimeZoneService.editTimeZone(formData, timeZone.id).then((data) => {
          let apimessagge = apiResponseTranslate(data?.data?.message);

                if(data.data.status){
                    getSnackBar(prev => ({
                       ...prev, open: true,
                       severity: 'success',
                       message: `${apimessagge}`
                       }
                    ));
                    onListZoneTime && onListZoneTime();
                } else {
                    getSnackBar(prev => ({
                       ...prev, open: true,
                       severity: 'error',
                       message: `${apimessagge}`
                       }
                    ));
                }
                closeModal && closeModal();
            })
        }else{
            await TimeZoneService.createTimeZone(formData).then((data) => {
          let apimessagge = apiResponseTranslate(data?.data?.message);

                if(data.data.status){
                    getSnackBar(prev => ({
                       ...prev, open: true,
                       severity: 'success',
                       message: `${apimessagge}`
                       }
                    ));
                    onListZoneTime && onListZoneTime();
                } else {
                    getSnackBar(prev => ({
                       ...prev, open: true,
                       severity: 'error',
                       message: `${apimessagge}`
                       }
                    ));
                }
                closeModal && closeModal();
            })
        }
        setLoading(false);
    }

    useEffect(() => {
        if(actionSelected=='edit'){
            setTimeSelected(timeZone.time_zone)
        }
    },[timeZone]);

    return(
        <>
        {loading && <Loading/>}

        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={(timeZone ? ___PHRASE("editar") : ___PHRASE("agregar"))+' '+___PHRASE("zonaHoraria")}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("descripcion")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="description"
                                            variant="standard"
                                            type="text" 
                                            name="description"
                                            fullWidth
                                            value={values.description}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("descripcion")}
                                            error={errors.description && touched.description ? true : false}
                                            helperText={errors.description ? errors.description : ''}
                                        />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                            <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("zonashorarias")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <Autocomplete
                                                id="disable-portal"
                                                fullWidth
                                                value={timeSelected}
                                                options={comboTimeZones}
                                                renderInput={(params) => <TextField
                                                    {...params} variant="standard"
                                                    label={___PHRASE("zonashorarias")}
                                                    error={errors.time_zone && touched.time_zone ? true : false}
                                                    helperText={errors.time_zone ? errors.time_zone : ''}
                                                    />}
                                                onChange={(e, newValue) => { setTimeSelected(newValue ? newValue.label : "") }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <ModalFooter
                                    buttonType="submit"
                                    confirmText={(timeZone && actionSelected=='edit') ? ___PHRASE("actualizar") : ___PHRASE("registrar")}
                                    cancelText={___PHRASE("cancelar")} 
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}
