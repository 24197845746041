import React, { useEffect, useState } from 'react';
import { Loading } from "@components/common/Loading";
import { Container, Grid, Typography,Button} from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { Props } from './PreviewPrevired.type';
import { templateRemunerationMonthlyService } from '@/service/services/TemplateRemunerationMonthly.service';
import { ModalAmountGeneral } from '@/components/Modal/ModalAmountGeneral';
import ___PHRASE from '@/service/translate/translateService';

export const PreviewPrevired: React.FC<Props> = (props: any): JSX.Element => {
    const { data, setShowPrevired, setShowMain, period, costCenter } = props;
    
    const cabecera = [
        { name: 'RUT Trabajador', label: ___PHRASE("rutTrabajador"), width: '200px' },
        { name: 'DV Trabajador', label: 'DV Trabajador', width: '200px'  },
        { name: 'Apellido Paterno', label: ___PHRASE("apellidoPaterno") , width: '200px' },
        { name: 'Apellido Materno', label: ___PHRASE("apellidoMaterno") , width: '200px' },
        { name: 'Nombres', label: ___PHRASE("nombres") , width: '200px' },
        { name: 'Sexo', label: 'Sexo' , width: '200px' },
        { name: 'Nacionalidad', label: 'Nacionalidad' , width: '200px' },
        { name: 'Tipo Pago', label: 'Tipo Pago', width: '200px'  },
        { name: 'Período (Desde)', label: 'Período (Desde)' , width: '200px' },
        { name: 'Período (Hasta)', label: 'Período (Hasta)' , width: '200px' },
        { name: 'Régimen Previsional', label: 'Régimen Previsional' , width: '200px' },
        { name: 'Tipo Trabajador', label: 'Tipo Trabajador' , width: '200px' },
        { name: 'Días Trabajados', label: 'Días Trabajados' , width: '200px' },
        { name: 'Tipo de Línea', label: 'Tipo de Línea' , width: '200px' },
        { name: 'Código Movimiento de Personal', label: 'Código Movimiento de Personal', width: '200px'  },
        { name: 'Fecha Desde', label: 'Fecha Desde', width: '200px'  },
        { name: 'Fecha Hasta', label: 'Fecha Hasta' , width: '200px' },
        { name: 'Tramo Asignación Familiar', label: 'Tramo Asignación Familiar' , width: '200px' },
        { name: 'N°Cargas Simples', label: 'N°Cargas Simples', width: '200px'  },
        { name: 'N° Cargas Maternales', label: 'N° Cargas Maternales' , width: '200px' },
        { name: 'N°Cargas Inválidas', label: 'N°Cargas Inválidas' , width: '200px' },
        { name: 'Asignación Familiar', label: 'Asignación Familiar' , width: '200px' },
        { name: 'Asignación Familiar Retroactiva', label: 'Asignación Familiar Retroactiva' , width: '200px' },
        { name: 'Reintegro Cargas Familiares', label: 'Reintegro Cargas Familiares', width: '200px'  },
        { name: 'Solicitud Trabajador Joven', label: 'Solicitud Trabajador Joven', width: '200px'  },
        { name: 'Código de la AFP', label: 'Código de la AFP' , width: '200px' },
        { name: 'Renta Imponible AFP', label: 'Renta Imponible AFP' , width: '200px' },
        { name: 'Cotización Obligatoria AFP', label: 'Cotización Obligatoria AFP', width: '200px'  },
        { name: 'Cotización Seguro de Invalidez y Sobrevivencia (SIS)', label: 'Cotización Seguro de Invalidez y Sobrevivencia (SIS)', width: '200px'  },
        { name: 'Cuenta de Ahorro Voluntario AFP', label: 'Cuenta de Ahorro Voluntario AFP' , width: '200px' },
        { name: 'Renta Imp. Sust.AFP', label: 'Renta Imp. Sust.AFP' , width: '200px' },
        { name: 'Tasa Pactada (Sustit.)', label: 'Tasa Pactada (Sustit.)' , width: '200px' },
        { name: 'Aporte Indemn. (Sustit.)', label: 'Aporte Indemn. (Sustit.)', width: '200px'  },
        { name: 'N° Períodos (Sustit.)', label: 'N° Períodos (Sustit.)' , width: '200px' },
        { name: 'Período desde (Sustit.)', label: 'Período desde (Sustit.)' , width: '200px' },
        { name: 'Período Hasta (Sustit.)', label: 'Período Hasta (Sustit.)' , width: '200px' },
        { name: 'Puesto de Trabajo Pesado', label: 'Puesto de Trabajo Pesado' , width: '200px' },
        { name: '% Cotización Trabajo Pesado', label: '% Cotización Trabajo Pesado' , width: '200px' },
        { name: 'Cotización Trabajo Pesado', label: 'Cotización Trabajo Pesado', width: '200px'  },
        { name: 'Código de la Institución APVI', label: 'Código de la Institución APVI', width: '200px'  },
        { name: 'Número de Contrato APVI', label: 'Número de Contrato APVI' , width: '200px' },
        { name: 'Forma de Pago APVI', label: 'Forma de Pago APVI', width: '200px'  },
        { name: 'Cotización APVI', label: 'Cotización APVI' , width: '200px' },
        { name: 'Cotización Depósitos Convenidos', label: 'Cotización Depósitos Convenidos' , width: '200px' },
        { name: 'Código Institución Autorizada APVC', label: 'Código Institución Autorizada APVC', width: '200px'  },
        { name: 'Número de Contrato APVC', label: 'Número de Contrato APVC' , width: '200px' },
        { name: 'Forma de Pago APVC', label: 'Forma de Pago APVC', width: '200px'  },
        { name: 'Cotización Trabajador APVC', label: 'Cotización Trabajador APVC' , width: '200px' },
        { name: 'Cotización Empleador APVC', label: 'Cotización Empleador APVC' , width: '200px' },
        { name: 'RUT Afiliado Voluntario', label: 'RUT Afiliado Voluntario' , width: '200px' },
        { name: 'DV Afiliado Voluntario', label: 'DV Afiliado Voluntario' , width: '200px' },
        { name: 'Monto Capitalización Voluntaria', label: 'Monto Capitalización Voluntaria', width: '200px'  },
        { name: 'Monto Ahorro Voluntario', label: 'Monto Ahorro Voluntario' , width: '200px' },
        { name: 'Número de periodos de cotización', label: 'Número de periodos de cotización' , width: '200px' },
        { name: 'Código EX-Caja Régimen', label: 'Código EX-Caja Régimen' , width: '200px' },
        { name: 'Tasa Cotización Ex-Caja Previsión', label: 'Tasa Cotización Ex-Caja Previsión', width: '200px'  },
        { name: 'Renta Imponible IPS', label: 'Renta Imponible IPS', width: '200px'  },
        { name: 'Cotización Obligatoria IPS', label: 'Cotización Obligatoria IPS' , width: '200px' },
        { name: 'Renta Imponible Desahucio', label: 'Renta Imponible Desahucio' , width: '200px' },
        { name: 'Código Ex-Caja Régimen Desahucio', label: 'Código Ex-Caja Régimen Desahucio' , width: '200px' },
        { name: 'Tasa Cotización Desahucio Ex-Cajas de Previsión', label: 'Tasa Cotización Desahucio Ex-Cajas de Previsión', width: '200px'  },
        { name: 'Cotización Desahucio', label: 'Cotización Desahucio' , width: '200px' },
        { name: 'Cotización Fonasa', label: 'Cotización Fonasa' , width: '200px' },
        { name: 'Cotización Acc. Trabajo (ISL)', label: 'Cotización Acc. Trabajo (ISL)' , width: '200px' },
        { name: 'Bonificación Ley 15.386', label: 'Bonificación Ley 15.386' , width: '200px' },
        { name: 'Descuento por cargas familiares de IPS (ex INP)', label: 'Descuento por cargas familiares de IPS (ex INP)', width: '200px'  },
        { name: 'Bonos Gobierno', label: 'Bonos Gobierno' , width: '200px' },
        { name: 'Código Institución de Salud', label: 'Código Institución de Salud' , width: '200px' },
        { name: 'Número del FUN', label: 'Número del FUN', width: '200px' },
        { name: 'Renta Imponible Isapre', label: 'Renta Imponible Isapre', width: '200px'  },
        { name: 'Moneda del plan pactado Isapre', label: 'Moneda del plan pactado Isapre' , width: '200px' },
        { name: 'Cotización Pactada', label: 'Cotización Pactada' , width: '200px' },
        { name: 'Cotización Obligatoria Isapre', label: 'Cotización Obligatoria Isapre', width: '200px'  },
        { name: 'Cotización Adicional Voluntaria', label: 'Cotización Adicional Voluntaria' , width: '200px' },
        { name: 'Monto Garantía Explícita de Salud GES (Uso Futuro)', label: 'Monto Garantía Explícita de Salud GES (Uso Futuro)' , width: '200px' },
        { name: 'Código CCAF', label: 'Código CCAF' , width: '200px' },
        { name: 'Renta Imponible CCAF', label: 'Renta Imponible CCAF' , width: '200px' },
        { name: 'Créditos Personales CCAF', label: 'Créditos Personales CCAF' , width: '200px' },
        { name: 'Descuento Dental CCAF', label: 'Descuento Dental CCAF', width: '200px'  },
        { name: 'Descuentos por Leasing (Programa Ahorro)', label: 'Descuentos por Leasing (Programa Ahorro)', width: '200px'  },
        { name: 'Descuentos por seguro de vida CCAF', label: 'Descuentos por seguro de vida CCAF', width: '200px'  },
        { name: 'Otros descuentos CCAF', label: 'Otros descuentos CCAF' , width: '200px' },
        { name: 'Cotización a CCAF de no afiliados a Isapres', label: 'Cotización a CCAF de no afiliados a Isapres', width: '200px'  },
        { name: 'Descuento Cargas Familiares CCAF', label: 'Descuento Cargas Familiares CCAF', width: '200px'  },
        { name: 'Otros descuentos CCAF 1 (Uso Futuro)', label: 'Otros descuentos CCAF 1 (Uso Futuro)' , width: '200px' },
        { name: 'Otros descuentos CCAF 2 (Uso Futuro)', label: 'Otros descuentos CCAF 2 (Uso Futuro)' , width: '200px' },
        { name: 'Bonos Gobierno (Uso Futuro)', label: 'Bonos Gobierno (Uso Futuro)' , width: '200px' },
        { name: 'Código de Sucursal (Uso Futuro)', label: ___PHRASE("codigoDeSucursalUsoFuturo"), width: '200px' },
        { name: 'Código Mutualidad', label: ___PHRASE("codigoMutualidad"), width: '200px' },
        { name: 'Renta Imponible Mutual', label: ___PHRASE("rentaImponibleMutual"), width: '200px' },
        { name: 'Cotización Accidente del Trabajo (MUTUAL)', label: ___PHRASE("cotizacionAccidenteDelTrabajo") , width: '200px' },
        { name: 'Sucursal para pago Mutual', label: ___PHRASE("sucursalParaPagoMutual"), width: '200px'  },
        { name: 'Renta Imponible Seguro Cesantía (Informar Renta Total Imponible)', label: ___PHRASE("rentaImponibleSeguroCesantia") , width: '200px' },
        { name: 'Aporte Trabajador Seguro Cesantía', label: ___PHRASE("aporteTrabajadorSeguroCesantia"), width: '200px' },
        { name: 'Aporte Empleador Seguro Cesantía', label: ___PHRASE("aporteEmpleadorSeguroCesantia"), width: '200px'  },
        { name: 'Rut Pagadora Subsidio', label: ___PHRASE("rutPagadoraSubsidio"), width: '200px'  },
        { name: 'DV Pagadora Subsidio', label: ___PHRASE("dvPagadoraSubsidio") , width: '200px' },
        { name: 'Centro de Costos, Sucursal, Agencia,Obra, Región', label: ___PHRASE("centroDeCostosSucursalAgenciaObraRegion"), width: '200px' },
    ];

    const [showUserModal, setShowUserModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const previredDocument = async(typeDownload) => {

        setLoading(true);
        
        if( typeDownload == 'excel'){
        const respExcel = await templateRemunerationMonthlyService.createPreviredExcel(period.id, costCenter?.id || "");
        console.log(respExcel.data.link);
        const btn_excel = document.createElement("a")
        btn_excel.href = `${respExcel.data.link}`
        btn_excel.download = 'previred.xlsx'

        document.body.appendChild(btn_excel);
        btn_excel.click();
        document.body.removeChild(btn_excel);
        setLoading(false);

        }else{
        const respCSV = await templateRemunerationMonthlyService.createPreviredCsv(period.id, costCenter?.id || "");
        const btn_csv = document.createElement("a")
        btn_csv.href = `${respCSV.data.link}`
        btn_csv.download = 'previred.csv'

        document.body.appendChild(btn_csv);
        btn_csv.click();
        document.body.removeChild(btn_csv);
        setLoading(false);

        }
        setLoading(false);
    }
    
    return (
        <>
        {loading && <Loading />}
        <Container>
        <Grid container spacing={3}>
            <Grid item xs={12} md={12} display='flex' justifyContent='space-between'>
                <Typography variant='h6' component='h1' gutterBottom className="tittle">
                    PREVIRED
                </Typography>
                <Button
                     color="error"
                     variant="contained"
                     onClick={()=>{
                        setShowPrevired(false)
                        setShowMain(true)
                     }}
                  >
                   {___PHRASE("regresar")}
                  </Button>
            </Grid>
        </Grid>

        <Grid item xs={12} spacing={2} container direction={'row'} justifyContent='center' alignItems={'center'} marginTop='15px'>

            <Grid item xs={12} container alignItems="center" justifyContent="start" marginBottom='25px'>
                <Grid item xs={4}>
                <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("previredFormatoExcel")}:</b></Typography>
                </Grid>
                <Grid item xs={8} justifyContent='start'>
                    <Button onClick={()=>{previredDocument('excel')}} type={'submit'} size="medium" color={"primary"} variant="contained" disableElevation sx={{width:'220px'}}>
                        {___PHRASE("descargar")}
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                <Grid item xs={4}>
                <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("previredFormatoCsv")}:</b></Typography>
                </Grid>
                <Grid item xs={8}>
                    <Button onClick={()=>{previredDocument('csv')}} type={'submit'} size="medium" color={"primary"} variant="contained" disableElevation sx={{width:'220px'}}>
                        {___PHRASE("descargar")}
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                <Grid item xs={4}>
                <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("previredMontosGenerales")}:</b></Typography>
                </Grid>
                <Grid item xs={8}>
                    <Button onClick={()=>setShowUserModal(true)} type={'submit'} size="medium" color={"primary"} variant="contained" disableElevation sx={{width:'220px'}}>
                        {___PHRASE("verMontosPrevired")}
                    </Button>
                </Grid>
            </Grid>

        </Grid>
        <Grid item container xs={12} justifyContent='center' marginBottom='15px'>
            <TableDataV2
                data={data || []}
                header={cabecera}
                disabled_title={true}
                btn_disabled
                btn_edit_disable={true}
                btn_delete_disable={true}
                sticky_column={'RUT Trabajador'}
                bg_header={'#3f9bcc'}
                color_text_header={'#FFFF'}
            />
        </Grid>
        
        </Container>
        { 
            showUserModal && (
               <ModalAmountGeneral
                  open={showUserModal}
                  closeModal={()=>{setShowUserModal(false)}}
                  idperiod={period.id}
                  
               />
            )
         }
        </>
    )
    
};