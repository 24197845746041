import { ProfileSessionRepository } from "../repositories/ProfileSession.repository";

export const ProfileSessionService = {
    getProfiles,
    getProfileByUser,
    deleteProfiles,
    getProfileByRole,
    getRoles,
    editViews,
    editProfile,
    createProfiles
}

async function getProfiles(){
    const res = await ProfileSessionRepository.getProfiles();
    return res;
}
async function getProfileByUser(idUser){
    const res = await ProfileSessionRepository.getProfileByUser(idUser);
    return res;
}
async function getProfileByRole(idRole){
    const res = await ProfileSessionRepository.getProfileByRole(idRole);
    return res;
}
async function deleteProfiles(id){
    const res = await ProfileSessionRepository.deleteProfiles(id);
    return res;
}
async function getRoles(){
    const res = await ProfileSessionRepository.getRoles();
    return res;
}
async function editProfile(id, data){
    const res = await ProfileSessionRepository.editProfile(id, data);
    return res;
}
async function editViews(id, data){
    const res = await ProfileSessionRepository.editViews(id, data);
    return res;
}
async function createProfiles(data){
    const res = await ProfileSessionRepository.createProfiles(data);
    return res;
}


