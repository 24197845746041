import React, { useEffect, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Alert, Container, Snackbar, Typography } from "@mui/material";
import { Loading } from '@/components/common/Loading';
import { Props } from './CompanySettings.type';
import { MutualRate } from './MutualRate/MutualRate';
import { companiesService } from '@/service/services/Companies.service';
import { ExtraHourValue } from './ExtraHourValue/ExtraHourValue';
import { CloseDay } from './CloseDay/CloseDay';
import { ConfigurationIPService } from '@/service/services/ConfigurationIP.service';
import { IpSetting } from './IpSetting/IpSetting';
import ___PHRASE from '@/service/translate/translateService';
import { Jornaly } from './Jornaly/Jornaly';
import { CompensationBox } from './CompensationBox/CompensationBox';

export const CompanySettingsView: React.FC<Props> = (props: any): JSX.Element => {
    
    const [loading, setLoading] = useState<boolean>(false);
    const [dataCompany, setDataCompany] = useState<any>(null);
    const [mutuality, setMutuality] = useState<any>([]);
    const [infoIP, setInfoIP] = useState<any>('');
    const [boxCompensation, setBoxCompensation] = useState<any>([])

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });
    
    useEffect(() => {
        getDataCompany();
        getMutualityByCompany();
        getCompanyIP();
        getCompensationBox();
    }, []);

    const getDataCompany = async() => {
        setLoading(true);
        await companiesService.getDataCompany().then((data) => {
            setDataCompany(data.data || []);
            setLoading(false);
        })
    }

    const getMutualityByCompany = async() => {
        setLoading(true);
        await companiesService.getMutualityByCompany().then((data) => {
            setMutuality(data.data || []);
            setLoading(false);
        })
    }

    const getCompensationBox = async() => {
        setLoading(true);
        await companiesService.getCompensationBox().then((data) => {
            setBoxCompensation(data || []);
            setLoading(false);
        })
    }

    const getCompanyIP = async() => {
        setLoading(true);
        await ConfigurationIPService.getCompanyIP().then((data) => {
            setInfoIP(data.data || []);
            setLoading(false);
        })
    }

    return (
        <Protected>
            {loading && <Loading />}
            <Snackbar
                open={snackBarConfig.open} 
                autoHideDuration={snackBarConfig.autoHideDuration} 
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
                <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                    {snackBarConfig.message || ""}
                </Alert>
            </Snackbar>
            <Container>
                <Typography variant='h6' component='h1' gutterBottom className="tittle"
                    sx={{ m: 1, fontWeight: 600 }}>{___PHRASE("configuracionDeLaEmpresa")}</Typography>

                {
                    dataCompany != null && (
                        <IpSetting 
                            dataIP={infoIP}
                            onListDataIP={getCompanyIP}
                            onListDataCompany={getDataCompany}
                            dataCompany={dataCompany}
                        />
                    )
                }

                {
                    dataCompany != null && mutuality.length > 0 && (
                        <MutualRate 
                            dataCompany={dataCompany}
                            onListDataCompany={getDataCompany}
                            mutuality={mutuality}
                        />
                    )
                }

                {
                    dataCompany != null && boxCompensation.length > 0 && (
                      <CompensationBox
                            dataCompany={dataCompany}
                            onListDataCompany={getDataCompany}
                            compensationBox={boxCompensation}
                      />  
                    )
                }

                {
                    dataCompany != null && (
                        <>
                        <ExtraHourValue 
                            dataCompany={dataCompany}
                            onListDataCompany={getDataCompany}
                        />

                        <CloseDay 
                            dataCompany={dataCompany}
                            onListDataCompany={getDataCompany}
                        />

                        <Jornaly
                            dataCompany={dataCompany}
                            onListDataCompany={getDataCompany}
                        />
                        </>
                    )
                }

            </Container>

        </Protected>
    )
};
