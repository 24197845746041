import React, { useState } from 'react';
import { Loading } from "@components/common/Loading";
import { Container, Grid, Typography,Button } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { Props } from './PreviewEbook.type';
import { templateRemunerationMonthlyService } from '@/service/services/TemplateRemunerationMonthly.service';
import ___PHRASE from '@/service/translate/translateService';
import { apiResponseTranslate } from '@/toolbox/helpers/responseApi.helper';

const cabecera = [
    { name: "Rut trabajador (cód 1101)", label: ___PHRASE("rutTrabajadorCod1101"), width: "200px" },
    { name: "Fecha inicio contrato (cód 1102)", label: ___PHRASE("fechaInicioContratoCod1102"), width: "200px" },
    { name: "Fecha de término de contrato (cód 1103)", label: ___PHRASE("fechaDeTerminoDeContratoCod1103"), width: "200px" },
    { name: "Causal de término del contrato (cód 1104)", label: ___PHRASE("causalDeTerminoDelContratoCod1104"), width: "200px" },
    { name: "Región de prestación de los servicios (cód 1105)", label: ___PHRASE("regionDePrestacionDeLosServiciosCod1105"), width: "200px" },
    { name: "Comuna de prestación de los servicios (cód 1106)", label: ___PHRASE("comunaDePrestacionDeLosServiciosCod1106"), width: "200px" },
    { name: "Tipo de impuesto a la renta (cód 1170)", label: ___PHRASE("tipoDeImpuestoALaRentaCod1170"), width: "200px" },
    { name: "Técnico extranjero exención de cotizaciones previsionales (Ley 18.156) (cód 1146)", label: ___PHRASE("tecnicoExtranjeroExencionDeCotizacionesPrevisionalesLey18156Cod1146"), width: "200px" },
    { name: "Código tipo de jornada (cód 1107)", label: ___PHRASE("codigoTipoDeJornadaCod1107"), width: "200px" },
    { name: "Persona con discapacidad/pensionado por invalidez (cód 1108)", label:___PHRASE("personaConDiscapacidadPensionadoPorInvalidezCod1108"), width: "200px" },
    { name: "Pensionado por vejez (cód 1109)", label: ___PHRASE("pensionadoPorVejezCod1109"), width: "200px" },
    { name: "AFP (cód 1141)", label: ___PHRASE("afpCod1141"), width: "200px" },
    { name: "IPS (ExINP) (cód 1142)", label: "IPS (ExINP) (cód 1142)", width: "200px" },
    { name: "FONASA / ISAPRE (cód 1143)", label: "FONASA / ISAPRE (cód 1143)", width: "200px" },
    { name: "AFC (cód 1151)", label: "AFC (cód 1151)", width: "200px" },
    { name: "CCAF (cód 1110)", label: "CCAF (cód 1110)", width: "200px" },
    { name: "Org. administrador ley 16.744 (cód 1152)", label: ___PHRASE("orgAdministradorLey16744Cod1152"), width: "200px" },
    { name: "Número cargas familiares legales autorizadas (cód 1111)", label: ___PHRASE("numeroCargasFamiliaresLegalesAutorizadasCod1111"), width: "200px" },
    { name: "Número de cargas familiares maternales (cód 1112)", label: ___PHRASE("numeroDeCargasFamiliaresMaternalesCod1112"), width: "200px" },
    { name: "Número de cargas familiares invalidez (cód 1113)", label: ___PHRASE("numeroDeCargasFamiliaresInvalidezCod1113"), width: "200px" },
    { name: "Tramo asignación familiar (cód 1114)", label: ___PHRASE("tramoAsignacionFamiliarCod1114"), width: "200px" },
    { name: "Rut org sindical 1 (cód 1171)", label: ___PHRASE("rutOrgSindical1Cod1171"), width: "200px" },
    { name: "Rut org sindical 2 (cód 1172)", label: ___PHRASE("rutOrgSindical2Cod1172"), width: "200px" },
    { name: "Rut org sindical 3 (cód 1173)", label: ___PHRASE("rutOrgSindical3Cod1173"), width: "200px" },
    { name: "Rut org sindical 4 (cód 1174)", label: ___PHRASE("rutOrgSindical4Cod1174"), width: "200px" },
    { name: "Rut org sindical 5 (cód 1175)", label: ___PHRASE("rutOrgSindical5Cod1175"), width: "200px" },
    { name: "Rut org sindical 6 (cód 1176)", label: ___PHRASE("rutOrgSindical6Cod1176"), width: "200px" },
    { name: "Rut org sindical 7 (cód 1177)", label: ___PHRASE("rutOrgSindical7Cod1177"), width: "200px" },
    { name: "Rut org sindical 8 (cód 1178)", label: ___PHRASE("rutorigsindical8cod1178"), width: "200px" },
    { name: "Rut org sindical 9 (cód 1179)", label: ___PHRASE("rutorigsindical9cod1179"), width: "200px" },
    { name: "Rut org sindical 10 (cód 1180)", label: ___PHRASE("rutorigsindical10cod1180"), width: "200px" },
    { name: "Número días trabajados en el mes (cód 1115)", label: ___PHRASE("numerodiastrabajadosenelmescod1115"), width: "200px" },
    { name: "Número días de licencia médica en el mes (cód 1116)", label: ___PHRASE("numerodiasdelicenciamedicaenelmescod1116"), width: "200px" },
    { name: "Número días de vacaciones en el mes (cód 1117)", label: ___PHRASE("numerodiasdevacacionesenelmescod1117"), width: "200px" },
    { name: "Subsidio trabajador joven (cód 1118)", label: ___PHRASE("subsidiotrabajadorjovencod1118"), width: "200px" },
    { name: "Puesto Trabajo Pesado (cód 1154)", label: ___PHRASE("puestotrabajopesadocod1154"), width: "200px" },
    { name: "Ahorro previsional voluntario individual (cód 1155)", label: ___PHRASE("ahorroprevisionalvoluntarioindividualcod1155"), width: "200px" },
    { name: "Ahorro previsional voluntario colectivo (cód 1157)", label: ___PHRASE("ahorroprevisionalvoluntariocolectivocod1157"), width: "200px" },
    { name: "Indemnización a todo evento (art. 164) (cód 1131)", label: ___PHRASE("indemnizacionatodoeventoart164cod1131"), width: "200px" },
    { name: "Tasa indemnización a todo evento (Art 164) (cód 1132)", label: ___PHRASE("tasaindemnizacionatodoeventoart164cod1132"), width: "200px" },
    { name: "Sueldo (cód 2101)", label: ___PHRASE("sueldocod2101"), width: "200px" },
    { name: "Sobresueldo (cód 2102)", label: ___PHRASE("sobresueldocod2102"), width: "200px" },
    { name: "Comisiones (mensual) (cód 2103)", label: ___PHRASE("comisionesmensualcod2103"), width: "200px" },
    { name: "Semana corrida mensual (Art 45) (cód 2104)", label: ___PHRASE("semanacorridamensualart45cod2104"), width: "200px" },
    { name: "Participación (mensual) (cód 2105)", label: ___PHRASE("participacionmensualcod2105"), width: "200px" },
    { name: "Gratificación (mensual) (cód 2106)", label: ___PHRASE("gratificacionmensualcod2106"), width: "200px" },
    { name: "Recargo 30% día domingo (Art. 38) (cód 2107)", label: ___PHRASE("recargo30diadomingoart38cod2107"), width: "200px" },
    { name: "Remuneración variable pagada en vacaciones (Art 71) (cód 2108)", label: ___PHRASE("remuneracionvariablepagadaenvacacionesart71cod2108"), width: "200px" },
    { name: "Remuneración variable pagada en clausura (Art. 38 DFL 2) (cód 2109)", label: ___PHRASE("remuneracionvariablepagadaenclausuraart38dfl2cod2109"), width: "200px" },
    { name: "Aguinaldo (cód 2110)", label: ___PHRASE("aguinaldocod2110"), width: "200px" },
    { name: "Bonos u otras remuneraciones fijas mensuales (cód 2111)", label: ___PHRASE("bonosuotrasremuneracionesfijasmensualescod2111"), width: "200px" },
    { name: "Tratos (mensual) (cód 2112)", label: ___PHRASE("tratosmensualcod2112"), width: "200px" },
    { name: "Bonos u otras remuneraciones variables mensuales o superiores a un mes (cód 2113)", label: ___PHRASE("bonosuotrasremuneracionesvariablesmensualesosuperioresaunmescod2113"), width: "200px" },
    { name: "Ejercicio opción no pactada en contrato (Art. 17 N°8 LIR) (cód 2114)", label: ___PHRASE("ejercicioopcionnopactadaencontratoart17n8lircod2114"), width: "200px" },
    { name: "Beneficios en especie constitutivos de remuneración (cód 2115)", label: ___PHRASE("beneficiosenespecieconstitutivosderemuneracioncod2115"), width: "200px" },
    { name: "Remuneraciones bimestrales (devengo en dos meses) (cód 2116)", label: ___PHRASE("remuneracionesbimestralesdevengoendosmesescod2116"), width: "200px" },
    { name: "Remuneraciones trimestrales (devengo en tres meses) (cód 2117)", label: ___PHRASE("remuneracionestrimestralesdevengoentresmesescod2117"), width: "200px" },
    { name: "Remuneraciones cuatrimestrales (devengo en cuatro meses) (cód 2118)", label: ___PHRASE("remuneracionescuatrimestralesdevengoencuatromesescod2118"), width: "200px" },
    { name: "Remuneraciones semestrales (devengo en sesis meses) (cód 2119)", label: ___PHRASE("remuneracionessemestralesdevengoensesismesescod2119"), width: "200px" },
    { name: "Remuneraciones anuales (devengo en doce meses) (cód 2120)", label: ___PHRASE("remuneracionesanualesdevengoendocemesescod2120"), width: "200px" },
    { name: "Participación anual (devengo en doce meses (cód 2121)", label: ___PHRASE("participacionanualdevengoendocemesescod2121"), width: "200px" },
    { name: "Gratificación anual (devengo en doce meses) (cód 2122)", label: ___PHRASE("gratificacionanualdevengoendocemesescod2122"), width: "200px" },
    { name: "Otras remuneraciones superiores a un mes (cód 2123)", label: ___PHRASE("otrasremuneracionessuperioresaunmescod2123"), width: "200px" },
    { name: "Pago por horas de trabajo sindical (cód 2124)", label: ___PHRASE("pagoporhorasdetrabajosindicalcod2124"), width: "200px" },
    { name: "Sueldo empresarial (cód 2161)", label: ___PHRASE("sueldoempresarialcod2161"), width: "200px" },
    { name: "Subsidio por incapacidad laboral por licencia médica - total mensual (cód 2201)", label: ___PHRASE("subsidioporincapacidadlaboralporlicenciamedicatotalmensualcod2201"), width: "200px" },
    { name: "Beca de estudio (Art. 17 N°18 LIR) (cód 2202)", label: ___PHRASE("becadeestudioart17n18lircod2202"), width: "200px" },
    { name: "Gratificaciones de zona (Art.17 N°27) (cód 2203)", label: ___PHRASE("gratificacionesdezonaart17n27cod2203"), width: "200px" },
    { name: "Otros ingresos no constitutivos de renta (Art 17 N°29 LIR) (cód 2204)", label: ___PHRASE("otrosingresosnoconstitutivosderentaart17n29lircod2204"), width: "200px" },
    { name: "Colación total mensual (Art 41) (cód 2301)", label: ___PHRASE("colaciontotalmensualart41cod2301"), width: "200px" },
    { name: "Movilización total mensual (Art 41) (cód 2302)", label: ___PHRASE("movilizaciontotalmensualart41cod2302"), width: "200px" },
    { name: "Viáticos total mensual (Art 41) (cód 2303)", label: ___PHRASE("viaticostotalmensualart41cod2303"), width: "200px" },
    { name: "Asignación de pérdida de caja total mensual (Art 41) (cód 2304)", label: ___PHRASE("asignaciondeperdidadecajatotalmensualart41cod2304"), width: "200px" },
    { name: "Asignación de desgaste herramientas total mensual (Art 41) (cód 2305)", label: ___PHRASE("asignaciondedesgasteherramientastotalmensualart41cod2305"), width: "200px" },
    { name: "Asignación familiar legal total mensual (Art 41) (cód 2311)", label: ___PHRASE("asignacionfamiliarlegaltotalmensualart41cod2311"), width: "200px" },
    { name: "Gastos por causa del trabajo (Art 41 CdT) y gastos de representación (Art. 42 Nº1 LIR) (cód 2306)", label: ___PHRASE("gastosporcausadeltrabajoart41cdtygastosderepresentacionart42n1lircod2306"), width: "200px" },
    { name: "Gastos por cambio de residencia (Art 53) (cód 2307)", label: ___PHRASE("gastosporcambioderesidenciaart53cod2307"), width: "200px" },
    { name: "Sala cuna (Art 203) (cód 2308)", label: ___PHRASE("salacunaart203cod2308"), width: "200px" },
    { name: "Asignación trabajo a distancia o teletrabajo (cód 2309)", label: ___PHRASE("asignaciontrabajoadistanciaoteletrabajocod2309"), width: "200px" },
    { name: "Depósito convenido hasta UF 900 (cód 2347)", label: ___PHRASE("depositoconvenidohastauf900cod2347"), width: "200px" },
    { name: "Alojamiento por razones de trabajo (Art 17 N°14 LIR) (cód 2310)", label: ___PHRASE("alojamientoporrazonesdetrabajoart17n14lircod2310"), width: "200px" },
    { name: "Asignación de traslación (Art. 17 N°15 LIR) (cód 2312)", label: ___PHRASE("asignaciondetraslacionart17n15lircod2312"), width: "200px" },
    { name: "Indemnización por feriado legal (cód 2313)", label: ___PHRASE("indemnizacionporferiadolegalcod2313"), width: "200px" },
    { name: "Indemnización años de servicio (cód 2314)", label: ___PHRASE("indemnizacionañosdeserviciocod2314"), width: "200px" },
    { name: "Indemnización sustitutiva del aviso previo (cód 2315)", label: ___PHRASE("indemnizacionsustitutivadelavisopreviocod2315"), width: "200px" },
    { name: "Indemnización fuero maternal (Art 163 bis) (cód 2316)", label: ___PHRASE("indemnizacionfueromaternalart163biscod2316"), width: "200px" },
    { name: "Indemnización a todo evento (Art.164) (cód 2331)", label: ___PHRASE("indemnizacionatodoeventoart164cod2331"), width: "200px" },
    { name: "Indemnizaciones voluntarias tributables (cód 2417)", label: ___PHRASE("indemnizacionesvoluntariastributablescod2417"), width: "200px" },
    { name: "Indemnizaciones contractuales tributables (cód 2418)", label: ___PHRASE("indemnizacionescontractualestributablescod2418"), width: "200px" },
    { name: "Cotización obligatoria previsional (AFP o IPS) (cód 3141)", label: ___PHRASE("cotizacionobligatoriaprevisionalafpoipscod3141"), width: "200px" },
    { name: "Cotización obligatoria salud 7% (cód 3143)", label: ___PHRASE("cotizacionobligatoriasalud7cod3143"), width: "200px" },
    { name: "Cotización voluntaria para salud (cód 3144)", label: ___PHRASE("cotizacionvoluntariaparasaludcod3144"), width: "200px" },
    { name: "Cotización AFC -Trabajador (cód 3151)", label: ___PHRASE("cotizacionafctrabajadorcod3151"), width: "200px" },
    { name: "Cotizaciones técnico extranjero para seguridad social fuera de Chile (cód 3146)", label: ___PHRASE("cotizacionestcnicoextranjeroparaseguridadsocialfueradechilecod3146"), width: "200px" },
    { name: "Descuento depósito convenido hasta UF 900 anual (cód 3147)", label: ___PHRASE("descuentodepositoconvenidohastauf900anualcod3147"), width: "200px" },
    { name: "Cotización ahorro previsional voluntario individual modalidad A (cód 3155)", label: ___PHRASE("cotizacionahorroprevisionalvoluntarioindividualmodalidadacod3155"), width: "200px" },
    { name: "Cotización ahorro previsional voluntario individual modalidad B hasta UF 50 (cód 3156)", label: ___PHRASE("cotizacionahorroprevisionalvoluntarioindividualmodalidadbhastauf50cod3156"), width: "200px" },
    { name: "Cotización ahorro previsional voluntario colectivo modalidad A (cód 3157)", label: ___PHRASE("cotizacionahorroprevisionalvoluntariocolectivomodalidadaacod3157"), width: "200px" },
    { name: "Cotización ahorro previsional voluntario colectivo modalidad B hasta UF 50 (cód 3158)", label: ___PHRASE("cotizacionahorroprevisionalvoluntariocolectivomodalidadbhastauf50cod3158"), width: "200px" },
    { name: "Impuesto retenido por remuneraciones (cód 3161)", label: ___PHRASE("impuestoretenidoporremuneracionescod3161"), width: "200px" },
    { name: "Impuesto retenido por indemnizaciones (cód 3162)", label: ___PHRASE("impuestoretenidoporindemnizacionescod3162"), width: "200px" },
    { name: "Mayor retención de impuesto solicitada por el trabajador (cód 3163)", label: ___PHRASE("mayorretenciondeimpuestosolicitadaporeltrabajadorcod3163"), width: "200px" },
    { name: "Impuesto retenido por reliquidación de remuneraciones devengadas en otros períodos mensuales (cód 3164)", label: ___PHRASE("impuestoretenidoporreliquidacionderemuneracionesdevengadasenotrosperiodosmensualescod3164"), width: "200px" },
    { name: "Diferencia de impuesto por reliquidación de remuneraciones devengadas en este período (cód 3165)", label: ___PHRASE("diferenciadeimpuestoporreliquidacionderemuneracionesdevengadasenesteperiodocod3165"), width: "200px" },
    { name: "Retención préstamo clase media 2020 (Ley 21.252) (cód 3166)", label: ___PHRASE("retencionprestamoclasemedia2020ley21252cod3166"), width: "200px" },
    { name: "Rebaja zona extrema DL 889 (cód 3167)", label: ___PHRASE("rebajazonaextremadl889cod3167"), width: "200px" },
    { name: "Cuota sindical 1 (cód 3171)", label: ___PHRASE("cuotasindical1cod3171"), width: "200px" },
    { name: "Cuota sindical 2 (cód 3172)", label: ___PHRASE("cuotasindical2cod3172"), width: "200px" },
    { name: "Cuota sindical 3 (cód 3173)", label: ___PHRASE("cuotasindical3cod3173"), width: "200px" },
    { name: "Cuota sindical 4 (cód 3174)", label: ___PHRASE("cuotasindical4cod3174"), width: "200px" },
    { name: "Cuota sindical 5 (cód 3175)", label: ___PHRASE("cuotasindical5cod3175"), width: "200px" },
    { name: "Cuota sindical 6 (cód 3176)", label: ___PHRASE("cuotasindical6cod3176"), width: "200px" },
    { name: "Cuota sindical 7 (cód 3177)", label: ___PHRASE("cuotasindical7cod3177"), width: "200px" },
    { name: "Cuota sindical 8 (cód 3178)", label: ___PHRASE("cuotasindical8cod3178"), width: "200px" },
    { name: "Cuota sindical 9 (cód 3179)", label: ___PHRASE("cuotasindical9cod3179"), width: "200px" },
    { name: "Cuota sindical 10 (cód 3180)", label: ___PHRASE("cuotasindical10cod3180"), width: "200px" },
    { name: "Crédito social CCAF (cód 3110)", label: ___PHRASE("creditosocialccafcod3110"), width: "200px" },
    { name: "Cuota vivienda o educación Art. 58 (cód 3181)", label: ___PHRASE("cuotaviviendaoeducacionart58cod3181"), width: "200px" },
    { name: "Crédito cooperativas de ahorro (Art 54 Ley Coop.) (cód 3182)", label: ___PHRASE("creditocooperativasdeahorroart54leycoopcod3182"), width: "200px" },
    { name: "Otros descuentos autorizados y solicitados por el trabajador (cód 3183)", label: ___PHRASE("otrosdescuentosautorizadosysolicitadosporeltrabajadorcod3183"), width: "200px" },
    { name: "Cotización adicional trabajo pesado- trabajador (cód 3154)", label: ___PHRASE("cotizacionadicionaltrabajopesadotrabajadorcod3154"), width: "200px" },
    { name: "Donaciones culturales y de reconstrucción (cód 3184)", label: ___PHRASE("donacionesculturalesydereconstruccioncod3184"), width: "200px" },
    { name: "Otros descuentos (Art 58) (cód 3185)", label: ___PHRASE("otrosdescuentosart58cod3185"), width: "200px" },
    { name: "Pensiones de alimentos (cód 3186)", label: ___PHRASE("pensionesdealimentoscod3186"), width: "200px" },
    { name: "Descuento mujer casada (Art 59) (cód 3187)", label: ___PHRASE("descuentomujercasadaart59cod3187"), width: "200px" },
    { name: "Descuento por anticipos o préstamos (cód 3188)", label: ___PHRASE("descuentoporanticiposoprestamoscod3188"), width: "200px" },
    { name: "Aporte AFC -empleador (cód 4151)", label: ___PHRASE("aporteafcempleadorcod4151"), width: "200px" },
    { name: "Aporte empleador seguro accidentes del trabajo y Ley SANNA (Ley 16.744) (cód 4152)", label: ___PHRASE("aporteempleadorseguroaccidentestrabajosannaley16744cod4152"), width: "200px" },
    { name: "Aporte empleador indemnización a todo evento (Art 164) (cód 4131)", label: ___PHRASE("aporteempleadorindemnizacionatodoeventoart164cod4131"), width: "200px" },
    { name: "Aporte adicional trabajo pesado- empleador (cód 4154)", label: ___PHRASE("aporteadicionaltrabajopesadoempleadorcod4154"), width: "200px" },
    { name: "Aporte empleador seguro invalidez y sobrevivencia (cód 4155)", label: ___PHRASE("aporteempleadorseguroinvalidezsobrevivenciacod4155"), width: "200px" },
    { name: "Aporte empleador ahorro previsional voluntario colectivo (cód 4157)", label: ___PHRASE("aporteempleadorahorroprevisionalvoluntariocolectivocod4157"), width: "200px" },
    { name: "Total haberes (cód 5201)", label: ___PHRASE("totalhaberescod5201"), width: "200px" },
    { name: "Total haberes imponibles y tributables (cód 5210)", label: ___PHRASE("totalhaberesimponiblestributablescod5210"), width: "200px" },
    { name: "Total haberes imponibles y no tributables (cód 5220)", label: ___PHRASE("totalhaberesimponiblestributablesnotributablescod5220"), width: "200px" },
    { name: "Total haberes no imponibles y no tributables (cód 5230)", label: ___PHRASE("totalhaberesnoimponiblestributablesnotributablescod5230"), width: "200px" },
    { name: "Total haberes no imponibles y tributables (cód 5240)", label: ___PHRASE("totalhaberesnoimponiblestributablescod5240"), width: "200px" },
    { name: "Total descuentos (cód 5301)", label: ___PHRASE("totaldescuentoscod5301"), width: "200px" },
    { name: "Total descuentos impuestos a las remuneraciones (cód 5361)", label: ___PHRASE("totaldescuentosimpuestosalasremuneracionescod5361"), width: "200px" },
    { name: "Total descuentos impuestos por indemnizaciones (cód 5362)", label: ___PHRASE("totaldescuentosimpuestosporeindemnizacionescod5362"), width: "200px" },
    { name: "Total descuentos por cotizaciones del trabajador (cód 5341)", label: ___PHRASE("totaldescuentosporcotizacionesdeltrabajadorcod5341"), width: "200px" },
    { name: "Total otros descuentos (cód 5302)", label: ___PHRASE("totalotrosdescuentoscod5302"), width: "200px" },
    { name: "Total aportes empleador (cód 5410)", label: ___PHRASE("totalaportesempleadorcod5410"), width: "200px" },
    { name: "Total líquido (cód 5501)", label: ___PHRASE("totalliquidocod5501"), width: "200px" },
    { name: "Total indemnizaciones (cód 5502)", label: ___PHRASE("totalindemnizacionescod5502"), width: "200px" },
    { name: "Total indemnizaciones tributables (cód 5564)", label: ___PHRASE("totalindemnizacionestributablescod5564"), width: "200px" },
    { name: "Total indemnizaciones no tributables (cód 5565)", label: ___PHRASE("totalindemnizacionesnotributablescod5565"), width: "200px" },
];


export const PreviewEbook: React.FC<Props> = (props: any): JSX.Element => {
    const { data, setShowEbook, setShowMain, period, costCenter } = props;
    const [loading, setLoading] = useState<boolean>(false);

    const ebookDocument = async(typeDownload) => {
        setLoading(true);

        if( typeDownload == 'excel'){
            const respExcel = await templateRemunerationMonthlyService.createElectronicBookExcel(period.id, costCenter?.id || "");

            const btn_excel = document.createElement("a")
            btn_excel.href = `${respExcel.data.link}`
            btn_excel.download = 'previred.xlsx'

            document.body.appendChild(btn_excel);
            btn_excel.click();
            document.body.removeChild(btn_excel);
            setLoading(false);

        }else if(typeDownload == 'excel-no-header'){
            const respExcel = await templateRemunerationMonthlyService.createElectronicBookExcelNotHeaders(period.id, costCenter?.id || "");

            const btn_excel = document.createElement("a")
            btn_excel.href = `${respExcel.data.link}`
            btn_excel.download = 'previred.xlsx'

            document.body.appendChild(btn_excel);
            btn_excel.click();
            document.body.removeChild(btn_excel);
            setLoading(false);
   
        }else if(typeDownload == 'csv'){
            const respCSV = await templateRemunerationMonthlyService.createElectronicBookCsv(period.id, costCenter?.id || "");
            const btn_csv = document.createElement("a")
            btn_csv.href = `${respCSV.data.link}`
            btn_csv.download = 'previred.csv'

            document.body.appendChild(btn_csv);
            btn_csv.click();
            document.body.removeChild(btn_csv);
            setLoading(false);
        }
        setLoading(false);

    }

    return (
        <>
        {loading && <Loading />}
        <Container>
        <Grid container spacing={3}>
            <Grid item xs={12} md={12} display='flex' justifyContent='space-between'>
                <Typography variant='h6' component='h1' gutterBottom className="tittle">
                    {___PHRASE("libroRemuneracionesElectronico")}
                </Typography>
                <Button
                     color="error"
                     variant="contained"
                     onClick={()=>{
                        setShowEbook(false)
                        setShowMain(true)
                     }}
                  >
                   {___PHRASE("regresar")}
                  </Button>
            </Grid>
        </Grid>

        <Grid item xs={12} spacing={2} container direction={'row'} justifyContent='center' alignItems={'center'} marginTop='15px'>

            <Grid item xs={12} container alignItems="center" justifyContent="start" marginBottom='25px'>
                <Grid item xs={4}>
                <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("libroDtFormatoExcelConCabecera")}:</b></Typography>
                </Grid>
                <Grid item xs={8} justifyContent='start'>
                    <Button onClick={()=>{ebookDocument('excel')}} type={'submit'} size="medium" color={"primary"} variant="contained" disableElevation sx={{width:'220px'}}>
                        {___PHRASE("descargar")} 
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                <Grid item xs={4}>
                <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("libroDtFormatoExcelSinCabecera")}:</b></Typography>
                </Grid>
                <Grid item xs={8}>
                    <Button onClick={()=>{ebookDocument('excel-no-header')}} type={'submit'} size="medium" color={"primary"} variant="contained" disableElevation sx={{width:'220px'}}>
                        {___PHRASE("descargar")} 
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                <Grid item xs={4}>
                <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("libroDtFormatoCsv")}:</b></Typography>
                </Grid>
                <Grid item xs={8}>
                    <Button onClick={()=>{ebookDocument('csv')}} type={'submit'} size="medium" color={"primary"} variant="contained" disableElevation sx={{width:'220px'}}>
                        {___PHRASE("descargar")}
                    </Button>
                </Grid>
            </Grid>

        </Grid>
        
        <Grid item container xs={12} justifyContent='center' marginBottom='15px'>
            <TableDataV2
                data={data || []}
                header={cabecera}
                disabled_title={true}
                btn_disabled
                btn_edit_disable={true}
                btn_delete_disable={true}
                sticky_column={'Rut trabajador (cód 1101)'}
                bg_header={'#3f9bcc'}
                color_text_header={'#FFFF'}
            />
        </Grid>
        </Container>
        </>
    )
};