import { RequestRepository } from "../repositories/Request.repository";

export const RequestService = {
    getRequestReport,
    getRequestWorker,
    getRequestForAdmin,
    createRequestWorker,
    AceptorDeniedRequest,
    getRequestLiquidation,
    verifyRequestLiquidation,
}

async function getRequestReport(id, id_period){
    const res = await RequestRepository.getRequestReport(id, id_period);
    return res;
}

async function getRequestWorker(year,month, iddetailuser){
    const res = await RequestRepository.getMyRequest(year,month, iddetailuser);
    return res;
}

async function getRequestForAdmin(year,month){
    const res = await RequestRepository.getRequestForAdmin(year,month);
    return res;
}
async function getRequestLiquidation(idRequest){
    const res = await RequestRepository.getRequestLiquidation(idRequest);
    return res;
}
async function createRequestWorker(data:{}){
    const res = await RequestRepository.createRequestWorker(data);
    return res;
}
async function AceptorDeniedRequest(id,data:{}){
    const res = await RequestRepository.AceptorDeniedRequest(id,data);
    return res;
}
async function verifyRequestLiquidation(data){
    const res = await RequestRepository.verifyRequestLiquidation(data);
    return res;
}

