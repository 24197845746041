import { Modal } from "../Modal";
import { ModalBody } from "../ModalBody";
import { ModalHeader } from "../ModalHeader";
import { Formik } from 'formik';
import { useEffect, useState } from "react";
import { Alert, Autocomplete, Divider, Grid, Snackbar, TextField, Typography } from "@mui/material";
import { ModalFooter } from "../ModalFooter";
import { Loading } from "@/components/common/Loading";
import { Props } from "./ModalStructureManteiner.type";
import { RleStructureService } from "@/service/services/RleStructure.service";
import { CountryService } from "@/service/services/Country.service";
import ___PHRASE from "@/service/translate/translateService";
import { apiResponseTranslate } from "@/toolbox/helpers/responseApi.helper";
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper";
import { KEY_TRANSLATE } from "@/toolbox/constants/local-storage";
import '../../../assets/styles/modals.scss';

export const ModalStructureManteiner: React.FC<Props> = (props:any): JSX.Element => {

    const { open, closeModal, actionSelected, structure, onListStructure, clearState } = props;

    const language = readLocalStorage(KEY_TRANSLATE);

    const [loading, setLoading] = useState<boolean>(false);

    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
     })
    const [data, setData] = useState({
        id: structure ? structure.id : '',
        code: structure ? structure.code : '',
        name: structure ? structure.name : '',
        idtypestructure: structure ? structure.idtypestructure : '',
        required: structure ? structure.required : '',
        percentage: 1,
        gratification: structure ? structure.gratification : '',
        concept_DT: structure ? structure.concept_DT : '',
        idcountry: structure ? structure.idcountry : '',
        
    });

    const [typeStructureSelected, setTypeStructureSelected] = useState<any>(null);
    const [typeStructure,setTypeStructure] = useState<any>([])
    const requiredOptions = [
        {id:"1", name: ___PHRASE('si')},
        {id:"0", name: ___PHRASE('no')}
    ]
    const [requiredSelected, setRequiredSelected] = useState<any>(null)
    const gratificationOptions = [
        {id:"1", name: ___PHRASE('si')},
        {id:"0", name: ___PHRASE('no')}
    ]
    const [gratificationSelected, setGratificationSelected] = useState<any>(null)
    const [countries,setCountries] = useState<any>([])
    const [countrySelected, setCountrySelected] = useState<any>(null)
    const [errorTypeStructure, setErrorTypeStructure] = useState<boolean>(false)
    const [errorRequired, setErrorRequired] = useState<any>(false)
    const [errorGratification, setErrorGratification] = useState<any>(false)
    const [errorCountry, setErrorCountry] = useState<any>(false)

    const validateForm = (values) => {
        let errors: any = {};
        if(!values.code) errors.code = ___PHRASE("nombreRequerido");
        if(!values.name) errors.name = ___PHRASE("nombreRequerido");
        // if(!typeStructureSelected) errors.idtypestructure = ___PHRASE("nombreRequerido");
        // if(!requiredSelected) errors.required = ___PHRASE("nombreRequerido");
        // if(!gratificationSelected) errors.gratification = ___PHRASE("nombreRequerido");
        if(!values.concept_DT) errors.concept_DT = ___PHRASE("nombreRequerido");
        // if(!countrySelected) errors.countrySelected = ___PHRASE("nombreRequerido");
        if(!typeStructureSelected){ setErrorTypeStructure(true)}
        if(!requiredSelected) {setErrorRequired(true)}
        if(!gratificationSelected) {setErrorGratification(true)}
        if(!countrySelected) {setErrorCountry(true)}
        return errors;
    }

    const onSubmit = async(values, {resetForm})  => {
        if(!typeStructureSelected){ setErrorTypeStructure(true)}
        if(!requiredSelected) {setErrorRequired(true)}
        if(!gratificationSelected) {setErrorGratification(true)}
         if(!countrySelected) {setErrorCountry(true)}
        if(!typeStructureSelected || !requiredSelected || !gratificationSelected || !countrySelected){return}
       
        const formData = {
            id: values.id,
            code: values.code,
            name: values.name,
            idtypestructure: typeStructureSelected.id,
            required: requiredSelected.id,
            percentage: values.percentage,
            gratification: gratificationSelected.id,
            concept_DT: values.concept_DT,
            idcountry: countrySelected.id,
        }
        setLoading(true);
        if(structure && actionSelected=='edit'){
            await RleStructureService.editRLEStructure(structure.id, formData).then((data) => {
                let apimessagge = apiResponseTranslate(data?.data?.message || '');
                if(data.data.status){
                    setSnackBarConfig({...snackBarConfig, open: true, severity: 'success', message: apimessagge})
                }else{
                    setSnackBarConfig({...snackBarConfig, open: true, severity: 'error', message:apimessagge})
                }
                setTimeout(()=>{
                onListStructure && onListStructure();
                closeModal && closeModal();
            },2000)
            })
        }else{
            await RleStructureService.createStructure(formData).then((data) => {
                let apimessagge = apiResponseTranslate(data?.data?.message || '');
                if(data.data.status){
                    setSnackBarConfig({...snackBarConfig, open: true, severity: 'success', message: apimessagge})
                }else{
                    setSnackBarConfig({...snackBarConfig, open: true, severity: 'error', message:apimessagge})
                }
                setTimeout(()=>{
                    onListStructure && onListStructure();
                    closeModal && closeModal();
                },2000)
            })
        }
        setLoading(false);
    }

    const getTypeStructure = async () =>{
        const resp:any = await RleStructureService.getRLEStructureType();
        setTypeStructure(resp.data)
        if(actionSelected == 'edit'){
            const objTypeStructure = resp.data.find((value)=> value.id == structure.idtypestructure)
            setTypeStructureSelected(objTypeStructure)
        }
    }

    const getCountry = async () => {
        const resp:any = await CountryService.getCountries();
        const newData = [];
        resp.data && (resp.data).length > 0 && (resp.data).map((item)=>{
            if(language && language == 'en'){
                newData.push({...item, name: apiResponseTranslate(item.name)})
            }else{
                newData.push(item)
            }
        })
        setCountries(newData || [])
        if(actionSelected == 'edit'){
            const objCountry = resp.data.find((value)=> value.id == structure.idcountry)
            setCountrySelected(objCountry)
        }
    }

    const getDataEdit = () => {
        const objRequired =  requiredOptions.find((value) => value.id == structure.required)
        setRequiredSelected(objRequired)
        const objGrati =  gratificationOptions.find((value) => value.id == structure.gratification)
        setGratificationSelected(objGrati)
    }
    useEffect(()=>{
        if(open){
            setLoading(true)
            getTypeStructure();
            getCountry();
            if(actionSelected == 'edit'){
                getDataEdit();
            }
            setTimeout(()=>{
                setLoading(false)
            },1500)
        }
    },[open])


    return(
        <>
        {loading && <Loading/>}
        <Snackbar
            open={snackBarConfig.open}
            autoHideDuration={snackBarConfig.autoHideDuration}
            onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
            <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                {snackBarConfig.message || ""}
            </Alert>
        </Snackbar>
        <Modal open={open} handleClose={closeModal} handle size="sm">
            <ModalHeader
                text={(actionSelected == 'edit' ? ___PHRASE("editar") : ___PHRASE("agregar"))+' '+___PHRASE("estructurasDeLiquidacion")}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue,setFieldTouched, setErrors, setFieldError}) =>{
                        return(
                            <form onSubmit={handleSubmit}>
                                <Grid container >

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("codigo")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="code"
                                            variant="standard"
                                            type="text" 
                                            name="code"
                                            fullWidth
                                            value={values.code}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("codigo")}
                                            error={errors.code && touched.code ? true : false}
                                        />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("nombre")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="name"
                                            variant="standard"
                                            type="text" 
                                            name="name"
                                            fullWidth
                                            value={values.name}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("nombre")}
                                            error={errors.name && touched.name ? true : false}
                                        />
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                            <Grid item xs={4}>
                                                <Typography variant="body1"><b>{___PHRASE("tipoDeEstructura")}:</b> </Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Autocomplete
                                                    id="disable-portal"
                                                    disablePortal
                                                    value={typeStructureSelected}
                                                    options={typeStructure || []}
                                                    getOptionLabel={(option) => option.name || ""}
                                                    fullWidth
                                                    renderInput={(params) => <TextField {...params} variant="standard" label={___PHRASE("escribeParaBuscar")} 
                                                    error={errorTypeStructure && touched.idtypestructure? true : false}
                                                    />}
                                                    onChange={(e, newValue) => { 
                                                    setFieldTouched("idtypestructure", true); 
                                                    setTypeStructureSelected(newValue);
                                                    if(newValue){setErrorTypeStructure(false)}                                               
                                                 }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                            <Grid item xs={4}>
                                                <Typography variant="body1"><b>{___PHRASE("obligatorio")}:</b> </Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Autocomplete
                                                    id="disable-portal"
                                                    disablePortal
                                                    value={requiredSelected}
                                                    options={requiredOptions || []}
                                                    getOptionLabel={(option) => option.name || ""}
                                                    fullWidth
                                                    renderInput={(params) => <TextField {...params} variant="standard" label={___PHRASE("escribeParaBuscar")}
                                                    error={errorRequired && touched.required ? true: false} />}
                                                    onChange={(e, newValue) => { 
                                                        setFieldTouched("required", true);
                                                        setErrorRequired(false)
                                                        if(newValue){
                                                            setRequiredSelected(newValue)
                                                        }
                                                         }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                            <Grid item xs={4}>
                                                <Typography variant="body1"><b>{___PHRASE("gratificacion")}:</b> </Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Autocomplete
                                                    id="disable-portal"
                                                    disablePortal
                                                    value={gratificationSelected}
                                                    options={gratificationOptions || []}
                                                    getOptionLabel={(option) => option.name || ""}
                                                    fullWidth
                                                    renderInput={(params) => <TextField {...params} variant="standard" label={___PHRASE("escribeParaBuscar")}
                                                    error={errorGratification && touched.gratification ? true: false} />}
                                                    onChange={(e, newValue) => { 
                                                        setFieldTouched('gratification', true)
                                                        setErrorGratification(false) 
                                                        if(newValue){
                                                            setGratificationSelected(newValue);
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px'>
                                        <Grid item xs={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{___PHRASE("conceptoDT")}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={8}>
                                        <TextField
                                            id="concept_DT"
                                            variant="standard"
                                            type="text" 
                                            name="concept_DT"
                                            fullWidth
                                            value={values.concept_DT}
                                            onChange={handleChange}
                                            placeholder={___PHRASE("conceptoDT")}
                                            error={errors.concept_DT && touched.concept_DT ? true : false}
                                        />
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} alignItems="center" justifyContent="center" marginBottom='25px'>
                                            <Grid item xs={4}>
                                                <Typography variant="body1"><b>{___PHRASE("pais")}:</b> </Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Autocomplete
                                                    id="disable-portal"
                                                    disablePortal
                                                    value={countrySelected}
                                                    options={countries || []}
                                                    getOptionLabel={(option) => option.name || ""}
                                                    fullWidth
                                                    renderInput={(params) => <TextField {...params} variant="standard" label={___PHRASE("escribeParaBuscar")}
                                                    error={errorCountry && touched.idcountry ? true : false} />}
                                                    onChange={(e, newValue) => {
                                                         setFieldTouched('idcountry', null)
                                                         setErrorCountry(false)
                                                        if(newValue){setCountrySelected(newValue); }
                                                        }}
                                                />
                                            </Grid>
                                        </Grid>

                                </Grid>
                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    confirmText={(structure && actionSelected=='edit') ? ___PHRASE("actualizar") : ___PHRASE("registrar")}
                                    cancelText={___PHRASE("cancelar")} 
                                    onCancel={closeModal}
                                    onConfirm={handleSubmit}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }}
                </Formik>
            </ModalBody>
        </Modal>
        </>
    );
}