import React, { useEffect, useRef, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Alert, Backdrop, CircularProgress, Container, Divider, Grid, IconButton, Menu, MenuItem, Snackbar } from "@mui/material";
import { TableDataV2 } from '@/components/common/Tablev2';
import { costCentersRepository } from '@/service/repositories/CostCenters.repository';
import { Loading } from '@/components/common/Loading';
import { Props } from './ValueExtraHours.type';
import ___PHRASE from '@/service/translate/translateService';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import { KEY_TRANSLATE } from '@/toolbox/constants/local-storage';
import { apiResponseTranslate } from '@/toolbox/helpers/responseApi.helper';

export const ValueExtraHoursView: React.FC<Props> = (props:any): JSX.Element => {

   const [loading, setLoading] = useState<boolean>(false);
   const [costCenters, setCostCenters] = useState([]);
   const [showCostCenterModal, setShowCostCenterModal] = useState<boolean>(false);
   const [showCostCenterDeleteModal, setShowCostCenterDeleteModal] = useState<boolean>(false);
   const [actionSelected, setActionSelected] = useState<String>('');
   const [costCenterSelected, setCostCenterSelected] = useState<String>('');

   const language = readLocalStorage(KEY_TRANSLATE);

   useEffect(() => {
      getCenterCostos()
   },[]);

   const getCenterCostos = async () => {
      setLoading(true);
      const resp = await costCentersRepository.getCostCenters();
      const newData = [];

      resp.data && (resp.data).length > 0 && (resp.data).map((item)=>{
         if(language && language == 'en'){
            newData.push({
               ...item, 
               name: apiResponseTranslate(item.name),
               description: apiResponseTranslate(item.description),
            })
         }else{
            newData.push(item)
         }
      })

      setCostCenters(newData || []);
      setLoading(false);
   }

   function RecuperarData(value){
      console.log(value)
   }

  return (
      <Protected>
         {loading && <Loading/>}
         <Container>
            <TableDataV2
               title={___PHRASE("centrosdecostos")}
               data={costCenters}
               header={[
                  { name: 'name', label: ___PHRASE("nombre"), filter: false, Chip: true },
                  { name: 'description', label: ___PHRASE("descripcion"), filter: false, Chip: false },
               ]}
               actionSelect={setActionSelected}
               setModalSave={setShowCostCenterModal}
               setModalDelete={setShowCostCenterDeleteModal}
               status_action
               // checkbox
               RecuperarData={RecuperarData}
            />
         </Container>
      
      </Protected>
   )
};