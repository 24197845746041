import React, { useEffect, useState } from 'react';
import { Protected } from '@components/layout/Protected';
import { Loading } from '@/components/common/Loading';
import { Alert, Autocomplete, Box, Button, Card,  Container, Grid, IconButton, Snackbar, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { ReportService } from '@/service/services/Report.service';
import { WorkerService } from '@/service/services/Worker.service';
import { API_URL_BASE } from '@/toolbox/defaults/app';
import { IntegrationDiviceService } from '@/service/services/IntegrationDiviceService';
import { KEY_USER_DATA } from '@/toolbox/constants/local-storage';
import { readLocalStorage } from '@/toolbox/helpers/local-storage-helper';
import MovingIcon from '@mui/icons-material/Moving';
import { ModalAbsenceReport } from '@/components/Modal/ModalAbsenceReport';
import moment from 'moment';
import ___PHRASE from '@/service/translate/translateService';

const Div = styled('div')(({ theme }) => ({
    ...theme.typography.button,
    padding: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: '20px',
    color: 'rgba(102, 102, 102, 0.4)',
}));

export const DelayAbsenceExtraHourView: React.FC<any> = (props: any): JSX.Element => {
   
    const [data, setData] = useState<any>('')
    const [loading, setLoading] = useState<boolean>(false);
    const [snackBarConfig, setSnackBarConfig] = useState<any>({
        open: false,
        severity: 'error',
        message: 'Error',
        autoHideDuration: 3000,
    });

    const [workerOptions, setWorkerOptions] = useState<any>([]);
    const [workerSelected, setWorkerSelected] = useState<any>(null)
    const [startDateSelected, setStartDateSelected] = useState<any>('')
    const [endDateSelected, setEndDateSelected] = useState<any>('')
    const [excelDissable, setExcelDissable]= useState<any>(false);
    const [isIntegration, setIsIntegration] = useState<boolean>(false);
    const [openModal,setOpenModal] = useState<boolean>(false)

    const user = readLocalStorage(KEY_USER_DATA);
    const idCompany = user.user.id_company

    useEffect(()=> {
        getDivicesByCompany();
    },[]);

    const getDivicesByCompany = async() => {
        setLoading(true)
        const divices = await IntegrationDiviceService.getDivicesByCompany(idCompany);
    
        if(divices?.data && (divices?.data).length > 0) { 
            getEmployeeRemyZkteco();
            setIsIntegration(true);
            setLoading(false);
            return true;
        }
        
        getEmployees();
        setLoading(false);
        return false;
    }

    const getEmployees = async  () => {
        const resp = await WorkerService.getWorkers();
        setWorkerOptions(resp?.data || [])
    }

    const getEmployeeRemyZkteco = async() => {
        setLoading(true)
        const resp = await IntegrationDiviceService.getEmployeeRemyZkteco();
        const oldData = resp.data || [];
        let newData = [];
        oldData.length > 0 && oldData.map((item) => {
            let obj = {
                ...item,
                name_complete: item.name+' '+item.surname+' '+item.second_surname,
            }
            newData.push(obj);
        });
        setWorkerOptions(newData || []);
        setLoading(false)
    }

    const getReportAbsenceDelay = async () => {
        
        if(!startDateSelected || !endDateSelected || !workerSelected){
            return (
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                    severity:'warning', 
                    message: ___PHRASE("debeCompletarTodosLosCamposParaRealizarElFiltrado") }
                ))
            );
        }

        let currentDate = moment().format("YYYY/MM/DD");
        let dateStart= '';
        let dateEnd= '';
        if(startDateSelected) dateStart = moment(startDateSelected).format("YYYY/MM/DD");
        if(endDateSelected) dateEnd = moment(endDateSelected).format("YYYY/MM/DD");

        if(startDateSelected && endDateSelected){
            if(dateStart >= dateEnd) return (
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                    severity:'warning', 
                    message: ___PHRASE("asegureseQueLaFechaInicialSeaMenorQueLaFinal") }
                ))
            );
            if(dateStart > currentDate || dateEnd > currentDate ) return (
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                    severity:'warning', 
                    message: ___PHRASE("asegureseQueLaFechaDeInicioyFinSeaMenorQueLaFechaActual") }
                ))
            );
        }

        setLoading(true);

        if(isIntegration){
            await IntegrationDiviceService.getReportAbsenceDelay(startDateSelected, endDateSelected, workerSelected?.id).then((data) => {
                setData(data.data || []);
                setLoading(false);
                setExcelDissable(true);
            })
        }else{
            await ReportService.getReportAbsenceDelay(startDateSelected, endDateSelected, workerSelected?.id).then((data) => {
                setData(data.data || []);
                setLoading(false);
                setExcelDissable(true);
            })
        }
        setLoading(false);
    }

    const getReportExcelAbsencePDF = async (typeDownload) => {

        if(!startDateSelected || !endDateSelected || !workerSelected){
            return (
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                    severity:'warning', 
                    message: ___PHRASE("debeCompletarTodosLosCamposParaRealizarElFiltrado") }
                ))
            );
        }

        let currentDate = moment().format("YYYY/MM/DD");
        let dateStart= '';
        let dateEnd= '';
        if(startDateSelected) dateStart = moment(startDateSelected).format("YYYY/MM/DD");
        if(endDateSelected) dateEnd = moment(endDateSelected).format("YYYY/MM/DD");

        if(startDateSelected && endDateSelected){
            if(dateStart >= dateEnd) return (
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                    severity:'warning', 
                    message: ___PHRASE("asegureseQueLaFechaInicialSeaMenorQueLaFinal") }
                ))
            );
            if(dateStart > currentDate || dateEnd > currentDate ) return (
                setSnackBarConfig(prev => ({ ...prev, open: true, 
                    severity:'warning', 
                    message: ___PHRASE("asegureseQueLaFechaDeInicioyFinSeaMenorQueLaFechaActual") }
                ))
            );
        }

        setLoading(true);

        if (typeDownload == 'excel') {
            
            const respExcel = await ReportService.getReportExcelAbsence(startDateSelected, endDateSelected, workerSelected?.id);
            const btn_excel = document.createElement("a")
            btn_excel.href = `${respExcel.data.link}`
            btn_excel.download = 'Absences_tardies_overtime_report.xlsx'

            document.body.appendChild(btn_excel);
            btn_excel.click();
            document.body.removeChild(btn_excel)
        }else{
            const respCSV = await ReportService.getReportExcelAbsence(startDateSelected, endDateSelected, workerSelected?.id);
            const btn_csv = document.createElement("a")
            btn_csv.href = `${API_URL_BASE}${respCSV.data.link}`
            
            btn_csv.download = 'Absences_tardies_overtime_report.csv'

            document.body.appendChild(btn_csv);
            btn_csv.click();
            document.body.removeChild(btn_csv)
    
        }
        setLoading(false);
    }

    return (
        <Protected>
            {loading && <Loading/>}
            <Snackbar
                open={snackBarConfig.open} 
                autoHideDuration={snackBarConfig.autoHideDuration} 
                onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'top', horizontal:'right' }} >
                <Alert onClose={() => setSnackBarConfig(prev => ({ ...prev, open: false }))} severity={snackBarConfig.severity}>
                    {snackBarConfig.message || ""}
                </Alert>
            </Snackbar>
            <Container>
                <Typography variant='h6' component='h1' gutterBottom className="tittle" sx={{ m: 1, fontWeight: 600 }}>
                    {___PHRASE("reporteDeTardanzasInasistenciasyHorasExtras")}
                </Typography>
                    
                <Grid container xs={12} direction="row" justifyContent="flex-start" alignItems="center">
                    <Grid sx={{border: 'solid 1px #e9e2e2', borderRadius:'10px', padding: '5px 8px 8px 8px', marginRight: '10px'}}>
                        <Autocomplete
                            id="disable-portal"
                            disablePortal
                            value={workerSelected}
                            options={workerOptions || []}
                            getOptionLabel={(option) => option.name+' '+option.surname+' '+option.second_surname || ""}
                            onChange={(e, newValue) => { setWorkerSelected(newValue) }}
                            sx={{ width: '350px' }}
                            renderInput={(params) => <TextField {...params} variant="standard" label={___PHRASE("seleccioneUnTrabajador")} />}
                        />
                    </Grid>
                    <Grid sx={{border: 'solid 1px #e9e2e2', borderRadius:'10px', padding: '5px 8px 8px 8px', marginRight: '10px'}}>
                        <TextField
                            id="start_date"
                            variant="standard"
                            name="start_date"
                            sx={{ width: '200px' }}
                            label={___PHRASE("desde")}
                            type="date"
                            value={startDateSelected}
                            onChange={(e)=>{setStartDateSelected(e.target.value)}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid sx={{border: 'solid 1px #e9e2e2', borderRadius:'10px', padding: '5px 8px 8px 8px', marginRight: '10px'}}>
                        <TextField
                            id="end_date"
                            variant="standard"
                            sx={{ width: '200px' }}
                            name="end_date"
                            label={___PHRASE("hasta")}
                            type="date"
                            value={endDateSelected}
                            onChange={(e)=>{setEndDateSelected(e.target.value)}}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>

                    <Grid display='flex'>
                        <Button onClick={getReportAbsenceDelay} type={'submit'} size="small" color={"primary"} variant="contained">
                            {___PHRASE("filtrar")}
                        </Button>
                    </Grid>
                    
                </Grid>
                    
                {
                    (!data && !loading) && (
                        <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'150px', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '30px' }} >
                            <Div>{___PHRASE("noSeEncontraronDatos")}</Div>
                        </Box>
                    )
                }
                {
                    loading && (
                        <Box sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'150px', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '30px' }}>
                            <Div>{___PHRASE("cargandoDatosEspereUnMomento")}</Div>
                        </Box>
                    )
                }

                {(excelDissable && !loading) &&
                    <Grid container spacing={3} sx={{ marginTop: '10px', marginBottom: '20px' }}>
                        <Grid item xs={12} md={12} display='flex' justifyContent='flex-end'>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => getReportExcelAbsencePDF('excel')}
                            >{___PHRASE("descargarExcel")}
                            </Button>
                        </Grid>
                    </Grid>
                }
                
                 {
                    (data && !loading) && (
                        <Card sx={{ width: "100%", marginTop: '20px' }}>
                            <TableContainer>
                            <Table>
                                <TableHead sx={{ bgcolor: '#EDEFF1' }}>
                                    <TableRow >
                                        <TableCell >
                                            <Typography>{___PHRASE("empleado")}</Typography>
                                        </TableCell>
                                        <TableCell >
                                            <Typography>{___PHRASE("tardanzasMin")}</Typography>
                                        </TableCell>
                                        <TableCell >
                                            <Typography>{___PHRASE("fechasInasistencias")}</Typography>
                                        </TableCell>
                                        <TableCell >
                                            <Typography>{___PHRASE("totalInasistencia")}</Typography>
                                        </TableCell>
                                        <TableCell >
                                            <Typography>{___PHRASE("extrasMin")}</Typography>
                                        </TableCell>
                                        <TableCell >
                                            <Typography>{___PHRASE("acciones")}</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow >
                                        {
                                            data && (<>
                                                <TableCell size={'small'} >
                                                    {data.employee}
                                                </TableCell>
                                                <TableCell size={'small'} >
                                                    {data.delay}
                                                </TableCell>
                                                <TableCell size={'small'} >
                                                    { data.missing_date?.length > 0 && data.missing_date.map((date, key)=>(
                                                            <div key={key}>{date}</div>
                                                        )) || '---'
                                                    }
                                                </TableCell>
                                                <TableCell size={'small'} >
                                                    {data.absence}
                                                </TableCell>
                                                <TableCell size={'small'} >
                                                    {data.extra_hour}
                                                </TableCell>
                                                <TableCell size={'small'} >
                                                    <Tooltip title={___PHRASE("crearInasistencias")}>
                                                    <IconButton size="small" color="inherit" aria-label="view"
                                                        onClick={() => {
                                                        setOpenModal(true)
                                                        }}
                                                    >
                                                        <MovingIcon fontSize='small' />
                                                    </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </>)
                                        }
                                    </TableRow >
                                </TableBody>
                            </Table>
                            </TableContainer>
                        </Card>
                    )
                }

            </Container>
            {
                openModal && 
                <ModalAbsenceReport
                open={openModal}
                closeModal={()=>{setOpenModal(false)}}
                dataInasis={data}
                worker={workerSelected}
                />
            }
        </Protected>
    )
};
