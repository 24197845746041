import { useEffect, useState } from "react"
import { TableDataV2 } from "@/components/common/Tablev2"
import { Protected } from "@/components/layout/Protected"
import { Autocomplete, Box, Button, Container, Grid, TextField, Typography } from "@mui/material"
import { Loading } from "@/components/common/Loading"
import { CountryService } from "@/service/services/Country.service"
import { Props } from "./District.type"
import { ModalCountryDistrict } from "@/components/Modal/ModalCountryDistrict/ModalCountryDistrict"
import { ModalCountryDistrictDelete } from "@/components/Modal/ModalCountryDistrictDelete/ModalCountryDistrictDelete"
import ___PHRASE from "@/service/translate/translateService"
import { apiResponseTranslate } from "@/toolbox/helpers/responseApi.helper"
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper"
import { KEY_TRANSLATE, KEY_USER_DATA } from "@/toolbox/constants/local-storage"

export const DistrictView: React.FC<Props> = (props:any): JSX.Element => {

    const [loading, setLoading] = useState<boolean>(false);
    const [districts, setDistricts] = useState<any>([]);
    const [showdistrictModal, setShowDistrictModal] = useState<boolean>(false);
    const [showDistrictDeleteModal, setShowDistrictDeleteModal] = useState<boolean>(false);
    const [districtSelected, setDistrictSelected] = useState<any>('');
    const [actionSelected, setActionSelected] = useState<any>('');

    const [countries, setCountries] = useState<any>([]);
    const [countrySelected, setCountrySelected] = useState<any>('');
    const [departaments, setDepartaments] = useState<any>([]);
    const [departamentSelected, setDepartamentSelected] = useState<any>('');
    const [provinces, setProvinces] = useState<any>([]);
    const [provinceSelected, setProvinceSelected] = useState<any>('');
    
    const [pageInitial, setPageInitial] = useState(false);

    const language = readLocalStorage(KEY_TRANSLATE);
    const dataUser = readLocalStorage(KEY_USER_DATA);
    const typeSuperadmin = dataUser?.user?.type_superadmin;

    useEffect(() => {
        getCountries();
    }, []);

    useEffect(() => {
        if(countrySelected) getDepartments(countrySelected.id);
    }, [countrySelected]);

    const getCountries = async () => {
        setLoading(true);
        await CountryService.getCountries().then((data) => {
            const newData = [];
            data.data && (data.data).length > 0 && (data.data).map((item)=>{
                if(language && language == 'en'){
                    newData.push({...item, name: apiResponseTranslate(item.name)})
                }else{
                    newData.push(item)
                }
            })
            setCountries(newData || []);
            setCountrySelected(newData[0] || '');
            setLoading(false);
        });
    }

    const getDepartments = async (idCountry) => {
        setLoading(true);
        await CountryService.getDepartments(idCountry).then((data) => {
            setDepartaments(data.data || [])
           setLoading(false);
        });
    }

    const getProvinces = async (idDepartament) => {
        setLoading(true);
        await CountryService.getProvinces(idDepartament).then((data) => {
            setProvinces(data.data || [])
           setLoading(false);
        });
    }

    const getDistricts = async (idProvince) => {
        setLoading(true);
        await CountryService.getDistricts(idProvince).then((data) => {
            setDistricts(data.data || [])
           setLoading(false);
        });
    }

    function RecuperarData(value){
        setDistrictSelected(value)
        console.log(value)
    }

    return (
        <Protected>
            {loading && <Loading/>}
            <Container>
                <Grid item xs={12} container direction={'row'} justifyContent='space-between' alignItems={'center'} sx={{marginLeft:'25px'}}>
                <Typography variant='h6' component='h1' gutterBottom className="tittle" sx={{ mt: 1 , fontWeight:600}}>{___PHRASE("filtrosDeBusqueda")}</Typography>
                    
                    <Grid item container direction={'row'} xs={10} spacing={2} >
                        <Grid item xs={12} md={4} mt={2}>
                            <Autocomplete
                            id="disable-portal"
                            disablePortal
                            value={countrySelected}
                            options={countries || []}
                            getOptionLabel={(option) => option.name || ""}
                            fullWidth
                            renderInput={(params) => <TextField {...params} label={___PHRASE("filtrarPorPais")} />}
                            onChange={(e, newValue) => {
                                    setCountrySelected(newValue);
                                    if(newValue){
                                        getDepartments(newValue.id);
                                    }else{
                                        setDepartaments([]);
                                        setProvinces([])
                                        setDistricts([])
                                    }
                                    setDepartamentSelected('');
                                    setProvinceSelected('')
                            }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} mt={2}>
                            <Autocomplete
                                id="disable-portal"
                                disablePortal
                                value={departamentSelected}
                                options={departaments || []}
                                getOptionLabel={(option) => option.name || ""}
                                fullWidth
                                renderInput={(params) => <TextField {...params} label={___PHRASE("filtrarPorDepartamento")} />}
                                onChange={(e, newValue) => { 
                                        setDepartamentSelected(newValue); 
                                        if(newValue) {
                                            getProvinces(newValue?.id);
                                        }else{
                                            setProvinces([])
                                            setDistricts([])
                                        }
                                        setProvinceSelected('')
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} mt={2}>
                            <Autocomplete
                                id="disable-portal"
                                disablePortal
                                value={provinceSelected}
                                options={provinces || []}
                                getOptionLabel={(option) => option.name || ""}
                                fullWidth
                                renderInput={(params) => <TextField {...params} label={___PHRASE("filtrarPorProvincia")} />}
                                onChange={(e, newValue) => { 
                                    setProvinceSelected(newValue);
                                    if(newValue) {getDistricts(newValue?.id); setPageInitial(true);}else{
                                        setDistricts([])
                                    }; 
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <TableDataV2
                    title={___PHRASE("comunas")}
                    data={districts || []}
                    header={[
                        { name: 'code', label: ___PHRASE("codigo"), filter: false, Chip: false },
                        { name: 'name', label: ___PHRASE("nombre"), filter: false, Chip: false },
                    ]}
                    actionSelect={setActionSelected}
                    setModalSave={setShowDistrictModal}
                    setModalDelete={setShowDistrictDeleteModal}
                    status_action={typeSuperadmin == '1' ? true : false}
                    btn_disabled={typeSuperadmin == '1' ? false : true}
                    setPageInitial={setPageInitial}
                    pageInitial={pageInitial}
                    RecuperarData={RecuperarData}
                />
            </Container>

            { 
                showdistrictModal && (
                    <ModalCountryDistrict
                        open={showdistrictModal}
                        closeModal={()=>{setShowDistrictModal(false); setDistrictSelected('')}}
                        actionSelected={actionSelected}
                        onListDistrict={getDistricts}
                        district={districtSelected}
                        clearState={()=>{setDistrictSelected('')}}
                        provinceSelected={provinceSelected}
                    />
                )
            }
            {
                actionSelected === 'delete' && (
                    <ModalCountryDistrictDelete
                        open={showDistrictDeleteModal}
                        closeModal={()=>{setShowDistrictDeleteModal(false); setDistrictSelected('')}}
                        actionSelected={actionSelected}
                        onListDistrict={getDistricts}
                        district={districtSelected}
                        clearState={()=>{setDistrictSelected('')}}
                        provinceSelected={provinceSelected}
                    />
                )
            }
        </Protected>
    )
}