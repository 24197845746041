import { Grid, Typography, List, ListItem, ListItemText, Divider } from "@mui/material";
import { styled } from '@mui/material/styles';
import ___PHRASE from '@/service/translate/translateService';
import { apiResponseTranslate } from "@/toolbox/helpers/responseApi.helper";

const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
}));

export const DetailEmployee = (props: any): JSX.Element => {

    const { data } = props;

    return (
        <>
        <Grid display='flex'>

            <Grid item xs={12} md={6}>
                <Demo>
                    <List>
                        <ListItem>
                            <ListItemText style={{minWidth: '200px', maxWidth: '200px'}}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}> {___PHRASE("nombrecompleto")} </Typography>
                            </ListItemText>
                            <ListItemText >
                                <Typography variant="body2">{`: ${data.surname +' '+ data.second_surname +' '+ data.name}`}</Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText style={{minWidth: '200px', maxWidth: '200px'}}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold'}}>RUT</Typography>
                            </ListItemText>
                            <ListItemText >
                                <Typography variant="body2">{`: ${data.n_document}`}</Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText style={{minWidth: '200px', maxWidth: '200px'}}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold'}}>{___PHRASE("cargo")}</Typography>
                            </ListItemText>
                            <ListItemText >
                                <Typography variant="body2">{`: ${apiResponseTranslate(data.charge || '')}`}</Typography>
                            </ListItemText>
                        </ListItem>
                    </List>
                </Demo>
            </Grid>

            <Grid item xs={12} md={6}>
                <Demo>
                    <List>
                        <ListItem>
                            <ListItemText style={{minWidth: '200px', maxWidth: '200px'}}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>UF</Typography>
                            </ListItemText>
                            <ListItemText >
                                <Typography variant="body2">{`: ${data.UF}`}</Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText style={{minWidth: '200px', maxWidth: '200px'}}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold'}}>UTM</Typography>
                            </ListItemText>
                            <ListItemText >
                                <Typography variant="body2">{`: ${data.UTM}`}</Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText style={{minWidth: '200px', maxWidth: '200px'}}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold'}}>{___PHRASE('tope')} Max Imp</Typography>
                            </ListItemText>
                            <ListItemText >
                                <Typography variant="body2">{`: ${data.Tope_Max_Imp}`}</Typography>
                            </ListItemText>
                        </ListItem>
                    </List>
                </Demo>
            </Grid>
        </Grid>

        <Divider></Divider>

        <Grid display='flex'>
            <Grid item xs={12} md={6}>
                <Demo>
                    <List>
                        <ListItem>
                            <ListItemText style={{minWidth: '200px', maxWidth: '200px'}}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{___PHRASE("fondodepensiones")}</Typography>
                            </ListItemText>
                            <ListItemText >
                                <Typography variant="body2">{`: ${data.AFP + ' ' + data.AFP_percentage}% `}</Typography>
                            </ListItemText>
                        </ListItem>
                    </List>
                </Demo>
            </Grid>

            <Grid item xs={12} md={6}>
                <Demo>
                    <List>
                        <ListItem>
                            <ListItemText style={{minWidth: '200px', maxWidth: '200px'}}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{___PHRASE("sistemadesalud")}</Typography>
                            </ListItemText>
                            <ListItemText >
                                <Typography variant="body2">{`: ${data.salud + ' ' + data.salud_percentage}% `}</Typography>
                            </ListItemText>
                        </ListItem>
                    </List>
                </Demo>
            </Grid>
        </Grid>

        <Divider></Divider>

        <Grid display='flex'>
            <Grid item xs={12} md={6}>
                <Demo>
                    <List>
                        <ListItem>
                            <ListItemText style={{minWidth: '200px', maxWidth: '200px'}}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{___PHRASE('diastrabajados')}</Typography>
                            </ListItemText>
                            <ListItemText >
                                <Typography variant="body2">{`: ${data.work_days} ${___PHRASE('dias')}`}</Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText style={{minWidth: '200px', maxWidth: '200px'}}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold'}}>{___PHRASE("inasistencias")}</Typography>
                            </ListItemText>
                            <ListItemText >
                                <Typography variant="body2">{`: ${data.absences}`}</Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText style={{minWidth: '200px', maxWidth: '200px'}}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold'}}>{___PHRASE("basetributable")}</Typography>
                            </ListItemText>
                            <ListItemText >
                                <Typography variant="body2">{`: $ ${data.tax_base}`}</Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText style={{minWidth: '200px', maxWidth: '200px'}}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold'}}>{___PHRASE("horasExtras")}</Typography>
                            </ListItemText>
                            <ListItemText >
                                <Typography variant="body2">{`: ${data.extra_hours}`}</Typography>
                            </ListItemText>
                        </ListItem>
                    </List>
                </Demo>
            </Grid>

            <Grid item xs={12} md={6}>
                <Demo>
                    <List>
                        <ListItem>
                            <ListItemText style={{minWidth: '200px', maxWidth: '200px'}}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{___PHRASE("sueldobase")} Cto.</Typography>
                            </ListItemText>
                            <ListItemText >
                                <Typography variant="body2">{`: $ ${data.desc_base_salary}`}</Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText style={{minWidth: '200px', maxWidth: '200px'}}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold'}}>{___PHRASE("atrasos")}</Typography>
                            </ListItemText>
                            <ListItemText >
                                <Typography variant="body2">{`: ${data.delay} ${___PHRASE('horas')}`}</Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText style={{minWidth: '200px', maxWidth: '200px'}}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold'}}>{___PHRASE("totalsalud")}</Typography>
                            </ListItemText>
                            <ListItemText >
                                <Typography variant="body2">{`: $ ${data.total_health}`}</Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText style={{minWidth: '200px', maxWidth: '200px'}}>
                                <Typography variant="body2" sx={{ fontWeight: 'bold'}}>{___PHRASE("cargasFamiliares")}</Typography>
                            </ListItemText>
                            <ListItemText >
                                <Typography variant="body2">{`: ${data.family_allowance}`}</Typography>
                            </ListItemText>
                        </ListItem>
                    </List>
                </Demo>
            </Grid>
        </Grid>
        </>
    )
}
