import { Button, Container, Grid, Typography } from "@mui/material"
import { Props } from "./PageNotFound.type"
import { readLocalStorage } from "@/toolbox/helpers/local-storage-helper"
import { ROLE_ADMIN, ROLE_SUPER_ADMIN, ROLE_TRABAJADOR } from "@/toolbox/constants/role-type"
import { useHistory } from "react-router-dom"
import { ROUTE_COMPANIES, ROUTE_MY_VALIDATIONS, ROUTE_USER } from "@/toolbox/constants/route-map"
import { KEY_TRANSLATE, KEY_USER_DATA, KEY_USER_PERMISSION } from "@/toolbox/constants/local-storage"
import './PageNotFound.css'
import { apiResponseTranslate } from "@/toolbox/helpers/responseApi.helper"

export const PageNoFoundView: React.FC<Props> = (props:any): JSX.Element => {

    const language = readLocalStorage(KEY_TRANSLATE);
    const history = useHistory();

    const redirect = () => {
        const userSession = readLocalStorage(KEY_USER_DATA);
        const role = userSession?.user?.role;

        switch (role) {
            case ROLE_SUPER_ADMIN:
                history.push(ROUTE_COMPANIES);
                break;

            case ROLE_ADMIN:
                history.push(ROUTE_USER);
                break;

            case ROLE_TRABAJADOR:
                history.push(ROUTE_MY_VALIDATIONS);
                break;
        }
    }

    return (
        <Container className={'page-not-found-container'}>
            <Grid>
                {/* imagen */}
            </Grid>
            <Grid className={'text-container'}>
                 <div className={'text-error'}>
                    {language == 'en' ? apiResponseTranslate('PÁGINA NO ENCONTRADA') : 'PÁGINA NO ENCONTRADA'}
                </div>
            </Grid>
            <Grid className={'number-container'}>
                <div className={'number-error'}>404</div>
            </Grid>
            <Grid className={'paragraph-container'}>
                <div className={'paragraph-error'}>
                    {language == 'en' ? apiResponseTranslate('Lo sentimos, no pudimos encontrar la página que estas buscando.') : 'Lo sentimos, no pudimos encontrar la página que estas buscando.'}
                </div>
            </Grid>
            <Grid className={'btn-container'}>
            <Button variant="contained" className={'btn-redirect'} onClick={redirect}>
                    {language == 'en' ? apiResponseTranslate('IR A LA PÁGINA DE INICIO') : 'IR A LA PÁGINA DE INICIO'}
                </Button>
            </Grid>
        </Container>
    )
}