import { WorkerValidationRepository } from "../repositories/WorkerValidation.repository";

export const WorkerValidationService = {
    getWorkerValidations,
    createWorkerValidation,
    editWorkerValidation,
    deleteWorkerValidation,
    listValidationIdentityAdmin,
    confirmValidationIdentityAdmin,
}

async function getWorkerValidations(){
    const res = await WorkerValidationRepository.getWorkerValidations();
    return res;
}

async function createWorkerValidation(data){
    const res = await WorkerValidationRepository.createWorkerValidation(data);
    return res;
}

async function editWorkerValidation(idValidation, data){
    const res = await WorkerValidationRepository.editWorkerValidation(idValidation, data);
    return res;
}

async function deleteWorkerValidation(idValidation:number){
    const res = await WorkerValidationRepository.deleteWorkerValidation(idValidation);
    return res;
}

async function listValidationIdentityAdmin(idemployee){
    const res = await WorkerValidationRepository.listValidationIdentityAdmin(idemployee);
    return res;
}

async function confirmValidationIdentityAdmin(data){
    const res = await WorkerValidationRepository.confirmValidationIdentityAdmin(data);
    return res;
}

