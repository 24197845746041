import { AssistanceRepository } from "../repositories/Assistance.repository";
import { MovementsRepository } from "../repositories/Movements.repository";

export const MovementsService = {
    getMovementsTypes,
    getMovementsByEmployee,
    createMovement,
    deleteMovement,
    generateExcelMovement,
    editMovement
}

async function getMovementsTypes(){
    const res = await MovementsRepository.getMovementsTypes();
    return res;
}

async function getMovementsByEmployee(idEmployee: number){
    const res = await MovementsRepository.getMovementsByEmployee(idEmployee);
    return res;
}

async function createMovement(data){
    const res = await MovementsRepository.createMovement(data);
    return res;
}

async function generateExcelMovement(){
    const res = await MovementsRepository.generateExcelMovement();
    return res;
}

async function deleteMovement(idMovement:number){
    const res = await MovementsRepository.deleteMovement(idMovement);
    return res;
}

async function editMovement(id, data){
    const res = await MovementsRepository.editMovement(id, data);
    return res;
}