import { ProfilePermissionsRepository } from "../repositories/ProfilePermissions.repository";

export const ProfilePermissionsService = {
    getPermissionsProfile,
    getPermissionsByIdProfile,
    createPermissionProfile,
    editPermissionProfile,
    editPermissionProfileForAdmin,
    deletePermissionProfile,
}

async function getPermissionsProfile(){
    const res = await ProfilePermissionsRepository.getPermissionsProfile();
    return res;
}

async function getPermissionsByIdProfile(idProfile){
    const res = await ProfilePermissionsRepository.getPermissionsByIdProfile(idProfile);
    return res;
}

async function createPermissionProfile(data){
    const res = await ProfilePermissionsRepository.createPermissionProfile(data);
    return res;
}

async function editPermissionProfile(id, data){
    const res = await ProfilePermissionsRepository.editPermissionProfile(id, data);
    return res;
}

async function editPermissionProfileForAdmin(id, data){
    const res = await ProfilePermissionsRepository.editPermissionProfileForAdmin(id, data);
    return res;
}
async function deletePermissionProfile(id){
    const res = await ProfilePermissionsRepository.deletePermissionProfile(id);
    return res;
}
